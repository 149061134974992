import React from 'react'
import {VmoCard, VmoCardContent, VmoContainer, VmoImage} from 'vmo-library'
import meetConf from 'assets/meet-conf.svg'
import vlogo from 'assets/desk-v-logo.svg'

const MeetingConfrimd = props => {
  const {detail, heading, owner} = props.history.location.state
  return (
    <>
      <div className="meetBookPage">
        <VmoContainer>
          <h1>{heading}</h1>
          <VmoCard fluid className="meetBooking">
            <VmoCardContent className="meet-booked">
              <VmoImage src={meetConf} />
              <h1>Booking Confirmed</h1>
              <p className="mt-3 mb-0">
                {`You're booked with ${owner} on `}
                <span>{detail}</span>
              </p>
              <p className="mt-0 mb-4">An invitation has been emailed to you.</p>
            </VmoCardContent>
          </VmoCard>
          <div className="cal-branding">
            <a href="">
              <VmoImage src={vlogo} /> Create your own meeting booking page
            </a>
          </div>
        </VmoContainer>
      </div>
    </>
  )
}
export default MeetingConfrimd
