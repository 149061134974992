import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  VmoCheckbox,
  VmoForm,
  VmoFormDropdown,
  VmoFormField,
  VmoFormInput,
  VmoList,
  VmoListContent,
  VmoListItem,
  VmoTooltip,
} from 'vmo-library'
import {GET_OFFLINE_EXPERIENCE, UPDATE_OFFLINE_EXPERIENCE} from 'actions/types'
import {getOfflineExperience, updateOfflineExperience} from 'actions/settings_messenger'
import SettingLayout from 'layouts/settingLayout'
import {removeDoubleQuotes} from 'utils/helper'
import {offlineExperienceDefaultValue, offlineExperienceSchema} from 'validation/Settings/messenger.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'

const OfflineExperience = () => {
  const dispatch = useDispatch()

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(offlineExperienceSchema),
    defaultValues: offlineExperienceDefaultValue,
  })

  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {offlineExperienceData = {}} = useSelector(state => state.settingsMessenger)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const offlineExperienceLearnMore = settingsLearnMoreUrl?.messenger?.offLineExperience?.learnMore

  const {channelsPermissions} = useUserPermissions()
  const manageMessangerPagesPermissions = channelsPermissions?.manage_messanger_pages

  useEffect(() => {
    dispatch(getOfflineExperience())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_OFFLINE_EXPERIENCE) || successLabels.includes(UPDATE_OFFLINE_EXPERIENCE)) {
      reset(_.omit(offlineExperienceData, ['id', 'accountId']))
    }
  }, [successLabels, offlineExperienceData, reset])

  const resetData = () => {
    if (offlineExperienceData) {
      reset(_.omit(offlineExperienceData, ['id', 'accountId']))
    } else {
      reset(offlineExperienceDefaultValue)
    }
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/offline-experience" />}
      header="Offline Experience"
      subHeader="Proactively communicate with your customers when your team is not available with offline messages."
      learnMoreUrl={offlineExperienceLearnMore}
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Reset',
          disabled: !isDirty || (isLoadingData && type.includes(UPDATE_OFFLINE_EXPERIENCE)),
          onClick: resetData,
        },
        success: {
          content: 'Update',
          loading: isLoadingData && type.includes(UPDATE_OFFLINE_EXPERIENCE),
          disabled: !isDirty || (isLoadingData && type.includes(UPDATE_OFFLINE_EXPERIENCE)),
          ...(manageMessangerPagesPermissions && {
            onClick: handleSubmit(data => dispatch(updateOfflineExperience(offlineExperienceData.id, data))),
          }),
        },
      }}
      lockRole={!manageMessangerPagesPermissions}
    >
      <VmoForm className="errorLabel">
        <Controller
          control={control}
          name="setting"
          render={({onChange, value}) => (
            <VmoFormField>
              <div className="info-header">
                <label>Configure</label>
                <VmoTooltip
                  content="Set the condition for offline experience feature to trigger"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </div>
              <VmoFormDropdown
                selection
                width={6}
                value={value}
                options={[
                  {
                    text: 'Enable when all agents are offline',
                    value: 2,
                    key: 2,
                  },
                  {
                    text: 'Enable outside business hours',
                    value: 1,
                    key: 1,
                  },
                  {
                    text: 'Disable (default)',
                    value: 0,
                    key: 0,
                  },
                ]}
                onChange={(e, {value}) => onChange(value)}
              />
            </VmoFormField>
          )}
        />

        <VmoFormField>
          <div className="info-header">
            <label>Collect customer details</label>
            <VmoTooltip
              content="Select which customer information you want to capture during offline message"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <VmoList relaxed className="swtichList errorLabel" style={{maxWidth: '350px'}}>
            <VmoListItem>
              <VmoListContent floated="right">
                <Controller
                  control={control}
                  name="name"
                  render={({onChange}) => (
                    <VmoCheckbox toggle disabled checked="true" onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </VmoListContent>
              Name
            </VmoListItem>

            <VmoListItem>
              <VmoListContent floated="right">
                <Controller
                  control={control}
                  name="email"
                  render={({onChange}) => (
                    <VmoCheckbox toggle disabled checked="true" onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </VmoListContent>
              Email
            </VmoListItem>

            <VmoListItem>
              <VmoListContent floated="right">
                <Controller
                  control={control}
                  name="phone"
                  render={({onChange, value}) => (
                    <VmoCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </VmoListContent>
              Phone No
            </VmoListItem>

            <VmoListItem>
              <VmoListContent floated="right">
                <Controller
                  control={control}
                  name="message"
                  render={({onChange}) => (
                    <VmoCheckbox toggle disabled checked="true" onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </VmoListContent>
              Message
            </VmoListItem>
          </VmoList>
        </VmoFormField>

        <VmoFormField required width={6}>
          <div className="info-header">
            <label>Thank you Message text</label>
            <VmoTooltip
              content="Set a message to be displayed after the user submits the ticket"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            control={control}
            name="thanksMsg"
            render={({onChange, value}) => (
              <VmoFormInput
                maxLength={30}
                placeholder="type message here..."
                onChange={(e, {value}) => onChange(value)}
                value={value}
                error={
                  errors.thanksMsg && {
                    content: removeDoubleQuotes(errors.thanksMsg.message),
                  }
                }
              />
            )}
          />
        </VmoFormField>
      </VmoForm>
    </SettingLayout>
  )
}

export default OfflineExperience
