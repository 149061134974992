import {
  GET_PURCHASES_CATEGORY,
  GET_SINGLE_PURCHASES_CATEGORY,
  DELETE_PURCHASES_CATEGORY,
  CREATE_PURCHASES_CATEGORY,
  GET_PURCHASES_LIST,
  GET_PURCHASES_DATA,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_PURCHASES_CATEGORY:
      return {
        ...state,
        getPurchasesCategoryData: action.payload,
      }
    case GET_SINGLE_PURCHASES_CATEGORY:
      return {
        ...state,
        getSinglePurchasesCategoryData: action.payload,
      }
    case DELETE_PURCHASES_CATEGORY: {
      return {
        ...state,
        getPurchasesCategoryData: state.getPurchasesCategoryData.filter(category => category.id !== action.payload),
      }
    }
    case CREATE_PURCHASES_CATEGORY:
      return {
        ...state,
        getPurchasesCategoryData: [action.payload, ...state.getPurchasesCategoryData],
      }
    case GET_PURCHASES_LIST:
      return {
        ...state,
        purchasesList: action.payload,
        purchaseListData: action.payload.purchases,
        purchaseListPage: action.payload.page,
      }
    case GET_PURCHASES_DATA:
      return {
        ...state,
        purchasesData: action.payload,
      }
    case 'CLEAR_PURCHASES_DATA':
      return {
        ...state,
        purchasesData: {},
      }
    default:
      return state
  }
}
