import {useMemo} from 'react'
import {useSelector} from 'react-redux'

const useProjectsTemplatesTasklist = () => {
  const {projectsTempaltesTasklistsFilters = []} = useSelector(state => state.projects)
  const {assignees = [], stages = []} = projectsTempaltesTasklistsFilters || {}

  const projectsTemplatesTasklistAssignees = useMemo(() => {
    return (
      assignees?.map(({id, firstName, lastName}) => ({
        key: id,
        value: id,
        text: `${firstName} ${lastName}`,
      })) || []
    )
  }, [assignees])

  const projectsTemplatesTasklistStages = useMemo(() => {
    return stages?.map(({id, name}) => ({key: id, value: id, text: name})) || []
  }, [stages])

  return {projectsTemplatesTasklistAssignees, projectsTemplatesTasklistStages}
}

export default useProjectsTemplatesTasklist
