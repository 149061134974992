import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormSelect,
  VmoFormTextArea,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'
import {getSMSList} from 'actions/inbox'
import {get, removeDoubleQuotes} from 'utils/helper'
import {smsResolver} from 'validation/Sales/sequence.schema'
import SvgIcon from 'components/common/SvgIcon'

function SmsModal({open, toggle, handleAdd, editData}) {
  const [smsList, setSmsList] = useState([])

  const {getSmsChannelList = []} = useSelector(state => state.emailInbox)

  const dispatch = useDispatch()

  const {handleSubmit, errors, control} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(smsResolver),
    defaultValues: editData || {
      messageText: '',
      mailboxId: Joi.number(),
    },
  })

  useEffect(() => {
    if (getSmsChannelList) {
      dispatch(getSMSList())
    }
  }, [])

  useEffect(() => {
    if (getSmsChannelList.length > 0) {
      setSmsList(
        getSmsChannelList.map(sms => ({
          key: sms.id,
          value: sms.mailboxId,
          text: sms.name,
          content: (
            <>
              {`${get(['name'], sms, '')}`}
              <small className="ml-2 kadence-lead">({sms.phoneNumber})</small>
            </>
          ),
        }))
      )
    }
  }, [getSmsChannelList])

  return (
    <VmoModal
      open={open}
      size="tiny"
      onClose={() => toggle(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
    >
      <VmoModalHeader>Auto SMS</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Select Sms Channel</label>
            <Controller
              control={control}
              name="mailboxId"
              render={({value, onChange}) => (
                <VmoFormSelect
                  options={smsList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.mailboxId && {
                      content: removeDoubleQuotes(errors.mailboxId.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>

          <VmoFormField required>
            <label>Message</label>
            <Controller
              control={control}
              name="messageText"
              render={({value, onChange}) => (
                <VmoFormTextArea
                  maxLength={200}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.messageText && {
                      content: removeDoubleQuotes(errors.messageText.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic className="mr-3" onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton
          primary
          onClick={handleSubmit(data => {
            handleAdd(data)
            toggle(false)
          })}
        >
          Save
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default SmsModal
