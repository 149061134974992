import {apiAction} from './api'
import {KADENCE, KADENCE_ACCOUNT_V1, KADENCE_ATTACHMENTS, KADENCE_SETTING} from './endpoint'
import {
  CONFIGURE_LOGO_UPLOAD,
  GET_ADVANCED_WIDGET,
  GET_APPEARENCE_WIDGET,
  GET_CONFIGURE_WIDGET,
  GET_INSTALLATION_WIDGET,
  GET_OFFLINE_EXPERIENCE,
  UPDATE_ADVANCED_WIDGET,
  UPDATE_APPEARENCE_WIDGET,
  UPDATE_CONFIGURE_WIDGET,
  UPDATE_OFFLINE_EXPERIENCE,
} from './types'

export function GetInstallationWidget() {
  return apiAction({
    url: `${KADENCE}/channels?type=web`,
    method: 'GET',
    label: GET_INSTALLATION_WIDGET,
  })
}

export function GetAppearenceWidget(id) {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/settings/widget?id=${id}`,
    method: 'GET',
    label: GET_APPEARENCE_WIDGET,
  })
}

export function UpdateAppearenceWidget(id, OnBoardingDesgin) {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/settings/widget?id=${id}`,
    method: 'PUT',
    label: UPDATE_APPEARENCE_WIDGET,
    data: OnBoardingDesgin,
    showToast: true,
    successMessage: 'Updated Successfully',
  })
}

// Configure Start
export function GetConfigureWidget(id) {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/settings/widget?id=${id}`,
    method: 'GET',
    label: GET_CONFIGURE_WIDGET,
  })
}

export function UpdateConfigureWidget(id, OnBoardingDesgin) {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/settings/widget?id=${id}`,
    method: 'PUT',
    label: UPDATE_CONFIGURE_WIDGET,
    data: OnBoardingDesgin,
    showToast: true,
    successMessage: 'Updated Successfully',
  })
}

export function uploadConfigureLogo(data) {
  return apiAction({
    url: KADENCE_ATTACHMENTS,
    method: 'POST',
    label: CONFIGURE_LOGO_UPLOAD,
    contentType: 'multipart/form-data',
    data,
  })
}
// Configure End

// Advanced Start
export function GetAdvancedWidget(id) {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/settings/widget?id=${id}`,
    method: 'GET',
    label: GET_ADVANCED_WIDGET,
    tokenIdentity: 'chat',
  })
}

export function UpdateAdvancedWidget(id, OnBoardingDesgin) {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/settings/widget?id=${id}`,
    method: 'PUT',
    label: UPDATE_ADVANCED_WIDGET,
    data: OnBoardingDesgin,
    showToast: true,
    successMessage: 'Updated Successfully',
  })
}
// Advanced End

// Offline Experience Start
export function getOfflineExperience() {
  return apiAction({
    url: `${KADENCE_SETTING}/offlineExperience`,
    method: 'GET',
    label: GET_OFFLINE_EXPERIENCE,
  })
}

export function updateOfflineExperience(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/offlineExperience/${id}`,
    method: 'PUT',
    label: UPDATE_OFFLINE_EXPERIENCE,
    data,
    showToast: true,
    successMessage: 'Offline Experience Updated',
  })
}
// Offline Experience End
