import {apiAction} from './api'
import {KADENCE_PURCHASES} from './endpoint'

import {
  GET_PURCHASES_CATEGORY,
  CREATE_PURCHASES_CATEGORY,
  DELETE_PURCHASES_CATEGORY,
  GET_SINGLE_PURCHASES_CATEGORY,
  EDIT_PURCHASES_CATEGORY,
  CREATE_PURCHASES,
  GET_PURCHASES_LIST,
  DELETE_PURCHASES,
  GET_PURCHASES_DATA,
  UPDATE_PURCHASES,
} from './types'

export function getPurchasesCategory() {
  return apiAction({
    url: `${KADENCE_PURCHASES}/categories`,
    method: 'GET',
    label: GET_PURCHASES_CATEGORY,
  })
}

export function createPurchasesCategory(data) {
  return apiAction({
    url: `${KADENCE_PURCHASES}/category`,
    method: 'POST',
    label: CREATE_PURCHASES_CATEGORY,
    data,
    showToast: true,
    successMessage: 'Category Added',
  })
}

export function deletePurchasesCategory(id) {
  return apiAction({
    url: `${KADENCE_PURCHASES}/category/${id}`,
    method: 'DELETE',
    label: DELETE_PURCHASES_CATEGORY,
    showToast: true,
    successMessage: 'Category Deleted',
  })
}

export function getSinglePurchasesCategory(id) {
  return apiAction({
    url: `${KADENCE_PURCHASES}/category/${id}`,
    method: 'GET',
    label: GET_SINGLE_PURCHASES_CATEGORY,
  })
}

export function editPurchasesCategory(id, data) {
  return apiAction({
    url: `${KADENCE_PURCHASES}/category/${id}`,
    method: 'PUT',
    label: EDIT_PURCHASES_CATEGORY,
    data,
    showToast: true,
    successMessage: 'Category Updated',
  })
}

export function createPurchases(data) {
  return apiAction({
    url: `${KADENCE_PURCHASES}`,
    method: 'POST',
    label: CREATE_PURCHASES,
    data,
    showToast: true,
    successMessage: 'Purchases Added',
  })
}

export function getPurchaseslist(params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params || {})
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }

  return apiAction({
    url: KADENCE_PURCHASES + query,
    method: 'GET',
    label: GET_PURCHASES_LIST,
  })
}

export function deletePurchases(id) {
  return apiAction({
    url: `${KADENCE_PURCHASES}/${id}`,
    method: 'DELETE',
    label: DELETE_PURCHASES,
    showToast: true,
    successMessage: 'Purchases Deleted',
  })
}

export function getPurchasesData(id) {
  return apiAction({
    url: `${KADENCE_PURCHASES}/${id}`,
    method: 'GET',
    label: GET_PURCHASES_DATA,
  })
}

export function updatePurchases(id, data) {
  return apiAction({
    url: `${KADENCE_PURCHASES}/${id}`,
    method: 'PUT',
    label: UPDATE_PURCHASES,
    data,
    showToast: true,
    successMessage: 'Purchases Updated',
  })
}
