import {VISITORS_OVERTIME, VISITORS_TOP_LIST, VISITOR_STATS} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case VISITOR_STATS:
      return {...state, visitorStats: payload}

    case VISITORS_OVERTIME:
      return {...state, visitorsOvertime: payload}

    case VISITORS_TOP_LIST:
      return {...state, visitorsTopList: payload}

    default:
      return state
  }
}
