import {TOTAL_VENDORS, NEW_VENDORS, VENDORS_BY_CATEGORY, VENDORS_OVER_TIME} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case TOTAL_VENDORS:
      return {
        ...state,
        totalVendorsData: action.payload,
      }

    case NEW_VENDORS:
      return {
        ...state,
        newVendorsData: action.payload,
      }

    case VENDORS_BY_CATEGORY:
      return {
        ...state,
        vendorsByCategoryData: action.payload,
      }

    case VENDORS_OVER_TIME:
      return {
        ...state,
        vendorsOverTimeData: action.payload,
      }

    default:
      return state
  }
}
