import Joi from 'joi'
import {inputNumberValidation} from 'validation'

export const ContactSegmentStep1 = Joi.object({
  name: Joi.string().trim().min(3).max(20).required().messages({
    'string.empty': `Name cannot be an empty field`,
    'any.required': `Name is required`,
    'string.min': `Minimum 3 characters are required`,
    'string.max': `Maximum 20 characters are allowed`,
  }),
  isStatic: Joi.boolean(),
})

export const ContactSegmentStep2 = Joi.object({
  ids: Joi.array().items(Joi.number().integer()),
  conditions: Joi.object({
    assigneeId: inputNumberValidation.allow('', null).label('Owner'),
    labelIds: Joi.number().allow('', null).label('Tags'),
    companyId: Joi.number().allow('', null),
    isFollowed: Joi.string().allow('', null),
    interactionsFrom: Joi.number().min(0).allow('', null, 0),
    interactionsTo: Joi.number().min(0).allow('', null, 0),
    lastContactedFrom: Joi.date().iso().allow(null),
    lastContactedTo: Joi.date().iso().allow(null),
    inactiveDaysFrom: Joi.number().min(0).allow('', null, 0),
    inactiveDaysTo: Joi.number().min(0).allow('', null, 0),
    createdAtFrom: Joi.date().iso().allow(null),
    createdAtTo: Joi.date().iso().allow(null),
    contactTypeId: Joi.number().allow('', null),
    statusId: Joi.number().allow('', null),
    locality: Joi.any().allow('', null).label('Locality'),
    state: Joi.string().allow('', null).label('State'),
    country: Joi.string().allow('', null).label('Country'),
    postalCode: Joi.number().allow(null, '').label('Postal code'),
  }),
})

export const LeadSegmentStep2 = Joi.object({
  ids: Joi.array().items(Joi.number().integer()),
  conditions: Joi.object({
    assigneeId: inputNumberValidation.allow('', null).label('Owner'),
    labelIds: Joi.number().allow('', null).label('Tags'),
    currency: Joi.string().allow('', null),
    company: Joi.string().allow('', null),
    isFollowed: Joi.string().allow('', null),
    monetaryValueFrom: Joi.number().min(0).allow('', null, 0),
    monetaryValueTo: Joi.number().min(0).allow('', null, 0),
    interactionsFrom: Joi.number().min(0).allow('', null, 0),
    interactionsTo: Joi.number().min(0).allow('', null, 0),
    lastContactedFrom: Joi.date().iso().allow(null),
    lastContactedTo: Joi.date().iso().allow(null),
    inactiveDaysFrom: Joi.number().min(0).allow('', null, 0),
    inactiveDaysTo: Joi.number().min(0).allow('', null, 0),
    createdAtFrom: Joi.date().iso().allow(null),
    createdAtTo: Joi.date().iso().allow(null),
    sourceId: Joi.number().allow('', null),
    statusId: Joi.number().allow('', null),
    locality: Joi.any().allow('', null).label('Locality'),
    state: Joi.string().allow('', null).label('State'),
    country: Joi.string().allow('', null).label('Country'),
    postalCode: Joi.number().allow(null, '').label('Postal code'),
  }),
})

export const CompaniesSegmentStep2 = Joi.object({
  ids: Joi.array().items(Joi.number().integer()),
  conditions: Joi.object({
    assigneeId: inputNumberValidation.allow('', null).label('Owner'),
    labelIds: Joi.number().allow('', null).label('Tags'),
    isFollowed: Joi.string().allow('', null),
    interactionsFrom: Joi.number().min(0).allow('', null, 0),
    interactionsTo: Joi.number().min(0).allow('', null, 0),
    lastContactedFrom: Joi.date().iso().allow(null),
    lastContactedTo: Joi.date().iso().allow(null),
    inactiveDaysFrom: Joi.number().min(0).allow('', null, 0),
    inactiveDaysTo: Joi.number().min(0).allow('', null, 0),
    createdAtFrom: Joi.date().iso().allow(null),
    createdAtTo: Joi.date().iso().allow(null),
    contactTypeId: Joi.number().allow('', null),
    locality: Joi.any().allow('', null).label('Locality'),
    state: Joi.string().allow('', null).label('State'),
    country: Joi.string().allow('', null).label('Country'),
    postalCode: Joi.number().allow(null, '').label('Postal code'),
  }),
})
