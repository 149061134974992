export default function (state = {}, action) {
  switch (action.type) {
    case 'API_PROGRESS':
      return {
        ...state,
        data: {
          percent: action.payload.percent || 0,
          loaded: action.payload.loaded || 0,
          total: action.payload.total || 0,
        },
      }

    default:
      return state
  }
}
