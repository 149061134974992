import React from 'react'
import {Controller} from 'react-hook-form'
import {VmoForm, VmoFormField, VmoFormGroup, VmoFormInput, VmoFormSelect} from 'vmo-library'
import {countryCodeList} from 'constants/variables'
import {removeDoubleQuotes} from 'utils/helper'

const BillingInfoForm = ({control, errors, clearErrors}) => {
  return (
    <VmoForm className="errorLabel">
      <VmoFormField required>
        <label>Address</label>
        <Controller
          name="line1"
          control={control}
          render={({value, onChange}) => (
            <VmoFormInput
              maxLength={20}
              type="text"
              placeholder="Line 1"
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={errors.line1 && {content: removeDoubleQuotes(errors.line1.message)}}
            />
          )}
        />
      </VmoFormField>
      <VmoFormGroup>
        <VmoFormField width={8} required>
          <label>City</label>
          <Controller
            name="city"
            control={control}
            render={({value, onChange}) => (
              <VmoFormInput
                maxLength={20}
                type="text"
                placeholder="City"
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={errors.city && {content: removeDoubleQuotes(errors.city.message)}}
              />
            )}
          />
        </VmoFormField>
        <VmoFormField width={8} required>
          <label>State</label>
          <Controller
            name="state"
            control={control}
            render={({value, onChange}) => (
              <VmoFormInput
                type="text"
                maxLength={20}
                placeholder="State"
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={errors.state && {content: removeDoubleQuotes(errors.state.message)}}
              />
            )}
          />
        </VmoFormField>
      </VmoFormGroup>
      <VmoFormGroup>
        <VmoFormField width={8} required>
          <label>Country</label>
          <Controller
            name="country"
            control={control}
            render={({value, onChange}) => (
              <VmoFormSelect
                type="text"
                placeholder="Country"
                search
                options={countryCodeList.map(item => ({
                  key: `${item.name}`,
                  value: item.code,
                  flag: item.code.toLowerCase(),
                  text: `${item.name}`,
                  content: (
                    <div
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        maxWidth: '150px',
                        display: 'inline-block',
                      }}
                    >{`${item.name}`}</div>
                  ),
                }))}
                value={value}
                onClick={() => clearErrors('country')}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.country && {
                    content: removeDoubleQuotes(errors.country.message),
                  }
                }
              />
            )}
          />
        </VmoFormField>
        <VmoFormField width={8} required>
          <label>Postal Code</label>
          <Controller
            name="postalCode"
            control={control}
            render={({value, onChange, onBlur}) => (
              <VmoFormInput
                maxLength={20}
                type="text"
                placeholder="Postal Code"
                value={value}
                onBlur={onBlur}
                onChange={e => onChange(e.target.value)}
                error={
                  errors.postalCode && {
                    content: removeDoubleQuotes(errors.postalCode.message),
                  }
                }
              />
            )}
          />
        </VmoFormField>
      </VmoFormGroup>
    </VmoForm>
  )
}

export default BillingInfoForm
