import moment from 'moment'

export const year = [
  {key: 1, text: `January ${moment().format('YYYY')}`, value: 'January'},
  {key: 2, text: `February ${moment().format('YYYY')}`, value: 'February'},
  {key: 3, text: `March ${moment().format('YYYY')}`, value: 'March'},
  {key: 4, text: `April ${moment().format('YYYY')}`, value: 'April'},
  {key: 5, text: `May ${moment().format('YYYY')}`, value: 'May'},
  {key: 6, text: `June ${moment().format('YYYY')}`, value: 'June'},
  {key: 7, text: `July ${moment().format('YYYY')}`, value: 'July'},
  {key: 8, text: `August ${moment().format('YYYY')}`, value: 'August'},
  {key: 9, text: `September ${moment().format('YYYY')}`, value: 'September'},
  {key: 10, text: `October ${moment().format('YYYY')}`, value: 'October'},
  {key: 11, text: `November ${moment().format('YYYY')}`, value: 'November'},
  {key: 12, text: `December ${moment().format('YYYY')}`, value: 'December'},
]

export const KpiList = [
  {key: 1, value: 'Revenue', text: 'Revenue'},
  {
    key: 2,
    value: 'Number of Interaction meetings',
    text: 'Number of Interaction meetings',
  },
  {
    key: 3,
    value: 'Number of Interaction calls',
    text: 'Number of Interaction calls',
  },
  {
    key: 4,
    value: 'Number of Interaction demo',
    text: 'Number of Interaction demo',
  },
  {
    key: 5,
    value: 'Number of Interaction event',
    text: 'Number of Interaction event',
  },
  {
    key: 6,
    value: 'Number of Meeting Booked',
    text: 'Number of Meeting Booked',
  },
  {
    key: 7,
    value: 'Number of leads',
    text: 'Number of leads',
  },
  {
    key: 8,
    value: 'Number of customers',
    text: 'Number of customers',
  },
  {
    key: 9,
    value: 'Number of deals created',
    text: 'Number of deals created',
  },
]

export const recursionMonthly = [
  {key: 1, text: 'Next 1 month', value: 1},
  {key: 2, text: 'Next 2 month', value: 2},
  {key: 3, text: 'Next 3 month', value: 3},
  {key: 4, text: 'Next 4 month', value: 4},
  {key: 5, text: 'Next 5 month', value: 5},
  {key: 6, text: 'Next 6 month', value: 6},
  {key: 7, text: 'Next 7 month', value: 7},
  {key: 8, text: 'Next 8 month', value: 8},
  {key: 9, text: 'Next 9 month', value: 9},
  {key: 10, text: 'Next 10 month', value: 10},
  {key: 11, text: 'Next 11 month', value: 11},
  {key: 12, text: 'Next 12 month', value: 12},
]
export const recursionQuarterly = [
  {key: 1, text: '1 quarterly', value: 1},
  {key: 2, text: '2 quarterly', value: 2},
  {key: 3, text: '3 quarterly', value: 3},
  {key: 4, text: '4 quarterly', value: 4},
]
export const recursionYearly = [
  {key: 1, text: 'Next 1 yearly', value: 1},
  {key: 2, text: 'Next 2 yearly', value: 2},
]
