import {
  GET_ALL_MAIL_INBOX,
  GET_MAIL_BOX,
  UPDATE_SMTP_SETTINGS,
  GET_ACTIVITY_FEED,
  CLEAR_ACTIVITY_FEED,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_MAIL_INBOX:
      return {
        ...state,
        inbox: action.payload,
      }

    case GET_MAIL_BOX:
      return {
        ...state,
        getSingleInbox: action.payload,
        successData: action.successData,
      }

    case UPDATE_SMTP_SETTINGS: {
      return {
        smtpData: action.payload,
      }
    }

    case GET_ACTIVITY_FEED: {
      let prvData = []
      if (state?.getActivityFeedData) {
        prvData = [...state?.getActivityFeedData?.data]
      }
      return {
        ...state,
        getActivityFeedData: {
          ...action.payload,
          data: [...prvData, ...action.payload.data],
        },
      }
    }

    case CLEAR_ACTIVITY_FEED: {
      return {
        ...state,
        getActivityFeedData: null,
      }
    }

    default:
      return state
  }
}
