import React from 'react'
import {getFormattedDate} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import {VmoCard, VmoCardContent, VmoCardDescription, VmoCardHeader, VmoCardMeta} from 'vmo-library'

const ProjectBillingInvoiceList = ({invoiceList = [], onListItemClick, onDeleteClick}) => {
  if (invoiceList.length === 0) {
    return (
      <VmoCard fluid>
        <VmoCardContent className="emptyValue">No Invoice Available</VmoCardContent>
      </VmoCard>
    )
  }

  return invoiceList.map(invoice => (
    <VmoCard fluid key={invoice.id} onClick={() => onListItemClick(invoice.id)}>
      <VmoCardContent>
        <VmoCardHeader className="mb-2" style={{display: 'flex', justifyContent: 'space-between'}}>
          {invoice.invoiceNumber}
          <SvgIcon
            path="common/delete"
            floated="right"
            onClick={e => {
              e.stopPropagation()
              onDeleteClick(invoice.id)
            }}
          />
        </VmoCardHeader>
        <VmoCardMeta style={{opacity: '0.6'}}>{getFormattedDate(invoice.issueDate)}</VmoCardMeta>
        <VmoCardDescription>{invoice.description || 'No description provided'}</VmoCardDescription>
      </VmoCardContent>
    </VmoCard>
  ))
}

export default ProjectBillingInvoiceList
