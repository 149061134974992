import {BREAK_DOWN_BY_KPI, FORECAST_SUMMARY, GOAL_KPI_BREAK_DOWN} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case BREAK_DOWN_BY_KPI:
      return {
        ...state,
        breakdownByKpiData: action.payload,
      }
    case GOAL_KPI_BREAK_DOWN:
      return {
        ...state,
        goalAchviedBreakdownKpiData: action.payload,
      }
    case FORECAST_SUMMARY:
      return {
        ...state,
        forecastSummaryData: action.payload,
      }

    default:
      return state
  }
}
