export const EmploymentStatus = [
  {value: 'Active', key: 'Active', text: 'Active'},
  {value: 'Retired', key: 'Retired', text: 'Retired'},
  {value: 'Suspended', key: 'Suspended', text: 'Suspended'},
  {value: 'Terminated', key: 'Terminated', text: 'Terminated'},
  {value: 'Salary_Continuation', key: 'Salary_Continuation', text: 'Salary Continuation'},
  {value: 'Probation', key: 'Probation', text: 'Probation'},
  {value: 'On_Paid_Leave', key: 'On_Paid_Leave', text: 'On Paid Leave'},
  {value: 'On_Unpaid_Leave', key: 'On_Unpaid_Leave', text: 'On Unpaid Leave'},
]

export const EmploymentType = [
  {value: 'Full_time', key: 'Full_time', text: 'Full Time'},
  {value: 'Part_Time', key: 'Part_Time', text: 'Part Time'},
  {value: 'Contract', key: 'Contract', text: 'Contract'},
  {value: 'Intern', key: 'Intern', text: 'Intern'},
  {value: 'Student', key: 'Student', text: 'Student'},
  {value: 'Freelancer', key: 'Freelancer', text: 'Freelancer'},
]

export const employeeSummary = [
  {color: 'green', time: '120h', name: 'Estimated time'},
  {color: 'blue', time: '6h 25m', name: 'Total logged time'},
  {color: 'orange', time: '20m', name: 'Billed time'},
  {color: 'red', time: '6h 5m', name: 'Billable time'},
  {color: 'purple', time: '0h', name: 'Non-billable'},
]
