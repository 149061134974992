/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

/**
 * Custom hook to handle API response and dispatch actions.
 *
 * @param {object} options - The options object.
 * @param {string} options.label - The label for the API call.
 * @param {function} [options.onSuccess=()=>{}] - Callback on success.
 * @param {function} [options.onError=()=>{}] - Callback on error.
 * @param {string} options.storePath - Path to the store data.
 * @param {function} options.action - The action to dispatch.
 * @param {array} [options.dependency=[]] - Dependency array for useEffect.
 * @param {boolean} options.enabled - Flag to enable API call.
 * @param {any} options.payload - Payload for the action.
 * @param {string} [options.dataToExtract=null] - Specific data to extract from the store.
 * @return {object} - Object containing isLoading, isError, data, and other meta data.
 */
const useApiResponse = ({
  label,
  onSuccess = () => {},
  onError = () => {},
  storePath,
  action,
  dependency = [],
  enabled = false,
  payload = null,
  dataToExtract = null,
}) => {
  const {successLabels = [], type = [], errorLabels = [], ...rest} = useSelector(state => state.apiReducer)
  const storeData = useSelector(state => _.get(state, storePath))
  const dispatch = useDispatch()
  const isLoading = type.includes(label)

  const isError = errorLabels.includes(label)

  const callAction = useCallback(
    (data = payload) => {
      dispatch(action(data))
    },
    [dispatch, action, payload]
  )

  useEffect(() => {
    if (successLabels.includes(label)) {
      onSuccess(storeData)
    }
  }, [successLabels, label, onSuccess, storeData])

  useEffect(() => {
    if (errorLabels.includes(label)) {
      const message = rest?.apiMessage?.errorMessage ?? 'Something went wrong'
      onError(message)
    }
  }, [errorLabels, label, onError, rest])

  useEffect(() => {
    if (enabled) {
      dispatch(action(payload))
    }
  }, [...dependency, enabled])

  const data = useMemo(
    () => (dataToExtract ? storeData?.[dataToExtract] : storeData) ?? storeData,
    [dataToExtract, storeData]
  )

  return {
    isLoading,
    isError,
    data,
    totalPages: storeData?.totalPages || 1,
    currentPage: storeData?.page || 1,
    totalRecords: storeData?.totalRecords || 0,
    pageSize: storeData?.pageSize || 50,
    callAction,
  }
}

export default useApiResponse
