import React, {useEffect, useState} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {Controller, useForm} from 'react-hook-form'
import {useHistory, useParams} from 'react-router'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoContainer,
  VmoDatePicker,
  VmoDropdown,
  VmoEditor,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'
import {
  CREATE_ANNOUNCEMENT,
  DEPARTMENTS_FOR_ANNOUNCEMENT,
  EMPLOYEES_FOR_ANNOUNCEMENT,
  GET_ALL_TAG_LABEL,
  GET_SINGLE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
} from 'actions/types'
import {
  getAllDepartments,
  getAllEmployees,
  CreateAnnouncements,
  getSingleAnnouncement,
  updateAnnouncement,
} from 'actions/Announcement'
import {getTagLabel} from 'actions/multiple_fields'
import {removeDoubleQuotes, get} from 'utils/helper'
import {editorOptions} from 'constants/variables'
import axios from 'axios'
import {KADENCE_ATTACHMENTS} from 'actions/endpoint'
import {getAppToken} from 'utils/local-storage'
import {AnnouncementSchema} from 'validation/Hr/announcement.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const CreateAnnouncement = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id: editId} = useParams()

  const [EmployeeOption, setEmployeeOption] = useState([])
  const [DepartmentOption, setDepartmentOption] = useState([])
  const [TagsOption, setTagsOption] = useState([])
  const [UnpublishDate, setUnpublishDate] = useState(null)
  const [ScheduledDateTime, setScheduledDateTime] = useState(null)
  const [publishModalStatus, setIsPublishModalStatus] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const initialCreateInfo = {
    displayTo: 'all',
    employeeIds: [],
    departmentIds: [],
    tagIds: [],
    title: '',
    enableUnPublish: false,
    isScheduled: false,
    scheduledDateTime: null,
    unpublishDate: null,
    announcement: '',
    isDraft: false,
  }

  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(AnnouncementSchema),
    defaultValues: initialCreateInfo,
  })
  const watchDisplayTo = watch('displayTo')
  const watchEnableUnPublish = watch('enableUnPublish')
  const watchIsScheduled = watch('isScheduled')
  const watchscheduledDateTime = watch('scheduledDateTime')

  const {data: getSingleAnnouncementData = {}} = useApiResponse({
    action: getSingleAnnouncement,
    payload: editId,
    dependency: [editId],
    enabled: editId,
    label: GET_SINGLE_ANNOUNCEMENT,
    storePath: 'announcement.getSingleAnnouncementData',
    onSuccess: () => {
      const {title, displayTo, announcement, isScheduled, enableUnPublish, unpublishDate, scheduledDateTime} =
        getSingleAnnouncementData
      setScheduledDateTime(scheduledDateTime)
      setUnpublishDate(unpublishDate)
      reset({
        title,
        displayTo,
        employeeIds: get(['employeeIds'], getSingleAnnouncementData, []),
        departmentIds: get(['departmentIds'], getSingleAnnouncementData, []),
        tagIds: get(['tagIds'], getSingleAnnouncementData, []),
        announcement,
        isScheduled,
        enableUnPublish,
        unpublishDate,
        scheduledDateTime,
      })
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_ANNOUNCEMENT) || successLabels.includes(UPDATE_ANNOUNCEMENT)) {
      history.push('/announcements')
    }
  }, [dispatch, history, successLabels])

  useApiResponse({
    action: getAllEmployees,
    enabled: true,
    label: EMPLOYEES_FOR_ANNOUNCEMENT,
    storePath: 'announcement.getEployeesListData',
    onSuccess: getEmployeeOptionData => {
      const list = getEmployeeOptionData.map(employee => ({
        value: employee.id,
        text: `${employee.name}(${employee.employeeID})`,
        id: employee.id,
      }))
      setEmployeeOption(list)
    },
  })

  useApiResponse({
    action: getAllDepartments,
    enabled: true,
    label: DEPARTMENTS_FOR_ANNOUNCEMENT,
    storePath: 'announcement.getDepartmentsListData',
    onSuccess: getDepartmentData => {
      const list = getDepartmentData.map(department => ({
        value: department.id,
        text: department.department,
        id: department.id,
      }))
      setDepartmentOption(list)
    },
  })

  useApiResponse({
    action: getTagLabel,
    enabled: true,
    label: GET_ALL_TAG_LABEL,
    storePath: 'multipleFields.getTagList',
    onSuccess: getTagList => {
      const list = getTagList.map(label => ({
        value: label.id,
        text: label.label,
        key: label.id,
        label: {
          style: {background: label.color},
          empty: true,
          circular: true,
        },
      }))
      setTagsOption(list)
    },
  })

  const RenderPublishConfirmationModal = () => {
    const submitForm = data => {
      const dataToDispatch = {...data}

      if (publishModalStatus === 1) {
        dataToDispatch.isDraft = true
      } else {
        dataToDispatch.isDraft = false
      }

      const newList = _.omitBy(dataToDispatch, value => !!(value === '' || value === null || _.isEqual(value, [])))

      if (editId) {
        dispatch(updateAnnouncement(editId, newList))
      } else {
        dispatch(CreateAnnouncements(newList))
      }
      setIsPublishModalStatus(false)
    }

    return (
      <VmoModal
        closeIcon={<SvgIcon path="common/close" className="closeIcon" onClick={() => setIsPublishModalStatus(false)} />}
        size="tiny"
        open={publishModalStatus}
        onClose={() => setIsPublishModalStatus(false)}
      >
        <VmoModalHeader>Are you sure?</VmoModalHeader>
        <VmoModalContent>
          <h4>
            Are you sure you want to {publishModalStatus === 1 ? 'Save As Draft ?' : 'publish this announcement ?'}
          </h4>
        </VmoModalContent>
        <VmoModalActions>
          <VmoButton basic onClick={() => setIsPublishModalStatus(false)}>
            Cancel
          </VmoButton>
          <VmoButton primary onClick={handleSubmit(submitForm)}>
            {publishModalStatus === 1 ? 'Save As Draft' : 'Publish'}
          </VmoButton>
        </VmoModalActions>
      </VmoModal>
    )
  }

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    if (files && files[0]) {
      const formData = new FormData()
      formData.append('files[0]', files[0])
      axios({
        method: 'post',
        url: `${KADENCE_ATTACHMENTS}/announcement-image`,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${getAppToken()}`},
      }).then(function (res) {
        const response = {
          result: [
            {
              url: res.data.payload,
            },
          ],
        }
        uploadHandler(response)
      })
    }
  }

  return (
    <div className="kadence-full-page">
      <div className="campaignNavbar">
        <div>
          <VmoButton primary onClick={() => history.push('/announcements')}>
            Exit
          </VmoButton>
        </div>
        <h3 className="m-0">Create Announcement</h3>
        <div>
          <VmoButton primary className="mr-3" onClick={() => setIsPublishModalStatus(1)}>
            Save as draft
          </VmoButton>
          <VmoButton primary onClick={() => setIsPublishModalStatus(2)} disabled={!isDirty}>
            Publish
          </VmoButton>
        </div>
      </div>
      {publishModalStatus && <RenderPublishConfirmationModal />}

      <VmoForm className="errorLabel">
        <VmoContainer>
          <VmoCard fluid>
            <VmoCardContent>
              <h3 className="mb-0">Announcement</h3>
              <p className="mt-0 mb-4 card-description">Create announcement to share any news and updates</p>

              <VmoFormField width={6}>
                <div className="info-header">
                  <label className="label-class">Display to</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="select whom you want to display the announcement"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="displayTo"
                  render={({onChange, value}) => (
                    <VmoDropdown
                      fluid
                      selection
                      options={[
                        {value: 'all', key: 'all', text: 'All'},
                        {value: 'departments', key: 'departments', text: 'Departments'},
                        {value: 'tags', key: 'tags', text: 'Tags'},
                        {value: 'specific', key: 'specific', text: 'Specific Employees'},
                      ]}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.displayTo && {
                          content: removeDoubleQuotes(errors?.displayTo?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              {watchDisplayTo === 'departments' ? (
                <VmoFormField width={6}>
                  <div className="info-header">
                    <label className="label-class">Department</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select specific departments for the announcement"
                      size="mini"
                      position="top center"
                    />
                  </div>

                  <Controller
                    name="departmentIds"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormSelect
                        fluid
                        multiple
                        options={DepartmentOption}
                        selection
                        placeholder="Department Id"
                        onBlur={onBlur}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        error={
                          errors?.departmentIds && {
                            content: removeDoubleQuotes(errors?.departmentIds?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>
              ) : null}
              {watchDisplayTo === 'tags' ? (
                <VmoFormField width={6}>
                  <div className="info-header">
                    <label className="label-class">Tags</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select tags for the announcement"
                      size="mini"
                      position="top center"
                    />
                  </div>

                  <Controller
                    name="tagIds"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormSelect
                        fluid
                        multiple
                        options={TagsOption}
                        selection
                        placeholder="Tag"
                        onBlur={onBlur}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        error={
                          errors?.tagIds && {
                            content: removeDoubleQuotes(errors?.tagIds?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>
              ) : null}
              {watchDisplayTo === 'specific' ? (
                <VmoFormField width={6}>
                  <div className="info-header">
                    <label className="label-class">Specific Employees</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select specific Employees for the announcement"
                      size="mini"
                      position="top center"
                    />
                  </div>

                  <Controller
                    name="employeeIds"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormSelect
                        fluid
                        multiple
                        options={EmployeeOption}
                        selection
                        placeholder="Employee Id"
                        onBlur={onBlur}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        error={
                          errors?.employeeIds && {
                            content: removeDoubleQuotes(errors?.employeeIds?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>
              ) : null}

              <VmoFormField width={6}>
                <div className="info-header">
                  <label className="label-class">Title</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="give a specific title to the announcement"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="title"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      placeholder="Title"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.title && {
                          content: removeDoubleQuotes(errors.title.message),
                        }
                      }
                    />
                  )}
                />{' '}
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Announcement</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="compose your announcement for selected audience"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="announcement"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoEditor
                      setDefaultStyle="font-size: 15px; line-height: 1.5;"
                      setContents={value}
                      onChange={content => onChange(content)}
                      width="100%"
                      setOptions={editorOptions}
                      onImageUploadBefore={handleImageUploadBefore}
                    />
                  )}
                />
              </VmoFormField>
            </VmoCardContent>
          </VmoCard>

          <VmoCard fluid>
            <VmoCardContent>
              <h3 className="mb-0">Announcement Settings</h3>
              <p className="mt-0 mb-4 card-description">Manage settings related to announcement from here</p>

              <VmoFormField>
                <Controller
                  control={control}
                  name="enableUnPublish"
                  render={({onChange, value}) => (
                    <VmoFormField>
                      <div className="d-flex">
                        <div>
                          <label>Enable Schedule</label>
                          <p className="kadence-lead">Announcement will get published during the mentioned time</p>
                        </div>
                        <VmoCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                      </div>
                    </VmoFormField>
                  )}
                />
              </VmoFormField>
              {watchEnableUnPublish ? (
                <VmoFormGroup>
                  <VmoFormField width={6}>
                    <div className="info-header">
                      <label>Schedule Date and Time</label>
                      <VmoTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Set publish date and time"
                        size="mini"
                        position="top center"
                      />
                    </div>
                    <Controller
                      name="scheduledDateTime"
                      render={({onChange, value}) => (
                        <VmoDatePicker
                          type="date"
                          value={typeof value === 'string' ? new Date(ScheduledDateTime) : ScheduledDateTime}
                          inputProps={{readOnly: true}}
                          onChange={value => {
                            onChange(value)
                            setScheduledDateTime(value)
                          }}
                          error={
                            errors?.scheduledDateTime && {
                              content: removeDoubleQuotes(errors?.scheduledDateTime?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </VmoFormField>

                  {/* <VmoFormField width={3}>
                    <div className="info-header">
                      <label>Schedule Time</label>
                      <VmoTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Display to who"
                        size="mini"
                        position="top center"
                      />
                    </div>
                    <VmoDatePicker date={false} />
                  </VmoFormField> */}
                </VmoFormGroup>
              ) : null}

              <VmoFormField>
                <Controller
                  control={control}
                  name="isScheduled"
                  render={({onChange, value}) => (
                    <VmoFormField>
                      <div className="d-flex">
                        <div>
                          <label>Enable Unpublish</label>
                          <p className="kadence-lead">Unpublish your announcement from here</p>
                        </div>
                        <VmoCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                      </div>
                    </VmoFormField>
                  )}
                />
              </VmoFormField>
              {watchIsScheduled ? (
                <VmoFormField width={6}>
                  <div className="info-header">
                    <label>Unpublish Date</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Set date and time for unpublishing"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="unpublishDate"
                    render={({onChange, value}) => (
                      <VmoDatePicker
                        type="date"
                        value={typeof value === 'string' ? new Date(UnpublishDate) : UnpublishDate}
                        inputProps={{readOnly: true}}
                        time={false}
                        min={new Date(watchscheduledDateTime)}
                        onChange={value => {
                          onChange(value)
                          setUnpublishDate(value)
                        }}
                        error={
                          errors?.unpublishDate && {
                            content: removeDoubleQuotes(errors?.unpublishDate?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>
              ) : null}
            </VmoCardContent>
          </VmoCard>
        </VmoContainer>
      </VmoForm>
    </div>
  )
}

export default CreateAnnouncement
