import {
  GET_PREFIX,
  GET_NEW_PO_NUMBER,
  GET_PURCHASE_OREDRS_LIST,
  GET_PURCHASE_ORDERS_DATA,
  CREATE_PURCHASE_ORDERS,
  UPDATE_PURCHASE_ORDERS,
} from 'actions/types'

export default function (
  state = {PurchaseOrderForm: {}, purchaseOrdersData: {}, uploadFile: {selectedFile: null, previewFile: null}},
  action
) {
  switch (action.type) {
    case GET_PREFIX:
      return {
        ...state,
        prefixListData: action.payload,
      }

    case GET_NEW_PO_NUMBER:
      return {
        ...state,
        PurchaseOrderForm: {
          ...state.PurchaseOrderForm,
          poNumber: action.payload,
        },
      }

    case GET_PURCHASE_OREDRS_LIST:
      return {
        ...state,
        purchaseOrdersList: action.payload.poDetail,
        purchaseOrdersPage: action.payload.page,
      }

    case GET_PURCHASE_ORDERS_DATA:
      return {
        ...state,
        purchaseOrdersData: action.payload,
        productsData: action.payload.products,
      }

    case CREATE_PURCHASE_ORDERS:
      return {
        ...state,
        poPublicLink: action.payload,
      }
    case UPDATE_PURCHASE_ORDERS:
      return {
        ...state,
        poPublicLink: action.payload,
      }

    case 'CLEAR_PO_FORM':
      return {
        ...state,
        PurchaseOrderForm: {},
      }

    case 'CLEAR_PURCHASE_ORDERS_DATA':
      return {
        ...state,
        purchaseOrdersData: {},
      }

    case 'SETUP_SUBMIT':
      return {
        ...state,
        PurchaseOrderForm: {
          ...state.PurchaseOrderForm,
          setupData: action.payload,
        },
      }

    case 'PRODUCT_HANDLE_SUBMIT':
      return {
        ...state,
        PurchaseOrderForm: {
          ...state.PurchaseOrderForm,
          products: action.payload,
        },
      }

    case 'CUSTOMIZATION_SUBMIT':
      return {
        ...state,
        PurchaseOrderForm: {
          ...state.PurchaseOrderForm,
          template: action.payload,
        },
      }

    // * file/logo upload and views
    case 'SET_PO_PREVIEW_FILE':
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          previewFile: action.payload,
        },
      }

    case 'SET_PO_SELECTED_FILE': {
      // let prevFile = action.payload.get('logo')
      // const objectUrl = URL.createObjectURL(prevFile);

      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          selectedFile: typeof action.payload === 'string' ? 'uploaded logo' : action.payload.get('logo'),
        },
      }
    }

    case 'CLEAR_PO_UPLOADFILE':
      return {
        ...state,
        uploadFile: {selectedFile: null, previewFile: null},
      }

    default:
      return state
  }
}
