import {GET_ALL_APPRAISAL_TEMPLATE, GET_APPRAISAL_TEMPLATE_DATA, GET_REVEIWER_LIST} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_APPRAISAL_TEMPLATE:
      return {
        ...state,
        getAllAppraisalTemplateList: action.payload.appraisalTemplates,
      }
    case GET_APPRAISAL_TEMPLATE_DATA:
      return {
        ...state,
        appraisalTemplateData: action.payload,
      }
    case GET_REVEIWER_LIST:
      return {
        ...state,
        reveiwerList: action.payload,
      }
    default:
      return state
  }
}
