import {
  TOTAL_REVENUE_BY_PRODUCTS,
  TOTAL_QUANTITY_SOLD_BY_PRODUCTS,
  CURRENT_DEAL_BY_PRODUCTS,
  WON_DEALS_BY_PRODUCTS,
  CONTACT_BY_PRODUCTS,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case WON_DEALS_BY_PRODUCTS:
      return {
        ...state,
        wonDealsByProductsData: action.payload,
      }
    case TOTAL_REVENUE_BY_PRODUCTS:
      return {
        ...state,
        totalRevenueByProductsData: action.payload,
      }
    case TOTAL_QUANTITY_SOLD_BY_PRODUCTS:
      return {
        ...state,
        totalQuantitySoldByProductData: action.payload,
      }
    case CURRENT_DEAL_BY_PRODUCTS:
      return {
        ...state,
        currentDealByProductsData: action.payload,
      }
    case CONTACT_BY_PRODUCTS:
      return {
        ...state,
        newLeadsOrContactByProductData: action.payload,
      }

    default:
      return state
  }
}
