import {createContext, useState} from 'react'

export const EmailBodyContext = createContext(null)

const EmailBodyContextProvider = ({children}) => {
  const [editorOpenFor, setEditorOpenFor] = useState('')
  const [editorData, setEditorData] = useState({
    from: '',
    to: [],
    cc: [],
    bcc: [],
    editorValue: '',
    subject: '',
  })

  return (
    <EmailBodyContext.Provider value={{editorOpenFor, setEditorOpenFor, setEditorData, editorData}}>
      {children}
    </EmailBodyContext.Provider>
  )
}
export default EmailBodyContextProvider
