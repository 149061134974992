import React, {useState} from 'react'
import {useHistory} from 'react-router'
import {getTimingScheme} from 'actions/settings_automations'
import CreateFirst from 'components/common/CreateFirst'
import {GET_TIMING_SCHEME} from 'actions/types'
import create from 'assets/create-rules.svg'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {getPermissionTooltipContent} from 'utils/helper'
import useApiResponse from 'hooks/impure/useApiResponse'
import BusinessHoursForm from './BusinessHoursForm'

const BusinessHours = () => {
  const history = useHistory()

  const [createBuss, setCreateBuss] = useState(false)
  const [buss, setBuss] = useState(false)

  const {isLoading} = useApiLoader({label: GET_TIMING_SCHEME})
  const {automationPermissions} = useUserPermissions()
  const manageBussinesHoursPermissions = automationPermissions?.manage_bussines_hours

  useApiResponse({
    action: getTimingScheme,
    enabled: true,
    label: GET_TIMING_SCHEME,
    storePath: 'settingAutomation.timingScheme',
    onSuccess: timingScheme => {
      const isBusinessHours = Boolean(timingScheme)
      setBuss(isBusinessHours)
      if (isBusinessHours) {
        history.push(`/settings/business-hours/${timingScheme.id}`)
      }
    },
  })

  if (isLoading) {
    return <PageLoader />
  }

  if (createBuss) {
    return <BusinessHoursForm toggleCreate={setCreateBuss} />
  }

  if (!buss) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="Business Hours"
          subHeader="Business hours helps to specify the working hours of your helpdesk for customer support"
          addFirst={() => manageBussinesHoursPermissions && setCreateBuss(true)}
          buttonText="Add New Business Hours"
          tooltip={!manageBussinesHoursPermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: manageBussinesHoursPermissions})}
          Feature1="Business Hours Definition"
          list1="Set working hours for different days of the week"
          Feature2="Holiday and Non-Working Days"
          list2="Mention holidays when the help desk is closed"
          Feature3="Time Zone Support"
          list3="Set work hours based on timezone you operate"
          imageSrc={create}
        />
      </div>
    )
  }
  return null
}
export default BusinessHours
