import React from 'react'
import create from 'assets/create.svg'
import CreateFirst from 'components/common/CreateFirst'
import {FaBriefcase} from 'react-icons/fa'

const Payroll = () => {
  return (
    <CreateFirst
      src={<FaBriefcase />}
      header="Payroll"
      subHeader="Payroll help organization to manage, maintain and automate payments to employee"
      addFirst={() => null}
      buttonText="Create Payroll"
      Feature1="Create payroll"
      Feature2="Tax filing"
      Feature3="Direct Deposits"
      list1="Create payrolls for multiple organization at same time"
      list2="Add tax to payrolls automatically & reduce error"
      list3="Pay salary directly to employees bank account"
      imageSrc={create}
    />
  )
}

export default Payroll
