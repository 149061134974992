import {apiAction} from './api'
import {KADENCE_ANALYTICS} from './endpoint'
import {ADDED_BY_SOURCE_OVERTIME, ADDED_VS_DELETED, CONTACT_STATS} from './types'

export function statsContacts(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/contacts/contactstats`,
    method: 'POST',
    label: CONTACT_STATS,
    data,
  })
}

export function addedVsDeletedContacts(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/contacts/addedvsdeleted`,
    method: 'POST',
    label: ADDED_VS_DELETED,
    data,
  })
}

export function addedBySourceOvertimeContacts(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/contacts/addedbysourceovertime`,
    method: 'POST',
    label: ADDED_BY_SOURCE_OVERTIME,
    data,
  })
}
