import {
  EXPENSES_STATS,
  EXPENSES_VALUE_STATS,
  EXPENSES_BREAKDOWN_BY_CATEGORY,
  EXPENSES_BREAKDOWN_BY_STATUS,
  EXPENSES_BY_DEPARTMENT,
  EXPENSES_STATUS_TIMETREND,
  EXPENSES_CATEGORY_TIMETREND,
  EXPENSES_TIMETREND_BY_DEPARTMENT,
  EXPENSES_EMPLOYEE_STATS,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case EXPENSES_STATS:
      return {
        ...state,
        expensesCountStatsData: action.payload,
      }
    case EXPENSES_VALUE_STATS:
      return {
        ...state,
        expensesValueStatsData: action.payload,
      }
    case EXPENSES_BREAKDOWN_BY_CATEGORY:
      return {
        ...state,
        BreakDownByCateogoryCountData: action.payload.formatedCountData,
        BreakDownByCateogoryValueData: action.payload.formatedValueData,
      }
    case EXPENSES_BREAKDOWN_BY_STATUS:
      return {
        ...state,
        BreakDownByStatusCountData: action.payload.formattedCountData,
        BreakDownByStatusValueData: action.payload.formattedValueData,
      }
    case EXPENSES_BY_DEPARTMENT:
      return {
        ...state,
        expensesByDepartmentData: action.payload,
      }
    case EXPENSES_STATUS_TIMETREND:
      return {
        ...state,
        expensesStatusTimetrendData: action.payload,
      }
    case EXPENSES_CATEGORY_TIMETREND:
      return {
        ...state,
        expensesCategoryTimetrendData: action.payload,
      }
    case EXPENSES_TIMETREND_BY_DEPARTMENT:
      return {
        ...state,
        TimetrendByDepartmentData: action.payload,
      }
    case EXPENSES_EMPLOYEE_STATS:
      return {
        ...state,
        ExpensesEmployeeStatsData: action.payload,
      }
    default:
      return state
  }
}
