/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import _ from 'lodash'
import Moment from 'moment'

import {
  VmoButton,
  VmoButtonGroup,
  VmoFlag,
  VmoHeader,
  VmoHeaderSubheader,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableFooter,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {GET_LIVEUSER_CONTACTS, START_NEW_CONVERSATION, UPDATE_CONTACT_LIST} from 'actions/types'
import {getLiveCount, getLiveUserContacts, startNewConversation} from 'actions/liveVisitors'
import {get, getSortType, wrapText} from 'utils/helper'
import KadenceCustomPagination from 'components/common/KadenceCustomPagination'
import SvgIcon from 'components/common/SvgIcon'

const LiveVisitors = ({location}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const Page = location.state

  const [numberOfPage, setNumberOfPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [isButtonClick, setIsButtonClick] = useState(0)
  const [isModelOpen, setIsModelOpen] = useState(false)
  const [startNewConversationId, setStartNewConversationId] = useState(null)
  const [sort, setSort] = useState({
    sortBy: '',
    sortType: '',
  })

  const {
    getLiveUserContactData: liveUserContactList = [],
    getLiveUserNumberOfPages: liveUserPages = {},
    getLiveCount: liveCount = {},
  } = useSelector(state => state.liveVisitors)
  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    dispatch(getLiveUserContacts({page: Page || 1, online: true}))
    dispatch(getLiveCount())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(START_NEW_CONVERSATION)) {
      history.push(`/inbox`)
    }

    if (successLabels.includes(GET_LIVEUSER_CONTACTS || UPDATE_CONTACT_LIST)) {
      setNumberOfPage(liveUserPages.totalPages)
      setCurrentPage(0)
    }
  }, [successLabels])

  useEffect(() => {
    if (sort.sortBy || sort.text) {
      const newList = _.omitBy({...sort, online: true, page: currentPage}, value => {
        if (value === 'none' || value === '' || !value) {
          return true
        }
        return false
      })
      dispatch(getLiveUserContacts(newList))
    }
  }, [sort])

  const renderConformModel = () => {
    return (
      <VmoModal
        size="tiny"
        open={isModelOpen}
        centered={false}
        closeIcon={
          <SvgIcon
            path="common/close"
            className="closeIcon"
            onClick={() => {
              setIsModelOpen(false)
              setStartNewConversationId(null)
            }}
          />
        }
      >
        <VmoModalHeader className="vmoModalHeader">Confirmation</VmoModalHeader>
        <VmoModalContent>
          <h5>Do you wish to start a new conversation with this user?</h5>
        </VmoModalContent>
        <VmoModalActions>
          <VmoButton
            basic
            onClick={() => {
              setStartNewConversationId(null)
              setIsModelOpen(false)
            }}
          >
            No
          </VmoButton>
          <VmoButton
            primary
            onClick={() => {
              if (startNewConversationId) {
                dispatch(startNewConversation({contactId: startNewConversationId}))
              }
              setStartNewConversationId(null)
            }}
          >
            Yes
          </VmoButton>
        </VmoModalActions>
      </VmoModal>
    )
  }

  const renderFooterPages = () => {
    return (
      <VmoTableFooter>
        <VmoTableRow>
          <VmoTableHeaderCell colSpan="8" style={{textAlign: 'center'}}>
            <KadenceCustomPagination
              activePage={numberOfPage}
              totalPages={currentPage}
              onPageChange={page =>
                setSort(sort => ({
                  ...sort,
                  page,
                }))
              }
            />
          </VmoTableHeaderCell>
        </VmoTableRow>
      </VmoTableFooter>
    )
  }

  const renderVisitorsList = () => {
    if (isLoadingData && type.includes(GET_LIVEUSER_CONTACTS)) {
      return <VmoTablePlaceholder columns={8} />
    }

    if (!isLoadingData && liveUserContactList.length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="8" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }

    return liveUserContactList.map((contact, index) => (
      <VmoTableRow key={index} className="tableLink">
        <VmoTableCell>{index + 1}</VmoTableCell>
        <VmoTableCell>
          <VmoHeader as="h5">
            {contact.firstName
              ? wrapText(`${get(['firstName'], contact, '-')} ${get(['lastName'], contact, ' ')}`, 13)
              : contact.id}
            <VmoHeaderSubheader>{get(['email'], contact, '-')}</VmoHeaderSubheader>
          </VmoHeader>
        </VmoTableCell>
        <VmoTableCell>
          {get(['city'], contact, '-')}
          <VmoHeaderSubheader>
            {get(['country'], contact, null) ? <VmoFlag name={contact.country.toLowerCase()} /> : null}
            {get(['country'], contact, '-')}
          </VmoHeaderSubheader>
        </VmoTableCell>
        <VmoTableCell>{get(['totalVisit'], contact, '-')}</VmoTableCell>
        <VmoTableCell>
          <VmoTooltip
            position="top center"
            size="mini"
            content={get(['pageUrl'], contact, '-')}
            trigger={
              <a target="_blank" href={get(['pageUrl'], contact, '')} rel="noopener noreferrer">
                {wrapText(get(['pageUrl'], contact, '-'), 25)}
              </a>
            }
          />
        </VmoTableCell>
        <VmoTableCell>{contact.duration ? Moment(contact.duration).fromNow() : '-'}</VmoTableCell>
        <VmoTableCell>
          <VmoTooltip
            position="top center"
            size="mini"
            content={get(['referralUrl'], contact, '-')}
            trigger={
              <a target="_blank" href={get(['referralUrl'], contact, '-')} rel="noopener noreferrer">
                {wrapText(get(['referralUrl'], contact, '-'), 25)}
              </a>
            }
          />
        </VmoTableCell>
        <VmoTableCell>
          <VmoButton
            primary
            onClick={() => {
              setIsModelOpen(true)
              setStartNewConversationId(contact.id)
            }}
          >
            Start Chat
          </VmoButton>
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="kadence-full-wrap">
      {isModelOpen && renderConformModel()}
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="inbox/user" />
            <h5>
              Live Users (<span>{get(['totalItems'], liveUserPages, 0)} Users</span>)
            </h5>
          </div>
        </div>
        <div className="page-action">
          <VmoButtonGroup basic>
            <VmoButton
              active={isButtonClick === 0}
              onClick={() => {
                dispatch(getLiveUserContacts({page: Page || 1, online: true}))
                setIsButtonClick(0)
              }}
            >
              All : {get(['totalCounts'], liveCount, '-')}
            </VmoButton>
            <VmoButton
              active={isButtonClick === 2}
              onClick={() => {
                dispatch(
                  getLiveUserContacts({
                    page: Page || 1,
                    online: true,
                    contactType: 2,
                  })
                )
                setIsButtonClick(2)
              }}
            >
              Repeat : {get(['repeatCounts'], liveCount, '-')}
            </VmoButton>
            <VmoButton
              active={isButtonClick === 1}
              onClick={() => {
                dispatch(
                  getLiveUserContacts({
                    page: Page || 1,
                    online: true,
                    contactType: 1,
                  })
                )
                setIsButtonClick(1)
              }}
            >
              New : {get(['newCounts'], liveCount, '-')}
            </VmoButton>
          </VmoButtonGroup>
        </div>
      </div>

      <VmoTable sortable basic selectable>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>#</VmoTableHeaderCell>
            <VmoTableHeaderCell
              sorted={sort.sortBy === 'name' && getSortType(sort.sortType)}
              onClick={() =>
                setSort({
                  sortBy: 'name',
                  sortType: sort.sortType === 'asc' ? 'desc' : 'asc',
                })
              }
            >
              Visitor
            </VmoTableHeaderCell>
            <VmoTableHeaderCell>Location</VmoTableHeaderCell>
            <VmoTableHeaderCell>Total visits</VmoTableHeaderCell>
            <VmoTableHeaderCell>Page</VmoTableHeaderCell>
            <VmoTableHeaderCell>Duration</VmoTableHeaderCell>
            <VmoTableHeaderCell>Referral URL</VmoTableHeaderCell>
            <VmoTableHeaderCell>Start Chat</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>{renderVisitorsList()}</VmoTableBody>

        {numberOfPage > 1 && renderFooterPages()}
      </VmoTable>
    </div>
  )
}

export default LiveVisitors
