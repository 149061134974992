export const compareList = [
  {key: 'purchase_order', value: 'purchase_order', text: 'PurchaseOrder'},
  {key: 'sales_order', value: 'sales_order', text: 'SalesOrder'},
  {key: 'invoices', value: 'invoices', text: 'Invoice'},
  {key: 'quotes', value: 'quotes', text: 'Quotes'},
]

export const RangeBreakdown = [
  {key: 'month', value: 'month', text: 'Month'},
  {key: 'quarter', value: 'quarter', text: 'Quarter '},
  {key: 'year', value: 'year', text: 'Year'},
  // {key: 'total', value: 'total', text: 'Total'},
]

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
