import Joi from 'joi'
import {inputStringValidation} from 'validation'

export const createHolidayGroupSchema = Joi.object({
  name: inputStringValidation.required(),
  description: Joi.string().required().max(200).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
})

export const createHolidaySchema = Joi.object({
  name: inputStringValidation.required(),
  date: Joi.date().iso().required(),
})
