import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoEditorSmall,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormRadio,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoRadio,
  VmoTooltip,
} from 'vmo-library'

import {GET_ALL_MAIL_INBOX, GET_CLIENT_PORTAL_SETTINGS, UPDATE_CLIENT_PORTAL_SETTINGS} from 'actions/types'
import {getClientPortalSettings, updateClientPortalSettings} from 'actions/client-portal'
import {get, removeDoubleQuotes} from 'utils/helper'
import SettingLayout from 'layouts/settingLayout'
import {getAllMailInbox} from 'actions/inbox'
import _ from 'lodash'
import {generalSchema} from 'validation/Customers/clientPortal.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const generalDefaultValues = {
  // enableClientPortal: false,
  enableWellcomeText: false,
  wellcomeText: '',
  enablePortalIcon: false,
  enableTicket: false,
  ticketStatusView: {
    viewTicketsOf: 'contact',
    defaultInbox: [],
  },
  enableAnnouncements: false,
  enableKnowledgebase: false,
  docId: null,
  enableProject: false,
  enableInvoiceAndQuotes: false,
  showQuickLinks: false,
  quickLinks: [
    // {
    //   name: '',
    //   url: '',
    // },
  ],
}

const ClientPortalModal = ({isModalOpen, setIsModalOpen, enableClientPortal}) => {
  const dispatch = useDispatch()

  const submitData = () => {
    const data = {enableClientPortal: !enableClientPortal}
    dispatch(updateClientPortalSettings(data))
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      centered={false}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <VmoModalHeader className="vmoModalHeader">Client Portal</VmoModalHeader>
      <VmoModalContent>{`Are you sure you want to ${
        enableClientPortal ? 'Disable' : 'Enable'
      } Client Portal`}</VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsModalOpen(false)}>
          No
        </VmoButton>
        <VmoButton primary onClick={submitData}>
          Yes
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

const General = () => {
  const dispatch = useDispatch()

  const [inboxList, setInboxList] = useState([])
  const [enableClientPortal, setEnableClientPortal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {generalSetting = {}} = useSelector(state => state.clientPortal)
  const {inbox = []} = useSelector(state => state.mailInbox)

  const {clientPortalPermissions} = useUserPermissions()
  const settingsPermissions = clientPortalPermissions?.settings

  const {
    handleSubmit,
    control,
    errors,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: joiResolver(generalSchema),
    defaultValues: generalDefaultValues,
  })

  const {
    fields: quickLinksFields,
    append: quickLinksAppend,
    remove: quickLinksRemove,
  } = useFieldArray({
    name: 'quickLinks',
    control,
  })

  const watchEnableTicket = watch('enableTicket')
  const watchEnableKnowledgebase = watch('enableKnowledgebase')
  const watchEnableWellcomeText = watch('enableWellcomeText')
  const watchShowQuickLinks = watch('showQuickLinks')

  useEffect(() => {
    dispatch(getClientPortalSettings())
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllMailInbox())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(UPDATE_CLIENT_PORTAL_SETTINGS)) {
      dispatch(getClientPortalSettings())
      setIsModalOpen(false)
    }
  }, [successLabels, dispatch])

  const resetData = useCallback(
    setting => {
      const data = {
        // enableClientPortal: get(['enableClientPortal'], setting, false),
        enableWellcomeText: get(['enableWellcomeText'], setting, false),
        wellcomeText: get(['wellcomeText'], setting, ''),
        enablePortalIcon: get(['enablePortalIcon'], setting, false),
        enableTicket: get(['enableTicket'], setting, false),
        ticketStatusView: {
          defaultInbox: get(['ticketStatusView', 'defaultInbox'], setting, []),
          viewTicketsOf: get(['ticketStatusView', 'viewTicketsOf'], setting, 'contact'),
        },
        enableAnnouncements: get(['enableAnnouncements'], setting, false),
        enableKnowledgebase: get(['enableKnowledgebase'], setting, false),
        docId: get(['docId'], setting, ''),
        enableProject: get(['enableProject'], setting, false),
        enableInvoiceAndQuotes: get(['enableInvoiceAndQuotes'], setting, false),
        showQuickLinks: get(['showQuickLinks'], setting, false),
        quickLinks: setting.quickLinks ? JSON.parse(setting.quickLinks) : [{name: '', url: ''}],
      }
      setEnableClientPortal(get(['enableClientPortal'], setting, false))
      reset(data)
    },
    [reset]
  )

  useEffect(() => {
    if (successLabels.includes(GET_CLIENT_PORTAL_SETTINGS)) {
      resetData(generalSetting)
    }
  }, [successLabels, generalSetting, resetData])

  const submitForm = data => {
    if (data.quickLinks) {
      data.quickLinks = JSON.stringify(data.quickLinks)
    }
    const payload = {...data, enableClientPortal}
    dispatch(updateClientPortalSettings(payload))
  }

  return (
    <>
      <SettingLayout
        header="General Settings"
        subHeader="Manage General settings related to client portal"
        headerRadioButton={{
          checked: enableClientPortal,
          onChange: (e, {checked}) => {
            // setEnableClientPortal(checked)
            setIsModalOpen(true)
          },
        }}
        table={false}
        headerDivider
        actionButton={{
          cancel: {
            content: 'Reset',
            disabled: !isDirty && enableClientPortal === get(['enableClientPortal'], generalSetting, false),
            onClick: () => resetData(generalSetting),
          },
          success: {
            content: 'Update',
            disabled: !isDirty && enableClientPortal === get(['enableClientPortal'], generalSetting, false),
            ...(settingsPermissions && {
              onClick: handleSubmit(submitForm),
            }),
          },
        }}
        lockRole={!settingsPermissions}
      >
        <VmoForm className="errorLabel">
          {/* <VmoCard fluid>
          <VmoCardContent>
            <div className="generalCardHeading">
              <div>
                <h5 className="mb-1">Customer Portal</h5>
                <p className="kadence-lead">Lorem ipsum dolor sit amet.</p>
              </div>
              <Controller
                name="enableClientPortal"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                )}
              />
            </div>
          </VmoCardContent>
        </VmoCard> */}

          <VmoCard fluid>
            <VmoCardContent>
              <div className="generalCardHeading">
                <div>
                  <h5 className="mb-1">Welcome Text</h5>
                  <p className="kadence-lead">Add a welcome text in your client portal</p>
                </div>
                <Controller
                  name="enableWellcomeText"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </div>
              {watchEnableWellcomeText && (
                <Controller
                  name="wellcomeText"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoEditorSmall
                      setContents={value}
                      onChange={content => onChange(content)}
                      width="100%"
                      setOptions={{
                        buttonList: [
                          ['font', 'fontSize', 'bold', 'underline', 'italic', 'fontColor', 'hiliteColor', 'indent'],
                        ],
                      }}
                    />
                  )}
                />
              )}
            </VmoCardContent>
          </VmoCard>

          <VmoCard fluid>
            <VmoCardContent>
              <div className="generalCardHeading">
                <div>
                  <h5 className="mb-1">Portal Icon</h5>
                  <p className="kadence-lead">Show client portal icon to customers</p>
                </div>
                <Controller
                  name="enablePortalIcon"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </div>
            </VmoCardContent>
          </VmoCard>

          <VmoCard fluid>
            <VmoCardContent>
              <div className="generalCardHeading">
                <div>
                  <h5 className="mb-1">Ticket Status</h5>
                  <p className="kadence-lead">View status of the ticket</p>
                </div>
                <Controller
                  name="enableTicket"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </div>
              {watchEnableTicket && (
                <>
                  <VmoFormField className="mt-2">
                    <div className="info-header">
                      <label className="label-class">Ticket Information</label>
                      <VmoTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Show the detailed ticket information"
                        size="mini"
                        position="top center"
                      />
                    </div>
                    <Controller
                      name="ticketStatusView.viewTicketsOf"
                      control={control}
                      render={({value, onChange}) => (
                        <>
                          <VmoRadio
                            label="Allow customers to view tickets that are associated with them"
                            value="contact"
                            checked={value === 'contact'}
                            onChange={(e, {value}) => onChange(value)}
                          />{' '}
                          <VmoRadio
                            label="Allow customers to view any ticket associated with their company"
                            value="company"
                            checked={value === 'company'}
                            onChange={(e, {value}) => onChange(value)}
                          />
                        </>
                      )}
                    />
                  </VmoFormField>
                  <VmoFormField width={6}>
                    <div className="info-header">
                      <label className="label-class">Default Inbox</label>
                      <VmoTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Select inbox for receiving tickets"
                        size="mini"
                        position="top center"
                      />
                    </div>
                    <Controller
                      name="ticketStatusView.defaultInbox"
                      control={control}
                      render={({value, onChange}) => (
                        <VmoFormSelect
                          fluid
                          multiple
                          placeholder="Select Inbox"
                          options={inboxList}
                          value={value}
                          onChange={(e, {value}) => onChange(value)}
                          error={
                            errors.ticketStatusView?.defaultInbox && {
                              content: removeDoubleQuotes(errors.ticketStatusView.defaultInbox.message),
                            }
                          }
                        />
                      )}
                    />
                  </VmoFormField>
                </>
              )}
            </VmoCardContent>
          </VmoCard>

          <VmoCard fluid>
            <VmoCardContent>
              <div className="generalCardHeading">
                <div>
                  <h5 className="mb-1">Announcement</h5>
                  <p className="kadence-lead">Show announcement to customers in client portal</p>
                </div>
                <Controller
                  name="enableAnnouncements"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </div>
            </VmoCardContent>
          </VmoCard>

          <VmoCard fluid>
            <VmoCardContent>
              <div className="generalCardHeading">
                <div>
                  <h5 className="mb-1">Project Status</h5>
                  <p className="kadence-lead">Display status of your project</p>
                </div>
                <Controller
                  name="enableProject"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </div>
            </VmoCardContent>
          </VmoCard>
          <VmoCard fluid>
            <VmoCardContent>
              <div className="generalCardHeading">
                <div>
                  <h5 className="mb-1">Invoice and quotes</h5>
                  <p className="kadence-lead">Display all invoice and quotes in client portal</p>
                </div>
                <Controller
                  name="enableInvoiceAndQuotes"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </div>
            </VmoCardContent>
          </VmoCard>
          <VmoCard fluid>
            <VmoCardContent>
              <div className="generalCardHeading">
                <div>
                  <h5 className="mb-1">Custom Links</h5>
                  <p className="kadence-lead">Add custom URL to portal</p>
                </div>
                <Controller
                  name="showQuickLinks"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </div>

              {watchShowQuickLinks && (
                <>
                  {quickLinksFields.map(({id, name, url}, index) => (
                    <div key={id} className="d-flex quick-links-chat" style={{justifyContent: 'flex-start'}}>
                      <VmoFormField width={6}>
                        <Controller
                          name={`quickLinks[${index}].name`}
                          control={control}
                          defaultValue={name}
                          render={({value, onChange}) => (
                            <VmoFormInput
                              maxLength={20}
                              className="mb-0"
                              placeholder="Enter name"
                              value={value}
                              onChange={(e, {value}) => onChange(value)}
                              error={
                                _.get(errors, `quickLinks[${index}].name`) && {
                                  content: removeDoubleQuotes(errors.quickLinks[index].name.message),
                                }
                              }
                            />
                          )}
                        />
                      </VmoFormField>
                      <VmoFormField width={6} className="mx-2">
                        <Controller
                          name={`quickLinks[${index}].url`}
                          control={control}
                          defaultValue={url}
                          render={({value, onChange}) => (
                            <VmoFormInput
                              maxLength={200}
                              className="mb-0"
                              placeholder="Enter url"
                              value={value}
                              onChange={(e, {value}) => onChange(value)}
                              error={
                                _.get(errors, `quickLinks[${index}].url`) && {
                                  content: removeDoubleQuotes(errors.quickLinks[index].url.message),
                                }
                              }
                            />
                          )}
                        />
                      </VmoFormField>
                      <VmoTooltip
                        size="mini"
                        position="top center"
                        content="Delete"
                        trigger={
                          <VmoButton
                            className="mb-3"
                            disabled={!(quickLinksFields.length > 1)}
                            icon
                            onClick={() => quickLinksRemove(index)}
                          >
                            <SvgIcon path="common/delete" />
                          </VmoButton>
                        }
                      />
                    </div>
                  ))}
                  {quickLinksFields.length < 3 && (
                    <VmoTooltip
                      content="Add"
                      size="mini"
                      position="top center"
                      trigger={
                        <VmoButton
                          basic
                          icon
                          className="mt-3"
                          onClick={() =>
                            quickLinksAppend({
                              name: '',
                              url: '',
                            })
                          }
                        >
                          <SvgIcon path="common/plus" />
                        </VmoButton>
                      }
                    />
                  )}
                </>
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoForm>
      </SettingLayout>

      {isModalOpen && (
        <ClientPortalModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          enableClientPortal={enableClientPortal}
        />
      )}
    </>
  )
}

export default General
