import Joi from 'joi'
import {
  emailValidation,
  inputNumberValidation,
  inputStringValidation,
  inputPhoneValidation,
  textAreaValidation,
} from 'validation'

export const createVendorSchema = Joi.object({
  address: Joi.object({
    streetAddress: Joi.any().allow('', null).label('Street'),
    locality: Joi.any().allow('', null).label('Locality'),
    state: Joi.string().allow('', null).label('State'),
    country: Joi.string().allow('', null).label('Country'),
    postalCode: Joi.string().allow(null, '').label('Postal code'),
  }),
  name: inputStringValidation.required(),
  assigneeId: inputNumberValidation.required().label('Owner'),
  description: Joi.string().trim().max(160).allow('', null).messages({
    'string.max': `Length must be less than or equal to 160 characters`,
  }),
  email: emailValidation.required(),
  phone: inputPhoneValidation.allow('', null),
  website: Joi.string().uri().allow('', null),
  categoryId: Joi.number().label('Category').messages({
    'number.base': `Category must be a required field`,
  }),
  fields: Joi.any(),
})

export const createVendorsCategorySchema = Joi.object({
  category: inputStringValidation.required().messages({
    'string.empty': `category cannot be an empty field`,
    'any.required': `category is a required field`,
    'string.min': `category should have a minimum length of 2`,
    'string.max': `category should have length of 20 characters or less`,
  }),
})

export const createVendorsFieldSchema = Joi.object({
  name: inputStringValidation.required().messages({
    'string.empty': `Name cannot be an empty field`,
    'any.required': `Name is a required field`,
    'string.min': `Name should have a minimum length of 2`,
    'string.max': `Name should have length of 20 characters or less`,
  }),
  type: inputStringValidation.required(),
  required: Joi.boolean(),
  categoryIds: Joi.array().min(1).messages({
    'array.empty': `Category Id cannot be an empty field`,
    'any.required': `Category Id is a required field`,
    'array.min': `Category Id should have a minimum length of 1`,
  }),
  description: textAreaValidation.allow('', null),
  helpText: textAreaValidation.allow('', null),
  value: inputStringValidation,
})
