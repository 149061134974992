import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {getSLAPolicy} from 'actions/settings_automations'
import {GET_SLA} from 'actions/types'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create.svg'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {getPermissionTooltipContent} from 'utils/helper'
import useApiResponse from 'hooks/impure/useApiResponse'
import SlaForm from './SlaForm'

const SLA = () => {
  const history = useHistory()

  const {workflowAndAutomationAccess, automationPermissions} = useUserPermissions()
  const slaManagementAccess = workflowAndAutomationAccess?.slaManagement
  const manageSlaPermissions = automationPermissions?.manage_sla

  const [createSla, setCreateSla] = useState(false)
  const [sla, setSla] = useState(false)

  const {isLoading} = useApiLoader({label: GET_SLA})

  useApiResponse({
    action: getSLAPolicy,
    enabled: slaManagementAccess,
    label: GET_SLA,
    storePath: 'settingAutomation.slaGet',
    onSuccess: slaGet => {
      const isSla = Boolean(slaGet)
      setSla(isSla)
      if (isSla) {
        history.push(`/settings/sla-policy/${slaGet.id}`)
      }
    },
  })

  if (isLoading && slaManagementAccess) {
    return <PageLoader />
  }

  if (createSla) {
    return <SlaForm toggleModal={setCreateSla} />
  }

  if (!sla || !slaManagementAccess) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="SLA"
          subHeader="Provide consistent and high-quality customer support, helping to meeting customers expectation"
          addFirst={() => slaManagementAccess && manageSlaPermissions && setCreateSla(true)}
          buttonText="Add SLA"
          tooltip={!slaManagementAccess || !manageSlaPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: slaManagementAccess,
            roleAccess: manageSlaPermissions,
          })}
          Feature1="SLA Definition"
          Feature2="SLA Escalations"
          Feature3="Response and Resolution Tracking"
          list1="Define SLAs based on expected response time and resolution time"
          list2="Add Escalation email to receive every update on SLA"
          list3="Tracks the elapsed time against the SLA targets easily"
          imageSrc={create}
        />
      </div>
    )
  }

  return null
}
export default SLA
