import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {useHistory} from 'react-router'
import {VmoButton, VmoCard, VmoCardContent, VmoGrid, VmoGridColumn, VmoImage} from 'vmo-library'
import {GOOGLE_CALENDAR_DISCONNECT} from 'actions/types'
import {googleCalendarAuthCheck, googleCalendarDisconnect} from 'actions/meeting'
import create from 'assets/calendar_dutt.svg'
import {get} from 'utils/helper'

const GoogleCalendar = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {userData = {}} = useSelector(state => state.meetings)

  const logout = (res, req) => {
    dispatch(googleCalendarDisconnect())
  }

  useEffect(() => {
    if (!get(['GCAL'], JSON.parse(window.localStorage.getItem('kadence')))) {
      history.push('/settings/meetings')
    }
    dispatch(googleCalendarAuthCheck())
  }, [dispatch, history])

  useEffect(() => {
    if (successLabels.includes(GOOGLE_CALENDAR_DISCONNECT)) {
      localStorage.removeItem('GCAL')
      history.push('/settings/meetings')
    }
  }, [successLabels, history])

  // const email = localStorage.getItem("GEMAIL");

  return (
    <div className="vmoSideBar-wrap">
      <VmoCard fluid>
        <VmoCardContent>
          <div className="createPage">
            <h2>Meetings</h2>
            <VmoGrid columns="equal">
              <VmoGridColumn>
                <div className="createPageInfo">
                  <p>{`Now connected to Google Calendar account ${_.get(userData, 'email') || ''}`}</p>
                  <p>
                    Calendar sync Calendar sync logs new meetings from your calendar to existing contacts in Veemo. You
                    can also create and send meeting invites from Veemo. This calendar setting is separate from the
                    meetings tool.
                  </p>
                  <p>{`${_.get(userData, 'family_name') || ''}`}</p>
                  <VmoButton onClick={() => logout()}>Disconnect</VmoButton>
                </div>
              </VmoGridColumn>
              <VmoGridColumn>
                <VmoImage src={create} />
              </VmoGridColumn>
            </VmoGrid>
          </div>
        </VmoCardContent>
      </VmoCard>
    </div>
  )
}

export default GoogleCalendar
