import React, {useEffect, useState} from 'react'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoDivider,
  VmoIcon,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableFooter,
  VmoTableRow,
} from 'vmo-library'
import {useDispatch, useSelector} from 'react-redux'
import {MOVE_PROJECT_FINANCE_BILLING_ITEMS, UPDATE_PROJECT_FINANCE_BILLING_INVOICE} from 'actions/types'
import {
  completeProjectFinanceBillingInvoice,
  getProjectFinanceBillingInvoiceLogs,
  getProjectFinanceBillingOpenInvoicesList,
} from 'actions/projects'
import {KADENCE_PROJECTS} from 'actions/endpoint'
import {getFormattedDate} from 'utils/helper'
import ProjectBillingTimeTableList from './ProjectBillingTimeTableList'
import ProjectBillingExpenseTableList from './ProjectBillingExpenseTableList'
import ProjectBillingInvoiceModal from '../ProjectBillingInvoiceModal'

const ProjectBillingInvoice = ({projectId, budgetId, invoiceId}) => {
  const dispatch = useDispatch()

  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false)
  const [isEditTime, setIsEditTime] = useState(false)
  const [isEditExpense, setIsEditExpense] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {billingInvoiceLogs = {}} = useSelector(state => state.projects)
  const {expenseList = [], timeLogs = [], currency = ''} = billingInvoiceLogs

  useEffect(() => {
    if (projectId && budgetId && invoiceId) {
      dispatch(getProjectFinanceBillingInvoiceLogs({projectId, budgetId, invoiceId}))
    }
  }, [dispatch, projectId, budgetId, invoiceId])

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECT_FINANCE_BILLING_INVOICE)) {
      setIsInvoiceModalOpen(false)
      dispatch(getProjectFinanceBillingOpenInvoicesList({projectId, budgetId}))
      dispatch(getProjectFinanceBillingInvoiceLogs({projectId, budgetId, invoiceId}))
    }
    if (successLabels.includes(MOVE_PROJECT_FINANCE_BILLING_ITEMS)) {
      dispatch(getProjectFinanceBillingInvoiceLogs({projectId, budgetId, invoiceId}))
    }
  }, [successLabels, dispatch, projectId, budgetId, invoiceId])

  const getHourMinuteFormatFromMinutes = minutes => {
    const hour = Math.floor(minutes / 60)
    const minute = minutes % 60
    return `${hour} hrs ${minute} mins`
  }

  const getTotalTime = () => {
    const totalMinutes = timeLogs.reduce((acc, item) => acc + item.loggedHrs * 60 + item.loggedMins, 0)
    return getHourMinuteFormatFromMinutes(totalMinutes)
  }

  const getSubTotalCost = list => {
    return list.reduce((acc, item) => acc + item.cost, 0).toFixed(2)
  }

  const renderTimeTableBody = () => {
    if (timeLogs.length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="6" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return timeLogs.map(time => (
      <VmoTableRow key={time.id} className="tableLink">
        <VmoTableCell>{getFormattedDate(time.date)}</VmoTableCell>
        <VmoTableCell>
          {time.createdByDetails?.firstName} {time.createdByDetails?.lastName}
        </VmoTableCell>
        <VmoTableCell>
          {time.tasklistDetails && (
            <p>
              {time.tasklistDetails?.title} - {time.taskDetails?.title}
            </p>
          )}
          <p>{time.description || 'No description'}</p>
        </VmoTableCell>
        <VmoTableCell>{getHourMinuteFormatFromMinutes(time.loggedHrs * 60 + time.loggedMins)}</VmoTableCell>
        <VmoTableCell>
          {currency} {time.cost.toFixed(2)}
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  const renderExpenseTableBody = () => {
    return expenseList.map(expense => (
      <VmoTableRow key={expense.id} className="tableLink">
        <VmoTableCell>{getFormattedDate(expense.expenseDate)}</VmoTableCell>
        <VmoTableCell>{expense.title}</VmoTableCell>
        <VmoTableCell>{expense.description}</VmoTableCell>
        <VmoTableCell>{expense.createdBy?.name}</VmoTableCell>
        <VmoTableCell>
          {currency} {expense.cost.toFixed(2)}
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <>
      <VmoCard fluid>
        <VmoCardContent>
          <div className="d-flex">
            <div>
              <p>
                Invoice No./ID : {billingInvoiceLogs.invoiceNumber}{' '}
                <VmoIcon
                  name="edit"
                  onClick={() => setIsInvoiceModalOpen(true)}
                  className="project-billing-edit-icon"
                />
              </p>
              <p>
                Issue Date : {billingInvoiceLogs.invoiceDate ? getFormattedDate(billingInvoiceLogs.invoiceDate) : ''}
              </p>
            </div>
            <div>
              <h2>Invoice</h2>
            </div>
          </div>
          <VmoDivider />
          {timeLogs.length ? (
            <>
              <h3>
                Billable Time{' '}
                {isEditTime ? (
                  <VmoButton basic size="mini" onClick={() => setIsEditTime(false)}>
                    Cancel
                  </VmoButton>
                ) : (
                  <VmoIcon name="edit" onClick={() => setIsEditTime(true)} className="project-billing-edit-icon" />
                )}
              </h3>
              {isEditTime ? (
                <ProjectBillingTimeTableList
                  timeList={timeLogs}
                  currency="USD"
                  projectId={projectId}
                  budgetId={budgetId}
                  invoiceId={invoiceId}
                />
              ) : (
                <VmoTable basic>
                  <VmoTableBody>{renderTimeTableBody()}</VmoTableBody>
                  <VmoTableFooter>
                    <VmoTableRow>
                      <VmoTableCell colSpan="2" />
                      <VmoTableCell textAlign="right">Sub-Total:</VmoTableCell>
                      <VmoTableCell>{getTotalTime()}</VmoTableCell>
                      <VmoTableCell>
                        {currency} {getSubTotalCost(timeLogs)}
                      </VmoTableCell>
                    </VmoTableRow>
                  </VmoTableFooter>
                </VmoTable>
              )}
            </>
          ) : (
            <p>No time logged on this invoice</p>
          )}

          {expenseList.length ? (
            <>
              <h3>
                Expenses{' '}
                {isEditExpense ? (
                  <VmoButton basic size="mini" onClick={() => setIsEditExpense(false)}>
                    Cancel
                  </VmoButton>
                ) : (
                  <VmoIcon name="edit" onClick={() => setIsEditExpense(true)} className="project-billing-edit-icon" />
                )}
              </h3>
              {isEditExpense ? (
                <ProjectBillingExpenseTableList
                  expenseList={expenseList}
                  currency="USD"
                  projectId={projectId}
                  budgetId={budgetId}
                  invoiceId={invoiceId}
                />
              ) : (
                <VmoTable basic>
                  <VmoTableBody>{renderExpenseTableBody()}</VmoTableBody>
                  <VmoTableFooter>
                    <VmoTableRow>
                      <VmoTableCell colSpan="3" />
                      <VmoTableCell textAlign="right">Sub-Total:</VmoTableCell>
                      <VmoTableCell>
                        {currency} {getSubTotalCost(expenseList)}
                      </VmoTableCell>
                    </VmoTableRow>
                  </VmoTableFooter>
                </VmoTable>
              )}
            </>
          ) : (
            <p>No expenses logged on this invoice</p>
          )}

          <VmoDivider />

          <div className="d-flex">
            <div>
              <VmoButton
                basic
                primary
                icon="download"
                content="Export"
                onClick={() =>
                  window.open(`${KADENCE_PROJECTS}/budgets/invoice/download/${billingInvoiceLogs.link}`, '_blank')
                }
              />
              <VmoButton
                color="green"
                icon="check"
                content="Mark Complete"
                onClick={() => dispatch(completeProjectFinanceBillingInvoice({projectId, budgetId, invoiceId}))}
                className="ml-3"
              />
            </div>
            <div>
              <h2>
                Total: {currency} {billingInvoiceLogs.finalTotal?.toFixed(2)}
              </h2>
              {billingInvoiceLogs.footerText && <span>({billingInvoiceLogs.footerText})</span>}
            </div>
          </div>
        </VmoCardContent>
      </VmoCard>

      {isInvoiceModalOpen && (
        <ProjectBillingInvoiceModal
          open={isInvoiceModalOpen}
          toggle={setIsInvoiceModalOpen}
          projectId={projectId}
          budgetId={budgetId}
          invoiceId={invoiceId}
        />
      )}
    </>
  )
}

export default ProjectBillingInvoice
