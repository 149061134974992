import SvgIcon from 'components/common/SvgIcon'
import _ from 'lodash'
import React from 'react'
import {Controller, useFieldArray} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoForm,
  VmoFormDropdown,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormRadio,
  VmoHeader,
  VmoTabPane,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

const billingBasedOnOptions = [
  {
    value: 'Fixed_Cost',
    text: 'Fixed Cost',
    key: 'Fixed_Cost',
  },
  {
    value: 'Specific_Cost',
    text: 'Specific Agent Cost',
    key: 'Specific_Cost',
  },
]

const BillingPane = ({control, errors, isBillableActive, billingType, list}) => {
  const {getUsersList: {users = []} = {}} = useSelector(state => state.settings)

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'users',
  })

  return (
    <VmoTabPane>
      <VmoCard fluid className="switchBox">
        <VmoCardContent>
          <div className="d-flex">
            <div>
              <label>Billing Preferences</label>
              <p className="kadence-lead" style={{width: '100%'}}>
                Agents can manually run the timer on tickets
              </p>
            </div>
            <Controller
              control={control}
              name="isBillable"
              render={({value, onChange}) => (
                <div className="d-flex">
                  <VmoFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                </div>
              )}
            />
          </div>
        </VmoCardContent>
        <VmoCardContent>
          <VmoForm className="errorLabel">
            <VmoFormGroup>
              <VmoFormField fluid disabled={!isBillableActive} width={billingType === 'Fixed_Cost' ? 8 : 12}>
                <label>Bill based on</label>
                <Controller
                  control={control}
                  name="billingType"
                  render={({value, onChange}) => (
                    <VmoFormDropdown
                      selection
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      placeholder="Select"
                      options={billingBasedOnOptions}
                    />
                  )}
                />
              </VmoFormField>

              {billingType === 'Fixed_Cost' && (
                <VmoFormField disabled={!isBillableActive} width={4} required>
                  <label>Enter Cost of Tickets (INR)</label>
                  <Controller
                    control={control}
                    name="fixedCost"
                    render={({value, onChange, onBlur}) => (
                      <VmoFormInput
                        required
                        placeholder="Enter the Fixed Cost"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        onBlur={onBlur}
                        error={
                          errors.fixedCost && {
                            content: errors.fixedCost.message,
                          }
                        }
                      />
                    )}
                  />
                </VmoFormField>
              )}
            </VmoFormGroup>

            {billingType === 'Specific_Cost' && (
              <>
                {fields.map(({id, cost}, index) => {
                  return (
                    <VmoFormGroup style={{alignItems: 'flex-end'}}>
                      <VmoFormField required disabled={!isBillableActive} width={6}>
                        <label>Users</label>
                        <Controller
                          control={control}
                          name={`users[${index}].id`}
                          defaultValue={id}
                          render={({value, onChange}) => (
                            <VmoFormDropdown
                              selection
                              value={value}
                              onChange={(e, {value}) => onChange(value)}
                              placeholder="Select User"
                              options={users?.map(list => ({
                                value: list.id,
                                text: `${list.firstName} ${list.lastName}`,
                                key: list.id,
                              }))}
                              error={
                                _.get(errors, `users[${index}].id`) && {
                                  content: errors.users[index].id.message,
                                }
                              }
                            />
                          )}
                        />
                      </VmoFormField>
                      <VmoFormField required disabled={!isBillableActive} width={6}>
                        <label>Enter Cost of Tickets (INR)</label>
                        <Controller
                          control={control}
                          name={`users[${index}].cost`}
                          defaultValue={cost}
                          render={({value, onChange}) => (
                            <VmoFormInput
                              placeholder="Enter the Cost"
                              value={value}
                              onChange={e => onChange(e.target.value)}
                              error={
                                _.get(errors, `users[${index}].cost`) && {
                                  content: errors.users[index].cost.message,
                                }
                              }
                            />
                          )}
                        />
                      </VmoFormField>
                      <VmoTooltip
                        position="top center"
                        content="Delete"
                        size="mini"
                        trigger={
                          <VmoButton
                            className="bg-btn-color"
                            icon
                            onClick={() => remove(index)}
                            disabled={!isBillableActive}
                          >
                            <SvgIcon path="common/delete" />
                          </VmoButton>
                        }
                      />
                    </VmoFormGroup>
                  )
                })}
                <VmoTooltip
                  position="top center"
                  content="Add Agent"
                  trigger={
                    <VmoButton
                      className="bg-btn-color"
                      icon
                      onClick={() => append({id: '', cost: ''})}
                      disabled={!isBillableActive}
                    >
                      <SvgIcon path="common/plus" />
                    </VmoButton>
                  }
                  size="mini"
                />
              </>
            )}
          </VmoForm>
          <VmoTable basic selectable className="mt-3">
            <VmoTableHeader>
              <VmoTableRow>
                <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
                <VmoTableHeaderCell>User</VmoTableHeaderCell>
                <VmoTableHeaderCell>Cost Per Hour</VmoTableHeaderCell>
              </VmoTableRow>
            </VmoTableHeader>
            <VmoTableBody>
              {list?.map((list, index) => (
                <VmoTableRow className="tableLink" key={list.id}>
                  <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
                  <VmoTableCell>
                    <VmoHeader as="h5">{list.name}</VmoHeader>
                  </VmoTableCell>
                  <VmoTableCell>{list.cost}</VmoTableCell>
                </VmoTableRow>
              ))}
            </VmoTableBody>
          </VmoTable>
        </VmoCardContent>
      </VmoCard>
      <a>Billing History</a>
    </VmoTabPane>
  )
}

export default BillingPane
