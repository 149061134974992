import {apiAction} from './api'
import {KADENCE_PURCHASE_ORDERS} from './endpoint'

import {
  GET_PREFIX,
  CREATE_PREFIX,
  DELETE_PREFIX,
  CREATE_PURCHASE_ORDERS,
  GET_NEW_PO_NUMBER,
  GET_PURCHASE_OREDRS_LIST,
  DELETE_PURCHASE_ORDERS,
  GET_PURCHASE_ORDERS_DATA,
  UPDATE_PURCHASE_ORDERS,
  COPY_PURCHASE_ORDER,
  DELETE_PO_LOGO,
} from './types'

export function getPrefixes() {
  return apiAction({
    url: `${KADENCE_PURCHASE_ORDERS}/prefixes`,
    method: 'GET',
    label: GET_PREFIX,
  })
}

export function getNewPoNumber(id) {
  return apiAction({
    url: `${KADENCE_PURCHASE_ORDERS}/number/${id}`,
    method: 'GET',
    label: GET_NEW_PO_NUMBER,
  })
}

export function createPrefix(data) {
  return apiAction({
    url: `${KADENCE_PURCHASE_ORDERS}/prefix`,
    method: 'POST',
    label: CREATE_PREFIX,
    data,
    showToast: true,
    successMessage: 'Prefix Added',
  })
}

export function deletePrefix(id) {
  return apiAction({
    url: `${KADENCE_PURCHASE_ORDERS}/prefix/${id}`,
    method: 'DELETE',
    label: DELETE_PREFIX,
    showToast: true,
    successMessage: 'Prefix Deleted',
  })
}

export function createPurchaseOrders(data) {
  return apiAction({
    url: `${KADENCE_PURCHASE_ORDERS}`,
    method: 'POST',
    label: CREATE_PURCHASE_ORDERS,
    data,
    showToast: true,
    successMessage: 'Purchase Order Added',
  })
}

export function getPurchaseOrderslist(params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params || {})
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }

  return apiAction({
    url: KADENCE_PURCHASE_ORDERS + query,
    method: 'GET',
    label: GET_PURCHASE_OREDRS_LIST,
  })
}

export function deletePurchaseOrders(id) {
  return apiAction({
    url: `${KADENCE_PURCHASE_ORDERS}/${id}`,
    method: 'DELETE',
    label: DELETE_PURCHASE_ORDERS,
    showToast: true,
    successMessage: 'Purchase Order Deleted',
  })
}

export function getPurchaseOrdersData(id) {
  return apiAction({
    url: `${KADENCE_PURCHASE_ORDERS}/${id}`,
    method: 'GET',
    label: GET_PURCHASE_ORDERS_DATA,
  })
}

export function updatePurchaseOrders(id, data) {
  return apiAction({
    url: `${KADENCE_PURCHASE_ORDERS}/${id}`,
    method: 'PUT',
    label: UPDATE_PURCHASE_ORDERS,
    data,
    showToast: true,
    successMessage: 'Purchase Order Updated',
  })
}

export function copyPurchaseOrder(id) {
  return apiAction({
    url: `${KADENCE_PURCHASE_ORDERS}/clone/${id}`,
    method: 'PUT',
    label: COPY_PURCHASE_ORDER,
    showToast: true,
    successMessage: 'Purchase Order Cloned',
  })
}

export function deletePoLogo(id) {
  return apiAction({
    url: `${KADENCE_PURCHASE_ORDERS}logo/${id}`,
    method: 'DELETE',
    label: DELETE_PO_LOGO,
    showToast: true,
    successMessage: 'Logo Updated',
  })
}
