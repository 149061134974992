import {SMS_CAMPAIGN_STATS, SMS_SENT_DELIVERED_OVERTIME, SMS_SUCCESS_RATE_OVERTIME} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case SMS_CAMPAIGN_STATS:
      return {...state, smsCampaignStatsData: payload}

    case SMS_SENT_DELIVERED_OVERTIME:
      return {...state, smsSentDeliveredOvertimeData: payload}

    case SMS_SUCCESS_RATE_OVERTIME:
      return {...state, smsSuccessRateOvertimeData: payload}

    case 'CLEAR_SMS_CAMPAIGN':
      return {...state, smsCampaignStatsData: {}, smsSentDeliveredOvertimeData: [], smsSuccessRateOvertimeData: []}

    default:
      return state
  }
}
