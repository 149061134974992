import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import {useParams} from 'react-router'
import {
  VmoContainer,
  VmoCard,
  VmoCardContent,
  VmoForm,
  VmoFormSelect,
  VmoFormGroup,
  VmoFormField,
  VmoTooltip,
  VmoFormInput,
  VmoDatePicker,
  VmoGridColumn,
  VmoFormTextArea,
} from 'vmo-library'
import {
  GET_USERS,
  GET_SALES_ORDERS_PREFIX,
  GET_PURCHASE_OREDRS_LIST,
  GET_QUOTE_LIST,
  GET_ALL_CONTACTS_IDS,
} from 'actions/types'
import {getPurchaseOrderslist} from 'actions/PurchaseOrders'
import {getQuoteList} from 'actions/quotes'
import {getAllContactsIds} from 'actions/contacts'

import {getNewSoNumber, getSalesOrdersPrefixes} from 'actions/salesOrders'
import {removeDoubleQuotes, KadenceCountryList} from 'utils/helper'
import {Carrier, Status} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'
import PreviewSalesOrder from '../Templates/PreviewSalesOrder'

function Setup(props) {
  const {style, errors, control, watch, setValue, dueDate, setDueDate} = props
  const dispatch = useDispatch()
  const {id: SalesOrdersId} = useParams()

  const [userOption, setUserOption] = useState([])
  const [purchaseOrderNumberList, setPurchaseOrderNumberList] = useState()
  const [prefixOptions, setPrefixOptions] = useState([])
  const [quoteName, setQuoteName] = useState([])
  const [contactOption, setcontactOption] = useState([])

  const {
    SalesOrderForm: {soNumber},
  } = useSelector(state => state.salesOrders)

  const watchPrefixId = watch('prefixId')
  const watchAll = watch()

  useEffect(() => {
    dispatch(getPurchaseOrderslist())
    dispatch(getQuoteList())
  }, [dispatch])

  useEffect(() => {
    if (!SalesOrdersId && !soNumber && watchPrefixId) {
      dispatch(getNewSoNumber(watchPrefixId))
    }
  }, [watchPrefixId, SalesOrdersId, soNumber, dispatch])

  useEffect(() => {
    if (soNumber) {
      setValue('soNumber', soNumber, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }
  }, [soNumber, setValue])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list)
    },
  })

  useApiResponse({
    action: getSalesOrdersPrefixes,
    enabled: true,
    label: GET_SALES_ORDERS_PREFIX,
    storePath: 'salesOrders.salesOrdersPrefixList',
    onSuccess: salesOrdersPrefixList => {
      const prefixes = salesOrdersPrefixList?.map(({id, prefix}) => ({
        key: id,
        value: id,
        text: prefix,
      }))
      setPrefixOptions(prefixes)
    },
  })

  useApiResponse({
    action: getPurchaseOrderslist,
    enabled: true,
    label: GET_PURCHASE_OREDRS_LIST,
    storePath: 'purchaseOrders.purchaseOrdersList',
    onSuccess: purchaseOrdersList => {
      const poNumber = purchaseOrdersList?.map(({poNumber}) => ({
        key: poNumber,
        value: poNumber,
        text: poNumber,
      }))
      setPurchaseOrderNumberList(poNumber)
    },
  })

  useApiResponse({
    action: getQuoteList,
    enabled: true,
    label: GET_QUOTE_LIST,
    storePath: 'quotes.quoteList.quoteDetails',
    onSuccess: quotesList => {
      const quoteName = quotesList?.map(({id, name}) => ({
        key: id,
        value: id,
        text: name,
      }))
      setQuoteName(quoteName)
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList.map(contact => ({
        value: contact.id,
        text: contact.name,
        id: contact.id,
      }))
      setcontactOption(list)
    },
  })

  return (
    <VmoContainer style={style}>
      <VmoCard fluid>
        <VmoCardContent style={{padding: '18px'}}>
          <h3 className="mb-0">Sales Order Information</h3>
          <p className="mt-0 mb-4 card-description">Create sales order by mentioning all the required information</p>
          <VmoForm className="errorLabel">
            <VmoFormGroup>
              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Sales Order Owner</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set ownership of the Sales order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="assigneeId"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      fluid
                      options={userOption}
                      selection
                      placeholder="Owner"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.assigneeId && {
                          content: removeDoubleQuotes(errors?.assigneeId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField width={4} style={{paddingLeft: '0'}}>
                <div className="info-header ml-3">
                  <label>Prefix Id</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="select a prefix id"
                    size="mini"
                    psoition="top center"
                  />
                </div>

                <Controller
                  control={control}
                  name="prefixId"
                  render={({value, onChange}) => (
                    <VmoFormSelect
                      width={8}
                      placeholder="ID Prefix"
                      options={prefixOptions}
                      value={value}
                      search
                      clearable
                      noResultsMessage={
                        setPrefixOptions.length ? 'No prefixes found' : 'You have not created any Prefixes.'
                      }
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField width={4} required>
                <div className="info-header">
                  <label className="label-class">SO Number</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify Sales order number"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="soNumber"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      placeholder="SO Number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.soNumber && {
                          content: removeDoubleQuotes(errors.soNumber.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label className="label-class">Subject</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify subject of sales order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="subject"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      placeholder="Subject"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.subject && {
                          content: removeDoubleQuotes(errors.subject.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Purchase Order</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention the purchase order details"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="purchaseOrderNumber"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      fluid
                      options={purchaseOrderNumberList}
                      selection
                      placeholder="Purchase Order"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors.purchaseOrderNumber && {
                          content: removeDoubleQuotes(errors.purchaseOrderNumber.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Customer Number</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention the customer number"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="customerNumber"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      placeholder="Customer Number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Due Date</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="set the last date for the order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="dueDate"
                  render={({onChange, value}) => (
                    <VmoDatePicker
                      type="date"
                      value={typeof value === 'string' ? new Date(dueDate) : dueDate}
                      inputProps={{readOnly: true}}
                      time={false}
                      onChange={value => {
                        onChange(value)
                        setDueDate(value)
                      }}
                      error={
                        errors?.dueDate && {
                          content: removeDoubleQuotes(errors?.dueDate?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Quote Name</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select a quotation to link with sales order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="quoteId"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      fluid
                      options={quoteName}
                      selection
                      placeholder="Quote Name"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Contact Name</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention contact name for the sales"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="contactId"
                  render={({onChange, value}) => (
                    <VmoFormSelect
                      fluid
                      selection
                      options={contactOption}
                      placeholder="Contact Name"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.contactId && {
                          content: removeDoubleQuotes(errors?.contactId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Pending</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="specify if anything is pending"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="pending"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      maxLength={20}
                      placeholder="Pending"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Excise Duty</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="set the excise duty"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="exciseDuty"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Carrier</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="set carrier of the sales order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="carrier"
                  render={({onChange, value}) => (
                    <VmoFormSelect
                      fluid
                      selection
                      options={Carrier}
                      placeholder="Carrier"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.carrier && {
                          content: removeDoubleQuotes(errors?.carrier?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Status</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="set current status of the sales order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="status"
                  render={({onChange, value}) => (
                    <VmoFormSelect
                      fluid
                      selection
                      options={Status}
                      placeholder="Status"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.status && {
                          content: removeDoubleQuotes(errors?.status?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Sales Commission</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Add if there is any extra commission"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="salesCommission"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput type="number" onChange={(e, {value}) => onChange(value)} value={value} />
                  )}
                />
              </VmoFormField>

              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Description</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="write brief description about sales order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="description"
                  render={({onChange, value}) => (
                    <VmoFormTextArea
                      maxLength={200}
                      type="text"
                      value={value}
                      placeholder="Description"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.description && {
                          content: removeDoubleQuotes(errors.description.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>
          </VmoForm>
        </VmoCardContent>
      </VmoCard>
      <VmoCard fluid>
        <VmoCardContent>
          <h3 className="mb-0">Address Information</h3>
          <p className="mt-0 mb-4 card-description">Add billing and shopping address for the sales order</p>
          <VmoForm className="errorLabel">
            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing Street</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention the billing street"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.streetAddress"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      fluid
                      value={value}
                      placeholder="Street"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.billingAddress?.streetAddress && {
                          content: removeDoubleQuotes(errors?.billingAddress?.streetAddress?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping Street</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention the shipping address"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.streetAddress"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      fluid
                      value={value}
                      placeholder="Street"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.shippingAddress?.streetAddress && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.streetAddress?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing City</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention billing city"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.locality"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      fluid
                      value={value}
                      placeholder="Locality"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.billingAddress?.locality && {
                          content: removeDoubleQuotes(errors?.billingAddress?.locality?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping City</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention shipping city"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.locality"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      fluid
                      value={value}
                      placeholder="Locality"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.shippingAddress?.locality && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.locality?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing State</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="specify the state"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.state"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      fluid
                      value={value}
                      placeholder="State"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.billingAddress?.state && {
                          content: removeDoubleQuotes(errors?.billingAddress?.state?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping State</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify state of product shipping"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.state"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      fluid
                      value={value}
                      placeholder="State"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.shippingAddress?.state && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.state?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing Postal Code</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention postal code for billing"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.postalCode"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormInput
                      type="text"
                      fluid
                      maxLength={10}
                      placeholder="Postal Code"
                      onBlur={onBlur}
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.billingAddress?.postalCode && {
                          content: removeDoubleQuotes(errors?.billingAddress?.postalCode?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping Postal Code</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention shipping code"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.postalCode"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormInput
                      maxLength={10}
                      type="text"
                      fluid
                      placeholder="Postal Code"
                      onBlur={onBlur}
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.shippingAddress?.postalCode && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.postalCode?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing Country</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify country name"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.country"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      search
                      fluid
                      options={KadenceCountryList}
                      selection
                      placeholder="Country"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.billingAddress?.country && {
                          content: removeDoubleQuotes(errors?.billingAddress?.country?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping Country</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention the shipping country"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.country"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      search
                      fluid
                      options={KadenceCountryList}
                      selection
                      placeholder="Country"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.shippingAddress?.country && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.country?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>
          </VmoForm>
        </VmoCardContent>
      </VmoCard>
      <VmoGridColumn width={8} className="quotePreBox" style={{display: 'none'}}>
        <PreviewSalesOrder watch={{setupData: watchAll}} />
      </VmoGridColumn>
    </VmoContainer>
  )
}

export default Setup
