import {
  ASSIGNED_VS_RESOLVED,
  RATINGS_AND_NPS_SCORE,
  TOTAL_ASSIGNED,
  TOTAL_RESOLVED,
  USER_ACTIVITIES_OVERTIME,
  USER_ACTIVITIES_TABLE_SLA,
  USER_ASSIGNMENT_OVERTIME,
} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case TOTAL_RESOLVED:
      return {
        ...state,
        totalResolvedData: payload,
      }

    case TOTAL_ASSIGNED:
      return {
        ...state,
        totalAssignedData: payload,
      }

    case ASSIGNED_VS_RESOLVED:
      return {
        ...state,
        assignedVsResolvedData: payload,
      }

    case RATINGS_AND_NPS_SCORE:
      return {
        ...state,
        ratingsAndNpsScoreData: payload,
      }

    case USER_ASSIGNMENT_OVERTIME:
      return {...state, userAssignmentOvertimeData: payload}

    case USER_ACTIVITIES_OVERTIME:
      return {
        ...state,
        userActivities: payload,
      }

    case USER_ACTIVITIES_TABLE_SLA:
      return {...state, userActivitiesSLAData: payload}

    default:
      return state
  }
}
