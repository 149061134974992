import React, {useEffect} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormTextArea,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'
import {removeDoubleQuotes} from 'utils/helper'
import {addFormsSchema} from 'validation/Projects/projects/forms.schema'
import {CREATE_PROJECT_FORM} from 'actions/types'
import {createProjectForm, getAllProjectForms} from 'actions/projects'
import SvgIcon from 'components/common/SvgIcon'

function FormModal(props) {
  const {isAddFormOpen, setIsAddFormOpen, projectId} = props
  const dispatch = useDispatch()

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange',
    resolver: joiResolver(addFormsSchema),
    defaultValues: {
      title: '',
      description: '',
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECT_FORM)) {
      reset({
        title: '',
        description: '',
      })
      dispatch(getAllProjectForms({projectId}))
      setIsAddFormOpen(false)
    }
  }, [successLabels, dispatch, projectId])

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isAddFormOpen}
      onClose={() => setIsAddFormOpen(false)}
      size="small"
      centered={false}
    >
      <VmoModalHeader className="vmoModalHeader">Add Form</VmoModalHeader>
      <VmoModalContent scrolling>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <div className="info-header">
              <label>Title</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify title of form"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="title"
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  type="text"
                  placeholder="Form Title..."
                  value={value}
                  maxLength={50}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  error={
                    errors.title && {
                      content: removeDoubleQuotes(errors.title.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
          <VmoFormField>
            <div className="info-header">
              <label>Description</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify brief description about form"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="description"
              render={({onChange, value}) => (
                <VmoFormTextArea
                  type="text"
                  value={value}
                  maxLength={250}
                  placeholder="Description"
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.description && {
                      content: removeDoubleQuotes(errors.description.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions style={{position: 'relative'}}>
        <VmoButton
          basic
          onClick={() => setIsAddFormOpen(false)}
          disabled={isLoadingData && type.includes(CREATE_PROJECT_FORM)}
        >
          Cancel
        </VmoButton>
        <VmoButton
          primary
          onClick={handleSubmit(data => dispatch(createProjectForm({data, projectId})))}
          loading={isLoadingData && type.includes(CREATE_PROJECT_FORM)}
          disabled={!isDirty}
        >
          Add Form
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default FormModal
