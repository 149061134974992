import React from 'react'
import {useHistory} from 'react-router'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoContainer,
  VmoDivider,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormTextArea,
  VmoGrid,
  VmoGridColumn,
  VmoGridRow,
  VmoInput,
  VmoRadio,
} from 'vmo-library'

const ViewAppraisal = () => {
  const history = useHistory()

  return (
    <div className="kadence-full-page">
      <div className="campaignNavbar">
        <div>
          <VmoButton primary onClick={() => history.push('/appraisal/manage')}>
            Exit
          </VmoButton>
        </div>
        <h3 className="m-0">Template Name</h3>
        <div>
          <VmoButton primary className="mr-3">
            Save as Draft
          </VmoButton>
          <VmoButton primary>Submit</VmoButton>
        </div>
      </div>

      <VmoForm>
        <VmoContainer>
          <VmoCard fluid>
            <VmoCardContent>
              <VmoGrid className="m-0">
                <VmoGridRow columns="equal" className="p-0">
                  <VmoGridColumn className="p-0">
                    <div className="appraisal-view-formhead">
                      <span className="appraisal-view-formhead-span">Form Name:</span>
                      <span className="appraisal-view-formhead-text">Sample</span>
                    </div>
                    <div className="appraisal-view-formhead">
                      <span className="appraisal-view-formhead-span">Description:</span>
                      <span className="appraisal-view-formhead-text">This is simple description</span>
                    </div>
                    <div className="appraisal-view-formhead">
                      <span className="appraisal-view-formhead-span">Employee Name:</span>
                      <span className="appraisal-view-formhead-text">Sample Employee</span>
                    </div>
                    <div className="appraisal-view-formhead">
                      <span className="appraisal-view-formhead-span">Reviewer Name:</span>
                      <span className="appraisal-view-formhead-text">Sample Reviewer</span>
                    </div>
                  </VmoGridColumn>
                  <VmoGridColumn className="p-0">
                    <div className="appraisal-view-formhead">
                      <span className="appraisal-view-formhead-span">Start Date:</span>
                      <span className="appraisal-view-formhead-text">21-09-2024</span>
                    </div>
                    <div className="appraisal-view-formhead">
                      <span className="appraisal-view-formhead-span">Employee End Date:</span>
                      <span className="appraisal-view-formhead-text">21-09-2024</span>
                    </div>
                    <div className="appraisal-view-formhead">
                      <span className="appraisal-view-formhead-span">End Date:</span>
                      <span className="appraisal-view-formhead-text">21-09-2024</span>
                    </div>
                    <VmoFormField style={{marginBottom: 12}}>
                      <VmoCheckbox label="Employee able to view after submitting" />
                    </VmoFormField>

                    <VmoFormField>
                      <VmoCheckbox label="Reviewer able to view after submitting" />
                    </VmoFormField>
                  </VmoGridColumn>
                </VmoGridRow>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>

          <VmoCard fluid>
            <VmoCardContent>
              <VmoFormGroup width="16">
                <VmoFormField width="16">
                  <label>Employee Name</label>
                  <VmoInput />
                </VmoFormField>
                <VmoFormField width="16">
                  <label>Age</label>
                  <VmoInput />
                </VmoFormField>
              </VmoFormGroup>
              <VmoFormGroup width="16">
                <VmoFormField width="16">
                  <label>Hire Date</label>
                  <VmoInput />
                </VmoFormField>
                <VmoFormField width="16">
                  <label>Year of service</label>
                  <VmoInput />
                </VmoFormField>
              </VmoFormGroup>
              <VmoFormGroup width="16">
                <VmoFormField width="16">
                  <label>Organization</label>
                  <VmoInput />
                </VmoFormField>
                <VmoFormField width="16">
                  <label>Department</label>
                  <VmoInput />
                </VmoFormField>
              </VmoFormGroup>
              <VmoFormGroup width="16">
                <VmoFormField width="8">
                  <label>Position</label>
                  <VmoInput />
                </VmoFormField>
              </VmoFormGroup>

              <h3 className="mb-3">Performance Review</h3>

              <VmoDivider className="my-3" />

              <h3 className="mt-0 mb-0">General</h3>
              <p className="mt-0 mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>

              <VmoFormField>
                <h4 style={{paddingBottom: 16}} className="m-0">
                  Has the past year been good/bad/satisfactory or otherwise for you, and why?
                </h4>
                <VmoFormGroup width="16">
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <VmoInput />
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <VmoInput />
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoFormField>
                <h4 style={{paddingBottom: 16}} className="m-0">
                  What do you consider to be your most important achievements of the past year?
                </h4>
                <VmoFormGroup width="16">
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <VmoFormTextArea />
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <VmoFormTextArea />
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoFormField>
                <h4 style={{paddingBottom: 16}} className="m-0">
                  What are your goals for the current year?
                </h4>
                <VmoFormGroup width="16">
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <VmoFormTextArea />
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <VmoFormTextArea />
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoFormField>
                <h4 style={{paddingBottom: 16}} className="m-0">
                  What do you like and dislike about working for this organization?
                </h4>
                <VmoFormGroup width="16">
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <VmoFormTextArea />
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <VmoFormTextArea />
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoFormField>
                <h4 style={{paddingBottom: 16}} className="m-0">
                  What elements of your job do you find most difficult?
                </h4>
                <VmoFormGroup width="16">
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <VmoInput />
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <VmoInput />
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoFormField>
                <h4 style={{paddingBottom: 16}} className="m-0">
                  What elements of your job interest you the most?
                </h4>
                <VmoFormGroup width="16" >
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <VmoInput />
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <VmoInput />
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoDivider className="my-3" />

              <h3 className="mt-0 mb-0">Performance</h3>
              <p className="mt-0 mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>

              <VmoFormField>
                <h4 style={{paddingBottom: 16}} className="m-0">
                  State your understanding of your main duties and responsibilities
                </h4>
                <VmoFormGroup width="16" >
                  <VmoFormField width="14">
                    <label>Filled by Employee</label>
                    <VmoFormTextArea />
                  </VmoFormField>
                  <VmoFormField width="14">
                    <label>Filled by Reviewer</label>
                    <VmoFormTextArea />
                  </VmoFormField>
                  <VmoFormField width="6">
                    <label>Points</label>
                    <div className="d-flex" style={{columnGap: 12}}>
                      <VmoInput />
                      <span style={{width: 30}}>/ 5</span>
                    </div>
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <h5 className="mt-0">Performance Factors</h5>

              <VmoFormField >
                <h4 style={{paddingBottom: 16}} className="m-0">
                  Quality of Work
                </h4>
                <VmoFormGroup width="16" >
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoDivider className="my-3" />

              <VmoFormField >
                <h4 style={{paddingBottom: 16}} className="m-0">
                  Professional Conduct
                </h4>
                <VmoFormGroup width="16" >
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoDivider className="my-3" />

              <VmoFormField >
                <h4 style={{paddingBottom: 16}} className="m-0">
                  Dependability / Responsibility
                </h4>
                <VmoFormGroup width="16" >
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoDivider className="my-3" />

              <VmoFormField >
                <h4 style={{paddingBottom: 16}} className="m-0">
                  Initiative
                </h4>
                <VmoFormGroup width="16" >
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoDivider className="my-3" />

              <VmoFormField >
                <h4 style={{paddingBottom: 16}} className="m-0">
                  Judgement
                </h4>
                <VmoFormGroup width="16" >
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoDivider className="my-3" />

              <VmoFormField >
                <h4 style={{paddingBottom: 16}} className="m-0">
                  Attitude
                </h4>
                <VmoFormGroup width="16" >
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoDivider className="my-3" />

              <VmoFormField >
                <h4 style={{paddingBottom: 16}} className="m-0">
                  Communication Skill
                </h4>
                <VmoFormGroup width="16" >
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <div className="appraisal-performance-factors-wrapper">
                      <VmoRadio label="1" />
                      <VmoRadio label="2" />
                      <VmoRadio label="3" />
                      <VmoRadio label="4" />
                      <VmoRadio label="5" />
                    </div>
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoDivider className="my-3" />

              <VmoFormField>
                <h4 style={{paddingBottom: 16}} className="m-0">
                  Overall Performance
                </h4>
                <VmoFormGroup width="16" >
                  <VmoFormField width="14">
                    <label>Filled by Employee</label>
                    <VmoFormTextArea />
                  </VmoFormField>
                  <VmoFormField width="14">
                    <label>Filled by Reviewer</label>
                    <VmoFormTextArea />
                  </VmoFormField>
                  <VmoFormField width="6">
                    <label>Points</label>
                    <div className="d-flex" style={{columnGap: 12}}>
                      <VmoInput />
                      <span style={{width: 50}}>/ 10</span>
                    </div>
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoDivider className="my-3" />

              <h3 className="mt-0 mb-0">Remarks / Feedbacks</h3>
              <p className="mt-0 mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>

              <VmoFormField>
                <h4 style={{paddingBottom: 16}} className="m-0">
                  Comments
                </h4>
                <VmoFormGroup width="16">
                  <VmoFormField width="16">
                    <label>Filled by Employee</label>
                    <VmoFormTextArea />
                  </VmoFormField>
                  <VmoFormField width="16">
                    <label>Filled by Reviewer</label>
                    <VmoFormTextArea />
                  </VmoFormField>
                </VmoFormGroup>
              </VmoFormField>

              <VmoDivider className="my-3" />

              <div className="appraisal-view-formhead">
                <span className="appraisal-view-formhead-span">Section 1 :</span>
                <span className="appraisal-view-formhead-text">7 / 50</span>
              </div>
              <div className="appraisal-view-formhead m-0">
                <span className="appraisal-view-formhead-span">Total Score:</span>
                <span className="appraisal-view-formhead-text">7 / 50</span>
              </div>
            </VmoCardContent>
          </VmoCard>
        </VmoContainer>
      </VmoForm>
    </div>
  )
}

export default ViewAppraisal
