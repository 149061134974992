import {memo} from 'react'
import SingleLineText from './ListOfQuestions/SingleLineText'
import MultiLineText from './ListOfQuestions/MultiLineText'
import Page from './ListOfQuestions/Page'
import Header from './ListOfQuestions/Header'
import Dropdown from './ListOfQuestions/Dropdown'
import Checkboxes from './ListOfQuestions/Checkboxes'
import MultipleChoice from './ListOfQuestions/MultipleChoice'
import Number from './ListOfQuestions/Number'
import Date from './ListOfQuestions/Date'

const QuestionSetting = ({control, setValue, question, currentIndex, setSelectedQuestion}) => {
  switch (question.type) {
    case 'page':
      return (
        <Page
          setSelectedQuestion={setSelectedQuestion}
          currentIndex={currentIndex}
          control={control}
          question={question}
        />
      )
    case 'header':
      return (
        <Header
          setSelectedQuestion={setSelectedQuestion}
          currentIndex={currentIndex}
          control={control}
          question={question}
        />
      )
    case 'text':
      return (
        <SingleLineText
          setSelectedQuestion={setSelectedQuestion}
          currentIndex={currentIndex}
          control={control}
          question={question}
        />
      )
    case 'textarea':
      return (
        <MultiLineText
          setSelectedQuestion={setSelectedQuestion}
          currentIndex={currentIndex}
          control={control}
          question={question}
        />
      )
    case 'dropdown':
      return (
        <Dropdown
          setSelectedQuestion={setSelectedQuestion}
          currentIndex={currentIndex}
          control={control}
          question={question}
        />
      )
    case 'checkbox':
      return (
        <Checkboxes
          setSelectedQuestion={setSelectedQuestion}
          currentIndex={currentIndex}
          control={control}
          question={question}
        />
      )
    case 'radio':
      return (
        <MultipleChoice
          setSelectedQuestion={setSelectedQuestion}
          currentIndex={currentIndex}
          control={control}
          question={question}
        />
      )
    case 'number':
      return (
        <Number
          setSelectedQuestion={setSelectedQuestion}
          currentIndex={currentIndex}
          control={control}
          question={question}
        />
      )
    case 'date':
      return (
        <Date
          setSelectedQuestion={setSelectedQuestion}
          currentIndex={currentIndex}
          control={control}
          question={question}
        />
      )

    default:
      return null
  }
}
export default memo(QuestionSetting)
