import Joi from 'joi'
import {domainValidation, emailValidation, inputPhoneValidation, inputStringValidation} from 'validation'

// const validDomain = async (domain) => {
//   try {
//     await Axios.post(USER_ACCOUNTS + "/isdomainavailable", {
//       domain: domain,
//     });
//     return true;
//   } catch (error) {
//     return false;
//   }
// };

export const signupSchema = Joi.object({
  email: emailValidation.required(),
  password: inputStringValidation.min(8).required().messages({
    'string.min': `Length must be at least 8 characters`,
  }),
  firstName: inputStringValidation.required().label('first name'),
  lastName: inputStringValidation.required().label('last name'),
  company: inputStringValidation.required(),
  phone: inputPhoneValidation.required().messages({
    'string.empty': `Phone cannot be an empty field`,
    'any.required': `Phone is a required field`,
  }),
  countryCode: Joi.any(),
  domain: domainValidation.required().messages({
    'string.empty': `Domain  cannot be an empty field`,
    'any.required': `Domain  is a required field`,
    'string.min': `Domain should have a minimum length of 4`,
  }),
})
