import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectFinanceBillingExpenseList} from 'actions/projects'
import {
  DELETE_PROJECT_FINANCE_EXPENSE,
  MOVE_PROJECT_FINANCE_BILLING_ITEMS,
  UPDATE_PROJECT_FINANCE_EXPENSE,
} from 'actions/types'
import ProjectBillingExpenseTableList from './ProjectBillingExpenseTableList'

const ProjectBillingUnbilledExpenses = ({projectId, budgetId}) => {
  const dispatch = useDispatch()
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {billingExpenseList = []} = useSelector(state => state.projects)

  useEffect(() => {
    if (
      successLabels.includes(UPDATE_PROJECT_FINANCE_EXPENSE) ||
      successLabels.includes(DELETE_PROJECT_FINANCE_EXPENSE) ||
      successLabels.includes(MOVE_PROJECT_FINANCE_BILLING_ITEMS)
    ) {
      dispatch(getProjectFinanceBillingExpenseList({projectId, budgetId}))
    }
  }, [successLabels, dispatch, projectId, budgetId])

  return (
    <>
      <ProjectBillingExpenseTableList expenseList={billingExpenseList} projectId={projectId} budgetId={budgetId} />
    </>
  )
}

export default ProjectBillingUnbilledExpenses
