import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {VmoModal, VmoModalHeader, VmoModalContent, VmoModalActions, VmoButton, VmoFormInput, VmoForm} from 'vmo-library'
import {addSocial} from 'actions/multiple_fields'
import {startCase} from 'utils/helper'

const AddSocialModal = props => {
  const {isModalOpen, setIsModalOpen, selectedSocial, id, identifier} = props
  const {isLoadingData} = useSelector(state => state.apiReducer)
  const {
    control,
    errors,
    handleSubmit,
    formState: {isDirty},
  } = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      url: '',
    },
    resolver: joiResolver(
      Joi.object({
        url: Joi.string().domain().required().messages({
          'string.empty': `URL is not allowed to be empty`,
          'string.domain': `URL must contain a valid domain name`,
          'any.required': `URL is a required field`,
        }),
      })
    ),
  })
  const dispatch = useDispatch()

  return (
    <VmoModal size="mini" open={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)}>
      <VmoModalHeader>Add {startCase(selectedSocial.category)}</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <Controller
            name="url"
            render={({onChange, value}) => (
              <VmoFormInput
                iconPosition="left"
                icon={selectedSocial.category}
                value={value}
                // label={startCase(selectedSocial.category)}
                placeholder="paste your profile url"
                onChange={e => onChange(e.target.value)}
                error={
                  errors.url && {
                    content: errors.url.message,
                  }
                }
              />
            )}
            control={control}
          />
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </VmoButton>
        <VmoButton
          disabled={!isDirty}
          primary
          loading={isLoadingData}
          onClick={handleSubmit(data =>
            dispatch(addSocial(id, {...data, category: selectedSocial.category}, identifier))
          )}
        >
          Add
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default AddSocialModal
