import {KADENCE_ANALYTICS} from './endpoint'
import {apiAction} from './api'
import {
  PURCHASES_STATS,
  PURCHASES_AMOUNTS,
  PURCHASES_NUMBERS_BY_CATEGORY,
  PURCHASES_AMOUNT_BY_CATEGORY,
  PURCHASES_NUMBER_OVERTIME,
  PURCHASES_AMOUNT_OVERTIME,
} from './types'

export function purchasesStats(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchases/purchases-stats`,
    method: 'POST',
    label: PURCHASES_STATS,
    data,
  })
}

export function purchaseAmounts(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchases/purchase-amounts`,
    method: 'POST',
    label: PURCHASES_AMOUNTS,
    data,
  })
}

export function purchaseNumbersByCategory(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchases/purchase-numbers-by-category`,
    method: 'POST',
    label: PURCHASES_NUMBERS_BY_CATEGORY,
    data,
  })
}

export function purchaseAmountByCategory(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchases/purchase-amount-by-category`,
    method: 'POST',
    label: PURCHASES_AMOUNT_BY_CATEGORY,
    data,
  })
}

export function purchaseNumberOvertime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchases/purchase-number-overtime`,
    method: 'POST',
    label: PURCHASES_NUMBER_OVERTIME,
    data,
  })
}

export function purchaseAmountOvertime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchases/purchase-amount-overtime`,
    method: 'POST',
    label: PURCHASES_AMOUNT_OVERTIME,
    data,
  })
}
