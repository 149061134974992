import {apiAction} from './api'
import {KADENCE_ANALYTICS} from './endpoint'
import {VISITORS_OVERTIME, VISITORS_TOP_LIST, VISITOR_STATS} from './types'

export function visitorStatsAnalytics(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/visitors/visitorstats`,
    method: 'POST',
    label: VISITOR_STATS,
    data,
  })
}

export function visitorsOvertimeAnalytics(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/visitors/visitorsovertime`,
    method: 'POST',
    label: VISITORS_OVERTIME,
    data,
  })
}

export function visitorsTopListAnalytics(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/visitors/toplist`,
    method: 'POST',
    label: VISITORS_TOP_LIST,
    data,
  })
}
