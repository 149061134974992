import React from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom/cjs/react-router-dom.min'
import {VmoMenu, VmoMenuItem} from 'vmo-library'

const TemplateFinanceMenu = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  return (
    <VmoMenu vertical secondary className="projects-side-menu kadence-menu">
      <VmoMenuItem
        active={window.location.pathname.includes(`/finance/summary`)}
        onClick={() => history.push(`${url}/summary`)}
      >
        Summary
      </VmoMenuItem>
      <VmoMenuItem
        active={window.location.pathname.includes(`/finance/rates`)}
        onClick={() => history.push(`${url}/rates`)}
      >
        Rates
      </VmoMenuItem>
    </VmoMenu>
  )
}

export default TemplateFinanceMenu
