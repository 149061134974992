import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import VendorsList from 'components/pages/Finance/Vendors/VendorsList'
import VendorsFields from 'components/pages/Finance/Vendors/Settings/Fields/Fields'
import Category from 'components/pages/Finance/Vendors/Settings/Category/Category'

const routes = [
  {path: '/vendors/all', component: VendorsList, title: 'All Vendors', exact: true},
  {path: '/vendors/category', component: Category, title: 'Category', exact: true},
  {path: '/vendors/fields', component: VendorsFields, title: 'Fields', exact: true},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} vendor />
        <Component {...props} />
      </>
    )}
  />
)

const VendorsRoutes = () => (
  <Switch>
    <Route exact path="/vendors">
      <Redirect to="/vendors/all" />
    </Route>
    {routes.map(renderRoute)}
    <Route path="/vendors">
      <Redirect to="/vendors/all" />
    </Route>
  </Switch>
)

export default VendorsRoutes
