import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {
  VmoButton,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoIcon,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
} from 'vmo-library'

import {DELETE_CLIENT_PORTAL_ANNOUNCEMENT, GET_CLIENT_PORTAL_ANNOUNCEMENTS} from 'actions/types'
import {deleteClientPortalAnnouncement, getClientPortalAnnouncements} from 'actions/client-portal'
import {get, getFormattedDate, getFormattedTime, getPermissionTooltipContent} from 'utils/helper'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import KadenceCustomPagination from 'components/common/KadenceCustomPagination'
import SearchComponent from 'components/common/SearchComponent'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import create from 'assets/create.svg'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const Announcements = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [announcementIdToDelete, setAnnouncementIdToDelete] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [activePage, setActivePage] = useState(1)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {
    isLoading: isLoadingAnnouncements,
    data: announcementsList = [],
    totalPages,
    currentPage,
    pageSize,
    totalRecords,
  } = useApiResponse({
    label: GET_CLIENT_PORTAL_ANNOUNCEMENTS,
    storePath: 'clientPortal.announcementsList',
    dataToExtract: 'announcements',
    onSuccess: announcementsList => {
      setActivePage(announcementsList?.page)
    },
  })

  const {isLoading} = useApiLoader({label: GET_CLIENT_PORTAL_ANNOUNCEMENTS})
  const {clientPortalPermissions} = useUserPermissions()
  const announcementsPermissions = clientPortalPermissions?.announcements

  useEffect(() => {
    const data = {page: activePage, ...(searchText && {text: searchText})}
    dispatch(getClientPortalAnnouncements(data))
  }, [dispatch, activePage, searchText])

  useEffect(() => {
    if (successLabels.includes(DELETE_CLIENT_PORTAL_ANNOUNCEMENT)) {
      const data = {page: activePage, ...(searchText && {text: searchText})}
      dispatch(getClientPortalAnnouncements(data))
    }
  }, [successLabels, dispatch, activePage, searchText])

  if (isLoading) {
    return <PageLoader />
  }

  if (!announcementsList?.length && !searchText) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          imageSrc={create}
          header="Announcement"
          subHeader="Announcements keep customers updated about urgent notifications without breaking the workflow"
          addFirst={() => announcementsPermissions && history.push('/portal/announcements/create')}
          tooltip={!announcementsPermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: announcementsPermissions})}
          buttonText="Create Announcement"
          Feature1="Customization"
          Feature2="Schedule announcement"
          Feature3="Targeted messaging"
          list1="Personalize to match the brand's style."
          list2="Plan and schedule announcements in advance."
          list3="Ensure that right audience receive the announcement"
          // imageSrc={create}
        />
      </div>
    )
  }

  const renderTableBody = () => {
    if (isLoadingAnnouncements) {
      return <VmoTablePlaceholder columns={6} rows={5} />
    }
    if (!isLoadingAnnouncements && announcementsList?.length === 0) {
      return (
        <VmoTableRow className="mb-3">
          <VmoTableCell colSpan="6" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return announcementsList?.map(({id, title, createdBy, publishDate, unpublishDate}, index) => (
      <VmoTableRow key={id} className="tableLink" onClick={() => history.push(`/portal/announcements/edit/${id}`)}>
        <VmoTableCell>{pageSize * (currentPage - 1) + index + 1}</VmoTableCell>
        <VmoTableCell>{title}</VmoTableCell>
        <VmoTableCell>{get(['name'], createdBy, '-')}</VmoTableCell>
        <VmoTableCell>
          {publishDate ? `${getFormattedDate(publishDate)}${getFormattedTime(publishDate)}` : '-'}
        </VmoTableCell>
        <VmoTableCell>{unpublishDate ? getFormattedDate(unpublishDate) : '-'}</VmoTableCell>
        <VmoTableCell>
          <VmoDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
            <VmoDropdownMenu>
              <LockPermissionTooltip isRoleAccessDenied={!announcementsPermissions}>
                <VmoDropdownItem
                  onClick={e => {
                    e.stopPropagation()
                    if (announcementsPermissions) {
                      setAnnouncementIdToDelete(id)
                      setIsDeleteModalOpen(true)
                    }
                  }}
                >
                  <VmoIcon name="trash alternate" /> Delete
                </VmoDropdownItem>
              </LockPermissionTooltip>
            </VmoDropdownMenu>
          </VmoDropdown>
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <>
      <div className="kadence-side-wrap">
        <div className="page-header pb-0">
          <div className="page-title">
            <div className="page-titleIcon">
              <SvgIcon path="clients/client-portal/announcement" />
              <h5>Announcements ({totalRecords})</h5>
            </div>
          </div>
          <div className="page-action">
            {/* <SearchComponent
              identifier="client-portal-announcement"
              setSearchText={setSearchText}
              searchText={searchText}
            /> */}
            <SearchComponent onSuccess={value => setSearchText(value)} />
            <LockPermissionTooltip isRoleAccessDenied={!announcementsPermissions}>
              <VmoButton
                content="Create Announcement"
                primary
                className={!announcementsPermissions ? 'disabled-button' : ''}
                onClick={() => announcementsPermissions && history.push('/portal/announcements/create')}
              />
            </LockPermissionTooltip>
          </div>
        </div>

        <VmoTable basic>
          <VmoTableHeader>
            <VmoTableRow>
              <VmoTableHeaderCell>#</VmoTableHeaderCell>
              <VmoTableHeaderCell>Title</VmoTableHeaderCell>
              <VmoTableHeaderCell>Created By</VmoTableHeaderCell>
              <VmoTableHeaderCell>Published Date</VmoTableHeaderCell>
              <VmoTableHeaderCell>UnPublished Date</VmoTableHeaderCell>
              <VmoTableHeaderCell>Actions</VmoTableHeaderCell>
            </VmoTableRow>
          </VmoTableHeader>
          <VmoTableBody>{renderTableBody()}</VmoTableBody>
        </VmoTable>

        <KadenceCustomPagination totalPages={totalPages} activePage={currentPage} onPageChange={setActivePage} />
      </div>

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          idTobeDeleted={announcementIdToDelete}
          deleteAction={deleteClientPortalAnnouncement}
          type={DELETE_CLIENT_PORTAL_ANNOUNCEMENT}
        />
      )}
    </>
  )
}

export default Announcements
