import React, {useRef, useState} from 'react'

const WrappedIframe = ({index, srcDoc}) => {
  const [iFrameHeight, setIFrameHeight] = useState('0px')

  const currentRef = useRef()

  return (
    <iframe
      title="title"
      style={{
        maxWidth: '100%',
        width: '100%',
        height: iFrameHeight,
        overflow: 'visible',
        fontFamily: 'Roboto',
        fontSize: '0.875rem',
      }}
      onLoad={() => {
        const myiFrame = document.getElementById(`${index}iFrame`)
        const doc = myiFrame.contentDocument
        // open every link in new tab
        const listOfLink = doc.querySelectorAll('a')
        listOfLink.forEach(link => link.setAttribute('target', '_blank'))

        doc.body.innerHTML = `${doc.body.innerHTML}
          <style>
          body { font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif; font-size: 15px; color: #001F3D; line-height: 1.5; white-space: pre-line;}
          </style>
          `

        setIFrameHeight(`${currentRef.current.contentWindow.document.body.scrollHeight + 40}px`)
      }}
      ref={currentRef}
      srcDoc={srcDoc}
      width="100%"
      height={iFrameHeight}
      scrolling="no"
      frameBorder="0"
      id={`${index}iFrame`}
    />
  )
}
export default WrappedIframe
