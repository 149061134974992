import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
} from 'vmo-library'
import {getProjectsTemplatesFinanceRatesList} from 'actions/projects'
import {GET_PROJECTS_TEMPLATES_FINANCE_RATES_LIST, UPDATE_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE} from 'actions/types'
import {getFormattedDate} from 'utils/helper'
import useApiResponse from 'hooks/impure/useApiResponse'
import TemplateFinanceRateModal from './TemplateFinanceRateModal'

const TemplateFinanceRates = ({templateId, budgetId}) => {
  const [isProjectRateModalOpen, setIsProjectRateModalOpen] = useState(false)
  const [idToUpdateRate, setIdToUpdateRate] = useState(null)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {
    data: ratesList = [],
    isLoading,
    callAction,
  } = useApiResponse({
    action: getProjectsTemplatesFinanceRatesList,
    payload: {templateId, budgetId},
    dependency: [templateId, budgetId],
    enabled: templateId && budgetId,
    label: GET_PROJECTS_TEMPLATES_FINANCE_RATES_LIST,
    storePath: 'projects.projectsTemplatesFinanceRatesList',
  })

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE)) {
      setIsProjectRateModalOpen(false)
      callAction()
    }
  }, [successLabels, callAction])

  const renderTableBody = () => {
    if (!isLoading && ratesList.length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="4" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return ratesList.map(rate => (
      <VmoTableRow
        className="tableLink"
        key={rate.id}
        onClick={() => {
          setIdToUpdateRate(rate.id)
          setIsProjectRateModalOpen(true)
        }}
      >
        <VmoTableCell>
          <VmoHeader as="h5">{rate.user?.name}</VmoHeader>
        </VmoTableCell>
        <VmoTableCell>{rate.billableRate ? `$${rate.billableRate}` : '-'}</VmoTableCell>
        <VmoTableCell>{rate.costRate ? `$${rate.costRate}` : '-'}</VmoTableCell>
        <VmoTableCell>
          <div>{rate.updatedBy?.name}</div>
          <div>{rate.updatedAt ? getFormattedDate(rate.updatedAt) : '-'}</div>
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="kadence-side-wrap">
      <VmoTable basic>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>Name</VmoTableHeaderCell>
            <VmoTableHeaderCell>Billable Rate/H</VmoTableHeaderCell>
            <VmoTableHeaderCell>Cost Rate/H</VmoTableHeaderCell>
            <VmoTableHeaderCell>Last Updated</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>{renderTableBody()}</VmoTableBody>
      </VmoTable>

      {isProjectRateModalOpen && (
        <TemplateFinanceRateModal
          open={isProjectRateModalOpen}
          toggle={setIsProjectRateModalOpen}
          templateId={templateId}
          budgetId={budgetId}
          userId={idToUpdateRate}
        />
      )}
    </div>
  )
}

export default TemplateFinanceRates
