import {
  GET_TIMING_SCHEME,
  GET_SLA,
  DELETE_TIMING_SCHEME,
  DELETE_SLA,
  GET_ROUTING,
  GET_ROUTING_DATA,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_SLA:
      return {
        ...state,
        slaGet: action.payload,
      }

    case DELETE_SLA: {
      const updatedState = {
        ...state,
        slaGet: null,
      }
      return updatedState
    }

    case GET_TIMING_SCHEME:
      return {
        ...state,
        timingScheme: action.payload,
      }

    case DELETE_TIMING_SCHEME: {
      const updatedState = {
        ...state,
        timingScheme: null,
      }
      return updatedState
    }

    case GET_ROUTING: {
      return {
        ...state,
        routingList: action.payload,
      }
    }

    case GET_ROUTING_DATA:
      return {
        ...state,
        routingData: action.payload,
      }

    case 'CLEAR_ROUTING_DATA':
      return {
        ...state,
        routingData: null,
      }

    default:
      return state
  }
}
