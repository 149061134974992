import React, {useState} from 'react'
import {useHistory} from 'react-router'
import {FiChevronRight} from 'react-icons/fi'
import {useSelector} from 'react-redux'
import {
  VmoAccordion,
  VmoAccordionContent,
  VmoAccordionTitle,
  VmoButton,
  VmoIcon,
  VmoMenu,
  VmoMenuItem,
  VmoTooltip,
} from 'vmo-library'
import PermissionIcon from 'components/common/PermissionIcon'
import SvgIcon from 'components/common/SvgIcon'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {isActiveNavlink} from 'utils/helper'
import {useRouteMatch} from 'react-router-dom'

const ClientPortalMenu = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  const [expand, setExpand] = useState([])

  const {generalSetting = {}} = useSelector(state => state.clientPortal)
  const {enableClientPortal = false} = generalSetting

  const {clientPortalPermissions} = useUserPermissions()
  const settingsPermissions = clientPortalPermissions?.settings

  const getHref = () => {
    const accountInfo = JSON.parse(localStorage.getItem('accountInfo') || '{}')
    return `https://${accountInfo.domain || 'default'}.kadencehq.com/client-portal`
  }

  const menus = [
    {
      key: 'clientPortal',
      title: 'Client Portal',
      items: [
        {
          path: '/announcements',
          title: 'Announcements',
          icon: <SvgIcon path="clients/client-portal/announcement" />,
        },
        {
          path: '/customization',
          title: 'Customization',
          icon: <SvgIcon path="clients/client-portal/customization" />,
          isRoleAccessDenied: !settingsPermissions,
        },
      ],
    },
    {
      key: 'settings',
      title: 'Settings',
      items: [
        {
          path: '/general',
          title: 'General',
          icon: <SvgIcon path="clients/client-portal/general" />,
          isRoleAccessDenied: !settingsPermissions,
        },
        {
          path: '/tickets',
          title: 'Tickets',
          icon: <SvgIcon path="clients/client-portal/tickets" />,
          isRoleAccessDenied: !settingsPermissions,
        },
      ],
    },
  ]

  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }

  const isActive = key => !expand.includes(key)

  return (
    <VmoMenu vertical secondary className="kadence-menu">
      {menus.map(({key, title, items = []}) => (
        <VmoAccordion key={key}>
          <VmoAccordionTitle onClick={() => handleMenuExpand(key)}>
            <div className="d-flex-ac">
              {isActive(key) ? <SvgIcon path="common/down" /> : <FiChevronRight />} {title}
            </div>
          </VmoAccordionTitle>
          <VmoAccordionContent active={isActive(key)}>
            {items.map(({path, title, icon, isFeatureAccessDenied = false, isRoleAccessDenied = false}) => (
              <VmoMenuItem
                key={path}
                active={isActiveNavlink(`${url}${path}`)}
                onClick={() => history.push(`${url}${path}`)}
              >
                {icon}
                {title}
                {(isFeatureAccessDenied || isRoleAccessDenied) && (
                  <span>
                    <PermissionIcon
                      isFeatureAccessDenied={isFeatureAccessDenied}
                      isRoleAccessDenied={isRoleAccessDenied}
                    />
                  </span>
                )}
              </VmoMenuItem>
            ))}
          </VmoAccordionContent>
        </VmoAccordion>
      ))}

      <div className="prevBottom">
        {enableClientPortal ? (
          <VmoButton fluid className="basic-fill-btn mt-3" href={getHref()} target="_blank">
            <VmoIcon name="external alternate" /> Preview
          </VmoButton>
        ) : (
          <VmoTooltip
            position="top center"
            size="mini"
            content="Please Enable Client Portal"
            trigger={
              <VmoButton primary fluid className="disabled-button mt-3">
                <VmoIcon name="external alternate" /> Preview
              </VmoButton>
            }
          />
        )}
      </div>
    </VmoMenu>
  )
}

export default ClientPortalMenu
