/* eslint-disable no-case-declarations */
import {
  UPDATE_FORECAST_SETTING,
  DELETE_FORECAST,
  GET_FORECAST_DETAIL,
  GET_FORECAST_LIST,
  GET_FORECAST_SETTING,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_FORECAST_LIST: {
      return {
        ...state,
        forecastList: action.payload,
      }
    }
    case UPDATE_FORECAST_SETTING: {
      return {
        ...state,
        forecastSetting: action.payload,
      }
    }
    case GET_FORECAST_SETTING: {
      return {
        ...state,
        forecastSetting: action.payload,
      }
    }
    // case ADD_FORECAST: {
    //   return {
    //     ...state,
    //     forecastList: action.payload
    //       ? [action.payload, ...state.forecastList]
    //       : state.forecastList,
    //   };
    // }
    case DELETE_FORECAST:
      const temp = state.forecastList ? [...state.forecastList] : []
      const ids = action.payload

      return {
        ...state,
        forecastList: temp.filter(contact => ids.indexOf(contact.id) === -1),
        // leadPage: {
        //   ...state.leadPage,
        //   total: _.subtract(_.get(state, "leadPage.total"), _.size(ids)),
        // },
      }

    case GET_FORECAST_DETAIL: {
      return {
        ...state,
        forecastDetail: action.payload,
      }
    }
    default:
      return state
  }
}
