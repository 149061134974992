import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import {
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoGrid,
  VmoGridColumn,
  VmoTooltip,
} from 'vmo-library'
import SvgIcon from 'components/common/SvgIcon'
import PreviewQuote from '../Templates/PreviewQuote'

function Signature({formData: {control, watch, setValue: setFormValue}, quoteId}) {
  const [value, setValue] = useState('No Signature')
  const [publish, setPublish] = useState(true)

  const {
    quoteForm: {signature},
  } = useSelector(state => state.quotes)

  useEffect(() => {
    setFormValue('signature', value)
  }, [value])

  useEffect(() => {
    setFormValue('publish', publish)
  }, [publish])

  useEffect(() => {
    if (signature) {
      setValue(signature.signature)
      setPublish(signature.publish)
    }
  }, [signature])

  const watchAll = watch()

  return (
    <div className="stepWrap">
      <VmoGrid>
        <VmoGridColumn width={8}>
          <VmoCard fluid>
            <VmoCardContent>
              <Controller control={control} name="signature" render={({value, onChange}) => <input type="hidden" />} />
              <Controller control={control} name="publish" render={({value, onChange}) => <input type="hidden" />} />
              <VmoForm>
                <h3 className="mb-0">Signature</h3>
                <p className="mt-0 mb-4 card-description">Manage your signature settings in your quote</p>

                <VmoFormGroup grouped>
                  <div className="info-header">
                    <label>Select Signature</label>
                    <VmoTooltip
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                      content="Select the signature type you would like to include"
                    />
                  </div>
                  <VmoFormField className="m-0">
                    <VmoCheckbox
                      radio
                      label="No Signature"
                      value="No Signature"
                      checked={value === 'No Signature'}
                      onChange={(e, {value}) => setValue(value)}
                    />
                  </VmoFormField>

                  <VmoFormField className="m-0">
                    <VmoCheckbox
                      radio
                      label="Add Signature"
                      value="Add Signature"
                      checked={value === 'Add Signature'}
                      onChange={(e, {value}) => setValue(value)}
                    />
                  </VmoFormField>
                </VmoFormGroup>

                <VmoFormField>
                  <label>Publish</label>
                  <VmoFormField>
                    <VmoCheckbox
                      label="Publish this quote"
                      checked={publish}
                      onChange={(e, {checked}) => setPublish(checked)}
                    />
                  </VmoFormField>
                </VmoFormField>
              </VmoForm>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={8} className="quotePreBox">
          <PreviewQuote watch={{signature: watchAll}} />
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Signature
