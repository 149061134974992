/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useRef, useState} from 'react'
import {Controller} from 'react-hook-form'
import {
  VmoGrid,
  VmoGridColumn,
  VmoCard,
  VmoCardContent,
  VmoForm,
  VmoFormField,
  VmoTooltip,
  VmoFormSelect,
  VmoLabel,
  VmoColorPicker,
  VmoFormInput,
  VmoButton,
} from 'vmo-library'
import {getAllAddressess} from 'actions/settings_organisation'
import {useDispatch, useSelector} from 'react-redux'
import {GET_ALL_ADDRESSESS} from 'actions/types'
import {deletePoLogo} from 'actions/PurchaseOrders'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import PreviewPurchaseOrder from '../Templates/PreviewPurchaseOrder'

const templateOptions = [
  {key: 'basic', value: 1, text: 'Basic'},
  {
    key: 'standard',
    value: 2,
    text: 'Standard',
    content: (
      <>
        Standard
        <VmoLabel className="kadence-label ml-2">Recommended</VmoLabel>
      </>
    ),
  },
  {key: 'advanced', value: 3, text: 'Advanced'},
]

function Customization(props) {
  const {control, watch, style, addressTemplate, setAddressTemplate, errors, editId} = props
  const dispatch = useDispatch()
  const inputRef = useRef()
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [addressOptions, setAddressOptions] = useState([])

  const {
    uploadFile: {selectedFile, previewFile},
  } = useSelector(state => state.purchaseOrders)

  const watchColor = watch('template.color')
  const watchTemplateNo = watch('templateNo')
  const watchAll = watch()

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      // setSelectedFile(undefined)
      dispatch({type: 'SET_PO_SELECTED_FILE', payload: null})
    }

    const fileFormData = new FormData()
    fileFormData.append('logo', e.target.files[0])
    // setSelectedFile(e.target.files[0]);
    dispatch({type: 'SET_PO_SELECTED_FILE', payload: fileFormData})
    // setSelectedFile(e.target.files[0])
  }

  useEffect(() => {
    if (!selectedFile) {
      // setPreview(undefined)
      dispatch({type: 'SET_PO_PREVIEW_FILE', payload: null})
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    // setPreview(objectUrl)
    dispatch({type: 'SET_PO_PREVIEW_FILE', payload: objectUrl})

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const {data: allAddressess = []} = useApiResponse({
    action: getAllAddressess,
    enabled: true,
    label: GET_ALL_ADDRESSESS,
    storePath: 'settingsOrganisation.allAddressess',
    onSuccess: allAddressess => {
      const list = allAddressess?.map(address => ({
        value: address.id,
        text: address.companyName,
        id: address.id,
      }))
      setAddressOptions(list)
    },
  })

  return (
    <div className="stepWrap" style={style}>
      <VmoGrid>
        <VmoGridColumn width={8} className="quotePreBox">
          <VmoCard fluid>
            <VmoCardContent>
              <h3 className="mb-0">Purchase Orders Customization</h3>
              <p className="mt-0 mb-4 card-description">
                Customize purchase order to make it unique and match with your brand
              </p>
              <VmoForm className="errorLabel">
                <VmoFormField>
                  <div className="info-header">
                    <label>Choose Template</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="choose a purchase order template from the list"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    control={control}
                    name="template.templateNo"
                    render={({onChange, value}) => (
                      <VmoFormSelect
                        value={value}
                        width={7}
                        onChange={(e, {value}) => onChange(value)}
                        options={templateOptions}
                      />
                    )}
                  />
                </VmoFormField>

                <VmoFormField>
                  <div className="info-header">
                    <label>Company Logo</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Upload logo of your company"
                      size="mini"
                      psoition="top center"
                    />
                  </div>

                  {previewFile ? (
                    <>
                      <img
                        onClick={() => inputRef.current.click()}
                        accept="image/*"
                        src={previewFile}
                        alt="product"
                        height="200"
                        width="200"
                      />
                      <div>
                        <VmoTooltip
                          content="Delete"
                          size="mini"
                          position="top center"
                          trigger={
                            <VmoButton
                              icon
                              onClick={() => {
                                // setPreview(null)
                                // setSelectedFile(null)

                                if (editId) {
                                  dispatch(deletePoLogo(editId))
                                }

                                dispatch({
                                  type: 'CLEAR_PO_UPLOADFILE',
                                })
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </VmoButton>
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <VmoButton basic size="small" onClick={() => inputRef.current.click()}>
                      Upload Image
                    </VmoButton>
                  )}
                </VmoFormField>

                <VmoFormField>
                  <div className="info-header">
                    <label>Brand Color</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="set the brand color"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <div
                    style={{
                      padding: 5,
                      background: watchColor,
                      borderRadius: '1px',
                      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                      display: 'inline-block',
                      cursor: 'pointer',
                    }}
                    onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                  >
                    <div
                      style={{
                        width: '50px',
                        height: '15px',
                        borderRadius: '2px',
                        background: `#${watchColor}`,
                      }}
                    />
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '2',
                      visibility: isColorPickerOpen ? '' : 'hidden',
                    }}
                  >
                    <div
                      style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                      }}
                      onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                    />
                    <Controller
                      control={control}
                      name="template.color"
                      render={({onChange, value}) => (
                        <VmoColorPicker color={value} onChange={value => onChange(value.hex)} />
                      )}
                    />
                  </div>
                </VmoFormField>

                <VmoFormField width={14}>
                  <div className="info-header">
                    <label>Header</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention a text to display on header of purchase order"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    control={control}
                    name="template.header"
                    render={({value, onChange}) => (
                      <VmoFormInput
                        maxLength={20}
                        placeholder="Enter header text"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                      />
                    )}
                  />
                </VmoFormField>

                <VmoFormField width={14}>
                  <div className="info-header">
                    <label>Footer</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="mention a text to display on footer of purchase order"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    control={control}
                    name="template.footer"
                    render={({value, onChange}) => (
                      <VmoFormInput
                        maxLength={20}
                        placeholder="Enter Footer text"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                      />
                    )}
                  />
                </VmoFormField>

                <VmoFormField width={14}>
                  <div className="info-header">
                    <label className="label-class">Address</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Specify an address to display on purchase order"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="template.addressId"
                    render={({onChange, value}) => (
                      <VmoFormSelect
                        fluid
                        selection
                        options={addressOptions}
                        placeholder="Address"
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                          setAddressTemplate(
                            allAddressess?.filter(({id}) => {
                              return id === value
                            })
                          )
                        }}
                        error={
                          errors?.template?.addressId && {
                            content: removeDoubleQuotes(errors?.template?.addressId?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>
                <input hidden type="file" accept=".png, .jpg, .jpeg" onChange={onSelectFile} ref={inputRef} />
              </VmoForm>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={8} className="quotePreBox">
          <PreviewPurchaseOrder
            watch={{template: {logo: previewFile, ...watchAll}}}
            addressTemplate={addressTemplate}
            liveNumber={watchTemplateNo}
          />
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Customization
