import {CHANGE_EMAIL, GET_VERIFICATION, RESEND_VERIFICATION, SET_PASSWORD, VERIFICATION} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case RESEND_VERIFICATION:
      return {
        ...state,
        resendVerificationResponseData: action.payload,
      }

    case GET_VERIFICATION:
      return {
        ...state,
        verificationData: action.payload,
      }
    case CHANGE_EMAIL:
      return {
        ...state,
        changeEmailData: action.payload,
      }

    case VERIFICATION:
      return {
        ...state,
        verificationOTPData: action.payload,
      }
    case SET_PASSWORD:
      return {
        ...state,
        setPwdData: action.payload,
      }

    default:
      return state
  }
}
