import React, {useCallback} from 'react'
import {FaCalendar} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectsTimesheetTimelogsByProjectList, getProjectsTimesheetTimelogsChart} from 'actions/analytics_projects'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {getGridYValuesMultipleBar, maxOfGraphMultipleBar} from 'utils/graph'
import {ResponsiveBar} from '@nivo/bar'
import CustomLoader from 'components/common/CustomLoader'
import {GET_PROJECTS_TIMESHEET_TIMELOGS_BY_PROJECT_LIST, GET_PROJECTS_TIMESHEET_TIMELOGS_CHART} from 'actions/types'
import Moment from 'moment'
import {get, startCase} from 'utils/helper'
import NoDataChart from 'components/common/NoDataChart'
import {ResponsivePie} from '@nivo/pie'
import SvgIcon from 'components/common/SvgIcon'
import {ProjectDropdown} from '../../AnalyticsUtils'
import DateRangeGroup from '../../DateRangeGroup'

const TimesheetByProjects = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)
  const {
    analyticsSelectedProject,
    projectsTimesheetTimelogsChartData = {},
    projectsTimesheetTimelogsByProjectList = [],
  } = useSelector(state => state.analyticsProjects)
  const {formattedData = [], piechartData = [], summaryData = {}} = projectsTimesheetTimelogsChartData

  const actionsToDispatch = useCallback(
    data => {
      dispatch(getProjectsTimesheetTimelogsChart(data))
      dispatch(getProjectsTimesheetTimelogsByProjectList(data))
    },
    [dispatch]
  )

  const getFormattedTime = minutes => {
    const duration = Moment.duration(minutes, 'minutes')
    const hour = Math.floor(duration.asHours())
    const minute = duration.minutes()

    const formattedDuration = `${hour}h ${minute}m`
    return formattedDuration
  }

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_BY_PROJECT_LIST)) {
      return <VmoTablePlaceholder columns={8} />
    }
    if (
      !type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_BY_PROJECT_LIST) &&
      !projectsTimesheetTimelogsByProjectList.length
    ) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="8" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return projectsTimesheetTimelogsByProjectList.map(project => (
      <VmoTableRow key={project.id} className="tableLink">
        <VmoTableCell>{startCase(project.title)}</VmoTableCell>
        <VmoTableCell>{project.managerName}</VmoTableCell>
        <VmoTableCell>{get(['timeData', 'loggedHours'], project, '-')}</VmoTableCell>
        <VmoTableCell>{get(['timeData', 'billableHours'], project, '-')}</VmoTableCell>
        <VmoTableCell>{get(['timeData', 'billedHours'], project, '-')}</VmoTableCell>
        <VmoTableCell>{get(['timeData', 'nonbillableHours'], project, '-')}</VmoTableCell>
        <VmoTableCell>
          <div style={{width: '130px'}}>
            <p className="d-flex mb-1">
              <span>{get(['timeData', 'billablePercentage'], project, 0)}%</span>
              <span>{get(['timeData', 'nonBillablePercentage'], project, 0)}%</span>
            </p>
            <div className="budget-progress">
              <div
                className="budget-progress-completed"
                style={{width: `${get(['timeData', 'billablePercentage'], project, 0).toFixed(2)}%`}}
              />
            </div>
          </div>
        </VmoTableCell>
        <VmoTableCell>
          {project.isBudgetCreated ? (
            <div style={{width: '130px'}}>
              <p className="mb-0">{get(['budgetData', 'usedPercentage'], project, 0).toFixed(2)}%</p>
              <div className="budget-progress">
                <div
                  className="budget-progress-completed"
                  style={{width: `${get(['budgetData', 'usedPercentage'], project, 0).toFixed(2)}%`}}
                />
              </div>
              <p>
                ${get(['budgetData', 'usedAmount'], project, 0)} 0f ${get(['budgetData', 'totalAmount'], project, 0)}
              </p>
            </div>
          ) : (
            '-'
          )}
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="kadence-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <FaCalendar />
            <h5>Timesheet by Projects</h5>
          </div>
        </div>
        <div className="page-action">
          <ProjectDropdown />
          <DateRangeGroup
            actionsToDispatch={actionsToDispatch}
            projectId={
              analyticsSelectedProject && analyticsSelectedProject !== 'all' ? Number(analyticsSelectedProject) : null
            }
          />
        </div>
      </div>

      <VmoGrid>
        <VmoGridColumn width={10}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Timesheets</VmoCardHeader>
              <VmoTooltip
                content="lorem ipsum"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 367}}>
              {type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_CHART) && <CustomLoader />}
              {!type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_CHART) &&
                formattedData.every(({added, deleted}) => added === 0 && deleted === 0) && (
                  <div className="empty-chart">No Data Available</div>
                )}
              {!type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_CHART) && (
                <ResponsiveBar
                  data={formattedData}
                  keys={['Billable', 'Non Billable', 'Billed']}
                  indexBy="time"
                  margin={{top: 37, right: 27, bottom: 70, left: 70}}
                  padding={0.3}
                  minValue={0}
                  maxValue={maxOfGraphMultipleBar(formattedData, ['Billable', 'Non Billable', 'Billed'])}
                  gridYValues={getGridYValuesMultipleBar(formattedData, ['Billable', 'Non Billable', 'Billed'])}
                  groupMode="grouped"
                  valueScale={{type: 'linear'}}
                  indexScale={{type: 'band', round: true}}
                  colors={d => d.data[`${d.id}Color`]}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 60,
                  }}
                  axisLeft={{
                    format: value => getFormattedTime(value),
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesMultipleBar(formattedData, ['Billable', 'Non Billable', 'Billed']),
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: -65,
                  }}
                  enableLabel={false}
                  labelSkipHeight={1}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -40,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  tooltip={e => {
                    const {label, value} = e
                    return (
                      <div style={{backgroundColor: 'white', padding: '3px 6px 3px 6px', borderRadius: '2px'}}>
                        {label} : <stong>{getFormattedTime(value)}</stong>
                      </div>
                    )
                  }}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>

        <VmoGridColumn width={6}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>pie data</VmoCardHeader>
              <VmoTooltip
                content="Overview of the success rate of search queries for the selected time range"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0 pb-0" style={{height: 300}}>
              {type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_CHART) && <CustomLoader />}
              {!type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_CHART) &&
                (!piechartData.every(({value}) => value === 0) ? (
                  <ResponsivePie
                    data={piechartData}
                    margin={{top: 20, right: 80, bottom: 20, left: 80}}
                    startAngle={-90}
                    endAngle={90}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={0}
                    borderWidth={1}
                    colors={d => d.data.color}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    arcLinkLabel={e => `${e.id} (${e.value})`}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{from: 'color'}}
                    arcLabelsSkipAngle={10}
                    enableArcLabels={false}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]],
                    }}
                    tooltip={function (e) {
                      const {id, value} = e.datum
                      return (
                        <strong style={{backgroundColor: 'white', padding: '3px 6px 3px 6px', borderRadius: '2px'}}>
                          {id} : {value}%
                        </strong>
                      )
                    }}
                  />
                ) : (
                  <NoDataChart top="30px" />
                ))}
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <div className="d-flex text-center">
                <div>
                  <p>Billable</p>
                  <p>{summaryData.billableTimeLog}</p>
                </div>
                <div>
                  <p>Total</p>
                  <p>{summaryData.totalTimeLog}</p>
                </div>
                <div>
                  <p>Non Billable</p>
                  <p>{summaryData.nonBilableTimeLOg}</p>
                </div>
              </div>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Timesheet by Projects</VmoCardHeader>
              <VmoTooltip
                content="lorem ipsum"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>Project Name</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Owner</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Logged Time</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Billable Time</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Billed Time</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Non Billable Time</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Billable vs Non Billable</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Budget</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>{renderTableBody()}</VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default TimesheetByProjects
