/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Editor from 'react-simple-code-editor'
import {highlight, languages} from 'prismjs'
import 'prismjs/themes/prism.css'
import {
  VmoButton,
  VmoDivider,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  vmoToast,
  VmoTooltip,
} from 'vmo-library'
import {GET_ALL_MAIL_INBOX, GET_INSTALLATION_WIDGET, GET_TEAMS_LIST, GET_USERS, UPDATE_INBOX} from 'actions/types'
import {getAllMailInbox, updateInbox} from 'actions/inbox'
import {GetInstallationWidget} from 'actions/settings_messenger'
import {getAllTeams} from 'actions/settings_organisation'
import SettingLayout from 'layouts/settingLayout'
import {copyToClipboardWithoutInput} from 'utils/helper'
import {ChatMessengerSchema} from 'validation/Settings/channels/chatMessenger.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getUsers} from 'actions/settings'

export const ChatMessenger = () => {
  const dispatch = useDispatch()

  const [allAssigness, setAllAssigness] = useState([])
  const [allTeam, setAllTeam] = useState([])
  const [code, setCode] = useState('')
  const [copySuccess, setCopySuccess] = useState('COPY')

  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {inbox} = useSelector(store => store.mailInbox)
  const {installation} = useSelector(store => store.settingsMessenger)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const chatMessengerLearnMore = settingsLearnMoreUrl?.channels?.chatMessenger?.learnMore
  const setupInstructionLearnMore = settingsLearnMoreUrl?.channels?.chatMessenger?.setupInstructionLearnMore

  const {channelsPermissions} = useUserPermissions()
  const manageMessangerPagesPermissions = channelsPermissions?.manage_messanger_pages

  const {
    handleSubmit,
    errors,
    control,
    formState: {isDirty},
    setValue,
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(ChatMessengerSchema),
    defaultValues: {
      name: '',
      assignees: [],
      teams: [],
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setAllAssigness(list || [])
    },
  })

  useApiResponse({
    action: getAllTeams,
    enabled: true,
    label: GET_TEAMS_LIST,
    storePath: 'settingsOrganisation.teamsList',
    onSuccess: teamsList => {
      const list = teamsList.map(team => ({
        key: team.id,
        value: team.id,
        text: team.name,
      }))
      setAllTeam(list || [])
    },
  })

  useEffect(() => {
    dispatch(GetInstallationWidget())
    dispatch(getAllMailInbox({type: 'web'}))
  }, [dispatch])

  const resetData = () => {
    if (inbox?.[0]) {
      setValue('name', inbox[0].name)
      setValue(
        'assignees',
        inbox[0].assignees?.map(d => d.id)
      )
      setValue(
        'teams',
        inbox[0].teams?.map(d => d.id)
      )
      return
    }
    vmoToast.error('Inbox Data Not Found')
  }

  useEffect(() => {
    if (successLabels.includes(GET_ALL_MAIL_INBOX)) {
      if (inbox?.[0]) {
        resetData()
      }
    }
  }, [successLabels, setValue, inbox])

  useEffect(() => {
    if (installation?.[0]?.username) {
      setCode(`      <link
      rel="stylesheet"
      href="https://s3.amazonaws.com/app.kadencehq.com/kadence-widget.css"
      />
      <div
        id="__kadence_widget__"
        class="__kadence_widget__ shadow position kadence-exWidget"
        data-form="${installation?.[0]?.username}"
        data-host="https://widget.kadencehq.com"
        data-csshost="https://api.kadencehq.com"
      ></div>
      <script
        type="text/javascript"
        src="https://s3.amazonaws.com/app.kadencehq.com/kadence-widget.js"
      ></script>
    `)
    }
  }, [installation])

  const submitData = data => {
    const payload = {
      name: data.name,
      assigneeIds: data.assignees,
      teamIds: data.teams,
    }
    if (inbox?.[0]?.id) {
      dispatch(updateInbox(payload, inbox?.[0]?.id))
      return
    }
    vmoToast.error('Inbox Ids Not Found')
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/chat-messenger" />}
      header="Chat Messenger"
      subHeader="Manage your chat messenger and integrate it by pasting code snippet in your website"
      learnMoreUrl={chatMessengerLearnMore}
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Reset',
          disabled: !isDirty,
          onClick: resetData,
        },
        success: {
          content: 'Save',
          disabled: !isDirty,
          loading: isLoadingData && (type.includes(GET_INSTALLATION_WIDGET) || type.includes(UPDATE_INBOX)),
          ...(manageMessangerPagesPermissions && {
            onClick: handleSubmit(submitData),
          }),
        },
      }}
      lockRole={!manageMessangerPagesPermissions}
    >
      <VmoForm className="errorLabel">
        <VmoFormField width={8} required>
          <div className="info-header">
            <label>Name</label>
            <VmoTooltip
              content="Specify a name to chat messenger"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="name"
            control={control}
            render={({onChange, value, onBlur}) => (
              <VmoFormInput
                maxLength={25}
                placeholder="Name"
                value={value}
                onChange={e => onChange(e.target.value)}
                onBlur={onBlur}
                error={
                  errors.name && {
                    content: errors.name.message,
                  }
                }
              />
            )}
          />
        </VmoFormField>
        <VmoFormGroup widths="equal">
          <VmoFormField required>
            <div className="info-header">
              <label>Assignee</label>
              <VmoTooltip
                content="Assign agent for the chat messenger"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="assignees"
              control={control}
              render={({onChange, value}) => (
                <VmoFormSelect
                  required
                  placeholder="Assignee"
                  multiple
                  search
                  options={allAssigness}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.assignees && {
                      content: errors.assignees.message,
                    }
                  }
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required>
            <div className="info-header">
              <label>Teams</label>
              <VmoTooltip
                content="Assign teams for chat messenger"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="teams"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  search
                  fluid
                  multiple
                  value={value}
                  options={allTeam}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.teams && {
                      content: errors.teams.message,
                    }
                  }
                />
              )}
            />
          </VmoFormField>
        </VmoFormGroup>
      </VmoForm>
      <VmoDivider className="my-2" />
      <h3 className="mb-1">Embed code</h3>
      <p className="mb-3">
        Copy and paste the code into your body tab of the HTML of your webpage where you wish to integrate this
        messenger. For more detailed and step by step guide to integrate the messenger{' '}
        <a href={setupInstructionLearnMore}>click here</a>
      </p>
      <div className="codeTextView">
        <Editor
          disabled
          value={decodeURIComponent(code)}
          highlight={code => highlight(code, languages.html)}
          padding={10}
          textareaId="codeArea"
          style={{
            border: '1px solid #e2e7f2',
            fontFamily: 'monospace',
            fontSize: 16,
            outline: 0,
            background: '#f9fbfd',
            borderRadius: 10,
          }}
        />
        <div className="setActionBtn">
          <VmoButton
            size="tiny"
            primary
            onClick={() => copyToClipboardWithoutInput(decodeURIComponent(code), setCopySuccess)}
          >
            {copySuccess}
          </VmoButton>
        </div>
      </div>
    </SettingLayout>
  )
}
