const conditionFieldOptions = [
  {key: 'firstName', value: 'firstName', text: 'First Name', disabled: false},
  {key: 'lastName', value: 'lastName', text: 'Last Name', disabled: false},
  {key: 'email', value: 'email', text: 'Email', disabled: false},
  {key: 'phone', value: 'phone', text: 'Phone Number', disabled: false},
  {
    key: 'description',
    value: 'description',
    text: 'Description',
    disabled: false,
  },
  {key: 'title', value: 'title', text: 'Title', disabled: false},
  {key: 'company', value: 'company', text: 'Company', disabled: false},
  {key: 'assignee', value: 'assignee', text: 'Assignee', disabled: false},
  {key: 'website', value: 'website', text: 'Website', disabled: false},
  {
    key: 'monetaryValue',
    value: 'monetaryValue',
    text: 'Monetary Value',
    disabled: false,
  },
  {key: 'locality', value: 'locality', text: 'Locality', disabled: false},
  {key: 'state', value: 'state', text: 'State', disabled: false},
  {
    key: 'postalCode',
    value: 'postalCode',
    text: 'Postal Code',
    disabled: false,
  },
  {key: 'country', value: 'country', text: 'Country', disabled: false},
  {key: 'tags', value: 'tags', text: 'Tags', disabled: false},
  {key: 'statusId', value: 'statusId', text: 'Status', disabled: false},
  {key: 'sourcesId', value: 'sourcesId', text: 'Source', disabled: false},
  {
    key: 'socialLinks',
    value: 'socialLinks',
    text: 'Social Links',
    disabled: false,
  },
  {
    key: 'contactType',
    value: 'contactType',
    text: 'Contact Type',
    disabled: false,
  },
  {key: 'name', value: 'name', text: 'Name', disabled: false},
  {key: 'domain', value: 'domain', text: 'Domain', disabled: false},
  {key: 'stage', value: 'stage', text: 'Stage', disabled: false},
  {key: 'contactId', value: 'contactId', text: 'Contact', disabled: false},
  {key: 'priority', value: 'priority', text: 'Priority', disabled: false},
  {key: 'win', value: 'win', text: 'Win', disabled: false},
  {
    key: 'interactions',
    value: 'interactions',
    text: 'Interactions',
    disabled: false,
  },
  {key: 'closedAt', value: 'closedAt', text: 'Closed At', disabled: false},
]

export const contactsConditionFieldOptions = [
  ...conditionFieldOptions.slice(0, 9),
  ...conditionFieldOptions.slice(10, 15),
  conditionFieldOptions[18],
  conditionFieldOptions[17],
]

export const companiesConditionFieldOptions = [
  conditionFieldOptions[19],
  conditionFieldOptions[20],
  conditionFieldOptions[2],
  conditionFieldOptions[3],
  conditionFieldOptions[4],
  conditionFieldOptions[8],
  conditionFieldOptions[10],
  conditionFieldOptions[11],
  conditionFieldOptions[12],
  conditionFieldOptions[13],
  conditionFieldOptions[14],
  conditionFieldOptions[17],
  conditionFieldOptions[18],
  conditionFieldOptions[7],
]

export const dealsConditionFieldOptions = [
  conditionFieldOptions[19],
  conditionFieldOptions[4],
  conditionFieldOptions[7],
  conditionFieldOptions[14],
  conditionFieldOptions[21],
  conditionFieldOptions[22],
  conditionFieldOptions[16],
  conditionFieldOptions[9],
  conditionFieldOptions[23],
  conditionFieldOptions[24],
  conditionFieldOptions[25],
  conditionFieldOptions[26],
]

export const operatorOptionsEq = [
  {value: '=', text: 'Equals', key: '='},
  {value: '!=', text: 'Not Equals', key: '!='},
]

export const operatorOptionsEqC = [...operatorOptionsEq, {value: '<>', text: 'Contains', key: '<>'}]

export const operatorsOptions = [
  ...operatorOptionsEqC,
  {value: '>', text: 'Greater Than', key: '>'},
  {value: '<', text: 'Less Than', key: '<'},
  {value: '>=', text: 'Greater than and equal to', key: '>='},
  {value: '<=', text: 'Less than and equal to', key: '<='},
]
