import React, {useEffect, useState} from 'react'
import {
  VmoButton,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {DELETE_TIMEOFF_TYPE, GET_TIMEOFF_TYPES} from 'actions/types'
import {deleteTimeOffTypes, getTimeOffTypes} from 'actions/timeOff'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create.svg'
import {useDispatch, useSelector} from 'react-redux'
import {FiList} from 'react-icons/fi'
import {startCase} from 'utils/helper'
import TimeOffSidemenu from 'navigation/sub-routes/Hr/TimeOff/TimeOffMenu'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import TimeOffTypes from './CreateTimeOffTypes'

function TimeOffTypesList() {
  const dispatch = useDispatch()

  const [deleteModal, setDeleteModal] = useState(false)
  const [typesId, setTypesId] = useState()
  const [typeModal, setTypeModal] = useState(false)

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_TIMEOFF_TYPES})

  const {data: getTimeOffTypesData = []} = useApiResponse({
    action: getTimeOffTypes,
    enabled: true,
    label: GET_TIMEOFF_TYPES,
    storePath: 'timeOff.getTimeOffTypesData',
  })

  const typesProps = {
    open: typeModal,
    toggleModal: setTypeModal,
    typeToggle: setTypesId,
  }
  if (typesId) {
    typesProps.editId = typesId
  }

  useEffect(() => {
    if (successLabels.includes(DELETE_TIMEOFF_TYPE)) {
      dispatch(getTimeOffTypes())
    }
  }, [successLabels, dispatch])

  const actionButton = () => {
    return getTimeOffTypesData && !getTimeOffTypesData.length
      ? null
      : {
          success: {
            content: 'Add Type',
            onClick: () => {
              setTypesId()
              setTypeModal(true)
            },
          },
        }
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <TimeOffSidemenu />

      <SettingLayout
        header="Time Off Types"
        subHeader="Time Off Types"
        table
        headerDivider
        actionButton={actionButton()}
      >
        {getTimeOffTypesData && !getTimeOffTypesData.length ? (
          <div className="mt-4">
            {typeModal ? <TimeOffTypes {...typesProps} /> : null}

            <CreateFirst
              src={<FiList />}
              header="Type"
              subHeader="Diversifying assets helps reduce the chance of loss of capital. Investment in debt instruments assures fixed return and safety of capital, having this as part of your portfolio ensures capital preservation."
              addFirst={() => {
                setTypesId()
                setTypeModal(true)
              }}
              buttonText="Add Type"
              Feature1="Organize your assets better"
              Feature2="Keep track and reporting according to the category"
              Feature3="Assets prevention is better"
              list1="Sorting assets according to their properties help organize it better and helps in tracking if number of assets are more"
              list2="Veemo support features detailed analytics with respect to the properties of the assets so it helps the company in making decision based on statistics"
              list3="Managing assets in veemo support will help in case of asset theft or missing asset situations and hence is more reliable"
              imageSrc={create}
            />
          </div>
        ) : (
          <>
            {deleteModal && (
              <DeleteModal
                idTobeDeleted={typesId}
                deleteAction={deleteTimeOffTypes}
                isModalOpen={deleteModal}
                setIsModalOpen={setDeleteModal}
                modalText="Are you sure you want to delete this Category?"
                type={DELETE_TIMEOFF_TYPE}
              />
            )}
            {typeModal ? <TimeOffTypes {...typesProps} /> : null}

            <VmoTable sortable basic selectable>
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell>#</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Approval</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Created by</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Actions</VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <VmoTableBody>
                {isLoadingData && type.includes(GET_TIMEOFF_TYPES) ? (
                  <VmoTablePlaceholder columns={4} rows={4} />
                ) : (
                  getTimeOffTypesData &&
                  getTimeOffTypesData.map(({color, name, approvalRequire, createdBy, id}, index) => (
                    <VmoTableRow
                      className="tableLink"
                      onClick={() => {
                        setTypeModal(true)
                        setTypesId(id)
                      }}
                      key={id}
                    >
                      <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
                      <VmoTableCell>
                        <div className="d-flex" style={{justifyContent: 'flex-start'}}>
                          <div className="timroff-color" style={{'--timeoff-type-color': color}} />
                          <span>{startCase(name)}</span>
                        </div>
                      </VmoTableCell>
                      <VmoTableCell>{approvalRequire === true ? 'Yes' : 'No'}</VmoTableCell>
                      <VmoTableCell>{startCase(createdBy?.name)}</VmoTableCell>
                      <VmoTableCell>
                        <VmoTooltip
                          content="Delete"
                          size="mini"
                          position="top center"
                          trigger={
                            <VmoButton
                              icon
                              onClick={e => {
                                setTypesId(id)
                                setDeleteModal(true)
                                e.stopPropagation()
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </VmoButton>
                          }
                        />
                      </VmoTableCell>
                    </VmoTableRow>
                  ))
                )}
              </VmoTableBody>
            </VmoTable>
          </>
        )}
      </SettingLayout>
    </>
  )
}

export default TimeOffTypesList
