import Joi from 'joi'
import {inputStringValidation} from 'validation'

export const announcementSchema = Joi.object({
  displayTo: Joi.string().valid('all', 'companies', 'tags', 'specific', 'status', 'contact_type').required().messages({
    'any.only': 'Display to must be specified',
  }),
  companyIds: Joi.array().when('displayTo', {
    is: 'companies',
    then: Joi.array().items(Joi.number()).required().min(1).messages({
      'array.min': 'Company must be specified',
    }),
  }),
  tagIds: Joi.array().when('displayTo', {
    is: 'tags',
    then: Joi.array().items(Joi.number()).required().min(1).messages({
      'array.min': 'Tag must be specified',
    }),
  }),
  contactIds: Joi.array().when('displayTo', {
    is: 'specific',
    then: Joi.array().items(Joi.number()).required().min(1).messages({
      'array.min': 'Contact must be specified',
    }),
  }),
  statusIds: Joi.array().when('displayTo', {
    is: 'status',
    then: Joi.array().items(Joi.number()).required().min(1).messages({
      'array.min': 'Status must be specified',
    }),
  }),
  contactTypeIds: Joi.array().when('displayTo', {
    is: 'contact_type',
    then: Joi.array().items(Joi.number()).required().min(1).messages({
      'array.min': 'Type must be specified',
    }),
  }),
  title: inputStringValidation.required(),
  announcement: Joi.string().required(),
  isScheduled: Joi.boolean(),
  scheduledDateTime: Joi.any().when('isScheduled', {
    is: true,
    then: Joi.date().required(),
  }),
  enableUnPublish: Joi.boolean(),
  unpublishDate: Joi.any().when('enableUnPublish', {
    is: true,
    then: Joi.date().required(),
  }),
})

export const customizationSchema = Joi.object({
  heading: Joi.string().allow('', null),
  subHeading: Joi.string().allow('', null),
  logo: Joi.string().allow('', null),
  backgroundColor: Joi.string().allow('', null),
  textColor: Joi.string().allow('', null),
  textOnPortal: Joi.string().allow('', null),
  textOnLoginPage: Joi.string().allow('', null),
})

export const generalSchema = Joi.object({
  // enableClientPortal: Joi.boolean(),
  enableWellcomeText: Joi.boolean(),
  wellcomeText: Joi.string().allow('', null),
  enablePortalIcon: Joi.boolean(),
  enableTicket: Joi.boolean(),
  ticketStatusView: Joi.object({
    viewTicketsOf: Joi.string().when(Joi.ref('...enableTicket'), {
      is: true,
      then: Joi.string().valid('contact', 'company').required(),
      otherwise: Joi.string().allow('', null),
    }),
    defaultInbox: Joi.any().when(Joi.ref('...enableTicket'), {
      is: true,
      then: Joi.array().items(Joi.number()).min(1).required(),
    }),
  }),
  enableAnnouncements: Joi.boolean(),
  enableKnowledgebase: Joi.boolean(),
  docId: Joi.any().when('enableKnowledgebase', {
    is: true,
    then: Joi.number().required().messages({
      'number.base': 'Knowledge base must be specified',
    }),
    otherwise: Joi.any().allow('', null),
  }),
  enableProject: Joi.boolean(),
  enableInvoiceAndQuotes: Joi.boolean(),
  showQuickLinks: Joi.boolean(),
  // quickLinksHeading: Joi.any().when('showQuickLinks', {
  //   switch: [
  //     {
  //       is: true,
  //       then: Joi.string().trim().min(3).max(20).required().label('Heading'),
  //     },
  //   ],
  // }),
  // quickLinks: Joi.array().items(
  //   Joi.object().keys({
  //     name: Joi.string().min(3).max(20).required().messages({
  //       'string.empty': 'Name is not allowed to be empty',
  //       'string.min': `Length must be at least 3 characters`,
  //       'string.max': `Length must be less or equal to 20 characters`,
  //     }),
  //     url: Joi.string().uri().required().messages({
  //       'string.empty': 'Url is not allowed to be empty',
  //       'string.uri': 'Url must be a valid uri',
  //     }),
  //   })
  // ),
  quickLinks: Joi.array().when('showQuickLinks', {
    is: true,
    then: Joi.array().items(
      Joi.object().keys({
        name: Joi.string().min(3).max(20).required().messages({
          'string.empty': 'Name is not allowed to be empty',
          'string.min': `Length must be at least 3 characters`,
          'string.max': `Length must be less or equal to 20 characters`,
        }),
        url: Joi.string().uri().required().messages({
          'string.empty': 'Url is not allowed to be empty',
          'string.uri': 'Url must be a valid uri',
        }),
      })
    ),
  }),
})

export const ticketsSchema = Joi.object({
  enableTicketSubmit: Joi.boolean(),
  heading: Joi.string().when('enableTicketSubmit', {is: true, then: inputStringValidation.required()}),
  enableSubject: Joi.boolean().when('enableTicketSubmit', {is: true, then: Joi.boolean().required()}),
  enableMessage: Joi.boolean().when('enableTicketSubmit', {is: true, then: Joi.boolean().required()}),
  allowAttchement: Joi.boolean().when('enableTicketSubmit', {is: true, then: Joi.boolean().required()}),
  submitButtonText: Joi.string().when('enableTicketSubmit', {
    is: true,
    then: inputStringValidation.label('Submit button text').required(),
  }),
  submitMessage: Joi.string().when('enableTicketSubmit', {
    is: true,
    then: Joi.string().trim().min(2).max(50).messages({
      'string.min': `Length must me at least 2 charactes`,
      'string.max': `Length must be less than or equal to 50 characters`,
    }),
  }),
  inboxId: Joi.any().when('enableTicketSubmit', {
    is: true,
    then: Joi.number().required().messages({
      'number.base': 'Inbox must be specified',
    }),
  }),
})
