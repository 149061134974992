/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiChevronRight} from 'react-icons/fi'
import {ResponsiveBar} from '@nivo/bar'
import {ResponsivePie} from '@nivo/pie'
import _ from 'lodash'
import moment from 'moment'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoDropdown,
  VmoDropdownHeader,
  VmoGrid,
  VmoGridColumn,
  VmoGridRow,
  VmoTooltip,
} from 'vmo-library'

import {GOAL_KPI_BREAK_DOWN, BREAK_DOWN_BY_KPI, FORECAST_SUMMARY} from 'actions/types'
import {breakDownByKpi, goalKpiBreakdown, forecastSummary} from 'actions/reports_forecast'
import {getGridYValuesBar, getGridYValuesMultipleBar, maxOfGraphBar} from 'utils/graph'
import {KpiList} from 'constants/sales/forecast'
import NoDataChart from 'components/common/NoDataChart'
import CustomLoader from 'components/common/CustomLoader'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import {getAllTeams} from 'actions/settings_organisation'

const ForecastAnalytics = () => {
  const dispatch = useDispatch()

  const [breakDownUserData, setBreakDownUserData] = useState({})
  const [forecastSummaryUserData, setForecastSummaryUserData] = useState({})
  const [userOption, setUserOption] = useState([])
  const [breakDownUser, setBreakDownUser] = useState('all')
  const [forecastSummaryUser, setForecastSummaryUser] = useState('all')
  const [forecastSummaryKpi, setForecastSummaryKpi] = useState('all')
  const [forecastSummaryYear, setForecastSummaryYear] = useState(() => parseInt(moment().format('YYYY')))

  const dependencies = [breakDownUser, forecastSummaryUser, forecastSummaryKpi, forecastSummaryYear]

  const {getUsersList = {}} = useSelector(state => state.settings)
  const {type = []} = useSelector(state => state.apiReducer)
  const {analyticsAccess} = useUserPermissions()
  const forecastingAccess = analyticsAccess?.forecasting

  const {
    breakdownByKpiData = [],
    forecastSummaryData = [],
    goalAchviedBreakdownKpiData: {forecastDetail: {achieved = 0, target = 0, kpis = [], createdAt = ''} = {}} = {},
  } = useSelector(state => state.reportsForecast)
  const {teamsList} = useSelector(state => state.settings)
  const {reportsPermissions} = useUserPermissions()
  const revenueReportsPermissions = reportsPermissions?.revenue_reports

  useEffect(() => {
    if (forecastingAccess && revenueReportsPermissions) {
      dispatch(getUsers())
      dispatch(getAllTeams())
    }
  }, [dispatch, forecastingAccess, revenueReportsPermissions])

  useEffect(() => {
    if (getUsersList) {
      const usersList = getUsersList.users.map(list => ({
        value: list.id,
        key: list.id,
        text: `${list.firstName} ${list.lastName}`,
        isUser: true,
      }))

      setUserOption(usersList)
    }

    if (teamsList) {
      const teams = teamsList.map(team => ({
        key: team.id,
        value: team.id,
        text: team.name,
        isTeam: true,
      }))
      if (teams.length) {
        setUserOption(list => [...list, <VmoDropdownHeader>Teams</VmoDropdownHeader>, ...teams])
      }
    }
  }, [getUsersList, teamsList])

  const actionsToDispatch = useCallback(
    data => {
      dispatch(goalKpiBreakdown(data))
      setBreakDownUserData(data)
      setForecastSummaryUserData(data)
    },
    [dispatch]
  )

  useEffect(() => {
    if (!revenueReportsPermissions) return
    if (typeof breakDownUser !== 'string') {
      dispatch(breakDownByKpi({...breakDownUserData, ...breakDownUser}))
    } else {
      dispatch(breakDownByKpi(breakDownUserData))
    }
  }, [revenueReportsPermissions, breakDownUserData, breakDownUser, dispatch])

  useEffect(() => {
    if (!revenueReportsPermissions) return
    if (typeof forecastSummaryUser !== 'string') {
      dispatch(
        forecastSummary({
          ...forecastSummaryUserData,
          ...forecastSummaryUser,
          ...(forecastSummaryKpi !== 'all' && {kpi: forecastSummaryKpi}),
          year: forecastSummaryYear,
        })
      )
    } else if (forecastSummaryKpi !== 'all') {
      dispatch(
        forecastSummary({
          ...forecastSummaryUserData,
          kpi: forecastSummaryKpi,
          ...(typeof forecastSummaryUser !== 'string' && forecastSummaryUser),
          year: forecastSummaryYear,
        })
      )
    } else if (forecastSummaryYear) {
      dispatch(
        forecastSummary({
          ...forecastSummaryUserData,
          ...(forecastSummaryKpi !== 'all' && {kpi: forecastSummaryKpi}),
          ...(typeof forecastSummaryUser !== 'string' && forecastSummaryUser),
          year: forecastSummaryYear,
        })
      )
    } else {
      dispatch(forecastSummary(forecastSummaryUserData))
    }
  }, [
    revenueReportsPermissions,
    forecastSummaryUserData,
    forecastSummaryUser,
    forecastSummaryKpi,
    forecastSummaryYear,
    dispatch,
  ])

  return (
    <div className="kadence-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="common/calendar" />
            <h5>Forecast Analytics</h5>
          </div>
        </div>
        {forecastingAccess && revenueReportsPermissions && (
          <div className="pageAction">
            <DateRangeGroup actionsToDispatch={actionsToDispatch} dependencies={dependencies} />
          </div>
        )}
      </div>
      <VmoGrid divided="vertically">
        <VmoGridRow columns={3}>
          <VmoGridColumn>
            <VmoCard fluid className="chartBox">
              <VmoCardContent>
                <div className="valueBox">
                  <VmoCardContent className="headerBox">
                    <VmoCardHeader>Goal achieved</VmoCardHeader>
                    <VmoTooltip
                      content="Number of goals achieved"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </VmoCardContent>
                  <h3>
                    {target}
                    <VmoTooltip
                      content="Total"
                      size="mini"
                      position="bottom center"
                      trigger={
                        <span
                          className={
                            _.get('target', `${((achieved * 100) / target).toFixed(1)}`, 0) >= 0 ? '' : 'text-danger'
                          }
                        >
                          {_.get('target', `${((achieved * 100) / target).toFixed(1)}`, 0) >= 0 ? (
                            <SvgIcon path="common/arrow-up-right" />
                          ) : (
                            <SvgIcon path="common/arrow-down-left" className="down-left" />
                          )}
                          {target ? ((achieved * 100) / target).toFixed(1) : 0}%
                        </span>
                      }
                    />
                  </h3>
                </div>
              </VmoCardContent>
            </VmoCard>
          </VmoGridColumn>
          <VmoGridColumn width={8}>
            <VmoCard fluid className="chartBox">
              <VmoCardContent className="headerBox">
                <VmoCardHeader>KPI breakdown</VmoCardHeader>
                <VmoTooltip
                  content="KPI breakdown of forecast"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </VmoCardContent>
              <VmoCardContent className="pt-0" style={{height: 270}}>
                {type.includes(GOAL_KPI_BREAK_DOWN) && <CustomLoader />}
                {!type.includes(GOAL_KPI_BREAK_DOWN) && !kpis.every(({value}) => value === 0) ? (
                  <ResponsivePie
                    data={kpis}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                    startAngle={-90}
                    innerRadius={0.5}
                    padAngle={2}
                    cornerRadius={0}
                    colors={d => d.color}
                    borderWidth={1}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.4]],
                    }}
                    enableRadialLabels
                    enableSlicesLabels
                    sliceLabel={({value}) => `${value}`}
                    radialLabelsSkipAngle={9}
                    radialLabelsTextXOffset={3}
                    radialLabelsTextColor="#333333"
                    radialLabelsLinkOffset={-10}
                    radialLabelsLinkDiagonalLength={16}
                    radialLabelsLinkHorizontalLength={8}
                    radialLabelsLinkStrokeWidth={1}
                    radialLabelsLinkColor={{from: 'color'}}
                    slicesLabelsSkipAngle={13}
                    slicesLabelsTextColor="#333333"
                    animate
                    motionStiffness={90}
                    motionDamping={15}
                    tooltip={({
                      datum: {
                        data: {kpi, percentage},
                      },
                    }) => (
                      <>
                        <strong>
                          {kpi}: {percentage}
                        </strong>
                      </>
                    )}
                  />
                ) : (
                  !type.includes(GOAL_KPI_BREAK_DOWN) && <NoDataChart top="15px" />
                )}
              </VmoCardContent>
            </VmoCard>
          </VmoGridColumn>
        </VmoGridRow>
      </VmoGrid>
      <VmoCard fluid className="chartBox small-cards-home">
        <VmoCardContent className="headerBox">
          <VmoCardHeader className="headerBox">Breakdown by Kpi</VmoCardHeader>
          <VmoTooltip
            content="Detailed breakdown of forecasts with respect to the KPI"
            size="mini"
            position="top center"
            trigger={<SvgIcon path="common/question" />}
          />
          <VmoTooltip
            position="top center"
            size="mini"
            content="Users"
            trigger={
              <VmoDropdown
                selection
                size="mini"
                className="graph-dropdown"
                options={[{value: 'all', text: 'All', key: 'all'}, ...userOption]}
                onChange={(e, {value, options}) =>
                  setBreakDownUser(
                    options.find(l => l.value === value && l.isTeam)
                      ? {teamId: value}
                      : options.find(l => l.value === value && l.isUser)
                      ? {userId: value}
                      : 'all'
                  )
                }
                defaultValue="all"
              />
            }
          />
        </VmoCardContent>
        <VmoCardContent className="pt-0" style={{height: 500}}>
          {type.includes(BREAK_DOWN_BY_KPI) && <CustomLoader />}
          {!type.includes(BREAK_DOWN_BY_KPI) && (
            <ResponsiveBar
              data={breakdownByKpiData}
              keys={['percentage']}
              indexBy="kpi"
              margin={{top: 40, right: 50, bottom: 70, left: 110}}
              padding={0.25}
              groupMode="grouped"
              borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              minValue={0}
              axisTop={null}
              axisRight={null}
              gridYValues={getGridYValuesMultipleBar(breakdownByKpiData, ['percentage'])}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Percentage',
                legendPosition: 'middle',
                legendOffset: 60,
              }}
              enableGridX
              enableGridY={false}
              layout="horizontal"
              type="linear"
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -60,
                legend: 'Kpi',
                legendPosition: 'middle',
                legendOffset: -100,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              tooltip={({data: {achieved = 0, target = 0, percentage = 0}}) => (
                <>
                  <strong>
                    achieved {achieved} Total {target} ({percentage}%)
                  </strong>
                </>
              )}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          )}
        </VmoCardContent>
      </VmoCard>
      <VmoCard fluid className="chartBox">
        <VmoCardContent className="headerBox">
          <VmoCardHeader className="headerBox">Opportunity and Forecast summary</VmoCardHeader>
          <VmoTooltip
            content="Forecast summary for the year breakdown"
            size="mini"
            position="top center"
            trigger={<SvgIcon path="common/question" />}
          />
          <div className="graph-dropdown">
            <VmoTooltip
              position="top center"
              size="mini"
              content="Forecast Summary User"
              trigger={
                <VmoDropdown
                  selection
                  style={{fontSize: '12px', right: '12px'}}
                  options={[{value: 'all', text: 'All', key: 'all'}, ...userOption]}
                  onChange={(e, {value, options}) =>
                    setForecastSummaryUser(
                      options.find(l => l.value === value && l.isTeam)
                        ? {teamId: value}
                        : options.find(l => l.value === value && l.isUser)
                        ? {userId: value}
                        : 'all'
                    )
                  }
                  defaultValue="all"
                />
              }
            />
            <VmoTooltip
              position="top center"
              size="mini"
              content="Forecast Summary Kpi"
              trigger={
                <VmoDropdown
                  selection
                  style={{fontSize: '12px'}}
                  className="mr-3"
                  options={[{value: 'all', text: 'All', key: 'all'}, ...KpiList]}
                  onChange={(e, {value}) => setForecastSummaryKpi(value)}
                  defaultValue="all"
                />
              }
            />
          </div>
        </VmoCardContent>
        <div className="d-flex my-2">
          <div className="year">
            <VmoButton
              disabled={!(parseInt(forecastSummaryYear) > parseInt(moment(createdAt).format('YYYY')))}
              basic
              onClick={() => {
                if (parseInt(forecastSummaryYear) > parseInt(moment(createdAt).format('YYYY'))) {
                  let forecastYearN = forecastSummaryYear
                  setForecastSummaryYear(parseInt((forecastYearN -= 1)))
                }
              }}
            >
              <SvgIcon path="common/left" />
            </VmoButton>
            <span>{forecastSummaryYear}</span>
            <VmoButton
              disabled={!(parseInt(forecastSummaryYear) < parseInt(moment().add(2, 'y').format('YYYY')))}
              basic
              onClick={() => {
                if (parseInt(forecastSummaryYear) < parseInt(moment().add(2, 'y').format('YYYY'))) {
                  let forecastYearP = forecastSummaryYear
                  setForecastSummaryYear(parseInt((forecastYearP += 1)))
                }
              }}
            >
              <FiChevronRight />
            </VmoButton>
          </div>
        </div>
        <VmoCardContent className="pt-0" style={{height: 330}}>
          {type.includes(FORECAST_SUMMARY) && <CustomLoader />}
          {!type.includes(FORECAST_SUMMARY) && (
            <ResponsiveBar
              data={forecastSummaryData}
              keys={['month']}
              indexBy="month"
              margin={{top: 40, right: 50, bottom: 70, left: 80}}
              padding={0.25}
              groupMode="grouped"
              borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              minValue={0}
              axisTop={null}
              axisRight={null}
              maxValue={maxOfGraphBar(forecastSummaryData, ['percentage'])}
              gridXValues={getGridYValuesBar(forecastSummaryData, ['percentage'])}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Months',
                legendPosition: 'middle',
                legendOffset: 60,
              }}
              enableGridX={false}
              enableGridY
              layout="vertical"
              type="linear"
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Percentage',
                tickValues: getGridYValuesMultipleBar(forecastSummaryData, ['percentage']),
                legendPosition: 'middle',
                legendOffset: -40,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          )}
        </VmoCardContent>
      </VmoCard>
    </div>
  )
}

export default ForecastAnalytics
