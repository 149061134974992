import Joi from 'joi'

export const createAccountSettingsSchema = Joi.object({
  companyName: Joi.string().required().trim().min(2).max(50).messages({
    'string.min': `Length must me at least 2 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
    'string.empty': `Company name is not allowed to be empty`,
  }),
  streetAddress: Joi.string().required().max(100).messages({
    'string.max': `Length must be less than or equal to 100 characters`,
    'string.empty': `Street Address is not allowed to be empty`,
  }),
  city: Joi.string().required().max(50).messages({
    'string.max': `Length must be less than or equal to 50 characters`,
    'string.empty': `City is not allowed to be empty`,
  }),
  state: Joi.string().required().max(30).messages({
    'string.max': `Length must be less than or equal to 30 characters`,
    'string.empty': `State is not allowed to be empty`,
  }),
  country: Joi.string().required(),
  postalCode: Joi.string().required(),
  email: Joi.string()
    .email({tlds: {allow: false}})
    .lowercase()
    .required(),
  phone: Joi.string()
    .required()
    .trim()
    .max(20)
    .regex(/^\+?[0-9]{4,20}$/)
    .messages({
      'string.max': `Phone should not exceed 20 numbers long`,
      'string.pattern.base': 'Enter a valid phone number',
      'string.empty': `Phone is not allowed to be empty`,
    }),
})
