/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import _ from 'lodash'
import moment from 'moment'
import {
  VmoButton,
  VmoCheckbox,
  VmoDatePicker,
  VmoForm,
  VmoFormDropdown,
  VmoFormField,
  VmoGrid,
  VmoGridColumn,
  VmoInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoRadio,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {GET_ALL_CONTACTS, GET_STATIC_CONTACT} from 'actions/types'
import {getStaticSegmentContactList} from 'actions/segment'
import {getContactList} from 'actions/contacts'
import {get, GetFormattedDate, KadenceCountryList} from 'utils/helper'
import KadenceCustomPagination from 'components/common/KadenceCustomPagination'
import SvgIcon from 'components/common/SvgIcon'

const convertIsoformat = ['lastContactedFrom', 'lastContactedTo', 'createdAtFrom', 'createdAtTo']

const SegmentStep2 = props => {
  const {
    open,
    setOpen,
    CreateSegment,
    handleSubmit,
    errors,
    reset,
    control,
    getValues,
    watch,
    setValue,
    step1Values,
    filter,
    setFilter,
    editId,
  } = props
  const dispatch = useDispatch()
  const watchedValues = watch()

  const [contactCompanyList, setContactCompanyList] = useState([])
  const [assigneeData, setAssigneeData] = useState([])
  const [statusListData, setStatusListData] = useState([])
  const [tagLabelData, setTagLabelData] = useState([])
  const [typeList, setTypeList] = useState([])
  const [numberOfPage, setNumberOfPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const initialCurrentListofFilter = {
    assigneeId: null,
    labelIds: null,
    companyId: null,
    isFollowed: null,
    interactionsFrom: '',
    interactionsTo: '',
    lastContactedFrom: null,
    lastContactedTo: null,
    inactiveDaysFrom: '',
    inactiveDaysTo: '',
    createdAtFrom: null,
    createdAtTo: null,
    contactTypeId: null,
    statusId: null,
    country: null,
    locality: '',
    state: '',
    postalCode: '',
  }

  const {
    getTypeListData,
    getAllContactCompanyList,
    contactStatusListData: getStatusListData,
  } = useSelector(state => state.contacts)
  const {getStaticSegmentData: {contacts = []} = {}} = useSelector(state => state.segment)
  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)

  const step1 = step1Values()

  const getTagList = useSelector(state => {
    return get(['multipleFields', 'getTagList'], state)
  })
  const {getUsersList = {}} = useSelector(state => state.settings)
  const {getAllContactsList = [], contactPage: pages = []} = useSelector(state => state.contacts)

  useEffect(() => {
    if (getAllContactCompanyList) {
      const companyList = getAllContactCompanyList.map(list => ({
        key: list.id,
        value: list.id,
        text: list.name,
      }))
      setContactCompanyList(companyList)
    }
  }, [getAllContactCompanyList])

  useEffect(() => {
    if (getTypeListData) {
      const companyList = getTypeListData.map(list => ({
        key: list.id,
        value: list.id,
        text: list.type,
      }))
      setTypeList(companyList)
    }
  }, [getTypeListData])

  useEffect(() => {
    if (getTagList) {
      const tagList = getTagList.map(list => ({
        key: list.id,
        value: list.id,
        text: list.label,
      }))
      setTagLabelData(tagList)
    }
  }, [getTagList])
  useEffect(() => {
    if (getUsersList) {
      const assigneeList = getUsersList.users.map(list => ({
        key: list.id,
        value: list.id,
        text: `${list.firstName} ${list.lastName}`,
      }))
      setAssigneeData(assigneeList)
    }
  }, [getUsersList])

  useEffect(() => {
    if (getStatusListData) {
      const statusList = getStatusListData.map(list => ({
        key: list.id,
        value: list.id,
        text: list.status,
      }))
      setStatusListData(statusList)
    }
  }, [getStatusListData])

  const applyFilterBtn = data => {
    const newData = _.pickBy(data.conditions, _.identity)
    convertIsoformat.map(item => {
      if (Object.keys(newData).includes(item)) {
        newData[item] = newData[item].toISOString()
      }
    })
    if (!_.isEmpty(newData)) {
      setFilter(newData)
      setValue('ids', [])
    }
  }
  const handleCheck = id => {
    const isChecked = watchedValues.ids.some(lead => lead === id)

    if (isChecked) {
      const removeChecked = watchedValues.ids.filter(lead => lead !== id)
      setValue('ids', removeChecked)
    } else {
      setValue('ids', [...watchedValues.ids, id])
    }
  }

  const isChecked = id => {
    const check = watchedValues.ids.find(lead => lead === id)
    if (check) {
      return true
    }
    return false
  }

  const renderContactList = () => {
    return contacts.map((item, i) => (
      <VmoTableRow key={item.id} className="tableLink">
        {step1.isStatic && (
          <VmoTableCell key="checkbox" onClick={e => e.stopPropagation()}>
            <VmoTooltip
              size="mini"
              position="bottom center"
              content="Select"
              trigger={
                <VmoCheckbox
                  id={`${item.id}segment`}
                  className="d-inline-block"
                  onChange={() => handleCheck(item.id)}
                  checked={isChecked(item.id)}
                />
              }
            />
          </VmoTableCell>
        )}
        <VmoTableCell>{`${get(['firstName'], item, '')} ${get(['lastName'], item, '')}`}</VmoTableCell>
        <VmoTableCell>{get(['emails', [0], 'email'], item, '')}</VmoTableCell>
        <VmoTableCell>{get(['status', 'status'], item, '')}</VmoTableCell>
        <VmoTableCell>{moment(get(['interactionTime'], item, new Date())).format('DD MMM')}</VmoTableCell>
      </VmoTableRow>
    ))
  }

  const isCheckedAll = () => {
    const allIds = (getAllContactsList || []).map(lead => lead.id)

    const checkAll = _.xor(allIds, watchedValues.ids).length === 0 && allIds.length !== 0
    if (checkAll) {
      return true
    }
    return false
  }

  const handleCheckAll = () => {
    const allIds = getAllContactsList.map(lead => lead.id)

    if (_.xor(allIds, watchedValues.ids).length === 0) {
      setValue('ids', [])
    } else setValue('ids', allIds)
  }

  const resetFilterBtn = () => {
    setFilter({})
    reset({ids: [], conditions: initialCurrentListofFilter})
    dispatch({type: 'CLEAR_STATIC_CONTACT'})
  }

  useEffect(() => {
    const newData = _.pickBy(filter, _.identity)
    if (!_.isEmpty(newData)) {
      dispatch(getStaticSegmentContactList(props.type, newData))
    } else {
      dispatch(getContactList({page: currentPage}))
      dispatch({type: 'CLEAR_STATIC_CONTACT'})
    }
  }, [filter, currentPage])

  useEffect(() => {
    if (successLabels.includes(GET_ALL_CONTACTS)) {
      setCurrentPage(pages.page)
      setNumberOfPage(pages.pages)
    }
  }, [successLabels, pages])

  const renderList = list => {
    return list.map((contact, index) => (
      <VmoTableRow key={index}>
        {step1.isStatic && (
          <VmoTableCell key="checkbox" onClick={e => e.stopPropagation()}>
            <VmoTooltip
              size="mini"
              position="bottom center"
              content="Select"
              trigger={
                <VmoCheckbox
                  id={`${contact.id}segment`}
                  className="d-inline-block"
                  onChange={() => handleCheck(contact.id)}
                  checked={isChecked(contact.id)}
                />
              }
            />
          </VmoTableCell>
        )}
        <VmoTableCell>
          {`${get(['firstName'], contact, '')} ${get(['lastName'], contact, '')}`.trim() || `#${contact.id}`}
        </VmoTableCell>
        <VmoTableCell>{get(['emails', [0], 'email'], contact, '--')}</VmoTableCell>
        <VmoTableCell>{get(['status', 'status'], contact, '--')}</VmoTableCell>
        <VmoTableCell>
          {get(['interactionTime'], contact) ? GetFormattedDate(get(['interactionTime'], contact)) : '--'}
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  const renderBody = () => {
    if (type.includes(GET_ALL_CONTACTS) || type.includes(GET_STATIC_CONTACT)) {
      return <VmoTablePlaceholder />
    }
    if ((_.isEmpty(filter) && getAllContactsList.length === 0) || (!_.isEmpty(filter) && contacts.length === 0)) {
      return (
        <tr>
          <td colSpan="8" className="emptyValue">
            No Data Available
          </td>
        </tr>
      )
    }
    if (_.isEmpty(filter) && !type.includes(GET_ALL_CONTACTS)) {
      return renderList(getAllContactsList)
    }
    return renderList(contacts)
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setOpen(false)}
      open={open}
      size="large"
    >
      <VmoModalHeader>Create List</VmoModalHeader>
      <VmoModalContent className="kadence-seg-wrap" scrolling>
        <div className="actBar">
          <h4 className="mb-3">Filter for Contact</h4>
          <VmoForm>
            <VmoFormField>
              <label>Assignee</label>
              <Controller
                name="conditions.assigneeId"
                control={control}
                render={({onChange, value}) => (
                  <VmoFormDropdown
                    placeholder="Assignee"
                    selection
                    search
                    fluid
                    clearable
                    options={assigneeData}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    value={value}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField>
              <label>Tag</label>
              <Controller
                control={control}
                name="conditions.labelIds"
                render={({onChange, value}) => (
                  <VmoFormDropdown
                    placeholder="Tags"
                    options={tagLabelData}
                    search
                    fluid
                    selection
                    clearable
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField>
              <label>Company</label>
              <Controller
                control={control}
                name="conditions.companyId"
                render={({onChange, value}) => (
                  <VmoFormDropdown
                    placeholder="Company"
                    options={contactCompanyList}
                    search
                    fluid
                    clearable
                    selection
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    value={value}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField>
              <label>Followed</label>
              <Controller
                control={control}
                name="conditions.isFollowed"
                render={({onChange, value}) => (
                  <>
                    <VmoRadio
                      label="Followed"
                      className="mr-4"
                      name="radioGroup"
                      value="true"
                      checked={value === 'true'}
                      onChange={() => {
                        onChange('true')
                      }}
                    />
                    <VmoRadio
                      label="Not Followed"
                      name="radioGroup"
                      value="false"
                      checked={value === 'false'}
                      onChange={() => {
                        onChange('false')
                      }}
                    />
                  </>
                )}
              />
            </VmoFormField>
            <VmoFormField>
              <label>Interactions</label>
              <VmoGrid>
                <VmoGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.interactionsFrom"
                    render={({onChange, value}) => (
                      <VmoInput
                        fluid
                        label={{content: 'From', basic: true}}
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        error={
                          errors?.interactionsFrom && {
                            content: errors?.interactionsFrom?.message,
                          }
                        }
                      />
                    )}
                  />
                </VmoGridColumn>

                <VmoGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.interactionsTo"
                    render={({onChange, value}) => (
                      <VmoInput
                        fluid
                        label={{content: 'To', basic: true}}
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        error={
                          errors?.interactionsTo && {
                            content: errors?.interactionsTo?.message,
                          }
                        }
                      />
                    )}
                  />
                </VmoGridColumn>
              </VmoGrid>
            </VmoFormField>
            <VmoFormField>
              <label>Last Contacted</label>
              <VmoGrid>
                <VmoGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.lastContactedFrom"
                    render={({onChange, value}) => (
                      <VmoDatePicker
                        placeholder="Date"
                        time={false}
                        onChange={value => {
                          onChange(value)
                        }}
                        value={typeof value === 'string' ? new Date(value) : value}
                        max={new Date()}
                      />
                    )}
                  />
                </VmoGridColumn>
                <VmoGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.lastContactedTo"
                    render={({onChange, value}) => (
                      <VmoDatePicker
                        placeholder="Date"
                        time={false}
                        onChange={value => {
                          onChange(value)
                        }}
                        value={value}
                        min={getValues('lastContactedFrom')}
                        max={new Date()}
                      />
                    )}
                  />
                </VmoGridColumn>
              </VmoGrid>
            </VmoFormField>
            <VmoFormField>
              <label>Inactive Days</label>
              <VmoGrid>
                <VmoGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.inactiveDaysFrom"
                    render={({onChange, value}) => (
                      <VmoInput
                        placeholder="Days"
                        fluid
                        label={{content: 'From', basic: true}}
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        error={
                          errors?.inactiveDaysFrom && {
                            content: errors?.inactiveDaysFrom?.message,
                          }
                        }
                      />
                    )}
                  />
                </VmoGridColumn>
                <VmoGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.inactiveDaysTo"
                    render={({onChange, value}) => (
                      <VmoInput
                        placeholder="Days"
                        fluid
                        label={{content: 'To', basic: true}}
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        error={
                          errors?.inactiveDaysTo && {
                            content: errors?.inactiveDaysTo?.message,
                          }
                        }
                      />
                    )}
                  />
                </VmoGridColumn>
              </VmoGrid>
            </VmoFormField>
            <VmoFormField>
              <label>Created At</label>
              <VmoGrid>
                <VmoGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.createdAtFrom"
                    render={({onChange, value}) => (
                      <VmoDatePicker
                        placeholder="Date"
                        time={false}
                        onChange={value => {
                          onChange(value)
                        }}
                        value={value}
                        max={new Date()}
                      />
                    )}
                  />
                </VmoGridColumn>
                <VmoGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.createdAtTo"
                    render={({onChange, value}) => (
                      <VmoDatePicker
                        placeholder="Date"
                        time={false}
                        onChange={value => {
                          onChange(value)
                        }}
                        value={value}
                        min={getValues('createdAtFrom')}
                        max={new Date()}
                      />
                    )}
                  />
                </VmoGridColumn>
              </VmoGrid>
            </VmoFormField>
            <VmoFormField>
              <label>Contact Type</label>
              <Controller
                control={control}
                name="conditions.contactTypeId"
                render={({onChange, value}) => (
                  <VmoFormDropdown
                    placeholder="Contact Type"
                    options={typeList}
                    fluid
                    search
                    clearable
                    selection
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField>
              <label>Status</label>
              <Controller
                control={control}
                name="conditions.statusId"
                render={({onChange, value}) => (
                  <VmoFormDropdown
                    placeholder="Status"
                    options={statusListData}
                    fluid
                    search
                    clearable
                    selection
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField>
              <label>Country</label>
              <Controller
                control={control}
                name="conditions.country"
                render={({onChange, value}) => (
                  <VmoFormDropdown
                    placeholder="Country"
                    search
                    fluid
                    clearable
                    options={KadenceCountryList}
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    selection
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField>
              <label>Locality</label>
              <Controller
                control={control}
                name="conditions.locality"
                render={({onChange, value}) => (
                  <VmoInput placeholder="Locality" value={value} onChange={(e, {value}) => onChange(value)} />
                )}
              />
            </VmoFormField>
            <VmoFormField>
              <label>State</label>
              <Controller
                control={control}
                name="conditions.state"
                render={({onChange, value}) => (
                  <VmoInput placeholder="State" value={value} onChange={(e, {value}) => onChange(value)} />
                )}
              />
            </VmoFormField>
            <VmoFormField>
              <label>Postal Code</label>
              <Controller
                control={control}
                name="conditions.postalCode"
                render={({onChange, value}) => (
                  <VmoInput
                    placeholder="Postal Code"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors?.postalCode && {
                        content: errors?.postalCode?.message,
                      }
                    }
                  />
                )}
              />
            </VmoFormField>
          </VmoForm>

          <VmoGrid className="mt-4">
            <VmoGridColumn width={8}>
              <VmoButton basic fluid onClick={() => resetFilterBtn()}>
                Reset Filter
              </VmoButton>
            </VmoGridColumn>
            <VmoGridColumn width={8}>
              <VmoButton fluid color="primary" onClick={handleSubmit(applyFilterBtn)}>
                Apply Filter
              </VmoButton>
            </VmoGridColumn>
          </VmoGrid>
        </div>
        <div className="seg-list-body">
          <h4>Contacts ({_.isEmpty(filter) ? getAllContactsList.length : contacts.length})</h4>
          <VmoTable basic>
            <VmoTableHeader>
              <VmoTableRow>
                {step1.isStatic && (
                  <VmoTableHeaderCell>
                    <VmoTooltip
                      size="mini"
                      position="bottom center"
                      content="Select All"
                      trigger={
                        <VmoCheckbox id="ckbSegment" label="" checked={isCheckedAll()} onChange={handleCheckAll} />
                      }
                    />
                  </VmoTableHeaderCell>
                )}
                <VmoTableHeaderCell>Name</VmoTableHeaderCell>

                <VmoTableHeaderCell>Emails</VmoTableHeaderCell>
                <VmoTableHeaderCell>Status</VmoTableHeaderCell>
                <VmoTableHeaderCell>Last Interaction time</VmoTableHeaderCell>
              </VmoTableRow>
            </VmoTableHeader>
            <VmoTableBody>{renderBody()}</VmoTableBody>
          </VmoTable>
          <KadenceCustomPagination
            totalPages={numberOfPage}
            activePage={currentPage}
            onPageChange={page => {
              setCurrentPage(sort => ({...sort, page}))
            }}
          />
        </div>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton onClick={() => setOpen('step1')} basic>
          Back
        </VmoButton>
        <VmoButton content={editId ? 'Update' : 'Create'} onClick={handleSubmit(CreateSegment)} primary />
      </VmoModalActions>
    </VmoModal>
  )
}

export default SegmentStep2
