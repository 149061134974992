import {
  INVOICES_NUMBER_BREAKDOWN_BY_PRODUCTS,
  TOTAL_INVOICES,
  INVOICES_AMOUNT_BREAKDOWN_BY_PRODUCTS,
  NUMBER_OF_INVOICES_BY_USERS,
  AMOUNT_OF_INVOICES_BY_USERS,
  INVOICES_COUNT_OVERTIME,
  INVOICES_AMOUNT_OVERTIME,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case TOTAL_INVOICES:
      return {
        ...state,
        totalInvoicesData: action.payload,
      }

    case INVOICES_NUMBER_BREAKDOWN_BY_PRODUCTS:
      return {
        ...state,
        numberInvoicesData: action.payload,
      }

    case INVOICES_AMOUNT_BREAKDOWN_BY_PRODUCTS:
      return {
        ...state,
        amountInvoicesData: action.payload,
      }

    case NUMBER_OF_INVOICES_BY_USERS:
      return {
        ...state,
        invoiceNumberByUserData: action.payload,
      }

    case AMOUNT_OF_INVOICES_BY_USERS:
      return {
        ...state,
        invoiceAmountByUserData: action.payload,
      }

    case INVOICES_COUNT_OVERTIME:
      return {
        ...state,
        invoiceCountOvertimeData: action.payload,
      }

    case INVOICES_AMOUNT_OVERTIME:
      return {
        ...state,
        invoiceAmountOvertimeData: action.payload,
      }

    default:
      return state
  }
}
