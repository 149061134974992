import {
  GET_SALES_ORDERS_PREFIX,
  GET_NEW_SO_NUMBER,
  GET_SALES_ORDERS_LIST,
  GET_SALES_ORDERS_DATA,
  CREATE_SALES_ORDERS,
  UPDATE_SALES_ORDERS,
} from 'actions/types'

export default function (
  state = {SalesOrderForm: {}, salesOrdersData: {}, uploadFile: {selectedFile: null, previewFile: null}},
  action
) {
  switch (action.type) {
    case GET_SALES_ORDERS_PREFIX:
      return {
        ...state,
        salesOrdersPrefixList: action.payload,
      }

    case GET_NEW_SO_NUMBER:
      return {
        ...state,
        SalesOrderForm: {
          ...state.PurchaseOrderForm,
          soNumber: action.payload,
        },
      }

    case GET_SALES_ORDERS_LIST:
      return {
        ...state,
        salesOrdersList: action.payload.soDetail,
        salesOrdersPage: action.payload.page,
      }

    case GET_SALES_ORDERS_DATA:
      return {
        ...state,
        salesOrdersData: action.payload,
        productsData: action.payload.products,
      }

    case CREATE_SALES_ORDERS:
      return {
        ...state,
        soPublicLink: action.payload,
      }
    case UPDATE_SALES_ORDERS:
      return {
        ...state,
        soPublicLink: action.payload,
      }

    case 'CLEAR_SO_FORM':
      return {
        ...state,
        SalesOrderForm: {},
      }

    case 'CLEAR_SO_DETAILS':
      return {
        ...state,
        salesOrdersData: {},
      }

    case 'SETUP_SUBMIT':
      return {
        ...state,
        SalesOrderForm: {
          ...state.SalesOrderForm,
          setupData: action.payload,
        },
      }

    case 'PRODUCT_HANDLE_SUBMIT':
      return {
        ...state,
        SalesOrderForm: {
          ...state.SalesOrderForm,
          products: action.payload,
        },
      }

    case 'CUSTOMIZATION_SUBMIT':
      return {
        ...state,
        SalesOrderForm: {
          ...state.SalesOrderForm,
          template: action.payload,
        },
      }

    // * file/logo upload and views
    case 'SET_SO_PREVIEW_FILE':
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          previewFile: action.payload,
        },
      }

    case 'SET_SO_SELECTED_FILE': {
      // let prevFile = action.payload.get('logo')
      // const objectUrl = URL.createObjectURL(prevFile);

      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          selectedFile: typeof action.payload === 'string' ? 'uploaded logo' : action.payload.get('logo'),
        },
      }
    }

    case 'CLEAR_SO_UPLOADFILE':
      return {
        ...state,
        uploadFile: {selectedFile: null, previewFile: null},
      }

    default:
      return state
  }
}
