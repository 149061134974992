/* eslint-disable no-plusplus */
export const Avdate = [
  {
    key: 'Everyday',
    text: 'Everyday',
    value: 'everyday',
  },
  {
    key: 'Mon-Fri',
    text: 'Mon-Fri',
    value: 'mon-fri',
  },
  {
    key: 'Sat-Sun',
    text: 'Sat-Sun',
    value: 'sat-sun',
  },
  {
    key: 'Monday',
    text: 'Monday',
    value: [1],
  },
  {
    key: 'Tuesday',
    text: 'Tuesday',
    value: [2],
  },
  {
    key: 'Wednesday',
    text: 'Wednesday',
    value: [3],
  },
  {
    key: 'Thursday',
    text: 'Thursday',
    value: [4],
  },
  {
    key: 'Friday',
    text: 'Friday',
    value: [5],
  },
  {
    key: 'Saturday',
    text: 'Saturday',
    value: [6],
  },
  {
    key: 'Sunday',
    text: 'Sunday',
    value: [0],
  },
]

export const Bufftime = [
  {key: 1, value: 0, text: 'No buffer time'},
  {key: 2, value: 5, text: '5 mins'},
  {key: 3, value: 10, text: '10 mins'},
  {key: 4, value: 15, text: '15 mins'},
  {key: 5, value: 30, text: '30 mins'},
  {key: 6, value: 60, text: '1 hour'},
]

export const inctime = [
  {key: 1, value: 0, text: 'Meeting duration'},
  {key: 2, value: 5, text: '5 mins'},
  {key: 3, value: 10, text: '10 mins'},
  {key: 4, value: 15, text: '15 mins'},
  {key: 5, value: 20, text: '20 mins'},
  {key: 6, value: 30, text: '30 mins'},
  {key: 7, value: 45, text: '45 mins'},
  {key: 8, value: 60, text: '60 mins'},
  {key: 9, value: 90, text: '90 mins'},
  {key: 10, value: 120, text: '120 mins'},
]
const arrayWeek = []

for (let i = 3; i <= 12; i++) {
  arrayWeek.push({
    key: i,
    value: i,
    text: `${i} weeks`,
  })
}

export const Setweeks = [{key: 2, value: 2, text: 'This week and next week'}, ...arrayWeek]

export const Mintime = [
  {key: 1, value: 15, text: '15 mins'},
  {key: 2, value: 30, text: '30 mins'},
  {key: 3, value: 60, text: '1 hour'},
  {key: 4, value: 120, text: '2 hours'},
  {key: 5, value: 180, text: '3 hours'},
  {key: 6, value: 240, text: '4 hours'},
  {key: 7, value: 300, text: '5 hours'},
  {key: 8, value: 360, text: '6 hours'},
  {key: 9, value: 1440, text: '1 days'},
  {key: 10, value: 4320, text: '3 days'},
  {key: 11, value: 10080, text: '7 days'},
]

export const contactToken = [
  {key: 'firstName', value: '{{contact.firstName}}', text: 'First Name'},
  {key: 'lastName', value: '{{contact.lastName}}', text: 'Last Name'},
  {key: 'email', value: '{{contact.email}}', text: 'Email'},
  {
    key: 'companyName',
    value: '{{contact.companyName}}',
    text: 'Company Name',
  },
]

export const timeDuration = [
  {key: 1, value: 15, text: '15 mins', disabled: true},
  {key: 2, value: 30, text: '30 mins', disabled: false},
  {key: 3, value: 60, text: '1 hours', disabled: false},
  {key: 4, value: 120, text: '2 hours', disabled: false},
]

export const formFieldValidation = [
  {key: 1, text: 'Text', value: 'text'},
  {key: 2, text: 'Email', value: 'email'},
  {key: 3, text: 'Textarea', value: 'textarea'},
  {key: 4, text: 'Number', value: 'number'},
]
