import React from 'react'
import {FiActivity, FiFileText, FiLayers} from 'react-icons/fi'
import {VmoList} from 'vmo-library'
import {GetFormattedDate} from 'utils/helper'

const PreviewTimeline = props => {
  const {allActivities} = props
  return (
    <VmoList relaxed="very" className="timeLineList">
      {allActivities.map((list, i) => {
        if (list.text) {
          return (
            <div className="kadence-timeline" key={`${i}text`}>
              <div className="icon">
                <FiFileText />
              </div>
              <div className="content">
                <article>
                  <b>{list.activityDescription} </b> {list.text}
                </article>
                {/* <p>
                  Lorem ipsum dolor laudantium assumenda maiores labore veniam porro, consectetur mollitia molestias
                  quisquam repudiandae deleniti ea est placeat libero. Perspiciatis.
                </p> */}
              </div>
              <div className="time">{GetFormattedDate(list.createdAt)}</div>
            </div>
          )
        }
        if (list.category) {
          return (
            <div className="kadence-timeline" key={`${i}category`}>
              <div className="icon">
                <FiLayers />
              </div>
              <div className="content">
                <article>
                  <b>{` ${list.title} (${list.category})`} </b> {list.text}
                </article>
                {/* <p>
                  Lorem ipsum dolor laudantium assumenda maiores labore veniam porro, consectetur mollitia molestias
                  quisquam repudiandae deleniti ea est placeat libero. Perspiciatis.
                </p> */}
              </div>
              <div className="time">{GetFormattedDate(list.createdAt)}</div>
            </div>
          )
        }
        if (list.activity) {
          return (
            <div className="kadence-timeline" key={`${i}activity`}>
              <div className="icon">
                <FiActivity />
              </div>
              <div className="content">
                <article>
                  {list.activity !== 'add_conversation' && `${list.agent}`}{' '}
                  <span style={{color: '#616FC7'}}>{list.activityDescription}</span>
                  <br />
                  {list.activityValue}
                </article>
                {/* <p>
                  Lorem ipsum dolor laudantium assumenda maiores labore veniam porro, consectetur mollitia molestias
                  quisquam repudiandae deleniti ea est placeat libero. Perspiciatis.
                </p> */}
              </div>
              <div className="time">{GetFormattedDate(list.ts)}</div>
            </div>
          )
        }
      })}
    </VmoList>
  )
}

export default PreviewTimeline
