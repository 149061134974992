import Joi from 'joi'
import {inputStringValidation} from 'validation'

export const logInteractionSchema = Joi.object({
  category: Joi.string().required().messages({
    'string.base': 'Type is required',
  }),
  interactionTime: Joi.date().iso().required().messages({
    'date.base': 'required field',
  }),
  title: inputStringValidation.required().messages({
    'string.empty': `Title cannot be an empty field`,
    'any.required': `Title is required`,
    'string.min': `Minimum 4 characters required`,
    'string.max': `Maximum 20 characters allowed`,
  }),
  description: Joi.string().allow('', null),
  duration: Joi.number().allow('', null),
  outcome: Joi.string().allow('', null),
  isFollowUp: Joi.boolean().allow(false),
  followUpType: Joi.string().allow('', null),
  followUpTime: Joi.date().iso().allow('', null),
})

export const labelSchema = Joi.object({
  label: Joi.string().trim().max(20).required().messages({
    'string.max': `Length must be less than or equal to 20 characters`,
  }),
  color: Joi.any().required(),
})
