import {countryList, featureAccessDeniedContent, roleAccessDeniedContent, timeZoneAllData} from 'constants/variables'
import _ from 'lodash'
import moment from 'moment'
import {AiOutlineCheckCircle} from 'react-icons/ai'
import {vmoToast} from 'vmo-library'
import {getUserInfo} from './local-storage'

export const get = (a, b, c) => {
  const retValue = c !== undefined ? c : null
  return a.reduce((obj, key) => (obj && key && obj[key] !== null && obj[key] !== undefined ? obj[key] : retValue), b)
}

export const capitalize = string => `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`

export const startCase = string => {
  return _.startCase(string)
}

export const removeDoubleQuotes = string => {
  return capitalize(string.replace(/"/g, ''))
}

export function copyToClipboard(e, textAreaRef, setCopySuccess) {
  if (textAreaRef.current) {
    textAreaRef.current.select()

    try {
      document.execCommand('copy')
      setCopySuccess('Copied')
    } catch (err) {
      //
    }
    e.target.focus()
  }
}

export function cleanUpObject(jsonObject) {
  Object.keys(jsonObject).forEach(function (key) {
    const currentObj = jsonObject[key]

    if (_.isNull(currentObj) || currentObj === '') {
      delete jsonObject[key]
    } else if (_.isObject(currentObj)) {
      if (_.isArray(currentObj)) {
        if (!currentObj.length) {
          delete jsonObject[key]
        } else {
          const cleanupArrayObj = []
          for (const obj of currentObj) {
            if (!_.isNull(obj) || currentObj !== '') {
              const cleanObj = cleanUpObject(obj)
              if (!_.isEmpty(cleanObj)) {
                cleanupArrayObj.push(cleanObj)
              }
            }
          }
          if (!cleanupArrayObj.length) {
            delete jsonObject[key]
          } else {
            jsonObject[key] = cleanupArrayObj
          }
        }
      } else if (_.isEmpty(Object.keys(jsonObject[key]))) {
        delete jsonObject[key]
      } else {
        jsonObject[key] = cleanUpObject(currentObj)

        if (_.isEmpty(Object.keys(jsonObject[key]))) {
          delete jsonObject[key]
        }
      }
    }
  })

  return jsonObject
}

export function extractTextContentFromHTML(s) {
  const span = document.createElement('span')
  span.innerHTML = s
  return span.textContent || span.innerText
}

export function GetFormattedDate(date) {
  const isToday = someDate => {
    const today = new Date()
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    )
  }

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const dt = new Date(date)

  let day = dt.getDate()
  let hr = dt.getHours()
  let min = dt.getMinutes()
  let postfix

  if (day < 10) {
    day = `0${day}`
  }
  if (hr < 12 && hr >= 0) postfix = 'am'
  if (hr <= 23 && hr >= 12) postfix = 'pm'
  if (hr > 12 && hr <= 23) hr %= 12
  if (hr < 10) hr = `0${hr}`
  if (min < 10) min = `0${min}`

  if (isToday(dt)) {
    return `${hr}:${min} ${postfix}`
  }

  return `${monthNames[dt.getMonth()]} ${day}`
}

export function wrapText(str, limit) {
  // eslint-disable-next-line no-nested-ternary
  return str ? (str.length > limit ? str.substring(0, limit).concat('...') : str) : str
}

export const onIdealUser = ({timeOut = 30000, onIdeal = () => {}}) => {
  let time
  function ideal() {
    onIdeal()
    document.onmousemove = () => {}
    document.onkeypress = () => {}
  }

  function resetTimer() {
    clearTimeout(time)
    time = setTimeout(ideal, timeOut)
    // 1000 milliseconds = 1 second
  }
  window.onload = resetTimer
  // DOM Events
  document.onmousemove = resetTimer
  document.onkeypress = resetTimer
}

export const copyToClipboardWithoutInput = (str, setSuccess) => {
  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  setSuccess('Copied')
}

export function copyToClipboardDropdown(e, value, toast) {
  if (e.button === 0) {
    try {
      const el = document.createElement('textarea')
      el.value = value
      document.body.appendChild(el)
      el.select()
      vmoToast.success(
        <div className="toast-body">
          <AiOutlineCheckCircle />
          <span>{toast}</span>
        </div>
      )
      document.execCommand('copy')
      document.body.removeChild(el)
    } catch (er) {
      vmoToast.error('error occurred')
    }
  }
}

export const newTimeZoneData = Object.values(timeZoneAllData.countries).map(function (elem) {
  return {
    country: elem.name,
    timezone: elem.zones[0],
  }
})

export function showStatus(status) {
  switch (status) {
    case 1:
      return 'Pending'
    case 2:
      return 'Under Progress'
    case 3:
      return 'Ready for Import'
    default:
      break
  }
}

export const downloadFile = (data, name) => {
  const json = JSON.stringify(data)
  const blob = new Blob([json], {type: 'application/json'})
  const href = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = href
  link.download = `${name}.json`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const KadenceCountryList = countryList.map((list, index) => ({
  key: index,
  value: list.value,
  text: list.value,
}))

export const downloadResponseCSV = (data, name) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${name}.csv`) // or any other extension
  document.body.appendChild(link)
  link.click()
}

export function abbreviateNumber(n) {
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`
  if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`
}

export const getBadgeColorPriority = priority => {
  if (priority === 'low') {
    return 'green'
  }
  if (priority === 'medium') {
    return 'olive'
  }
  if (priority === 'high') {
    return 'orange'
  }
  if (priority === 'critical') {
    return 'red'
  }
}

export function adjustTimeUnit(seconds) {
  const time = seconds / 60
  if (time > 1 && time < 60) {
    return (
      <>
        {time.toFixed(2)}
        <small className="ml-2">Mins</small>
      </>
    )
  }
  if (time >= 60 && time < 1440) {
    return (
      <>
        {(time / 60).toFixed(2)}
        <small className="ml-2">Hours</small>
      </>
    )
  }
  if (time >= 1440) {
    return (
      <>
        {(time / (60 * 24)).toFixed(2)}
        <small className="ml-2">Days</small>
      </>
    )
  }
  return (
    <>
      {seconds}
      <small className="ml-2">Secs</small>
    </>
  )
}

export const getHourMinuteFromMinutes = minutes => {
  const hour = Math.floor(minutes / 60)
  const minute = minutes % 60
  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
}

export const getPermissionTooltipContent = ({featureAccess, roleAccess}) => {
  if (featureAccess !== undefined && !featureAccess) return featureAccessDeniedContent
  if (roleAccess !== undefined && !roleAccess) return roleAccessDeniedContent
  return null
}

export const secondsToMoment = s => {
  return moment.utc(s * 1000).format('HH:mm:ss')
}

export function getCurrentWeekDates() {
  const today = new Date()
  const firstDayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())
  const lastDayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 6)

  const startDate = firstDayOfWeek.toISOString()
  const endDate = lastDayOfWeek.toISOString()

  return {startDate, endDate}
}

export function getCurrentMonthDates() {
  const today = new Date()
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

  const startDate = firstDayOfMonth.toISOString()
  const endDate = lastDayOfMonth.toISOString()

  return {startDate, endDate}
}

export function getCurrentQuarterDates() {
  const today = new Date()
  const currentMonth = today.getMonth()
  const quarterStartMonth = Math.floor(currentMonth / 3) * 3
  const firstDayOfQuarter = new Date(today.getFullYear(), quarterStartMonth, 1)
  const lastDayOfQuarter = new Date(today.getFullYear(), quarterStartMonth + 3, 0)

  const startDate = firstDayOfQuarter.toISOString()
  const endDate = lastDayOfQuarter.toISOString()

  return {startDate, endDate}
}

export const getDecimalNumberInput = (value, decimal = 2) => {
  let enteredValue = value
  if (enteredValue.includes('.') && enteredValue.split('.')[1].length > decimal) {
    const numberEnteredValue = +enteredValue
    enteredValue = numberEnteredValue.toFixed(decimal)
  }
  return parseFloat(enteredValue)
}

export const getFormattedTime = time => {
  if (!time) return '-'

  const localStorageData = getUserInfo()
  const timezone = localStorageData.timezone || moment.tz.guess()
  const timeFormat = (localStorageData.timeFormat || '12') === '12' ? 'h:mm A' : 'HH:mm'

  const convertedTime = moment.tz(time, timezone).format(timeFormat)

  return convertedTime
}

export const getFormattedDate = date => {
  if (!date) return '-'

  const localStorageData = getUserInfo()
  const timezone = localStorageData.timezone || moment.tz.guess()
  const dateFormat = localStorageData.dateFormat || 'DD MM YYYY'

  const convertedDate = moment.tz(date, timezone).format(dateFormat)

  return convertedDate
}

export const milestoneStatusColor = (status, isCompleted) => {
  if (status === 'upcoming') {
    return {background: '#ffc486'}
  }
  if (status === 'late') {
    return {background: '#ff6562'}
  }
  if (isCompleted) {
    return {background: '#59c894'}
  }
  if (status === '') {
    return {background: '#3b93f7'}
  }
}

export const isActiveNavlink = (path, isExact = false) => {
  if (!path) return false
  if (isExact) {
    return window.location.pathname === path
  }
  return window.location.pathname.includes(path)
}

export const getSortType = type => {
  if (type === 'asc') return 'ascending'
  if (type === 'desc') return 'descending'
}
