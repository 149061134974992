/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {
  VmoButton,
  VmoHeader,
  VmoTable,
  VmoTableHeader,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoModal,
  VmoModalHeader,
  VmoModalContent,
  VmoForm,
  VmoFormInput,
  VmoModalActions,
  VmoTooltip,
  VmoFormField,
} from 'vmo-library'

import {ADD_CONTACT_TYPE, DELETE_CONTACT_TYPE, UPDATE_CONTACT_TYPE, CONTACT_TYPE_LIST} from 'actions/types'
import {addContactType, deleteContactType, updateContactType} from 'actions/custom_options'
import create from 'assets/create.svg'
import {getContactType} from 'actions/contacts'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import {getPermissionTooltipContent, removeDoubleQuotes, startCase} from 'utils/helper'
import CreateFirst from 'components/common/CreateFirst'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const ContactType = () => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(
      Joi.object({
        type: Joi.string().trim().max(30).required().label('Contact Type'),
      })
    ),
    defaultValues: {
      type: '',
    },
  })

  const [modal, setModal] = useState(false)
  const [idToEdit, setIdToEdit] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const contactTypesLearnMore = settingsLearnMoreUrl?.dataManagement?.customization?.contactTypes?.learnMore

  const {isLoading} = useApiLoader({label: CONTACT_TYPE_LIST})
  const {generalAccess, customizationPermissions} = useUserPermissions()
  const dataManageCustomizationAccess = generalAccess?.dataManageCustomization
  const manageContactTypesPermissions = customizationPermissions?.manage_contact_types

  const {data: getTypeListData = []} = useApiResponse({
    action: getContactType,
    dependency: [dataManageCustomizationAccess],
    enabled: dataManageCustomizationAccess,
    label: CONTACT_TYPE_LIST,
    storePath: 'contacts.getTypeListData',
  })

  useEffect(() => {
    if (
      successLabels.includes(ADD_CONTACT_TYPE) ||
      successLabels.includes(UPDATE_CONTACT_TYPE) ||
      successLabels.includes(DELETE_CONTACT_TYPE)
    ) {
      setModal(false)
      setIdToEdit(null)
      dispatch(getContactType())
      reset({type: ''})
    }
  }, [successLabels])

  const onModelClose = () => {
    setModal(false)
    setIdToEdit(null)
    reset({status: ''})
  }

  const CreateModal = () => {
    if (modal) {
      return (
        <VmoModal
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          size="mini"
          centered={false}
          open={modal}
          onClose={onModelClose}
        >
          <VmoModalHeader className="vmoModalHeader">{idToEdit ? 'Update' : 'Create'} Contact Type</VmoModalHeader>
          <VmoModalContent>
            <VmoForm className="errorLabel">
              <Controller
                control={control}
                name="type"
                render={({value, onChange}) => (
                  <VmoFormField required>
                    <label>Contact Type</label>
                    <VmoFormInput
                      maxLength={20}
                      placeholder="Write type.."
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      error={
                        errors.type && {
                          content: removeDoubleQuotes(errors.type.message),
                        }
                      }
                    />
                  </VmoFormField>
                )}
              />
            </VmoForm>
          </VmoModalContent>
          <VmoModalActions>
            <VmoButton
              size="tiny"
              disabled={isLoadingData && (type.includes(ADD_CONTACT_TYPE) || type.includes(UPDATE_CONTACT_TYPE))}
              basic
              onClick={onModelClose}
            >
              Close
            </VmoButton>
            <LockPermissionTooltip isRoleAccessDenied={!manageContactTypesPermissions}>
              <VmoButton
                primary
                size="tiny"
                loading={isLoadingData && (type.includes(ADD_CONTACT_TYPE) || type.includes(UPDATE_CONTACT_TYPE))}
                disabled={
                  !isDirty || (isLoadingData && (type.includes(ADD_CONTACT_TYPE) || type.includes(UPDATE_CONTACT_TYPE)))
                }
                {...(manageContactTypesPermissions && {
                  onClick: handleSubmit(data => {
                    idToEdit ? dispatch(updateContactType(idToEdit, data)) : dispatch(addContactType(data))
                  }),
                })}
                className={!manageContactTypesPermissions ? 'disabled-button' : ''}
              >
                {idToEdit ? 'Update' : 'Create'}
              </VmoButton>
            </LockPermissionTooltip>
          </VmoModalActions>
        </VmoModal>
      )
    }
  }

  const renderList = () => {
    return getTypeListData.map((type, index) => (
      <tr
        className="tableLink"
        onClick={() => {
          setIdToEdit(type.id)
          setModal(true)
          reset({type: type.type})
        }}
        key={type.id}
      >
        <td className="pl-4">{index + 1}</td>
        <td>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <VmoHeader as="h5" className="m-0">
              {startCase(type.type || '-')}
            </VmoHeader>
          </div>
        </td>
        <td onClick={e => e.stopPropagation()}>
          <VmoTooltip
            content={
              manageContactTypesPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: manageContactTypesPermissions})
            }
            size="mini"
            position="top center"
            trigger={
              <VmoButton
                icon
                onClick={() => {
                  if (manageContactTypesPermissions) {
                    setIdToEdit(type.id)
                    setDeleteModal(true)
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </VmoButton>
            }
          />
        </td>
      </tr>
    ))
  }

  if (isLoading && dataManageCustomizationAccess) {
    return <PageLoader />
  }

  if (getTypeListData.length === 0 || !dataManageCustomizationAccess) {
    return (
      <SettingLayout>
        <CreateFirst
          addFirst={() => dataManageCustomizationAccess && manageContactTypesPermissions && setModal(true)}
          buttonText="Add Contact Type"
          tooltip={!dataManageCustomizationAccess || !manageContactTypesPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: dataManageCustomizationAccess,
            roleAccess: manageContactTypesPermissions,
          })}
          img={create}
          header="Contact Type"
          subHeader="Contact types are used to classify contacts based on different attributes"
          Feature1="Contact Type Creation"
          list1="Create contact types based on criteria"
          Feature2="Contact Categorization"
          list2="Categorization helps you segment and organize your contacts."
          Feature3="Communication Customization"
          list3="Tailor your communication strategies based on contact type."
        />
        {modal && CreateModal()}
      </SettingLayout>
    )
  }

  return (
    <>
      {modal && CreateModal()}
      <SettingLayout
        icon={<SvgIcon path="settings/contact-types" />}
        header="Manage Contact Type"
        subHeader="Manage list of all contact types that are currently in use.  Create new contact type by clicking on Add"
        learnMoreUrl={contactTypesLearnMore}
        headerButton={{
          onClick: () => manageContactTypesPermissions && setModal(true),
          content: 'Add',
        }}
        table
        headerDivider
        lockRole={!manageContactTypesPermissions}
      >
        <VmoTable basic selectable>
          <VmoTableHeader>
            <VmoTableRow>
              <th className="pl-4">#</th>
              <th>Type</th>
              <th>Action</th>
            </VmoTableRow>
          </VmoTableHeader>
          <tbody>
            {isLoadingData && type.includes(CONTACT_TYPE_LIST) ? <VmoTablePlaceholder columns={3} /> : renderList()}
          </tbody>
        </VmoTable>

        {deleteModal && (
          <DeleteModal
            idTobeDeleted={idToEdit}
            deleteAction={deleteContactType}
            isModalOpen={deleteModal}
            setIsModalOpen={setDeleteModal}
            modalText="Are you sure you want to delete ?"
            type={DELETE_CONTACT_TYPE}
          />
        )}
      </SettingLayout>
    </>
  )
}
export default ContactType
