import Joi from 'joi'

export const feedbackSchema = Joi.object({
  headerText: Joi.string().max(30).required().label('heading').messages({
    'string.max': `Length must be less than or equal to 30 characters`,
  }),
  subHeaderText: Joi.string().max(100).required().label('subheader').messages({
    'string.max': `Length must be less than or equal to 100 characters`,
  }),
  submitText: Joi.string().max(100).required().label('submit text').messages({
    'string.max': `Length must be less than or equal to 100 characters`,
  }),
  submitMsg: Joi.string().max(100).required().label('submit message').messages({
    'string.max': `Length must be less than or equal to 100 characters`,
  }),
  allowFields: Joi.any(),
  captcha: Joi.boolean(),
  attachments: Joi.boolean(),
  inboxId: Joi.any().required(),
})
