import {
  PURCHASES_STATS,
  PURCHASES_AMOUNTS,
  PURCHASES_NUMBERS_BY_CATEGORY,
  PURCHASES_AMOUNT_BY_CATEGORY,
  PURCHASES_NUMBER_OVERTIME,
  PURCHASES_AMOUNT_OVERTIME,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case PURCHASES_STATS:
      return {
        ...state,
        purchasesStatsData: action.payload,
      }

    case PURCHASES_AMOUNTS:
      return {
        ...state,
        purchasesAmountsData: action.payload,
      }

    case PURCHASES_NUMBERS_BY_CATEGORY:
      return {
        ...state,
        purchaseNumbersByCategoryData: action.payload,
      }

    case PURCHASES_AMOUNT_BY_CATEGORY:
      return {
        ...state,
        purchaseAmountByCategoryData: action.payload.data,
      }

    case PURCHASES_NUMBER_OVERTIME:
      return {
        ...state,
        purchaseNumberOvertimeData: action.payload,
      }

    case PURCHASES_AMOUNT_OVERTIME:
      return {
        ...state,
        purchaseAmountOvertimeData: action.payload.data,
      }

    default:
      return state
  }
}
