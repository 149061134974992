import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {joiResolver} from '@hookform/resolvers'
import {Controller, useForm} from 'react-hook-form'
import _ from 'lodash'
import axios from 'axios'
import {useHistory, useParams} from 'react-router'
import {
  VmoAttachment,
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoDatePicker,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormRadio,
  VmoFormSelect,
  VmoFormTextArea,
  VmoGrid,
  VmoGridColumn,
  VmoGridRow,
  VmoTooltip,
} from 'vmo-library'
import {
  ATTACHMENT_POST,
  CREATE_TIMEOFF,
  GET_SINGLE_TIMEOFF,
  GET_TIMEOFF_EMPLOYEE_LIST,
  GET_TIMEOFF_TYPES,
  UPDATE_TIMEOFF,
} from 'actions/types'
import {createTimeOff, getSingleTimeOff, getTimeOffEmployeeList, getTimeOffTypes, updateTimeOff} from 'actions/timeOff'
import {uploadAttachments} from 'actions/settings_productivity'
import AttachmentPreview from 'components/common/AttachmentPreview'
import {removeDoubleQuotes} from 'utils/helper'
import {createTimeOffSchema} from 'validation/Hr/timeoff.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const summary = [
  {color: 'green', time: '120h', name: 'Estimated time'},
  {color: 'blue', time: '6h 25m', name: 'Total logged time'},
  {color: 'orange', time: '20m', name: 'Billed time'},
  {color: 'red', time: '6h 5m', name: 'Billable time'},
  {color: 'purple', time: '0h', name: 'Non-billable'},
]

const {CancelToken} = axios
const requestToken = CancelToken.source()

const CreateTimeOffForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id: editId} = useParams()

  const [EmployeeOption, setEmployeeOption] = useState([])
  const [TypeOption, setTypeOption] = useState([])
  const [files, setFiles] = useState([])
  const [data, setData] = useState({
    attachments: 0,
  })
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [halfDayDate, setHalfDayDate] = useState(null)
  const [oneDayDate, setOneDayDate] = useState(null)
  const [FromDate, setFromDate] = useState(null)
  const [toDateDate, setToDateDate] = useState(null)
  const [active, setActive] = useState({
    halfDay: true,
    oneDay: false,
    severalDaya: false,
  })

  const {timeOffData} = useSelector(state => state.timeOff)
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {successData: attachmentSuccessData, attachmentList = []} = useSelector(state => state.emailInbox)

  const initialCreateInfo = {
    employeeId: null,
    typeId: null,
    description: '',
    attachmentId: null,
    dateType: '',
    dateRange: {},
  }

  const {handleSubmit, errors, control, setValue, reset, watch} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(createTimeOffSchema),
    defaultValues: initialCreateInfo,
  })

  const WatchfromDate = watch('dateRange.fromDate')

  const resetData = () => {
    if (successLabels.includes(GET_SINGLE_TIMEOFF)) {
      const {employeeId, typeId, dateRange, description, attachment, attachmentId} = timeOffData
      if (attachmentId > 0) {
        setUploadedFiles(
          [attachment]?.map(({id, name, size, type}) => {
            return {id, name, size, type}
          })
        )
      }
      setHalfDayDate(dateRange?.date)
      setOneDayDate(dateRange?.date)
      setFromDate(dateRange.fromDate)
      setToDateDate(dateRange.toDate)
      reset({
        employeeId,
        typeId,
        dateRange,
        description,
        attachmentId: attachmentId > 0 ? Number([attachment]?.map(({id}) => id)) : 0,
      })
    }
  }

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_TIMEOFF)) {
      if (timeOffData?.dateType === 'days') {
        setActive({
          ...active,
          halfDay: false,
          oneDay: false,
          severalDaya: true,
        })
      } else if (timeOffData?.dateType === 'full_day') {
        setActive({
          ...active,
          halfDay: false,
          oneDay: true,
          severalDaya: false,
        })
      } else if (timeOffData?.dateType === 'half_day') {
        setActive({
          ...active,
          halfDay: true,
          oneDay: false,
          severalDaya: false,
        })
      }
    }
  }, [successLabels, timeOffData, active])

  useEffect(() => {
    if (editId) {
      dispatch(getSingleTimeOff(editId))
    }
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_TIMEOFF)) {
      resetData()
    }
  }, [successLabels, timeOffData])

  useEffect(() => {
    if (editId) {
      resetData()
    }
  }, [timeOffData, editId])

  useEffect(() => {
    if (active.halfDay === true) {
      setValue('dateType', 'half_day')
    }
    if (active.oneDay === true) {
      setValue('dateType', 'full_day')
    }
    if (active.severalDaya === true) {
      setValue('dateType', 'days')
    }
  }, [active, setValue])

  useEffect(() => {
    if (successLabels.includes(CREATE_TIMEOFF) || successLabels.includes(UPDATE_TIMEOFF)) {
      history.push('/time-off')
    }
  }, [dispatch, history, successLabels])

  useEffect(() => {
    if (attachmentSuccessData === 'composeAttachment' && successLabels.includes(ATTACHMENT_POST)) {
      const newFiles = attachmentList.map(file => ({
        id: file.id,
        name: file.name,
        size: file.size,
      }))

      setData({
        attachments: newFiles,
      })
      dispatch({
        type: 'CLEAR_ATTACHMENT',
      })
    }
  }, [successLabels, attachmentList, attachmentSuccessData, data, dispatch])

  useApiResponse({
    action: getTimeOffEmployeeList,
    enabled: true,
    label: GET_TIMEOFF_EMPLOYEE_LIST,
    storePath: 'timeOff.getTimeOffEmployeeOptionData',
    onSuccess: getTimeOffEmployeeOptionData => {
      const list = getTimeOffEmployeeOptionData?.map(employee => ({
        value: employee.id,
        text: `${employee.name}(${employee.employeeID})`,
        id: employee.id,
      }))
      setEmployeeOption(list)
    },
  })

  useApiResponse({
    action: getTimeOffTypes,
    enabled: true,
    label: GET_TIMEOFF_TYPES,
    storePath: 'timeOff.getTimeOffTypesData',
    onSuccess: getTimeOffTypesData => {
      const list = getTimeOffTypesData?.map(type => ({
        value: type.id,
        text: (
          <div className="d-flex" style={{justifyContent: 'flex-start'}}>
            <div className="timroff-color" style={{'--timeoff-type-color': type.color}} />
            <span>{type.name}</span>
          </div>
        ),
        id: type.id,
      }))
      setTypeOption(list)
    },
  })

  function submitForm(data) {
    data.attachmentId = Number(uploadedFiles.map(file => file.id))

    const newList = _.omitBy(data, value => !!(value === '' || value === null || _.isEqual(value, [])))

    if (editId) {
      return dispatch(updateTimeOff(editId, newList))
    }

    dispatch(createTimeOff(newList))
  }

  return (
    <div className="kadence-full-page">
      <div className="campaignNavbar">
        <div>
          <VmoButton primary onClick={() => history.push('/time-off')}>
            Exit
          </VmoButton>
        </div>
        <h3 className="m-0">Create new Time off</h3>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          Save
        </VmoButton>
      </div>

      <VmoContainer>
        <VmoCard fluid>
          <VmoCardContent className="d-flex">
            {summary.map(e => (
              <div className="summary-grid">
                <div
                  style={{
                    '--summary-color': e.color,
                    boxShadow: 'none',
                  }}
                  className="m-0 p-0"
                >
                  <span>{e.name}</span>
                  <span>{e.time}</span>
                </div>
              </div>
            ))}
          </VmoCardContent>
        </VmoCard>

        <VmoCard fluid>
          <VmoCardContent style={{padding: '18px'}}>
            <h3 className="mb-0">Time Off Information</h3>
            <p className="mt-0 mb-4 card-description">Add required information to create new time-off</p>
            <VmoForm className="errorLabel">
              <VmoGrid>
                <VmoGridRow>
                  <VmoGridColumn width="8">
                    <VmoFormField width={15}>
                      <div className="info-header mb-1">
                        <label className="label-class">Employee</label>
                        <VmoTooltip
                          content="select employee who is taking times-off"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="employeeId"
                        render={({onChange, value, onBlur}) => (
                          <VmoFormSelect
                            fluid
                            options={EmployeeOption}
                            selection
                            placeholder="Employee"
                            onBlur={onBlur}
                            value={value}
                            onChange={(e, {value}) => {
                              onChange(value)
                            }}
                            error={
                              errors?.employeeId && {
                                content: removeDoubleQuotes(errors?.employeeId?.message),
                              }
                            }
                          />
                        )}
                        control={control}
                      />
                    </VmoFormField>

                    <VmoFormField width={15}>
                      <div className="info-header mb-1">
                        <label className="label-class">Type</label>
                        <VmoTooltip
                          content="choose the type of time-off"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="typeId"
                        render={({onChange, value, onBlur}) => (
                          <VmoFormSelect
                            fluid
                            options={TypeOption}
                            selection
                            placeholder="typeId"
                            onBlur={onBlur}
                            value={value}
                            onChange={(e, {value}) => {
                              onChange(value)
                            }}
                            error={
                              errors?.typeId && {
                                content: removeDoubleQuotes(errors?.typeId?.message),
                              }
                            }
                          />
                        )}
                        control={control}
                      />
                    </VmoFormField>

                    <VmoFormField width={15}>
                      <div className="info-header mb-1">
                        <label>Description</label>
                        <VmoTooltip
                          content="give a brief description about time off"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="description"
                        render={({onChange, value}) => (
                          <VmoFormTextArea
                            type="text"
                            value={value}
                            placeholder="Description"
                            onChange={e => {
                              onChange(e.target.value)
                            }}
                            error={
                              errors.description && {
                                content: removeDoubleQuotes(errors.description.message),
                              }
                            }
                          />
                        )}
                        control={control}
                      />
                    </VmoFormField>

                    <VmoFormField width={15}>
                      <div className="info-header mb-1">
                        <label>Approver</label>
                        <VmoTooltip
                          content="shows who will be approving your time-off request"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <div style={{justifyContent: 'flex-start'}} className="d-flex">
                        <span className="mr-2">Ronak Shah</span>
                        <VmoTooltip
                          trigger={<SvgIcon path="common/question" />}
                          content="If this is not your approving manager, pls request admin to update"
                          size="mini"
                          position="top center"
                        />
                      </div>
                    </VmoFormField>
                  </VmoGridColumn>

                  <VmoGridColumn width="8">
                    <VmoFormField width={15}>
                      <div className="info-header mb-1">
                        <label>Date</label>
                        <VmoTooltip
                          content="choose number of days for time off"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <div className="combinedBtns">
                        <VmoButton
                          onClick={() =>
                            setActive({
                              ...active,
                              halfDay: true,
                              oneDay: false,
                              severalDaya: false,
                            })
                          }
                          className={active.halfDay ? 'active' : null}
                        >
                          Half Day
                        </VmoButton>
                        <VmoButton
                          onClick={() =>
                            setActive({
                              ...active,
                              halfDay: false,
                              oneDay: true,
                              severalDaya: false,
                            })
                          }
                          className={active.oneDay ? 'active' : null}
                        >
                          One Day
                        </VmoButton>
                        <VmoButton
                          onClick={() =>
                            setActive({
                              ...active,
                              halfDay: false,
                              oneDay: false,
                              severalDaya: true,
                            })
                          }
                          className={active.severalDaya ? 'active' : null}
                        >
                          Several Day
                        </VmoButton>
                      </div>
                    </VmoFormField>

                    {active.halfDay ? (
                      <VmoFormField>
                        <div className="info-header mb-1">
                          <label>Date & Period</label>
                          <VmoTooltip
                            content="set date and time-off period"
                            trigger={<SvgIcon path="common/question" />}
                            size="mini"
                            position="top center"
                          />
                        </div>
                        <div className="timeoffHalfDay">
                          <Controller
                            name="dateRange.date"
                            render={({onChange, value}) => (
                              <VmoDatePicker
                                type="date"
                                value={typeof value === 'string' ? new Date(halfDayDate) : halfDayDate}
                                inputProps={{readOnly: true}}
                                time={false}
                                onChange={value => {
                                  onChange(value)
                                  setHalfDayDate(value)
                                }}
                                error={
                                  errors?.dateRange?.date && {
                                    content: removeDoubleQuotes(errors?.dateRange?.date?.message),
                                  }
                                }
                              />
                            )}
                            control={control}
                          />

                          <div className="timeoff-dayselection">
                            <Controller
                              name="dateRange.datePeriod"
                              control={control}
                              render={({value, onChange}) => (
                                <>
                                  <VmoFormRadio
                                    className="mb-2"
                                    value="FirstHalf"
                                    checked={value === 'FirstHalf'}
                                    onChange={(e, {value}) => onChange(value)}
                                    label="First half of the day"
                                  />
                                  <VmoFormRadio
                                    value="SecondHalf"
                                    checked={value === 'SecondHalf'}
                                    onChange={(e, {value}) => onChange(value)}
                                    label="Second half of the day"
                                  />
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </VmoFormField>
                    ) : null}

                    {active.oneDay ? (
                      <VmoFormField>
                        <div className="timeoffFullDay">
                          <label>Date</label>
                          <Controller
                            name="dateRange.date"
                            render={({onChange, value}) => (
                              <VmoDatePicker
                                type="date"
                                value={typeof value === 'string' ? new Date(oneDayDate) : oneDayDate}
                                inputProps={{readOnly: true}}
                                time={false}
                                onChange={value => {
                                  onChange(value)
                                  setOneDayDate(value)
                                }}
                                error={
                                  errors?.dateRange?.date && {
                                    content: removeDoubleQuotes(errors?.dateRange?.date?.message),
                                  }
                                }
                              />
                            )}
                            control={control}
                          />
                        </div>
                      </VmoFormField>
                    ) : null}

                    {active.severalDaya ? (
                      <VmoFormGroup inline>
                        <VmoFormField>
                          <label>From</label>
                          <Controller
                            name="dateRange.fromDate"
                            render={({onChange, value}) => (
                              <VmoDatePicker
                                type="date"
                                value={typeof value === 'string' ? new Date(FromDate) : FromDate}
                                inputProps={{readOnly: true}}
                                time={false}
                                onChange={value => {
                                  onChange(value)
                                  setFromDate(value)
                                }}
                                error={
                                  errors?.dateRange?.fromDate && {
                                    content: removeDoubleQuotes(errors?.dateRange?.fromDate?.message),
                                  }
                                }
                              />
                            )}
                            control={control}
                          />
                        </VmoFormField>
                        <VmoFormField>
                          <label>To</label>
                          <Controller
                            name="dateRange.toDate"
                            render={({onChange, value}) => (
                              <VmoDatePicker
                                type="date"
                                value={typeof value === 'string' ? new Date(toDateDate) : toDateDate}
                                inputProps={{readOnly: true}}
                                time={false}
                                min={new Date(WatchfromDate)}
                                onChange={value => {
                                  onChange(value)
                                  setToDateDate(value)
                                }}
                                error={
                                  errors?.dateRange?.toDate && {
                                    content: removeDoubleQuotes(errors?.dateRange?.toDate?.message),
                                  }
                                }
                              />
                            )}
                            control={control}
                          />
                        </VmoFormField>
                      </VmoFormGroup>
                    ) : null}

                    <VmoFormField width="15" className="expense-file-input">
                      <AttachmentPreview
                        files={files}
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                        success="composeAttachment"
                        cancelToken={requestToken}
                      />
                      <label>Select Document</label>
                      <VmoAttachment
                        content={
                          <>
                            <SvgIcon path="common/attachment" /> Upload Attachment
                          </>
                        }
                        accept=".pdf, .docx"
                        onChange={e => {
                          const data = {
                            file: e,
                          }
                          if (e.length > 0) {
                            dispatch(uploadAttachments(data, 'composeAttachment', requestToken))
                          }
                        }}
                        onClick={e => {
                          e.target.value = null
                        }}
                        fileList={files => {
                          setFiles(files)
                        }}
                      />
                      <p className="kadence-lead pt-3">File upload section (Only pdf or docx support)</p>
                    </VmoFormField>
                  </VmoGridColumn>
                </VmoGridRow>
              </VmoGrid>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
      </VmoContainer>
    </div>
  )
}

export default CreateTimeOffForm
