import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoGrid,
  VmoGridColumn,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'
import {removeDoubleQuotes, startCase} from 'utils/helper'
import {templateTasklistTaskSchema} from 'validation/Projects/projects/templates.schema'
import {
  CREATE_PROJECTS_TEMPLATES_TASKLIST_TASK,
  GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_TASK,
  UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK,
} from 'actions/types'
import {
  createProjectsTemplatesTasklistTask,
  getProjectsTemplatesTasklistSingleTask,
  updateProjectsTemplatesTasklistTask,
} from 'actions/projects'
import useProjectsTemplatesTasklist from 'hooks/pure/useProjectsTemplatesTasklist'
import useApiResponse from 'hooks/impure/useApiResponse'
import _ from 'lodash'
import SvgIcon from 'components/common/SvgIcon'

const taskDefaultValues = {
  type: 'task',
  title: '',
  description: '',
  estimatedHrs: 0,
  estimatedMin: 0,
  stageId: null,
  labelIds: [],
  assignedTo: null,
}

const typeOptions = [
  {key: 'task', value: 'task', text: 'Task'},
  {key: 'event', value: 'event', text: 'Event'},
  {key: 'milestone', value: 'milestone', text: 'Milestone'},
]

const TemplateTaskModal = ({open, toggle, templateId, currentlySelectedTasklist, taskId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsTasksLabelsList = []} = useSelector(state => state.settingsProjects)
  const {projectsTemplatesTasklistAssignees, projectsTemplatesTasklistStages} = useProjectsTemplatesTasklist()

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: joiResolver(templateTasklistTaskSchema),
    defaultValues: taskDefaultValues,
  })

  useApiResponse({
    action: getProjectsTemplatesTasklistSingleTask,
    payload: {templateId, taskId},
    dependency: [templateId, taskId],
    enabled: templateId && taskId,
    label: GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_TASK,
    storePath: 'projects.projectsTemplatesTasklistTaskData',
    onSuccess: projectsTemplatesTasklistTaskData => {
      const data = _.pick(projectsTemplatesTasklistTaskData, [
        'type',
        'title',
        'description',
        'estimatedHrs',
        'estimatedMin',
        'stageId',
        'labelIds',
        'assignedTo',
      ])
      reset(data)
    },
  })

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_TEMPLATES_TASKLIST_TASK) ||
      successLabels.includes(UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK)
    ) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    const payload = {...data, isRecurring: false}
    if (taskId) {
      delete payload.type
      dispatch(updateProjectsTemplatesTasklistTask({templateId, taskId, data: payload}))
    } else {
      dispatch(createProjectsTemplatesTasklistTask({templateId, tasklistId: currentlySelectedTasklist, data: payload}))
    }
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>{taskId ? 'Edit Task' : 'Add Task'}</VmoModalHeader>
      <VmoModalContent scrolling>
        <VmoForm className="errorLabel">
          {!taskId && (
            <VmoFormField required>
              <div className="info-header">
                <label>Select Type</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Choose type of the task"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="type"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormSelect
                    options={typeOptions}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.type && {content: removeDoubleQuotes(errors.type.message)}}
                  />
                )}
              />
            </VmoFormField>
          )}
          <VmoFormField>
            <label>Assignee</label>
            <Controller
              name="assignedTo"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  search
                  options={projectsTemplatesTasklistAssignees}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.assignedTo && {content: removeDoubleQuotes(errors.assignedTo.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required>
            <div className="info-header">
              <label>Title</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify a title for the task"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField>
            <div className="info-header">
              <label>Description</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify a brief description about the task"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="description"
              control={control}
              render={({value, onChange}) => (
                <VmoFormTextArea
                  style={{resize: 'none'}}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormGroup>
            <VmoFormField width={8} className="errMsg">
              <label>Estimate Time</label>
              <VmoGrid columns="equal">
                <VmoGridColumn>
                  <Controller
                    name="estimatedHrs"
                    control={control}
                    render={({value, onChange}) => (
                      <VmoFormInput
                        fluid
                        type="number"
                        placeholder="00H"
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={errors.estimatedHrs && {content: removeDoubleQuotes(errors.estimatedHrs.message)}}
                      />
                    )}
                  />
                </VmoGridColumn>
                <VmoGridColumn>
                  <Controller
                    name="estimatedMin"
                    control={control}
                    render={({value, onChange}) => (
                      <VmoFormInput
                        fluid
                        type="number"
                        placeholder="00M"
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors.estimatedMin && {
                            content: removeDoubleQuotes(errors.estimatedMin.message),
                          }
                        }
                      />
                    )}
                  />
                </VmoGridColumn>
              </VmoGrid>
            </VmoFormField>
            <VmoFormField required width={8}>
              <div className="info-header">
                <label>Stage</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Select current stage of the task"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="stageId"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormSelect
                    options={projectsTemplatesTasklistStages}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.stageId && {content: removeDoubleQuotes(errors.stageId.message)}}
                  />
                )}
              />
            </VmoFormField>
          </VmoFormGroup>
          <VmoFormField>
            <div className="info-header">
              <label>Labels</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="add some tags to the task"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="labelIds"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  search
                  multiple
                  options={projectsTasksLabelsList.map(({id, title, color}) => ({
                    key: id,
                    value: id,
                    text: startCase(title),
                    label: {color},
                  }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.labelIds && {content: removeDoubleQuotes(errors.labelIds.message)}}
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          {taskId ? 'Update' : 'Add'}
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default TemplateTaskModal
