/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {DateRange} from 'react-date-range'
import {FiChevronDown, FiChevronRight} from 'react-icons/fi'
import moment from 'moment'
import _ from 'lodash'
import {VmoCheckbox, VmoAccordion, VmoAccordionTitle, VmoAccordionContent, VmoMenu, VmoMenuItem} from 'vmo-library'

import {getCalendarEvent} from 'actions/calendar'
import {EDIT_TASK} from 'actions/types'
import {calendarList} from 'actions/meeting'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getUsers} from 'actions/settings'
import CalendarView from './CalendarView'

const CalendarContainer = () => {
  const dispatch = useDispatch()
  const [activeActivityTypes, setActiveActivityTypes] = useState(true)
  const [activeIndexUsers, setActiveIndexUsers] = useState(false)
  const [activeCalender, setActiveCalender] = useState(false)
  const [datePosition, setDatePosition] = useState(new Date())
  const [calendarView, setCalendarView] = useState('week')

  const [currentDate, setCurrentDate] = useState({
    from: moment().startOf('week'),
    to: moment().endOf('week'),
  })

  const [filters, setFilters] = useState({
    assigneeIds: '',
    calendarId: '',
    types: ['call', 'meeting', 'task', 'event', 'milestone', 'calendar_event'],
  })

  const {meetingCalendarList} = useSelector(state => state.meetings)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {data: getUsersList = {}} = useApiResponse({
    action: getUsers,
    enabled: true,
    storePath: 'settings.getUsersList',
  })
  const {users = []} = getUsersList || {}

  const renderTeammates = () => {
    if (users && users.length > 0) {
      return users.map((teammate, i) => {
        return (
          <VmoMenuItem key={i}>
            <VmoCheckbox
              label={`${teammate.firstName} ${teammate.lastName}`}
              checked={filters.assigneeIds.includes(teammate.id)}
              onChange={() => {
                if (filters.assigneeIds.includes(teammate.id)) {
                  setFilters({
                    ...filters,
                    assigneeIds: filters.assigneeIds.filter(i => i !== teammate.id),
                  })
                } else {
                  setFilters({
                    ...filters,
                    assigneeIds: [...filters.assigneeIds, teammate.id],
                  })
                }
              }}
            />
          </VmoMenuItem>
        )
      })
    }
    return 'Empty'
  }

  const calendarListData = () => {
    if (_.size(meetingCalendarList) !== 0) {
      return meetingCalendarList.map(
        (list, i) =>
          list.twoWaySync && (
            <VmoMenuItem key={i}>
              <VmoCheckbox
                label={`${list.family_name}`}
                checked={filters.calendarId.includes(list.calendarId)}
                onChange={() => {
                  if (filters.calendarId.includes(list.calendarId)) {
                    setFilters({
                      ...filters,
                      calendarId: filters.calendarId.filter(i => i !== list.calendarId),
                    })
                  } else {
                    setFilters({
                      ...filters,
                      calendarId: [...filters.calendarId, list.calendarId],
                    })
                  }
                }}
              />
            </VmoMenuItem>
          )
      )
    }
    return 'Empty'
  }

  const handleView = currentDate => {
    const diff = currentDate.to.diff(currentDate.from, 'days')
    let view = ''

    if (diff < 1) {
      view = 'day'
    } else if (diff <= 35 && diff >= 1) {
      view = 'week'
    } else {
      view = 'month'
    }
    setCalendarView(view)
  }

  useEffect(() => {
    dispatch(calendarList())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getCalendarEvent({
        from: currentDate.from.toISOString(),
        to: currentDate.to.toISOString(),
        assigneeIds: filters.assigneeIds,
        calendarId: filters.calendarId,
        types: filters.types.join(','),
      })
    )
  }, [JSON.stringify(currentDate), JSON.stringify(filters), dispatch])

  useEffect(() => {
    if (successLabels.includes(EDIT_TASK)) {
      dispatch(
        getCalendarEvent({
          from: currentDate.from.toISOString(),
          to: currentDate.to.toISOString(),
          assigneeIds: filters.assigneeIds,
          types: filters.types.join(','),
          calendarId: filters.calendarId,
        })
      )
    }
  }, [successLabels, dispatch, currentDate, filters])

  return (
    <>
      <div className="cal-view">
        <div className="cal-sideBar">
          <h5>Calender Information</h5>
          <div className="calBox">
            <DateRange
              ranges={[
                {
                  startDate: new Date(currentDate.from),
                  endDate: new Date(currentDate.to),
                  key: 'selection',
                },
              ]}
              onChange={value => {
                handleView({
                  from: moment(value.selection.startDate),
                  to: moment(value.selection.endDate),
                })
                setCurrentDate({
                  from: moment(value.selection.startDate),
                  to: moment(value.selection.endDate),
                })
                setDatePosition(moment(value.selection.startDate))
              }}
            />
          </div>

          <VmoMenu secondary vertical className="cal-list">
            <VmoAccordion style={{marginTop: '5px'}}>
              <VmoAccordionTitle onClick={() => setActiveActivityTypes(!activeActivityTypes)}>
                Activity Types
                {activeActivityTypes ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              </VmoAccordionTitle>
              <VmoAccordionContent active={activeActivityTypes}>
                <VmoMenuItem className="cal-checkbox-call">
                  <VmoCheckbox
                    label="Call"
                    checked={filters.types.includes('call')}
                    onChange={(e, {checked}) => {
                      if (filters.types.includes('call')) {
                        setFilters({
                          ...filters,
                          types: filters.types.filter(i => i !== 'call'),
                        })
                      } else {
                        setFilters({
                          ...filters,
                          types: [...filters.types, 'call'],
                        })
                      }
                    }}
                  />
                </VmoMenuItem>
                <VmoMenuItem className="cal-checkbox-meeting">
                  <VmoCheckbox
                    label="Meeting"
                    checked={filters.types.includes('meeting')}
                    onChange={() => {
                      if (filters.types.includes('meeting')) {
                        setFilters({
                          ...filters,
                          types: filters.types.filter(i => i !== 'meeting'),
                        })
                      } else {
                        setFilters({
                          ...filters,
                          types: [...filters.types, 'meeting'],
                        })
                      }
                    }}
                  />
                </VmoMenuItem>
                <VmoMenuItem className="cal-checkbox-task">
                  <VmoCheckbox
                    label="Task"
                    checked={filters.types.includes('task')}
                    onChange={() => {
                      if (filters.types.includes('task')) {
                        setFilters({
                          ...filters,
                          types: filters.types.filter(i => i !== 'task'),
                        })
                      } else {
                        setFilters({
                          ...filters,
                          types: [...filters.types, 'task'],
                        })
                      }
                    }}
                  />
                </VmoMenuItem>
                <VmoMenuItem className="cal-checkbox-event">
                  <VmoCheckbox
                    label="Event"
                    checked={filters.types.includes('event')}
                    onChange={() => {
                      if (filters.types.includes('event')) {
                        setFilters({
                          ...filters,
                          types: filters.types.filter(i => i !== 'event'),
                        })
                      } else {
                        setFilters({
                          ...filters,
                          types: [...filters.types, 'event'],
                        })
                      }
                    }}
                  />
                </VmoMenuItem>
                <VmoMenuItem className="cal-checkbox-milestone">
                  <VmoCheckbox
                    label="Milestone"
                    checked={filters.types.includes('milestone')}
                    onChange={() => {
                      if (filters.types.includes('milestone')) {
                        setFilters({
                          ...filters,
                          types: filters.types.filter(i => i !== 'milestone'),
                        })
                      } else {
                        setFilters({
                          ...filters,
                          types: [...filters.types, 'milestone'],
                        })
                      }
                    }}
                  />
                </VmoMenuItem>
                <VmoMenuItem className="cal-checkbox-events">
                  <VmoCheckbox
                    label="Appointments"
                    checked={filters.types.includes('calendar_event')}
                    onChange={() => {
                      if (filters.types.includes('calendar_event')) {
                        setFilters({
                          ...filters,
                          types: filters.types.filter(i => i !== 'calendar_event'),
                        })
                      } else {
                        setFilters({
                          ...filters,
                          types: [...filters.types, 'calendar_event'],
                        })
                      }
                    }}
                  />
                </VmoMenuItem>
              </VmoAccordionContent>
            </VmoAccordion>

            <VmoAccordion>
              <VmoAccordionTitle onClick={() => setActiveIndexUsers(!activeIndexUsers)}>
                Users
                <span>{activeIndexUsers ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
              </VmoAccordionTitle>
              <VmoAccordionContent active={activeIndexUsers}>{renderTeammates()}</VmoAccordionContent>
            </VmoAccordion>

            <VmoAccordion>
              <VmoAccordionTitle onClick={() => setActiveCalender(!activeCalender)}>
                Calendar
                <span>{activeCalender ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
              </VmoAccordionTitle>
              <VmoAccordionContent active={activeCalender}>{calendarListData()}</VmoAccordionContent>
            </VmoAccordion>
          </VmoMenu>
        </div>
        <div className="cal-body">
          <CalendarView
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            datePosition={datePosition}
            setDatePosition={setDatePosition}
            calendarView={calendarView}
            setCalendarView={setCalendarView}
          />
        </div>
      </div>
    </>
  )
}

export default CalendarContainer
