import {GET_ALL_CALENDAR_EVENTS} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_CALENDAR_EVENTS: {
      return {
        ...state,
        calendarEvents: action.payload,
      }
    }

    default:
      return state
  }
}
