import {ATTACHMENT_POST} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case ATTACHMENT_POST: {
      return {
        ...state,
        attachmentList: action.payload,
        successData: action.successData,
      }
    }

    case 'CLEAR_ATTACHMENT': {
      return {
        ...state,
        attachmentList: null,
        successData: null,
      }
    }

    default:
      return state
  }
}
