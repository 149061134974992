import {apiAction} from './api'
import {KADENCE_SETTING} from './endpoint'
import {
  CREATE_HOLIDAY_GROUP,
  GET_HOLIDAY_GROUP,
  GET_SINGLE_HOLIDAY_GROUP,
  UPDATE_HOLIDAY_GROUP,
  DELETE_HOLIDAY_GROUP,
  CREATE_HOLIDAY,
  DELETE_HOLIDAY,
  GET_SINGLE_HOLIDAY,
  UPDATE_HOLIDAY,
  DEFAULT_GROUP,
} from './types'

export function createHolidaysGroup(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/holidays/group`,
    method: 'POST',
    label: CREATE_HOLIDAY_GROUP,
    data,
    showToast: true,
    successMessage: 'Group Added',
  })
}

export function getHolidaysGroup() {
  return apiAction({
    url: `${KADENCE_SETTING}/holidays/groups`,
    method: 'GET',
    label: GET_HOLIDAY_GROUP,
  })
}

export function getSingleHolidaysGroup(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/holidays/group/${id}`,
    method: 'GET',
    label: GET_SINGLE_HOLIDAY_GROUP,
  })
}

export function updateHolidayGroup(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/holidays/group/${id}`,
    method: 'PUT',
    label: UPDATE_HOLIDAY_GROUP,
    data,
    showToast: true,
    successMessage: 'Group Updated',
  })
}

export function deleteHolidayGroup(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/holidays/group/${id}`,
    method: 'DELETE',
    label: DELETE_HOLIDAY_GROUP,
    showToast: true,
    successMessage: 'Group Deleted',
  })
}

export function createHolidays(data, id) {
  return apiAction({
    url: `${KADENCE_SETTING}/holidays/group/${id}/holiday`,
    method: 'POST',
    label: CREATE_HOLIDAY,
    data,
    showToast: true,
    successMessage: 'Holiday Added',
  })
}

export function defaultGroup(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/holidays/group/${id}`,
    method: 'PATCH',
    label: DEFAULT_GROUP,
    showToast: true,
    successMessage: 'Default success',
  })
}

export function deleteHoliday(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/holidays/holiday/${id}`,
    method: 'DELETE',
    label: DELETE_HOLIDAY,
    showToast: true,
    successMessage: 'Holiday Deleted',
  })
}

export function getSingleHolidays(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/holidays/holiday/${id}`,
    method: 'GET',
    label: GET_SINGLE_HOLIDAY,
  })
}

export function updateHoliday(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/holidays/holiday/${id}`,
    method: 'PUT',
    label: UPDATE_HOLIDAY,
    data,
    showToast: true,
    successMessage: 'Holiday Updated',
  })
}
