import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {MOVE_PROJECT_FINANCE_BILLING_ITEMS} from 'actions/types'
import {getProjectFinanceBillingUnbilledTimeList} from 'actions/projects'
import ProjectBillingTimeTableList from './ProjectBillingTimeTableList'

const ProjectBillingUnbilledTime = ({projectId, budgetId}) => {
  const dispatch = useDispatch()
  const {billingUnbilledTimeList = []} = useSelector(state => state.projects)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(MOVE_PROJECT_FINANCE_BILLING_ITEMS)) {
      dispatch(getProjectFinanceBillingUnbilledTimeList({projectId, budgetId}))
    }
  }, [successLabels, dispatch, projectId, budgetId])

  return (
    <>
      <ProjectBillingTimeTableList timeList={billingUnbilledTimeList} projectId={projectId} budgetId={budgetId} />
    </>
  )
}

export default ProjectBillingUnbilledTime
