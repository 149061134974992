/* eslint-disable no-nested-ternary */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoContainer,
  VmoGrid,
  VmoGridColumn,
  VmoGridRow,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTablePlaceholder,
  VmoTableRow,
} from 'vmo-library'
import {SEQUENCE_CONTACTS} from 'actions/types'
import {sequnceContactList} from 'actions/sequence'

const SequenceContact = ({id}) => {
  const dispatch = useDispatch()
  const {
    sequenceContactData: {contacts = [], active = 0, pending = 0, paused = 0, unsubscribed = 0, completed = 0} = {},
  } = useSelector(state => state.sequences)
  const {isLoadingData, type = []} = useSelector(state => state.apiReducer)

  const renderLeadList = () => {
    return contacts.map(item => (
      <VmoTableRow key={item.id} className="tableLink">
        <VmoTableCell key="checkbox" onClick={e => e.stopPropagation()}>
          {`${item.firstName} ${item.lastName}`}
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  useEffect(() => {
    if (id) {
      dispatch(sequnceContactList(id))
    }
  }, [])

  return (
    <>
      <VmoCard fluid className="mb-0">
        <VmoCardContent>
          <VmoGrid columns={5} divided>
            <VmoGridRow>
              <VmoGridColumn>
                <div className="valueBox">
                  <VmoCardHeader>Active</VmoCardHeader>
                  <h3> {active}</h3>
                </div>
              </VmoGridColumn>
              <VmoGridColumn>
                <div className="valueBox">
                  <VmoCardHeader>Pending</VmoCardHeader>
                  <h3> {pending}</h3>
                </div>
              </VmoGridColumn>
              <VmoGridColumn>
                <div className="valueBox">
                  <VmoCardHeader>Paused</VmoCardHeader>
                  <h3> {paused}</h3>
                </div>
              </VmoGridColumn>
              <VmoGridColumn>
                <div className="valueBox">
                  <VmoCardHeader>Unsubscribed</VmoCardHeader>
                  <h3> {unsubscribed}</h3>
                </div>
              </VmoGridColumn>
              <VmoGridColumn>
                <div className="valueBox">
                  <VmoCardHeader>Completed</VmoCardHeader>
                  <h3> {completed}</h3>
                </div>
              </VmoGridColumn>
            </VmoGridRow>
          </VmoGrid>
        </VmoCardContent>
      </VmoCard>
      <div className="seqTabel">
        <VmoContainer fluid>
          <div className="pageHeader">
            <div className="pageTitle">
              <h5>Contacts</h5>
            </div>
          </div>
          <VmoTable basic selectable className="m-0">
            <VmoTableHeader>
              <VmoTableRow>
                <th className="pl-4">Email</th>
              </VmoTableRow>
            </VmoTableHeader>
            <VmoTableBody>
              {isLoadingData && type.includes(SEQUENCE_CONTACTS) ? (
                <VmoTablePlaceholder columns={1} row={5} />
              ) : contacts.length > 0 ? (
                renderLeadList()
              ) : (
                <tr>
                  <td className="emptyValue">No Data Available</td>
                </tr>
              )}
            </VmoTableBody>
          </VmoTable>
        </VmoContainer>
      </div>
    </>
  )
}

export default SequenceContact
