import Joi from 'joi'

export const roleSchema = Joi.object({
  role: Joi.string().trim().required().max(40).messages({
    'string.empty': 'Role Name is not allowed to be empty',
    'string.max': 'Role Name should have a length of 40 characters or less',
  }),
  description: Joi.string().allow('', null).max(150).required().messages({
    'string.max': `Description length must be less than or equal to 150 characters`,
  }),
})
