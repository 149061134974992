import {GET_CHAT_SURVEY, GET_EMAIL_SURVEY} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case GET_EMAIL_SURVEY:
      return {
        ...state,
        emailSurveySingle: payload,
      }

    case GET_CHAT_SURVEY:
      return {
        ...state,
        chatSurveySingle: payload,
      }

    case 'CLEAR_EMAIL_SURVEY':
      return {
        ...state,
        emailSurveySingle: null,
      }

    case 'CLEAR_CHAT_SURVEY':
      return {
        ...state,
        chatSurveySingle: null,
      }

    default:
      return state
  }
}
