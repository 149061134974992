import {GET_LIVEUSER_CONTACTS, LIVE_COUNT_DETAIL, UPDATE_CONTACT_LIST} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action
  switch (type) {
    case GET_LIVEUSER_CONTACTS: {
      const pageData = {
        page: payload.page,
        size: 20,
        totalItems: payload.totalContacts,
        totalPages: payload.totalPages,
      }
      return {
        ...state,
        getLiveUserContactData: payload.contacts || [],
        getLiveUserNumberOfPages: pageData,
      }
    }

    case LIVE_COUNT_DETAIL:
      return {
        ...state,
        getLiveCount: payload,
      }

    case UPDATE_CONTACT_LIST: {
      const pageData = {
        page: payload.page,
        size: 20,
        totalItems: payload.totalContacts,
        totalPages: payload.totalPages,
      }
      return {
        ...state,
        getContactListData: payload,
        getNumberOfPages: pageData,
      }
    }
    default:
      return state
  }
}
