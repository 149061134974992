import React from 'react'
import CompaniesMenu from 'navigation/sub-routes/Customers/Companies/CompaniesMenu'

function Companies() {
  return (
    <>
      <CompaniesMenu />
    </>
  )
}

export default Companies
