import React, {useEffect, useState} from 'react'
import {Prompt} from 'react-router-dom'

const UnSavedWrap = ({children}) => {
  const [warningModal, setWarningModal] = useState(false)

  useEffect(() => {
    if (warningModal) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }, [warningModal])

  return (
    <>
      <Prompt when={warningModal} message="Please save your changes" />
      {React.cloneElement(children, {setWarningModal, warningModal})}
    </>
  )
}

export default UnSavedWrap
