import {TAGS_STATS, CONTACT_TAGS, COMPANY_TAGS, DEAL_TAGS} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case TAGS_STATS:
      return {
        ...state,
        tagsStatsData: action.payload,
      }
    case CONTACT_TAGS:
      return {
        ...state,
        contactTagsData: action.payload,
      }
    case COMPANY_TAGS:
      return {
        ...state,
        companyTagsData: action.payload,
      }
    case DEAL_TAGS:
      return {
        ...state,
        dealTagsData: action.payload,
      }

    default:
      return state
  }
}
