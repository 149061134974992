import React from 'react'
import {useHistory} from 'react-router-dom'
import {copyToClipboardDropdown} from 'utils/helper'
import {VmoButton, VmoFormInput, VmoModal, VmoModalActions, VmoModalContent} from 'vmo-library'
import SvgIcon from 'components/common/SvgIcon'

function InvoiceModal({open, toggle, link}) {
  const {push} = useHistory()

  return (
    <VmoModal
      open={open}
      onClose={toggle}
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      style={{textAlign: 'center'}}
    >
      <VmoModalContent>
        <h1>Download as PDf</h1>
        <h2 className="mt-3">A web page for your Invoices has been created</h2>
        <p className="my-4">
          Your Sales Order has been successfully created, below is the link to download and view it. You can also send
          this Sales oders directly to the customer from below button
        </p>

        <VmoFormInput
          readOnly
          value={link?.link}
          style={{width: '80%'}}
          action={{
            color: 'basic',
            content: 'Copy Link',
            onClick: e => copyToClipboardDropdown(e, link?.link, 'Link Copied'),
          }}
        />
        <div className="mt-4">
          <VmoButton
            primary
            onClick={() => {
              push({
                pathname: '/inbox',
                state: {
                  InvoiceState: link?.link,
                },
              })
            }}
          >
            Send Invoices
          </VmoButton>
          {/* <VmoButton basic className="ml-3">
            Download
          </VmoButton> */}
        </div>
      </VmoModalContent>

      <VmoModalActions>
        <VmoButton basic onClick={toggle}>
          Cancel
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default InvoiceModal
