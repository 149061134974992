import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {VmoDropdown, VmoForm, VmoFormField} from 'vmo-library'

import {UPDATE_FORECAST_SETTING, GET_ALL_PIPELINES, GET_FORECAST_SETTING} from 'actions/types'
import {getPipelines} from 'actions/deals'
import {getForecastSetting, forecastSetting} from 'actions/forecast'
import {year} from 'constants/sales/forecast'
import SettingLayout from 'layouts/settingLayout'
import {createUpdateForecastSchema} from 'validation/Settings/sales/forecasting.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const Goals = () => {
  const dispatch = useDispatch()
  const {successLabels = [], isLoadingData, type} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const forecastingLearnMore = settingsLearnMoreUrl?.sales?.forecasting?.learnMore

  const {salesPermissions} = useUserPermissions()
  const manageForecastingSettingPermissions = salesPermissions?.manage_forecasting_setting

  const {data: getAllPipelineData = []} = useApiResponse({
    action: getPipelines,
    enabled: true,
    label: GET_ALL_PIPELINES,
    storePath: 'deals.getAllPipelineData',
  })
  const {data: forecastData = {}} = useApiResponse({
    action: getForecastSetting,
    enabled: true,
    label: GET_FORECAST_SETTING,
    storePath: 'forecast.forecastSetting',
  })

  const initailData = {
    pipelineId: _.get(forecastData, 'pipelineId', _.get(getAllPipelineData, '[0][id]')),
    timePeriod: _.get(forecastData, 'timePeriod', null),
    startFrom: _.get(forecastData, 'startFrom', null)?.split(',')[0],
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: {isDirty},
  } = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: initailData,
    resolver: joiResolver(createUpdateForecastSchema),
  })

  useEffect(() => {
    if (successLabels.includes(GET_FORECAST_SETTING)) {
      reset(initailData)
    }
    if (successLabels.includes(GET_ALL_PIPELINES)) {
      reset(initailData)
    }
  }, [successLabels])

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/forecasting" />}
      header="Forecasting"
      subHeader="Create and Manage forecast by setting the pipeline and duration of the deal"
      learnMoreUrl={forecastingLearnMore}
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Cancel',
          disabled: isLoadingData && type.includes(UPDATE_FORECAST_SETTING),
          onClick: () => reset(initailData),
        },
        success: {
          content: 'Update',
          loading: isLoadingData && type.includes(UPDATE_FORECAST_SETTING),
          ...(manageForecastingSettingPermissions && {
            onClick: handleSubmit(data => {
              dispatch(forecastSetting(data))
            }),
          }),
          disabled: !isDirty || (isLoadingData && type.includes(UPDATE_FORECAST_SETTING)),
        },
      }}
    >
      <VmoForm className="my-4">
        <VmoFormField width={6}>
          <label>Select default pipeline</label>
          <Controller
            control={control}
            name="pipelineId"
            render={({onChange, value}) => (
              <VmoDropdown
                selection
                placeholder="Select pipeline"
                options={getAllPipelineData.map(pipeline => ({
                  key: pipeline.id,
                  text: pipeline.pipeline,
                  value: pipeline.id,
                }))}
                value={value}
                onChange={(e, {value}) => onChange(value)}
              />
            )}
          />
        </VmoFormField>
        <VmoFormField width={6}>
          <label>Show the default deal duration</label>
          <Controller
            control={control}
            name="timePeriod"
            render={({onChange, value}) => (
              <VmoDropdown
                selection
                placeholder="select pipeline"
                options={[
                  {key: 1, value: 'monthly', text: 'Monthly'},
                  {key: 2, value: 'quarterly', text: 'Quarterly'},
                  {key: 3, value: 'yearly', text: 'Yearly'},
                ]}
                value={value}
                onChange={(e, {value}) => onChange(value)}
              />
            )}
          />
        </VmoFormField>
        <VmoFormField width={6}>
          <label>Financial year begins in</label>
          <Controller
            control={control}
            name="startFrom"
            render={({onChange, value}) => (
              <VmoDropdown
                selection
                placeholder="select start month"
                options={year}
                value={value}
                onChange={(e, {value}) => onChange(value)}
              />
            )}
          />
        </VmoFormField>
      </VmoForm>
    </SettingLayout>
  )
}
export default Goals
