import {CREATE_SUBTASK, CREATE_TASK, GET_ALL_TASK, GET_CONVERSATION_ACTIVITIES, GET_TASK} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case CREATE_SUBTASK:
      return {
        ...state,
        createSubtaskStatus: action.status || action.error,
      }

    case GET_TASK:
      return {
        ...state,
        getTaskData: action.payload,
        getTaskStatus: action.status,
      }
    case GET_ALL_TASK:
      if (state.getAllTaskData) {
        // const getAllTaskData = {
        //   totalPages: action.payload.totalPages,
        // };
        // Object.keys(state.getAllTaskData)
        //   .filter((lane) => lane !== "totalPages")
        //   .map((lane) => {
        //     getAllTaskData[lane] = [
        //       ...(state.getAllTaskData[lane] || []),
        //       ...(action.payload[lane] || []),
        //     ];
        //   });
        return {
          ...state,
          getAllTaskData: action.payload,
        }
      }
      return {
        ...state,
        getAllTaskData: action.payload,
      }

    case 'CLEAR_ALL_TASK_DATA':
      return {
        ...state,
        getAllTaskData: null,
      }

    case GET_CONVERSATION_ACTIVITIES:
      return {
        ...state,
        conversation: action.payload,
      }
    case CREATE_TASK:
      return {
        ...state,
        createTaskData: action.payload,
      }

    default: {
      return state
    }
  }
}
