/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Board from 'react-trello'
import {AiOutlineEye} from 'react-icons/ai'
import {MdOutlineMoreVert} from 'react-icons/md'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoIcon,
  VmoLabel,
  VmoTooltip,
} from 'vmo-library'

import {
  createProjectsTemplatesTaskslistStage,
  deleteProjectsTemplatesTasklistStage,
  deleteProjectsTemplatesTasklistTask,
  moveProjectsTemplatesTasklistStage,
  updateProjectsTemplatesTasklistTaskStage,
} from 'actions/projects'
import {get, getFormattedDate, removeDoubleQuotes, startCase} from 'utils/helper'
import DeleteModal from 'components/common/DeleteModal'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {DELETE_PROJECTS_TASKS_LIST_STAGE} from 'actions/types'
import {colorOptions} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import TemplateTaskModal from './TemplateTaskModal'

const TemplateTasksBoardView = ({kanBoardData, templateId, currentlySelectedTasklist, rolePermissions}) => {
  const dispatch = useDispatch()

  const [taskId, setTaskId] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isViewTaskModalOpen, setIsViewTaskModalOpen] = useState(false)
  const [idToDeleteColumn, setIdToDeleteColumn] = useState(null)
  const [isDeleteColumnModalOpen, setIsDeleteColumnModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(DELETE_PROJECTS_TASKS_LIST_STAGE)) {
      setIdToDeleteColumn(null)
      setIsDeleteColumnModalOpen(false)
    }
  }, [successLabels])

  const MyCard = ({id, title, metadata}) => {
    return (
      <VmoCard fluid style={metadata.isCompleted ? {backgroundColor: '#fceded'} : {}}>
        <VmoCardContent className="p-3">
          <div className="d-flex mb-2">
            <h5
              className="mb-0 d-flex"
              style={{cursor: 'pointer'}}
              onClick={() => {
                if (rolePermissions) {
                  setTaskId(id)
                  setIsViewTaskModalOpen(true)
                }
              }}
            >
              {title}
            </h5>
            {rolePermissions && (
              <VmoDropdown direction="left" icon={<MdOutlineMoreVert />} className="icon">
                <VmoDropdownMenu>
                  <VmoDropdownItem
                    onClick={() => {
                      setTaskId(id)
                      setIsViewTaskModalOpen(true)
                    }}
                  >
                    <AiOutlineEye /> View Task
                  </VmoDropdownItem>

                  <VmoDropdownItem
                    onClick={() => {
                      setTaskId(id)
                      setIsDeleteModalOpen(true)
                    }}
                  >
                    <SvgIcon path="common/delete" /> Delete
                  </VmoDropdownItem>
                </VmoDropdownMenu>
              </VmoDropdown>
            )}
          </div>
          <div className="project-board-card-labels">
            {get(['labels'], metadata, []).map(label => (
              <VmoLabel key={label.id} color={label.color}>
                {label.title}
              </VmoLabel>
            ))}
          </div>
          <div className="d-flex">
            {get(['endDate'], metadata) && <div>{getFormattedDate(get(['endDate'], metadata))}</div>}
            {get(['progressRate'], metadata) && <div>{get(['progressRate'], metadata)}</div>}
          </div>
          <div className="d-flex-js">
            <VmoTooltip
              position="top left"
              size="mini"
              content={`${metadata.assigneeDetails?.firstName} ${metadata.assigneeDetails?.lastName}`}
              trigger={
                <div className="discussion-users" style={{'--user-bg': 'green'}}>
                  {`${get(['assigneeDetails', 'firstName'], metadata, '')[0]}${
                    get(['assigneeDetails', 'lastName'], metadata, '')[0]
                  }`}
                </div>
              }
            />
          </div>
          <div className="d-flex mt-2">
            {metadata.actualTime ? (
              <span className="project-board-task-time">Log: {metadata.actualTime}</span>
            ) : (
              <span />
            )}
            {metadata.estimateTime ? (
              <span className="project-board-task-time">Est: {metadata.estimateTime}</span>
            ) : (
              <span />
            )}
          </div>
        </VmoCardContent>
      </VmoCard>
    )
  }

  const CustomLaneHeader = ({title, label, color, laneId}) => {
    return (
      <div className="d-flex project-board-column-header" style={{borderTopColor: color}}>
        <h3>
          {startCase(title)} <VmoLabel circular>{label}</VmoLabel>
        </h3>
        <VmoDropdown direction="left" icon={<SvgIcon path="common/more-verticle" />} className="icon morePrev">
          <VmoDropdownMenu>
            <VmoDropdownItem
              onClick={() => {
                setIdToDeleteColumn(laneId)
                setIsDeleteColumnModalOpen(true)
              }}
            >
              <VmoIcon name="trash alternate" /> Delete
            </VmoDropdownItem>
          </VmoDropdownMenu>
        </VmoDropdown>
      </div>
    )
  }

  const CustomNewLaneSection = ({onClick}) => {
    return (
      <VmoButton basic onClick={onClick}>
        <SvgIcon path="common/plus" /> Add a column
      </VmoButton>
    )
  }

  const CustomNewLaneForm = ({onAdd, onCancel}) => {
    const {handleSubmit, control, errors} = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      resolver: joiResolver(
        Joi.object({
          name: Joi.string().trim().min(2).label('Column').required(),
          color: Joi.string().required(),
        })
      ),
      defaultValues: {
        name: '',
        color: 'blue',
      },
    })

    return (
      <VmoCard>
        <VmoCardContent>
          <VmoForm className="errorLabel">
            <VmoFormField>
              <Controller
                name="name"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormInput
                    placeholder="Column name"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.name && {content: removeDoubleQuotes(errors.name.message)}}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField>
              <Controller
                name="color"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormSelect
                    placeholder="Choose color"
                    options={colorOptions}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.color && {content: removeDoubleQuotes(errors.color.message)}}
                  />
                )}
              />
            </VmoFormField>
            <div>
              <VmoButton basic size="tiny" className="mr-3" onClick={onCancel}>
                Cancel
              </VmoButton>
              <VmoButton primary size="tiny" onClick={handleSubmit(onAdd)}>
                Save
              </VmoButton>
            </div>
          </VmoForm>
        </VmoCardContent>
      </VmoCard>
    )
  }

  const onCardDrag = (cardId, stageLandId, targetLaneId) => {
    if (targetLaneId === stageLandId) {
      return
    }

    const data = {
      stageId: targetLaneId,
    }

    dispatch(updateProjectsTemplatesTasklistTaskStage({templateId, taskId: cardId, data}))
  }

  const onLaneDragEnd = (removedIndex, addedIndex) => {
    const data = {sourceId: removedIndex + 1, targetId: addedIndex + 1}
    dispatch(moveProjectsTemplatesTasklistStage({templateId, data}))
  }

  return (
    <>
      <Board
        components={{
          Card: MyCard,
          LaneHeader: CustomLaneHeader,
          NewLaneSection: CustomNewLaneSection,
          NewLaneForm: CustomNewLaneForm,
        }}
        handleDragEnd={onCardDrag}
        laneStyle={{
          // width: '20%',
          margin: '0',
          borderRight: '1px solid #ebedf1',
          borderRadius: '4px',
          backgroundColor: '#F9FBFD',
          height: 'calc(100vh - 132px)',
          overflow: 'hidden',
        }}
        data={kanBoardData}
        cardDragClass="draggingCard"
        className="canBanView projectCanBanView"
        cardDraggable={rolePermissions}
        canAddLanes
        editable
        draggable
        laneDraggable
        onLaneAdd={data => dispatch(createProjectsTemplatesTaskslistStage({templateId, data}))}
        handleLaneDragEnd={onLaneDragEnd}
      />
      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectsTemplatesTasklistTask({templateId, taskId}))}
          modalText="Are you sure you want to delete the task?"
        />
      )}

      {isViewTaskModalOpen && (
        <TemplateTaskModal
          open={isViewTaskModalOpen}
          toggle={setIsViewTaskModalOpen}
          templateId={templateId}
          currentlySelectedTasklist={currentlySelectedTasklist}
          taskId={taskId}
        />
      )}

      {isDeleteColumnModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteColumnModalOpen}
          setIsModalOpen={setIsDeleteColumnModalOpen}
          onDelete={() => dispatch(deleteProjectsTemplatesTasklistStage({templateId, stageId: idToDeleteColumn}))}
          modalText={
            <>
              <p>
                You are about to delete the{' '}
                <b>{kanBoardData?.lanes?.find(lane => lane.id === idToDeleteColumn)?.title}</b> column.
              </p>
              <p>Once you delete, all your tasks will be deleted and this action can not be reversed. </p>
            </>
          }
        />
      )}
    </>
  )
}

export default TemplateTasksBoardView
