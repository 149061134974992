import {CAMPAIGN_STATES_ANALYTICS, CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case CAMPAIGN_STATES_ANALYTICS:
      return {
        ...state,
        campaignAnalyticsStatus: payload,
      }

    case CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS:
      return {
        ...state,
        campaignUniqueVisitors: payload,
      }

    case 'UPDATE_SELECTED_CAMPAIGN_ID':
      return {
        ...state,
        selectedCampaignId: payload,
      }

    case 'CLEAR_ANALYTICS_CAMPAIGN':
      return {...state, campaignAnalyticsStatus: {}, campaignUniqueVisitors: {}}

    default:
      return state
  }
}
