import {createContext, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {getRoleInfo} from 'utils/local-storage'

export const UserPermissionsContext = createContext()

const UserPermissionsProvider = ({children}) => {
  const {currentSubscriptionData: {accessObject = {}} = {}} = useSelector(state => state.billing)
  const {permissions = {}} = useSelector(state => state.roles)

  const userRole = getRoleInfo()?.role || ''

  // Feature Access
  const clientManagementAccess = useMemo(() => {
    const temp = {...accessObject?.clientManagement}
    return temp
  }, [accessObject])

  const salesAndCrmAccess = useMemo(() => {
    const temp = {...accessObject?.salesAndCRM}
    return temp
  }, [accessObject])

  const financeAccess = useMemo(() => {
    const temp = {...accessObject?.finance}
    return temp
  }, [accessObject])

  const projectsAndCollaborationAccess = useMemo(() => {
    const temp = {...accessObject?.projectsAndCollaboration}
    return temp
  }, [accessObject])

  const workflowAndAutomationAccess = useMemo(() => {
    const temp = {...accessObject?.workflowAndAutomation}
    return temp
  }, [accessObject])

  const generalAccess = useMemo(() => {
    const temp = {...accessObject?.general}
    return temp
  }, [accessObject])

  const teamAndUsersAccess = useMemo(() => {
    const temp = {...accessObject?.teamAndUsers}
    return temp
  }, [accessObject])

  const analyticsAccess = useMemo(() => {
    const temp = {...accessObject?.analytics}
    return temp
  }, [accessObject])

  // Role Permissions
  const conversationsPermissions = useMemo(() => {
    const temp = {}
    temp.manage_conversation = permissions?.conversations?.includes('manage_conversation')
    temp.split_conversation = permissions?.conversations?.includes('split_conversation')
    temp.merge_conversation = permissions?.conversations?.includes('merge_conversation')
    temp.delete_conversation = permissions?.conversations?.includes('delete_conversation')
    temp.move_conversation = permissions?.conversations?.includes('move_conversation')
    return temp
  }, [permissions])

  const contactsAndCompaniesPermissions = useMemo(() => {
    const temp = {}
    temp.manage_contacts_company = permissions?.contactsAndCompanies?.includes('manage_contacts_company')
    temp.bulk_actions = permissions?.contactsAndCompanies?.includes('bulk_actions')
    temp.create_lists = permissions?.contactsAndCompanies?.includes('create_lists')
    temp.manage_import_export = permissions?.contactsAndCompanies?.includes('manage_import_export')
    return temp
  }, [permissions])

  const dealsPermissions = useMemo(() => {
    const temp = {}
    temp.manage_pipeline = permissions?.deals?.includes('manage_pipeline')
    temp.manage_deals = permissions?.deals?.includes('manage_deals')
    temp.bulk_actions_deals = permissions?.contactsAndCompanies?.includes('bulk_actions')
    temp.manage_import_export_deals = permissions?.deals?.includes('manage_import_export')
    return temp
  }, [permissions])

  const forecastingPermissions = useMemo(() => {
    const temp = {}
    temp.manage_forecasting =
      permissions?.forecasting?.includes('manage_forecasting') ||
      permissions?.forecasting?.includes('manage_own_forecasting')
    return temp
  }, [permissions])

  const meetingsPermissions = useMemo(() => {
    const temp = {}
    temp.manage_meetings = permissions?.meetings?.includes('manage_meetings')
    return temp
  }, [permissions])

  const sequencesPermissions = useMemo(() => {
    const temp = {}
    temp.manage_sequences =
      permissions?.sequences?.includes('manage_sequences') || permissions?.sequences?.includes('manage_own_sequences')
    return temp
  }, [permissions])

  const quotesPermissions = useMemo(() => {
    const temp = {}
    temp.manage_quote_settings = permissions?.quotes?.includes('manage_quote_settings')
    temp.manage_quotes =
      permissions?.quotes?.includes('manage_quotes') || permissions?.quotes?.includes('manage_own_quotes')
    return temp
  }, [permissions])

  const workflowsPermissions = useMemo(() => {
    const temp = {}
    temp.manage_workflows =
      permissions?.workflows?.includes('manage_workflows') || permissions?.workflows?.includes('manage_own_workflows')
    return temp
  }, [permissions])

  const salesPermissions = useMemo(() => {
    const temp = {}
    temp.manage_forecasting_setting = permissions?.sales?.includes('manage_forecasting_setting')
    temp.manage_scoring_rules = permissions?.sales?.includes('manage_scoring_rules')
    temp.manage_currencies = permissions?.sales?.includes('manage_currencies')
    return temp
  }, [permissions])

  const projectsPermissions = useMemo(() => {
    const temp = {}
    temp.manage_projects = permissions?.projects?.includes('manage_projects')
    temp.manage_project_settings = permissions?.projects?.includes('manage_project_settings')
    temp.manage_retainers = permissions?.projects?.includes('manage_retainers')
    temp.manage_retainer_settings = permissions?.projects?.includes('manage_retainer_settings')
    temp.manage_timesheets = permissions?.projects?.includes('manage_timesheets')
    return temp
  }, [permissions])

  const customizationPermissions = useMemo(() => {
    const temp = {}
    temp.manage_contact_status = permissions?.customization?.includes('manage_contact_status')
    temp.manage_deal_sources = permissions?.customization?.includes('manage_deal_sources')
    temp.manage_contact_types = permissions?.customization?.includes('manage_contact_types')
    temp.manage_loss_reasons = permissions?.customization?.includes('manage_loss_reasons')
    return temp
  }, [permissions])

  const productivityPermissions = useMemo(() => {
    const temp = {}
    temp.manage_canned_reponses = permissions?.productivity?.includes('manage_canned_reponses')
    temp.audit_logs = permissions?.productivity?.includes('audit_logs')
    temp.customer_satfications = permissions?.productivity?.includes('customer_satfications')
    return temp
  }, [permissions])

  const productLibraryPermissions = useMemo(() => {
    const temp = {}
    temp.manage_products = permissions?.productLibrary?.includes('manage_products')
    temp.manage_product_settings = permissions?.productLibrary?.includes('manage_product_settings')
    return temp
  }, [permissions])

  const tasksPermissions = useMemo(() => {
    const temp = {}
    temp.manage_tasks = permissions?.tasks?.includes('manage_tasks')
    temp.manage_task_templates = permissions?.tasks?.includes('manage_task_templates')
    return temp
  }, [permissions])

  const clientPortalPermissions = useMemo(() => {
    const temp = {}
    temp.announcements = permissions?.clientPortal?.includes('announcements')
    temp.settings = permissions?.clientPortal?.includes('settings')
    return temp
  }, [permissions])

  const channelsPermissions = useMemo(() => {
    const temp = {}
    temp.manage_emails = permissions?.channels?.includes('manage_emails')
    temp.manage_messanger_pages = permissions?.channels?.includes('manage_messanger_pages')
    temp.manage_contact_form = permissions?.channels?.includes('manage_contact_form')
    temp.manage_facebook = permissions?.channels?.includes('manage_facebook')
    temp.manage_sms = permissions?.channels?.includes('manage_sms')
    return temp
  }, [permissions])

  const automationPermissions = useMemo(() => {
    const temp = {}
    temp.manage_bussines_hours = permissions?.automation?.includes('manage_bussines_hours')
    temp.manage_routing_rules = permissions?.automation?.includes('manage_routing_rules')
    temp.manage_sla = permissions?.automation?.includes('manage_sla')
    return temp
  }, [permissions])

  const financePermissions = useMemo(() => {
    const temp = {}
    temp.manage_invoices = permissions?.finance?.includes('manage_invoices')
    temp.manage_invoice_settings = permissions?.finance?.includes('manage_invoice_settings')
    temp.manage_sales_orders = permissions?.finance?.includes('manage_sales_orders')
    temp.manage_sales_order_settings = permissions?.finance?.includes('manage_sales_order_settings')
    temp.manage_purchase_orders = permissions?.finance?.includes('manage_purchase_orders')
    temp.manage_purchase_orders_settings = permissions?.finance?.includes('manage_purchase_orders_settings')
    temp.manage_vendors = permissions?.finance?.includes('manage_vendors')
    temp.manage_vendor_settings = permissions?.finance?.includes('manage_vendor_settings')
    temp.manage_purchases = permissions?.finance?.includes('manage_purchases')
    temp.manage_purchase_settings = permissions?.finance?.includes('manage_purchase_settings')
    temp.manage_budget_planner = permissions?.finance?.includes('manage_budget_planner')
    return temp
  }, [permissions])

  const reportsPermissions = useMemo(() => {
    const temp = {}
    temp.conversations_reports = permissions?.reports?.includes('conversations_reports')
    temp.customers_reports = permissions?.reports?.includes('customers_reports')
    temp.deals_reports = permissions?.reports?.includes('deals_reports')
    temp.revenue_reports = permissions?.reports?.includes('revenue_reports')
    temp.performance_reports = permissions?.reports?.includes('performance_reports')
    temp.campaign_reports = permissions?.reports?.includes('campaign_reports')
    temp.productivity_reports = permissions?.reports?.includes('productivity_reports')
    temp.finance_reports = permissions?.reports?.includes('finance_reports')
    temp.projects_reports = permissions?.reports?.includes('projects_reports')
    temp.create_customer_dashboard = permissions?.reports?.includes('create_customer_dashboard')
    return temp
  }, [permissions])

  const myOrganizationDefaultPermissions = ['owner', 'admin'].includes(userRole)
  const manageBillingDefaultPermissions = userRole === 'owner'

  return (
    <UserPermissionsContext.Provider
      value={{
        // Feature Access
        clientManagementAccess,
        salesAndCrmAccess,
        financeAccess,
        projectsAndCollaborationAccess,
        workflowAndAutomationAccess,
        generalAccess,
        teamAndUsersAccess,
        analyticsAccess,
        // Role Permissions
        conversationsPermissions,
        contactsAndCompaniesPermissions,
        dealsPermissions,
        forecastingPermissions,
        meetingsPermissions,
        sequencesPermissions,
        quotesPermissions,
        workflowsPermissions,
        salesPermissions,
        projectsPermissions,
        customizationPermissions,
        productivityPermissions,
        productLibraryPermissions,
        tasksPermissions,
        clientPortalPermissions,
        channelsPermissions,
        automationPermissions,
        financePermissions,
        reportsPermissions,
        myOrganizationDefaultPermissions,
        manageBillingDefaultPermissions,
      }}
    >
      {children}
    </UserPermissionsContext.Provider>
  )
}

export default UserPermissionsProvider
