import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {getAllMailInbox} from 'actions/inbox'
import ReportsMenu from 'navigation/sub-routes/Analytics/ReportsMenu'
import ReportsRoutes from 'navigation/sub-routes/Analytics/ReportsRoutes'

function Analytics() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllMailInbox())
  }, [dispatch])

  return (
    <>
      <ReportsMenu />
      <ReportsRoutes />
    </>
  )
}

export default Analytics
