import React from 'react'
import {Controller} from 'react-hook-form'
import {VmoCheckbox, VmoFormField, VmoFormInput, VmoFormSelect, VmoGrid, VmoGridColumn} from 'vmo-library'
import {
  PROJECT_FINANCE_BUDGET_TYPES,
  projectBudgetsBasedOnOptions,
  projectBudgetsPeriodLengthOptions,
} from 'constants/projects'
import {removeDoubleQuotes} from 'utils/helper'

const ProjectTemplateBudgetForm = ({control, errors, budgetType, rangeType, budgetTimeError, setBudgetTimeError}) => {
  return (
    <>
      <VmoFormField required width={8} className="mb-2">
        <label>
          {budgetType === PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS ? 'Budget amount' : 'Retainer budget'}
        </label>
        <VmoGrid>
          <VmoGridColumn width={4}>
            <Controller
              name="rangeType"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  fluid
                  options={[
                    {key: 'financial', value: 'financial', text: '$'},
                    {key: 'time', value: 'time', text: '🕐'},
                  ]}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.rangeType && {content: removeDoubleQuotes(errors.rangeType.message)}}
                />
              )}
            />
          </VmoGridColumn>
          <VmoGridColumn width={12}>
            {rangeType === 'financial' && (
              <Controller
                name="budgetAmount"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormInput
                    type="number"
                    fluid
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.budgetAmount && {content: removeDoubleQuotes(errors.budgetAmount.message)}}
                  />
                )}
              />
            )}
            {rangeType === 'time' && (
              <div className="d-flex-js">
                <Controller
                  name="budgetTimeHour"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      fluid
                      width={4}
                      className="mb-0 mr-2"
                      value={value}
                      onChange={(e, {value}) => {
                        setBudgetTimeError('')
                        onChange(value)
                      }}
                      error={errors.budgetTimeHour && {content: removeDoubleQuotes(errors.budgetTimeHour.message)}}
                    />
                  )}
                />
                <span>h</span>
                <Controller
                  name="budgetTimeMinute"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      fluid
                      width={4}
                      className="mb-0 mx-2"
                      value={value}
                      onChange={(e, {value}) => {
                        setBudgetTimeError('')
                        onChange(value)
                      }}
                      error={errors.budgetTimeMinute && {content: removeDoubleQuotes(errors.budgetTimeMinute.message)}}
                    />
                  )}
                />
                <span>m</span>
              </div>
            )}
            {budgetTimeError && <p className="errorLabelElement">{budgetTimeError}</p>}
          </VmoGridColumn>
        </VmoGrid>
      </VmoFormField>

      <VmoGrid>
        <VmoGridColumn width={8}>
          <VmoFormField>
            <label>Budget based on</label>
            <Controller
              name="timelogType"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  fluid
                  options={projectBudgetsBasedOnOptions}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.timelogType && {content: removeDoubleQuotes(errors.timelogType.message)}}
                />
              )}
            />
          </VmoFormField>
        </VmoGridColumn>
        {budgetType === PROJECT_FINANCE_BUDGET_TYPES.RETAINER && (
          <VmoGridColumn width={8}>
            <VmoFormField>
              <label>Period Length</label>
              <Controller
                name="repeatUnit"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormSelect
                    fluid
                    options={projectBudgetsPeriodLengthOptions}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.repeatUnit && {content: removeDoubleQuotes(errors.repeatUnit.message)}}
                  />
                )}
              />
            </VmoFormField>
          </VmoGridColumn>
        )}
      </VmoGrid>

      {budgetType === PROJECT_FINANCE_BUDGET_TYPES.RETAINER && (
        <>
          <p className="mb-2">
            <strong>Retainer Options</strong>
          </p>
          <Controller
            name="carryUnderspend"
            control={control}
            render={({value, onChange}) => (
              <VmoCheckbox
                className="mb-2"
                label="Add unspent hours to next period’s budget"
                checked={value}
                onChange={(e, {checked}) => onChange(checked)}
              />
            )}
          />
          <br />
          <Controller
            name="carryOverspend"
            control={control}
            render={({value, onChange}) => (
              <VmoCheckbox
                label="Subtract overspent hours from next period’s budget"
                checked={value}
                onChange={(e, {checked}) => onChange(checked)}
              />
            )}
          />
        </>
      )}
    </>
  )
}

export default ProjectTemplateBudgetForm
