/* eslint-disable react/destructuring-assignment */
import React from 'react'
import _ from 'lodash'
import {VmoCheckbox, VmoFlag, VmoLabel, VmoListItem, VmoTooltip} from 'vmo-library'
import UserImage from 'components/common/UserImage'
import {get, GetFormattedDate, startCase, wrapText} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

export const EmailListItem = props => {
  const {
    id,
    emailListItemData,
    currentlySelectedItem,
    checkedConversations,
    bulkSelect,
    selected,
    conversationType,
    updatedAt,
    subTitle,
    mailText,
    mime,
    typingConversationId,
    mailDraftStatus,
  } = props

  const isItemChecked = () => {
    return checkedConversations.indexOf(Number(id)) !== -1
  }

  // oncheck of individual checkbox
  function onCheck(e) {
    e.stopPropagation()

    if (isItemChecked()) {
      props.updateCheckedConversation('remove', id)
    } else {
      props.updateCheckedConversation('add', id)
    }
  }

  const getName = data => {
    return (
      (get(['firstName'], data) + get(['lastName'], data)
        ? `${get(['firstName'], data, '')}
        ${get(['lastName'], data, '')}`
        : false) ||
      get(['name'], data) ||
      get(['email'], data) ||
      get(['username'], data, '')
    )
  }

  function convClicked(e) {
    if (checkedConversations.length === 0 && currentlySelectedItem !== id) {
      props.onClick()
    }
    if (checkedConversations.length > 0) {
      onCheck(e)
    }
  }

  function getSubTitle(subTitle, mailText, mime) {
    if (conversationType !== 'web') return subTitle
    if (typingConversationId.includes(id))
      return (
        <div className="left-user">
          <span>typing...</span>
        </div>
      )
    if (mime) return mime
    if (conversationType === 'web' && mailText) return wrapText(mailText, 35)
  }

  const getChannelIcon = type => {
    if (type === 'web') return <SvgIcon path="inbox/web-channel" />
    if (type === 'email') return <SvgIcon path="common/mail" />
    if (type === 'support_form') return <SvgIcon path="inbox/support-form" />
    if (type === 'facebook') return <SvgIcon path="inbox/facebook" />
    if (type === 'twitter') return <SvgIcon path="inbox/twitter" />
    if (type === 'custom_sms') return <SvgIcon path="inbox/custom-sms" />
    if (type === 'twilio') return <SvgIcon path="inbox/twilio" />
    return <SvgIcon path="inbox/default-channel" />
  }

  return (
    <VmoListItem className={currentlySelectedItem === id ? 'active' : ''} onClick={convClicked}>
      <div className="emailListHeader">
        <h5>
          {bulkSelect && <VmoCheckbox checked={selected === 'All' || isItemChecked()} id={id} onClick={onCheck} />}
          {wrapText(getName(emailListItemData.contact), 35)}

          {get(['totalMessages'], emailListItemData, 0) > 1 && conversationType !== 'web' && (
            <span className="ml-1 mt-1">({get(['totalMessages'], emailListItemData, 0)})</span>
          )}

          {get(['attachment'], emailListItemData) && <SvgIcon path="common/attachment" className="attach mt-1" />}
        </h5>

        <span>
          {emailListItemData && !get(['isRead'], emailListItemData, false) && <div className="unreadDot" />}
          {startCase(get(['status', 'status'], emailListItemData)) && (
            <VmoLabel size="tiny" basic className="mr-2">
              {startCase(emailListItemData.status.status)}
            </VmoLabel>
          )}
          {get(['contact', 'country'], emailListItemData, null) && (
            <VmoTooltip
              size="mini"
              position="left center"
              content={`${get(['contact', 'city'], emailListItemData, null)}, ${get(
                ['contact', 'state'],
                emailListItemData,
                null
              )}, ${get(['contact', 'country'], emailListItemData, null)}`}
              trigger={<VmoFlag className="channel" name={emailListItemData.contact.country.toLowerCase()} />}
            />
          )}
          <span className="chat-date">{GetFormattedDate(updatedAt)}</span>
        </span>
      </div>
      <div className="emailListBody">
        <p>{getSubTitle(subTitle, mailText, mime)}</p>
        <div className="d-flex-ac">
          {get(['assignee'], emailListItemData) && (
            <VmoTooltip
              size="mini"
              position="left center"
              content={getName(get(['assignee'], emailListItemData))}
              trigger={
                <div className="mr-3">
                  <UserImage profile={emailListItemData.assignee} />
                </div>
              }
            />
          )}
          <div className="channel">
            {get(['contact', 'online'], emailListItemData, false) && <div className="online" />}
            <VmoTooltip
              size="mini"
              position="left center"
              content={startCase(get(['inbox', 'name'], emailListItemData, '-'))}
              trigger={getChannelIcon(get(['type'], emailListItemData, null))}
            />
          </div>
        </div>
      </div>
      {conversationType !== 'web' && (
        <div className="emailListFooter">
          <p>
            {mailText}
            {mailDraftStatus && (
              <span className="draftText">
                <SvgIcon path="common/file" /> Draft
              </span>
            )}
          </p>
        </div>
      )}
      {/* <div className="emailListTag mt-1">
        {(emailListItemData?.labels || [])
          .filter((item, index) => index < 3)
          .map(tag => (
            <VmoLabel style={{cursor: 'text'}} basic color={tag.color} as="a" className="vmo-tag ml-0">
              {tag.label}
            </VmoLabel>
          ))}
      </div> */}
    </VmoListItem>
  )
}
