import React from 'react'
import {useDispatch} from 'react-redux'
import {
  VmoMenu,
  VmoMenuMenu,
  VmoMenuItem,
  VmoDropdown,
  VmoDropdownMenu,
  VmoDropdownItem,
  VmoImage,
  VmoTooltip,
  VmoDropdownDivider,
} from 'vmo-library'
import {useHistory} from 'react-router-dom'
import {logoutCall} from 'actions'
import {FiFolder, FiSettings} from 'react-icons/fi'
import SvgIcon from 'components/common/SvgIcon'
import AccountUserImage from '../components/common/AccountUserImage'
import logo from '../assets/logo-white.svg'
import GlobalSearch from '../components/pages/GlobalSearch'

export function NavBar() {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <VmoMenu size="small" className="kadence-nav-bar">
      <div className="d-flex">
        <VmoImage src={logo} onClick={() => history.push('/home')} style={{cursor: 'pointer'}} />
        <VmoDropdownItem text="Home" onClick={() => history.push('/home')} style={{cursor: 'pointer'}} />
        <VmoDropdown className="simple" item icon="chevron down" text="Clients">
          <VmoDropdownMenu>
            <VmoDropdownItem item onClick={() => history.push('/contacts')}>
              {/* <SvgIcon path="navigation/contacts" /> */}
              <span>Contacts</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/companies')}>
              <SvgIcon path="navigation/companies" />
              <span>Companies</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/portal')}>
              <SvgIcon path="navigation/client-portal" />
              <span>Client Portal</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/calendar')}>
              <SvgIcon path="navigation/calendar" />
              <span>Calendar</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/activities-feed')}>
              <SvgIcon path="navigation/activities-feed" />
              <span>Activities Feed</span>
            </VmoDropdownItem>
            {/* <VmoDropdownItem item onClick={() => history.push('/live-visitors')}>
              <FiFolder />
              <span>Live Visitors</span>
            </VmoDropdownItem> */}
          </VmoDropdownMenu>
        </VmoDropdown>
        {/* <VmoDropdown
          className="simple"
          item
          icon="chevron down"
          onChange={(e, {value}) => history.push(`/${value}`)}
          options={conversationsOptions}
          text="Conversations"
        /> */}
        <VmoDropdownItem text="Inbox" onClick={() => history.push('/inbox')} style={{cursor: 'pointer'}} />
        {/* <VmoDropdown
          className="simple"
          item
          icon="chevron down"
          onChange={(e, {value}) => history.push(`/${value}`)}
          options={marketingOptions}
          text="Marketing"
        /> */}

        {/* <VmoDropdown className="simple" item icon="chevron down" text="Marketing">
          <VmoDropdownMenu>
            
            <VmoDropdownItem item onClick={() => history.push('/forms')}>
              <FiFolder /> <span>Forms</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/landing-pages')}>
              <FiFolder /> <span>Landing Pages</span>
            </VmoDropdownItem>
          </VmoDropdownMenu>
        </VmoDropdown> */}

        <VmoDropdown className="simple" item icon="chevron down" text="Sales">
          <VmoDropdownMenu>
            <VmoDropdownItem item onClick={() => history.push('/deals')}>
              <SvgIcon path="navigation/deals" /> <span>Deals</span>
            </VmoDropdownItem>
            {/* <VmoDropdownItem item onClick={() => history.push('/campaigns')}>
              <VmoImage src={} /> <span>Campaigns</span>
            </VmoDropdownItem> */}
            <VmoDropdownItem item onClick={() => history.push('/meetings')}>
              <SvgIcon path="navigation/meetings" /> <span>Meetings</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/forecasts')}>
              <SvgIcon path="navigation/forecasting" /> <span>Forecasting</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/sequences')}>
              <SvgIcon path="navigation/sequences" /> <span>Sequences</span>
            </VmoDropdownItem>
            {/* <VmoDropdownItem item onClick={() => history.push('/playbooks')}>
              <FiFolder /> <span>Playbooks</span>
            </VmoDropdownItem> */}
            <VmoDropdownItem item onClick={() => history.push('/quotes')}>
              <SvgIcon path="navigation/quotes" /> <span>Quotes</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/products')}>
              <SvgIcon path="navigation/products" /> <span>Products</span>
            </VmoDropdownItem>
          </VmoDropdownMenu>
        </VmoDropdown>
        {/* <VmoDropdown
          className="simple"
          item
          icon="chevron down"
          onChange={(e, {value}) => history.push(`/${value}`)}
          options={supportOptions}
          text="Support"
        /> */}
        {/* <VmoDropdown
          className="simple"
          item
          icon="chevron down"
          onChange={(e, {value}) => history.push(`/${value}`)}
          options={projectsOptions}
          text="Projects"
        /> */}
        {/* <VmoDropdown
          className="simple"
          item
          icon="chevron down"
          options={financeOptions}
          text="Finance"
          onChange={(e, {value}) => history.push(`/${value}`)}
        /> */}

        <VmoDropdown className="simple" item icon="chevron down" text="Projects">
          <VmoDropdownMenu>
            <VmoDropdownItem item onClick={() => history.push('/projects')}>
              <SvgIcon path="navigation/projects" /> <span>Projects</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/budgeting')}>
              <SvgIcon path="navigation/budgeting" /> <span>Budgeting</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/tasks')}>
              <SvgIcon path="navigation/tasks" /> <span>Tasks</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/timesheets')}>
              <SvgIcon path="navigation/timesheets" /> <span>Timesheets</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/proofs')}>
              <SvgIcon path="navigation/proofs" /> <span>Proofs</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/resource-planning/overview')}>
              <SvgIcon path="navigation/resource-planning" /> <span>Resource Planning</span>
            </VmoDropdownItem>
          </VmoDropdownMenu>
        </VmoDropdown>

        <VmoDropdown className="simple" item icon="chevron down" text="Finance">
          <VmoDropdownMenu>
            <VmoDropdownItem item onClick={() => history.push('/invoices')}>
              <SvgIcon path="navigation/invoices" /> <span>Invoices</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/sales-orders')}>
              <SvgIcon path="navigation/sales-orders" /> <span>Sales Orders</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/purchase-orders')}>
              <SvgIcon path="navigation/purchase-orders" /> <span>Purchase Orders</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/budgets-planner')}>
              <SvgIcon path="navigation/budget-planner" /> <span>Budgets Planner</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/vendors')}>
              <SvgIcon path="navigation/vendors" /> <span>Vendors</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/purchases')}>
              <SvgIcon path="navigation/purchases" /> <span>Purchases</span>
            </VmoDropdownItem>
          </VmoDropdownMenu>
        </VmoDropdown>

        <VmoDropdown className="simple" item icon="chevron down" text="HR">
          <VmoDropdownMenu>
            <VmoDropdownItem item onClick={() => history.push('/employees')}>
              <FiFolder /> <span>Employees</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/time-off')}>
              <FiFolder /> <span>Time Off</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/expenses')}>
              <FiFolder /> <span>Expenses</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/payroll')}>
              <FiFolder /> <span>Payroll</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/contracts')}>
              <FiFolder /> <span>Contracts</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/appraisal/templates')}>
              <FiFolder /> <span>Appraisals</span>
            </VmoDropdownItem>
            <VmoDropdownDivider />
            <VmoDropdownItem item onClick={() => history.push('/announcements')}>
              <FiFolder /> <span>Announcements</span>
            </VmoDropdownItem>
          </VmoDropdownMenu>
        </VmoDropdown>

        <VmoDropdownItem text="Automation" onClick={() => history.push('/automations')} style={{cursor: 'pointer'}} />

        <VmoDropdown className="simple" item icon="chevron down" text="Analytics">
          <VmoDropdownMenu>
            <VmoDropdownItem item onClick={() => history.push('/dashboards/overview')}>
              <SvgIcon path="navigation/dashboards" /> <span>Dashboards</span>
            </VmoDropdownItem>
            <VmoDropdownItem item onClick={() => history.push('/reports/overview')}>
              <SvgIcon path="navigation/reports" /> <span>Reports</span>
            </VmoDropdownItem>
          </VmoDropdownMenu>
        </VmoDropdown>

        {/* <VmoDropdown
          className="simple"
          item
          icon="chevron down"
          onChange={(e, {value}) => history.push(`/${value}`)}
          options={analyticsOptions}
          text="Analytics"
        /> */}
      </div>

      <VmoMenuMenu position="right" className="pb-2">
        <div className="d-flex">
          <GlobalSearch />
          {/* <VmoTooltip
            position="top center"
            content="Activity Feed"
            size="mini"
            trigger={
              <VmoMenuItem onClick={() => {}}>
                <FiRss />
              </VmoMenuItem>
            }
          />
          <VmoTooltip
            position="top center"
            content="Notification"
            size="mini"
            trigger={
              <VmoMenuItem onClick={() => {}}>
                <FiBell />
              </VmoMenuItem>
            }
          /> */}
          <VmoTooltip
            position="top center"
            content="Settings"
            size="mini"
            trigger={
              <VmoMenuItem
                active={window.location.pathname.includes(`/settings`)}
                onClick={() => history.push(`/settings/my-profile`)}
              >
                <FiSettings />
              </VmoMenuItem>
            }
          />
          <VmoDropdown icon={<AccountUserImage />} className="link item icon nav-agent-profile mt-2" direction="left">
            <VmoDropdownMenu>
              <VmoDropdownItem onClick={() => history.push(`/settings/my-profile`)}>
                <SvgIcon path="navigation/my-profile" /> <span>My Profile</span>
              </VmoDropdownItem>
              <VmoDropdownItem onClick={() => history.push(`/getting-started`)}>
                <SvgIcon path="navigation/getting-started" /> <span>Getting Started</span>
              </VmoDropdownItem>
              <VmoDropdownItem onClick={() => history.push(`/setting/my-plan`)}>
                <SvgIcon path="navigation/my-plan" /> <span>My Plan</span>
              </VmoDropdownItem>
              <VmoDropdownItem onClick={() => dispatch(logoutCall())}>
                <SvgIcon path="navigation/logout" /> <span>Logout</span>
              </VmoDropdownItem>
            </VmoDropdownMenu>
          </VmoDropdown>
        </div>
      </VmoMenuMenu>
    </VmoMenu>
  )
}
