export const setMetadataInfo = data => {
  window.localStorage.setItem('accountMetadata', JSON.stringify(data))
}

export const getMetadataInfo = () => {
  return JSON.parse(window.localStorage.getItem('accountMetadata') || '{}')
}

export const getRouteState = () => {
  return JSON.parse(window.localStorage.getItem('routeState') || '{}')
}

export const setRouteState = data => {
  window.localStorage.setItem('routeState', JSON.stringify(data))
}

export const getUserInfo = () => {
  return JSON.parse(window.localStorage.getItem('userInfo') || '{}')
}

export const setUserInfo = data => {
  window.localStorage.setItem('userInfo', JSON.stringify(data))
}

export const getAccountInfo = () => {
  return JSON.parse(window.localStorage.getItem('accountInfo') || '{}')
}

export const setAccountInfo = data => {
  window.localStorage.setItem('accountInfo', JSON.stringify(data))
}

export const getRoleInfo = () => {
  return JSON.parse(window.localStorage.getItem('roleInfo') || '{}')
}

export const setRoleInfo = data => {
  window.localStorage.setItem('roleInfo', JSON.stringify(data))
}

export const getProfilePicColor = () => {
  return window.localStorage.getItem('profilePicColor')
}

export const setProfilePicColor = data => {
  window.localStorage.setItem('profilePicColor', data)
}

export const getAppToken = () => {
  return window.localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
}

export const setAppToken = data => {
  window.localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, data)
}

export const getRememberMe = () => {
  return Boolean(window.localStorage.getItem('rememberMe'))
}

export const setRememberMe = data => {
  window.localStorage.setItem('rememberMe', data)
}

export const getLastActivity = () => {
  return window.localStorage.getItem('lastActivity')
}

export const setLastActivity = data => {
  window.localStorage.setItem('lastActivity', data)
}

export const clearStorage = () => {
  window.localStorage.clear()
}
