import {CONVERSATION_BY_CHANNELS, MY_ACTIVITIES, PROJECTS_BY_BUDGET_TYPE, PROJECTS_TIMES_BY_USER} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case MY_ACTIVITIES:
      return {
        ...state,
        myActivitiesData: action.payload,
      }
    case CONVERSATION_BY_CHANNELS:
      return {
        ...state,
        conversationByChannelsData: action.payload,
      }
    case PROJECTS_BY_BUDGET_TYPE:
      return {
        ...state,
        projectsByBudgetTypeData: action.payload,
      }
    case PROJECTS_TIMES_BY_USER:
      return {
        ...state,
        projectTimesByUserData: action.payload,
      }

    default:
      return state
  }
}
