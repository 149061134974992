import {apiAction} from './api'
import {KADENCE_EXPENSES} from './endpoint'

import {
  GET_EXPENSES_CATEGORY,
  CREATE_EXPENSES_CATEGORY,
  DELETE_EXPENSES_CATEGORY,
  GET_SINGLE_EXPENSES_CATEGORY,
  EDIT_EXPENSES_CATEGORY,
  GET_EMPLOYEE_LIST,
  GET_PROJECT_LIST,
  CREATE_EXPENSES,
  GET_ALL_EXPENSES,
  DELETE_EXPENSES,
  GET_SINGLE_EXPENSE,
  UPDATE_EXPENSE,
} from './types'

export function getExpensesCategory() {
  return apiAction({
    url: `${KADENCE_EXPENSES}/categories`,
    method: 'GET',
    label: GET_EXPENSES_CATEGORY,
  })
}

export function createExpensesCategory(data) {
  return apiAction({
    url: `${KADENCE_EXPENSES}/category`,
    method: 'POST',
    label: CREATE_EXPENSES_CATEGORY,
    data,
    showToast: true,
    successMessage: 'Category Added',
  })
}

export function deleteExpensesCategory(id) {
  return apiAction({
    url: `${KADENCE_EXPENSES}/category/${id}`,
    method: 'DELETE',
    label: DELETE_EXPENSES_CATEGORY,
    showToast: true,
    successMessage: 'Category Deleted',
  })
}

export function getSingleExpensesCategory(id) {
  return apiAction({
    url: `${KADENCE_EXPENSES}/category/${id}`,
    method: 'GET',
    label: GET_SINGLE_EXPENSES_CATEGORY,
  })
}

export function editExpensesCategory(id, data) {
  return apiAction({
    url: `${KADENCE_EXPENSES}/category/${id}`,
    method: 'PUT',
    label: EDIT_EXPENSES_CATEGORY,
    data,
    showToast: true,
    successMessage: 'Category Updated',
  })
}

export function getEmployeeList() {
  return apiAction({
    url: `${KADENCE_EXPENSES}/employee-list`,
    method: 'GET',
    label: GET_EMPLOYEE_LIST,
  })
}

export function getProjectList() {
  return apiAction({
    url: `${KADENCE_EXPENSES}/project-list`,
    method: 'GET',
    label: GET_PROJECT_LIST,
  })
}

export function createExpenses(data) {
  return apiAction({
    url: `${KADENCE_EXPENSES}`,
    method: 'POST',
    label: CREATE_EXPENSES,
    data,
    showToast: true,
    successMessage: 'Expense Added',
  })
}

export function getAllExpenses(params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params || {})
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }
  return apiAction({
    url: KADENCE_EXPENSES + query,
    method: 'GET',
    label: GET_ALL_EXPENSES,
  })
}

export function deleteExpenses(id) {
  return apiAction({
    url: `${KADENCE_EXPENSES}/${id}`,
    method: 'DELETE',
    label: DELETE_EXPENSES,
    showToast: true,
    successMessage: 'Expense Deleted',
  })
}

export function getSingleExpenses(id) {
  return apiAction({
    url: `${KADENCE_EXPENSES}/${id}`,
    method: 'GET',
    label: GET_SINGLE_EXPENSE,
  })
}

export function updateExpenses(id, data) {
  return apiAction({
    url: `${KADENCE_EXPENSES}/${id}`,
    method: 'PUT',
    label: UPDATE_EXPENSE,
    data,
    showToast: true,
    successMessage: 'Expense Updated',
  })
}
