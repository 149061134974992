import Joi from 'joi'

export const cancelPlanSchema = Joi.object({
  reason: Joi.string()
    .valid(
      'lack of specific features',
      'product is having bugs or unstable',
      'pricing',
      'product design is not intuitive',
      'others'
    )
    .required()
    .messages({
      'any.only': 'Select any one feedback option',
    }),
  comment: Joi.when('reason', {
    is: 'others',
    then: Joi.string().trim().required().max(250).messages({
      'string.empty': 'comment is not allowed to be empty',
      'string.max': `Length must be less or equal to 250 characters`,
    }),
    otherwise: Joi.string().max(250).allow(null).empty('').messages({
      'string.max': `Length must be less or equal to 250 charecters`,
    }),
  }),
})

export const cardDetailsSchema = Joi.object({
  number: Joi.string().creditCard().required().label('Card Number').messages({
    'string.empty': `Card Number is not allowed to be empty`,
    'any.required': `Card Number is not allowed to be empty`,
    'string.creditCard': `Card Number must be a credit card`,
  }),
  cardHolderName: Joi.string().trim().required().min(5).max(30).label('Card Holder Name').messages({
    'string.empty': `Name is not allowed to be empty`,
    'any.required': `Name is not allowed to be empty`,
    'string.max': `Name must be less than or equal 30 characters`,
    'string.min': `Name must be at least 5 characters long`,
  }),
  isDefault: Joi.boolean().default(false),
  cvc: Joi.string()
    .regex(/^[0-9]{3,4}$/)
    .required()
    .label('CVC')
    .messages({
      'string.empty': `CVV is not allowed to be empty`,
      'string.pattern.base': `Please enter a valid CVV`,
    }),

  expMonth: Joi.string()
    .pattern(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/)
    .required()
    .messages({
      'string.pattern.base': 'Enter valid expiry date',
      'string.empty': 'Exp Date is not allowed to be empty',
    }),
})

export const billingAddressSchema = Joi.object({
  city: Joi.string().trim().min(2).max(40).required().label('City').messages({
    'string.empty': `City is not allowed to be empty`,
  }),
  country: Joi.string().trim().min(2).max(40).required().label('Country').messages({
    'string.empty': `Country must be specified`,
  }),
  line1: Joi.string().trim().min(2).max(40).required().label('Address').messages({
    'string.empty': `Address is not allowed to be empty`,
  }),
  postalCode: Joi.string().required().label('Postal Code'),
  state: Joi.string().trim().min(2).max(40).required().label('State').messages({
    'string.empty': `State is not allowed to be empty`,
  }),
  isDefault: Joi.boolean().default(true),
})
