import React from 'react'
import {Redirect, Route, Switch} from 'react-router'
import Announcements from 'components/pages/Customers/ClientPortal/Announcements'
import Customization from 'components/pages/Customers/ClientPortal/Customization'
import General from 'components/pages/Customers/ClientPortal/General'
import Tickets from 'components/pages/Customers/ClientPortal/Tickets'
import DocumentTitle from 'components/common/DocumentTitle'

const routes = [
  // Client Portal
  {path: '/portal/announcements', component: Announcements, title: 'Announcements', exact: true},
  {path: '/portal/customization', component: Customization, title: 'Customization', exact: true},
  // Settings
  {path: '/portal/general', component: General, title: 'General', exact: true},
  {path: '/portal/tickets', component: Tickets, title: 'Tickets', exact: true},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} clientPortal />
        <Component {...props} />
      </>
    )}
  />
)

const ClientPortalRoute = () => (
  <Switch>
    <Route exact path="/portal">
      <Redirect to="/portal/announcements" />
    </Route>
    {routes.map(renderRoute)}
    <Route path="/portal/*">
      <Redirect to="/portal/announcements" />
    </Route>
  </Switch>
)

export default ClientPortalRoute
