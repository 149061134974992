import React from 'react'

export const dateFormatOptions = [
  {
    key: 'DD MM YYYY',
    value: 'DD MM YYYY',
    text: 'DD MM YYYY',
    content: (
      <div>
        DD MM YYYY <span style={{fontSize: '12px'}}>(31 08 2020)</span>
      </div>
    ),
  },
  {
    key: 'MM DD YYYY',
    value: 'MM DD YYYY',
    text: 'MM DD YYYY',
    content: (
      <div>
        MM DD YYYY <span style={{fontSize: '12px'}}>(08 31 2020)</span>
      </div>
    ),
  },
  {
    key: 'YYYY MM DD',
    value: 'YYYY MM DD',
    text: 'YYYY MM DD',
    content: (
      <div>
        YYYY MM DD <span style={{fontSize: '12px'}}>(2020 08 31)</span>
      </div>
    ),
  },
]

export const dateFormatDelimiterOptions = [
  {key: 'space', value: ' ', text: 'space', content: '  space'},
  {key: 'dot', value: '.', text: 'dot', content: '. dot'},
  {key: 'hyphen', value: '-', text: 'hyphen', content: '- hyphen'},
  {key: 'slash', value: '/', text: 'slash', content: '/ slash'},
]
