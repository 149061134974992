/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState} from 'react'
import {useFieldArray} from 'react-hook-form'
import {MdOutlineMoreVert} from 'react-icons/md'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoDragDropContext,
  VmoDraggable,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoDroppable,
  VmoTooltip,
} from 'vmo-library'
import {projectFormComponents} from 'constants/projects'
import SvgIcon from 'components/common/SvgIcon'
import QuestionSettings from './QuestionSettings'

const Questions = ({selectedQuestion, setSelectedQuestion, control, setValue, setQuestionType, subType}) => {
  const [addComponents, setAddComponents] = useState(false)
  const [showAccordion, setShowAccordion] = useState(false)

  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion,
    move,
  } = useFieldArray({
    control,
    name: 'questions',
  })

  const inAppQuestionSchema = [
    {
      type: 'page',
      order: questionFields.length + 1,
    },
    {
      type: 'header',
      order: questionFields.length + 1,
    },
    {
      type: 'text',
      order: questionFields.length + 1,
    },
    {
      type: 'textarea',
      order: questionFields.length + 1,
    },
    {
      type: 'dropdown',
      order: questionFields.length + 1,
    },
    {
      type: 'checkbox',
      order: questionFields.length + 1,
    },
    {
      type: 'radio',
      order: questionFields.length + 1,
    },
    {
      type: 'number',
      order: questionFields.length + 1,
    },
    {
      type: 'date',
      order: questionFields.length + 1,
    },
  ]

  const handleDeleteQuestion = (question, indexOf) => {
    const tempQuestionList = JSON.parse(JSON.stringify(questionFields))
    if (indexOf > -1) {
      tempQuestionList.splice(indexOf, 1)
      setValue('questions', tempQuestionList)
    }
  }
  const handleDuplicateQuestion = question => {
    const tempNewQuestion = JSON.parse(JSON.stringify(question))
    setValue('questions', [...questionFields, {...tempNewQuestion, id: ''}])
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return null
    if (result?.destination.index === 0 && subType !== 'custom') return
    setValue(`questions[${result.source.index}].order`, result.destination.index + 1)
    move(result.source.index, result.destination.index)
  }

  return (
    <div className="designbuild-Main">
      <VmoDragDropContext onDragEnd={handleOnDragEnd}>
        <VmoDroppable VmoDraggableId="qId">
          {provided => (
            <div className="designbuild-content" {...provided.VmoDraggableProps} ref={provided.innerRef}>
              {questionFields?.length > 0 &&
                questionFields?.map((question, index) => {
                  return (
                    <VmoDraggable
                      key={`${question.id}`}
                      VmoDraggableId={`${question.id}`}
                      index={index}
                      //  isDragDisabled={subType !== 'custom' && index === 0}
                    >
                      {provided => (
                        <div
                          className="component-whole-wrapper"
                          ref={provided.innerRef}
                          {...provided.VmoDraggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            className={
                              selectedQuestion === index ? `component-list-card active` : `component-list-card`
                            }
                            style={selectedQuestion === index ? {borderRadius: '8px 8px 0 0'} : {borderRadius: 8}}
                            onClick={e => {
                              e.preventDefault()
                              setQuestionType(question.type)
                              setSelectedQuestion(index)
                              if (selectedQuestion === index) {
                                setShowAccordion(!showAccordion)
                              } else setShowAccordion(true)
                            }}
                          >
                            <div className="component-list-content">
                              {
                                projectFormComponents.filter(el => {
                                  return el.type === question.type
                                })[0]?.icon
                              }
                              <span>{index + 1}</span>
                              <div className="component-divider" />
                              {
                                projectFormComponents.filter(el => {
                                  return el.type === question.type
                                })[0]?.name
                              }
                            </div>
                            <div className="component-list-actions">
                              <VmoTooltip
                                content="More"
                                size="mini"
                                position="top center"
                                trigger={
                                  <VmoDropdown icon={<MdOutlineMoreVert />}>
                                    <VmoDropdownMenu>
                                      <VmoDropdownItem onClick={() => handleDeleteQuestion(question, index)}>
                                        <SvgIcon path="common/delete" /> Delete
                                      </VmoDropdownItem>
                                      <VmoDropdownItem onClick={() => handleDuplicateQuestion(question)}>
                                        <SvgIcon path="common/clone" /> Duplicate
                                      </VmoDropdownItem>
                                    </VmoDropdownMenu>
                                  </VmoDropdown>
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="component-edit-content"
                            style={{
                              display: selectedQuestion === index && showAccordion ? '' : 'none',
                            }}
                          >
                            <div className="component-edit-wrapper">
                              <QuestionSettings
                                control={control}
                                setValue={setValue}
                                question={question}
                                currentIndex={index}
                                setSelectedQuestion={setSelectedQuestion}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </VmoDraggable>
                  )
                })}
              {provided.placeholder}
              <VmoButton
                className="add-content-btn"
                onClick={e => {
                  setAddComponents(!addComponents)
                  e.preventDefault()
                }}
              >
                <span>
                  <SvgIcon path="common/plus" />
                </span>
                {questionFields?.length !== 0 ? 'Add New' : 'Add Your First Question'}
              </VmoButton>
              {addComponents ? (
                <VmoCard fluid>
                  <VmoCardContent>
                    <div className="d-flex">
                      <div className="left-sde-compo">
                        {projectFormComponents.map(list => (
                          <>
                            {list.type === 'ces' ||
                            list.type === 'form' ||
                            list.type === 'thank-you' ||
                            list.type === 'csat' ||
                            list.type === 'nps' ? null : (
                              <div
                                className="comp-list"
                                key={list.name}
                                onClick={() => {
                                  setAddComponents(false)
                                  appendQuestion(
                                    inAppQuestionSchema.filter(el => {
                                      return el.type === list.type
                                    })[0]
                                  )
                                }}
                              >
                                {list.icon} {list.name}
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </VmoCardContent>
                </VmoCard>
              ) : null}
            </div>
          )}
        </VmoDroppable>
      </VmoDragDropContext>
    </div>
  )
}

export default Questions
