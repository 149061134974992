import {joiResolver} from '@hookform/resolvers'
import {moveProjectFinanceBillingItems} from 'actions/projects'
import SvgIcon from 'components/common/SvgIcon'
import Joi from 'joi'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {removeDoubleQuotes} from 'utils/helper'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

const ProjectBillingMoveExpenseModal = ({open, toggle, projectId, budgetId, expenseIds, invoiceId}) => {
  const dispatch = useDispatch()

  const {billingOpenInvoicesList = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        newInvoiceId: Joi.number().required().messages({
          'number.bas': 'Invoice must be specified',
        }),
      })
    ),
    defaultValues: {
      newInvoiceId: null,
    },
  })

  const submitForm = data => {
    const payload = {...data, expenseIds}
    if (invoiceId) {
      payload.oldInvoiceId = invoiceId
    }
    dispatch(moveProjectFinanceBillingItems({projectId, budgetId, data: payload}))
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>Add Expenses to Invoice</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Choose destination</label>
            <Controller
              name="newInvoiceId"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  placeholder="Select destination"
                  options={billingOpenInvoicesList
                    .filter(invoice => invoice.id !== invoiceId)
                    .map(invoice => ({
                      key: invoice.id,
                      value: invoice.id,
                      text: invoice.invoiceNumber,
                    }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.newInvoiceId && {content: removeDoubleQuotes(errors.newInvoiceId.message)}}
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          Move Expenses
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default ProjectBillingMoveExpenseModal
