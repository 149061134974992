import React from 'react'
import {useSelector} from 'react-redux'

import AdvancedTemplate from './AdvancedTemplate'
import BasicTemplate from './BasicTemplate'
import StandardTemplate from './StandardTemplate'

function PreviewQuote({watch, liveNumber}) {
  const quoteForm = useSelector(state => state.quotes.quoteForm)
  const {previewFile} = useSelector(state => state.quotes.uploadFile)

  const {template: {templateNo = 2} = {}} = quoteForm

  if ((liveNumber || templateNo) === 3) {
    return <AdvancedTemplate data={{...quoteForm, preview: previewFile, ...watch}} />
  }

  if ((liveNumber || templateNo) === 2) {
    return <StandardTemplate data={{...quoteForm, preview: previewFile, ...watch}} />
  }

  return <BasicTemplate data={{...quoteForm, preview: previewFile, ...watch}} />
}

export default PreviewQuote
