import {joiResolver} from '@hookform/resolvers'
import {get, removeDoubleQuotes} from 'utils/helper'
import {getUserInfo} from 'utils/local-storage'
import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {ADD_GMAIL_INBOX} from 'actions/types'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import {addGmailInbox} from 'actions/inbox'
import googleLogo from 'assets/google-logo.svg'
import {gmailSetupSchema} from 'validation/Settings/channels/emailInbox.schema'

const GmailSetupModal = ({open, toggle, assigneeOptionsList, teamOptionsList}) => {
  const dispatch = useDispatch()
  const userId = Number(get(['id'], getUserInfo()))

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {newGmailInboxData = ''} = useSelector(state => state.inboxSetup)

  const {handleSubmit, control, errors} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(gmailSetupSchema),
    defaultValues: {
      inboxName: '',
      assigneeIds: [userId],
      teamIds: [],
    },
  })

  useEffect(() => {
    if (successLabels.includes(ADD_GMAIL_INBOX)) {
      window.open(newGmailInboxData, '_self')
    }
  }, [successLabels, newGmailInboxData])

  return (
    <VmoModal size="small" closeIcon open={open} onClose={() => toggle()}>
      <VmoModalHeader>Connect your Gmail</VmoModalHeader>
      <VmoModalContent className="emlIbxSetupModal">
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <div className="info-header">
              <label className="label-class" htmlFor="inboxName">
                Inbox name
              </label>
              <VmoTooltip
                trigger={<AiOutlineInfoCircle />}
                content="Specify a name for your mailbox"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="inboxName"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  placeholder="Enter Inbox Name"
                  id="inboxName"
                  maxLength={30}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.inboxName && {
                      content: removeDoubleQuotes(errors.inboxName.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>

          <VmoFormField required>
            <div className="info-header">
              <label className="label-class" htmlFor="assigneeIds">
                Assignee
              </label>
              <VmoTooltip
                trigger={<AiOutlineInfoCircle />}
                content="Select from the drop down whom you want to assign"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="assigneeIds"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  placeholder="Select Assignee"
                  search
                  multiple
                  id="assigneeIds"
                  options={assigneeOptionsList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.assigneeIds && {
                      content: removeDoubleQuotes(errors.assigneeIds.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>

          <VmoFormField>
            <label htmlFor="teamIds">Team</label>
            <Controller
              name="teamIds"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  placeholder="Select Team"
                  search
                  multiple
                  id="teamIds"
                  options={teamOptionsList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.teamIds && {
                      content: removeDoubleQuotes(errors.teamIds.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton primary onClick={handleSubmit(data => dispatch(addGmailInbox(data)))}>
          <img src={googleLogo} alt="google" />
          Connect your Gmail Account
        </VmoButton>

        <VmoButton basic onClick={() => toggle()}>
          Back
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default GmailSetupModal
