import Joi from 'joi'

import {inputStringValidation} from 'validation'

export const appraisalTemplateSchema = Joi.object({
  name: inputStringValidation.required(),
  description: Joi.string().required().max(200).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
  viewByEmployeeAfterSubmit: Joi.boolean(),
  viewByReviewerAfterSubmit: Joi.boolean(),
  showEmployeeName: Joi.boolean(),
  showEmployeeNumber: Joi.boolean(),
  showDateOfBirth: Joi.boolean(),
  showAge: Joi.boolean(),
  showDateOfJoin: Joi.boolean(),
  showYearOfService: Joi.boolean(),
  showCountryOfWork: Joi.boolean(),
  showOrganisation: Joi.boolean(),
  showDepartment: Joi.boolean(),
  showPosition: Joi.boolean(),
  templateSections: Joi.array().items({
    indexNo: Joi.number(),
    weightage: Joi.number().allow(NaN),
    sectionName: Joi.string(),
    sectionFields: Joi.array().items({
      fieldType: Joi.string(),
      label: Joi.string(),
      minPointLabel: Joi.string(),
      maxPointLabel: Joi.string(),
      withPoints: Joi.boolean(),
      maxPoints: Joi.number(),
      numberOfButtons: Joi.number(),
      fieldQuestions: Joi.array().items({
        question: Joi.string(),
        inputFor: Joi.string(),
        employeeAnswer: Joi.string().allow(null),
        reviewerAnswer: Joi.string().allow(null),
        points: Joi.number().allow(null),
      }),
    }),
  }),
  showWeightage: Joi.boolean(),
  sectionWeightage: Joi.array().items({
    label: Joi.string().allow('', null),
    value: Joi.number(),
  }),
})

export const updateappraisalTemplateSchema = Joi.object({
  name: inputStringValidation.required(),
  description: Joi.string().required().max(200).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
  viewByEmployeeAfterSubmit: Joi.boolean(),
  viewByReviewerAfterSubmit: Joi.boolean(),
  showEmployeeName: Joi.boolean(),
  showEmployeeNumber: Joi.boolean(),
  showDateOfBirth: Joi.boolean(),
  showAge: Joi.boolean(),
  showDateOfJoin: Joi.boolean(),
  showYearOfService: Joi.boolean(),
  showCountryOfWork: Joi.boolean(),
  showOrganisation: Joi.boolean(),
  showDepartment: Joi.boolean(),
  showPosition: Joi.boolean(),
  templateSections: Joi.array().items({
    id: Joi.number().allow(null),
    indexNo: Joi.number(),
    weightage: Joi.number().allow(NaN),
    sectionName: Joi.string(),
    sectionFields: Joi.array().items({
      fieldType: Joi.string(),
      label: Joi.string().max(60).messages({
        'string.max': `Length must be less than or equal to 60 characters`,
      }),
      withPoints: Joi.boolean(),
      maxPoints: Joi.number(),
      fieldQuestions: Joi.array().items({
        question: Joi.string(),
        inputFor: Joi.string(),
        employeeAnswer: Joi.string().allow(null),
        reviewerAnswer: Joi.string().allow(null),
        points: Joi.number().allow(null),
      }),
    }),
  }),
  showWeightage: Joi.boolean(),
  sectionWeightage: Joi.array().items({
    label: Joi.string().allow('', null),
    value: Joi.number(),
  }),
})

export const sendAppraisalSchema = Joi.object({
  flow: Joi.string().valid('upward', 'downward'),
  reviewerId: Joi.number().integer().required(),
  isPrivate: Joi.boolean(),
  setNotification: Joi.boolean(),
  notificationTimeline: Joi.object()
    .keys({startDate: Joi.date().iso(), endDateForEmployee: Joi.date().iso(), endDate: Joi.date().iso()})
    .allow('', null),
  employeeIds: Joi.array().items(Joi.number()),
})
