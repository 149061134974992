import {LOGIN_USER, VERIFY_DOMAIN} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case LOGIN_USER: {
      return {
        loginUserDetails: action.payload,
      }
    }

    case VERIFY_DOMAIN:
      return {
        ...state,
        domainVerifyData: action.payload,
      }

    default:
      return state
  }
}
