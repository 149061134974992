import SvgIcon from 'components/common/SvgIcon'
import {projectStatusList} from 'constants/projects'
import React from 'react'
import {Controller} from 'react-hook-form'
import {removeDoubleQuotes} from 'utils/helper'
import {VmoDatePicker, VmoForm, VmoFormField, VmoFormGroup, VmoFormSelect, VmoTooltip} from 'vmo-library'

const ProjectAdvanceOptionCarousel = ({
  style,
  control,
  errors,
  usersList,
  contactsList,
  categoryList,
  setCategoryList,
}) => {
  return (
    <VmoForm className="errorLabel" style={style}>
      <VmoFormField required>
        <div className="info-header">
          <label className="label-class">Users</label>
          <VmoTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Add members in the project"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="assignedTo"
          control={control}
          render={({value, onChange}) => (
            <VmoFormSelect
              search
              multiple
              options={usersList}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={errors.assignedTo && {content: removeDoubleQuotes(errors.assignedTo.message)}}
            />
          )}
        />
      </VmoFormField>

      <VmoFormField required>
        <div className="info-header">
          <label className="label-class">Project Owner</label>
          <VmoTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Assign a owner for the project for management purpose"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="managerId"
          control={control}
          render={({value, onChange}) => (
            <VmoFormSelect
              search
              options={usersList}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={errors.managerId && {content: removeDoubleQuotes(errors.managerId.message)}}
            />
          )}
        />
      </VmoFormField>

      <VmoFormField required>
        <div className="info-header">
          <label>Contacts</label>
          <VmoTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Select contact for the project"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="contactIds"
          control={control}
          render={({value, onChange}) => (
            <VmoFormSelect
              search
              multiple
              options={contactsList}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={errors.contactIds && {content: removeDoubleQuotes(errors.contactIds.message)}}
            />
          )}
        />
      </VmoFormField>

      <VmoFormGroup>
        <VmoFormField width={8}>
          <div className="info-header">
            <label>Project start date</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Set the start date of the project"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="projectStartDate"
            control={control}
            render={({value, onChange}) => (
              <VmoDatePicker
                time={false}
                value={value ? new Date(value) : value}
                onChange={value => onChange(value)}
                inputProps={{readOnly: true}}
              />
            )}
          />
        </VmoFormField>
        <VmoFormField width={8}>
          <div className="info-header">
            <label>Project end date</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Set the end date of the project"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="projectEndDate"
            control={control}
            render={({value, onChange}) => (
              <VmoDatePicker
                time={false}
                value={value ? new Date(value) : value}
                onChange={value => onChange(value)}
                inputProps={{readOnly: true}}
              />
            )}
          />
        </VmoFormField>
      </VmoFormGroup>

      <VmoFormGroup>
        <VmoFormField width={8} required>
          <div className="info-header">
            <label className="label-class">Category</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Set Category of the project"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="categoryId"
            control={control}
            render={({value, onChange}) => (
              <VmoFormSelect
                selection
                fluid
                search
                options={categoryList}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={errors.categoryId && {content: removeDoubleQuotes(errors.categoryId.message)}}
                allowAdditions
                onAddItem={(e, {value}) => setCategoryList([{key: value, value, text: value}, ...categoryList])}
              />
            )}
          />
        </VmoFormField>
        <VmoFormField width={8} required>
          <div className="info-header">
            <label className="label-class">Status</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Select initial status of the project"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="status"
            control={control}
            render={({value, onChange}) => (
              <VmoFormSelect
                options={projectStatusList}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={errors.status && {content: removeDoubleQuotes(errors.status.message)}}
              />
            )}
          />
        </VmoFormField>
      </VmoFormGroup>
    </VmoForm>
  )
}

export default ProjectAdvanceOptionCarousel
