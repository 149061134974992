import _ from 'lodash'
import {get} from 'utils/helper'

export const commonEmail = props => {
  const {dispatch, id, data, singleData, formStateVal, addApi, updateApi, removeEmail} = props
  const dirtyEmailIndexArray = _.map(_.keys(_.pickBy(formStateVal?.emails, _.isDefined)), Number)

  dirtyEmailIndexArray.forEach(emailItem => {
    if (formStateVal.emails[emailItem] === true) {
      return removeEmail(emailItem)
    }
    if (
      emailItem >= get(['emails'], singleData, []).length &&
      _.get(data, `emails[${emailItem}].email`) &&
      _.get(data, `emails[${emailItem}].category`)
    ) {
      const newEmail = data.emails[emailItem]
      dispatch(addApi(id, newEmail))
    }

    if (
      emailItem < get(['emails'], singleData, []).length &&
      _.get(data, `emails[${emailItem}].email`) &&
      _.get(data, `emails[${emailItem}].category`)
    ) {
      if (
        typeof emailItem !== 'undefined' &&
        (_.get(formStateVal, `emails.${emailItem}.category`) || _.get(formStateVal, `emails.${emailItem}.email`)) &&
        _.get(data, `emails.${emailItem}.category`) &&
        _.get(data, `emails.${emailItem}.email`)
      ) {
        dispatch(updateApi(id, _.get(singleData, `emails.${emailItem}.id`), data.emails[emailItem]))
      }
    }
  })
}

export const commonPhone = props => {
  const {dispatch, id, data, singleData, formStateVal, addApi, updateApi, removePhone} = props
  const dirtyPhoneIndexArray = _.map(_.keys(_.pickBy(formStateVal?.phones, _.isDefined)), Number)

  dirtyPhoneIndexArray.forEach(phoneItem => {
    if (formStateVal.phones[phoneItem] === true) {
      return removePhone(phoneItem)
    }
    if (
      phoneItem >= get(['phones'], singleData, []).length &&
      _.get(data, `phones[${phoneItem}].phone`) &&
      _.get(data, `phones[${phoneItem}].category`)
    ) {
      const newPhone = data.phones[phoneItem]
      dispatch(addApi(id, newPhone))
    }

    if (
      phoneItem < get(['phones'], singleData, []).length &&
      _.get(data, `phones[${phoneItem}].phone`) &&
      _.get(data, `phones[${phoneItem}].category`)
    ) {
      if (
        typeof phoneItem !== 'undefined' &&
        (_.get(formStateVal, `phones.${phoneItem}.category`) || _.get(formStateVal, `phones.${phoneItem}.phone`)) &&
        _.get(data, `phones.${phoneItem}.category`) &&
        _.get(data, `phones.${phoneItem}.phone`)
      ) {
        dispatch(updateApi(id, _.get(singleData, `phones.${phoneItem}.id`), data.phones[phoneItem]))
      }
    }
  })
}

export const commonWebsite = props => {
  const {dispatch, id, data, singleData, formStateVal, addApi, updateApi, removeWebsite} = props
  const dirtyWebsiteIndexArray = _.map(_.keys(_.pickBy(formStateVal?.websites, _.isDefined)), Number)

  dirtyWebsiteIndexArray.forEach(websiteItem => {
    if (formStateVal.websites[websiteItem] === true) {
      return removeWebsite(websiteItem)
    }
    if (
      websiteItem >= get(['websites'], singleData, []).length &&
      _.get(data, `websites[${websiteItem}].url`) &&
      _.get(data, `websites[${websiteItem}].category`)
    ) {
      const newWebsite = data.websites[websiteItem]
      dispatch(addApi(id, newWebsite))
    }

    if (
      websiteItem < get(['websites'], singleData, []).length &&
      _.get(data, `websites[${websiteItem}].url`) &&
      _.get(data, `websites[${websiteItem}].category`)
    ) {
      if (
        typeof websiteItem !== 'undefined' &&
        (_.get(formStateVal, `websites.${websiteItem}.category`) ||
          _.get(formStateVal, `websites.${websiteItem}.url`)) &&
        _.get(data, `websites.${websiteItem}.category`) &&
        _.get(data, `websites.${websiteItem}.url`)
      ) {
        dispatch(updateApi(id, _.get(singleData, `websites.${websiteItem}.id`), data.websites[websiteItem]))
      }
    }
  })
}
