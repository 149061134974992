/* eslint-disable no-use-before-define */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiList} from 'react-icons/fi'
import axios from 'axios'
import _ from 'lodash'
import {
  VmoAttachment,
  VmoButton,
  VmoChipInput,
  VmoDropdown,
  VmoDropdownDivider,
  VmoDropdownHeader,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoForm,
  VmoFormField,
  VmoFormTextArea,
  VmoInput,
  VmoTab,
  VmoTabPane,
  VmoTextArea,
  VmoTooltip,
} from 'vmo-library'

import {ADD_NOTE_COMPANY, ADD_NOTE_CONTACT, ADD_NOTE_DEAL, SEND_MAIL} from 'actions/types'
import {uploadAttachments} from 'actions/attachments'
import {
  getAllMailInbox,
  getEmailIdPairs,
  getMailBox,
  getUserTemplates,
  sendEmail,
  singleUserTemplates,
} from 'actions/inbox'
import {extractTextContentFromHTML, get, getPermissionTooltipContent} from 'utils/helper'
import AttachmentPreview from 'components/common/AttachmentPreview'
import LogInteraction from './LogInteraction'
import LockPermissionTooltip from './LockPermissionTooltip'
import SvgIcon from './SvgIcon'

const Category = props => {
  const {id, addNote, addLogInteration, rolePermissions = true} = props
  const dispatch = useDispatch()
  const [notes, setNotes] = useState('')
  const [listOfInbox, setListOfInbox] = useState([])
  const [emailIdPairsList, setEmailIdPairsList] = useState([])
  const [files, setFiles] = useState([])
  const [templateList, setTemplateList] = useState([])

  const [uploadedFiles, setUploadedFiles] = useState([])
  const [emailEditorCurrentValue, setEmailEditorCurrentValue] = useState('')

  const [data, setData] = useState({
    sendLaterTime: new Date(),
    attachments: [],
    from: '',
    to: [],
    cc: [],
    bcc: [],
    sendTime: new Date(),
    subject: '',
  })
  const [toggleStates, setToggleStates] = useState({
    sendLater: false,
    isCCActive: false,
    isBCCActive: false,
    isSendLaterActive: false,
    moreCanOpen: false,
    timePicker: false,
  })

  const {CancelToken} = axios
  const requestToken = CancelToken.source()

  const {inbox: mailboxes} = useSelector(state => state.mailInbox)
  const {getUserTemplates: userTemplates = [], emailIdPairsData} = useSelector(state => state.emailInbox)
  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (mailboxes) {
      const mailBoxList = mailboxes
        .filter(ls => ls.type === 'email')
        .map(mail => {
          return {
            value: mail.id,
            key: mail.id,
            content: (
              <div>
                {get(['name'], mail, '')}
                <small className="ml-2 kadence-lead">({get(['email'], mail, '')})</small>
                {!mail.emailVerified && <small className="ml-2 kadence-lead">- not verified</small>}
              </div>
            ),
            text: `${get(['name'], mail, '')}`,
            disabled: !mail.emailVerified,
          }
        })

      setListOfInbox(mailBoxList)
    }
    if (emailIdPairsData) {
      setEmailIdPairsList(emailIdPairsData)
    }
  }, [mailboxes, emailIdPairsData])

  useEffect(() => {
    dispatch(getAllMailInbox())
    dispatch(getEmailIdPairs())
  }, [dispatch])

  useEffect(() => {
    if (
      successLabels.includes(ADD_NOTE_COMPANY) ||
      successLabels.includes(ADD_NOTE_CONTACT) ||
      successLabels.includes(ADD_NOTE_DEAL)
    ) {
      setNotes('')
    }
    if (successLabels.includes(SEND_MAIL)) {
      resetData()
    }
  }, [successLabels])

  const resetData = () => {
    setData({
      sendLaterTime: new Date(),
      attachments: [],
      from: '',
      to: [],
      cc: [],
      bcc: [],
      sendTime: new Date(),
      subject: '',
    })
    setEmailEditorCurrentValue('')
    setUploadedFiles([])
    setFiles([])
  }
  const sendMail = (draft, conversationId, isSendLaterActive) => {
    const messageBody = emailEditorCurrentValue

    const messageText = extractTextContentFromHTML(messageBody)

    const updatedSubject = data.subject === '' ? '(no subject)' : data.subject

    const ccMail = data.cc.map(mail => ({
      email: mail,
    }))

    const bccMail = data.bcc.map(mail => ({
      email: mail,
    }))

    const payload = {
      inboxId: data.from,
      subject: updatedSubject,
      to: data.to,
      cc: ccMail,
      bcc: bccMail,
      isDraft: draft,
      messageHtml: messageBody,
      messageText,
      type: 'email',
    }

    if (files.length > 0) {
      payload.attachments = uploadedFiles.map(file => file.id)
    }
    if (isSendLaterActive) {
      payload.sendLater = true
      payload.sendTime = data.sendLaterTime
    }

    dispatch(sendEmail(payload))
  }
  const handleSendMail = () => {
    if (data.from === '' || _.isEqual(data.to, []) || emailEditorCurrentValue === '') {
      return true
    }
    return false
  }

  const searchFromTemplateList = searchString => {
    const updatedList = templateList.filter(template => template.subject.includes(searchString))
    setTemplateList(updatedList)
    if (searchString === '') {
      setTemplateList(userTemplates)
    }
  }
  const panes = [
    {
      menuItem: {
        key: 'addNote',
        icon: <SvgIcon path="clients/contacts/contacts-dashboard/add-note" />,
        content: 'Add Note',
      },
      render: () => (
        <VmoTabPane attached={false}>
          <VmoForm className="addNoteTimeline">
            <VmoFormTextArea
              placeholder="Type your note here..."
              value={notes}
              onChange={e => setNotes(e.target.value)}
              style={{background: '#fff'}}
              disabled={!rolePermissions}
            />
            <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
              <VmoButton
                className={`mt-0 ${!rolePermissions ? 'disabled-button' : ''}`}
                onClick={() => {
                  if (!rolePermissions) return
                  const data = {
                    note: notes,
                  }
                  if (notes.length !== 0 && notes.trim() !== '') {
                    if (id) {
                      dispatch(addNote(id, data))
                      setNotes('')
                    }
                  }
                }}
                primary
                // size="tiny"
              >
                Add
              </VmoButton>
            </LockPermissionTooltip>
          </VmoForm>
        </VmoTabPane>
      ),
    },
    {
      menuItem: {
        key: 'sendEmail',
        icon: <SvgIcon path="clients/contacts/contacts-dashboard/send-email" />,
        content: 'Send Email',
      },
      render: () => (
        <VmoTabPane attached={false}>
          <div className="sendEmailTimeLine">
            <div className="emailActionFields">
              <label>From: </label>
              <div className="mailActivity">
                <VmoDropdown
                  button
                  icon="chevron down"
                  direction="right"
                  placeholder="Select Inbox"
                  options={listOfInbox}
                  onChange={(e, value) => {
                    dispatch(getMailBox(value.value))

                    const newData = {...data, from: value.value}

                    setData(newData)
                  }}
                  value={data.from}
                />
              </div>
            </div>
            <div className="emailActionFields">
              <label>To: </label>
              <div className="mailActivity">
                <VmoDropdown
                  button
                  icon="chevron down"
                  options={emailIdPairsList}
                  placeholder="Select Email"
                  multiple
                  value={data.to}
                  onChange={(e, {value}) => setData({...data, to: value})}
                />
              </div>
              <div className="ccBcc">
                {!toggleStates.isCCActive && (
                  <VmoButton onClick={() => setToggleStates({...toggleStates, isCCActive: true})} color="light">
                    Cc
                  </VmoButton>
                )}
                {!toggleStates.isBCCActive && (
                  <VmoButton onClick={() => setToggleStates({...toggleStates, isBCCActive: true})} color="light">
                    Bcc
                  </VmoButton>
                )}
              </div>
            </div>
            <div>
              {toggleStates.isCCActive && (
                <div className="emailActionFields">
                  <label>Cc: </label>

                  <VmoChipInput defaultValue={data.cc} onChange={value => setData({...data, cc: value})} />
                </div>
              )}
              {toggleStates.isBCCActive && (
                <div className="emailActionFields">
                  <label>Bcc: </label>

                  <VmoChipInput defaultValue={data.bcc} onChange={value => setData({...data, bcc: value})} />
                </div>
              )}
            </div>
            <div className="emailActionFields">
              <label>Subject: </label>
              <VmoInput
                placeholder="Enter Subject"
                onChange={e => setData({...data, subject: e.target.value})}
                value={data.subject}
              />
            </div>
            <VmoForm className="mt-3">
              <VmoFormField>
                <VmoFormTextArea
                  placeholder="type email here..."
                  maxLength={500}
                  value={emailEditorCurrentValue}
                  onChange={e => setEmailEditorCurrentValue(e.target.value)}
                />
              </VmoFormField>
            </VmoForm>
            <AttachmentPreview
              files={files}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              success="composeAttachment"
              cancelToken={requestToken}
            />
            <div className="d-flexb my-3">
              <div className="d-flex" style={{justifyContent: 'flex-start'}}>
                <VmoTooltip
                  content={rolePermissions ? 'Attachment' : getPermissionTooltipContent({roleAccess: rolePermissions})}
                  size="mini"
                  position="top center"
                  trigger={
                    rolePermissions ? (
                      <VmoAttachment
                        content={<SvgIcon path="common/attachment" />}
                        multiple
                        onChange={e => {
                          const data = {
                            file: e,
                          }
                          if (e.length > 0) {
                            dispatch(uploadAttachments(data, 'composeAttachment', requestToken))
                          }
                        }}
                        onClick={e => {
                          e.target.value = null
                        }}
                        fileList={files => {
                          setFiles(files)
                        }}
                      />
                    ) : (
                      <button type="button" className="ui icon button attach">
                        <SvgIcon path="common/attachment" />
                      </button>
                    )
                  }
                />

                <VmoTooltip
                  content={
                    rolePermissions ? 'Email templates' : getPermissionTooltipContent({roleAccess: rolePermissions})
                  }
                  size="mini"
                  position="top center"
                  trigger={
                    <VmoDropdown
                      button
                      direction="right"
                      floating
                      className="icon ml-2"
                      onClick={() => dispatch(getUserTemplates())}
                      closeOnChange={false}
                      icon={<FiList />}
                    >
                      <VmoDropdownMenu>
                        <VmoDropdownHeader>Insert Email Templates</VmoDropdownHeader>
                        <VmoDropdownDivider />
                        <VmoInput
                          icon="search"
                          iconPosition="left"
                          type="search"
                          name="search"
                          id="search"
                          placeholder="Search email templates... "
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                          onChange={e => {
                            searchFromTemplateList(e.target.value)
                          }}
                        />
                        <VmoDropdownDivider />
                        {templateList.length > 0 ? (
                          templateList.map(temp => (
                            <VmoDropdownItem
                              key={temp.id}
                              disabled={!rolePermissions}
                              onClick={() =>
                                rolePermissions &&
                                dispatch(singleUserTemplates(get(['id'], temp, '-'), null, 'templateListCompose'))
                              }
                            >
                              {get(['subject'], temp, '-')}
                            </VmoDropdownItem>
                          ))
                        ) : (
                          <VmoDropdownItem>No Data Available</VmoDropdownItem>
                        )}
                      </VmoDropdownMenu>
                    </VmoDropdown>
                  }
                />
                {/* <VmoButton icon>
                  <FiFileText />
                </VmoButton> */}
              </div>
              <div>
                <VmoButton basic onClick={resetData}>
                  Reset
                </VmoButton>
                <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
                  <VmoButton
                    className="ml-3"
                    primary
                    loading={isLoadingData && type.includes(SEND_MAIL)}
                    disabled={handleSendMail() || (isLoadingData && type.includes(SEND_MAIL))}
                    onClick={() => rolePermissions && sendMail(false, null, toggleStates.isSendLaterActive)}
                  >
                    Send
                  </VmoButton>
                </LockPermissionTooltip>
              </div>
            </div>
          </div>
        </VmoTabPane>
      ),
    },
    {
      menuItem: {
        key: 'logInteraction',
        icon: <SvgIcon path="clients/contacts/contacts-dashboard/log-interaction" />,
        content: 'Log Interaction',
      },
      render: () => (
        <VmoTabPane attached={false}>
          <LogInteraction id={id} addLogInteration={addLogInteration} rolePermissions={rolePermissions} />
        </VmoTabPane>
      ),
    },
  ]
  return (
    <div>
      <VmoTab key="addTab" className="addTab" menu={{secondary: true, pointing: true, color: 'blue'}} panes={panes} />
    </div>
  )
}

export default Category
