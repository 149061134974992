/* eslint-disable eqeqeq */
/* eslint-disable no-case-declarations */
import {
  BOOKING_SLOT_AVALIBLITY,
  DELETE_MEETINGS,
  EDIT_MEETING,
  GET_MEETING_LIST,
  GET_SINGLE_MEETING,
  GOOGLE_CALENDAR_ACCESS,
  GOOGLE_CALENDAR_AUTH_CHECK,
  GOOGLE_CALENDAR_DISCONNECT,
  MEETING_CALENDAR_LIST,
  MEETING_CALENDAR_TWO_WAY_SYNC,
  UNAVAILABLE_SLOTS,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_MEETING_LIST:
      return {
        ...state,
        meetingsList: action.payload,
      }
    case DELETE_MEETINGS:
      const temp = state.meetingsList ? [...state.meetingsList?.meetingList] : []
      const ids = action.payload

      return {
        ...state,
        meetingsList: {
          ...state.meetingsList,
          meetingList: temp.filter(meeting => ids.indexOf(meeting.id) === -1),
        },
      }
    case GET_SINGLE_MEETING:
      return {
        ...state,
        getSingleMeeting: action.payload,
      }
    case EDIT_MEETING:
      return {
        ...state,
        getSingleMeeting: action.payload,
      }
    case GOOGLE_CALENDAR_ACCESS:
      return {
        ...state,
        gCalXc: action.payload,
      }
    case BOOKING_SLOT_AVALIBLITY:
      return {
        ...state,
        booking_slot: action.payload,
      }
    case UNAVAILABLE_SLOTS:
      return {
        ...state,
        booking_slot: {...state.booking_slot, userBusyTime: action.payload},
      }
    case GOOGLE_CALENDAR_AUTH_CHECK:
      return {
        ...state,
        userData: action.payload,
      }
    case MEETING_CALENDAR_LIST:
      return {
        ...state,
        addCalendar: action.payload?.addCalendar,
        meetingCalendarList: action.payload?.calendarList,
      }
    case MEETING_CALENDAR_TWO_WAY_SYNC:
      const calTwoWaySync = state.meetingCalendarList
      const updatedCalTwoWaySync = calTwoWaySync.map(list => {
        if (action.payload == list.calendarId) {
          return {...list, twoWaySync: !list.twoWaySync}
        }
        return list
      })
      return {
        ...state,
        meetingCalendarList: updatedCalTwoWaySync,
      }
    case GOOGLE_CALENDAR_DISCONNECT:
      const GoogleDisconnect = state.meetingCalendarList ? [...state.meetingCalendarList] : []
      const CalendarIds = action.payload

      return {
        ...state,
        meetingCalendarList: action.payload
          ? GoogleDisconnect.filter(meeting => CalendarIds.indexOf(meeting.calendarId) === -1)
          : GoogleDisconnect,
      }

    default:
      return state
  }
}
