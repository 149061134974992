/* eslint-disable no-case-declarations */
import _ from 'lodash'
import {
  CREATE_CONTACT,
  GET_ALL_CONTACTS,
  GET_SINGLE_CONTACT,
  EDIT_CONTACT,
  ADD_NOTE_CONTACT,
  GET_ALL_CONTACT_NOTES,
  GET_ALL_ACTIVITIES_CONTACT,
  ADD_CONTACT_INTERACTION,
  CONTACT_STATUS_LIST,
  CONTACT_TYPE_LIST,
  DELETE_CONTACT,
  MULTIPLE_UPDATE_CONTACT,
  EXPORT_ALL_CONTACTS,
  SAMPLE_CONTACTS_IMPORT,
  IMPORT_CONTACTS,
  ADD_PHONE_CONTACT,
  ADD_EMAIL_CONTACT,
  ADD_CONTACT_WEBSITE,
  UPDATE_PHONE_CONTACT,
  UPDATE_CONTACT_WEBSITE,
  DELETE_CONTACT_EMAIL,
  DELETE_CONTACT_PHONE,
  UPDATE_EMAIL_CONTACT,
  DELETE_CONTACT_WEBSITE,
  COLUMNS_SELECTION_CONTACT,
  GET_COLUMNS_SELECTION_CONTACT,
  CONTACT_ALL_COMPANIES,
  MULTIPLE_MERGE_CONTACT,
  ADD_SOCIAL,
  UPDATE_SOCIAL,
  REMOVE_SOCIAL,
  ATTACHMENT_POST_CONTACT,
  GET_CONTACT_DETAILS,
  GET_ALL_CONTACTS_IDS,
} from 'actions/types'
import {get} from 'utils/helper'

export default function (state = {}, action) {
  switch (action.type) {
    case CREATE_CONTACT:
      return {
        ...state,
        getAllContactsList: [action.payload, ...state.getAllContactsList],
      }

    case GET_ALL_CONTACTS: {
      const {contacts = [], ...rest} = action.payload
      return {
        ...state,
        getAllContactsList: contacts ?? [],
        contactPage: {...rest},
      }
    }

    case GET_ALL_CONTACTS_IDS:
      return {...state, contactsIdsList: action.payload}

    case GET_SINGLE_CONTACT: {
      return {
        ...state,
        singleContact: action.payload,
      }
    }
    case GET_CONTACT_DETAILS:
      return {
        ...state,
        getContactDetails: action.payload,
      }
    case EDIT_CONTACT: {
      const temp = [...get(['getAllContactsList'], state, [])]
      const updatedIndex = temp.findIndex(list => list.id === action.payload.id)
      temp.splice(updatedIndex, 1, action.payload)
      return {
        ...state,
        singleContact: action.payload,
        getAllContactsList: temp,
      }
    }
    // case EDIT_CONTACT: {
    //   // const data =
    //   const temp = state ? state.getAllContactsList : ' ';
    //   const dataUpdate = temp.map((list) => {
    //     if (list.id === action.payload.id) {
    //       return action.payload;
    //     } else {
    //       return list;
    //     }
    //   });
    //   return {
    //     ...state,
    //     getAllContactsList: dataUpdate,
    //
    //   };
    // }

    case ATTACHMENT_POST_CONTACT:
      return {
        ...state,
      }

    case ADD_NOTE_CONTACT:
      return {
        ...state,
        singleContact: action.payload,
      }

    case GET_ALL_CONTACT_NOTES:
      return {...state, getAllContactNoteData: action.payload}

    case GET_ALL_ACTIVITIES_CONTACT:
      return {
        ...state,
        getAllContactActivitiesData: action.payload,
      }

    case ADD_CONTACT_INTERACTION:
      return {
        ...state,
        singleContact: action.payload,
      }

    case CONTACT_STATUS_LIST:
      return {
        ...state,
        contactStatusListData: action.payload,
      }

    case CONTACT_TYPE_LIST:
      return {
        ...state,
        getTypeListData: action.payload,
      }

    case DELETE_CONTACT:
      const temp = state.getAllContactsList ? [...state.getAllContactsList] : []
      const ids = action.payload
      return {
        ...state,
        getAllContactsList: temp.filter(contact => ids.indexOf(contact.id) === -1),
        contactPage: {
          ...state.contactPage,
          total: _.subtract(_.get(state, 'contactPage.total'), _.size(ids)),
        },
      }

    case MULTIPLE_UPDATE_CONTACT: {
      const temp = state.getAllContactsList ? [...state.getAllContactsList] : []
      if (action.payload) {
        action.payload.forEach(updated => {
          const updatedIndex = temp.findIndex(list => list.id === updated.id)
          temp.splice(updatedIndex, 1, updated)
        })
      }
      return {
        ...state,
        getAllContactsList: action.payload ? temp : state.getAllContactsList,
      }
    }

    case EXPORT_ALL_CONTACTS: {
      return {
        ...state,
        exportAllContactsData: action.payload,
      }
    }
    case SAMPLE_CONTACTS_IMPORT:
      return {...state, sampleContactImport: action.payload}
    case IMPORT_CONTACTS:
      return {
        ...state,
        importContactData: action.payload,
      }

    case ADD_PHONE_CONTACT:
      return {
        ...state,
        singleContact: action.payload ? action.payload : state.singleContact,
      }

    case ADD_EMAIL_CONTACT:
      return {
        ...state,
        singleContact: action.payload ? action.payload : state.singleContact,
      }

    case ADD_CONTACT_WEBSITE:
      return {
        ...state,
        singleContact: action.payload ? action.payload : state.singleContact,
      }

    case UPDATE_PHONE_CONTACT:
      return {
        ...state,
        singleContact: action.payload ? action.payload : state.singleContact,
      }

    case UPDATE_EMAIL_CONTACT:
      return {
        ...state,
        singleContact: action.payload ? action.payload : state.singleContact,
      }

    case UPDATE_CONTACT_WEBSITE:
      return {
        ...state,
        singleContact: action.payload ? action.payload : state.singleContact,
      }

    case DELETE_CONTACT_EMAIL:
      return {
        ...state,
        singleContact: action.payload ? action.payload : state.singleContact,
      }

    case DELETE_CONTACT_PHONE:
      return {
        ...state,
        singleContact: action.payload ? action.payload : state.singleContact,
      }

    case DELETE_CONTACT_WEBSITE:
      return {
        ...state,
        singleContact: action.payload ? action.payload : state.singleContact,
      }

    case COLUMNS_SELECTION_CONTACT:
      return {
        ...state,
        getAllContactColumnsList: action.payload,
      }
    case GET_COLUMNS_SELECTION_CONTACT: {
      return {
        ...state,
        getAllContactColumnsList: action.payload,
      }
    }

    case CONTACT_ALL_COMPANIES:
      return {...state, getAllContactCompanyList: action.payload}

    case MULTIPLE_MERGE_CONTACT:
      let updatedList = []
      const getContactList = state.getAllContactsList ? [...state.getAllContactsList] : []
      // getContactList.forEach((updated) => {
      //  updatedList = action.payload.ids.filter((list) => list.id !== updated.id);
      updatedList = getContactList.filter(contact => action.payload.ids.indexOf(contact.id) === -1)
      return {
        ...state,
        getAllContactsList: [action.payload.contact, ...updatedList],
        contactPage: {
          ...state.contactPage,
          total: _.subtract(_.get(state, 'contactPage.total'), _.size(action.payload.ids)) + 1,
        },
      }

    case ADD_SOCIAL:
      return {
        ...state,
        singleContact: action.payload,
      }

    case UPDATE_SOCIAL:
      return {
        ...state,
        singleContact: action.payload,
      }
    case REMOVE_SOCIAL:
      return {
        ...state,
        singleContact: action.payload,
      }
    default:
      return state
  }
}
