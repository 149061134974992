import {
  GET_PRODUCTS_CATEGORY,
  GET_PRODUCTS_FIELD,
  GET_PRODUCTS_FIELDLIST,
  GET_FIELD_BY_CATEGORY,
  GET_PRODUCT_DATA,
  GET_PRODUCTS_LIST,
  EXPORT_ALL_PRODUCTS,
  EXPORT_SELECTED_PRODUCTS,
  EXPORT_SAMPLE_PRODUCTS_CSV,
  IMPORT_PRODUCTS,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_PRODUCTS_CATEGORY:
      return {
        ...state,
        categoryList: action.payload,
      }

    case GET_PRODUCTS_FIELD:
      return {
        ...state,
        fieldData: action.payload,
      }

    case 'CLEAR_PRODUCTS_FIELD':
      return {
        ...state,
        fieldData: {},
      }

    case GET_PRODUCTS_FIELDLIST:
      return {
        ...state,
        fieldList: action.payload,
      }

    case GET_FIELD_BY_CATEGORY:
      return {...state, fieldsByCategory: action.payload}

    case GET_PRODUCTS_LIST:
      return {
        ...state,
        productList: action.payload,
      }

    case GET_PRODUCT_DATA:
      return {
        ...state,
        productData: action.payload,
      }

    case 'CLEAR_PRODUCT_DATA':
      return {
        ...state,
        productData: {},
      }

    case EXPORT_ALL_PRODUCTS:
      return {
        ...state,
        exportData: action.payload,
      }

    case EXPORT_SELECTED_PRODUCTS:
      return {
        ...state,
        exportData: action.payload,
      }

    case EXPORT_SAMPLE_PRODUCTS_CSV:
      return {
        ...state,
        sampleData: action.payload,
      }

    case 'CLEAR_EXPORT_SAMPLE_PRODUCTS_CSV':
      return {
        ...state,
        sampleData: null,
      }

    case 'CLEAR_SELECTED_PRODUCTS':
      return {
        ...state,
        exportData: null,
      }
    case IMPORT_PRODUCTS:
      return {
        ...state,
        productImportData: action.payload,
      }

    default:
      return state
  }
}
