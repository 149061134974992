import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import InvoicesList from 'components/pages/Finance/Invoices/InvoicesList'
import PrefixList from 'components/pages/Finance/Invoices/settings/Prefix/PrefixList'

const routes = [
  {path: '/invoices/all', component: InvoicesList, title: 'All Invoices', exact: true},
  {path: '/invoices/prefix', component: PrefixList, title: 'Prefix'},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} invoices />
        <Component {...props} />
      </>
    )}
  />
)

const InvoicesRoutes = () => (
  <Switch>
    <Route exact path="/invoices">
      <Redirect to="/invoices/all" />
    </Route>
    {routes.map(renderRoute)}
    <Route path="/invoices/*">
      <Redirect to="/invoices/all" />
    </Route>
  </Switch>
)

export default InvoicesRoutes
