import {GET_AUTOMATIONS, GET_SINGLE_AUTOMATION} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case GET_AUTOMATIONS:
      return {...state, automationsList: payload}

    case GET_SINGLE_AUTOMATION:
      return {...state, singleAutomationData: payload}

    default:
      return state
  }
}
