/* eslint-disable no-unused-expressions */
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {vmoToast} from 'vmo-library'
import {get} from 'utils/helper'
import {AiOutlineCheckCircle} from 'react-icons/ai'
import {FiAlertTriangle} from 'react-icons/fi'

const withToast = Component => props => {
  const {apiData, successLabels = [], errorLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(get(['label'], apiData)) && get(['showToast'], apiData)) {
      if (
        get(['label'], apiData).includes('CREATE') ||
        get(['label'], apiData).startsWith('ADD') ||
        get(['label'], apiData).includes('DELETE')
      ) {
        vmoToast.success(
          <div className="toast-body">
            <AiOutlineCheckCircle />
            <span>{apiData.successMessage}</span>
          </div>
        )
      } else if (get(['label'], apiData).includes('UPDATE') || get(['label'], apiData).includes('EDIT')) {
        vmoToast.info(
          <div className="toast-body">
            <AiOutlineCheckCircle />
            <span>{apiData.successMessage}</span>
          </div>
        )
        // } else if (get(['label'], apiData).includes('DELETE')) {
        //   vmoToast.error(
        //     <div className="toast-body">
        //       <AiOutlineCheckCircle />
        //       <span>{apiData.successMessage}</span>
        //     </div>
        //   )
      } else {
        apiData.successMessage !== null &&
          vmoToast.info(
            <div className="toast-body">
              <AiOutlineCheckCircle />
              <span>{apiData.successMessage}</span>
            </div>
          )
      }
    }
    if (errorLabels.includes(get(['label'], apiData)) && get(['showToast'], apiData)) {
      vmoToast.error(
        <div className="toast-body">
          <FiAlertTriangle />
          <span>{apiData.errorMessage}</span>
        </div>
      )
    }
  }, [apiData, successLabels, errorLabels])

  return <Component {...props} />
}

export default withToast
