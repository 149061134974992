import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoDatePicker,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormTextArea,
  VmoHeader,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalDescription,
  VmoModalHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {
  CREATE_HOLIDAY,
  CREATE_HOLIDAY_GROUP,
  DEFAULT_GROUP,
  DELETE_HOLIDAY,
  DELETE_HOLIDAY_GROUP,
  GET_SINGLE_HOLIDAY,
  GET_SINGLE_HOLIDAY_GROUP,
  UPDATE_HOLIDAY,
  UPDATE_HOLIDAY_GROUP,
} from 'actions/types'
import {
  createHolidays,
  createHolidaysGroup,
  deleteHoliday,
  deleteHolidayGroup,
  getHolidaysGroup,
  getSingleHolidays,
  getSingleHolidaysGroup,
  updateHoliday,
  updateHolidayGroup,
  defaultGroup,
} from 'actions/holidays'
import SettingLayout from 'layouts/settingLayout'
import {getFormattedDate, removeDoubleQuotes, startCase} from 'utils/helper'
import DeleteModal from 'components/common/DeleteModal'
import {createHolidayGroupSchema, createHolidaySchema} from 'validation/Settings/myOrganisation/holidays.schema'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'

const Holidays = () => {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [issueDate, setIssueDate] = useState(null)
  const [holidayGroupId, setHolidayGroupId] = useState()
  const [holidayId, setHolidayId] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteModal1, setDeleteModal1] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {getHolidaysGroupData, getSingleHolidayData, getSingleHolidayGroupData} = useSelector(state => state.holidays)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const holidaysLearnMore = settingsLearnMoreUrl?.myOrganization?.holidays?.learnMore

  const initialCreateInfo = {
    name: '',
    description: '',
  }
  const initialCreateInfo1 = {
    name: '',
    date: null,
  }

  const {handleSubmit, errors, control, reset} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(createHolidayGroupSchema),
    defaultValues: initialCreateInfo,
  })

  const {
    handleSubmit: handleSubmit1,
    control: control1,
    errors: errors1,
    reset: reset1,
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: joiResolver(createHolidaySchema),
    defaultValues: initialCreateInfo1,
  })

  useEffect(() => {
    dispatch(getHolidaysGroup())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_HOLIDAY)) {
      setIssueDate(getSingleHolidayData.date)
      reset1({
        name: getSingleHolidayData.name,
        date: getSingleHolidayData.date,
      })
    }
    if (successLabels.includes(GET_SINGLE_HOLIDAY_GROUP)) {
      setIssueDate(getSingleHolidayGroupData.date)
      reset({
        name: getSingleHolidayGroupData.name,
        description: getSingleHolidayGroupData.description,
      })
    }
  }, [successLabels, getSingleHolidayData, getSingleHolidayGroupData, reset1, reset])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_HOLIDAY_GROUP) ||
      successLabels.includes(DELETE_HOLIDAY_GROUP) ||
      successLabels.includes(UPDATE_HOLIDAY_GROUP) ||
      successLabels.includes(DEFAULT_GROUP)
    ) {
      setOpen(false)
      dispatch(getHolidaysGroup())
    }
  }, [successLabels, dispatch])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_HOLIDAY) ||
      successLabels.includes(DELETE_HOLIDAY) ||
      successLabels.includes(UPDATE_HOLIDAY)
    ) {
      setEdit(false)
      dispatch(getHolidaysGroup())
    }
  }, [successLabels, dispatch])

  const submitForm = data => {
    if (holidayGroupId) {
      dispatch(updateHolidayGroup(holidayGroupId, data))
    } else {
      dispatch(createHolidaysGroup(data))
    }
  }

  const handleSave = data => {
    if (holidayId) {
      dispatch(updateHoliday(holidayId, data))
    } else {
      dispatch(createHolidays(data, holidayGroupId))
    }
  }

  const createGroupModal = () => {
    return (
      <VmoModal
        size="tiny"
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <VmoModalHeader>
          <span>{holidayGroupId ? 'Edit Group' : 'Create Group'}</span>
        </VmoModalHeader>
        <VmoModalContent>
          <VmoModalDescription>
            <VmoForm className="errorLabel">
              <VmoFormField required>
                <label>Name</label>
                <Controller
                  name="name"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      placeholder="Name"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.name && {
                          content: removeDoubleQuotes(errors.name.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField required>
                <label>Description</label>
                <Controller
                  name="description"
                  render={({onChange, value}) => (
                    <VmoFormTextArea
                      maxLength={200}
                      type="text"
                      value={value}
                      placeholder="Description"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.description && {
                          content: removeDoubleQuotes(errors.description.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoForm>
          </VmoModalDescription>
        </VmoModalContent>
        <VmoModalActions>
          <div className="set-action-btn">
            <VmoButton basic onClick={() => setOpen(false)}>
              Cancel
            </VmoButton>
            <VmoButton primary onClick={handleSubmit(submitForm)}>
              {holidayGroupId ? 'Save' : 'Create'}
            </VmoButton>
          </div>
        </VmoModalActions>
      </VmoModal>
    )
  }

  const editHolidayModal = () => {
    return (
      <VmoModal
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        size="tiny"
        onClose={() => setEdit(false)}
        onOpen={() => setEdit(true)}
        open={edit}
      >
        <VmoModalHeader>
          <span>Add Holiday</span>
        </VmoModalHeader>
        <VmoModalContent>
          <VmoModalDescription>
            <VmoForm className="errorLabel">
              <VmoFormField required>
                <label>Name</label>
                <Controller
                  name="name"
                  control={control1}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      maxLength={20}
                      placeholder="Name"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors1.name && {
                          content: removeDoubleQuotes(errors1.name.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField required>
                <label>Date</label>
                <Controller
                  name="date"
                  render={({onChange, value}) => (
                    <VmoDatePicker
                      type="date"
                      value={typeof value === 'string' ? new Date(issueDate) : issueDate}
                      inputProps={{readOnly: true}}
                      time={false}
                      onChange={value => {
                        onChange(value)
                        setIssueDate(value)
                      }}
                    />
                  )}
                  control={control1}
                />
                {errors1?.date && (
                  <p style={{color: '#9f3a38', fontSize: '11px'}}>{removeDoubleQuotes(errors1?.date?.message)}</p>
                )}
              </VmoFormField>
            </VmoForm>
          </VmoModalDescription>
        </VmoModalContent>
        <VmoModalActions>
          <div className="set-action-btn">
            <VmoButton basic onClick={() => setEdit(false)}>
              Cancel
            </VmoButton>
            <VmoButton primary onClick={handleSubmit1(data => handleSave(data))}>
              {holidayId ? 'Save' : 'Create'}
            </VmoButton>
          </div>
        </VmoModalActions>
      </VmoModal>
    )
  }

  return (
    <SettingLayout
      // icon={<SvgIcon path="settings/holidays" />}
      header="Holiday group list for 2023"
      subHeader="Configure multiple holiday group and click on each group to view all holidays"
      learnMoreUrl={holidaysLearnMore}
      headerButton={{
        onClick: () => {
          setOpen(true)
          setHolidayGroupId(null)
          reset(initialCreateInfo)
        },
        content: 'Add Group',
      }}
      table
      headerDivider
    >
      {getHolidaysGroupData && !getHolidaysGroupData.length
        ? null
        : getHolidaysGroupData?.map(({name, id, holidays, isDefault}) => (
            <VmoTable basic className="holidayTable" key={id}>
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell>
                    <div className="holidayGroupHeader">
                      <div>
                        <VmoHeader className="mb-0">{startCase(name)}</VmoHeader>
                        <VmoButton
                          // style={{size: '10px'}}
                          disabled={isDefault}
                          onClick={() => {
                            dispatch(defaultGroup(id))
                          }}
                        >
                          {isDefault ? 'Default' : 'Set As Default'}
                        </VmoButton>
                        <VmoTooltip
                          size="mini"
                          position="top center"
                          content="Edit"
                          trigger={
                            <SvgIcon
                              path="common/edit2"
                              onClick={() => {
                                setOpen(true)
                                setHolidayGroupId(id)
                                dispatch(getSingleHolidaysGroup(id))
                              }}
                            />
                          }
                        />
                        <VmoTooltip
                          size="mini"
                          position="top center"
                          content="Delete"
                          trigger={
                            <SvgIcon
                              path="common/delete"
                              onClick={e => {
                                e.stopPropagation()
                                setHolidayGroupId(id)
                                setDeleteModal(true)
                              }}
                            />
                          }
                        />
                      </div>
                      <div>
                        <VmoButton
                          onClick={() => {
                            setEdit(true)
                            setHolidayId(null)
                            setHolidayGroupId(id)
                            setIssueDate(null)
                            reset1(initialCreateInfo1)
                          }}
                          className="addHoliday"
                        >
                          <SvgIcon path="common/plus" /> Add New Holiday
                        </VmoButton>
                      </div>
                    </div>
                  </VmoTableHeaderCell>
                  <VmoTableHeaderCell>Date</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Action</VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <VmoTableBody>
                {holidays?.map(({name, id, date}) => (
                  <VmoTableRow
                    key={id}
                    className="tableLink "
                    onClick={() => {
                      setEdit(true)
                      setHolidayId(id)
                      dispatch(getSingleHolidays(id))
                    }}
                  >
                    <VmoTableCell>
                      <VmoHeader as="h5" style={{marginBottom: '0px'}} onClick={() => setEdit(true)}>
                        {startCase(name)}
                      </VmoHeader>
                    </VmoTableCell>
                    <VmoTableCell>{getFormattedDate(date)}</VmoTableCell>
                    <VmoTableCell>
                      <VmoTooltip
                        size="mini"
                        position="top center"
                        content="Delete"
                        trigger={
                          <VmoButton
                            basic
                            circular
                            icon
                            className="tableLink-delete-icon"
                            onClick={e => {
                              e.stopPropagation()
                              setHolidayId(id)
                              setDeleteModal1(true)
                            }}
                          >
                            <SvgIcon path="common/delete" />
                          </VmoButton>
                        }
                      />
                    </VmoTableCell>
                  </VmoTableRow>
                ))}
              </VmoTableBody>
            </VmoTable>
          ))}
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          deleteAction={deleteHolidayGroup}
          idTobeDeleted={holidayGroupId}
          type={DELETE_HOLIDAY_GROUP}
        />
      )}
      {deleteModal1 && (
        <DeleteModal
          isModalOpen={deleteModal1}
          setIsModalOpen={setDeleteModal1}
          modalText="Are you sure you want to delete ?"
          deleteAction={deleteHoliday}
          idTobeDeleted={holidayId}
          type={DELETE_HOLIDAY}
        />
      )}
      {open && createGroupModal()}
      {edit && editHolidayModal()}
    </SettingLayout>
  )
}

export default Holidays
