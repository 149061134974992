import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

const useApiLoader = ({label}) => {
  const [isLoading, setIsLoading] = useState(true)

  const {successLabels = [], errorLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(label)) {
      setIsLoading(false)
    }
  }, [successLabels, label])

  useEffect(() => {
    if (errorLabels.includes(label)) {
      setIsLoading(false)
    }
  }, [errorLabels, label])

  return {isLoading}
}

export default useApiLoader
