/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {getProjectsBudtesList} from 'actions/budgeting'
import {getUsers} from 'actions/settings'
import {GET_PROJECTS_BUDGETS_LIST} from 'actions/types'
import SearchComponent from 'components/common/SearchComponent'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import {get, getFormattedDate, startCase} from 'utils/helper'
import {
  VmoDropdown,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

const BudgetsList = () => {
  const dispatch = useDispatch()

  const history = useHistory()

  const [currentListParams, setCurrentListParams] = useState({
    text: '',
    managerId: 'all',
  })

  const {type = []} = useSelector(state => state.apiReducer)
  const {projectsBudgetsList = []} = useSelector(state => state.budgeting)
  const {getUsersList: {users = []} = {}} = useSelector(state => state.settings)

  useEffect(() => {
    const newList = _.omitBy(currentListParams, value => {
      if (value === 'none' || value === '' || value === 'all') {
        return true
      }
      return false
    })
    dispatch(getProjectsBudtesList(newList))
  }, [dispatch, currentListParams])

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_BUDGETS_LIST)) {
      return <VmoTablePlaceholder />
    }
    if (!type.includes(GET_PROJECTS_BUDGETS_LIST) && !projectsBudgetsList.length) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="5" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return projectsBudgetsList.map(budget => (
      <VmoTableRow className="tableLink">
        <VmoTableCell>
          <VmoHeader as="h5" onClick={() => history.push(`/projects/${budget.id}/finance/summary`)}>
            {startCase(budget.title)}
          </VmoHeader>
        </VmoTableCell>
        <VmoTableCell>{budget.managerName}</VmoTableCell>
        <VmoTableCell>
          {budget.isBudgetCreated ? (
            <div style={{width: '130px'}}>
              <h5 className="mb-1">{get(['budget', 'usedPercentage'], budget, 0).toFixed(2)}%</h5>
              <div className="budget-progress">
                <div
                  className="budget-progress-completed"
                  style={{width: `${get(['budget', 'usedPercentage'], budget, 0).toFixed(2)}%`}}
                />
              </div>
              <p className="mt-1">
                ${get(['budget', 'usedAmount'], budget, 0)} 0f ${get(['budget', 'budgetAmount'], budget, 0)}
              </p>
            </div>
          ) : (
            '-'
          )}
        </VmoTableCell>
        <VmoTableCell>
          {getFormattedDate(budget.budget?.periodStartDate)} - {getFormattedDate(budget.budget?.periodEndDate)}
        </VmoTableCell>
        <VmoTableCell>
          {budget.isBudgetCreated ? (
            <a
              onClick={() =>
                history.push({
                  pathname: `projects/${budget.id}/finance/rates`,
                  state: {
                    budgetId: budget.budget?.budgetId,
                  },
                })
              }
            >
              Set Rates
            </a>
          ) : (
            '-'
          )}
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="pageBodyWrap">
      <div className="pageHeader">
        <div className="pageTitle">
          <div className="pageTitleIcon">
            <h5>Budgets</h5>
          </div>
        </div>
        <div className="pageAction">
          <SearchComponent
            onSuccess={text => setCurrentListParams(currentListParams => ({...currentListParams, text}))}
          />
          <div className="mailActivity ml-3">
            <VmoTooltip
              content="Owner"
              size="mini"
              position="top center"
              trigger={
                <VmoDropdown
                  icon="chevron down"
                  button
                  direction="left"
                  defaultValue={currentListParams.managerId}
                  options={[
                    {key: 'all', value: 'all', text: 'All'},
                    ...users.map(user => ({key: user.id, value: user.id, text: `${user.firstName} ${user.lastName}`})),
                  ]}
                  onChange={(e, {value}) => setCurrentListParams({...currentListParams, managerId: value})}
                />
              }
            />
          </div>
        </div>
      </div>
      <VmoTable basic>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>Name</VmoTableHeaderCell>
            <VmoTableHeaderCell>Owner</VmoTableHeaderCell>
            <VmoTableHeaderCell>Budget</VmoTableHeaderCell>
            <VmoTableHeaderCell>Budget Dates</VmoTableHeaderCell>
            <VmoTableHeaderCell>Rates</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>{renderTableBody()}</VmoTableBody>
      </VmoTable>
    </div>
  )
}

export default BudgetsList
