import {
  TOTAL_PURCHASE_ORDERS,
  PURCHASE_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS,
  PURCHASE_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS,
  NUMBER_OF_PURCHASE_ORDER_BY_USERS,
  AMOUNT_OF_PURCHASE_ORDER_BY_USERS,
  PURCHASE_ORDER_COUNT_OVERTIME,
  PURCHASE_ORDER_AMOUNT_OVERTIME,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case TOTAL_PURCHASE_ORDERS:
      return {
        ...state,
        totalPurchaseOrdersData: action.payload,
      }

    case PURCHASE_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS:
      return {
        ...state,
        numberPurchaseOrdersData: action.payload,
      }

    case PURCHASE_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS:
      return {
        ...state,
        amountPurchaseOrdersData: action.payload,
      }

    case NUMBER_OF_PURCHASE_ORDER_BY_USERS:
      return {
        ...state,
        purchaseOrderNumberByUserData: action.payload,
      }

    case AMOUNT_OF_PURCHASE_ORDER_BY_USERS:
      return {
        ...state,
        purchaseOrderAmountByUserData: action.payload,
      }

    case PURCHASE_ORDER_COUNT_OVERTIME:
      return {
        ...state,
        purchaseOrderCountOvertimeData: action.payload,
      }

    case PURCHASE_ORDER_AMOUNT_OVERTIME:
      return {
        ...state,
        purchaseOrderAmountOvertimeData: action.payload,
      }

    default:
      return state
  }
}
