import {KADENCE_SETTING, KADENCE_ATTACHMENTS} from './endpoint'
import {ATTACHMENT_POST} from './types'
import {apiAction} from './api'

export function uploadAttachments(data, successData, cancelToken) {
  return apiAction({
    url: KADENCE_ATTACHMENTS,
    method: 'POST',
    label: ATTACHMENT_POST,
    data,

    contentType: 'multipart/form-data',
    successData,
    cancelToken,
  })
}

export function uploadTemplateAttachments(data, successData) {
  return apiAction({
    url: `${KADENCE_SETTING}/automation/templates/attachments`,
    method: 'POST',
    label: ATTACHMENT_POST,
    data,

    contentType: 'multipart/form-data',
    successData,
  })
}
