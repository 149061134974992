import {COMPANIES_STATS, COMPANIES_BY_STATUS} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case COMPANIES_STATS:
      return {
        ...state,
        companiesStatsData: action.payload,
      }

    case COMPANIES_BY_STATUS:
      return {
        ...state,
        companiesByStatusData: action.payload,
      }

    default:
      return state
  }
}
