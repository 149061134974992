/* eslint-disable no-nested-ternary */
import {
  moveConversationTo,
  permanentDeleteConversation,
  snoozeConversation,
  updateConversation,
  updateConversationAndRefresh,
  updateConversationPriority,
  updateConversationStatus,
} from 'actions/inbox'
import _ from 'lodash'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {MdSettingsBackupRestore} from 'react-icons/md'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoChipInput,
  VmoDivider,
  VmoDropdown,
  VmoDropdownHeader,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoIcon,
  VmoLabel,
  VmoRating,
  VmoTooltip,
} from 'vmo-library'
import CreateLabel from 'components/common/CreateLabel'
import {createTagSettings} from 'actions/multiple_fields'
import KadenceCustomDropdown from 'components/common/KadenceCustomDropdown'
import {getTags} from 'actions/settings_productivity'
import ReactToPrint from 'react-to-print'
import {get, getPermissionTooltipContent, startCase} from 'utils/helper'
import {priorityList} from 'constants/variables'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import PermissionIcon from 'components/common/PermissionIcon'
import {submitTimeTrackingManual} from 'actions/time_tracking'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {GET_TAGS} from 'actions/types'
import EmailDeleteModal from './EmailDeleteModal'
import OverdueModal from './OverdueModal'
import MergeConversationModal from './MergeConversationModal'
import SnoozeConversationModal from './SnoozeConversationModal'

function EmailBodyHeader(props) {
  const {
    ticketNo,
    conversations,
    conversation,
    ConversationId,
    CurrentStatusOfConversation,
    isSnoozed,
    mailboxes,
    inboxId,
    CurrentReadStatusOfConversation,
    isTrashedConversation,
    emailBodyContentRef,
    CurrentSpamStatusOfConversation,
    CurrentPriorityOfConversation,
    CurrentAssigneeOfConversation,
    currentTeamOfConversation,
    assigneeOptions,
    conversationSubject,
    currentLabelsOfConversation,
    customerProfile,
    setCustomerProfile,
    conversationType,
    contact,
    timeTrackingData,
    timeTrackingCounterStatus,
    setTimeTrackingCounterStatus,
    timeTrackingTimer,
    setTimeTrackingTimer,
  } = props

  const dispatch = useDispatch()

  const [activeAssigneeType, setActiveAssigneeType] = useState('assignees')
  const [assigneeValue, setAssigneeValue] = useState('Unassign')
  const [timePicker, setTimePicker] = useState(false)
  const [snoozeDatePickerTime, setSnoozeDatePickerTime] = useState(new Date())
  const [isMergeModalOpen, setMergeModalOpen] = useState(false)
  const [overDue, setOverDue] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [labelOptionsState, setLabelOptionsState] = useState([])
  const [listOfLable, setListOfLabel] = useState([])
  const [newLabelModal, setNewLabelModal] = useState(false)

  const {statusList = []} = useSelector(state => state.emailInbox, _.isEqual)

  const {clientManagementAccess, conversationsPermissions} = useUserPermissions()
  const mergeAndSplitAccess = clientManagementAccess?.mergeAndSplit
  const manageConversationPermissions = conversationsPermissions?.manage_conversation
  const mergeConversationPermissions = conversationsPermissions?.merge_conversation
  const deleteConversationPermissions = conversationsPermissions?.delete_conversation
  const moveConversationPermissions = conversationsPermissions?.move_conversation

  const getDefaultAssignee = () => {
    if (CurrentAssigneeOfConversation !== null) {
      setActiveAssigneeType('assignees')
      setAssigneeValue(CurrentAssigneeOfConversation.firstName)
    } else if (currentTeamOfConversation !== null) {
      setActiveAssigneeType('teams')
      setAssigneeValue(currentTeamOfConversation?.name)
    } else {
      setActiveAssigneeType('assignees')
      setAssigneeValue('Unassign')
    }
  }

  useEffect(() => {
    getDefaultAssignee()
  }, [CurrentAssigneeOfConversation, currentTeamOfConversation])

  useApiResponse({
    label: GET_TAGS,
    storePath: 'settingsProductivity.tagList',
    onSuccess: tagList => {
      const list = tagList?.map(({id, label, color}) => ({
        key: id,
        value: id,
        text: label,
        label: {
          style: {background: color},
          empty: true,
          circular: true,
        },
      }))
      setLabelOptionsState(list || [])
    },
  })

  useEffect(() => {
    const formated = (currentLabelsOfConversation || []).map(label => {
      return {
        value: label.id,
        text: label.label,
        color: label?.color,
        description: label.description,
      }
    })
    setListOfLabel(formated)
  }, [currentLabelsOfConversation])

  const onConversationStatusChange = statusTo => {
    const data = {
      statusId: statusTo,
      conversationIds: ConversationId,
    }
    dispatch(updateConversationStatus(data, 'Status'))
  }

  const unSnoozeMail = () => {
    const data = {
      ts: null,
      isSnoozed: false,
    }
    dispatch(snoozeConversation(data, ConversationId))
  }

  const snoozeMail = time => {
    const data = {
      ts: time,
      isSnoozed: true,
    }
    dispatch(snoozeConversation(data, ConversationId))
  }

  const markAsRead = val => {
    const data = {
      conversationIds: ConversationId,
      isRead: val,
    }
    dispatch(updateConversation(data, 'Read'))
  }

  const markAsSpam = val => {
    const conversationIds = []
    conversationIds.push(ConversationId)
    const data = {
      conversationIds,
      isSpam: val,
    }
    dispatch(updateConversationAndRefresh(data, 'Spam Updated'))
  }

  const getStatusId = status => {
    return statusList.find(stat => stat.status === status).id
  }

  const renderListOfMailInbox = () => {
    const finalListOfInbox = mailboxes || []

    const listOfInboxExceptOne = finalListOfInbox.filter(inbox => inbox.id !== inboxId)

    const listOfInboxSameType = listOfInboxExceptOne.filter(inbox => {
      if (conversationType === 'custom_sms' || conversationType === 'twilio' || conversationType === 'sms') {
        return inbox.type === 'custom_sms' || inbox.type === 'twilio' || inbox.type === 'sms'
      }
      if (conversationType === 'email' || conversationType === 'helpdesk') {
        return inbox.type === 'email'
      }
      return inbox.type === conversationType
    })

    return listOfInboxSameType.map(inbox => (
      <VmoDropdownItem
        key={inbox.id}
        onClick={() => {
          if (!moveConversationPermissions) return
          const data = {
            conversationIds: [ConversationId],
            inboxId: inbox.id,
          }
          dispatch(moveConversationTo(data))
        }}
        disabled={!moveConversationPermissions || !inbox.emailVerified}
      >
        {inbox.name}
        {!inbox.emailVerified && <small className="ml-2 kadence-lead">- not verified</small>}
      </VmoDropdownItem>
    ))
  }

  const renderConversationOptions = () => {
    return (
      <>
        {get(['isTrashed'], conversation) && (
          <VmoTooltip
            trigger={
              <VmoButton
                icon
                className="m-0"
                onClick={() => {
                  if (!manageConversationPermissions) return

                  const conversationIds = []
                  conversationIds.push(ConversationId)
                  const data = {
                    conversationIds,
                    isTrashed: false,
                  }
                  dispatch(updateConversationAndRefresh(data, 'Conversation Restored'))
                }}
              >
                <MdSettingsBackupRestore />
              </VmoButton>
            }
            size="mini"
            position="top center"
            content={
              manageConversationPermissions
                ? 'Restore Conversation'
                : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
            }
          />
        )}
        <VmoTooltip
          size="mini"
          content={
            manageConversationPermissions
              ? get(['status'], CurrentStatusOfConversation, 'open') !== 'closed'
                ? 'Mark as Close'
                : 'Mark as Open'
              : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
          }
          position="top center"
          trigger={
            <VmoButton
              className="m-0"
              icon={
                <SvgIcon
                  path="common/ckeck"
                  style={
                    get(['status'], CurrentStatusOfConversation, 'open') !== 'closed'
                      ? {color: '#616fc7'}
                      : {color: 'grey'}
                  }
                />
              }
              primary={get(['status'], CurrentStatusOfConversation, 'open') === 'closed'}
              onClick={() =>
                manageConversationPermissions &&
                onConversationStatusChange(
                  get(['status'], CurrentStatusOfConversation, 'open') !== 'closed'
                    ? getStatusId('Closed')
                    : getStatusId('Open')
                )
              }
              size="mini"
              position="top center"
            />
          }
        />
        <VmoTooltip
          size="mini"
          content={
            manageConversationPermissions
              ? conversation.isStarred
                ? 'Remove Favorite'
                : 'Add as Favorite'
              : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
          }
          position="top center"
          trigger={
            <VmoRating
              className="icon button m-0"
              icon="star"
              rating={conversation.isStarred ? 1 : 0}
              size="large"
              onClick={() =>
                manageConversationPermissions &&
                dispatch(
                  updateConversation(
                    {
                      isStarred: !conversation.isStarred,
                      conversationIds: ConversationId,
                    },
                    'Favourite'
                  )
                )
              }
            />
          }
        />
        {isSnoozed !== null ? (
          <VmoTooltip
            size="mini"
            content={
              manageConversationPermissions
                ? moment(isSnoozed).endOf().fromNow()
                : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
            }
            position="top center"
            trigger={
              <VmoDropdown
                className="active icon button m-0"
                direction="right"
                icon={
                  <SvgIcon
                    path="inbox/inbox-dashboard/snooze"
                    id="TooltipSnooze"
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (manageConversationPermissions) {
                        unSnoozeMail()
                      }
                    }}
                  />
                }
              />
            }
          />
        ) : (
          <VmoTooltip
            size="mini"
            content={
              manageConversationPermissions
                ? 'Snooze'
                : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
            }
            position="top center"
            trigger={
              <VmoDropdown
                className="icon button m-0"
                direction="right"
                icon={<SvgIcon path="inbox/inbox-dashboard/snooze" id="TooltipSnooze" />}
              >
                <VmoDropdownMenu>
                  <VmoDropdownItem
                    disabled={!manageConversationPermissions}
                    onClick={() => {
                      if (!manageConversationPermissions) return
                      const time = new Date()
                      time.setHours(time.getHours() + 1)
                      const date = new Date(time)
                      snoozeMail(date)
                    }}
                  >
                    1 hour
                  </VmoDropdownItem>
                  <VmoDropdownItem
                    disabled={!manageConversationPermissions}
                    onClick={() => {
                      if (!manageConversationPermissions) return
                      const time = new Date()
                      time.setHours(time.getHours() + 2)
                      const date = new Date(time)
                      snoozeMail(date)
                    }}
                  >
                    2 hours
                  </VmoDropdownItem>
                  <VmoDropdownItem
                    disabled={!manageConversationPermissions}
                    onClick={() => {
                      if (!manageConversationPermissions) return
                      const time = new Date()
                      time.setHours(time.getHours() + 4)
                      const date = new Date(time)
                      snoozeMail(date)
                    }}
                  >
                    4 hours
                  </VmoDropdownItem>
                  <VmoDropdownItem
                    disabled={!manageConversationPermissions}
                    onClick={() => {
                      if (!manageConversationPermissions) return
                      const time = new Date()
                      time.setHours(time.getHours() + 8)
                      const date = new Date(time)
                      snoozeMail(date)
                    }}
                  >
                    8 hours
                  </VmoDropdownItem>
                  <VmoDropdownItem
                    disabled={!manageConversationPermissions}
                    onClick={() => {
                      if (!manageConversationPermissions) return
                      setTimePicker(true)
                      setSnoozeDatePickerTime(new Date())
                    }}
                  >
                    Pick Date and Time
                  </VmoDropdownItem>
                </VmoDropdownMenu>
              </VmoDropdown>
            }
          />
        )}
        <VmoTooltip
          size="mini"
          content={
            moveConversationPermissions
              ? 'Move To'
              : getPermissionTooltipContent({roleAccess: moveConversationPermissions})
          }
          position="top center"
          trigger={
            <VmoDropdown className="icon button m-0" icon={<SvgIcon path="common/folder" id="TooltipFolderInbox" />}>
              <VmoDropdownMenu>
                <VmoDropdownHeader>Move to inbox</VmoDropdownHeader>
                {renderListOfMailInbox()}
              </VmoDropdownMenu>
            </VmoDropdown>
          }
        />
        <KadenceCustomDropdown
          options={labelOptionsState}
          multiple
          dropdownProps={{
            onClick: () => dispatch(getTags()),
            icon: (
              <VmoTooltip
                content={
                  manageConversationPermissions
                    ? 'Labels'
                    : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
                }
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/tag" id="TooltipTagInbox" />}
              />
            ),
            closeOnChange: false,
            className: 'm-0 tag-dropdown icon button',
          }}
          menu={{scrolling: true}}
          input={{
            icon: 'search',
            iconPosition: 'left',
            className: 'search',
          }}
          activeIndicator
          item={{disabled: !manageConversationPermissions}}
          value={listOfLable.map(labels => labels.value)}
          onClick={value => {
            if (!manageConversationPermissions) return
            dispatch(
              updateConversation({
                conversationIds: ConversationId,
                labels: value,
              })
            )
          }}
          extraAction={{
            content: '+ Add Tag',
            onClick: () => manageConversationPermissions && setNewLabelModal(true),
            disabled: !manageConversationPermissions,
          }}
        />
        <VmoTooltip
          content="More"
          size="mini"
          position="top center"
          trigger={
            <VmoDropdown className="icon button m-0" icon={<SvgIcon path="common/more-verticle" id="TooltipMore" />}>
              <VmoDropdownMenu>
                {!CurrentReadStatusOfConversation ? (
                  <VmoDropdownItem onClick={() => manageConversationPermissions && markAsRead(true)}>
                    <SvgIcon path="inbox/inbox-dashboard/mark-as" id="TooltipMore" />
                    Mark as a Read
                    {!manageConversationPermissions && (
                      <span style={{marginLeft: 'auto'}}>
                        <PermissionIcon isRoleAccessDenied={!manageConversationPermissions} />
                      </span>
                    )}
                  </VmoDropdownItem>
                ) : (
                  <VmoDropdownItem onClick={() => manageConversationPermissions && markAsRead(false)}>
                    <SvgIcon path="inbox/inbox-dashboard/mark-unread" id="TooltipMore" />
                    Mark as a Unread
                    {!manageConversationPermissions && (
                      <span style={{marginLeft: 'auto'}}>
                        <PermissionIcon isRoleAccessDenied={!manageConversationPermissions} />
                      </span>
                    )}
                  </VmoDropdownItem>
                )}
                {conversationType !== 'web' && (
                  <VmoDropdownItem
                    onClick={() =>
                      mergeAndSplitAccess && mergeConversationPermissions && setMergeModalOpen(!isMergeModalOpen)
                    }
                  >
                    <SvgIcon path="inbox/inbox-dashboard/merge-mails" id="TooltipMore" /> Merge Mails
                    {(!mergeAndSplitAccess || !mergeConversationPermissions) && (
                      <span style={{marginLeft: 'auto'}}>
                        <PermissionIcon
                          isFeatureAccessDenied={!mergeAndSplitAccess}
                          isRoleAccessDenied={!mergeConversationPermissions}
                        />
                      </span>
                    )}
                  </VmoDropdownItem>
                )}
                <VmoDropdownItem onClick={() => manageConversationPermissions && setOverDue(!overDue)}>
                  <SvgIcon path="inbox/inbox-dashboard/overdue" /> Overdue
                  {!manageConversationPermissions && (
                    <span style={{marginLeft: 'auto'}}>
                      <PermissionIcon isRoleAccessDenied={!manageConversationPermissions} />
                    </span>
                  )}
                </VmoDropdownItem>
                <VmoDropdownItem
                  onClick={() => deleteConversationPermissions && setIsDeleteModalOpen(!isDeleteModalOpen)}
                >
                  <SvgIcon path="common/delete" id="TooltipMore" /> Delete
                  {!deleteConversationPermissions && (
                    <span style={{marginLeft: 'auto'}}>
                      <PermissionIcon isRoleAccessDenied={!deleteConversationPermissions} />
                    </span>
                  )}
                </VmoDropdownItem>
                <VmoDivider />
                <ReactToPrint
                  trigger={() => (
                    <VmoDropdownItem>
                      <SvgIcon path="inbox/inbox-dashboard/print" id="TooltipMore" /> Print
                    </VmoDropdownItem>
                  )}
                  content={() => emailBodyContentRef.current}
                />

                {!CurrentSpamStatusOfConversation ? (
                  <VmoDropdownItem onClick={() => manageConversationPermissions && markAsSpam(true)}>
                    <SvgIcon path="inbox/inbox-dashboard/report-spam" id="TooltipMore" /> Report as Spam
                    {!manageConversationPermissions && (
                      <span style={{marginLeft: 'auto'}}>
                        <PermissionIcon isRoleAccessDenied={!manageConversationPermissions} />
                      </span>
                    )}
                  </VmoDropdownItem>
                ) : (
                  <VmoDropdownItem onClick={() => manageConversationPermissions && markAsSpam(false)}>
                    <SvgIcon path="inbox/inbox-dashboard/report-spam" id="TooltipMore" /> Report not Spam
                    {!manageConversationPermissions && (
                      <span style={{marginLeft: 'auto'}}>
                        <PermissionIcon isRoleAccessDenied={!manageConversationPermissions} />
                      </span>
                    )}
                  </VmoDropdownItem>
                )}
              </VmoDropdownMenu>
            </VmoDropdown>
          }
        />
      </>
    )
  }

  const onDeleteConversation = () => {
    const conversationIds = []
    conversationIds.push(ConversationId)

    if (isTrashedConversation === true) {
      const data = {
        conversationIds,
      }
      dispatch(permanentDeleteConversation(data))
      setIsDeleteModalOpen(false)
    } else {
      const data = {
        conversationIds,
        isTrashed: true,
      }
      dispatch(updateConversationAndRefresh(data, 'Moved to Trash'))
      setIsDeleteModalOpen(false)
    }
  }

  const getFromValue = () => {
    if (conversationType === 'sms') {
      return `${get(['firstName'], contact, '')} ${get(['lastName'], contact, '')}`
    }
    return get(['pageVisits', '0'], contact, null) ? (
      <a href={contact.pageVisits[contact.pageVisits.length - 1].pageUrl} target="_blank" rel="noopener noreferrer">
        {contact.pageVisits[contact.pageVisits.length - 1].pageUrl}
      </a>
    ) : (
      '--'
    )
  }

  return (
    <>
      <div className="headerBar">
        {ticketNo && (
          <div className="actBtn">
            {renderConversationOptions()}
            {timeTrackingData.isTimeTracking && (
              <div className="timeTrack">
                <VmoButton
                  onClick={() => {
                    if (!manageConversationPermissions) return
                    setTimeTrackingCounterStatus(timeTrackingCounterStatus === 'running' ? 'paused' : 'running')
                    if (!timeTrackingData.isAutoForConversation)
                      dispatch(
                        submitTimeTrackingManual({
                          conversationId: ConversationId,
                          status: timeTrackingCounterStatus === 'running' ? 'paused' : 'running',
                        })
                      )
                  }}
                >
                  {timeTrackingCounterStatus === 'running' ? (
                    <VmoTooltip
                      size="mini"
                      content={
                        manageConversationPermissions
                          ? 'Stop Time Tracking'
                          : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
                      }
                      position="top center"
                      trigger={<VmoIcon name="pause" />}
                    />
                  ) : (
                    <VmoTooltip
                      size="mini"
                      content={
                        manageConversationPermissions
                          ? 'Start Time Tracking'
                          : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
                      }
                      position="top center"
                      trigger={<VmoIcon name="play" />}
                    />
                  )}
                </VmoButton>
                {`${moment('2000-01-01 00:00:00').add(moment.duration(timeTrackingTimer)).format('HH:mm:ss')}`}
                <VmoButton
                  onClick={() => {
                    if (!manageConversationPermissions) return
                    if (timeTrackingData.isAutoForConversation) {
                      setTimeTrackingCounterStatus('running')
                    } else {
                      setTimeTrackingCounterStatus('completed')
                    }
                    dispatch(
                      submitTimeTrackingManual({
                        conversationId: ConversationId,
                        status: 'completed',
                        duration: 0,
                      })
                    )
                    setTimeTrackingTimer(0)
                  }}
                >
                  <VmoTooltip
                    size="mini"
                    content={
                      manageConversationPermissions
                        ? 'Reset Time Tracking'
                        : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
                    }
                    position="top center"
                    trigger={<VmoIcon name="redo" />}
                  />
                </VmoButton>
              </div>
            )}
          </div>
        )}
        {ticketNo && (
          <div className="mailActivity">
            <VmoTooltip
              content={
                manageConversationPermissions
                  ? 'Status'
                  : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
              }
              position="top center"
              size="mini"
              trigger={
                <VmoDropdown
                  button
                  icon="chevron down"
                  className="inboxtour__step-three"
                  options={statusList.map(status => ({
                    key: status.id,
                    value: status.id,
                    text: startCase(status.status),
                    disabled: !manageConversationPermissions,
                  }))}
                  header="Status"
                  key={get(['id'], CurrentStatusOfConversation)}
                  defaultValue={get(['id'], CurrentStatusOfConversation)}
                  onChange={(e, {value}) => manageConversationPermissions && onConversationStatusChange(value)}
                />
              }
            />
            <VmoTooltip
              content={
                manageConversationPermissions
                  ? 'Priority'
                  : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
              }
              position="top center"
              size="mini"
              trigger={
                <VmoDropdown
                  button
                  icon="chevron down"
                  value={CurrentPriorityOfConversation}
                  options={priorityList.map(priority => ({
                    key: priority.key,
                    value: priority.value,
                    text: startCase(priority.text),
                    disabled: !manageConversationPermissions,
                  }))}
                  header="Priority"
                  onChange={(e, value) => {
                    if (!manageConversationPermissions) return
                    const data = {
                      priority: value.value,
                      conversationIds: ConversationId,
                    }
                    dispatch(updateConversationPriority(data, 'Priority'))
                  }}
                />
              }
            />
            <VmoTooltip
              content={
                manageConversationPermissions
                  ? 'Assignee'
                  : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
              }
              position="top center"
              size="mini"
              trigger={
                <VmoDropdown
                  button
                  icon="chevron down"
                  text={assigneeValue}
                  className="assignee-dropdown inboxtour__step-two"
                  onBlur={() => getDefaultAssignee()}
                >
                  <VmoDropdownMenu>
                    <h5>Conversation assign to :</h5>
                    <div className="action">
                      <VmoButton
                        className={activeAssigneeType === 'assignees' && 'active'}
                        onClick={e => {
                          e.stopPropagation()
                          setActiveAssigneeType('assignees')
                        }}
                      >
                        <VmoIcon name="user" />
                        Users
                      </VmoButton>
                      <VmoButton
                        className={activeAssigneeType === 'teams' && 'active'}
                        onClick={e => {
                          e.stopPropagation()
                          setActiveAssigneeType('teams')
                        }}
                      >
                        <VmoIcon name="users" />
                        Teams
                      </VmoButton>
                    </div>
                    {assigneeOptions[activeAssigneeType]?.length > 0 ? (
                      assigneeOptions[activeAssigneeType]?.map(({value, text}) => (
                        <VmoDropdownItem
                          disabled={text === 'Teams' || !manageConversationPermissions}
                          className={`${activeAssigneeType === 'teams' ? '' : assigneeValue === text ? 'active' : ''}`}
                          onClick={() => {
                            if (!manageConversationPermissions) return
                            if (value === 'unassign') {
                              return dispatch(
                                updateConversation(
                                  {
                                    unassign: true,
                                    conversationIds: ConversationId,
                                  },
                                  'Assignee'
                                )
                              )
                            }

                            let data = {}
                            if (value?.isTeam) {
                              data = {
                                conversationIds: ConversationId,
                                teamId: value.value,
                              }
                            } else {
                              data = {
                                conversationIds: ConversationId,
                                assigneeId: value,
                              }
                            }

                            dispatch(updateConversation(data, 'Assignee'))
                          }}
                        >
                          {value === 'unassign' ? (
                            <VmoIcon name="ban" />
                          ) : value?.isTeam ? (
                            <VmoIcon name="users" />
                          ) : text === 'Teams' ? null : (
                            <VmoIcon name="user" />
                          )}{' '}
                          {text === 'Teams' ? <p>No Teams Available</p> : text}
                        </VmoDropdownItem>
                      ))
                    ) : (
                      <VmoDropdownItem disabled>No Team Available</VmoDropdownItem>
                    )}
                  </VmoDropdownMenu>
                </VmoDropdown>
              }
            />
          </div>
        )}
      </div>
      <div className="SubjectBar">
        <div className="subject">
          {conversationType !== 'web' && conversationType !== 'sms' ? (
            <h5>
              {ticketNo && <VmoLabel horizontal>#{ticketNo}</VmoLabel>}
              {conversationSubject}
            </h5>
          ) : (
            <div className="livepage">
              <VmoLabel horizontal>#{ticketNo}</VmoLabel>
              <label>From : </label>
              {getFromValue()}
            </div>
          )}
          <div className="d-flex">
            <VmoChipInput
              labelProps={{
                basic: true,
                as: 'a',
                className: 'vmo-tag',
              }}
              className="subtag mr-2"
              defaultValue={listOfLable.map(tag => {
                return {
                  id: tag.value,
                  text: <>{tag?.text}</>,
                  color: tag?.color,
                }
              })}
              onChange={value => {
                if (!manageConversationPermissions) return
                const valueIds = value.map(tag => tag.id)

                dispatch(
                  updateConversation({
                    conversationIds: ConversationId,
                    labels: valueIds,
                  })
                )
              }}
              hideInputField
            />
            <VmoTooltip
              size="mini"
              content={`${get(['contact', 'firstName'], conversation, 'not available')}`}
              position="left center"
              trigger={
                <VmoButton
                  className="inboxtour__step-four"
                  onClick={() => {
                    setCustomerProfile(!customerProfile)
                    window.localStorage.setItem('sidebarToggle', !customerProfile)
                  }}
                  disabled={!get(['contact', 'id'], conversation)}
                >
                  <SvgIcon path="inbox/contactProfile" profile={get(['contact'], conversation)} />
                </VmoButton>
              }
            />
          </div>
        </div>
      </div>

      {timePicker && (
        <SnoozeConversationModal
          timePicker={timePicker}
          setTimePicker={setTimePicker}
          snoozeDatePickerTime={snoozeDatePickerTime}
          setSnoozeDatePickerTime={setSnoozeDatePickerTime}
          ConversationId={ConversationId}
        />
      )}

      {isMergeModalOpen && (
        <MergeConversationModal
          isMergeModalOpen={isMergeModalOpen}
          setMergeModalOpen={setMergeModalOpen}
          conversationSubject={conversationSubject}
          ConversationId={ConversationId}
          conversations={conversations}
          conversation={conversation}
        />
      )}

      {overDue && (
        <OverdueModal
          isOpen={overDue}
          setIsOpen={setOverDue}
          onSuccess={value => {
            const data = {
              conversationIds: ConversationId,
              dueDate: value,
            }
            dispatch(updateConversation(data, 'Overdue'))
          }}
        />
      )}

      {isDeleteModalOpen && (
        <EmailDeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDeleteConversation={onDeleteConversation}
          isTrashedConversation={isTrashedConversation}
        />
      )}

      {newLabelModal && (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
          conversationIds={[ConversationId]}
        />
      )}
    </>
  )
}

export default EmailBodyHeader
