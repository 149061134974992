import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {VmoButton, VmoIcon, VmoImage, VmoTooltip} from 'vmo-library'
import {deletePaymentMethods, updatePaymentMethods} from 'actions/billing'
import visa from 'assets/visa.png'
import SvgIcon from 'components/common/SvgIcon'

const SavedCardList = () => {
  const dispatch = useDispatch()

  const {getPaymentMethodsData = []} = useSelector(state => state.billing)

  return (
    <div className="saved-cards">
      {getPaymentMethodsData.map(card => (
        <div className={`card-info ${card.isDefault ? 'active' : ''}`}>
          <VmoIcon name="check square" />
          <p>
            <VmoImage src={visa} /> {card.cardHolderName} {card.id}
          </p>
          <span>xxxx xxxx xxxx {card.last4}</span>
          {!card.isDefault && (
            <>
              <VmoButton
                primary
                className="basic-fill-btn"
                style={{width: 'fit-content', whiteSpace: 'nowrap'}}
                onClick={() => dispatch(updatePaymentMethods(card.id))}
              >
                Make Default
              </VmoButton>
              <VmoTooltip
                content="Delete"
                size="mini"
                position="top center"
                trigger={
                  <VmoButton basic onClick={() => dispatch(deletePaymentMethods(card.id))}>
                    <SvgIcon path="common/delete" />
                  </VmoButton>
                }
              />
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default SavedCardList
