import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import {useParams} from 'react-router'
import {
  VmoContainer,
  VmoCard,
  VmoCardContent,
  VmoForm,
  VmoFormSelect,
  VmoFormGroup,
  VmoFormField,
  VmoTooltip,
  VmoFormInput,
  VmoDatePicker,
  VmoGridColumn,
  VmoFormTextArea,
} from 'vmo-library'
import {
  GET_USERS,
  GET_INVOICES_PREFIX,
  GET_PURCHASE_OREDRS_LIST,
  GET_ALL_CONTACTS_IDS,
  GET_SALES_ORDERS_LIST,
} from 'actions/types'
import {getPurchaseOrderslist} from 'actions/PurchaseOrders'
import {getSalesOrderslist} from 'actions/salesOrders'
import {getAllContactsIds} from 'actions/contacts'

import {getNewInvoicesNumber, getInvoicesPrefixes} from 'actions/invoices'
import {removeDoubleQuotes, KadenceCountryList} from 'utils/helper'
import {Status} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'
import PreviewInvoices from '../Templates/PreviewInvoices'

function Setup(props) {
  const {style, errors, control, watch, setValue, InvoiceDate, setInvoiceDate} = props
  const dispatch = useDispatch()
  const {id: SalesOrdersId} = useParams()

  const [userOption, setUserOption] = useState([])
  const [purchaseOrderNumberList, setPurchaseOrderNumberList] = useState()
  const [salesOrderNumberList, setSalesOrderNumberList] = useState()
  const [prefixOptions, setPrefixOptions] = useState([])
  const [contactOption, setcontactOption] = useState([])

  const {
    InvoicesForm: {invoicesNumber},
  } = useSelector(state => state.invoices)

  const watchPrefixId = watch('prefixId')
  const watchAll = watch()

  useEffect(() => {
    if (!SalesOrdersId && !invoicesNumber && watchPrefixId) {
      dispatch(getNewInvoicesNumber(watchPrefixId))
    }
  }, [watchPrefixId, SalesOrdersId, invoicesNumber, dispatch])

  useEffect(() => {
    if (invoicesNumber) {
      setValue('invoiceNumber', invoicesNumber, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }
  }, [invoicesNumber, setValue])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list)
    },
  })

  useApiResponse({
    action: getInvoicesPrefixes,
    enabled: true,
    label: GET_INVOICES_PREFIX,
    storePath: 'invoices.invoicesPrefixList',
    onSuccess: invoicesPrefixList => {
      const prefixes = invoicesPrefixList?.map(({id, prefix}) => ({
        key: id,
        value: id,
        text: prefix,
      }))
      setPrefixOptions(prefixes)
    },
  })

  useApiResponse({
    action: getPurchaseOrderslist,
    enabled: true,
    label: GET_PURCHASE_OREDRS_LIST,
    storePath: 'purchaseOrders.purchaseOrdersList',
    onSuccess: purchaseOrdersList => {
      const poNumber = purchaseOrdersList?.map(({poNumber}) => ({
        key: poNumber,
        value: poNumber,
        text: poNumber,
      }))
      setPurchaseOrderNumberList(poNumber)
    },
  })

  useApiResponse({
    action: getSalesOrderslist,
    enabled: true,
    label: GET_SALES_ORDERS_LIST,
    storePath: 'salesOrders.salesOrdersList',
    onSuccess: salesOrdersList => {
      const soNumber = salesOrdersList?.map(({soNumber}) => ({
        key: soNumber,
        value: soNumber,
        text: soNumber,
      }))
      setSalesOrderNumberList(soNumber)
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList.map(contact => ({
        value: contact.id,
        text: contact.name,
        id: contact.id,
      }))
      setcontactOption(list)
    },
  })
  return (
    <VmoContainer style={style}>
      <VmoCard fluid>
        <VmoCardContent style={{padding: '18px'}}>
          <h3 className="mb-0">Invoices Information</h3>
          <p className="mt-0 mb-4 card-description">Create invoice by mentioning all the required information</p>
          <VmoForm className="errorLabel">
            <VmoFormGroup>
              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Invoice Owner</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set ownership of the invoice"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="assigneeId"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      fluid
                      options={userOption}
                      selection
                      placeholder="Owner"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.assigneeId && {
                          content: removeDoubleQuotes(errors?.assigneeId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField width={4} style={{paddingLeft: '0'}}>
                <div className="info-header ml-3">
                  <label>Prefix Id</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select billing prefix id"
                    size="mini"
                    psoition="top center"
                  />
                </div>

                <Controller
                  control={control}
                  name="prefixId"
                  render={({value, onChange}) => (
                    <VmoFormSelect
                      width={8}
                      placeholder="ID Prefix"
                      options={prefixOptions}
                      value={value}
                      search
                      clearable
                      noResultsMessage={
                        setPrefixOptions.length ? 'No prefixes found' : 'You have not created any Prefixes.'
                      }
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField width={4} required>
                <div className="info-header">
                  <label className="label-class">Invoice Number</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify invoice number"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="invoiceNumber"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      placeholder="Invoice Number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.invoiceNumber && {
                          content: removeDoubleQuotes(errors.invoiceNumber.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label className="label-class">Subject</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify subject of invoice"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="subject"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      className="mb-0"
                      type="text"
                      placeholder="Subject"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.subject && {
                          content: removeDoubleQuotes(errors.subject.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Purchase Order</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select Purchase order from the list"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="purchaseOrderNumber"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      fluid
                      options={purchaseOrderNumberList}
                      selection
                      placeholder="Purchase Order"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors.purchaseOrderNumber && {
                          content: removeDoubleQuotes(errors.purchaseOrderNumber.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Sales Order</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select sales order from the list"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="salesOrderNumber"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      fluid
                      options={salesOrderNumberList}
                      selection
                      placeholder="Sales Order"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors.salesOrderNumber && {
                          content: removeDoubleQuotes(errors.salesOrderNumber.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Invoice Date</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set invoice issue date"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="invoiceDate"
                  render={({onChange, value}) => (
                    <VmoDatePicker
                      type="date"
                      value={typeof value === 'string' ? new Date(InvoiceDate) : InvoiceDate}
                      inputProps={{readOnly: true}}
                      time={false}
                      onChange={value => {
                        onChange(value)
                        setInvoiceDate(value)
                      }}
                      error={
                        errors?.invoiceDate && {
                          content: removeDoubleQuotes(errors?.invoiceDate?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Contact Name</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select a contact name to display on the invoice"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="contactId"
                  render={({onChange, value}) => (
                    <VmoFormSelect
                      fluid
                      selection
                      options={contactOption}
                      placeholder="Contact Name"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.contactId && {
                          content: removeDoubleQuotes(errors?.contactId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Excise Duty</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="set the excise duty of invoice"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="exciseDuty"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput type="number" onChange={(e, {value}) => onChange(value)} value={value} />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Status</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="set the status of invoice"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="status"
                  render={({onChange, value}) => (
                    <VmoFormSelect
                      fluid
                      selection
                      options={Status}
                      placeholder="Status"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.status && {
                          content: removeDoubleQuotes(errors?.status?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Sales Commission</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Add if there is any extra commission"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="salesCommission"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput type="number" onChange={(e, {value}) => onChange(value)} value={value} />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup>
              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Description</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="brief description about invoice"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="description"
                  render={({onChange, value}) => (
                    <VmoFormTextArea
                      maxLength={200}
                      type="text"
                      value={value}
                      placeholder="Description"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.description && {
                          content: removeDoubleQuotes(errors.description.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>
          </VmoForm>
        </VmoCardContent>
      </VmoCard>
      <VmoCard fluid>
        <VmoCardContent>
          <h3 className="mb-0">Address Information</h3>
          <p className="mt-0 mb-4 card-description">Add billing and shopping address for the invoice</p>
          <VmoForm className="errorLabel">
            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing Street</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Mention the billing street"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.streetAddress"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      maxLength={20}
                      fluid
                      value={value}
                      placeholder="Street"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.billingAddress?.streetAddress && {
                          content: removeDoubleQuotes(errors?.billingAddress?.streetAddress?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping Street</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention shipping address of the product"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.streetAddress"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      maxLength={20}
                      fluid
                      value={value}
                      placeholder="Street"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.shippingAddress?.streetAddress && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.streetAddress?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing City</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Mention billing city"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.locality"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      fluid
                      value={value}
                      placeholder="Locality"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.billingAddress?.locality && {
                          content: removeDoubleQuotes(errors?.billingAddress?.locality?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping City</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Mention shipping city"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.locality"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      fluid
                      maxLength={20}
                      value={value}
                      placeholder="Locality"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.shippingAddress?.locality && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.locality?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing State</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="specify buyers billing state"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.state"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      fluid
                      maxLength={20}
                      value={value}
                      placeholder="State"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.billingAddress?.state && {
                          content: removeDoubleQuotes(errors?.billingAddress?.state?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping State</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify state of product shipping"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.state"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      fluid
                      maxLength={20}
                      value={value}
                      placeholder="State"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.shippingAddress?.state && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.state?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing Postal Code</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention postal code for billing"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.postalCode"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormInput
                      type="text"
                      fluid
                      maxLength={10}
                      placeholder="Postal Code"
                      onBlur={onBlur}
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.billingAddress?.postalCode && {
                          content: removeDoubleQuotes(errors?.billingAddress?.postalCode?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping Postal Code</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify postal code for shipping"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.postalCode"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormInput
                      type="text"
                      fluid
                      maxLength={10}
                      placeholder="Postal Code"
                      onBlur={onBlur}
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.shippingAddress?.postalCode && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.postalCode?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing Country</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify country name for billing purpose"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.country"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      search
                      fluid
                      options={KadenceCountryList}
                      selection
                      placeholder="Country"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.billingAddress?.country && {
                          content: removeDoubleQuotes(errors?.billingAddress?.country?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping Country</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention shipping country"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.country"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      search
                      fluid
                      maxLength={20}
                      options={KadenceCountryList}
                      selection
                      placeholder="Country"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.shippingAddress?.country && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.country?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>
          </VmoForm>
        </VmoCardContent>
      </VmoCard>
      <VmoGridColumn width={8} className="quotePreBox" style={{display: 'none'}}>
        <PreviewInvoices watch={{setupData: watchAll}} />
      </VmoGridColumn>
    </VmoContainer>
  )
}

export default Setup
