import {apiAction} from './api'
import {
  CREATE_USER_ACCOUNT,
  LOGIN_USER,
  VERIFICATION,
  RESEND_VERIFICATION,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  SET_METADATA,
  RESEND_INVITATION,
  GET_VERIFICATION,
  SET_PASSWORD,
  VERIFY_DOMAIN,
  FORGOT_DOMAIN,
  LOGOUT,
  CHANGE_EMAIL,
  ADD_NEW_INVITEE,
  GET_MAIL_BOX,
  INTEGRATE_MAIL_BOX,
  UPDATE_SMTP_SETTINGS,
  EMPLOYEE_SET_PASSWORD,
  LOGIN_EMPLOYEE,
  EMPLOYEE_RESET_PASSWORD,
  EMPLOYEE_LOGOUT,
  GLOBAL_SEARCH_CUSTOMERS,
  GLOBAL_SEARCH_INBOX,
  GLOBAL_SEARCH_SALES,
  GLOBAL_SEARCH_PROJECTS,
  GLOBAL_SEARCH_FINANCE,
  GLOBAL_SEARCH_HR,
  LEARN_MORE,
} from './types'

import * as endpoint from './endpoint'

// -- createUserAccount ---//

export function createUserAccount(data) {
  //
  return apiAction({
    url: endpoint.USER_ACCOUNTS,
    method: 'POST',
    label: CREATE_USER_ACCOUNT,
    data,
    isTokenSkipped: true,
  })
}

// ----Reset Password---/
// ---migration pending---//
export function otpRequest(data) {
  return apiAction({
    url: `${endpoint.USER_ACCOUNTS}/forgotpassword`,
    method: 'POST',
    label: RESET_PASSWORD,
    data,
    showToast: true,
    isTokenSkipped: true,
  })
}

// ----Reset Password OTP---/

// ----Change Password ---/
// ---migration pending---//
export function changePassword(data) {
  //
  return apiAction({
    url: `${endpoint.USER_ACCOUNTS}/setpassword`,
    method: 'POST',
    label: CHANGE_PASSWORD,
    data,
    showToast: true,
    isTokenSkipped: true,
  })
}

// -- logInUser --//

export function logInUser(data) {
  return apiAction({
    url: `${endpoint.USER_ACCOUNTS}/login`,
    method: 'POST',

    label: LOGIN_USER,
    data,
    isTokenSkipped: true,
    showToast: true,
  })
}

// -- userVerification ---//

export function userVerification(data) {
  return apiAction({
    url: `${endpoint.USER_VERIFICATIONS}/verifyotp`,
    method: 'POST',
    label: VERIFICATION,
    data,
    showToast: true,
    isTokenSkipped: true,
  })
}

// -- Email Change after opt send ---//

export function changeEmail(data) {
  return apiAction({
    url: `${endpoint.USER_PLATFORM_V1}/email`,
    method: 'PUT',
    label: CHANGE_EMAIL,
    data,
    showToast: true,
    successMessage: 'Email Changed Successfully',
    isTokenSkipped: true,
  })
}

// Verfication status of account

export function getVerification(data) {
  return apiAction({
    url: `${endpoint.USER_VERIFICATIONS}?requestId=${data}`,
    method: 'GET',
    label: GET_VERIFICATION,
    showToast: true,
    isTokenSkipped: true,
  })
}

// -- resendVerification ---//
export function resendVerification(data) {
  return apiAction({
    url: `${endpoint.USER_VERIFICATIONS}/resend?requestId=${data}`,
    method: 'GET',
    label: RESEND_VERIFICATION,
    showToast: true,
    isTokenSkipped: true,
  })
}

// Set Password
// ---migration pending---//
export function setPassword(data) {
  return apiAction({
    url: `${endpoint.USER_ACCOUNTS}/setpassword`,
    method: 'POST',
    label: SET_PASSWORD,
    data,
    showToast: true,
    isTokenSkipped: true,
  })
}

// -----Getting Started Status ----///
export function setMetaData(data) {
  return apiAction({
    url: `${endpoint.USER_ACCOUNTS}/metadata`,
    method: 'PUT',
    label: SET_METADATA,
    data,
  })
}

// ---migration pending---//
export function getMetaData() {
  return apiAction({
    url: `${endpoint.USER_ACCOUNTS}/metadata`,
    method: 'GET',
    label: 'GET_META_DATA',
  })
}

export function resendInvitation(id) {
  return apiAction({
    url: `${endpoint.USER_VERIFICATIONS}/resend?userId=${id}`,
    method: 'GET',
    label: RESEND_INVITATION,
    showToast: true,
    successMessage: 'Invitation sent',
  })
}

export function domainVerification(data) {
  return apiAction({
    url: `${endpoint.USER_ACCOUNTS}/domain`,
    method: 'POST',
    label: VERIFY_DOMAIN,
    data,
    errorMessage: 'Invalid Domain',
    showToast: true,
  })
}

export function forgotDomain(data) {
  return apiAction({
    url: `${endpoint.USER_ACCOUNTS}/forgotdomain`,
    method: 'POST',
    label: FORGOT_DOMAIN,
    data,
    showToast: true,
  })
}

export function logoutCall() {
  return apiAction({
    url: `${endpoint.USER_ACCOUNTS}/logout`,
    method: 'POST',
    label: LOGOUT,
  })
}

export function addNewInvitee(inviteeData) {
  return apiAction({
    url: `${endpoint.USER_ACCOUNTS}/users`,
    method: 'POST',
    label: ADD_NEW_INVITEE,
    data: inviteeData,
    showToast: true,
    successMessage: 'User Added',
  })
}

// Setting Channels Email Inbox
export function setSMTPSetting(data, inboxId) {
  return apiAction({
    url: `${endpoint.KADENCE_INBOXES}/${inboxId}/smtp`,
    method: 'PUT',
    label: UPDATE_SMTP_SETTINGS,
    data,
    showToast: true,
  })
}

export function getMailBox(data) {
  return apiAction({
    url: `${endpoint.KADENCE_INBOXES}/${data}`,
    method: 'GET',
    label: GET_MAIL_BOX,
  })
}

export function getAllAssigness() {
  return apiAction({
    url: endpoint.USER_USERS,
    method: 'GET',
    label: 'GET_ALL_ASSIGNEE',
  })
}

export function integrateMailBox(id) {
  return apiAction({
    url: `${endpoint.KADENCE_INBOXES}/validate/${id}`,
    method: 'GET',
    label: INTEGRATE_MAIL_BOX,
  })
}

export function employeeSetPassword(data) {
  return apiAction({
    url: `${endpoint.KADENCE_EMPLOYEE}/auth/invitation`,
    method: 'POST',
    label: EMPLOYEE_SET_PASSWORD,
    data,
  })
}

export function employeeSignIn(data) {
  return apiAction({
    url: `${endpoint.KADENCE_EMPLOYEE}/auth/login`,
    method: 'POST',
    label: LOGIN_EMPLOYEE,
    data,
    isTokenSkipped: true,
    showToast: true,
  })
}

export function employeeResetPassword(data) {
  return apiAction({
    url: `${endpoint.KADENCE_EMPLOYEE}/auth/forget-password`,
    method: 'POST',
    label: EMPLOYEE_RESET_PASSWORD,
    data,
    showToast: true,
    isTokenSkipped: true,
  })
}

export function employeeLogout() {
  return apiAction({
    url: `${endpoint.KADENCE_EMPLOYEE}/auth/logout`,
    method: 'POST',
    label: EMPLOYEE_LOGOUT,
  })
}

export function globalSearchCustomers(value) {
  return apiAction({
    url: `${endpoint.KADENCE_ACCOUNT_V1}/global/search/customers?text=${value}`,
    method: 'GET',
    label: GLOBAL_SEARCH_CUSTOMERS,
  })
}

export function globalSearchInbox(value) {
  return apiAction({
    url: `${endpoint.KADENCE_ACCOUNT_V1}/global/search/inbox?text=${value}`,
    method: 'GET',
    label: GLOBAL_SEARCH_INBOX,
  })
}

export function globalSearchSales(value) {
  return apiAction({
    url: `${endpoint.KADENCE_ACCOUNT_V1}/global/search/sales?text=${value}`,
    method: 'GET',
    label: GLOBAL_SEARCH_SALES,
  })
}

export function globalSearchProjects(value) {
  return apiAction({
    url: `${endpoint.KADENCE_ACCOUNT_V1}/global/search/projects?text=${value}`,
    method: 'GET',
    label: GLOBAL_SEARCH_PROJECTS,
  })
}

export function globalSearchFinance(value) {
  return apiAction({
    url: `${endpoint.KADENCE_ACCOUNT_V1}/global/search/finance?text=${value}`,
    method: 'GET',
    label: GLOBAL_SEARCH_FINANCE,
  })
}

export function globalSearchHr(value) {
  return apiAction({
    url: `${endpoint.KADENCE_ACCOUNT_V1}/global/search/hr?text=${value}`,
    method: 'GET',
    label: GLOBAL_SEARCH_HR,
  })
}

export function helpLearnMore() {
  return apiAction({
    url: `${endpoint.KADENCE_ACCOUNT_V1}/help-learnmore/urls`,
    method: 'GET',
    label: LEARN_MORE,
  })
}
