import {apiAction} from './api'
import {KADENCE_MEETINGS} from './endpoint'
import {
  ADD_EVENT_MEETING_BOOK,
  BOOKING_SLOT_AVALIBLITY,
  DELETE_MEETINGS,
  EDIT_MEETING,
  GET_MEETING_LIST,
  GET_SINGLE_MEETING,
  GOOGLE_CALENDAR_AUTH_CHECK,
  GOOGLE_CALENDAR_DISCONNECT,
  MEETING_CALENDAR_LIST,
  MEETING_CALENDAR_TWO_WAY_SYNC,
  MEETING_CREATE,
  MEETING_EVENT_LIST,
  MEETING_SAMPLE_MAIL,
  UNAVAILABLE_SLOTS,
} from './types'

export function meetingCreate(data) {
  return apiAction({
    url: `${KADENCE_MEETINGS}/meetings`,
    method: 'POST',
    label: MEETING_CREATE,
    data,
    showToast: true,
    successMessage: 'Created Meeting',
  })
}

export function getMeetingsList(params = {}) {
  const keys = Object.keys(params)
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${KADENCE_MEETINGS}/meetings?${query}`,
    method: 'GET',
    label: GET_MEETING_LIST,
  })
}

export function deleteMeetings(id) {
  return apiAction({
    url: `${KADENCE_MEETINGS}/meetings/${id}`,
    method: 'DELETE',
    label: DELETE_MEETINGS,
    showToast: true,
    successMessage: 'Deleted',
  })
}

export function getSingleMeetings(id) {
  return apiAction({
    url: `${KADENCE_MEETINGS}/meetings/${id}`,
    method: 'GET',
    label: GET_SINGLE_MEETING,
    showToast: true,
  })
}

export function editMeeting(id, data) {
  return apiAction({
    url: `${KADENCE_MEETINGS}/meetings/${id}`,
    method: 'PUT',
    label: EDIT_MEETING,
    data,
    showToast: true,
    successMessage: 'Meeting updated',
  })
}

export function googleCalendarAuthCheck() {
  return apiAction({
    url: `${KADENCE_MEETINGS}/meetings/googleConnect`,
    method: 'GET',
    label: GOOGLE_CALENDAR_AUTH_CHECK,
    showToast: false,
  })
}

export function googleCalendarDisconnect(id) {
  return apiAction({
    url: `${KADENCE_MEETINGS}/google_auth/authRemove/${id}`,
    method: 'POST',
    label: GOOGLE_CALENDAR_DISCONNECT,
    showToast: true,
    successMessage: 'Google calendar Disconnected',
  })
}

export function bookingSlotAvaliablity(id) {
  return apiAction({
    url: `${KADENCE_MEETINGS}/google_auth/checkAvaliablity/${id}`,
    method: 'GET',
    label: BOOKING_SLOT_AVALIBLITY,
  })
}

export function eventList(id) {
  return apiAction({
    url: `${KADENCE_MEETINGS}/google_auth/eventList/${id}`,
    method: 'GET',
    label: MEETING_EVENT_LIST,
    showToast: true,
  })
}

export function addEventMeetingBook(data) {
  return apiAction({
    url: `${KADENCE_MEETINGS}/google_auth/eventAdd`,
    method: 'POST',
    label: ADD_EVENT_MEETING_BOOK,
    data,
    showToast: true,
  })
}

export function unavailableSlots(id, data) {
  return apiAction({
    url: `${KADENCE_MEETINGS}/google_auth/eventList/${id}`,
    method: 'POST',
    label: UNAVAILABLE_SLOTS,
    data,
    showToast: false,
  })
}

export function sampleMail(params = {}) {
  const keys = Object.keys(params)
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${KADENCE_MEETINGS}/meetings/sampleMail?${query}`,
    method: 'GET',
    label: MEETING_SAMPLE_MAIL,
    showToast: true,
    successMessage: 'Mail Sent',
  })
}

export function calendarList() {
  return apiAction({
    url: `${KADENCE_MEETINGS}/meetings/calendarList`,
    method: 'GET',
    label: MEETING_CALENDAR_LIST,
  })
}

export function calendarTwoWaySync(id) {
  return apiAction({
    url: `${KADENCE_MEETINGS}/meetings/twoWaySynch/${id}`,
    method: 'GET',
    label: MEETING_CALENDAR_TWO_WAY_SYNC,
    showToast: true,
  })
}
