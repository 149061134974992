/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {MdCheckCircle} from 'react-icons/md'
import _ from 'lodash'
import {
  VmoButton,
  VmoDivider,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormTextArea,
  VmoIcon,
  VmoInput,
  VmoTooltip,
} from 'vmo-library'
import {GET_APPEARENCE_WIDGET, GET_INSTALLATION_WIDGET, UPDATE_APPEARENCE_WIDGET} from 'actions/types'
import {GetAppearenceWidget, GetInstallationWidget, UpdateAppearenceWidget} from 'actions/settings_messenger'
import SettingLayout from 'layouts/settingLayout'
import {copyToClipboardDropdown, removeDoubleQuotes} from 'utils/helper'
import {chatPages} from 'validation/Settings/channels/chatpages.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'

export const ChatPages = ({setWarningModal}) => {
  const dispatch = useDispatch()

  const [link, setLink] = useState('')

  const {successLabels = [], isLoadingData, type = []} = useSelector(store => store.apiReducer)
  const {appearence, installation = []} = useSelector(store => store.settingsMessenger)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const chatPagesLearnMore = settingsLearnMoreUrl?.channels?.chatPages?.learnMore

  const {channelsPermissions} = useUserPermissions()
  const manageMessangerPagesPermissions = channelsPermissions?.manage_messanger_pages

  const defaultValues = {
    pageBgColor: {
      color: 'linear-gradient(to left, #09203f 0%, #537895 100%)',
      primaryColor: '#537895',
    },
    pageCompanyUrl: '',
    pageHeader: '',
    pageMetaDescription: '',
    pageMetaTitle: '',
    pageWelcomeMsg: '',
  }

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(chatPages),
    defaultValues,
  })

  const resetData = () => {
    if (appearence && appearence.id) {
      const newApperence = {
        ...appearence,
        pageBgColor: JSON.parse(appearence.pageBgColor) || defaultValues.pageBgColor,
      }
      reset(
        _.pick(newApperence, [
          'pageBgColor',
          'pageCompanyUrl',
          'pageHeader',
          'pageWelcomeMsg',
          'pageMetaTitle',
          'pageMetaDescription',
        ])
      )
    } else {
      reset(defaultValues)
    }
  }

  useEffect(() => {
    dispatch(GetInstallationWidget())
  }, [dispatch])

  useEffect(() => {
    if (installation?.[0]) {
      if (!appearence) dispatch(GetAppearenceWidget(installation[0].id))
    }
  }, [dispatch, installation])

  useEffect(() => {
    if (installation?.[0]?.username) return setLink(`https://widget.kadencehq.com/${installation[0].username}`)
  }, [installation])

  useEffect(() => {
    if (
      successLabels.includes(GET_INSTALLATION_WIDGET) ||
      successLabels.includes(UPDATE_APPEARENCE_WIDGET) ||
      successLabels.includes(GET_APPEARENCE_WIDGET)
    ) {
      resetData()
    }
  }, [successLabels])

  useEffect(() => {
    if (isDirty) {
      setWarningModal(true)
    }
    return () => {
      setWarningModal(false)
    }
  }, [isDirty, setWarningModal])

  const updateData = data => {
    data.pageBgColor = JSON.stringify(data.pageBgColor)
    if (appearence.id) dispatch(UpdateAppearenceWidget(appearence.id, data))
    dispatch({type: UPDATE_APPEARENCE_WIDGET, data})
    setWarningModal(false)
  }

  const pageBg = {
    c1: {
      color: '#e5e9ee',
      primaryColor: '#e5e9ee',
    },
    c2: {
      color: 'linear-gradient(to left, #09203f 0%, #537895 100%)',
      primaryColor: '#537895',
    },
    c3: {
      color: 'linear-gradient(-255deg,#ac32e4 0%, #7918f2 48%,#4801ff 100%)',
      primaryColor: '#4801ff',
    },
    c4: {
      color: 'linear-gradient(-255deg,#a445b2 0%,#d41872 52%,#ff0066 100%)',
      primaryColor: '#f06',
    },
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/chat-pages" />}
      header="Chat Pages"
      subHeader="Customize the chat page to match its look with your brand"
      learnMoreUrl={chatPagesLearnMore}
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Reset',
          disabled: !isDirty,
          onClick: resetData,
        },
        success: {
          content: 'Save',
          disabled: !isDirty,
          loading: isLoadingData && type.includes(UPDATE_APPEARENCE_WIDGET),
          ...(manageMessangerPagesPermissions && {
            onClick: handleSubmit(updateData),
          }),
        },
      }}
      lockRole={!manageMessangerPagesPermissions}
    >
      <VmoForm className="errorLabel">
        <VmoFormField>
          <label>Share your chat page so customer can easily contact</label>
          <div className="d-flex">
            <VmoInput
              readonly
              value={link}
              style={{width: '550px'}}
              action={{
                color: 'primary',
                content: 'Copy Link',
                onClick: e => copyToClipboardDropdown(e, link, 'Link Copied'),
              }}
            />
            <VmoButton className="basic-primary" href={link} target="_blank" rel="noopener noreferrer">
              <VmoIcon name="external alternate" /> Preview
            </VmoButton>
          </div>
        </VmoFormField>

        <VmoFormField>
          <label>Background Colour</label>
          <Controller
            control={control}
            name="pageBgColor"
            render={({onChange, value}) => (
              <div className="gredColor mt-0">
                <VmoTooltip
                  size="mini"
                  position="top center"
                  content="Gradient White"
                  trigger={
                    <div id="color1" className="colorBox solCol09" onClick={() => onChange(pageBg.c1)}>
                      {value?.color === pageBg.c1.color && <MdCheckCircle className="active" />}
                    </div>
                  }
                />

                <VmoTooltip
                  size="mini"
                  position="top center"
                  content="Gradient Blue"
                  trigger={
                    <div id="color2" className="colorBox greCol04" onClick={() => onChange(pageBg.c2)}>
                      {value?.color === pageBg.c2.color && <MdCheckCircle className="active" />}
                    </div>
                  }
                />

                <VmoTooltip
                  size="mini"
                  position="top center"
                  content="Gradient purple"
                  trigger={
                    <div id="color3" className="colorBox greCol05" onClick={() => onChange(pageBg.c3)}>
                      {value?.color === pageBg.c3.color && <MdCheckCircle className="active" />}
                    </div>
                  }
                />

                <VmoTooltip
                  size="mini"
                  position="top center"
                  content="Gradient Pink"
                  trigger={
                    <div id="color4" className="colorBox greCol06" onClick={() => onChange(pageBg.c4)}>
                      {value?.color === pageBg.c4.color && <MdCheckCircle className="active" />}
                    </div>
                  }
                />
              </div>
            )}
          />
        </VmoFormField>

        <h3 className="mb-0">Appearance</h3>
        <VmoDivider className="mt-1" />

        <VmoFormGroup widths="equal">
          <VmoFormField>
            <div className="info-header">
              <label>Company URL</label>
              <VmoTooltip
                content="Insert your company URL which you want to show in your chat page."
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="pageCompanyUrl"
              render={({onChange, value, onBlur}) => (
                <VmoFormInput
                  maxLength={200}
                  placeholder="Company URL"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  onBlur={onBlur}
                  error={
                    errors.pageCompanyUrl && {
                      content: removeDoubleQuotes(errors.pageCompanyUrl.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
          <VmoFormField>
            <div className="info-header">
              <label>Heading</label>
              <VmoTooltip
                content="Mention a header for your chat page"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="pageHeader"
              render={({onChange, value, onBlur}) => (
                <VmoFormInput
                  maxLength={20}
                  placeholder="Heading"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  onBlur={onBlur}
                  error={
                    errors.pageHeader && {
                      content: removeDoubleQuotes(errors.pageHeader.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoFormGroup>

        <VmoFormField width={8}>
          <div className="info-header">
            <label>Welcome Message</label>
            <VmoTooltip
              content="specify a welcome message which show up to visitors on chat page"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="pageWelcomeMsg"
            control={control}
            render={({onChange, value, onBlur}) => (
              <VmoFormInput
                maxLength={60}
                placeholder="Welcome message"
                value={value}
                onChange={e => onChange(e.target.value)}
                onBlur={onBlur}
                error={
                  errors.pageWelcomeMsg && {
                    content: removeDoubleQuotes(errors.pageWelcomeMsg.message),
                  }
                }
              />
            )}
          />
        </VmoFormField>

        <h3 className="mb-0">Search Engine Optimization</h3>
        <VmoDivider className="mt-1" />

        <VmoFormField width={8}>
          <div className="info-header">
            <label>Meta Title</label>
            <VmoTooltip
              content="Title of the meta data of the page for SEO Optimization."
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="pageMetaTitle"
            control={control}
            render={({onChange, value, onBlur}) => (
              <VmoFormInput
                maxLength={30}
                placeholder="Meta Title"
                value={value}
                onChange={e => onChange(e.target.value)}
                onBlur={onBlur}
                error={
                  errors.pageMetaTitle && {
                    content: removeDoubleQuotes(errors.pageMetaTitle.message),
                  }
                }
              />
            )}
          />
        </VmoFormField>

        <VmoFormField width={8}>
          <div className="info-header">
            <label>Meta Description</label>
            <VmoTooltip
              content="Description of meta data of the page for SEO Optimization."
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="pageMetaDescription"
            control={control}
            render={({onChange, value, onBlur}) => (
              <VmoFormTextArea
                placeholder="Meta Description"
                maxLength={200}
                value={value}
                onChange={e => onChange(e.target.value)}
                onBlur={onBlur}
                error={
                  errors.pageMetaDescription && {
                    content: removeDoubleQuotes(errors.pageMetaDescription.message),
                  }
                }
              />
            )}
          />
        </VmoFormField>
      </VmoForm>
    </SettingLayout>
  )
}
