import {
  GET_ALL_TIMEOFF,
  GET_SINGLE_TIMEOFF,
  GET_SINGLE_TIMEOFF_TYPE,
  GET_TIMEOFF_EMPLOYEE_LIST,
  GET_TIMEOFF_TYPES,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_TIMEOFF_TYPES:
      return {
        ...state,
        getTimeOffTypesData: action.payload,
      }
    case GET_SINGLE_TIMEOFF_TYPE:
      return {
        ...state,
        getSingleTimeOffTypesData: action.payload,
      }
    case GET_TIMEOFF_EMPLOYEE_LIST:
      return {
        ...state,
        getTimeOffEmployeeOptionData: action.payload,
      }
    case GET_ALL_TIMEOFF:
      return {
        ...state,
        getAllTimeOffData: action.payload.timeoffs,
        getAllTimeOffPage: action.payload.page,
      }
    case GET_SINGLE_TIMEOFF:
      return {
        ...state,
        timeOffData: action.payload,
      }
    default:
      return state
  }
}
