const Joi = require('joi')

export const addMilestoneSchema = Joi.object().keys({
  name: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  assignees: Joi.array().items(Joi.number()).min(1).required().messages({
    'array.min': 'Assignee must be specified',
  }),
  dueDate: Joi.date().required().messages({
    'date.base': 'Please enter valid date',
  }),
  tasklistIds: Joi.array().items(Joi.number()).allow(null),
  description: Joi.string().trim().max(500).allow('', null).messages({
    'string.max': `Length must be less than or equal to 500 characters`,
  }),
})

export const addCommentSchema = Joi.object().keys({
  comment: Joi.string().trim().max(250).required().messages({
    'string.max': `Length must be less than or equal to 250 characters`,
  }),
})

export const milestoneDuplicateSchema = Joi.object().keys({
  name: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
})

export const milestoneMoveSchema = Joi.object().keys({
  name: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  projectId: Joi.number().required().messages({
    'number.base': 'Project must be specified',
  }),
})
