import {
  QUOTES_BY_CLOSE_DEALS,
  QUOTES_BY_OPEN_DEALS,
  QUOTES_BY_OWNER,
  QUOTES_BY_PRODUCTS,
  QUOTES_OVER_TIME,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case QUOTES_OVER_TIME:
      return {
        ...state,
        quotesOverTimeData: action.payload,
      }
    case QUOTES_BY_PRODUCTS:
      return {
        ...state,
        quotesByProductsData: action.payload,
      }
    case QUOTES_BY_OWNER:
      return {
        ...state,
        quotesByOwnerData: action.payload,
      }
    case QUOTES_BY_OPEN_DEALS:
      return {
        ...state,
        quotesByOpenDealsData: action.payload,
      }
    case QUOTES_BY_CLOSE_DEALS:
      return {
        ...state,
        quotesByCloseDealsData: action.payload,
      }
    default:
      return state
  }
}
