import {GET_SCORING_RULES, GET_SCORING_RULES_INSIGHTS, GET_SINGLE_SCORING_RULES} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case GET_SCORING_RULES:
      return {...state, getScoringRulesData: payload}

    case GET_SINGLE_SCORING_RULES:
      return {...state, getSingleScoringRulesData: payload}

    case GET_SCORING_RULES_INSIGHTS:
      return {...state, getScoringRulesInsightsData: payload}

    default:
      return {...state}
  }
}
