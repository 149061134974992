import {KADENCE_ANALYTICS} from './endpoint'
import {apiAction} from './api'
import {
  SALES_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS,
  TOTAL_SALES_ORDERS,
  SALES_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS,
  NUMBER_OF_SALES_ORDER_BY_USERS,
  AMOUNT_OF_SALES_ORDER_BY_USERS,
  SALES_ORDER_COUNT_OVERTIME,
  SALES_ORDER_AMOUNT_OVERTIME,
} from './types'

export function totalSalesOrders(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/sales-orders/total-sales-orders`,
    method: 'POST',
    label: TOTAL_SALES_ORDERS,
    data,
  })
}

export function salesOrderNumber(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/sales-orders/sales-order-number-breakdown-by-products`,
    method: 'POST',
    label: SALES_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS,
    data,
  })
}

export function salesOrderAmount(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/sales-orders/sales-order-amount-breakdown-by-products`,
    method: 'POST',
    label: SALES_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS,
    data,
  })
}

export function salesOrderNumberByUser(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/sales-orders/number-of-sales-order-by-users`,
    method: 'POST',
    label: NUMBER_OF_SALES_ORDER_BY_USERS,
    data,
  })
}

export function salesOrderAmountByUser(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/sales-orders/amount-of-sales-order-by-users`,
    method: 'POST',
    label: AMOUNT_OF_SALES_ORDER_BY_USERS,
    data,
  })
}

export function salesOrderCountOvertime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/sales-orders/sales-order-count-overtime`,
    method: 'POST',
    label: SALES_ORDER_COUNT_OVERTIME,
    data,
  })
}

export function salesOrderAmountOvertime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/sales-orders/sales-order-amount-overtime`,
    method: 'POST',
    label: SALES_ORDER_AMOUNT_OVERTIME,
    data,
  })
}
