import React, {useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiHome} from 'react-icons/fi'
import {MdDesktopWindows, MdSmartphone} from 'react-icons/md'
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar'
import {ResponsiveBar} from '@nivo/bar'
import {ResponsivePie} from '@nivo/pie'
import _ from 'lodash'
import {VmoCard, VmoCardContent, VmoCardHeader, VmoGrid, VmoGridColumn, VmoTooltip} from 'vmo-library'

import {CONTACT_DEVICES_ANALYTICS, INFLOW_BY_CHANNEL} from 'actions/types'
import {homepageDevicesAnalytics, homepageLiveCount, inflowByChannel} from 'actions/dashboards_support'
import CustomLoader from 'components/common/CustomLoader'
import {get, startCase} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import DateRangeGroup from '../DateRangeGroup'

const Overview = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)
  const {
    inflowChannel: dataInflowByChannel = [],
    liveCountData = {},
    analyticsDevices = [],
  } = useSelector(state => state.dashboardsSupport)

  const actionsToDispatch = useCallback(
    data => {
      dispatch(inflowByChannel(data))
      dispatch(homepageLiveCount(data))
      dispatch(homepageDevicesAnalytics(data))
    },
    [dispatch]
  )

  const graphDevices = useMemo(() => {
    if (analyticsDevices) {
      const tempArr = analyticsDevices.map(obj => {
        return {
          ...obj,
          id: startCase(obj.device),
          label: startCase(obj.device),
          value: obj.value,
        }
      })
      return _.sortBy(
        tempArr.filter(v => v.label !== 'Tablet'),
        ['label']
      )
    }
  }, [analyticsDevices])

  return (
    <div className="kadence-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <FiHome />
            <h5>Home</h5>
          </div>
        </div>
        <div className="page-action">
          <DateRangeGroup actionsToDispatch={actionsToDispatch} />
        </div>
      </div>

      <VmoGrid>
        <VmoGridColumn width={10}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Inflow by Channels</VmoCardHeader>
              <VmoTooltip
                content="graphical representation of conversation inflow across channels over a timeframe"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 350}}>
              {type.includes(INFLOW_BY_CHANNEL) && <CustomLoader />}
              {!type.includes(INFLOW_BY_CHANNEL) && dataInflowByChannel.every(val => val.percentage === 0) && (
                <div className="empty-chart">No Data Available</div>
              )}
              {!type.includes(INFLOW_BY_CHANNEL) && (
                <ResponsiveBar
                  data={dataInflowByChannel}
                  keys={['percentage']}
                  indexBy="id"
                  margin={{top: 50, right: 10, bottom: 60, left: 60}}
                  padding={0.5}
                  groupMode="grouped"
                  minValue={0}
                  maxValue={100}
                  gridYValues={[0, 20, 40, 60, 80, 100]}
                  valueScale={{type: 'linear'}}
                  indexScale={{type: 'band', round: true}}
                  colors={d => d.data.color}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Channel',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    format: value => `${value}%`,
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: [0, 20, 40, 60, 80, 100],
                    legend: 'Percentage',
                    legendPosition: 'middle',
                    legendOffset: -45,
                  }}
                  labelSkipHeight={1}
                  label={d => <tspan y={-8}>{d.value}%</tspan>}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={4}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Online Visitors ({get(['totalCounts'], liveCountData, 0)})</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of online visitors based on mobile and desktop over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent style={{height: 350}}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10px',
                }}
              >
                <p style={{fontSize: '12px'}}>New ({get(['newCounts'], liveCountData, 0)})</p>
                <p style={{fontSize: '12px'}}>Return ({get(['repeatCounts'], liveCountData, 0)})</p>
              </div>
              <div style={{height: 200}}>
                {type.includes(CONTACT_DEVICES_ANALYTICS) && <CustomLoader />}
                {!type.includes(CONTACT_DEVICES_ANALYTICS) && !graphDevices.every(({value}) => value === 0) ? (
                  <ResponsivePie
                    data={graphDevices}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                    startAngle={-50}
                    innerRadius={0.7}
                    padAngle={1}
                    cornerRadius={1}
                    colors={d => d.data.color}
                    borderWidth={1}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.4]],
                    }}
                    arcLinkLabel={d => `${d.id} (${d.data.devicePercentage}%)`}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsDiagonalLength={5}
                    arcLinkLabelsStraightLength={10}
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{from: 'color'}}
                    enableArcLabels={false}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]],
                    }}
                    animate
                  />
                ) : (
                  !type.includes(CONTACT_DEVICES_ANALYTICS) && (
                    <VmoGrid centered>
                      {!type.includes(CONTACT_DEVICES_ANALYTICS) && (
                        <VmoGridColumn
                          style={{
                            width: '200px',
                            height: '200px',
                            marginLeft: '20px',
                          }}
                        >
                          <CircularProgressbar
                            styles={buildStyles({
                              textColor: 'rgba(0, 0, 0, 0.6)',
                              trailColor: '#f2f4f7',
                              textSize: '7px',
                            })}
                            strokeWidth={10}
                            value={0}
                            text="No Data Available"
                          />
                        </VmoGridColumn>
                      )}
                    </VmoGrid>
                  )
                )}
              </div>
              <VmoGrid columns="equal" className="ml-3 mr-3 mt-3">
                <VmoGridColumn>
                  <div
                    className="user-device-chart"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <VmoTooltip
                      content={graphDevices[0]?.device}
                      size="mini"
                      position="top center"
                      trigger={<MdDesktopWindows />}
                    />
                    <h5 className="mt-0 ml-2">{graphDevices[0]?.value} Users</h5>
                  </div>
                </VmoGridColumn>
                <VmoGridColumn>
                  <div
                    className="user-device-chart"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <VmoTooltip
                      content={graphDevices[1]?.device}
                      size="mini"
                      position="top center"
                      trigger={<MdSmartphone />}
                    />
                    <h5 className="mt-0 ml-2">{graphDevices[1]?.value} Users</h5>
                  </div>
                </VmoGridColumn>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Overview
