import React from 'react'
import create from 'assets/create.svg'
import CreateFirst from 'components/common/CreateFirst'
import {FaBriefcase} from 'react-icons/fa'

const Contracts = () => {
  return (
    <CreateFirst
      src={<FaBriefcase />}
      header="Contract"
      subHeader="Help organization create, store, and manage employment contract related documents"
      addFirst={() => null}
      buttonText="Create Contract"
      Feature1="Version Control"
      Feature2="Contract Templates"
      Feature3="Electronic signature"
      list1="Maintain multiple versions of contracts and updates over time"
      list2="Manage contract templates for different employment agreements"
      list3="Allow employees to sign contracts digitally"
      imageSrc={create}
    />
  )
}

export default Contracts
