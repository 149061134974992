import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {VmoMenu, VmoMenuItem, VmoAccordion, VmoAccordionTitle, VmoAccordionContent} from 'vmo-library'
import {FiChevronRight, FiList, FiLayers} from 'react-icons/fi'
import {isActiveNavlink} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const ProductsMenu = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  const [expand, setExpand] = useState([])

  const {productList = []} = useSelector(state => state.productLibrary)

  const menus = [
    {
      key: 'settings',
      title: 'Settings',
      items: [
        {
          path: '/products-settings',
          title: 'Settings',
          icon: <FiList />,
        },
      ],
    },
  ]

  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }

  const isActive = key => !expand.includes(key)

  return (
    <>
      {productList.length > 0 && (
        <VmoMenu vertical secondary className="kadence-menu">
          <VmoMenuItem active={isActiveNavlink('/products/all')} onClick={() => history.push('/products/all')}>
            <FiLayers /> All products
          </VmoMenuItem>

          {menus.map(({title, key, items}) => (
            <VmoAccordion key={key}>
              <VmoAccordionTitle onClick={() => handleMenuExpand(key)}>
                {title}
                {isActive(key) ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              </VmoAccordionTitle>
              <VmoAccordionContent active={isActive(key)}>
                {items.map(({path, title, icon}) => (
                  <VmoMenuItem key={path} active={isActiveNavlink(path)} onClick={() => history.push(`${url}${path}`)}>
                    {icon} {title}
                  </VmoMenuItem>
                ))}
              </VmoAccordionContent>
            </VmoAccordion>
          ))}
        </VmoMenu>
      )}
    </>
  )
}

export default ProductsMenu
