import React, {useEffect, useState} from 'react'
import svgs from 'utils/svgs'

const SvgIcon = ({path, width = '16px', height = '16px', color = '#000000', ...rest}) => {
  const [SvgComponent, setSvgComponent] = useState(null)

  useEffect(() => {
    let isMounted = true

    // Dynamically load the SVG component
    svgs[`${path}.svg`]?.()
      .then(module => {
        if (isMounted) {
          setSvgComponent(module.default)
        }
      })
      .catch(error => {
        if (isMounted) {
          console.error('Error loading SVG:', path, error)
          setSvgComponent(null) // Handle error if SVG loading fails
        }
      })

    return () => {
      isMounted = false
    }
  }, [path])

  if (!SvgComponent) {
    return null
  }

  return <SvgComponent width={width} height={height} fill={color} stroke={color} {...rest} />
}

export default SvgIcon
