import React from 'react'
import EmployeeMenu from 'navigation/sub-routes/Hr/Employees/EmployeeMenu'
import EmployeeRoutes from 'navigation/sub-routes/Hr/Employees/EmployeeRoutes'

function Employees() {
  return (
    <>
      <EmployeeMenu />
      <EmployeeRoutes />
    </>
  )
}

export default Employees
