/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoContainer,
  VmoHeader,
  VmoLabel,
  VmoList,
  VmoListItem,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {DELETE_PRODUCTS_FIELD, GET_PRODUCTS_CATEGORY} from 'actions/types'
import {deleteProductsField, getProductCategory, getProductField, getProductFieldlist} from 'actions/productLibrary'
import DeleteModal from 'components/common/DeleteModal'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import FieldModal from './FieldsModal'

function ProductFields() {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()

  const {categoryList, fieldList = []} = useSelector(state => state.productLibrary)
  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)

  const {productLibraryPermissions} = useUserPermissions()
  const manageProductSettingsPermissions = productLibraryPermissions?.manage_product_settings

  useEffect(() => {
    dispatch(getProductFieldlist())
    if (!categoryList) {
      dispatch(getProductCategory())
    }
  }, [])

  useEffect(() => {
    if (successLabels.includes(DELETE_PRODUCTS_FIELD)) {
      dispatch(getProductFieldlist())
      setEditId()
    }
  }, [successLabels])

  return (
    <>
      <VmoContainer fluid>
        <div className="pageHeader px-0">
          <div className="pageTitle">
            <h5>Product Fields</h5>
          </div>
          <div className="pageAction">
            <VmoTooltip
              size="mini"
              position="bottom center"
              content={
                manageProductSettingsPermissions
                  ? 'Add new Product'
                  : getPermissionTooltipContent({roleAccess: manageProductSettingsPermissions})
              }
              trigger={
                <VmoButton
                  floated="right"
                  content="Add New"
                  onClick={() => manageProductSettingsPermissions && setOpen(true)}
                  className={!manageProductSettingsPermissions ? 'disabled-button' : ''}
                  primary
                />
              }
            />
          </div>
        </div>

        <div>
          <VmoTable sortable basic selectable>
            <VmoTableHeader>
              <VmoTableRow>
                <VmoTableHeaderCell className="pl-4" disabled>
                  #
                </VmoTableHeaderCell>
                <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                <VmoTableHeaderCell disabled>Category</VmoTableHeaderCell>
                <VmoTableHeaderCell disabled>Type</VmoTableHeaderCell>
                <VmoTableHeaderCell disabled>Required</VmoTableHeaderCell>
                <VmoTableHeaderCell disabled>Action</VmoTableHeaderCell>
              </VmoTableRow>
            </VmoTableHeader>
            <VmoTableBody>
              {isLoadingData && type.includes(GET_PRODUCTS_CATEGORY) ? (
                <VmoTablePlaceholder columns={6} />
              ) : fieldList.length > 0 ? (
                fieldList.map(({id, name, required, categoryIds, type}, index) => {
                  return (
                    <VmoTableRow
                      onClick={() => {
                        if (manageProductSettingsPermissions) {
                          dispatch(getProductField(id))
                          setOpen(true)
                          setEditId(id)
                        }
                      }}
                      className="tableLink"
                      key={id}
                    >
                      <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
                      <VmoTableCell>
                        <VmoHeader as="h5">{startCase(name)}</VmoHeader>
                      </VmoTableCell>
                      <VmoTableCell>
                        <VmoList horizontal>
                          {categoryIds &&
                            categoryIds
                              .slice(0, 2)
                              .map(({category = '', id}, i) => (
                                <VmoListItem key={id}>
                                  {`${category} ${i + 1 !== categoryIds?.length ? ',' : ''}`}
                                </VmoListItem>
                              ))}
                          <VmoListItem>
                            {categoryIds?.length > 2 && (
                              <VmoLabel>
                                &{categoryIds.length - 2}
                                more
                              </VmoLabel>
                            )}
                          </VmoListItem>
                        </VmoList>
                      </VmoTableCell>
                      <VmoTableCell>{type}</VmoTableCell>
                      <VmoTableCell>{required ? 'Yes' : 'No'}</VmoTableCell>
                      <VmoTableCell onClick={e => e.stopPropagation()}>
                        <VmoTooltip
                          content={
                            manageProductSettingsPermissions
                              ? 'Delete'
                              : getPermissionTooltipContent({roleAccess: manageProductSettingsPermissions})
                          }
                          size="mini"
                          position="top center"
                          trigger={
                            <VmoButton
                              icon
                              onClick={() => {
                                if (manageProductSettingsPermissions) {
                                  setEditId(id)
                                  setDeleteModal(true)
                                }
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </VmoButton>
                          }
                        />
                      </VmoTableCell>
                    </VmoTableRow>
                  )
                })
              ) : (
                <tr>
                  <td colSpan="6" className="emptyValue">
                    No Data Available
                  </td>
                </tr>
              )}
            </VmoTableBody>
          </VmoTable>
        </div>
      </VmoContainer>

      {open && <FieldModal open={open} toggle={() => setOpen(false)} editId={editId} clearEditId={() => setEditId()} />}
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete this field?"
          deleteAction={deleteProductsField}
          idTobeDeleted={editId}
          type={DELETE_PRODUCTS_FIELD}
        />
      )}
    </>
  )
}

export default ProductFields
