/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {useHistory, useParams} from 'react-router-dom'
import _ from 'lodash'
import {
  VmoCard,
  VmoCardContent,
  VmoDropdown,
  VmoHeader,
  VmoHeaderSubheader,
  VmoRating,
  VmoTooltip,
  VmoButton,
} from 'vmo-library'

import {
  ADD_CONTACT_WEBSITE,
  ADD_EMAIL_CONTACT,
  ADD_NOTE_CONTACTS,
  ADD_PHONE_CONTACT,
  ATTACHMENT_POST_CONTACT,
  CREATE_TASK,
  DELETE_CONTACT_EMAIL,
  DELETE_CONTACT_PHONE,
  DELETE_CONTACT_WEBSITE,
  EDIT_CONTACT,
  EDIT_TASK,
  GET_SINGLE_CONTACT,
  GET_USERS,
  UPDATE_CONTACT_WEBSITE,
  UPDATE_EMAIL_CONTACT,
  UPDATE_PHONE_CONTACT,
} from 'actions/types'
import {
  addInteractionContact,
  addNoteContact,
  editContact,
  getContactCompanyList,
  getContactStatuses,
  getContactType,
  getSingleContact,
} from 'actions/contacts'
import {
  addContactEmail,
  addContactPhone,
  addContactWebsite,
  getTagLabel,
  updateContactEmail,
  updateContactPhone,
  updateContactWebsite,
} from 'actions/multiple_fields'
import {get, getPermissionTooltipContent} from 'utils/helper'
import Category from 'components/common/Category'
import {commonEmail, commonPhone, commonWebsite} from 'components/common/CommonDispatch'
import TimeLine from 'components/common/TimeLine'
import EmailEditorBox from 'components/common/EmailEditorBox'
import CreateTask from 'components/pages/Projects/Tasks/CreateTask'
import {editContactSchema} from 'validation/Customers/contacts.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'
import ContactInfo from './ContactInfo'
import ContactSummary from './ContactSummary'

const ContactCustomer = () => {
  const {id: contactId} = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const [userOption, setUserOption] = useState([])
  const [toMailBoxList, setToMailBox] = useState([])
  const [isOpenMailComposer, setIsOpenMailComposer] = useState(false)
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false)
  const [noteText, setNoteText] = useState('')

  const {singleContact = {}} = useSelector(state => state.contacts)
  const {
    allActivities = [],
    notes: getAllNotesData = [],
    updates: getAllUpdatesData = [],
    conversationTimeline = [],
  } = singleContact
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {getContactDetails: contactDetails = [], getContactDetails: {tags: contactTags} = {}} = useSelector(
    state => state.customers
  )
  const {inbox: mailboxes} = useSelector(state => state.mailInbox)
  const getAllInterActionsData = useSelector(state => get(['contacts', 'singleContact', 'interactions'], state))

  const {conversationsPermissions, contactsAndCompaniesPermissions, tasksPermissions} = useUserPermissions()
  const manageConversationPermissions = conversationsPermissions?.manage_conversation
  const manageContactsCompanyPermissions = contactsAndCompaniesPermissions?.manage_contacts_company
  const manageTasksPermissions = tasksPermissions?.manage_tasks

  // useForm
  const initialPreviewData = {
    firstName: get(['firstName'], singleContact, ''),
    lastName: get(['lastName'], singleContact, ''),
    email: get(['email'], singleContact, ''),
    phone: get(['phone'], singleContact, ''),
    website: get(['website'], singleContact, ''),
    emails: get(['emails'], singleContact, [{category: 'work', email: '', id: null}]),
    phones: get(['phones'], singleContact, [{category: 'work', phone: ''}]),
    websites: get(['websites'], singleContact, [{category: 'work', url: ''}]),
    // company: get(["company", "name"], singleContact, ""),
    // companyId: get(["companyId"], singleContact, null),
    title: get(['title'], singleContact, ''),
    description: get(['description'], singleContact, ''),
    address: {
      streetAddress: get(['address', 'streetAddress'], singleContact, ''),
      locality: get(['address', 'locality'], singleContact, ''),
      state: get(['address', 'state'], singleContact, ''),
      country: get(['address', 'country'], singleContact, ''),
      postalCode: get(['address', 'postalCode'], singleContact, ''),
    },
    contactTypeId: get(['contactTypeId'], singleContact, null),
    assigneeId: get(['assigneeId'], singleContact, null),
    statusId: get(['statusId'], singleContact, null),
    allowPortalAccess: get(['allowPortalAccess'], singleContact, false),
  }
  const {
    control,
    errors,
    reset,
    formState: {dirtyFields},
    handleSubmit,
    getValues,
  } = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    submitFocusError: true,
    defaultValues: initialPreviewData,
    resolver: joiResolver(editContactSchema),
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list || [])
    },
  })

  useEffect(() => {
    if (contactId) {
      dispatch(getSingleContact(contactId))
      dispatch(getContactType())
      dispatch(getContactCompanyList())
      dispatch(getContactStatuses())
      dispatch(getTagLabel())
    }
  }, [])

  useEffect(() => {
    if (
      successLabels.includes(ATTACHMENT_POST_CONTACT) ||
      successLabels.includes(CREATE_TASK) ||
      successLabels.includes(EDIT_TASK)
    ) {
      dispatch(getSingleContact(contactId))
    }
    if (successLabels.includes(ADD_NOTE_CONTACTS)) {
      dispatch(getSingleContact(contactId))
      setNoteText('')
    }
    if (successLabels.includes(ADD_EMAIL_CONTACT)) {
      removeEmail()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(UPDATE_EMAIL_CONTACT)) {
      removeEmail()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(DELETE_CONTACT_EMAIL)) {
      removeEmail()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(ADD_PHONE_CONTACT)) {
      removePhone()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(UPDATE_PHONE_CONTACT)) {
      removePhone()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(DELETE_CONTACT_PHONE)) {
      removePhone()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(ADD_CONTACT_WEBSITE)) {
      removeWebsite()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(UPDATE_CONTACT_WEBSITE)) {
      removeWebsite()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(DELETE_CONTACT_WEBSITE)) {
      removeWebsite()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(EDIT_CONTACT)) {
      reset(initialPreviewData, {dirtyFields: false})
    }
  }, [successLabels, reset])

  const renderComposeMail = () => {
    if (isOpenMailComposer)
      return (
        <EmailEditorBox
          isOpenMailComposer={isOpenMailComposer}
          stopDraftOnClose
          setIsOpenMailComposer={setIsOpenMailComposer}
          mailboxes={mailboxes}
          defaultTo={toMailBoxList}
        />
      )
    return null
  }

  const renderCreateTaskModal = () => {
    if (isCreateTaskOpen) {
      return (
        <CreateTask
          setIsCreateTaskOpen={setIsCreateTaskOpen}
          isCreateTaskOpen={isCreateTaskOpen}
          conversation={get(['conversation'], contactDetails)}
          contactId={contactId}
          isDataAvailabel
        />
      )
    }
  }

  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: 'emails',
  })
  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: 'phones',
  })
  const {
    fields: websiteFields,
    append: appendwebsite,
    remove: removeWebsite,
  } = useFieldArray({
    control,
    name: 'websites',
  })
  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_CONTACT)) {
      reset(initialPreviewData)
    }
  }, [successLabels, reset])

  const formStateVal = dirtyFields

  const updateContact = data => {
    const dirtyFields = _.pick(data, Object.keys(formStateVal))

    if (dirtyFields.emails) {
      commonEmail({
        dispatch,
        id: contactId,
        data,
        singleData: singleContact,
        formStateVal,
        addApi: addContactEmail,
        updateApi: updateContactEmail,
        removeEmail,
      })
    }
    if (dirtyFields.phones) {
      commonPhone({
        dispatch,
        id: contactId,
        data,
        singleData: singleContact,
        formStateVal,
        addApi: addContactPhone,
        updateApi: updateContactPhone,
        removePhone,
      })
    }
    if (dirtyFields.websites) {
      commonWebsite({
        dispatch,
        id: contactId,
        data,
        singleData: singleContact,
        formStateVal,
        addApi: addContactWebsite,
        updateApi: updateContactWebsite,
        removeWebsite,
      })
    }
    if (dirtyFields.emails || dirtyFields.phones || dirtyFields.websites) {
      delete dirtyFields.emails
      delete dirtyFields.phones
      delete dirtyFields.websites
    }
    if (!_.isEmpty(dirtyFields)) {
      dispatch(editContact(dirtyFields, contactId))
    }
  }

  return (
    <div style={{display: 'flex'}}>
      <div className="customerProfilePage">
        <VmoCard className="userProfileInfo">
          <VmoCardContent>
            <VmoTooltip
              size="mini"
              position="bottom center"
              content={
                manageContactsCompanyPermissions
                  ? singleContact.isFollowed
                    ? 'Remove Favorite'
                    : 'Add Favorite'
                  : getPermissionTooltipContent({roleAccess: manageContactsCompanyPermissions})
              }
              trigger={
                <VmoRating
                  style={{float: 'right'}}
                  icon="star"
                  className="favStar"
                  onClick={() =>
                    manageContactsCompanyPermissions &&
                    dispatch(editContact({isFollowed: !singleContact.isFollowed}, contactId))
                  }
                  rating={singleContact.isFollowed ? 1 : 0}
                />
              }
            />
            <VmoButton className="backBtn" onClick={() => history.push('/contacts')}>
              <SvgIcon path="common/left" /> Back to contacts
            </VmoButton>
            <VmoHeader className="contName">
              {`${_.get(singleContact, 'firstName') || ''} ${_.get(singleContact, 'lastName') || ''}`}
              <VmoHeaderSubheader>
                {_.get(singleContact, 'title') || ''}
                {_.get(singleContact, 'company.name') || ''}
              </VmoHeaderSubheader>
            </VmoHeader>

            <div className="userActBtn">
              <VmoTooltip
                size="mini"
                position="bottom center"
                content={
                  manageConversationPermissions
                    ? 'Create Conversation'
                    : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
                }
                trigger={
                  <VmoButton
                    className="mr-3"
                    onClick={() => {
                      if (manageConversationPermissions) {
                        setToMailBox(contactDetails.email && [get(['email'], contactDetails)])
                        setIsOpenMailComposer(true)
                      }
                    }}
                    icon={<SvgIcon path="clients/contacts/contacts-dashboard/create-conversations" />}
                  />
                }
              />

              <VmoTooltip
                size="mini"
                position="bottom center"
                content={
                  manageTasksPermissions
                    ? 'Create Tasks'
                    : getPermissionTooltipContent({roleAccess: manageTasksPermissions})
                }
                trigger={
                  <VmoButton
                    className="mr-3"
                    icon={<SvgIcon path="clients/contacts/contacts-dashboard/create-task" />}
                    onClick={() => manageTasksPermissions && setIsCreateTaskOpen(true)}
                  />
                }
              />

              {/* <VmoTooltip
                size="mini"
                position="bottom center"
                content="Send SMS"
                trigger={
                  <VmoButton
                    icon={<FiSend />}
                    // Cant Find a Function, So commented below functions
                    // onClick={() => setIsCreateTaskOpen(true)}
                  />
                }
              /> */}
            </div>

            <div className="staticData">
              <div className="dataOne">
                <p>Contact Score</p>
                <h4>{get(['entityScore'], singleContact)}</h4>
              </div>
              <div className="dataOne">
                <p>Email Insight Score</p>
                <h4>{get(['emailInsightScore'], singleContact)}</h4>
              </div>
            </div>
          </VmoCardContent>
          <VmoCardContent>
            <ContactInfo
              contactId={contactId}
              errors={errors}
              control={control}
              Controller={Controller}
              handleSubmit={handleSubmit}
              updateContact={updateContact}
              emailFields={emailFields}
              appendEmail={appendEmail}
              removeEmail={removeEmail}
              phoneFields={phoneFields}
              appendPhone={appendPhone}
              removePhone={removePhone}
              websiteFields={websiteFields}
              appendwebsite={appendwebsite}
              removeWebsite={removeWebsite}
            />
          </VmoCardContent>
        </VmoCard>
        <div className="userTimeLine">
          <VmoCard fluid className="mb-0">
            <Category
              id={contactId}
              task="contact"
              addNote={addNoteContact}
              addLogInteration={addInteractionContact}
              rolePermissions={manageContactsCompanyPermissions}
            />
          </VmoCard>
          <div className="page-header px-0 mt-4" style={{background: 'transparent'}}>
            <div className="page-title">
              <div className="page-titleIcon">
                <h4 className="m-0">Timeline</h4>
              </div>
            </div>
            <div className="page-action">
              <VmoTooltip
                content="Agent"
                size="mini"
                position="top center"
                trigger={
                  <div className="mailActivity">
                    <VmoDropdown
                      icon="chevron down"
                      button
                      selection
                      header="User"
                      style={{float: 'right'}}
                      options={[{value: 'all', text: 'All', key: 'all'}, ...userOption]}
                      onChange={(e, {value}) => dispatch(getSingleContact(contactId, value))}
                      defaultValue="all"
                    />
                  </div>
                }
              />
            </div>
          </div>
          <VmoCard fluid className="m-0">
            <TimeLine
              allActivities={allActivities}
              getAllInterActionsData={getAllInterActionsData}
              getAllNotesData={getAllNotesData}
              getAllUpdatesData={getAllUpdatesData}
              conversationTimeline={conversationTimeline}
            />
          </VmoCard>
        </div>
      </div>
      <div
        className="customerProfile pl-0"
        style={{
          // height: "max-content",
          border: 'none',
        }}
      >
        <ContactSummary
          contactId={contactId}
          getValues={getValues}
          errors={errors}
          control={control}
          Controller={Controller}
          updateContact={updateContact}
          handleSubmit={handleSubmit}
          noteText={noteText}
          setNoteText={setNoteText}
        />
      </div>
      {renderComposeMail()}
      {renderCreateTaskModal()}
    </div>
  )
}

export default ContactCustomer
