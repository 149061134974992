import Joi from 'joi'

export const productsSchema = Joi.object({
  image: Joi.any().label('Image'),
  name: Joi.string().trim().min(2).max(30).required().label('Name'),
  description: Joi.string().allow('', null).optional(),
  sku: Joi.string().trim().required().label('SKU').messages({
    'string.empty': 'SKU is not allowed to be empty',
  }),
  categoryId: Joi.number().label('Category').messages({
    'number.base': `Category must be a required field`,
  }),
  currencyId: Joi.number().required(),
  unitPrice: Joi.number().min(0).label('Unit Price').optional(),
  unitCost: Joi.number().min(0).label('Unit Cost').optional(),
  billingFreq: Joi.string().valid('One Time', 'Monthly', 'Quarterly', 'Half Annually', 'Annually'),
  tax: Joi.string(),
  fields: Joi.any(),
})
