import {apiAction} from './api'
import {KADENCE_SETTING} from './endpoint'
import {
  CREATE_SCORING_RULES,
  DELETE_SCORING_RULES,
  GET_SCORING_RULES,
  GET_SCORING_RULES_INSIGHTS,
  GET_SINGLE_SCORING_RULES,
  UPDATE_SCORING_RULES,
  UPDATE_SCORING_RULES_INSIGHTS,
} from './types'

export function getAllScoringRules() {
  return apiAction({
    url: `${KADENCE_SETTING}/scoring-rules`,
    method: 'GET',
    label: GET_SCORING_RULES,
  })
}

export function createScoringRules(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/scoring-rules`,
    method: 'POST',
    label: CREATE_SCORING_RULES,
    data,
    showToast: true,
    successMessage: 'Scoring Rules Created',
  })
}

export function getSingleScoringRules(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/scoring-rules/${id}`,
    method: 'GET',
    label: GET_SINGLE_SCORING_RULES,
  })
}

export function updateScoringRules(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/scoring-rules/${id}`,
    method: 'PUT',
    label: UPDATE_SCORING_RULES,
    data,
    showToast: true,
    successMessage: 'Scoring Rules Updated',
  })
}
export function deleteScoringRules(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/scoring-rules/${id}`,
    method: 'DELETE',
    label: DELETE_SCORING_RULES,
    showToast: true,
    successMessage: 'Scoring Rules Deleted',
  })
}

export function getScoringRulesInsights() {
  return apiAction({
    url: `${KADENCE_SETTING}/scoring-rules/insight`,
    method: 'GET',
    label: GET_SCORING_RULES_INSIGHTS,
  })
}

export function updateScoringRulesInsights(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/scoring-rules/insight`,
    method: 'PUT',
    label: UPDATE_SCORING_RULES_INSIGHTS,
    data,
    showToast: true,
    successMessage: 'Scoring Rules Insights Updated',
  })
}
