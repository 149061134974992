import {PROJECT_FINANCE_BUDGET_TYPES} from 'constants/projects'
import Joi from 'joi'

export const projectFinanceBudgetSchema = Joi.object({
  budgetType: Joi.string()
    .valid(
      PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS,
      PROJECT_FINANCE_BUDGET_TYPES.RETAINER,
      PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE
    )
    .required(),
  rangeType: Joi.string().valid('financial', 'time').required(),
  budgetAmount: Joi.when('rangeType', {
    is: 'financial',
    then: Joi.number().min(1).required().messages({
      'number.min': 'Please enter a value greater than 0',
    }),
    otherwise: Joi.number().default(0),
  }),
  budgetTimeHour: Joi.number().integer(),
  budgetTimeMinute: Joi.number().integer(),
  startDate: Joi.date().required().messages({
    'date.base': 'Please enter valid date',
  }),
  endDate: Joi.date().min(Joi.ref('startDate')).required().messages({
    'date.base': 'Please enter valid date',
    'date.min': 'End date must be greater then start date',
  }),
  timelogType: Joi.when('budgetType', {
    is: Joi.valid(PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER),
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  repeatUnit: Joi.when('budgetType', {
    is: Joi.valid(PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER),
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  carryUnderspend: Joi.boolean().default(false),
  carryOverspend: Joi.boolean().default(false),
  isDefaultBillableRateSet: Joi.boolean(),
  defaultBillableRate: Joi.number(),
  budgetProfitMargin: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    then: Joi.number().default(0),
    otherwise: Joi.any().default(0),
  }),
  budgetTargetProfit: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    then: Joi.number().default(0),
    otherwise: Joi.any().default(0),
  }),
  budgetTargetCosts: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    then: Joi.number().default(0),
    otherwise: Joi.any().default(0),
  }),
})

export const projectFinanceBillingInvoiceSchema = Joi.object({
  invoiceNumber: Joi.string().trim().required(),
  invoiceDate: Joi.date().required(),
  currencyId: Joi.number().required().messages({
    'number.base': 'Currency must be specified',
  }),
  pricingBasedOn: Joi.string().required(),
  finalPrice: Joi.when('pricingBasedOn', {
    is: 'fixed',
    then: Joi.number().required(),
    otherwise: Joi.number(),
  }),
  poNumber: Joi.string().trim().allow('', null),
  description: Joi.string().trim().allow('', null),
})
