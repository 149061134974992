import {KADENCE_ANALYTICS} from './endpoint'
import {apiAction} from './api'
import {
  PURCHASE_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS,
  TOTAL_PURCHASE_ORDERS,
  PURCHASE_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS,
  NUMBER_OF_PURCHASE_ORDER_BY_USERS,
  AMOUNT_OF_PURCHASE_ORDER_BY_USERS,
  PURCHASE_ORDER_COUNT_OVERTIME,
  PURCHASE_ORDER_AMOUNT_OVERTIME,
} from './types'

export function totalPurchaseOrders(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchase-orders/total-purchase-orders`,
    method: 'POST',
    label: TOTAL_PURCHASE_ORDERS,
    data,
  })
}

export function purchaseOrderNumber(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchase-orders/purchase-order-number-breakdown-by-products`,
    method: 'POST',
    label: PURCHASE_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS,
    data,
  })
}

export function purchaseOrderAmount(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchase-orders/purchase-order-amount-breakdown-by-products`,
    method: 'POST',
    label: PURCHASE_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS,
    data,
  })
}

export function purchaseOrderNumberByUser(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchase-orders/number-of-purchase-order-by-users`,
    method: 'POST',
    label: NUMBER_OF_PURCHASE_ORDER_BY_USERS,
    data,
  })
}

export function purchaseOrderAmountByUser(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchase-orders/amount-of-purchase-order-by-users`,
    method: 'POST',
    label: AMOUNT_OF_PURCHASE_ORDER_BY_USERS,
    data,
  })
}

export function purchaseOrderCountOvertime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchase-orders/purchase-order-count-overtime`,
    method: 'POST',
    label: PURCHASE_ORDER_COUNT_OVERTIME,
    data,
  })
}

export function purchaseOrderAmountOvertime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/finance/purchase-orders/purchase-order-amount-overtime`,
    method: 'POST',
    label: PURCHASE_ORDER_AMOUNT_OVERTIME,
    data,
  })
}
