const Joi = require('joi')
const {inputStringValidation, emailValidation, inputPhoneValidation} = require('validation')

export const myProfileSchema = Joi.object({
  firstName: inputStringValidation.required().label('First Name'),
  lastName: inputStringValidation.required().label('Last Name'),
  email: emailValidation.required().label('Email'),
  phoneCode: Joi.string().required().label('Phone Code'),
  roleId: Joi.number().required().label('Role'),
  phone: inputPhoneValidation.required().label('Phone No.').messages({
    'string.empty': `Phone No cannot be an empty field`,
    'any.required': `Phone No is a required field`,
    'string.max': `Phone No length must be less than or equal to 20 characters`,
  }),
  timezone: Joi.string().required(),
  timeFormat: Joi.string().required(),
  dateFormat: Joi.string().required(),
  dateDelimiterFormat: Joi.string().required(),
})
