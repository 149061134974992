import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from 'react'
import {HiOutlineChatAlt2} from 'react-icons/hi'
import {CAMPAIGN_STATES_ANALYTICS, GET_CAMPAIGN_LIST} from 'actions/types'
import {getCampaignList} from 'actions/campaign'
import {startCase} from 'utils/helper'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useApiResponse from 'hooks/impure/useApiResponse'
import Campaign from './Campaign'

const initialCampaignStatus = [
  {name: 'totalSent', label: 'Total Sent'},
  {name: 'uniqueVisitors', label: 'Unique Visitors'},
  {name: 'totalReplied', label: 'Total Replied'},
  {name: 'engagementRate', label: 'Engagement Rate'},
]

const Chat = () => {
  const dispatch = useDispatch()

  const [campaignStatus, setCampaignStatus] = useState(initialCampaignStatus)
  const [campaignIsAvailabel, setCampaignIsAvailabel] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {campaignAnalyticsStatus = {}} = useSelector(state => state.analyticsCampaign)
  const {campaignList = []} = useSelector(state => state.campaign)

  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const campaignsAccess = analyticsAccess?.campaigns
  const campaignReportsPermissions = reportsPermissions?.campaign_reports

  useEffect(() => {
    if (campaignsAccess && campaignReportsPermissions) {
      dispatch(getCampaignList())
    } else {
      setCampaignIsAvailabel(false)
      dispatch({type: 'CLEAR_ANALYTICS_CAMPAIGN'})
      setCampaignStatus(initialCampaignStatus)
    }
  }, [campaignReportsPermissions, dispatch, campaignsAccess])

  useEffect(() => {
    if (successLabels.includes(GET_CAMPAIGN_LIST)) {
      if (campaignList.some(campaign => campaign.type === '1')) {
        setCampaignIsAvailabel(true)
      } else {
        setCampaignIsAvailabel(false)
        dispatch({type: 'CLEAR_ANALYTICS_CAMPAIGN'})
        setCampaignStatus(initialCampaignStatus)
      }
    }
  }, [dispatch, campaignList, successLabels])

  useEffect(() => {
    if (successLabels.includes(CAMPAIGN_STATES_ANALYTICS) && Object.keys(campaignAnalyticsStatus).length > 0) {
      const temp = []
      for (const [key, val] of Object.entries(campaignAnalyticsStatus)) {
        if (key !== 'totalRead') temp.push({name: key, label: startCase(key), ...val})
      }
      setCampaignStatus(temp)
    }
  }, [campaignAnalyticsStatus, successLabels])

  return (
    <Campaign
      headerLogo={<HiOutlineChatAlt2 />}
      header="Chat"
      campaignType="1"
      campaignStatus={campaignStatus}
      lineChartHeader="Total Sent and Unique Visitors"
      showBarChart
      campaignIsAvailabel={campaignIsAvailabel}
    />
  )
}

export default Chat
