import React, {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  VmoButton,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTab,
  VmoTabPane,
  VmoTooltip,
} from 'vmo-library'
import {ContentState, convertFromHTML, Editor, EditorState} from 'draft-js'
import {stateToHTML} from 'draft-js-export-html'

import {
  createProjectDiscussionTopicComment,
  deleteProjectDiscussionTopicComment,
  updateProjectDiscussionTopicComment,
} from 'actions/projects'
import {
  extractTextContentFromHTML,
  get,
  getFormattedDate,
  getFormattedTime,
  getPermissionTooltipContent,
} from 'utils/helper'
import {DELETE_PROJECT_DISCUSSION_TOPIC_COMMENT} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {MdOutlineMoreVert} from 'react-icons/md'
import SvgIcon from 'components/common/SvgIcon'

const DiscussionBody = ({projectId, topicId, chatMessages, rolePermissions}) => {
  const dispatch = useDispatch()
  const messagesEndRef = useRef(null)

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView()
    }
  }, [chatMessages])

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [commentEditId, setCommentEditId] = useState(null)
  const [isEditCommentModalOpen, setIsEditCommentModalOpen] = useState(false)
  const [commentDeleteID, setCommentDeleteID] = useState(null)
  const [isDeleteCommentModalOpen, setIsDeleteCommentModalOpen] = useState(false)

  const getContent = () => {
    return extractTextContentFromHTML(stateToHTML(editorState.getCurrentContent()))
  }

  const setEditorClear = () => {
    const editorStateaa = EditorState.push(editorState, ContentState.createFromText(''))
    return setEditorState(EditorState.moveFocusToEnd(editorStateaa))
  }

  const sendMessageData = () => {
    const data = {content: getContent().trim()}
    if (commentEditId) {
      dispatch(updateProjectDiscussionTopicComment({projectId, topicId, commentId: commentEditId, data}))
      setCommentEditId(null)
      setIsEditCommentModalOpen(false)
    } else {
      dispatch(createProjectDiscussionTopicComment({projectId, topicId, data}))
    }
    setEditorClear()
  }

  const EditorTab = [
    {
      menuItem: 'Comment',
      render: () => (
        <VmoTabPane attached={false} className="m-0">
          <div className="edtrBody">
            <div style={{marginTop: '-15px'}}>
              <Editor
                placeholder="Type here..."
                editorState={editorState}
                onChange={editorState => setEditorState(editorState)}
              />
            </div>
            <div className="sendMsgBtn">
              {rolePermissions ? (
                <VmoButton className="mr-0 ml-3" primary onClick={sendMessageData}>
                  Send
                </VmoButton>
              ) : (
                <VmoTooltip
                  content={getPermissionTooltipContent({roleAccess: rolePermissions})}
                  position="bottom right"
                  size="mini"
                  trigger={
                    <VmoButton className={`mr-0 ml-3 ${!rolePermissions ? 'disabled-button' : ''}`} primary>
                      Send
                    </VmoButton>
                  }
                />
              )}
            </div>
          </div>
        </VmoTabPane>
      ),
    },
  ]

  const renderEditComment = () => {
    return (
      <VmoModal
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        size="tiny"
        open={isEditCommentModalOpen}
        onClose={() => setIsEditCommentModalOpen(false)}
      >
        <VmoModalHeader>Edit Comment</VmoModalHeader>
        <VmoModalContent>
          <Editor editorState={editorState} onChange={editorState => setEditorState(editorState)} />
        </VmoModalContent>
        <VmoModalActions>
          <VmoButton
            basic
            onClick={() => {
              setEditorClear()
              setIsEditCommentModalOpen(false)
            }}
          >
            Cancel
          </VmoButton>
          <VmoButton primary onClick={sendMessageData}>
            Update
          </VmoButton>
        </VmoModalActions>
      </VmoModal>
    )
  }

  return (
    <>
      {isEditCommentModalOpen && renderEditComment()}
      <div className="project-discussion-body">
        <div className="project-discussion-chat">
          {chatMessages.map(comment => (
            <div ref={messagesEndRef} key={comment.id}>
              {comment.showDateStamp && (
                <div style={{textAlign: 'center'}} className="mb-3">
                  <span style={{opacity: 0.5, fontWeight: 500}}>- {getFormattedDate(comment.createdAt)} -</span>
                </div>
              )}
              <div className="project-discussion-chatting">
                <div className="project-discussion-userProfile" style={{'--user-bg': 'green'}}>
                  {`${get(['createdBy', 'firstName'], comment, '')[0].toUpperCase()}${get(
                    ['createdBy', 'lastName'],
                    comment,
                    ''
                  )[0].toUpperCase()}`}
                </div>
                <div className="project-discussion-username-chat">
                  <span style={{'--user-bg': 'green'}}>{`${get(['createdBy', 'firstName'], comment)} ${get(
                    ['createdBy', 'lastName'],
                    comment
                  )}`}</span>
                  <span>{getFormattedTime(comment.createdAt)}</span>
                  <span>{get(['content'], comment, '')}</span>
                </div>
                {rolePermissions && (
                  // <VmoDropdown
                  //   upward
                  //   direction="left"
                  //   icon={<SvgIcon path="common/more-verticle" />}
                  //   closeOnBlur={false}
                  //   className="showPrev icon mr-2 mt-3"
                  //   style={{marginLeft: 'auto'}}
                  // >
                  //   <VmoDropdownMenu>
                  //     <VmoDropdownItem
                  //       onClick={() => {
                  //         const temp = EditorState.createWithContent(
                  //           ContentState.createFromBlockArray(convertFromHTML(comment.content))
                  //         )
                  //         setEditorState(temp)
                  //         setCommentEditId(comment.id)
                  //         setIsEditCommentModalOpen(true)
                  //       }}
                  //     >
                  //       <VmoIcon name="edit" /> edit
                  //     </VmoDropdownItem>
                  //     <VmoDropdownItem
                  //       onClick={() => {
                  //         setCommentDeleteID(comment.id)
                  //         setIsDeleteCommentModalOpen(true)
                  //       }}
                  //     >
                  //       <VmoIcon name="trash alternate" /> delete
                  //     </VmoDropdownItem>
                  //   </VmoDropdownMenu>
                  // </VmoDropdown>

                  <VmoDropdown
                    className="showPrev icon mr-2 mt-3"
                    direction="left"
                    style={{marginLeft: 'auto'}}
                    icon={<MdOutlineMoreVert />}
                  >
                    <VmoDropdownMenu>
                      <VmoDropdownItem
                        onClick={() => {
                          const temp = EditorState.createWithContent(
                            ContentState.createFromBlockArray(convertFromHTML(comment.content))
                          )
                          setEditorState(temp)
                          setCommentEditId(comment.id)
                          setIsEditCommentModalOpen(true)
                        }}
                      >
                        <SvgIcon path="common/edit2" /> Edit
                      </VmoDropdownItem>
                      <VmoDropdownItem
                        onClick={() => {
                          setCommentDeleteID(comment.id)
                          setIsDeleteCommentModalOpen(true)
                        }}
                      >
                        <SvgIcon path="common/delete" /> Delete
                      </VmoDropdownItem>
                    </VmoDropdownMenu>
                  </VmoDropdown>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="project-discussion-editor">
          <VmoTab menu={{color: 'blue', secondary: true, pointing: true}} panes={EditorTab} />
        </div>
      </div>

      {isDeleteCommentModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteCommentModalOpen}
          onDelete={() =>
            dispatch(deleteProjectDiscussionTopicComment({projectId, topicId, commentId: commentDeleteID}))
          }
          setIsModalOpen={setIsDeleteCommentModalOpen}
          type={DELETE_PROJECT_DISCUSSION_TOPIC_COMMENT}
        />
      )}
    </>
  )
}

export default DiscussionBody
