/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {Controller, useFieldArray} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoDropdown,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoGrid,
  VmoGridColumn,
  VmoInput,
  VmoList,
  VmoListItem,
  VmoSearch,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {getProductlist} from 'actions/productLibrary'
import {billingFreqOptions} from 'components/pages/Sales/Products/ProductsModal'
import SvgIcon from 'components/common/SvgIcon'

const testIsNumber = value => /^\d{0,8}(\.\d{1,2})?$/g.test(value)
const testIsPercentage = value => /^[0-9][0-9]?(\.\d+)?$|^100$/.test(value)

/**
 *
 * @param {number} totalPrice - multiplication of quantity and price
 * @param {'%' | 'USD'} discountType
 * @param {number} discountValue 0-100 if disType is % otherwise valid number
 */
const getUnitTotal = (totalPrice, discountType, discountValue) => {
  if (!testIsNumber(totalPrice)) return 0

  if (discountType === '%') {
    if (!testIsPercentage(discountValue)) return 0

    return Number((totalPrice * ((100 - discountValue) / 100)).toFixed(2))
  }

  if (discountType === 'USD') {
    if (!testIsNumber(discountValue)) return 0

    return Number((totalPrice - discountValue).toFixed(2))
  }
}

const getCountedValue = (price, type, value) => {
  if (!testIsNumber(price)) return 0

  if (type === '%') {
    if (!testIsPercentage(value)) return 0
    return Number(((price * value) / 100).toFixed(2))
  }
  if (!testIsNumber(value)) return 0
  return Number(value)
}

const typeOptions = [
  {key: '%', value: '%', text: '%'},
  {
    key: 'USD',
    value: 'USD',
    text: 'USD',
  },
]

function FieldList({fieldArray, remove, control, errors}) {
  return (
    <form className="errorLabel">
      {fieldArray.map(({id, type, name, subType, value, total}, i) => {
        return (
          <div key={id} className="disField m-1">
            {/* //! dont remove below this line */}
            <Controller
              control={control}
              defaultValue={type}
              name={`otherCharges[${i}].type`}
              render={({value}) => <VmoFormInput type="hidden" value={value} />}
            />

            <Controller
              control={control}
              defaultValue={total}
              name={`otherCharges[${i}].total`}
              render={({value}) => <VmoFormInput type="hidden" value={value} />}
            />
            {/* //! dont remove above this line */}

            <VmoFormField>
              <label className="mb-2">
                {type.slice(0, 1).toUpperCase() + type.slice(1)}
                <span style={{color: '#db2828'}}>*</span>
              </label>

              <Controller
                name={`otherCharges[${i}].name`}
                control={control}
                defaultValue={name}
                render={({onChange, value}) => (
                  <VmoFormInput
                    placeholder={`Enter name of ${type}`}
                    className="name"
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={
                      errors?.otherCharges?.[i]?.name && {
                        content: (
                          <p
                            style={{
                              color: '#a03c3a',
                              fontSize: '11px',
                              margin: '4px',
                            }}
                          >
                            Name is not allowed to be empty
                          </p>
                        ),
                      }
                    }
                  />
                )}
              />
            </VmoFormField>

            <VmoFormField>
              <Controller
                control={control}
                name={`otherCharges[${i}].value`}
                defaultValue={value}
                render={({onChange, value: renderValue}) => (
                  <VmoFormInput
                    className="type"
                    label={
                      <Controller
                        control={control}
                        defaultValue={subType}
                        name={`otherCharges[${i}].subType`}
                        render={({value: currValue, onChange}) => (
                          <div className="mailActivity">
                            <VmoDropdown
                              icon="chevron down"
                              button
                              value={currValue}
                              onChange={(e, {value: subValue}) => {
                                onChange(subValue)
                              }}
                              options={typeOptions}
                              error={errors?.otherCharges?.[i]?.subType}
                            />
                          </div>
                        )}
                      />
                    }
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors?.otherCharges?.[i]?.value && {
                        content: (
                          <p
                            style={{
                              color: '#a03c3a',
                              fontSize: '11px',
                              margin: '4px',
                            }}
                          >
                            Value is not allowed to be empty
                          </p>
                        ),
                      }
                    }
                    value={renderValue}
                  />
                )}
              />
            </VmoFormField>

            <VmoButton icon onClick={() => remove(i)} style={{marginTop: '28px'}}>
              <SvgIcon path="common/delete" />
            </VmoButton>
          </div>
        )
      })}
    </form>
  )
}

function Product({formData, buttonRef}) {
  const {setValue, control, errors, watch, getValues} = formData

  const dispatch = useDispatch()

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'otherCharges',
  })

  const watchProducts = watch('products')
  const watchOtherCharges = watch('otherCharges')
  const watchSubtotal = watch('subTotal')
  const watchTotal = watch('total')

  const {productList = []} = useSelector(state => state.productLibrary)

  const {
    quoteForm: {products},
  } = useSelector(state => state.quotes)

  const [searchBoxOptions, setSearchBoxOptions] = useState([])
  const [optionsToShow, setOptionsToShow] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchValue, setSearchValue] = useState([])

  useEffect(() => {
    if (!productList?.length) dispatch(getProductlist())
    // return () => {
    //   buttonRef.current.disabled = false;
    // };
  }, [])

  // * set searchbox options for dropdown
  useEffect(() => {
    if (productList.length) {
      const options = productList.map(item => {
        return {
          productId: item.id,
          title: item.name,
          description: item.description,
          price: item.sku,
          quantity: 1,
          billingFreq: item.billingFreq,
          unitPrice: Number(item.unitPrice),
          discountType: '%',
          discountValue: 0,
        }
      })
      setSearchBoxOptions(options)
    }
  }, [productList])

  // *  form value products
  useEffect(() => {
    if (searchValue.length) {
      const valueToSet = searchValue.map(({title, description, price, ...rest}) => {
        return {
          ...rest,
          unitTotal: getUnitTotal(rest.quantity * rest.unitPrice, rest.discountType, rest.discountValue),
        }
      })
      setValue('products', valueToSet)
      buttonRef.current.disabled = false
    } else {
      buttonRef.current.disabled = true
    }
  }, [searchValue])

  // * handle subtotal value
  useEffect(() => {
    if (watchProducts) {
      const subTotalValue = watchProducts.reduce((acc, ite) => Number(acc + ite.unitTotal), 0)
      setValue('subTotal', subTotalValue.toFixed(2))
    }
  }, [watchProducts])

  useEffect(() => {
    if (watchOtherCharges.length) {
      watchOtherCharges.map((charge, index) => {
        setValue(`otherCharges[${index}].total`, getCountedValue(watchSubtotal, charge.subType, charge.value))
      })
    }
  }, [JSON.stringify(watchOtherCharges), JSON.stringify(watchSubtotal)])

  useEffect(() => {
    let subTot = Number(watchSubtotal || 0)

    if (watchOtherCharges.length) {
      for (const ite of watchOtherCharges) {
        if (ite.type === 'discount') {
          subTot -= Number(ite.total || 0)
        } else {
          subTot += Number(ite.total || 0)
        }
      }
    }
    setValue('total', subTot)
  }, [JSON.stringify(watchOtherCharges), JSON.stringify(watchSubtotal)])

  // * reset
  useEffect(() => {
    if (products) {
      const currentProducts = products.products?.map(({id, accountId, quoteId, productName, ...product}) => {
        const option = searchBoxOptions.find(op => {
          return op.productId === product.productId
        })

        return {
          ...option,
          ...product,
        }
      })

      setSearchValue(currentProducts || [])

      setValue('otherCharges', products?.otherCharges)
    }
  }, [products, searchBoxOptions])

  // useEffect(() => {
  //   if (quoteId && (productsData || otherChargesData) && !products) {
  //     dispatch({
  //       type: "PRODUCT_HANDLE_SUBMIT",
  //       payload: {
  //         total,
  //         subTotal,
  //         products: productsData,
  //         otherCharges: otherChargesData ? otherChargesData : [],
  //       },
  //     });
  //   }
  // }, [quoteId, productsData, otherChargesData]);

  const handleResultSelect = (e, {result}) => {
    setSearchValue(searchValue => {
      if (searchValue.some(sv => sv.productId === result.productId)) {
        return searchValue
      }
      return [...searchValue, result]
    })
    setSearchText('')
    setOptionsToShow([])
  }

  const handleSearchChange = (e, {value}) => {
    setSearchText(value)
    setTimeout(() => {
      if (value.length < 1) {
        setOptionsToShow(searchBoxOptions)
        setSearchText('')
        setSearchValue([])
        return
      }
      const re = new RegExp(_.escapeRegExp(searchText), 'i')
      const isMatch = result => re.test(result.title)
      setOptionsToShow(_.filter(searchBoxOptions, isMatch))
    }, 300)
  }

  /**
   *
   * @param {number} index - of an array
   * @param {string | number} value - for that field
   * @param {string} property - to change
   */
  const changeSearchValue = (index, value, property) => {
    setSearchValue(searchValue => {
      const tempSearchValue = [...searchValue]
      const newValue = {
        ...tempSearchValue[index],
        [property]: value,
      }
      tempSearchValue.splice(index, 1, newValue)
      return tempSearchValue
    })
  }

  return (
    <div className="stepWrap">
      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid>
            <VmoCardContent>
              <VmoForm className="errorLabel">
                <h3 className="mb-0">Quote Product & Services</h3>
                <p className="mt-0 mb-4 card-description">
                  Add products along with price which you would like to include in quote
                </p>
                {/* //! dont remove below this line 👇 , used for form handling with library  */}
                <Controller control={control} name="products" render={() => <input type="hidden" />} />
                <Controller control={control} name="subTotal" render={() => <input type="hidden" />} />
                <Controller control={control} name="total" render={() => <input type="hidden" />} />
                <Controller control={control} name="otherCharges" />
                {/* //! dont remove above this line ☝  */}

                <VmoFormField>
                  <div className="info-header">
                    <label>Product & Services</label>
                    <VmoTooltip
                      content="Select product and service to display in quotes"
                      trigger={<SvgIcon path="common/question" />}
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <VmoSearch
                    placeholder="Search to get needed results"
                    minCharacters={0}
                    results={optionsToShow}
                    value={searchText}
                    onResultSelect={handleResultSelect}
                    onSearchChange={_.debounce(handleSearchChange, 500, {
                      leading: true,
                    })}
                  />
                </VmoFormField>
              </VmoForm>
              <div className="info-header mt-3">
                <h5 className="mb-0">Review Line Items</h5>
                <VmoTooltip
                  content="Review all line items which need to be displayed on quotes"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <VmoTable basic className="billProduct">
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>#</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Product Name</VmoTableHeaderCell>
                    <VmoTableHeaderCell>SKU</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Billing Frequency</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Qty</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Unit Price</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Unit Discount (%)</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Net Price</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {searchValue?.length < 1 ? (
                    <tr>
                      <td colSpan="8" className="emptyValue required">
                        Add Line Items to Continue Filling Form
                      </td>
                    </tr>
                  ) : (
                    searchValue.map((item, index) => {
                      return (
                        <VmoTableRow key={`tablerow${index}`}>
                          <VmoTableCell>{index + 1}</VmoTableCell>

                          <VmoTableCell>{item.title}</VmoTableCell>

                          <VmoTableCell>{item.price}</VmoTableCell>

                          <VmoTableCell>
                            <VmoFormSelect
                              fluid
                              options={billingFreqOptions}
                              value={item.billingFreq}
                              onChange={(e, {value}) => changeSearchValue(index, value, 'billingFreq')}
                            />
                          </VmoTableCell>

                          <VmoTableCell>
                            <VmoInput
                              // type="number"
                              min={1}
                              value={item.quantity}
                              onChange={(e, {value}) => changeSearchValue(index, value, 'quantity')}
                              style={{width: '70px'}}
                              onBlur={() => {
                                if (!testIsNumber(item.quantity)) {
                                  changeSearchValue(index, '0', 'quantity')
                                }
                              }}
                            />
                          </VmoTableCell>

                          <VmoTableCell>
                            <VmoInput
                              style={{width: '70px'}}
                              value={item.unitPrice}
                              onChange={(e, {value}) => changeSearchValue(index, value, 'unitPrice')}
                              onBlur={() => {
                                if (!testIsNumber(item.unitPrice)) {
                                  changeSearchValue(index, '0', 'unitPrice')
                                }
                              }}
                            />
                          </VmoTableCell>

                          <VmoTableCell>
                            <VmoInput
                              style={{width: '100px'}}
                              value={item.discountValue}
                              onChange={(e, {value}) => changeSearchValue(index, value, 'discountValue')}
                              onBlur={() => {
                                if (item.discountType === '%') {
                                  if (!testIsPercentage(item.discountValue)) {
                                    changeSearchValue(index, '0', 'discountValue')
                                  }
                                } else if (!testIsNumber(item.discountValue)) {
                                  changeSearchValue(index, '0', 'discountValue')
                                }
                              }}
                              label={
                                <VmoDropdown
                                  options={typeOptions}
                                  value={item.discountType}
                                  onChange={(e, {value}) => changeSearchValue(index, value, 'discountType')}
                                />
                              }
                            />
                          </VmoTableCell>
                          <VmoTableCell>
                            {getUnitTotal(item.quantity * item.unitPrice, item.discountType, item.discountValue)}
                          </VmoTableCell>
                        </VmoTableRow>
                      )
                    })
                  )}
                </VmoTableBody>
              </VmoTable>

              <div className="totalBillCount">
                <div className="action">
                  <div className="disBar">
                    <FieldList
                      fieldArray={fields}
                      remove={remove}
                      control={control}
                      errors={errors}
                      getValues={getValues}
                      setValue={setValue}
                    />
                  </div>

                  <VmoList horizontal divided>
                    <VmoListItem
                      as="a"
                      onClick={() =>
                        append({
                          type: 'discount',
                          name: '',
                          subType: 'USD',
                          value: 0,
                          total: 0,
                        })
                      }
                    >
                      + Discount
                    </VmoListItem>
                    <VmoListItem
                      as="a"
                      onClick={() =>
                        append({
                          type: 'fee',
                          name: '',
                          subType: 'USD',
                          value: 0,
                          total: 0,
                        })
                      }
                    >
                      + Fee
                    </VmoListItem>
                    <VmoListItem
                      as="a"
                      onClick={() =>
                        append({
                          type: 'tax',
                          name: '',
                          subType: 'USD',
                          value: 0,
                          total: 0,
                        })
                      }
                    >
                      + Tax
                    </VmoListItem>
                  </VmoList>
                </div>

                <div className="count">
                  <p className="d-flex">
                    <span>Subtotal</span>
                    <span>{watchSubtotal}</span>
                  </p>

                  {watchOtherCharges?.map((item, index) => {
                    const isPercent = item.subType === '%'
                    return (
                      <p className="d-flex" key={`othercharges${index}`}>
                        <span>
                          {item.name} {isPercent && `( ${item.value} % )`}
                        </span>
                        <span>{item.total}</span>
                      </p>
                    )
                  })}

                  <p className="d-flex">
                    <span>Grand Total</span>
                    <span>{watchTotal.toFixed(2)}</span>
                  </p>
                </div>
              </div>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Product
