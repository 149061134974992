import {apiAction} from './api'
import {KADENCE_SETTING} from './endpoint'
import {
  CHANGE_AUTOMATION_STATUS,
  CREATE_AUTOMATION,
  DELETE_AUTOMATION,
  GET_AUTOMATIONS,
  GET_SINGLE_AUTOMATION,
  UPDATE_AUTOMATION,
} from './types'

export function createAutomation(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/automation-rules`,
    method: 'POST',
    label: CREATE_AUTOMATION,
    data,
    showToast: true,
    successMessage: 'Automation Created',
  })
}

export function getAutomations() {
  return apiAction({
    url: `${KADENCE_SETTING}/automation-rules`,
    method: 'GET',
    label: GET_AUTOMATIONS,
  })
}

export function getSingleAutomation(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/automation-rules/${id}`,
    method: 'GET',
    label: GET_SINGLE_AUTOMATION,
  })
}

export function updateAutomation(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/automation-rules/${id}`,
    method: 'PUT',
    label: UPDATE_AUTOMATION,
    data,
    showToast: true,
    successMessage: 'Automation Updated',
  })
}

export function deleteAutomation(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/automation-rules/${id}`,
    method: 'DELETE',
    label: DELETE_AUTOMATION,
    showToast: true,
    successMessage: 'Automation Deleted',
  })
}

export function changeAutomationStatus(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/automation-rules/${id}/active`,
    method: 'PUT',
    label: CHANGE_AUTOMATION_STATUS,
    data,
    showToast: true,
    successMessage: 'Status Updated',
  })
}
