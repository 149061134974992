import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {VmoButton, VmoFormSelect, VmoInput} from 'vmo-library'

const ProjectBudgetNotifications = () => {
  return (
    <>
      <div className="project-finance-budget-expense-action">
        <VmoButton primary onClick={() => {}}>
          Add Notification
        </VmoButton>
      </div>

      <div className="d-flex-js ml-5">
        Notify <span className="dummyAvtar">SK</span> <SvgIcon path="common/plus" className="mx-1" /> via{' '}
        <VmoFormSelect
          style={{width: '120px'}}
          fluid
          className="mx-2 mb-0"
          disabled
          defaultValue="email"
          options={[
            {key: 'email', value: 'email', text: 'email'},
            {key: 'mobilePush', value: 'mobilePush', text: 'mobile push'},
          ]}
        />
        when budget exceeds{' '}
        <VmoInput className="ml-2" style={{width: '60px'}} label="%" labelPosition="right" value={80} disabled />
        <span className="ml-5">
          <SvgIcon path="common/delete" />
        </span>
      </div>
    </>
  )
}

export default ProjectBudgetNotifications
