import {apiAction} from './api'
import {KADENCE_TIMEOFF} from './endpoint'
import {
  CREATE_TIMEOFF_TYPES,
  GET_TIMEOFF_TYPES,
  DELETE_TIMEOFF_TYPE,
  GET_SINGLE_TIMEOFF_TYPE,
  EDIT_TIMEOFF_TYPE,
  GET_TIMEOFF_EMPLOYEE_LIST,
  CREATE_TIMEOFF,
  GET_ALL_TIMEOFF,
  DELETE_TIMEOFF,
  GET_SINGLE_TIMEOFF,
  UPDATE_TIMEOFF,
} from './types'

export function createTimeOffTypes(data) {
  return apiAction({
    url: `${KADENCE_TIMEOFF}/type`,
    method: 'POST',
    label: CREATE_TIMEOFF_TYPES,
    data,
    showToast: true,
    successMessage: 'Type Added',
  })
}

export function getTimeOffTypes() {
  return apiAction({
    url: `${KADENCE_TIMEOFF}/types`,
    method: 'GET',
    label: GET_TIMEOFF_TYPES,
  })
}

export function deleteTimeOffTypes(id) {
  return apiAction({
    url: `${KADENCE_TIMEOFF}/type/${id}`,
    method: 'DELETE',
    label: DELETE_TIMEOFF_TYPE,
    showToast: true,
    successMessage: 'Type Deleted',
  })
}

export function getSingleTimeOffTypes(id) {
  return apiAction({
    url: `${KADENCE_TIMEOFF}/type/${id}`,
    method: 'GET',
    label: GET_SINGLE_TIMEOFF_TYPE,
  })
}

export function editTimeOffTypes(id, data) {
  return apiAction({
    url: `${KADENCE_TIMEOFF}/type/${id}`,
    method: 'PUT',
    label: EDIT_TIMEOFF_TYPE,
    data,
    showToast: true,
    successMessage: 'Type Updated',
  })
}

export function getTimeOffEmployeeList() {
  return apiAction({
    url: `${KADENCE_TIMEOFF}/employee-list`,
    method: 'GET',
    label: GET_TIMEOFF_EMPLOYEE_LIST,
  })
}

export function createTimeOff(data) {
  return apiAction({
    url: `${KADENCE_TIMEOFF}`,
    method: 'POST',
    label: CREATE_TIMEOFF,
    data,
    showToast: true,
    successMessage: 'TimeOff Added',
  })
}

export function getAllTimeOff(params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params || {})
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }
  return apiAction({
    url: KADENCE_TIMEOFF + query,
    method: 'GET',
    label: GET_ALL_TIMEOFF,
  })
}

export function deleteTimeOff(id) {
  return apiAction({
    url: `${KADENCE_TIMEOFF}/${id}`,
    method: 'DELETE',
    label: DELETE_TIMEOFF,
    showToast: true,
    successMessage: 'TimeOff Deleted',
  })
}

export function getSingleTimeOff(id) {
  return apiAction({
    url: `${KADENCE_TIMEOFF}/${id}`,
    method: 'GET',
    label: GET_SINGLE_TIMEOFF,
  })
}

export function updateTimeOff(id, data) {
  return apiAction({
    url: `${KADENCE_TIMEOFF}/${id}`,
    method: 'PUT',
    label: UPDATE_TIMEOFF,
    data,
    showToast: true,
    successMessage: 'TimeOff Updated',
  })
}
