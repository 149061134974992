import React, {useState} from 'react'
import {VmoModal, VmoModalHeader, VmoModalContent, VmoDatePicker, VmoModalActions, VmoButton} from 'vmo-library'
import moment from 'moment'
import SvgIcon from 'components/common/SvgIcon'

const OverdueModal = props => {
  const {isOpen, setIsOpen, onSuccess} = props

  const [time, setTime] = useState(moment(new Date()).add('days', 5).toDate())

  return (
    <VmoModal
      centered={false}
      size="tiny"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setIsOpen(false)}
      open={isOpen}
    >
      <VmoModalHeader className="vmoModalHeader">Overdue Time</VmoModalHeader>
      <VmoModalContent>
        <VmoDatePicker
          value={time}
          inputProps={{
            component: props => <input {...props} readOnly />,
          }}
          onChange={value => {
            setTime(value)
          }}
          time={false}
        />
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsOpen(false)}>
          Cancel
        </VmoButton>
        <VmoButton
          primary
          onClick={() => {
            onSuccess(time)
            setIsOpen(false)
          }}
        >
          Update
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}
export default OverdueModal
