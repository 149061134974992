/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoContainer,
  VmoGrid,
  VmoGridColumn,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {DELETE_PRODUCTS_CATEGORY} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {deleteProductsCategory, getProductCategory} from 'actions/productLibrary'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import CategoryModal from './CategoryModal'

function ProductsDefault() {
  const dispatch = useDispatch()

  const [categoryModal, setCategoryModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [categoryId, setCategoryId] = useState()
  const {categoryList = []} = useSelector(state => state.productLibrary)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {productLibraryPermissions} = useUserPermissions()
  const manageProductSettingsPermissions = productLibraryPermissions?.manage_product_settings

  useEffect(() => {
    dispatch(getProductCategory())
  }, [])

  useEffect(() => {
    if (successLabels.includes(DELETE_PRODUCTS_CATEGORY)) {
      dispatch(getProductCategory())
    }
  }, [successLabels])

  return (
    <>
      <VmoContainer fluid>
        <div className="pageHeader px-0">
          <div className="pageTitle">
            <h5>Category</h5>
          </div>
          <div className="pageAction">
            <VmoTooltip
              size="mini"
              position="bottom center"
              content={
                manageProductSettingsPermissions
                  ? 'Add new Category'
                  : getPermissionTooltipContent({roleAccess: manageProductSettingsPermissions})
              }
              trigger={
                <VmoButton
                  floated="right"
                  content="Add New"
                  onClick={() => manageProductSettingsPermissions && setCategoryModal(true)}
                  className={!manageProductSettingsPermissions ? 'disabled-button' : ''}
                  primary
                />
              }
            />
          </div>
        </div>
        <div className="tableScroll" style={{height: 'max-content'}}>
          <VmoGrid className="m-0">
            <VmoGridColumn width={8} className="p-0">
              <VmoTable sortable basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Action</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {categoryList.map(({category, id}, index) => (
                    <VmoTableRow key={id}>
                      <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
                      <VmoTableCell>
                        <VmoHeader as="h5">{startCase(category)}</VmoHeader>
                      </VmoTableCell>
                      <VmoTableCell>
                        <VmoTooltip
                          content={
                            manageProductSettingsPermissions
                              ? 'Delete'
                              : getPermissionTooltipContent({roleAccess: manageProductSettingsPermissions})
                          }
                          size="mini"
                          position="top center"
                          trigger={
                            <VmoButton
                              icon
                              onClick={e => {
                                e.stopPropagation()
                                if (manageProductSettingsPermissions) {
                                  setCategoryId(id)
                                  setDeleteModal(true)
                                }
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </VmoButton>
                          }
                        />
                      </VmoTableCell>
                    </VmoTableRow>
                  ))}
                </VmoTableBody>
              </VmoTable>
            </VmoGridColumn>
          </VmoGrid>
        </div>
      </VmoContainer>

      {categoryModal && <CategoryModal open={categoryModal} toggle={() => setCategoryModal(false)} />}

      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete this category?"
          deleteAction={deleteProductsCategory}
          idTobeDeleted={categoryId}
          type={DELETE_PRODUCTS_CATEGORY}
        />
      )}
    </>
  )
}

export default ProductsDefault
