import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveHeatMap} from '@nivo/heatmap'
import _ from 'lodash'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {CHANNEL_DISTRIBUTION, INBOX_ACTIVITIES, INFLOW_BY_CHANNEL_ACROSS_TIME} from 'actions/types'
import {channelDistribution, inboxActivities, inflowByChannelAcrossTime} from 'actions/analytics_channels'
import CustomLoader from 'components/common/CustomLoader'
import {get, getSortType, startCase} from 'utils/helper'
import KadenceCustomPagination from 'components/common/KadenceCustomPagination'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const Channels = () => {
  const dispatch = useDispatch()

  const [data, setData] = useState(JSON.parse(localStorage.getItem('kadence')).persistDateRangeDataToDispatch)
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
  })

  const {type = []} = useSelector(state => state.apiReducer)
  const {
    inflowByChannelAcrossTime: inflowByChannelAcrossTimeData = {},
    channelDistrbution: channelDistrbutionData = {},
  } = useSelector(state => state.analyticsChannels)
  const {dataArray = [], timeArray = []} = inflowByChannelAcrossTimeData
  const {reportsPermissions} = useUserPermissions()
  const conversationsReportsPermissions = reportsPermissions?.conversations_reports

  const {
    iaLoading: isLoadingInboxActivities,
    data: inboxActivitiesData = {},
    totalPages,
    currentPage,
  } = useApiResponse({
    label: INBOX_ACTIVITIES,
    storePath: 'analyticsChannels.inboxActivities',
  })

  useEffect(() => {
    if (!conversationsReportsPermissions) return
    const newList = _.omitBy(currentListParams, value => {
      if (value === 'none' || value === '') {
        return true
      }
      return false
    })
    dispatch(inboxActivities({...data, ...newList}))
  }, [conversationsReportsPermissions, currentListParams, dispatch, data])

  const actionsToDispatch = useCallback(
    data => {
      dispatch(inflowByChannelAcrossTime(data))
      dispatch(channelDistribution(data))
      setData(data)
    },
    [dispatch]
  )

  const handleSort = clickedColumn => () => {
    if (currentListParams.sortBy !== clickedColumn) {
      setCurrentListParams({
        ...currentListParams,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
    } else {
      setCurrentListParams({
        ...currentListParams,
        sortType: currentListParams.sortType === 'asc' ? 'desc' : 'asc',
      })
    }
  }

  const renderChannelDistributionHeader = () => {
    if (get(['headerKeys'], channelDistrbutionData)) {
      return get(['headerKeys'], channelDistrbutionData, []).map((channel, i) => (
        <VmoTableHeaderCell key={i}>{startCase(channel)}</VmoTableHeaderCell>
      ))
    }
  }

  const renderChannelDistributionBody = () => {
    if (type.includes(CHANNEL_DISTRIBUTION)) {
      return <VmoTablePlaceholder columns={6} lines={1} />
    }
    if (get(['data'], channelDistrbutionData, []).length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="6" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return get(['data'], channelDistrbutionData, []).map(channel => {
      return (
        <VmoTableRow key={channel.priority}>
          {get(['headerKeys'], channelDistrbutionData, []).map(key => {
            return <VmoTableCell key={key}>{channel[key] || 0}</VmoTableCell>
          })}
        </VmoTableRow>
      )
    })
  }

  const renderInboxActivitiesBody = () => {
    if (isLoadingInboxActivities) {
      return <VmoTablePlaceholder columns={6} lines={1} />
    }
    if (!isLoadingInboxActivities && get(['data'], inboxActivitiesData, []).length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="6" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return get(['data'], inboxActivitiesData, []).map(item => (
      <VmoTableRow key={item.id}>
        <VmoTableCell>{startCase(item.inbox)}</VmoTableCell>
        <VmoTableCell>{item.unresolved}</VmoTableCell>
        <VmoTableCell>{item.assigned}</VmoTableCell>
        <VmoTableCell>{item.resolved}</VmoTableCell>
        <VmoTableCell>{item.firstResponseTime}</VmoTableCell>
        <VmoTableCell>{item.resolvedTime}</VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="kadence-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="settings/users" />
            <h5>Channels</h5>
          </div>
        </div>
        {conversationsReportsPermissions && (
          <div className="page-action">
            <DateRangeGroup actionsToDispatch={actionsToDispatch} />
          </div>
        )}
      </div>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Inflow by channel accross time</VmoCardHeader>
              <VmoTooltip
                content="Heatmap shows the conversation flow under each channels over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
              <div className="heatMapBar" style={{margin: '15px 50px 0 auto'}}>
                Low
                <div className="value" style={{background: 'rgba(29, 137, 255, 0.4)'}} />
                <div className="value" style={{background: 'rgba(29, 137, 255, 0.55)'}} />
                <div className="value" style={{background: 'rgba(29, 137, 255, 0.7)'}} />
                <div className="value" style={{background: 'rgba(29, 137, 255, 0.85)'}} />
                <div className="value  mr-3" />
                High
              </div>
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 400}}>
              {type.includes(INFLOW_BY_CHANNEL_ACROSS_TIME) && <CustomLoader />}
              {!type.includes(INFLOW_BY_CHANNEL_ACROSS_TIME) && (
                <ResponsiveHeatMap
                  data={dataArray}
                  keys={timeArray}
                  indexBy="channel"
                  margin={{top: 10, right: 17, bottom: 55, left: 100}}
                  cellOpacity={1}
                  padding={3}
                  colors={[
                    'rgba(29, 137, 255, 0.4)',
                    'rgba(29, 137, 255, 0.55)',
                    'rgba(29, 137, 255, 0.7)',
                    'rgba(29, 137, 255, 0.85)',
                    'rgba(29, 137, 255, 1)',
                  ]}
                  labelTextColor="#ffffff"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'top',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: '',
                    legendOffset: 36,
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Channel',
                    innerHeight: 85,
                    legendPosition: 'middle',
                    legendOffset: -90,
                  }}
                  animate={false}
                  motionConfig="molasses"
                  motionStiffness={80}
                  motionDamping={9}
                  cellHoverOthersOpacity={0.5}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Channel distribution over Priorities</VmoCardHeader>
              <VmoTooltip
                content="Distribution of conversations in channel according to their priorities"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable responsive="true" basic>
                <VmoTableHeader>
                  <VmoTableRow>{renderChannelDistributionHeader()}</VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>{renderChannelDistributionBody()}</VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Inbox activities</VmoCardHeader>
              <VmoTooltip
                content="List of inboxes based on activities over time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable responsive="true" basic sortable>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell
                      sorted={currentListParams.sortBy === 'inbox' ? getSortType(currentListParams.sortType) : null}
                      onClick={handleSort('inbox')}
                    >
                      Inbox
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={
                        currentListParams.sortBy === 'unresolved' ? getSortType(currentListParams.sortType) : null
                      }
                      onClick={handleSort('unresolved')}
                    >
                      Unresolved
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={currentListParams.sortBy === 'assigned' ? getSortType(currentListParams.sortType) : null}
                      onClick={handleSort('assigned')}
                    >
                      Assigned
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={currentListParams.sortBy === 'resolved' ? getSortType(currentListParams.sortType) : null}
                      onClick={handleSort('resolved')}
                    >
                      Resolved
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={
                        currentListParams.sortBy === 'firstResponseTime'
                          ? getSortType(currentListParams.sortType)
                          : null
                      }
                      onClick={handleSort('firstResponseTime')}
                    >
                      Avg First Response Time
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={
                        currentListParams.sortBy === 'resolvedTime' ? getSortType(currentListParams.sortType) : null
                      }
                      onClick={handleSort('resolvedTime')}
                    >
                      Avg Resolution Time
                    </VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>{renderInboxActivitiesBody()}</VmoTableBody>
              </VmoTable>
              <KadenceCustomPagination
                activePage={currentPage}
                totalPages={totalPages}
                onPageChange={page =>
                  setCurrentListParams(currentListParams => ({
                    ...currentListParams,
                    page,
                  }))
                }
              />
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Channels
