import {KADENCE_ANALYTICS} from './endpoint'
import {apiAction} from './api'
import {
  QUOTES_BY_CLOSE_DEALS,
  QUOTES_BY_OPEN_DEALS,
  QUOTES_BY_OWNER,
  QUOTES_BY_PRODUCTS,
  QUOTES_OVER_TIME,
} from './types'

export function totalQuotesOvertime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/quotes/totalnumberofquotesentovertime`,
    method: 'POST',
    label: QUOTES_OVER_TIME,
    data,
  })
}
export function totalQuotesByProducts(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/quotes/totalquotebyproducts`,
    method: 'POST',
    label: QUOTES_BY_PRODUCTS,
    data,
  })
}
export function totalQuotesByOwner(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/quotes/totalquotesbyowner`,
    method: 'POST',
    label: QUOTES_BY_OWNER,
    data,
  })
}
export function totalQuotesByOpenDeals(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/quotes/totalquotesentovertimebyopendeals`,
    method: 'POST',
    label: QUOTES_BY_OPEN_DEALS,
    data,
  })
}
export function totalQuotesByCloseDeals(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/quotes/totalquotesentovertimeBycloseddeals`,
    method: 'POST',
    label: QUOTES_BY_CLOSE_DEALS,
    data,
  })
}
