/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import CreateFirst from 'components/common/CreateFirst'
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom/cjs/react-router-dom.min'
import {getPermissionTooltipContent} from 'utils/helper'
import create from 'assets/create.svg'
import useApiResponse from 'hooks/impure/useApiResponse'
import {
  deleteProjectsTemplatesTasklist,
  getProjectsTemplatesFilters,
  getProjectsTemplatesTasklist,
  getProjectsTemplatesTasklistFilters,
  getProjectsTemplatesTasklistTasks,
} from 'actions/projects'
import {
  CREATE_PROJECTS_TEMPLATES_TASKLIST,
  DELETE_PROJECTS_TEMPLATES_TASKLIST,
  GET_PROJECTS_TEMPLATES_TASKLIST,
  UPDATE_PROJECTS_TEMPLATES_TASKLIST,
} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import TemplateTasklistModal from './TemplateTasklistModal'
import TemplateTasksMenu from './TemplateTasksMenu'
import TemplateTasksDetails from './TemplateTasksDetails'

const TemplateTasks = ({rolePermissions}) => {
  const {templateId} = useParams()
  const dispatch = useDispatch()

  const [isTaskListModalOpen, setIsTaskListModalOpen] = useState(false)
  const [currentlySelectedTasklist, setCurrentlySelectedTasklist] = useState(null)
  const [tasklistId, setTasklistId] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_PROJECTS_TEMPLATES_TASKLIST})

  const {data: tasklists = [], callAction} = useApiResponse({
    action: getProjectsTemplatesTasklist,
    payload: templateId,
    dependency: [templateId],
    enabled: templateId,
    label: GET_PROJECTS_TEMPLATES_TASKLIST,
    storePath: 'projects.projectsTemplatesTasklist',
    dataToExtract: 'tasklists',
    onSuccess: projectsTemplatesTasklist => {
      const {tasklists = []} = projectsTemplatesTasklist || {}
      if (tasklists.length > 0 && !currentlySelectedTasklist) {
        setCurrentlySelectedTasklist(tasklists[0].id)
      }
    },
  })

  useEffect(() => {
    if (templateId) {
      dispatch(getProjectsTemplatesFilters({templateId, filters: ['assignedTo']}))
    }
  }, [dispatch, templateId])

  useEffect(() => {
    if (templateId && currentlySelectedTasklist) {
      dispatch(getProjectsTemplatesTasklistTasks({templateId, tasklistId: currentlySelectedTasklist}))
      dispatch(getProjectsTemplatesTasklistFilters({templateId, tasklistId: currentlySelectedTasklist}))
    }
  }, [templateId, currentlySelectedTasklist, dispatch])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_TEMPLATES_TASKLIST) ||
      successLabels.includes(UPDATE_PROJECTS_TEMPLATES_TASKLIST) ||
      successLabels.includes(DELETE_PROJECTS_TEMPLATES_TASKLIST)
    ) {
      callAction()
    }
    if (successLabels.includes(DELETE_PROJECTS_TEMPLATES_TASKLIST)) {
      setCurrentlySelectedTasklist(null)
    }
  }, [successLabels, callAction])

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECTS_TEMPLATES_TASKLIST)) {
      if (tasklistId === currentlySelectedTasklist) {
        dispatch(getProjectsTemplatesTasklistFilters({templateId, tasklistId: currentlySelectedTasklist}))
      }
    }
  }, [successLabels, tasklistId, currentlySelectedTasklist, templateId, dispatch])

  const onAddTasklistClick = useCallback(() => {
    setTasklistId(null)
    setIsTaskListModalOpen(true)
  }, [])

  const onEditTasklistClick = useCallback(id => {
    setTasklistId(id)
    setIsTaskListModalOpen(true)
  }, [])

  const onDeleteTasklistClick = useCallback(id => {
    setTasklistId(id)
    setIsDeleteModalOpen(true)
  }, [])

  const onTasklistClick = useCallback(
    id => {
      if (currentlySelectedTasklist !== id) {
        setCurrentlySelectedTasklist(id)
      }
    },
    [currentlySelectedTasklist]
  )

  if (isLoading) {
    return <PageLoader />
  }

  if (!tasklists.length) {
    return (
      <>
        {isTaskListModalOpen && (
          <TemplateTasklistModal open={isTaskListModalOpen} toggle={setIsTaskListModalOpen} templateId={templateId} />
        )}
        <CreateFirst
          header="Tasklist"
          subHeader="Task are activities created and assigned to users in specific project"
          addFirst={() => rolePermissions && setIsTaskListModalOpen(true)}
          buttonText="Add Tasklist"
          tooltip={!rolePermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: rolePermissions})}
          imageSrc={create}
          Feature1="Task creation"
          list1="Create task easily in just one click"
          Feature2="Associate workflow"
          list2="Set workflow based on priority and requirement"
          Feature3="Users assigning"
          list3="Assign users to a task for timely completion"
        />
      </>
    )
  }

  return (
    <>
      <TemplateTasksMenu
        rolePermissions={rolePermissions}
        tasklists={tasklists}
        currentlySelectedTasklist={currentlySelectedTasklist}
        onTasklistClick={onTasklistClick}
        onAddTasklistClick={onAddTasklistClick}
        onEditTasklistClick={onEditTasklistClick}
        onDeleteTasklistClick={onDeleteTasklistClick}
      />

      <TemplateTasksDetails
        rolePermissions={rolePermissions}
        templateId={templateId}
        currentlySelectedTasklist={currentlySelectedTasklist}
      />

      {isTaskListModalOpen && (
        <TemplateTasklistModal
          open={isTaskListModalOpen}
          toggle={setIsTaskListModalOpen}
          templateId={templateId}
          tasklistId={tasklistId}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectsTemplatesTasklist({templateId, tasklistId}))}
          type={DELETE_PROJECTS_TEMPLATES_TASKLIST}
        />
      )}
    </>
  )
}

export default TemplateTasks
