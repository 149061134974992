import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {
  VmoButton,
  VmoCheckbox,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {CHANGE_AUTOMATION_STATUS, DELETE_AUTOMATION, GET_AUTOMATIONS} from 'actions/types'
import {changeAutomationStatus, deleteAutomation, getAutomations} from 'actions/automations'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import AutomationTitle from 'components/pages/Automations/AutomationTitle'
import create from 'assets/create-user.svg'
import {GetFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'

const Automations = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {automationsList = []} = useSelector(state => state.automations)

  const {isLoading} = useApiLoader({label: GET_AUTOMATIONS})
  const {workflowAndAutomationAccess, workflowsPermissions} = useUserPermissions()
  const advanceWorkflowsAccess = workflowAndAutomationAccess?.advanceWorkflows
  const advanceWorkflowsLimitAccess = workflowAndAutomationAccess?.advanceWorkflowsLimit
  const manageWorkflowsPermissions = workflowsPermissions?.manage_workflows

  const isFeatureLocked = useMemo(() => {
    return (
      !advanceWorkflowsAccess || (advanceWorkflowsLimitAccess && automationsList.length >= +advanceWorkflowsLimitAccess)
    )
  }, [advanceWorkflowsAccess, advanceWorkflowsLimitAccess, automationsList.length])

  useEffect(() => {
    if (advanceWorkflowsAccess && manageWorkflowsPermissions) {
      dispatch(getAutomations())
    }
  }, [advanceWorkflowsAccess, manageWorkflowsPermissions, dispatch])

  useEffect(() => {
    if (successLabels.includes(CHANGE_AUTOMATION_STATUS) || successLabels.includes(DELETE_AUTOMATION)) {
      dispatch(getAutomations())
    }
  }, [successLabels, dispatch])

  if (isLoading && advanceWorkflowsAccess && manageWorkflowsPermissions) {
    return <PageLoader />
  }

  if (!automationsList.length || !advanceWorkflowsAccess || !manageWorkflowsPermissions) {
    return (
      <>
        {isCreateModalOpen && <AutomationTitle open={isCreateModalOpen} toggle={setIsCreateModalOpen} />}
        <CreateFirst
          header="Automations"
          subHeader="Helps to automate tasks or workflows, reducing manual intervention and improving efficiency"
          addFirst={() => advanceWorkflowsAccess && manageWorkflowsPermissions && setIsCreateModalOpen(true)}
          buttonText="Add Automation"
          tooltip={!advanceWorkflowsAccess || !manageWorkflowsPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: advanceWorkflowsAccess,
            roleAccess: manageWorkflowsPermissions,
          })}
          imageSrc={create}
          Feature1="Task Automation"
          list1="Automate routine tasks based on predefined triggers."
          Feature2="Appointment Scheduling"
          list2="Schedule appointments with your customers online"
          Feature3="Email Automation"
          list3="Set up automated email campaigns engaging with leads."
        />
      </>
    )
  }

  const renderBody = () => {
    if (type.includes(GET_AUTOMATIONS)) {
      return <VmoTablePlaceholder columns={7} row={3} />
    }
    return automationsList.map((automation, index) => (
      <VmoTableRow
        key={automation.id}
        className="tableLink"
        onClick={() =>
          history.push({
            pathname: `/automations/builder/${automation.id}`,
            state: {name: automation.name, description: ''},
          })
        }
      >
        <VmoTableCell>{index + 1}</VmoTableCell>
        <VmoTableCell>{startCase(automation.name)}</VmoTableCell>
        <VmoTableCell>{startCase(automation.entity)}</VmoTableCell>
        <VmoTableCell>{GetFormattedDate(automation.createdAt)}</VmoTableCell>
        <VmoTableCell>{automation.createdBy.firstName}</VmoTableCell>
        <VmoTableCell>
          <LockPermissionTooltip isRoleAccessDenied={!manageWorkflowsPermissions}>
            <VmoCheckbox
              toggle
              checked={automation.isActive}
              onClick={e => e.stopPropagation()}
              onChange={() => {
                if (!manageWorkflowsPermissions) return
                const data = {
                  isActive: !automation.isActive,
                }
                dispatch(changeAutomationStatus(automation.id, data))
              }}
            />
          </LockPermissionTooltip>
        </VmoTableCell>
        <VmoTableCell>
          <VmoTooltip
            size="mini"
            position="top center"
            content={
              manageWorkflowsPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: manageWorkflowsPermissions})
            }
            trigger={
              <VmoButton
                icon
                onClick={e => {
                  e.stopPropagation()
                  if (manageWorkflowsPermissions) {
                    setIdToDelete(automation.id)
                    setIsDeleteModalOpen(true)
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </VmoButton>
            }
          />
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <>
      {isCreateModalOpen && <AutomationTitle open={isCreateModalOpen} toggle={setIsCreateModalOpen} />}

      <div className="kadence-full-wrap">
        <div className="page-header">
          <div className="page-title">
            <div className="page-titleIcon">
              <SvgIcon path="settings/users" />
              <h5>
                Automations (<span>{automationsList.length}</span>)
              </h5>
            </div>
          </div>
          <div className="page-action">
            <LockPermissionTooltip
              isFeatureAccessDenied={isFeatureLocked}
              isRoleAccessDenied={!manageWorkflowsPermissions}
            >
              <VmoButton
                primary
                className={isFeatureLocked || !manageWorkflowsPermissions ? 'disabled-button' : ''}
                onClick={() => !isFeatureLocked && manageWorkflowsPermissions && setIsCreateModalOpen(true)}
              >
                Create Automation
              </VmoButton>
            </LockPermissionTooltip>
          </div>
        </div>

        <VmoTable basic selectable>
          <VmoTableHeader>
            <VmoTableRow>
              <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
              <VmoTableHeaderCell>Name</VmoTableHeaderCell>
              <VmoTableHeaderCell>Entity</VmoTableHeaderCell>
              <VmoTableHeaderCell>Created At</VmoTableHeaderCell>
              <VmoTableHeaderCell>Created By</VmoTableHeaderCell>
              <VmoTableHeaderCell>Status</VmoTableHeaderCell>
              <VmoTableHeaderCell>Action</VmoTableHeaderCell>
            </VmoTableRow>
          </VmoTableHeader>
          <VmoTableBody>{renderBody()}</VmoTableBody>
        </VmoTable>
      </div>

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          idTobeDeleted={idToDelete}
          deleteAction={deleteAutomation}
          type={DELETE_AUTOMATION}
        />
      )}
    </>
  )
}

export default Automations
