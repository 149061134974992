import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import React, {useEffect, useState} from 'react'
import {VmoButton} from 'vmo-library'
import {useDispatch, useSelector} from 'react-redux'
import {
  CREATE_PROJECTS_TEMPLATES_TASKLIST_TASK,
  CREATE_PROJECTS_TEMPLATES_TASKLIST_STAGE,
  DELETE_PROJECTS_TEMPLATES_TASKLIST_TASK,
  GET_PROJECTS_TEMPLATES_TASKLIST_TASKS,
  UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK,
  DELETE_PROJECTS_TEMPLATES_TASKLIST_STAGE,
  UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK_STAGE,
  MOVE_PROJECTS_TEMPLATES_TASKLIST_STAGE,
} from 'actions/types'
import {getProjectsTemplatesTasklistTasks} from 'actions/projects'
import useApiResponse from 'hooks/impure/useApiResponse'
import TemplateTaskModal from './TemplateTaskModal'
import TemplateTasksBoardView from './TemplateTasksBoardView'

const TemplateTasksDetails = ({rolePermissions, templateId, currentlySelectedTasklist}) => {
  const dispatch = useDispatch()
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false)
  const [kanBoardData, setKanBoardData] = useState({
    lanes: [],
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)

  useApiResponse({
    label: GET_PROJECTS_TEMPLATES_TASKLIST_TASKS,
    storePath: 'projects.projectsTemplatesTasklistTasks',
    onSuccess: projectsTemplatesTasklistTasks => {
      const boardData = {
        lanes: Object.values(projectsTemplatesTasklistTasks).map(task => {
          return {
            id: task.id,
            title: task.name,
            label: task.tasks.length,
            laneId: task.id,
            color: task.color,
            disallowAddingCard: true,
            cards: task.tasks.map(task => {
              return {
                id: task.id,
                title: task.title,
                metadata: {
                  endDate: task.endDate,
                  labels: task.labelDetails,
                  progressRate: task.progressRate,
                  assigneeDetails: task.assigneeDetails,
                  stageId: task.stageId,
                  status: task.status,
                  isCompleted: task.isCompleted,
                  ...(task.estimatedHrs || task.estimatedMin
                    ? {
                        estimateTime: `${task.estimatedHrs || 0}h ${task.estimatedMin || 0}m`,
                      }
                    : {}),
                  ...(task.actualHrs || task.actualMin
                    ? {
                        actualTime: `${task.actualHrs || 0}h ${task.actualMin || 0}m`,
                      }
                    : {}),
                },
              }
            }),
          }
        }),
      }
      setKanBoardData(boardData)
    },
  })

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_TEMPLATES_TASKLIST_TASK) ||
      successLabels.includes(UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK) ||
      successLabels.includes(DELETE_PROJECTS_TEMPLATES_TASKLIST_TASK) ||
      successLabels.includes(CREATE_PROJECTS_TEMPLATES_TASKLIST_STAGE) ||
      successLabels.includes(DELETE_PROJECTS_TEMPLATES_TASKLIST_STAGE) ||
      successLabels.includes(UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK_STAGE) ||
      successLabels.includes(MOVE_PROJECTS_TEMPLATES_TASKLIST_STAGE)
    ) {
      dispatch(getProjectsTemplatesTasklistTasks({templateId, tasklistId: currentlySelectedTasklist}))
    }
  }, [successLabels, dispatch, templateId, currentlySelectedTasklist])

  return (
    <>
      <div className="project-discussion-side-wrap">
        <div className="project-discussion-head">
          <div className="d-flex project-discussion-head-left">{/*  */}</div>
          <div className="d-flex project-discussion-head-action">
            <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
              <VmoButton
                primary
                className={!rolePermissions ? 'disabled-button' : ''}
                onClick={() => rolePermissions && setIsAddTaskModalOpen(true)}
              >
                Add Task
              </VmoButton>
            </LockPermissionTooltip>
          </div>
        </div>
        <TemplateTasksBoardView
          kanBoardData={kanBoardData}
          templateId={templateId}
          currentlySelectedTasklist={currentlySelectedTasklist}
          rolePermissions={rolePermissions}
        />
      </div>
      {isAddTaskModalOpen && (
        <TemplateTaskModal
          open={isAddTaskModalOpen}
          toggle={setIsAddTaskModalOpen}
          templateId={templateId}
          currentlySelectedTasklist={currentlySelectedTasklist}
        />
      )}
    </>
  )
}

export default TemplateTasksDetails
