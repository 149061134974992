import {apiAction} from './api'
import {KADENCE_SALES_ORDERS} from './endpoint'
import {
  CREATE_SALES_ORDERS_PREFIX,
  GET_SALES_ORDERS_PREFIX,
  DELETE_SALES_ORDERS_PREFIX,
  GET_NEW_SO_NUMBER,
  CREATE_SALES_ORDERS,
  GET_SALES_ORDERS_LIST,
  DELETE_SALES_ORDERS,
  GET_SALES_ORDERS_DATA,
  UPDATE_SALES_ORDERS,
  COPY_SALES_ORDERS,
  DELETE_SO_LOGO,
} from './types'

export function createSalesOrdersPrefix(data) {
  return apiAction({
    url: `${KADENCE_SALES_ORDERS}/prefix`,
    method: 'POST',
    label: CREATE_SALES_ORDERS_PREFIX,
    data,
    showToast: true,
    successMessage: 'Prefix Added',
  })
}

export function getNewSoNumber(id) {
  return apiAction({
    url: `${KADENCE_SALES_ORDERS}/number/${id}`,
    method: 'GET',
    label: GET_NEW_SO_NUMBER,
  })
}

export function getSalesOrdersPrefixes() {
  return apiAction({
    url: `${KADENCE_SALES_ORDERS}/prefixes`,
    method: 'GET',
    label: GET_SALES_ORDERS_PREFIX,
  })
}

export function deleteSalesOrdersPrefix(id) {
  return apiAction({
    url: `${KADENCE_SALES_ORDERS}/prefix/${id}`,
    method: 'DELETE',
    label: DELETE_SALES_ORDERS_PREFIX,
    showToast: true,
    successMessage: 'Prefix Deleted',
  })
}

export function createSalesOrders(data) {
  return apiAction({
    url: `${KADENCE_SALES_ORDERS}`,
    method: 'POST',
    label: CREATE_SALES_ORDERS,
    data,
    showToast: true,
    successMessage: 'Sales Order Added',
  })
}

export function getSalesOrderslist(params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params || {})
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }

  return apiAction({
    url: KADENCE_SALES_ORDERS + query,
    method: 'GET',
    label: GET_SALES_ORDERS_LIST,
  })
}

export function deleteSalesOrders(id) {
  return apiAction({
    url: `${KADENCE_SALES_ORDERS}/${id}`,
    method: 'DELETE',
    label: DELETE_SALES_ORDERS,
    showToast: true,
    successMessage: 'Sales Order Deleted',
  })
}

export function getSalesOrdersData(id) {
  return apiAction({
    url: `${KADENCE_SALES_ORDERS}/${id}`,
    method: 'GET',
    label: GET_SALES_ORDERS_DATA,
  })
}

export function updateSalesOrders(id, data) {
  return apiAction({
    url: `${KADENCE_SALES_ORDERS}/${id}`,
    method: 'PUT',
    label: UPDATE_SALES_ORDERS,
    data,
    showToast: true,
    successMessage: 'Sales Order Updated',
  })
}

export function copySalesOrder(id) {
  return apiAction({
    url: `${KADENCE_SALES_ORDERS}/clone/${id}`,
    method: 'PUT',
    label: COPY_SALES_ORDERS,
    showToast: true,
    successMessage: 'Sales Order Cloned',
  })
}

export function deleteSoLogo(id) {
  return apiAction({
    url: `${KADENCE_SALES_ORDERS}logo/${id}`,
    method: 'DELETE',
    label: DELETE_SO_LOGO,
    showToast: true,
    successMessage: 'Logo Updated',
  })
}
