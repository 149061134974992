import React from 'react'
import Helmet from 'react-helmet'

const DocumentTitle = props => {
  const kadenceTitle = 'Kadence'

  const {
    title,
    contacts = false,
    companies = false,
    inbox = false,
    deals = false,
    campaign = false,
    knowledgebase = false,
    reports = false,
    dashboards = false,
    settings = false,
    clientPortal = false,
    products = false,
    projects = false,
    invoices = false,
    salesOrders = false,
    purchaseOrders = false,
    vendor = false,
    purchases = false,
  } = props

  const documentTitle = () => {
    if (title) {
      if (contacts) {
        return `${title} | Contacts - ${kadenceTitle}`
      }
      if (companies) {
        return `${title} | Companies - ${kadenceTitle}`
      }
      if (inbox) {
        return `${title} | Inbox - ${kadenceTitle}`
      }
      if (deals) {
        return `${title} | Deals - ${kadenceTitle}`
      }
      if (campaign) {
        return `${title} | Campaign - ${kadenceTitle}`
      }
      if (knowledgebase) {
        return `${title} | Knowledgebase - ${kadenceTitle}`
      }
      if (reports) {
        return `${title} | Reports - ${kadenceTitle}`
      }
      if (dashboards) {
        return `${title} | Dashboards - ${kadenceTitle}`
      }
      if (settings) {
        return `${title} | Settings - ${kadenceTitle}`
      }
      if (clientPortal) {
        return `${title} | Client Portal - ${kadenceTitle}`
      }
      if (products) {
        return `${title} | Products - ${kadenceTitle}`
      }
      if (projects) {
        return `${title} | Projects - ${kadenceTitle}`
      }
      if (invoices) {
        return `${title} | Invoices - ${kadenceTitle}`
      }
      if (salesOrders) {
        return `${title} | Sales Orders - ${kadenceTitle}`
      }
      if (purchaseOrders) {
        return `${title} | Purchase Orders - ${kadenceTitle}`
      }
      if (vendor) {
        return `${title} | Vendor - ${kadenceTitle}`
      }
      if (purchases) {
        return `${title} | Purchases - ${kadenceTitle}`
      }
      return `${title} - ${kadenceTitle}`
    }
    return kadenceTitle
  }

  return (
    <Helmet>
      <title>{documentTitle()}</title>
    </Helmet>
  )
}

export default DocumentTitle
