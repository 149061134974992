import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {
  VmoButton,
  VmoDatePicker,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoRadio,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
} from 'vmo-library'
import {getProjectsTemplatesFinanceSingleRate, updateProjectsTemplatesFinanceSingleRate} from 'actions/projects'
import {GET_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE} from 'actions/types'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getDecimalNumberInput, getFormattedDate, removeDoubleQuotes} from 'utils/helper'

const TemplateFinanceRateModal = ({open, toggle, templateId, budgetId, userId}) => {
  const dispatch = useDispatch()

  const [projectRateFrom, setProjectRateFrom] = useState('today')
  const [fromNewDate, setFromNewDate] = useState(new Date())

  const {projectData = {}, rateData = {}} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        billableRate: Joi.number().min(1).required().messages({
          'number.min': 'Please enter a value greater than 0',
        }),
        costRate: Joi.number().min(1).required().messages({
          'number.min': 'Please enter a value greater than 0',
        }),
      })
    ),
    defaultValues: {
      billableRate: 0,
      costRate: 0,
    },
  })

  useApiResponse({
    action: getProjectsTemplatesFinanceSingleRate,
    payload: {templateId, budgetId, userId},
    dependency: [templateId, budgetId, userId],
    enabled: templateId && budgetId && userId,
    label: GET_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE,
    storePath: 'projects.projectsTemplatesFinanceRateData',
    onSuccess: projectsTemplatesFinanceRateData => {
      const data = {
        billableRate: projectsTemplatesFinanceRateData?.billableRate ?? 0,
        costRate: projectsTemplatesFinanceRateData?.costRate ?? 0,
      }
      reset(data)
    },
  })

  const submitForm = data => {
    const payload = {...data}
    if (projectRateFrom === 'today') {
      payload.fromDate = new Date()
    } else if (projectRateFrom === 'newDate') {
      payload.fromDate = fromNewDate()
    } else {
      payload.fromDate = new Date(projectData.createdAt)
    }
    dispatch(updateProjectsTemplatesFinanceSingleRate({templateId, budgetId, userId, data: payload}))
  }

  const renderTableBody = () => {
    return (
      <VmoTableRow className="tableLink">
        <VmoTableCell>${rateData.billableRate || 0}</VmoTableCell>
        <VmoTableCell>${rateData.costRate || 0}</VmoTableCell>
        <VmoTableCell>{rateData.fromDate ? getFormattedDate(rateData.fromDate) : '-'}</VmoTableCell>
        <VmoTableCell>
          <div>{rateData.updateBy?.name}</div>
          <div>{rateData.updatedAt ? getFormattedDate(rateData.updatedAt) : '-'}</div>
        </VmoTableCell>
      </VmoTableRow>
    )
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>
        Billable rate for project <strong>{projectData.title}</strong>
      </VmoModalHeader>
      <VmoModalContent>
        <h3>{rateData.user?.name}</h3>
        <VmoForm className="errorLabel">
          <VmoFormField required width={8}>
            <label>Billable rate/h</label>
            <Controller
              name="billableRate"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  type="number"
                  value={value}
                  onChange={(e, {value}) => onChange(getDecimalNumberInput(value))}
                  error={errors.billableRate && {content: removeDoubleQuotes(errors.billableRate.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required width={8}>
            <label>Cost rate/h</label>
            <Controller
              name="costRate"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  type="number"
                  value={value}
                  onChange={(e, {value}) => onChange(getDecimalNumberInput(value))}
                  error={errors.costRate && {content: removeDoubleQuotes(errors.costRate.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField>
            <label>Apply project rate:</label>
            <div>
              <VmoRadio
                label="from today"
                checked={projectRateFrom === 'today'}
                onChange={() => setProjectRateFrom('today')}
              />
            </div>
            <VmoFormGroup inline>
              <VmoRadio checked={projectRateFrom === 'newDate'} onChange={() => setProjectRateFrom('newDate')} />
              <label className="ml-2">from</label>
              <VmoDatePicker
                disabled={projectRateFrom !== 'newDate'}
                time={false}
                value={fromNewDate}
                onChange={value => setFromNewDate(value)}
                inputProps={{readOnly: true}}
              />
            </VmoFormGroup>
            <VmoRadio
              checked={projectRateFrom === 'projectStartDate'}
              onChange={() => setProjectRateFrom('projectStartDate')}
              label={`for project duration (${getFormattedDate(projectData.createdAt)}-onwards)`}
            />
          </VmoFormField>
        </VmoForm>

        <h3>Effective rate</h3>
        <VmoTable basic>
          <VmoTableHeader>
            <VmoTableRow>
              <VmoTableHeaderCell>Billable Rate/H</VmoTableHeaderCell>
              <VmoTableHeaderCell>Cost Rate/H</VmoTableHeaderCell>
              <VmoTableHeaderCell>Rate Applied</VmoTableHeaderCell>
              <VmoTableHeaderCell>Last Updated</VmoTableHeaderCell>
            </VmoTableRow>
          </VmoTableHeader>
          <VmoTableBody>{renderTableBody()}</VmoTableBody>
        </VmoTable>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          Save
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default TemplateFinanceRateModal
