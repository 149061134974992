/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoCheckbox,
  VmoDatePicker,
  VmoDropdown,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoGrid,
  VmoGridColumn,
} from 'vmo-library'

import {ADD_COMPANY_INTERACTION, ADD_CONTACT_INTERACTION, ADD_DEAL_INTERACTION, ADD_INTERACTION} from 'actions/types'
import {durationTime, InteractionList, outCome, taskTypes} from 'constants/variables'
import {logInteractionSchema} from 'validation/common.schema'
import LockPermissionTooltip from './LockPermissionTooltip'

const LogInteraction = props => {
  const {id, addLogInteration, rolePermissions} = props
  const dispatch = useDispatch()
  const [outComeList, setOutComeList] = useState(outCome)

  const initialLogInteraction = {
    category: 'call',
    interactionTime: null,
    title: '',
    description: '',
    duration: null,
    outcome: outComeList[0].value,
    isFollowUp: false,
    followUpType: null,
    followUpTime: null,
  }
  const {handleSubmit, errors, control, reset, watch, setValue} = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(logInteractionSchema),
    defaultValues: initialLogInteraction,
  })

  const watchList = watch('category')
  useEffect(() => {
    const temp = outCome.filter(({type}) =>
      watchList === 'meeting' || watchList === 'demo'
        ? type === 'meeting'
        : watchList === 'event'
        ? type === 'event'
        : type === 'call'
    )
    setValue('outcome', temp[0].value)
    setOutComeList(temp)
  }, [watchList])

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const [logInteraction, setLogInteraction] = useState(false)

  const LogInteractionBtn = data => {
    if (id && addLogInteration) {
      dispatch(addLogInteration(id, data))
    }
  }
  useEffect(() => {
    if (
      successLabels.includes(ADD_COMPANY_INTERACTION) ||
      successLabels.includes(ADD_CONTACT_INTERACTION) ||
      successLabels.includes(ADD_INTERACTION) ||
      successLabels.includes(ADD_DEAL_INTERACTION)
    ) {
      setLogInteraction(false)
      reset(initialLogInteraction)
    }
  }, [successLabels, reset, initialLogInteraction])

  return (
    <div className="addActivityTimeline">
      <VmoForm className="errorLabel">
        <VmoFormGroup widths="equal" className="px-3">
          <VmoGrid>
            <VmoGridColumn width={4}>
              <VmoFormField required>
                <label>Type</label>
                <Controller
                  control={control}
                  name="category"
                  render={({onChange, value}) => (
                    <VmoFormSelect
                      placeholder="Type"
                      selection
                      fluid
                      options={InteractionList}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.category && {
                          content: errors.category.message,
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
            </VmoGridColumn>

            <VmoGridColumn width={7}>
              <VmoFormField required>
                <label>Date & Time</label>
                <Controller
                  control={control}
                  name="interactionTime"
                  render={({onChange, value}) => (
                    <VmoDatePicker
                      placeholder="Date & Time"
                      inputProps={{
                        component: props => <input {...props} readOnly />,
                      }}
                      onChange={value => {
                        onChange(value)
                      }}
                      value={value}
                      max={new Date()}
                      time
                      error={
                        errors.interactionTime && {
                          content: errors.interactionTime.message,
                        }
                      }
                    />
                  )}
                />
                {errors.interactionTime && (
                  <div style={{color: '#9F3A38', fontSize: '12px'}}>{errors.interactionTime.message}</div>
                )}
              </VmoFormField>
            </VmoGridColumn>

            <VmoGridColumn width={4}>
              <VmoFormField>
                <label>Duration</label>
                <Controller
                  control={control}
                  name="duration"
                  render={({onChange, value}) => (
                    <VmoDropdown
                      fluid
                      selection
                      clearable
                      options={durationTime}
                      placeholder="1 hour"
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
              </VmoFormField>
            </VmoGridColumn>

            <VmoGridColumn width={5}>
              <VmoFormField>
                <label>Outcome</label>
                <Controller
                  control={control}
                  name="outcome"
                  render={({onChange, value}) => (
                    <VmoDropdown
                      placeholder="Outcome"
                      selection
                      fluid
                      options={outComeList}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
              </VmoFormField>
            </VmoGridColumn>
          </VmoGrid>
        </VmoFormGroup>
        <VmoFormField required>
          <label>Title</label>
          <Controller
            control={control}
            name="title"
            render={({onChange, value}) => (
              <VmoFormInput
                maxLength={30}
                placeholder="Type title..."
                onChange={(e, {value}) => onChange(value)}
                value={value}
                error={
                  errors.title && {
                    content: errors.title.message,
                  }
                }
              />
            )}
          />
        </VmoFormField>
        <VmoFormField >
          <label>Description</label>
          <Controller
            control={control}
            name="description"
            render={({onChange, value}) => (
              <VmoFormTextArea
              maxLength={500}
                placeholder="Type Description..."
                style={{resize: 'none'}}
                value={value}
                onChange={(e, {value}) => onChange(value)}
              />
            )}
          />
        </VmoFormField>
      </VmoForm>
      <div className="mt-3">
        <Controller
          control={control}
          name="isFollowUp"
          render={({onChange, value}) => (
            <VmoCheckbox
              label="Schedule a follow-up"
              onChange={(e, {checked}) => {
                setLogInteraction(!logInteraction)
                onChange(checked)
              }}
              checked={value}
            />
          )}
        />
        {logInteraction && (
          <VmoForm>
            <VmoFormGroup widths="equal">
              <VmoFormField className="mt-2">
                <label>Follow Up Type</label>
                <Controller
                  control={control}
                  name="followUpType"
                  render={({onChange, value}) => (
                    <VmoDropdown
                      placeholder="Type"
                      selection
                      fluid
                      options={taskTypes}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      className="mt-2"
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField className="mt-2">
                <label>Follow Up Time</label>
                <Controller
                  control={control}
                  name="followUpTime"
                  render={({onChange, value}) => (
                    <VmoDatePicker
                      placeholder="After"
                      onChange={value => {
                        onChange(value)
                      }}
                      value={value}
                      min={new Date()}
                      className="mt-2"
                      inputProps={{
                        component: props => <input {...props} readOnly />,
                      }}
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>
          </VmoForm>
        )}
      </div>
      <div className="mt-2 mb-4" style={{float: 'right'}}>
        <VmoButton
          basic
          onClick={() => {
            setLogInteraction(false)
            reset()
          }}
        >
          Reset
        </VmoButton>
        <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
          <VmoButton
            className={`ml-2 ${!rolePermissions ? 'disabled-button' : ''}`}
            primary
            onClick={() => rolePermissions && handleSubmit(LogInteractionBtn)}
          >
            Save
          </VmoButton>
        </LockPermissionTooltip>
      </div>
    </div>
  )
}

export default LogInteraction
