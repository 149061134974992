import React from 'react'
import {useDispatch} from 'react-redux'
import moment from 'moment'
import {VmoButton, VmoDatePicker, VmoModal, VmoModalActions, VmoModalContent, VmoModalHeader} from 'vmo-library'
import {snoozeConversation} from 'actions/inbox'
import SvgIcon from 'components/common/SvgIcon'

const SnoozeConversationModal = ({
  timePicker,
  setTimePicker,
  snoozeDatePickerTime,
  setSnoozeDatePickerTime,
  ConversationId,
}) => {
  const dispatch = useDispatch()

  const date = moment(new Date())
  const roundUp =
    date.minute() || date.second() || date.millisecond() ? date.add(1, 'hour').startOf('hour') : date.startOf('hour')

  const snoozeMail = time => {
    const data = {
      ts: time,
      isSnoozed: true,
    }
    dispatch(snoozeConversation(data, ConversationId))
  }

  return (
    <VmoModal
      centered={false}
      size="mini"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setTimePicker(false)}
      open={timePicker}
    >
      <VmoModalHeader className="vmoModalHeader">Snooze Date and Time</VmoModalHeader>
      <VmoModalContent>
        <VmoDatePicker
          defaultValue={new Date()}
          min={roundUp.toDate()}
          inputProps={{
            component: props => <input {...props} readOnly />,
          }}
          onChange={value => {
            setSnoozeDatePickerTime(value)
          }}
        />
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setTimePicker(!timePicker)}>
          Cancel
        </VmoButton>
        <VmoButton
          color="primary"
          onClick={() => {
            snoozeMail(snoozeDatePickerTime)
            setTimePicker(false)
          }}
        >
          Snooze
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default SnoozeConversationModal
