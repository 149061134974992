import Joi from 'joi'

export const slaSchema = Joi.object({
  targets: Joi.array().items(
    Joi.object().keys({
      priority: Joi.string().required(),
      response: {
        value: Joi.number().required(),
        unit: Joi.string().required(),
      },
      resolve: {
        value: Joi.number().required(),
        unit: Joi.string().required(),
      },
    })
  ),
  isEscalate: Joi.boolean().required(),
  escalateTo: Joi.array().max(3),
})
