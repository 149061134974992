import DocumentTitle from 'components/common/DocumentTitle'
import * as React from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route, useLocation} from 'react-router-dom'
import {get} from 'utils/helper'
import {getAppToken} from 'utils/local-storage'

export default function ProtectedRoute({title, path, component: Component, exact = false, render, ...rest}) {
  const location = useLocation()

  const isLoggedOut = useSelector(state => {
    return get(['appReducer', 'isLoggedOut'], state)
  })
  const isPlanExpired = useSelector(state => get(['createUserAcct', 'metadata', 'isPlanExpired'], state))

  const isAuthPath =
    ['/signin', '/signup', '/', '/thankyou'].includes(location.pathname) || location.pathname.includes('/emailverify')
  const isPlanPath = location.pathname === '/settings/my-plan'

  const shouldRedirectToSignIn = !getAppToken() || isLoggedOut
  const shouldRedirectToHome = getAppToken() && isAuthPath
  const shouldRedirectToPlan = !isPlanPath && ['canceled', 'past_due'].includes(isPlanExpired)

  const renderRouteContent = props => (
    <>
      <DocumentTitle title={title} {...rest} />
      {Component ? <Component {...props} /> : render ? render(props) : null}
    </>
  )

  const renderAppropriateRoute = props => {
    if (shouldRedirectToSignIn && !isAuthPath) {
      return (
        <Redirect
          to={{
            pathname: '/signin',
            state: {from: location},
          }}
        />
      )
    }
    if (shouldRedirectToHome) {
      return (
        <Redirect
          to={{
            pathname: '/home',
            state: {from: location},
          }}
        />
      )
    }
    if (shouldRedirectToPlan) {
      return (
        <Redirect
          to={{
            pathname: '/settings/my-plan',
            state: {from: location},
          }}
        />
      )
    }
    return renderRouteContent(props)
  }

  return <Route path={path} exact={exact} render={renderAppropriateRoute} {...rest} />
}
