import {KADENCE_ANALYTICS} from './endpoint'
import {apiAction} from './api'
import {
  TASKS_BY_STATUS,
  TASKS_BY_STATUS_OVER_TIME,
  TASKS_BY_TYPES,
  TASKS_BY_TYPES_OVER_TIME,
  TASK_BY_USERS,
} from './types'

export function taskByStatus(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/tasks/tasksbystatus`,
    method: 'POST',
    label: TASKS_BY_STATUS,
    data,
  })
}

export function taskByType(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/tasks/taskbytypes`,
    method: 'POST',
    label: TASKS_BY_TYPES,
    data,
  })
}

export function taskByTypeOverTime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/tasks/tasksbytypesovertime`,
    method: 'POST',
    label: TASKS_BY_TYPES_OVER_TIME,
    data,
  })
}

export function taskByStatusOverTime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/tasks/tasksbystatusovertime`,
    method: 'POST',
    label: TASKS_BY_STATUS_OVER_TIME,
    data,
  })
}

export function taskByUser(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/tasks/tasksbyusers`,
    method: 'POST',
    label: TASK_BY_USERS,
    data,
  })
}
