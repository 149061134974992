import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import create from 'assets/create-user.svg'
import {getProjectsList, getProjectsLookup, getProjectsTimeTrackingList} from 'actions/projects'
import CreateFirst from 'components/common/CreateFirst'
import {VmoButton, VmoMenu, VmoTooltip} from 'vmo-library'
import _ from 'lodash'
import {getUsers} from 'actions/settings'
import {
  CREATE_PROJECTS_TIMESHEET_TIME_LOG,
  DELETE_PROJECTS_TIMESHEET_TIME_LOGS,
  GET_PROJECTS_TIME_TRACKING,
  UPDATE_PROJECTS_TIMESHEET,
  UPDATE_PROJECTS_TIMESHEET_TIME_LOG,
} from 'actions/types'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {getPermissionTooltipContent} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import TimeTrackingModal from './TimeTrackingModal'
import TimeTrackingList from './TimeTrackingList'
import TimeTrackingFilter from './TimeTrackingFilter'

const TimeTracking = () => {
  const dispatch = useDispatch()

  const [isTimeTrackingModalOpen, setIsTimeTrackingModalOpen] = useState(false)
  const [currentListParams, setCurrentListParams] = useState({
    projectId: 'all',
    status: 'all',
    groupBy: 'date',
    orderBy: 'asc',
    createdBy: 'all',
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {timeTrackingList = {}} = useSelector(state => state.projects)

  const {isLoading} = useApiLoader({label: GET_PROJECTS_TIME_TRACKING})

  const {projectsAndCollaborationAccess, projectsPermissions} = useUserPermissions()
  const projectTimesheetAccess = projectsAndCollaborationAccess?.timesheet
  const manageTimesheetsPermissions = projectsPermissions?.manage_timesheets

  useEffect(() => {
    if (projectTimesheetAccess) {
      const newList = _.omitBy(currentListParams, value => value === 'all')
      dispatch(getProjectsTimeTrackingList(newList))
    }
  }, [projectTimesheetAccess, dispatch, currentListParams])

  useEffect(() => {
    if (projectTimesheetAccess) {
      dispatch(getUsers())
      dispatch(getProjectsList())
      dispatch(getProjectsLookup({lookup: ['timesheets']}))
    }
  }, [projectTimesheetAccess, dispatch])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_TIMESHEET_TIME_LOG) ||
      successLabels.includes(UPDATE_PROJECTS_TIMESHEET_TIME_LOG) ||
      successLabels.includes(DELETE_PROJECTS_TIMESHEET_TIME_LOGS) ||
      successLabels.includes(UPDATE_PROJECTS_TIMESHEET)
    ) {
      const newList = _.omitBy(currentListParams, value => value === 'all')
      dispatch(getProjectsTimeTrackingList(newList))
    }
  }, [successLabels, currentListParams, dispatch])

  const onRefresh = () => {
    const newList = _.omitBy(currentListParams, value => value === 'all')
    dispatch(getProjectsTimeTrackingList(newList))
  }

  if (isLoading && projectTimesheetAccess) {
    return <PageLoader />
  }

  if (
    !projectTimesheetAccess ||
    (!Object.keys(timeTrackingList).length &&
      currentListParams.projectId === 'all' &&
      currentListParams.status === 'all' &&
      currentListParams.createdBy === 'all')
  ) {
    return (
      <>
        {isTimeTrackingModalOpen && (
          <TimeTrackingModal open={isTimeTrackingModalOpen} toggle={setIsTimeTrackingModalOpen} />
        )}
        <CreateFirst
          header="Time Tracking"
          subHeader="Time tracking system helps to track employees activities and task related to project"
          addFirst={() => projectTimesheetAccess && manageTimesheetsPermissions && setIsTimeTrackingModalOpen(true)}
          buttonText="Add Time Tracking"
          tooltip={!projectTimesheetAccess || !manageTimesheetsPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: projectTimesheetAccess,
            roleAccess: manageTimesheetsPermissions,
          })}
          imageSrc={create}
          Feature1="Configure Timesheet Periods"
          list1="Create and set your own timesheet periods"
          Feature2="Monitor Employee"
          list2="Track and manage work hours of employees"
          Feature3="Track Project from scratch"
          list3="Analyze work hours spent in projects, tasks & activities."
        />
      </>
    )
  }

  return (
    <>
      <VmoMenu vertical secondary className="kadence-menu">
        Timesheets
      </VmoMenu>

      <div className="kadence-side-wrap" style={{height: '100%'}}>
        <div className="page-header pb-0">
          <div className="page-title">
            <div className="page-titleIcon">
              <SvgIcon path="navigation/timesheets" />
              <h5>Timesheets</h5>
            </div>
          </div>
          <div className="page-action">
            <div className="mailActivity">
              <TimeTrackingFilter currentListParams={currentListParams} setCurrentListParams={setCurrentListParams} />
              <VmoTooltip
                position="top center"
                content="Refresh"
                size="mini"
                trigger={
                  <VmoButton icon onClick={() => onRefresh()}>
                    <SvgIcon path="common/refresh" />
                  </VmoButton>
                }
              />
              <LockPermissionTooltip isRoleAccessDenied={!manageTimesheetsPermissions}>
                <VmoButton
                  primary
                  content="Add New"
                  className={!manageTimesheetsPermissions ? 'disabled-button' : ''}
                  onClick={() => manageTimesheetsPermissions && setIsTimeTrackingModalOpen(true)}
                />
              </LockPermissionTooltip>
            </div>
          </div>
        </div>
        <TimeTrackingList currentListParams={currentListParams} rolePermissions={manageTimesheetsPermissions} />
      </div>

      {isTimeTrackingModalOpen && (
        <TimeTrackingModal open={isTimeTrackingModalOpen} toggle={setIsTimeTrackingModalOpen} />
      )}
    </>
  )
}

export default TimeTracking
