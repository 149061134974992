import React from 'react'
import {AiFillLock} from 'react-icons/ai'
import {VmoTooltip} from 'vmo-library'
import {featureAccessDeniedContent} from 'constants/variables'

const LockFeature = () => {
  return <VmoTooltip content={featureAccessDeniedContent} position="top center" size="mini" trigger={<AiFillLock />} />
}

export default LockFeature
