import React from 'react'
import {useSelector} from 'react-redux'
import {VmoButton, VmoImage, VmoTooltip} from 'vmo-library'
import PropTypes from 'prop-types'
import noFlow from 'assets/no-flow.svg'

/**
 * @component
 * @example
 * <CreateFirst header='Create User' subHeader='user' addFirst={() => setCreateModal(true)} imageSrc={src} />
 */

function CreateFirst({
  header = 'Create',
  subHeader = '',
  buttonText = 'Add',
  addFirst = () => {},
  imageSrc,
  Feature1,
  list1,
  Feature2,
  list2,
  Feature3,
  list3,
  tooltip,
  tooltipContent,
  ...props
}) {
  const {isLoadingData} = useSelector(state => state.apiReducer)

  const renderButton = () => {
    if (tooltip) {
      return (
        <VmoTooltip
          content={tooltipContent}
          position="top center"
          size="mini"
          trigger={
            <VmoButton primary className="disabled-button">
              {buttonText}
            </VmoButton>
          }
        />
      )
    }
    return (
      <VmoButton primary onClick={addFirst}>
        {buttonText}
      </VmoButton>
    )
  }

  if (isLoadingData) return null
  return (
    <div className="setEmpty">
      <div className="setEmpty-header">
        <p>{header}</p>
        {props.hideButton ? null : renderButton()}
        {props.dropdown && props.dropdown}
      </div>
      <div className="setEmpty-content">
        <div className="setEmpty-img">
          <VmoImage src={noFlow} />
        </div>
        <div className="setEmpty-details">
          <p className="detail-head">{`${header.replace('Create', '')} details`}</p>
          <p className="detail-subhead">{subHeader}</p>
        </div>
        {props.hideButton ? null : renderButton()}
        <VmoButton basic className="ml-3">
          Learn More
        </VmoButton>
      </div>
      <div className="setEmpty-footer">
        <div className="footer-content">
          <p className="footer-head">{Feature1 || 'Feature 1'}</p>
          <p className="footer-subhead">
            {list1 || 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae eos magni fugit in enim'}
          </p>
        </div>
        <div className="footer-content">
          <p className="footer-head">{Feature2 || 'Feature 2'}</p>
          <p className="footer-subhead">
            {list2 || 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae eos magni fugit in enim'}
          </p>
        </div>
        <div className="footer-content">
          <p className="footer-head">{Feature3 || 'Feature 3'}</p>
          <p className="footer-subhead">
            {list3 || 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae eos magni fugit in enim'}
          </p>
        </div>
      </div>
    </div>
  )
}

CreateFirst.prototype = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  buttonText: PropTypes.string,
  addFirst: PropTypes.func.isRequired,
  imageSrc: PropTypes.element,
}

export default CreateFirst
