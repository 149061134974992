import Joi from 'joi'
import {emailValidation, inputPhoneValidation, inputStringValidation} from 'validation'

export const userFormSchema = Joi.object({
  firstName: inputStringValidation.required().label('First Name'),
  lastName: inputStringValidation.required().label('Last Name'),
  email: emailValidation.required().label('Email'),
  roleId: Joi.number().required().label('Role').messages({'number.base': `Role must be specified`}),
  phone: inputPhoneValidation.required().label('Phone No.').messages({
    'string.empty': `Phone No cannot be an empty field`,
    'any.required': `Phone No is a required field`,
    'string.max': `Phone No length must be less than or equal to 20 characters`,
  }),
  phoneCode: Joi.string().allow('').empty(null).required().label('Phone Code'),
  inboxIds: Joi.array().label('Mailboxes'),
  workPeriods: Joi.array().items(
    Joi.object().keys({
      weekday: Joi.string(),
      working_hour: Joi.number().integer().default(0),
    })
  ),
})

export const userFormEditSchema = Joi.object({
  firstName: inputStringValidation.required().label('First Name').messages({
    'string.empty': `First Name cannot be an empty field`,
    'any.required': `First Name is a required field`,
    'string.min': `First Name length must be atleast 2 characters long`,
    'string.max': `First Name length must be less than or equal to 20 characters`,
  }),
  lastName: inputStringValidation.required().label('Last Name').messages({
    'string.empty': `Last Name cannot be an empty field`,
    'any.required': `Last Name is a required field`,
    'string.min': `First Name length must be atleast 2 characters long`,
    'string.max': `Last Name length must be less than or equal to 20 characters`,
  }),
  email: emailValidation.required().label('Email').messages({
    'string.empty': `Email cannot be an empty field`,
    'any.required': `Email is a required field`,
  }),
  phoneCode: Joi.string().required().label('Phone Code'),
  roleId: Joi.number().required().label('Role'),
  phone: inputPhoneValidation.required().label('Phone No.').messages({
    'string.empty': `Phone No cannot be an empty field`,
    'any.required': `Phone No is a required field`,
    'string.max': `Phone No length must be less than or equal to 20 characters`,
  }),
  workPeriods: Joi.array().items(
    Joi.object().keys({
      weekday: Joi.string(),
      working_hour: Joi.number().integer().default(0),
    })
  ),
})
