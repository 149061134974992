import React from 'react'
import {VmoTab, VmoTabPane} from 'vmo-library'
import SettingLayout from 'layouts/settingLayout'
import ProductsDefault from './ProductsDefault'
import ProductFields from './ProductFields'
import ProductImport from './ProductImport'

const panes = [
  {
    menuItem: 'Categories',
    render: () => (
      <VmoTabPane attached={false}>
        <ProductsDefault />
      </VmoTabPane>
    ),
  },
  {
    menuItem: 'Fields',
    render: () => (
      <VmoTabPane attached={false}>
        <ProductFields />
      </VmoTabPane>
    ),
  },
  {
    menuItem: 'Import',
    render: () => (
      <VmoTabPane attached={false}>
        <ProductImport />
      </VmoTabPane>
    ),
  },
]

function ProductsSettings() {
  return (
    <SettingLayout
      header="Settings"
      subHeader="Manage all products by categorizing them and putting them under fields"
      table
      headerDivider
    >
      <VmoTab panes={panes} secondary menu={{secondary: true, pointing: true, color: 'blue'}} />
    </SettingLayout>
  )
}

export default ProductsSettings
