import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {Controller} from 'react-hook-form'
import {
  VmoButton,
  VmoDivider,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

function Rating({setToggleModal, toggleModal, activeSection, index, control, data, watch}) {
  return (
    <VmoModal
      open={toggleModal === 'rating'}
      onClose={() => setToggleModal(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      centered={false}
    >
      <VmoModalHeader className="vmoModalHeader">Edit Rating</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormGroup widths="equal">
            <VmoFormField required>
              <div className="info-header">
                <label className="label-class">Label for lowest point</label>
              </div>
              <Controller
                name={`templateSections[${activeSection}].sectionFields[${index}].maxPointLabel`}
                defaultValue={watch(`templateSections[${activeSection}].sectionFields[${index}].maxPointLabel`)}
                control={control}
                render={({value, onChange, onBlur}) => (
                  <VmoFormInput value={value} onChange={(e, {value}) => onChange(value)} onBlur={onBlur} />
                )}
              />
            </VmoFormField>
            <VmoFormField required>
              <div className="info-header">
                <label className="label-class">Label for highest point</label>
              </div>
              <Controller
                name={`templateSections[${activeSection}].sectionFields[${index}].minPointLabel`}
                defaultValue={watch(`templateSections[${activeSection}].sectionFields[${index}].minPointLabel`)}
                control={control}
                render={({value, onChange, onBlur}) => (
                  <VmoFormInput value={value} onChange={(e, {value}) => onChange(value)} onBlur={onBlur} />
                )}
              />
            </VmoFormField>
          </VmoFormGroup>

          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Number of options</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].numberOfButtons`}
              defaultValue={watch(`templateSections[${activeSection}].sectionFields[${index}].numberOfButtons`)}
              control={control}
              render={({value, onChange, onBlur}) => (
                <VmoFormInput type="number" value={value} onChange={(e, {value}) => onChange(+value)} onBlur={onBlur} />
              )}
            />
          </VmoFormField>
          <VmoDivider />
          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Question</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].question`}
              defaultValue={watch(
                `templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].question`
              )}
              control={control}
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  placeholder="Enter Question"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  onBlur={onBlur}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Input For</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].inputFor`}
              defaultValue={watch(
                `templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].inputFor`
              )}
              render={({value, onChange}) => (
                <VmoFormSelect
                  search
                  fluid
                  options={[
                    {key: 'both', value: 'both', text: 'Both'},
                    {key: 'employee', value: 'employee', text: 'Employee'},
                    {key: 'Reviewer', value: 'Reviewer', text: 'Reviewer'},
                  ]}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton onClick={() => setToggleModal(false)}>Cancel</VmoButton>
        <VmoButton primary onClick={() => setToggleModal(false)}>
          Ok
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default Rating
