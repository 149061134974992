import {apiAction} from './api'
import {KADENCE_ANALYTICS} from './endpoint'
import {
  TOTAL_REVENUE_BY_PRODUCTS,
  TOTAL_QUANTITY_SOLD_BY_PRODUCTS,
  WON_DEALS_BY_PRODUCTS,
  CURRENT_DEAL_BY_PRODUCTS,
  CONTACT_BY_PRODUCTS,
} from './types'

export function wonDealsByProducts(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/products/totalwondealsbyproducts`,
    method: 'POST',
    label: WON_DEALS_BY_PRODUCTS,
    data,
  })
}

export function totalRevenueByProducts(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/products/totalrevenuebyproducts`,
    method: 'POST',
    label: TOTAL_REVENUE_BY_PRODUCTS,
    data,
  })
}

export function totalQuantitySoldByProduct(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/products/totalquantitysoldbyproduct`,
    method: 'POST',
    label: TOTAL_QUANTITY_SOLD_BY_PRODUCTS,
    data,
  })
}

export function currentDealByProducts(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/products/currentdealbyproducts`,
    method: 'POST',
    label: CURRENT_DEAL_BY_PRODUCTS,
    data,
  })
}

export function newLeadsOrContactByProduct(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/products/newcontactbyproduct`,
    method: 'POST',
    label: CONTACT_BY_PRODUCTS,
    data,
  })
}
