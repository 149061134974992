import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  breakDownByDepartment,
  breakDownByTypes,
  employeeStats,
  leavesByDepartment,
  leavesByType,
} from 'actions/reports_timeoff'
import moment from 'moment'
import {UserDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import CustomLoader from 'components/common/CustomLoader'
import {ResponsivePie} from '@nivo/pie'
import {BREAKDOWN_BY_DEPARTMENT, BREAKDOWN_BY_TYPES, LEAVES_BY_DEPARTMENT, LEAVES_BY_TYPE} from 'actions/types'
import NoDataChart from 'components/common/NoDataChart'
import {ResponsiveBar} from '@nivo/bar'
import {get, startCase} from 'utils/helper'
import {getGridYValuesBar, maxOfGraphBar} from 'utils/graph'
import {getDepartment} from 'actions/employees'
import {getTimeOffTypes} from 'actions/timeOff'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

function TimeOff() {
  const dispatch = useDispatch()

  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  const {type = []} = useSelector(state => state.apiReducer)
  const {
    breakdownByTypesData = [],
    breakdownByDepartmentData = [],
    LeavesByDepartmentData = [],
    LeavesByTypeData = [],
    EmployeeStatsData,
  } = useSelector(state => state.reportsTimeOff)

  const diff = moment(get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem('kadence')))).diff(
    moment(get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem('kadence')))),
    'days'
  )

  const {data: getDepartmentData = []} = useApiResponse({
    action: getDepartment,
    enabled: true,
    storePath: 'employees.getDepartmentData',
  })

  const {data: getTimeOffTypesData = []} = useApiResponse({
    action: getTimeOffTypes,
    enabled: true,
    storePath: 'timeOff.getTimeOffTypesData',
  })

  const actionsToDispatch = useCallback(
    data => {
      dispatch(breakDownByTypes(data))
      dispatch(breakDownByDepartment(data))
      dispatch(leavesByDepartment(data))
      dispatch(leavesByType(data))
      dispatch(employeeStats(data))
    },
    [dispatch]
  )

  const renderLeavesByEmployeeHeader = () => {
    if (get(['headerKeys'], EmployeeStatsData)) {
      return get(['headerKeys'], EmployeeStatsData, []).map((types, i) => (
        <VmoTableHeaderCell key={i}>{startCase(types)}</VmoTableHeaderCell>
      ))
    }
  }

  const renderLeavesByEmployeeBody = () => {
    if (get(['data'], EmployeeStatsData, []).length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="6" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return get(['data'], EmployeeStatsData, []).map(Type => {
      return (
        <VmoTableRow key={Type.name}>
          {get(['headerKeys'], EmployeeStatsData, []).map(key => {
            return <VmoTableCell key={key}>{startCase(Type[key] || 0)}</VmoTableCell>
          })}
        </VmoTableRow>
      )
    })
  }

  return (
    <div className="kadence-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="settings/users" />
            <h5>TimeOff Analytics</h5>
          </div>
        </div>
        <div className="page-action">
          <UserDropdown />
          <DateRangeGroup
            actionsToDispatch={actionsToDispatch}
            userId={
              analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                ? Number(analyticsSelectedAssignee.id)
                : null
            }
          />
        </div>
      </div>

      <VmoGrid columns="equal">
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Breakdown by Types</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of leaves based on types over a period of time"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(BREAKDOWN_BY_TYPES) && <CustomLoader />}
              {!type.includes(BREAKDOWN_BY_TYPES) && !breakdownByTypesData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={breakdownByTypesData}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({value}) => `${value}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {value} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(BREAKDOWN_BY_TYPES) && <NoDataChart top="35px" />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Breakdown by Department</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of leaves based on department over a period of time"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(BREAKDOWN_BY_DEPARTMENT) && <CustomLoader />}
              {!type.includes(BREAKDOWN_BY_DEPARTMENT) && !breakdownByDepartmentData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={breakdownByDepartmentData}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({value}) => `${value}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {value} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(BREAKDOWN_BY_DEPARTMENT) && <NoDataChart top="35px" />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent>
              <div className="info-header">
                <h5 className="m-0">Leaves by Department</h5>
                <VmoTooltip
                  content="Graphically represents leaves based on department over a time range"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(LEAVES_BY_DEPARTMENT) && <CustomLoader />}
              {!type.includes(LEAVES_BY_DEPARTMENT) && (
                <ResponsiveBar
                  data={LeavesByDepartmentData}
                  keys={getDepartmentData?.map(({department}) => {
                    return department
                  })}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(LeavesByDepartmentData, 'value')}
                  gridYValues={getGridYValuesBar(LeavesByDepartmentData, 'value')}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(LeavesByDepartmentData, 'value'),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent>
              <div className="info-header">
                <h5 className="m-0">Leaves by Type</h5>
                <VmoTooltip
                  content="Graphically represents leaves based on their types over a time range"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(LEAVES_BY_TYPE) && <CustomLoader />}
              {!type.includes(LEAVES_BY_TYPE) && (
                <ResponsiveBar
                  data={LeavesByTypeData}
                  keys={getTimeOffTypesData?.map(({name}) => {
                    return name
                  })}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(LeavesByTypeData, 'value')}
                  gridYValues={getGridYValuesBar(LeavesByTypeData, 'value')}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(LeavesByTypeData, 'value'),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent>
              <div className="info-header">
                <h5 className="m-0">Leaves by Employee</h5>
                <VmoTooltip
                  content="List of all employees based on types of leaves issued over time"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable responsive basic>
                <VmoTableHeader>
                  <VmoTableRow>{renderLeavesByEmployeeHeader()}</VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>{renderLeavesByEmployeeBody()}</VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default TimeOff
