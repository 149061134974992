import DocumentTitle from 'components/common/DocumentTitle'
import Projects from 'components/pages/Projects/Projects/Projects'
import ProjectTemplates from 'components/pages/Projects/Projects/ProjectTemplates/ProjectTemplates'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom/cjs/react-router-dom.min'

const projectsRoutes = [{path: '/projects/templates', exact: true, component: ProjectTemplates, title: 'Templates'}]

const renderRoute = ({path, exact = false, component: Component, title}) => {
  return (
    <Route
      key={path}
      path={path}
      exact={exact}
      render={props => (
        <>
          <DocumentTitle title={title} projects />
          <Component {...props} />
        </>
      )}
    />
  )
}

const ProjectRoutes = () => {
  return (
    <Switch>
      <Route
        path="/projects"
        exact
        render={props => (
          <>
            <DocumentTitle title="Project" />
            <Projects {...props} />
          </>
        )}
      />
      {projectsRoutes.map(renderRoute)}
      <Route path="/projects/*">
        <Redirect to="/projects" />
      </Route>
    </Switch>
  )
}

export default ProjectRoutes
