import SvgIcon from 'components/common/SvgIcon'
import {FiList, FiMessageSquare, FiPhoneCall} from 'react-icons/fi'

export const ENTITY_OPTIONS = [
  {
    key: 'deal',
    value: 'deal',
    text: 'Deals',
    description: 'Create activities for deals',
    icon: <SvgIcon path="common/mail" />,
  },
  {
    key: 'contact',
    value: 'contact',
    text: 'Contacts',
    description: 'Create activities for contacts',
    icon: <FiPhoneCall />,
  },
  {
    key: 'company',
    value: 'company',
    text: 'Companies',
    description: 'Create activities for companies',
    icon: <SvgIcon path="settings/users" />,
  },
  {
    key: 'quotes',
    value: 'quotes',
    text: 'Quotes',
    description: 'Create specific quotes activities',
    icon: <FiList />,
  },
  {
    key: 'meeting',
    value: 'meeting',
    text: 'Meetings',
    description: 'Create activities for meetings',
    icon: <FiMessageSquare />,
  },
  {
    key: 'conversations',
    value: 'conversations',
    text: 'Conversations',
    description: 'Create activities for conversation',
    icon: <SvgIcon path="common/mail" />,
  },
]

export const TRIGGER_OPTIONS = {
  deal: [
    {
      key: 'created',
      value: 'created',
      text: 'Created',
      description: 'Specify entity as creation of new deal',
    },
    {
      key: 'updated',
      value: 'updated',
      text: 'Updated',
      description: 'Specify entity as an update to existing deal',
    },
    {
      key: 'deleted',
      value: 'deleted',
      text: 'Deleted',
      description: 'Specify entity as deletion of a deal',
    },
    {
      key: 'open_inactive_for',
      value: 'open_inactive_for',
      text: 'Open Inactive For',
      description: 'Specify entity if a deal is open and inactive for specific days',
    },
    {
      key: 'open_close_within',
      value: 'open_close_within',
      text: 'Open Close Within',
      description: 'Specify entity if a deal is opened and closed within specific days',
    },
  ],
  contact: [
    {
      key: 'created',
      value: 'created',
      text: 'Created',
      description: 'Specify entity as creation of new contact',
    },
    {
      key: 'updated',
      value: 'updated',
      text: 'Updated',
      description: 'Specify entity as updation of existing contact',
    },
    {
      key: 'deleted',
      value: 'deleted',
      text: 'Deleted',
      description: 'Specify entity as deletion of a contact',
    },
    {
      key: 'inactive_for',
      value: 'inactive_for',
      text: 'Inactive For',
      description: 'Specify entity as if a contact is inactive for specific time',
    },
  ],
  company: [
    {
      key: 'created',
      value: 'created',
      text: 'Created',
      description: 'Specify entity as creation of new companies',
    },
    {
      key: 'updated',
      value: 'updated',
      text: 'Updated',
      description: 'Specify entity as updation of existing companies',
    },
    {
      key: 'deleted',
      value: 'deleted',
      text: 'Deleted',
      description: 'Specify entity as deletion of a company',
    },
    {
      key: 'inactive_for',
      value: 'inactive_for',
      text: 'Inactive For',
      description: 'Specify entity as if a company is inactive for a specific time',
    },
  ],
  quotes: [
    {
      key: 'created',
      value: 'created',
      text: 'Created',
      description: 'Specify entity as creation of quotes',
    },
    {
      key: 'updated',
      value: 'updated',
      text: 'Updated',
      description: 'Specify entity as updation of existing quotes',
    },
    {
      key: 'deleted',
      value: 'deleted',
      text: 'Deleted',
      description: 'Specify entity as deletion of quote',
    },
  ],
  meeting: [
    {
      key: 'booked',
      value: 'booked',
      text: 'Booked',
      description: 'Specify entity as meeting booked',
    },
  ],
  conversations: [
    {
      key: 'incoming',
      value: 'incoming',
      text: 'Incoming',
      description: 'Specify entity for incoming conversations',
    },
  ],
}

const arrayOfAllFields = [
  // 0 - company(created, updated, deleted), deal(created, updated, deleted), deal, company
  {value: 'name', text: 'Name', key: 'name'},
  // 1 - deal(created, updated, deleted), deal
  {value: 'sourceId', text: 'Source', key: 'sourceId'},
  // 2 - contact(created, updated, deleted), company(created, updated, deleted), deal(created, updated, deleted), deal, contact, company
  {value: 'description', text: 'Description', key: 'description'},
  // 3 - contact(created, updated, deleted), contact
  {value: 'title', text: 'Title', key: 'title'},
  // 4 - contact
  {value: 'company', text: 'Company', key: 'company'},
  // 5 - contact(created, updated, deleted), company(created, updated, deleted), deal(created, updated, deleted), deal
  {value: 'assigneeId', text: 'Assignee', key: 'assigneeId'},
  // 6
  {value: 'statusId', text: 'Status', key: 'statusId'},
  // 7 - deal
  {
    value: 'monetaryValue',
    text: 'Monetary Value',
    key: 'monetaryValue',
  },
  // 8 - contact(created, updated, deleted), company(created, updated, deleted), deal(created, updated, deleted), contact, company
  {value: 'labelIds', text: 'Label', key: 'labelIds'},
  // 9 - contact(updated, deleted), comapany(updated, deleted), quotes(updated)
  {
    value: 'lastContacted',
    text: 'Last contacted',
    key: 'lastContacted',
  },
  // 10 - contact(updated, deleted), comapany(updated, deleted), quotes(updated)
  {value: 'interactions', text: 'Interactions', key: 'interactions'},
  // 11 - contact(created, updated, deleted), contact
  {value: 'firstName', text: 'First Name', key: 'firstName'},
  // 12 - contact(created, updated, deleted), contact
  {value: 'lastName', text: 'Last Name', key: 'lastName'},
  // 13 - company(created, updated, deleted), deal
  {value: 'domain', text: 'Domain', key: 'domain'},
  // 14 - deal(created, updated, deleted)
  {value: 'closeDate', text: 'Close Date', key: 'closeDate'},
  // 15
  {value: 'source', text: 'Source', key: 'source'},
  // 16 - deal(created, updated, deleted)
  {value: 'priority', text: 'Priority', key: 'priority'},
  // 17 - deal(updated, deleted)
  {value: 'leadId', text: 'Lead', key: 'leadId'},
  // 18 - deal(created, updated, deleted)
  {value: 'contactId', text: 'Contact', key: 'contactId'},
  // 19 - deal(created, updated, deleted), deal
  {value: 'companyId', text: 'Company', key: 'companyId'},
  // 20 - deal(created, updated, deleted)
  {value: 'stageId', text: 'Stage', key: 'stageId'},
  // 21 - deal(created, updated, deleted)
  {
    value: 'monetaryValue',
    text: 'Monetary Value',
    key: 'monetaryValue',
  },
  // 22 - deal(updated, deleted), deal
  {value: 'win', text: 'Win', key: 'win'},
  // 23 - quotes(created, updated, deleted)
  {value: 'status', text: 'Status', key: 'status'},
  // 24 - deal(updated, deleted), quotes(updated)
  {value: 'lossReason', text: 'Loss Reason', key: 'lossReason'},
  // 25 - quotes(updated)
  {value: 'daysInStage', text: 'Days In Stage', key: 'daysInStage'},
  // 26 - quotes(updated)
  {value: 'lastStageAt', text: 'Last Stage At', key: 'lastStageAt'},
  // 27 - contact(created, updated, deleted), company(created, updated, deleted)
  {value: 'country', text: 'Country', key: 'country'},
  // 28 - contact(created, updated, deleted), company(created, updated, deleted), contact, company
  {
    value: 'contactTypeId',
    text: 'Contact Type',
    key: 'contactTypeId',
  },
  // 29 - contact, company
  {value: 'email', text: 'Email', key: 'email'},
  // 30 - contact
  {value: 'phone', text: 'Phone', key: 'phone'},
  // 31
  {value: 'website', text: 'Website', key: 'website'},
  // 32 - quotes(created, deleted)
  {value: 'prefixId', text: 'prefixId', key: 'prefixId'},
  // 33 - quotes(created, deleted)
  {value: 'validTill', text: 'validTill', key: 'validTill'},
  // 34 - quotes(created, deleted)
  {value: 'Name', text: 'Name', key: 'Name'},
  // 35 - quotes(created, deleted)
  {value: 'total', text: 'total', key: 'total'},
  // 36 - meeting(booked)
  {value: 'type', text: 'Type', key: 'Type'},
  // 37 - meeting(booked)
  {value: 'meetingName', text: 'meetingName', key: 'meetingName'},
  // 38 - meeting(booked)
  {
    value: 'meetingHeading',
    text: 'meetingHeading',
    key: 'meetingHeading',
  },
  // 39 - meeting(booked)
  {
    value: 'conversionRate',
    text: 'conversionRate',
    key: 'conversionRate',
  },
  // 40 - meeting(booked)
  {value: 'assigneeId', text: 'Owner', key: 'assigneeId'},
  // 41 - conversations(incoming-web)
  {value: 'newContact', key: 'newContact', text: 'First time user'},
  // 42 - conversations(incoming-web)
  {
    value: 'repeatContact',
    key: 'repeatContact',
    text: 'if X time repeat user',
  },
  // 43 - conversations(incoming-web)
  {value: 'country', key: 'country', text: 'Country'},
  // 44 - conversations(incoming-web)
  {
    value: 'pageUrlMatch',
    key: 'pageUrlMatch',
    text: 'Page URL Exactly',
  },
  // 45 - conversations(incoming-web)
  {
    value: 'pageUrlContain',
    key: 'pageUrlContain',
    text: 'Page URL Contains',
  },
  // 46 - conversations(incoming-web)
  {
    value: 'referralUrl',
    key: 'referralUrl',
    text: 'Referral URL Match',
  },
  // 47 - conversations(incoming-web)
  {
    value: 'referralDomain',
    key: 'referralDomain',
    text: 'Referral domain match',
  },
  // 48 - conversations(incoming-web)
  {
    value: 'conversationstatus',
    key: 'conversationstatus',
    text: 'Status is change after X time',
  },
  // 49 - conversations(incoming-web)
  {
    value: 'newConversation',
    key: 'newConversation',
    text: 'New conversation hasn’t received a response',
  },
  // 50 - conversations(incoming-email)
  {value: 'contactId', key: 'contactId', text: 'Sender Is'},
  // 51 - conversations(incoming-email)
  {
    value: 'contactEmailDomain',
    key: 'contactEmailDomain',
    text: 'Contact Domain',
  },
  // 52 - conversations(incoming-email)
  {
    value: 'subjectLike',
    key: 'subjectLike',
    text: 'Subject Contains',
  },
  // 53 - conversations(incoming-email)
  {value: 'assigneeId', key: 'assigneeId', text: 'Assignee '},
  // 54 - conversations(incoming-email)
  {value: 'haveTags', key: 'haveTags', text: 'Have Tags'},
  // 55 - conversations(incoming-email)
  {
    value: 'doNotHaveTags',
    key: 'doNotHaveTags',
    text: 'Do Not Have Tags',
  },
  // 56 - conversations(incoming-email)
  {value: 'contactEmail', key: 'contactEmail', text: 'Contact Email'},
  // 57 - conversations(incoming-email)
  {value: 'company', key: 'company', text: 'Company Name'},
]

export const fieldsForConditions = {
  deal: {
    created: [
      arrayOfAllFields[0],
      arrayOfAllFields[14],
      arrayOfAllFields[1],
      arrayOfAllFields[2],
      arrayOfAllFields[16],
      arrayOfAllFields[18],
      arrayOfAllFields[19],
      arrayOfAllFields[20],
      arrayOfAllFields[21],
      arrayOfAllFields[5],
      arrayOfAllFields[8],
    ],
    updated: [
      arrayOfAllFields[0],
      arrayOfAllFields[14],
      arrayOfAllFields[1],
      arrayOfAllFields[24],
      arrayOfAllFields[2],
      arrayOfAllFields[16],
      // arrayOfAllFields[17],
      arrayOfAllFields[18],
      arrayOfAllFields[19],
      arrayOfAllFields[20],
      arrayOfAllFields[21],
      arrayOfAllFields[22],
      arrayOfAllFields[5],
      arrayOfAllFields[8],
    ],
    deleted: [
      arrayOfAllFields[0],
      arrayOfAllFields[14],
      arrayOfAllFields[1],
      arrayOfAllFields[24],
      arrayOfAllFields[2],
      arrayOfAllFields[16],
      // arrayOfAllFields[17],
      arrayOfAllFields[18],
      arrayOfAllFields[19],
      arrayOfAllFields[20],
      arrayOfAllFields[21],
      arrayOfAllFields[22],
      arrayOfAllFields[5],
      arrayOfAllFields[8],
    ],
  },
  contact: {
    created: [
      arrayOfAllFields[11],
      arrayOfAllFields[12],
      arrayOfAllFields[3],
      arrayOfAllFields[2],
      arrayOfAllFields[27],
      arrayOfAllFields[5],
      arrayOfAllFields[28],
      arrayOfAllFields[8],
    ],
    updated: [
      arrayOfAllFields[11],
      arrayOfAllFields[12],
      arrayOfAllFields[3],
      arrayOfAllFields[2],
      arrayOfAllFields[27],
      arrayOfAllFields[5],
      arrayOfAllFields[28],
      arrayOfAllFields[8],
      // arrayOfAllFields[9],
      arrayOfAllFields[10],
    ],
    deleted: [
      arrayOfAllFields[11],
      arrayOfAllFields[12],
      arrayOfAllFields[3],
      arrayOfAllFields[2],
      arrayOfAllFields[27],
      arrayOfAllFields[5],
      arrayOfAllFields[28],
      arrayOfAllFields[8],
      // arrayOfAllFields[9],
      arrayOfAllFields[10],
    ],
  },
  company: {
    created: [
      arrayOfAllFields[0],
      arrayOfAllFields[2],
      arrayOfAllFields[27],
      arrayOfAllFields[13],
      arrayOfAllFields[5],
      arrayOfAllFields[28],
      arrayOfAllFields[8],
    ],
    updated: [
      arrayOfAllFields[0],
      arrayOfAllFields[2],
      arrayOfAllFields[27],
      arrayOfAllFields[13],
      arrayOfAllFields[5],
      arrayOfAllFields[28],
      arrayOfAllFields[8],
      // arrayOfAllFields[9],
      arrayOfAllFields[10],
    ],
    deleted: [
      arrayOfAllFields[0],
      arrayOfAllFields[2],
      arrayOfAllFields[27],
      arrayOfAllFields[13],
      arrayOfAllFields[5],
      arrayOfAllFields[28],
      arrayOfAllFields[8],
      // arrayOfAllFields[9],
      arrayOfAllFields[10],
    ],
  },
  quotes: {
    created: [
      arrayOfAllFields[23],
      arrayOfAllFields[32],
      arrayOfAllFields[33],
      arrayOfAllFields[34],
      arrayOfAllFields[35],
    ],
    updated: [
      arrayOfAllFields[23],
      arrayOfAllFields[24],
      // arrayOfAllFields[25],
      // arrayOfAllFields[26],
      // arrayOfAllFields[9],
      arrayOfAllFields[10],
    ],
    deleted: [
      arrayOfAllFields[23],
      arrayOfAllFields[32],
      arrayOfAllFields[33],
      arrayOfAllFields[34],
      arrayOfAllFields[35],
    ],
  },
  meeting: {
    booked: [
      arrayOfAllFields[40],
      arrayOfAllFields[36],
      arrayOfAllFields[37],
      arrayOfAllFields[38],
      arrayOfAllFields[39],
    ],
  },
  conversations: {
    incoming: {
      web: [
        arrayOfAllFields[41],
        arrayOfAllFields[42],
        arrayOfAllFields[43],
        arrayOfAllFields[44],
        arrayOfAllFields[45],
        arrayOfAllFields[46],
        arrayOfAllFields[47],
        arrayOfAllFields[48],
        arrayOfAllFields[49],
      ],
      email: [
        arrayOfAllFields[50],
        arrayOfAllFields[51],
        arrayOfAllFields[52],
        arrayOfAllFields[53],
        arrayOfAllFields[54],
        arrayOfAllFields[55],
        arrayOfAllFields[56],
        arrayOfAllFields[57],
      ],
    },
  },
}

export const operatorsOptions = [
  {value: '=', text: 'Equals', key: '='},
  {value: '!=', text: 'Not Equals', key: '!='},
  {value: 'contains', text: 'Contains', key: 'contains'},
  {value: '>', text: 'Greater Than', key: '>'},
  {value: '<', text: 'Less Than', key: '<'},
  {value: '>=', text: 'Greater than and equal to', key: '>='},
  {value: '<=', text: 'Less than and equal to', key: '<='},
]

export const ACTION_ENTITY_OPTIONS = [
  {
    key: 'deal',
    value: 'deal',
    text: 'Deals',
    description: 'Send an automatic email on your desired date/time',
    icon: <SvgIcon path="common/mail" />,
  },
  {
    key: 'contact',
    value: 'contact',
    text: 'Contact',
    description: 'Create activity as a call',
    icon: <FiPhoneCall />,
  },
  {
    key: 'company',
    value: 'company',
    text: 'Company',
    description: 'Create activity as meeting',
    icon: <SvgIcon path="settings/users" />,
  },
  {
    key: 'quote',
    value: 'quote',
    text: 'Quotes',
    description: 'Create a specific activity',
    icon: <FiList />,
  },
  {
    key: 'task',
    value: 'task',
    text: 'Task',
    description: 'Manage action on tasks',
    icon: <FiMessageSquare />,
  },
  {
    key: 'communication',
    value: 'communication',
    text: 'Communication',
    description: 'Manage actions on communications',
    icon: <FiMessageSquare />,
  },
  // {
  //   key: 'conversations',
  //   value: 'conversations',
  //   text: 'Conversations',
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
  //   icon: <SvgIcon path="common/mail" />,
  // },
]

export const ACTION_EVENT_OPTONS = {
  deal: [
    {
      entity: 'deal',
      key: 'create_deal',
      value: 'create_deal',
      text: 'Create Deal',
      description: 'Set action to create a new deal',
      icon: <SvgIcon path="common/mail" />,
    },
    {
      entity: 'deal',
      key: 'update_deal',
      value: 'update_deal',
      text: 'Update Deal',
      description: 'Set action to update an existing deal',
      icon: <SvgIcon path="common/mail" />,
    },
    {
      entity: 'deal',
      key: 'delete_deal',
      value: 'delete_deal',
      text: 'Delete Deal',
      description: 'Set action to delete a deal',
      icon: <SvgIcon path="common/mail" />,
    },
  ],
  contact: [
    {
      entity: 'contact',
      key: 'create_contact',
      value: 'create_contact',
      text: 'Create Contact',
      description: 'Set action to create a new contact',
      icon: <SvgIcon path="common/mail" />,
    },
    {
      entity: 'contact',
      key: 'update_contact',
      value: 'update_contact',
      text: 'Update Contact',
      description: 'Set action to update an existing contact',
      icon: <SvgIcon path="common/mail" />,
    },
    {
      entity: 'contact',
      key: 'delete_contact',
      value: 'delete_contact',
      text: 'Delete Contact',
      description: 'Set action to delete contact',
      icon: <SvgIcon path="common/mail" />,
    },
  ],
  company: [
    {
      entity: 'company',
      key: 'create_company',
      value: 'create_company',
      text: 'Create Company',
      description: 'Set action to create a new company',
      icon: <SvgIcon path="common/mail" />,
    },
    {
      entity: 'company',
      key: 'update_company',
      value: 'update_company',
      text: 'Update Company',
      description: 'Set action to update an existing company',
      icon: <SvgIcon path="common/mail" />,
    },
    {
      entity: 'company',
      key: 'delete_company',
      value: 'delete_company',
      text: 'Delete Company',
      description: 'Set action to delete a company',
      icon: <SvgIcon path="common/mail" />,
    },
  ],
  task: [
    {
      entity: 'task',
      key: 'create_task',
      value: 'create_task',
      text: 'Create Task',
      description: 'Set action to create a new task',
      icon: <SvgIcon path="common/mail" />,
    },
    {
      entity: 'task',
      key: 'update_task',
      value: 'update_task',
      text: 'Update Task',
      description: 'Set action to update an existing task',
      icon: <SvgIcon path="common/mail" />,
    },
    {
      entity: 'task',
      key: 'delete_task',
      value: 'delete_task',
      text: 'Delete Task',
      description: 'Set action to delete a task',
      icon: <SvgIcon path="common/mail" />,
    },
  ],
  quote: [
    {
      entity: 'quote',
      key: 'create_quote',
      value: 'create_quote',
      text: 'Create Quote',
      description: 'Set action to create a new quote',
      icon: <SvgIcon path="common/mail" />,
    },
    {
      entity: 'quote',
      key: 'update_quote',
      value: 'update_quote',
      text: 'Update Quote',
      description: 'Set action to update an existing quote',
      icon: <SvgIcon path="common/mail" />,
    },
    {
      entity: 'quote',
      key: 'delete_quote',
      value: 'delete_quote',
      text: 'Delete Quote',
      description: 'Set action to delete a quote',
      icon: <SvgIcon path="common/mail" />,
    },
  ],
  communication: [
    {
      entity: 'communication',
      key: 'send_internal_email',
      value: 'send_internal_email',
      text: 'Send Internal Email',
      description: 'Send out an internal email to the mailbox email',
      icon: <SvgIcon path="common/mail" />,
    },
    {
      entity: 'communication',
      key: 'send_external_email',
      value: 'send_external_email',
      text: 'Send External Email',
      description: 'Send out an external email',
      icon: <SvgIcon path="common/mail" />,
    },
    {
      entity: 'communication',
      key: 'in_app_notify',
      value: 'in_app_notify',
      text: 'In App Notify',
      description: 'Send an in app notification',
      icon: <SvgIcon path="common/mail" />,
    },
  ],
  conversations: [
    {
      entity: 'conversations',
      key: 'update_conversations',
      value: 'update_conversations',
      text: 'Update Conversations',
      description: 'Set action to update an existing conversations',
      icon: <SvgIcon path="common/mail" />,
    },
  ],
}

export const fieldsForActions = {
  deal: [
    arrayOfAllFields[0],
    arrayOfAllFields[13],
    arrayOfAllFields[22],
    arrayOfAllFields[1],
    arrayOfAllFields[19],
    arrayOfAllFields[7],
    arrayOfAllFields[2],
    arrayOfAllFields[5],
  ],
  contact: [
    arrayOfAllFields[11],
    arrayOfAllFields[12],
    arrayOfAllFields[28],
    arrayOfAllFields[29],
    arrayOfAllFields[30],
    arrayOfAllFields[4],
    arrayOfAllFields[2],
    arrayOfAllFields[3],
    arrayOfAllFields[8],
  ],
  company: [arrayOfAllFields[0], arrayOfAllFields[28], arrayOfAllFields[29], arrayOfAllFields[8], arrayOfAllFields[2]],
}

export const ACTION_CONVERSATION_WEB_OPTIONS = [
  {
    key: 'assigneeId',
    value: 'assigneeId',
    text: 'Assign conversations to teammate',
  },
  {
    key: 'teamId',
    value: 'teamId',
    text: 'Assign conversations to team',
  },
  {
    key: 'tag',
    value: 'tag',
    text: 'Add Tags',
  },
  {
    key: 'status',
    value: 'status',
    text: 'Set Status As',
  },
  {
    key: 'priority',
    value: 'priority',
    text: 'Set Priority',
  },
]

export const ACTION_CONVERSATION_EMAIL_OPTIONS = [
  {
    key: 'assigneeId',
    value: 'assigneeId',
    text: 'Assign To',
  },
  {
    key: 'statusId',
    value: 'statusId',
    text: 'Set Status As',
  },
  {
    key: 'note',
    value: 'note',
    text: 'Add Notes',
  },
  {
    key: 'addLabelIds',
    value: 'addLabelIds',
    text: 'Add Tags',
  },
  {
    key: 'removeLabelIds',
    value: 'removeLabelIds',
    text: 'Remove Tags',
  },
  {
    key: 'mailboxId',
    value: 'mailboxId',
    text: 'Move To Inbox',
  },
  {
    key: 'deleteConversation',
    value: 'deleteConversation',
    text: 'Delete Conversation',
  },
  {
    key: 'priority',
    value: 'priority',
    text: 'Set Priority',
  },
]
