import Joi from 'joi'
import {inputStringValidation, textAreaValidation} from 'validation'

export const fieldsSchema = Joi.object({
  name: inputStringValidation.required().min(4).max(20).messages({
    'string.empty': 'Name is not allowed to be empty',
    'string.min': `Minimum 4 characters required`,
    'string.max': 'Maximum 20 characters allowed',
  }),
  type: inputStringValidation.required().messages({
    'string.empty': `Pipeline cannot be an empty field`,
    'string.min': `At least 4 characters long`,
  }),
  required: Joi.boolean(),
  categoryIds: Joi.array().min(1).messages({
    'array.min': `Link Category cannot be an empty field`,
  }),
  description: textAreaValidation.trim().messages({
    'string.empty': `Description cannot be an empty field`,
    'string.base': `Description cannot be an empty field`,
  }),
  helpText: textAreaValidation.trim().messages({
    'string.empty': `Help text for agents cannot be an empty field`,
    'string.base': `Help text for agents cannot be an empty field`,
  }),
  values: Joi.array().items({value: Joi.string()}),
})
