import React from 'react'
import {VmoButton, VmoModal, VmoModalContent, VmoModalHeader} from 'vmo-library'
import googleLogo from 'assets/google-logo.svg'
import emailLogo from 'assets/email-logo.svg'

const SetupModal = ({open, toggle, setCurrentModal}) => {
  return (
    <VmoModal size="small" closeIcon open={open} onClose={() => toggle()}>
      <VmoModalHeader>SetUp Email</VmoModalHeader>
      <VmoModalContent className="emlIbxSetupModal">
        <div style={{textAlign: 'center'}}>
          <h2 className="mb-3">Let's connect your inbox!</h2>
          <p style={{width: '80%', margin: 'auto'}}>
            Connect the inbox you use for chatting with your customers, and all of your emails will automatically flow
            into kadence.
          </p>

          <div className="email-wrapper">
            <VmoButton onClick={() => setCurrentModal('gmailSetupModal')}>
              <img src={googleLogo} alt="google" />
              <p>Connect Gmail</p>
              <span>Start recieving your GSuite or Email directly into kadence</span>
            </VmoButton>
            <VmoButton onClick={() => setCurrentModal('otherSetupModal')}>
              <img src={emailLogo} alt="email" />
              <p>Other email client</p>
              <span>Setup email forwarding to receive email in your Kadence inbox</span>
            </VmoButton>
          </div>
        </div>
      </VmoModalContent>
    </VmoModal>
  )
}

export default SetupModal
