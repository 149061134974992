import {
  ACCESS_DENIED,
  GLOBAL_SEARCH_CUSTOMERS,
  GLOBAL_SEARCH_FINANCE,
  GLOBAL_SEARCH_HR,
  GLOBAL_SEARCH_INBOX,
  GLOBAL_SEARCH_PROJECTS,
  GLOBAL_SEARCH_SALES,
  LEARN_MORE,
  LOGGED_OUT,
} from 'actions/types'
import {clearStorage} from 'utils/local-storage'

export default function (state = {}, action) {
  // console.log("LOGIN_USER => ", JSON.stringify(state));

  switch (action.type) {
    case 'LOGGED_IN':
      return {...state, isLoggedOut: false}
    case LOGGED_OUT:
      return {isLoggedOut: true, tokenExpired: false}
    case ACCESS_DENIED: {
      clearStorage()
      return {...state, tokenExpired: true}
    }
    case GLOBAL_SEARCH_CUSTOMERS:
      return {
        ...state,
        globalSearchCustomersData: action.payload,
      }
    case GLOBAL_SEARCH_INBOX:
      return {
        ...state,
        globalSearchInboxData: action.payload,
      }
    case GLOBAL_SEARCH_SALES:
      return {
        ...state,
        globalSearchSalesData: action.payload,
      }
    case GLOBAL_SEARCH_PROJECTS:
      return {
        ...state,
        globalSearchProjectsData: action.payload,
      }
    case GLOBAL_SEARCH_FINANCE:
      return {
        ...state,
        globalSearchFinanceData: action.payload,
      }
    case GLOBAL_SEARCH_HR:
      return {
        ...state,
        globalSearchHrData: action.payload,
      }

    case LEARN_MORE:
      return {
        ...state,
        learnMoreData: action.payload || {},
      }

    default:
      return state
  }
}
