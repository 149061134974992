import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import {
  VmoButton,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {deleteRole, getAllRoles} from 'actions/roles'
import {ADD_ROLE, DELETE_ROLE, GET_ALL_ROLES} from 'actions/types'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import SettingLayout from 'layouts/settingLayout'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import RolesDetails from './RolesDetails'

const Roles = props => {
  const history = useHistory()

  const [createRole, setCreateRole] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const rolesLearnMore = settingsLearnMoreUrl?.myOrganization?.roles?.learnMore

  const {isLoading} = useApiLoader({label: GET_ALL_ROLES})
  const {teamAndUsersAccess, myOrganizationDefaultPermissions} = useUserPermissions()
  const customRolesAndPermisionAccess = teamAndUsersAccess?.customRolesAndPermision

  const {data: getRoleList = [], callAction} = useApiResponse({
    action: getAllRoles,
    dependency: [customRolesAndPermisionAccess],
    enabled: customRolesAndPermisionAccess,
    storePath: 'roles.getRoleList',
  })

  useEffect(() => {
    if (successLabels.includes(ADD_ROLE)) {
      callAction()
    }
    if (successLabels.includes(DELETE_ROLE)) {
      callAction()
      setDeleteModal(false)
      setDeleteId(null)
    }
  }, [successLabels, callAction])

  const renderTableBody = () => {
    return getRoleList.map((role, index) => (
      <VmoTableRow
        key={role.id}
        className="tableLink"
        onClick={() => !role.isDefault && history.push(`/settings/roles/${role.id}`)}
      >
        <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
        <VmoTableCell>
          <VmoHeader as="h5">{startCase(role.role)}</VmoHeader>
        </VmoTableCell>
        <VmoTableCell>
          {!role.isDefault && (
            <VmoTooltip
              content={
                myOrganizationDefaultPermissions
                  ? 'Delete'
                  : getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})
              }
              size="mini"
              position="top center"
              trigger={
                <VmoButton
                  basic
                  icon
                  className="tableLink-delete-icon"
                  onClick={e => {
                    e.stopPropagation()
                    if (myOrganizationDefaultPermissions) {
                      setDeleteId(role.id)
                      setDeleteModal(true)
                    }
                  }}
                >
                  <SvgIcon path="common/delete" />
                </VmoButton>
              }
            />
          )}
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  if (isLoading && customRolesAndPermisionAccess) {
    return <PageLoader />
  }

  if (createRole) {
    return <RolesDetails toggleCreateRole={setCreateRole} {...props} />
  }

  if (getRoleList.length === 0 || !customRolesAndPermisionAccess) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="Roles"
          subHeader="Roles allow you to control who can access certain features and data within the organization."
          addFirst={() => customRolesAndPermisionAccess && myOrganizationDefaultPermissions && setCreateRole(true)}
          buttonText="Create Role"
          tooltip={!customRolesAndPermisionAccess || !myOrganizationDefaultPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: customRolesAndPermisionAccess,
            roleAccess: myOrganizationDefaultPermissions,
          })}
          Feature1="Customization"
          list1="flexibility in creating custom roles with specific permissions."
          Feature2="Access Control"
          list2="Assign roles to team members based on job function."
          Feature3="Team Collaboration"
          list3="Assigning roles help teams to collaborate on projects more effectively"
        />
      </div>
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/roles" />}
      header="Roles"
      subHeader="Create custom roles and permissions for your users"
      learnMoreUrl={rolesLearnMore}
      table
      headerDivider
      headerButton={{content: 'Add', onClick: () => myOrganizationDefaultPermissions && setCreateRole(true)}}
      lockRole={!myOrganizationDefaultPermissions}
    >
      <VmoTable basic selectable collapsing>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell className="pl-4" style={{width: '70px'}}>
              #
            </VmoTableHeaderCell>
            <VmoTableHeaderCell style={{width: '200px'}}>Roles</VmoTableHeaderCell>
            <VmoTableHeaderCell style={{width: '100px'}}>Action</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>{renderTableBody()}</VmoTableBody>
      </VmoTable>

      {deleteModal && (
        <DeleteModal
          idTobeDeleted={deleteId}
          deleteAction={deleteRole}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete this Role?"
          type={DELETE_ROLE}
        />
      )}
    </SettingLayout>
  )
}

export default Roles
