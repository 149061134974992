import {LOGIN_EMPLOYEE} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case LOGIN_EMPLOYEE:
      return {...state, loginEmployeeDetails: payload}

    default:
      return state
  }
}
