import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import PurchasesCategory from 'components/pages/Finance/Purchases/Settings/Category/Category'
import Purchases from 'components/pages/Finance/Purchases/Purchases'

const routes = [
  {path: '/purchases/all', component: Purchases, title: 'All Purchases', exact: true},
  {path: '/purchases/category', component: PurchasesCategory, title: 'Category', exact: true},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} purchases />
        <Component {...props} />
      </>
    )}
  />
)

const PurchasesRoutes = () => (
  <Switch>
    <Route exact path="/purchases">
      <Redirect to="/purchases/all" />
    </Route>
    {routes.map(renderRoute)}
    <Route path="/purchases">
      <Redirect to="/purchases/all" />
    </Route>
  </Switch>
)

export default PurchasesRoutes
