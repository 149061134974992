import Joi from 'joi'

export const timeTrackingSchema = Joi.object({
  isTimeTracking: Joi.boolean(),
  isAutoForConversation: Joi.boolean(),
  onConversation: Joi.boolean(),
  onDraftAndReply: Joi.boolean(),
  pauseTimerIdle: Joi.boolean(),
  timerPauseLimit: Joi.when('pauseTimerIdle', {
    is: true,
    then: Joi.number().optional(),
    otherwise: Joi.number().required().messages({'number.base': 'timerPauseLimit must be a number'}),
  }),
  oneActivityAtTime: Joi.boolean(),
  isBillable: Joi.boolean(),
  task: Joi.boolean(),
  meeting: Joi.boolean(),
  call: Joi.boolean(),
  event: Joi.boolean(),
  milestone: Joi.boolean(),
  // hideTimerForAgent: Joi.boolean(),
  billingType: Joi.string().allow('').empty(null),
  users: Joi.when('billingType', {
    is: 'Specific_Cost',
    then: Joi.array().items(
      Joi.object({
        id: Joi.number().integer().messages({
          'number.base': 'Atleast 1 User must be selected',
        }),
        cost: Joi.number().messages({
          'number.base': 'cost must be a number',
        }),
      })
    ),
    otherwise: Joi.array().optional(),
  }),

  fixedCost: Joi.when('billingType', {
    is: 'Fixed_Cost',
    then: Joi.number().required().messages({'number.base': 'fixedCost must be a number'}),
    otherwise: Joi.number().optional(),
  }),
})
