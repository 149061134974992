import React from 'react'
import SvgIcon from 'components/common/SvgIcon'
import {
  VmoButton,
  VmoCheckbox,
  VmoHeader,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoFormField,
  VmoForm,
} from 'vmo-library'

const DeletePipelineModal = ({deleteDeal, setDeleteDeal, onDelete}) => {
  return (
    <VmoModal
      size="tiny"
      open={deleteDeal}
      centered={false}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={setDeleteDeal}
    >
      <VmoHeader className="vmoModalHeader">Delete Pipeline</VmoHeader>
      <VmoModalContent>
        <VmoForm>
          <VmoFormField>
            <VmoCheckbox label="Do you want to delete pipeline?" checked />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setDeleteDeal(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={onDelete}>
          Yes
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default DeletePipelineModal
