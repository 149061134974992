import {GET_ACCOUNT_CURRENCY, GET_ACCOUNT_CURRENCY_NOTE_ADDED, GET_ALL_CURRENCY} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_CURRENCY: {
      return {
        ...state,
        allCurrencyList: action.payload,
      }
    }
    case GET_ACCOUNT_CURRENCY: {
      return {
        ...state,
        accountCurrencyList: action.payload,
      }
    }
    case GET_ACCOUNT_CURRENCY_NOTE_ADDED: {
      return {
        ...state,
        noteAddedCurrencyList: action.payload,
      }
    }
    default:
      return state
  }
}
