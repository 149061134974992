import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import Expenses from 'components/pages/HumanResourse/Expenses/Expenses'
import Category from 'components/pages/HumanResourse/Expenses/Settings/Category/Category'

const routes = [
  {path: '/expenses/all', component: Expenses, title: 'All Expenses', exact: true},
  {path: '/expenses/categories', component: Category, title: 'Category', exact: false},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} />
        <Component {...props} />
      </>
    )}
  />
)

const ExpenseRoutes = () => (
  <Switch>
    <Route exact path="/expenses">
      <Redirect to="/expenses/all" />
    </Route>
    {routes.map(renderRoute)}
    <Route path="/expenses/*">
      <Redirect to="/expenses/all" />
    </Route>
  </Switch>
)

export default ExpenseRoutes
