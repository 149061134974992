import React from 'react'
import {
  VmoButton,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoStatistic,
  VmoStatisticValue,
} from 'vmo-library'
import {get, startCase} from 'utils/helper'
import {useDispatch, useSelector} from 'react-redux'
import {startBilling, updateBilling} from 'actions/billing'
import _ from 'lodash'
import SvgIcon from 'components/common/SvgIcon'

const CheckoutModal = props => {
  const {isOpen, setIsOpen, paymentData} = props

  const dispatch = useDispatch()

  const {isLoadingData} = useSelector(state => state.apiReducer)
  const {calculateBillingData = {}, currentSubscriptionData = {}} = useSelector(state => state.billing)

  const payment = (
    <>
      <VmoModalHeader>Are you sure ?</VmoModalHeader>
      <VmoModalContent>
        <h5>You have chosen {startCase(paymentData.plan)} Plan</h5>
        <VmoStatistic color="blue">
          <VmoStatisticValue>$ {Number(calculateBillingData.finalAmount).toFixed(2)}</VmoStatisticValue>
        </VmoStatistic>
      </VmoModalContent>
    </>
  )

  const actionCancelModal = action => {
    if (action === 'completed') {
      if (get(['subscription', 'status'], currentSubscriptionData) === 'trialing') {
        dispatch(startBilling(_.omit(paymentData, 'interval')))
      } else {
        dispatch(updateBilling(_.omit(paymentData, 'interval')))
      }
    }
  }

  return (
    <VmoModal
      size="tiny"
      open={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      onClose={() => setIsOpen(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
    >
      {payment}
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsOpen(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary loading={isLoadingData} onClick={() => actionCancelModal('completed')}>
          Pay Now
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default CheckoutModal
