import React from 'react'
import {Controller} from 'react-hook-form'
import {useCreditCardValidator} from 'react-creditcard-validator'
import {VmoForm, VmoFormField, VmoFormGroup, VmoFormInput} from 'vmo-library'
import {removeDoubleQuotes} from 'utils/helper'

const AddCardForm = ({control, errors}) => {
  const {getCardNumberProps, getExpiryDateProps, getCVCProps} = useCreditCardValidator()

  return (
    <VmoForm className="errorLabel">
      <VmoFormGroup>
        <VmoFormField width={8} required>
          <label>Card Holder Name</label>
          <Controller
            name="cardHolderName"
            control={control}
            render={({value, onChange, onBlur}) => (
              <VmoFormInput
                type="text"
                placeholder="Card Holder Name"
                value={value}
                onBlur={onBlur}
                onChange={e => onChange(e.target.value)}
                error={
                  errors.cardHolderName && {
                    content: removeDoubleQuotes(errors.cardHolderName.message),
                  }
                }
              />
            )}
          />
        </VmoFormField>
        <VmoFormField width={8} required>
          <label>Card Number</label>
          <Controller
            name="number"
            control={control}
            render={({onChange, onBlur}) => (
              <input
                {...getCardNumberProps({
                  onChange: e => {
                    if (e.target.value.length <= 19) {
                      onChange(e.target.value.split(' ').join(''))
                    }
                  },
                  onBlur,
                })}
              />
            )}
          />
          <p className="errorLabelElement">{errors.number?.message && removeDoubleQuotes(errors.number.message)}</p>
        </VmoFormField>
      </VmoFormGroup>
      <VmoFormGroup>
        <VmoFormField width={8} required>
          <label>Exp Date (MM/YY)</label>
          <Controller
            name="expMonth"
            control={control}
            render={({onChange, onBlur}) => (
              <input
                {...getExpiryDateProps({
                  onChange: e => {
                    e.stopPropagation()
                    onChange(e.target.value.split(' ').join(''))
                  },
                  onBlur,
                })}
              />
            )}
          />
          <p className="errorLabelElement">{errors.expMonth?.message && removeDoubleQuotes(errors.expMonth.message)}</p>
        </VmoFormField>

        <VmoFormField width={8} required>
          <label>CVV</label>
          <Controller
            name="cvc"
            control={control}
            render={({onChange, onBlur}) => (
              <input
                {...getCVCProps({
                  onChange: e => onChange(e.target.value),
                  onBlur,
                })}
              />
            )}
          />
          <p className="errorLabelElement">{errors.cvc?.message && removeDoubleQuotes(errors.cvc.message)}</p>
        </VmoFormField>
      </VmoFormGroup>
    </VmoForm>
  )
}

export default AddCardForm
