import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import _ from 'lodash'
import {
  VmoButton,
  VmoCheckbox,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

import {COPY_PROJECTS_TASK_LIST} from 'actions/types'
import {copyProjectsTasksList} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const CopyTaskListModal = ({open, toggle, title, projectId, taskListId}) => {
  const dispatch = useDispatch()

  const [contents, setContents] = useState({
    stages: false,
    assignees: false,
    dates: false,
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsList = []} = useSelector(state => state.projects)

  const {handleSubmit, errors, control, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        title: Joi.string().trim().min(3).max(50).required().messages({
          'string.min': `Length must me at least 3 charactes`,
          'string.max': `Length must be less than or equal to 50 characters`,
        }),
        projectId: Joi.number().required().messages({
          'number.base': 'Project must be specified',
        }),
      })
    ),
    defaultValues: {
      title: '',
      projectId: null,
    },
  })

  useEffect(() => {
    if (title && projectId) {
      const data = {title: `Copy of ${title}`, projectId: Number(projectId)}
      reset(data)
    }
  }, [reset, title, projectId])

  useEffect(() => {
    if (successLabels.includes(COPY_PROJECTS_TASK_LIST)) {
      toggle()
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    const contentsObj = _.omitBy(contents, value => !value)
    const contentsArr = Object.keys(contentsObj)
    const payload = {...data, contents: contentsArr}
    dispatch(copyProjectsTasksList({projectId, taskListId, data: payload}))
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={toggle}
    >
      <VmoModalHeader>Copy tasklist</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Title</label>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required>
            <label>Project</label>
            <Controller
              name="projectId"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  options={projectsList.map(({id, title}) => ({key: id, value: id, text: title}))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.projectId && {content: removeDoubleQuotes(errors.projectId.message)}}
                />
              )}
            />
          </VmoFormField>
          <label style={{fontSize: '13px'}}>Copy</label>
          <VmoFormField className="m-0">
            <VmoCheckbox
              label="Task stages"
              checked={contents.stages}
              onChange={() => setContents({...contents, stages: !contents.stages})}
            />
          </VmoFormField>
          <VmoFormField className="m-0">
            <VmoCheckbox
              label="Subscribers/Assignees"
              checked={contents.assignees}
              onChange={() => setContents({...contents, assignees: !contents.assignees})}
            />
          </VmoFormField>
          <VmoFormField className="m-0">
            <VmoCheckbox
              label="Dates"
              checked={contents.dates}
              onChange={() => setContents({...contents, dates: !contents.dates})}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={toggle}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          Ok
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default CopyTaskListModal
