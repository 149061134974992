import {apiAction} from './api'
import {KADENCE_PROJECTS} from './endpoint'
import {
  CREATE_PROJECTS_CATEGORY,
  CREATE_PROJECTS_TASKS_LABEL,
  DELETE_PROJECTS_CATEGORY,
  DELETE_PROJECTS_TASKS_LABEL,
  GET_PROJECTS_CATEGORIES,
  GET_PROJECTS_SINGLE_CATEGORY,
  GET_PROJECTS_TASKS_LABELS,
  GET_PROJECTS_TASKS_SINGLE_LABEL,
  UPDATE_PROJECTS_CATEGORY,
  UPDATE_PROJECTS_TASKS_LABEL,
} from './types'

export function getProjectsTasksLabels() {
  return apiAction({
    url: `${KADENCE_PROJECTS}/tasks/labels`,
    method: 'GET',
    label: GET_PROJECTS_TASKS_LABELS,
  })
}

export function createProjectsTasksLabel(data) {
  return apiAction({
    url: `${KADENCE_PROJECTS}/tasks/labels`,
    method: 'POST',
    label: CREATE_PROJECTS_TASKS_LABEL,
    data,
    showToast: true,
    successMessage: 'Label Created',
  })
}

export function getProjectsTasksSingleLabel(id) {
  return apiAction({
    url: `${KADENCE_PROJECTS}/tasks/labels/${id}`,
    method: 'GET',
    label: GET_PROJECTS_TASKS_SINGLE_LABEL,
  })
}

export function updateProjectsTasksLabel(id, data) {
  return apiAction({
    url: `${KADENCE_PROJECTS}/tasks/labels/${id}`,
    method: 'PUT',
    label: UPDATE_PROJECTS_TASKS_LABEL,
    data,
    showToast: true,
    successMessage: 'Label Updated',
  })
}

export function deleteProjectsTasksLabel(id) {
  return apiAction({
    url: `${KADENCE_PROJECTS}/tasks/labels/${id}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_TASKS_LABEL,
    showToast: true,
    successMessage: 'Label Deleted',
  })
}

export function getProjectsCategories() {
  return apiAction({
    url: `${KADENCE_PROJECTS}/categories`,
    method: 'GET',
    label: GET_PROJECTS_CATEGORIES,
  })
}

export function createProjectsCategory(data) {
  return apiAction({
    url: `${KADENCE_PROJECTS}/categories`,
    method: 'POST',
    label: CREATE_PROJECTS_CATEGORY,
    data,
    showToast: true,
    successMessage: 'Category Created',
  })
}

export function getProjectsSingleCategory(id) {
  return apiAction({
    url: `${KADENCE_PROJECTS}/categories/${id}`,
    method: 'GET',
    label: GET_PROJECTS_SINGLE_CATEGORY,
  })
}

export function updateProjectsCategory(id, data) {
  return apiAction({
    url: `${KADENCE_PROJECTS}/categories/${id}`,
    method: 'PUT',
    label: UPDATE_PROJECTS_CATEGORY,
    data,
    showToast: true,
    successMessage: 'Category Updated',
  })
}

export function deleteProjectsCategory(id) {
  return apiAction({
    url: `${KADENCE_PROJECTS}/categories/${id}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_CATEGORY,
    showToast: true,
    successMessage: 'Category Deleted',
  })
}
