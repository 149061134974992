import {
  GET_ENTERIES_CONVERSATION,
  GET_TIME_TRACKING,
  GET_TIME_TRACKING_ENTRY_STATUS,
  GET_TIME_TRACKING_ENTRY_STATUS_TASK,
  GET_ENTERIES_TASK,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_TIME_TRACKING:
      return {...state, timeTrackingData: action.payload}
    case GET_TIME_TRACKING_ENTRY_STATUS:
      return {...state, timeTrackingEntryStatusData: action.payload}

    case GET_ENTERIES_CONVERSATION:
      return {...state, timeTrackingConversationEnteries: action.payload}
    case GET_TIME_TRACKING_ENTRY_STATUS_TASK:
      return {...state, timeTrackingEntryStatusTaskData: action.payload}

    case GET_ENTERIES_TASK:
      return {...state, timeTrackingTaskEnteries: action.payload}
    default:
      return state
  }
}
