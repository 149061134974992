import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import _ from 'lodash'
import {
  VmoGrid,
  VmoCard,
  VmoGridColumn,
  VmoCardContent,
  VmoCardHeader,
  VmoGridRow,
  VmoTable,
  VmoTableHeader,
  VmoTableRow,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeaderCell,
  VmoTooltip,
} from 'vmo-library'

import {EMAIL_CLICK_VS_TIME, EMAIL_OPEN_VS_TIME} from 'actions/types'
import {emailStats, emailOpenedVsTime, emailClickedVsTime, emailTemplateStats} from 'actions/reports_email'
import Moment from 'moment'
import {maxOfGraphBar, getGridYValuesBar} from 'utils/graph'
import {get, startCase} from 'utils/helper'
import CustomLoader from 'components/common/CustomLoader'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import {AssigneeDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const EmailAnalytics = () => {
  const dispatch = useDispatch()

  const diff = Moment(get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem('kadence')))).diff(
    Moment(get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem('kadence')))),
    'days'
  )

  const {type = []} = useSelector(state => state.apiReducer)
  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  // Data for graphs and content
  const {
    emailStatsData,
    emailOpenedVsTimeData = [],
    emailClickedVsTimeData = [],
    emailTemplateStatsData = [],
  } = useSelector(state => state.reportsEmail)
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const emailTrackingAccess = analyticsAccess?.emailTracking
  const conversationsReportsPermissions = reportsPermissions?.conversations_reports

  // Actions to dispatch in Date function
  const actionsToDispatch = useCallback(
    data => {
      dispatch(emailStats(_.omit(data, 'graphType')))
      dispatch(emailOpenedVsTime(_.omit(data, 'graphType')))
      dispatch(emailClickedVsTime(_.omit(data, 'graphType')))
      dispatch(emailTemplateStats(_.omit(data, 'graphType')))
    },
    [dispatch]
  )
  return (
    <div className="kadence-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="common/calendar" />
            <h5>Email Tracking Analytics</h5>
          </div>
        </div>
        {emailTrackingAccess && conversationsReportsPermissions && (
          <div className="page-action">
            <AssigneeDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              teamId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'team'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
            />
          </div>
        )}
      </div>
      <VmoGrid columns="equal">
        <VmoGridColumn width={16}>
          <VmoCard fluid>
            <VmoCardContent>
              <VmoGrid columns={4} divided>
                <VmoGridRow>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>Total</VmoCardHeader>
                      <h3>
                        {get(['value'], emailStatsData?.totalEmails, '0')}
                        <VmoTooltip
                          content="Total"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.totalEmails, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.totalEmails, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.totalEmails, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>Open</VmoCardHeader>
                      <h3>
                        {get(['value'], emailStatsData?.openedEmails, '0')}
                        <VmoTooltip
                          content="Open"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.openedEmails, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.openedEmails, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.openedEmails, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>Clicked</VmoCardHeader>
                      <h3>
                        {get(['value'], emailStatsData?.clickedEmails, '0')}
                        <VmoTooltip
                          content="Clicked"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.clickedEmails, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.clickedEmails, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.clickedEmails, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>Replied</VmoCardHeader>
                      <h3>
                        {get(['value'], emailStatsData?.repliedEmails, '0')}
                        <VmoTooltip
                          content="Replied"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.repliedEmails, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.repliedEmails, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.repliedEmails, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </VmoGridColumn>
                </VmoGridRow>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
          <VmoCard fluid>
            <VmoCardContent>
              <VmoGrid columns={3} divided>
                <VmoGridRow>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <div className="info-header">
                        <VmoCardHeader>Open Ratio</VmoCardHeader>
                        <VmoTooltip
                          content="Ratio of opened email to total emails sent"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="right center"
                        />
                      </div>
                      <h3>
                        {get(['openRatio', 'value'], emailStatsData, '0')}
                        <VmoTooltip
                          content="Open Ratio"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.openRatio, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.openRatio, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.openRatio, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <div className="info-header">
                        <VmoCardHeader>Click Ratio</VmoCardHeader>
                        <VmoTooltip
                          content="Ratio of clicks on emails to total emails sent"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="right center"
                        />
                      </div>
                      <h3>
                        {get(['value'], emailStatsData?.clickRatio, '0')}
                        <VmoTooltip
                          content="Click Ratio"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.clickRatio, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.clickRatio, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.clickRatio, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <div className="info-header">
                        <VmoCardHeader>Response Ratio</VmoCardHeader>
                        <VmoTooltip
                          content="Ratio of response on emails to total emails sent"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="right center"
                        />
                      </div>
                      <h3>
                        {get(['value'], emailStatsData?.responseRatio, '0')}
                        <VmoTooltip
                          content="Response Ratio"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.responseRatio, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.responseRatio, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.responseRatio, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </VmoGridColumn>
                </VmoGridRow>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent>
              <div className="info-header">
                <h5 className="m-0">Time based chart of Open</h5>
                <VmoTooltip
                  content="Graphical representation email opened over a period of time"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(EMAIL_OPEN_VS_TIME) && <CustomLoader />}
              {!type.includes(EMAIL_OPEN_VS_TIME) && (
                <ResponsiveBar
                  data={emailOpenedVsTimeData}
                  // keys={"opened"}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(emailOpenedVsTimeData, 'value')}
                  gridYValues={getGridYValuesBar(emailOpenedVsTimeData, 'value')}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                    format: tick => {
                      if (diff > 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(emailOpenedVsTimeData, 'value'),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent>
              <div className="info-header">
                <h5 className="m-0">Time based chart of Click</h5>
                <VmoTooltip
                  content="Graphical representation of number of clicks on emails over a period of time"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(EMAIL_CLICK_VS_TIME) && <CustomLoader />}
              {!type.includes(EMAIL_CLICK_VS_TIME) && (
                <ResponsiveBar
                  data={emailClickedVsTimeData}
                  // keys={"Clicked"}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(emailClickedVsTimeData, 'value')}
                  gridYValues={getGridYValuesBar(emailClickedVsTimeData, 'value')}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                    format: tick => {
                      if (diff > 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(emailClickedVsTimeData, 'value'),
                    legend: 'Clicked',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent>
              <div className="info-header">
                <h5 className="m-0">Email Template Stats</h5>
                <VmoTooltip
                  content="list of email templates based on their performance over time period"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable responsive basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Total</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Open</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Clicked</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Replied</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Open Ratio</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Click Ratio</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Response Ratio</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {emailTemplateStatsData && emailTemplateStatsData.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    <>
                      {emailTemplateStatsData?.map((data, index) => (
                        <VmoTableRow key={index}>
                          <VmoTableCell>{startCase(data.name)}</VmoTableCell>
                          <VmoTableCell>{data.totalEmails}</VmoTableCell>
                          <VmoTableCell>{data.openedEmails}</VmoTableCell>
                          <VmoTableCell>{data.clickedEmails}</VmoTableCell>
                          <VmoTableCell>{data.repliedEmails}</VmoTableCell>
                          <VmoTableCell>{data.openRatio}</VmoTableCell>
                          <VmoTableCell>{data.clickRatio}</VmoTableCell>
                          <VmoTableCell>{data.responseRaatio}</VmoTableCell>
                        </VmoTableRow>
                      ))}
                    </>
                  )}
                </VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}
export default EmailAnalytics
