import Joi from 'joi'

import {inputStringValidation, inputNumberValidation} from 'validation'

export const createTimeOffSettingSchema = Joi.object({
  name: inputStringValidation.required(),
  color: Joi.string().required(),
  description: Joi.string().required().max(200).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
  noOfDays: Joi.string().required(),
  isAccrue: Joi.boolean(),
  approvalRequire: Joi.boolean(),
  documentRequire: Joi.boolean(),
  visibleToEmployees: Joi.boolean(),
  countForAbsent: Joi.boolean(),
})

export const createTimeOffSchema = Joi.object({
  employeeId: inputNumberValidation.required(),
  typeId: inputNumberValidation.required(),
  description: Joi.string().allow('', null).max(200).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
  attachmentId: Joi.number().allow('', null),
  dateType: Joi.string().valid('half_day', 'full_day', 'days'),
  dateRange: Joi.object().keys({
    date: Joi.date().iso().allow('', null),
    datePeriod: Joi.string().allow('', null),
    fromDate: Joi.date().iso().allow('', null),
    toDate: Joi.date().iso().allow('', null),
  }),
})
