/* eslint-disable no-nested-ternary */
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoImage,
  VmoRadio,
  VmoTablePlaceholder,
  VmoTooltip,
} from 'vmo-library'
import {DELETE_PROJECT, GET_PROJECTS_LIST} from 'actions/types'
import {cloneProject, deleteProject, updateProjectStatus} from 'actions/projects'
import DeleteModal from 'components/common/DeleteModal'
import {get, getPermissionTooltipContent, startCase} from 'utils/helper'
import KadenceCustomPagination from 'components/common/KadenceCustomPagination'
import KadenceNoDataTable from 'components/common/KadenceNoDataTable'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import EditProjectModal from './EditProjectModal'

const ProjectList = ({filter, setFilter, rolePermissions = true}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [idToEdit, setIdToEdit] = useState(null)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const {projectsList = [], users = []} = useSelector(state => state.projects)
  const {type} = useSelector(state => state.apiReducer)

  const {totalPages, currentPage} = useApiResponse({storePath: 'projects.projectsPage'})

  const getDateMonthYear = date => {
    return `${moment(date).date()}-${moment(date).month()}-${moment(date).year()}`
  }

  const getFullName = id => {
    const user = users.find(u => u.id === id)
    if (user) {
      return `${get(['firstName'], user, '')} ${get(['lastName'], user, '')}`
    }
    return null
  }

  const getSortName = id => {
    const user = users.find(u => u.id === id)
    if (user) {
      return `${get(['firstName'], user, '')[0]}${get(['lastName'], user, '')[0]}`
    }
    return null
  }

  const getStatus = status => {
    if (status === 'active') return 'Active'
    if (status === 'inactive') return 'In-Active'
    if (status === 'archived') return 'Archived'
    return null
  }

  const renderProjectList = () => {
    if (type.includes(GET_PROJECTS_LIST)) {
      return <VmoTablePlaceholder columns={8} />
    }
    if (!type.includes(GET_PROJECTS_LIST) && !projectsList.length) {
      return (
        <VmoCard fluid className="project-list-card">
          <VmoCardContent>
            <KadenceNoDataTable />
          </VmoCardContent>
        </VmoCard>
      )
    }
    return projectsList.map(project => {
      return (
        <VmoCard fluid className="project-list-card" key={project.id}>
          <VmoCardContent>
            <div className="d-flex">
              <VmoCardHeader className="d-flex" onClick={() => history.push(`/projects/${project.id}/overview`)}>
                {startCase(project.title)}
              </VmoCardHeader>
              <div className="d-flex" style={{columnGap: 'var(--gap-medium)'}}>
                <VmoTooltip
                  content={
                    rolePermissions
                      ? getStatus(project.status)
                      : getPermissionTooltipContent({roleAccess: rolePermissions})
                  }
                  position="top center"
                  size="mini"
                  trigger={
                    <VmoRadio
                      toggle
                      checked={project.status === 'active'}
                      onClick={() => {
                        if (rolePermissions) {
                          let data
                          if (project.status === 'active') {
                            data = {status: 'inactive'}
                          } else if (['inactive', 'archived'].includes(project.status)) {
                            data = {status: 'active'}
                          }
                          dispatch(updateProjectStatus(project.id, data))
                        }
                      }}
                    />
                  }
                />
                <VmoTooltip
                  content="Analytics"
                  position="top center"
                  size="mini"
                  trigger={
                    <VmoButton icon>
                      <SvgIcon path="settings/activities" />
                    </VmoButton>
                  }
                />
                <VmoTooltip
                  content={rolePermissions ? 'Clone' : getPermissionTooltipContent({roleAccess: rolePermissions})}
                  position="top center"
                  size="mini"
                  trigger={
                    <VmoButton icon onClick={() => rolePermissions && dispatch(cloneProject(project.id))}>
                      <SvgIcon path="common/clone" />
                    </VmoButton>
                  }
                />
                <VmoTooltip
                  content={rolePermissions ? 'Edit' : getPermissionTooltipContent({roleAccess: rolePermissions})}
                  position="top center"
                  size="mini"
                  trigger={
                    <VmoButton
                      icon
                      onClick={() => {
                        if (rolePermissions) {
                          setIdToEdit(project.id)
                          setIsEditModalOpen(true)
                        }
                      }}
                    >
                      <SvgIcon path="common/edit2" />
                    </VmoButton>
                  }
                />
                <VmoTooltip
                  content={rolePermissions ? 'Delete' : getPermissionTooltipContent({roleAccess: rolePermissions})}
                  position="top center"
                  size="mini"
                  trigger={
                    <VmoButton
                      icon
                      onClick={() => {
                        if (rolePermissions) {
                          setIdToDelete(project.id)
                          setIsDeleteModalOpen(true)
                        }
                      }}
                    >
                      <SvgIcon path="common/delete" />
                    </VmoButton>
                  }
                />
              </div>
            </div>

            <div className="d-flex projects-listing-assign" style={{justifyContent: 'flex-start'}}>
              <div>
                <div className="user-position">Manager</div>
                <div className="user-list">
                  <VmoTooltip
                    content={getFullName(get(['managerId'], project, null))}
                    position="top center"
                    size="mini"
                    trigger={<span className="circle-user">{getSortName(get(['managerId'], project, null))}</span>}
                  />
                </div>
              </div>
              <div>
                <div className="user-position">Assignee</div>
                <div className="user-list">
                  {get(['assignedTo'], project, []).map(assignedId => {
                    return (
                      <VmoTooltip
                        key={assignedId}
                        content={getFullName(assignedId)}
                        position="top center"
                        size="mini"
                        trigger={<span className="circle-user">{getSortName(assignedId)}</span>}
                      />
                    )
                  })}
                </div>
              </div>
            </div>

            <div className="d-flex projects-listing-dates mt-3" style={{justifyContent: 'flex-start'}}>
              <div>
                <span>Created By</span>
                <VmoTooltip
                  content={getDateMonthYear(project.createdAt)}
                  size="mini"
                  position="top center"
                  trigger={<span>{getFullName(project.createdBy)}</span>}
                />
              </div>
              {project.updatedAt && (
                <div>
                  <span>Modified By</span>
                  <VmoTooltip
                    content={getDateMonthYear(project.updatedAt)}
                    size="mini"
                    position="top center"
                    trigger={<span>{getFullName(project.updatedBy)}</span>}
                  />
                </div>
              )}
            </div>
          </VmoCardContent>
        </VmoCard>
      )
    })
  }

  return (
    <>
      {renderProjectList()}

      {totalPages > 1 && (
        <KadenceCustomPagination
          totalPages={totalPages}
          activePage={currentPage}
          onPageChange={page => {
            setFilter(sort => ({...sort, page}))
          }}
        />
      )}
      {isEditModalOpen && <EditProjectModal open={isEditModalOpen} toggle={setIsEditModalOpen} id={idToEdit} />}
      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={() => dispatch(deleteProject({id: [idToDelete]}))}
          type={DELETE_PROJECT}
        />
      )}
    </>
  )
}

export default ProjectList
