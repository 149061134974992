/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import {
  VmoDropdown,
  VmoDropdownHeader,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoTooltip,
} from 'vmo-library'
import {copyToClipboardDropdown, removeDoubleQuotes} from 'utils/helper'
import {msgPlaceholder} from 'constants/campaign'
import EmailEditorTemplate from '../EmailEditor'

const Messages = props => {
  const {style, errors, control, editorRef, editorDefaultValue} = props

  const [templateValue, setTemplateValue] = useState(null)
  const [editorValue, setEditorValue] = useState(editorDefaultValue || '')

  const {getCampaignTemplateListData = []} = useSelector(store => store.settingsProductivity)

  useEffect(() => {
    if (templateValue) {
      setEditorValue(getCampaignTemplateListData.find(temp => temp.id === templateValue).textDesign)
    }
  }, [templateValue])

  useEffect(() => {
    if (editorDefaultValue) setEditorValue(editorDefaultValue)
  }, [editorDefaultValue])

  return (
    <div style={style} className="stepWrap px-4">
      <div className="d-flex py-4">
        <VmoForm className="errorLabel">
          <VmoFormField required className="error-bg-transparent">
            <label>Email Subject</label>
            <Controller
              name="campaign_email.subject"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  className="email-sub-input"
                  placeholder="Subject"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors?.campaign_email?.subject && {
                      content: removeDoubleQuotes(errors.campaign_email.subject.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
        <div className="action d-flex">
          <VmoTooltip
            size="mini"
            position="top center"
            content="Clipboard"
            trigger={
              <VmoDropdown icon="clipboard" direction="left" floating button className="icon basic">
                <VmoDropdownMenu>
                  <VmoDropdownHeader>
                    <b>Insert Template</b>
                  </VmoDropdownHeader>
                  {getCampaignTemplateListData.length > 0 ? (
                    getCampaignTemplateListData.map(temp => (
                      <VmoDropdownItem onClick={() => setTemplateValue(temp.id)}>{temp.name}</VmoDropdownItem>
                    ))
                  ) : (
                    <VmoDropdownItem style={{pointerEvents: 'none'}}>No Data Available</VmoDropdownItem>
                  )}
                </VmoDropdownMenu>
              </VmoDropdown>
            }
          />

          <VmoTooltip
            size="mini"
            position="top center"
            content="Placeholder"
            trigger={
              <VmoDropdown icon="file alternate" direction="left" floating button className="icon basic ml-2">
                <VmoDropdownMenu>
                  <VmoDropdownHeader>
                    <b>Copy Placeholder</b>
                  </VmoDropdownHeader>
                  {msgPlaceholder.map(option => (
                    <VmoDropdownItem
                      key={option.key}
                      onClick={e => copyToClipboardDropdown(e, option.value, option.toast)}
                    >
                      {option.text}
                    </VmoDropdownItem>
                  ))}
                </VmoDropdownMenu>
              </VmoDropdown>
            }
          />
        </div>
      </div>

      <EmailEditorTemplate emailRef={editorRef} defaultValue={editorValue} />
    </div>
  )
}

export default Messages
