import {EMAIL_STATS, EMAIL_OPEN_VS_TIME, EMAIL_CLICK_VS_TIME, EMAIL_TEMPLATE_STATS} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case EMAIL_STATS:
      return {
        ...state,
        emailStatsData: action.payload,
      }
    case EMAIL_OPEN_VS_TIME:
      return {
        ...state,
        emailOpenedVsTimeData: action.payload,
      }
    case EMAIL_CLICK_VS_TIME:
      return {
        ...state,
        emailClickedVsTimeData: action.payload,
      }
    case EMAIL_TEMPLATE_STATS:
      return {
        ...state,
        emailTemplateStatsData: action.payload,
      }
    default:
      return state
  }
}
