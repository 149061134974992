import {
  ARTICLE_RATINGS_ANALYTICS,
  CTR_STATS,
  FAILED_TERMS_TABLE,
  KB_PERFORMANCE_ANALYTICS,
  MOST_POPULAR_PAGES,
  SEARCH_ANALYTICS,
  SUCCESSFUL_TERMS_TABLE,
  TOTAL_ARTICLE_VIEWS,
  TOTAL_SEARCH_ANALYTICS,
  TOTAL_VIEWS_ANALYTICS,
  TOTAL_VISITORS_ANALYTICS,
  TOTAL_VISITORS_SEARCH,
} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    // articles
    case TOTAL_VISITORS_ANALYTICS:
      return {
        ...state,
        totalVisitorsData: payload,
      }

    case TOTAL_VIEWS_ANALYTICS:
      return {
        ...state,
        totalViewsData: payload,
      }

    case TOTAL_ARTICLE_VIEWS:
      return {
        ...state,
        totalArticleViewsData: payload,
      }

    case CTR_STATS:
      return {
        ...state,
        ctrStatsData: payload,
      }

    case ARTICLE_RATINGS_ANALYTICS:
      return {
        ...state,
        articleRatingsData: payload,
      }

    case KB_PERFORMANCE_ANALYTICS:
      return {
        ...state,
        kbPerformanceData: payload,
      }

    case MOST_POPULAR_PAGES:
      return {
        ...state,
        mostPopularPageData: payload,
      }

    // searches
    case TOTAL_VISITORS_SEARCH:
      return {
        ...state,
        totalVisitorSearchData: payload,
      }

    case SEARCH_ANALYTICS:
      return {
        ...state,
        searchAnalyticsData: payload,
      }

    case TOTAL_SEARCH_ANALYTICS:
      return {
        ...state,
        totalSearchGraphData: payload,
      }

    case SUCCESSFUL_TERMS_TABLE:
      return {
        ...state,
        successfulTermsTableData: payload,
      }

    case FAILED_TERMS_TABLE:
      return {
        ...state,
        failedTermsTableData: payload,
      }

    // general
    case 'UPDATE_SELECTED_KNOWLEDGEBASE':
      return {
        ...state,
        analyticsSelectedKb: payload,
      }

    case 'CLEAR_KNOWLEDGEBASE_ARTICLES':
      return {
        ...state,
        totalVisitorsData: {},
        totalViewsData: {},
        totalArticleViewsData: {},
        ctrStatsData: {},
        articleRatingsData: [],
        kbPerformanceData: [],
        mostPopularPageData: [],
      }

    case 'CLEAR_KNOWLEDGEBASE_SEARCHES':
      return {
        ...state,
        totalVisitorSearchData: {},
        searchAnalyticsData: {},
        totalSearchGraphData: [],
        successfulTermsTableData: {},
        failedTermsTableData: {},
      }

    default:
      return state
  }
}
