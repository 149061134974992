import {
  AVERAGE_RESOLUTION_TIME_SLA,
  FIRST_RESPONSE_TIME_SLA,
  RESOLVE_TARGET_OVER_TIME_SLA,
  RESPONSE_TARGET_OVER_TIME_SLA,
} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case FIRST_RESPONSE_TIME_SLA:
      return {
        ...state,
        firstResponseTimeSLAData: payload,
      }

    case AVERAGE_RESOLUTION_TIME_SLA:
      return {
        ...state,
        averageResolutionTimeSLAData: payload,
      }

    case RESOLVE_TARGET_OVER_TIME_SLA:
      return {
        ...state,
        resolveTargetOverTimeSLAData: payload,
      }

    case RESPONSE_TARGET_OVER_TIME_SLA:
      return {
        ...state,
        responseTargetOverTimeSLAData: payload,
      }

    default:
      return state
  }
}
