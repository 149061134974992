import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  VmoButton,
  VmoInput,
  VmoList,
  VmoMessage,
  VmoMessageHeader,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

import {get} from 'utils/helper'
import {mergeConversation} from 'actions/inbox'
import {EmailListItem} from './EmailListItem'

const MergeConversationModal = props => {
  const {isMergeModalOpen, setMergeModalOpen, conversationSubject, ConversationId, conversations, conversation} = props

  const dispatch = useDispatch()

  const [currentlySelectedItem, setCurrentlySelectedItem] = useState('None')
  const [conversationsForMerge, setConversationsForMerge] = useState([])

  const {searchedConversations} = useSelector(state => state.emailInbox, _.isEqual)

  // List of Conversation for merging
  useEffect(() => {
    if (searchedConversations && searchedConversations.length > 0) {
      setConversationsForMerge(searchedConversations)
    }
  }, [searchedConversations])

  useEffect(() => {
    if (conversations) {
      setConversationsForMerge(conversations)
    }
  }, [conversations])

  const doSearch = evt => {
    const searchText = evt.target.value // this is the search text
    if (searchText === '') {
      setConversationsForMerge(conversations)
    }
    // else {
    //   if (time) clearTimeout(time)
    //   setTime(
    //     setTimeout(() => {
    //       dispatch(getSearchedMailBoxConversation({text: searchText}))
    //     }, 300)
    //   )
    // }
  }
  const SearchInput = () => {
    return (
      <VmoInput
        iconPosition="left"
        icon="search"
        fluid
        placeholder="Search email..."
        onChange={e => {
          doSearch(e)
        }}
      />
    )
  }
  function renderMailItem() {
    if (conversationsForMerge) {
      return conversationsForMerge.map(conversationItem => {
        if (conversationItem.id !== ConversationId)
          return (
            <EmailListItem
              emailListItemData={conversationItem}
              key={`conversation${conversationItem.id}`}
              id={conversationItem.id}
              currentlySelectedItem={currentlySelectedItem}
              title={get(['from[0]', 'email'], conversationItem) || 'UNKNOWN'}
              subTitle={conversationItem.subject}
              mailText={get(['message', 'messageText'], conversationItem) || '-'}
              mailDraftStatus={get(['message', 'isDraft'], conversationItem)}
              isRead={conversationItem.isRead}
              selected="None"
              onClick={e => {
                setCurrentlySelectedItem(conversationItem.id)
              }}
              checkedConversations={[]}
              updatedAt={conversationItem.updatedAt}
              fromObj={Array.isArray(conversationItem.from) ? conversationItem.from[0] : []}
              isInbound={conversationItem.isInbound}
              noHover
            />
          )
      })
    }
  }

  const renderConversationBodyForMerge = () => {
    return (
      <div>
        {SearchInput()}
        <VmoList className="emailGridList" style={{height: 'calc(100vh - 28rem)'}} selection verticalAlign="middle">
          {renderMailItem()}
        </VmoList>
      </div>
    )
  }

  const onMergeConversation = () => {
    const data = {
      targetId: get(['id'], conversation),
      ids: [currentlySelectedItem],
    }
    dispatch(mergeConversation(data))
    setMergeModalOpen(false)
  }

  return (
    <VmoModal size="tiny" open={isMergeModalOpen} onClose={() => setMergeModalOpen(false)} closeIcon>
      <VmoModalHeader>Merge conversation</VmoModalHeader>
      <VmoModalContent className="mergePopUpBody">
        <VmoMessage>
          <VmoMessageHeader
            className="mergeHeader"
            style={{
              position: 'relative',
              right: '12px',
              Top: '-10px',
            }}
          >
            Subject : {conversationSubject}
          </VmoMessageHeader>
          <p>Select from below mails to merge with above mail:</p>
        </VmoMessage>
        {renderConversationBodyForMerge()}
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setMergeModalOpen(false)}>
          Cancel
        </VmoButton>
        <VmoButton
          primary
          disabled={!currentlySelectedItem || currentlySelectedItem === 'None' || currentlySelectedItem === []}
          onClick={() => onMergeConversation()}
        >
          Merge
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default MergeConversationModal
