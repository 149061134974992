import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'
import {MOVE_PROJECT_MILESTONE} from 'actions/types'
import {moveProjectMilestone} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import {milestoneMoveSchema} from 'validation/Projects/projects/milestone.schema'
import SvgIcon from 'components/common/SvgIcon'

const MoveMilestoneModal = ({open, toggle, projectId, milestoneId, milestoneName}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsList = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(milestoneMoveSchema),
    defaultValues: {
      name: milestoneName,
      projectId: null,
    },
  })

  useEffect(() => {
    if (successLabels.includes(MOVE_PROJECT_MILESTONE)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    dispatch(moveProjectMilestone({projectId, milestoneId, data}))
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>Move Milestone to Another Project</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <div className="info-header">
              <label>Name</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify name of the milestone"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="name"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.name && {content: removeDoubleQuotes(errors.name.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required>
            <div className="info-header">
              <label>Project</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Select project in which you want to move the milestone"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="projectId"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  options={projectsList
                    .filter(project => project.id !== Number(projectId))
                    .map(({id, title}) => ({key: id, value: id, text: title}))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.projectId && {content: removeDoubleQuotes(errors.projectId.message)}}
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          Move Milestone
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default MoveMilestoneModal
