import Joi from 'joi'

export const appearenceDefaultValue = {
  brandColor: {
    color: 'linear-gradient(to right,#5eabff,#4c9df9,#3a90f3,#2782ec,#0d74e5)',
    primaryColor: '#0d74e5',
  },
  buttonIcon: '1',
  position: 'right',
  spacingRight: 25,
  spacingBottom: 25,
  disableChat: false,
  showMeeting: false,
  showWhatsapp: false,
  whatsappLink: '',
  whatsappMsg: '',
  showFb: false,
  fbLink: '',
  fbMsg: '',
  showInsta: false,
  instaLink: '',
  instaMsg: '',
  showQuickLinks: false,
  // quickLinksHeading: 'Useful Links',
  quickLinks: [
    {
      name: '',
      url: '',
    },
  ],
}

export const appearenceSchema = Joi.object({
  brandColor: Joi.object().required(),
  buttonIcon: Joi.string().required(),
  position: Joi.string().valid('left', 'right').required(),
  spacingRight: Joi.number().min(0).max(40).required(),
  spacingBottom: Joi.number().min(0).max(30).required(),
  disableChat: Joi.boolean(),
  showMeeting: Joi.boolean(),
  showWhatsapp: Joi.boolean(),
  whatsappLink: Joi.any().when('showWhatsapp', {
    switch: [
      {
        is: true,
        then: Joi.string()
          .trim()
          .domain()
          .regex(/^.*whatsapp.*$/)
          .min(3)
          .label('whatsapp')
          .required()
          .messages({
            'string.min': `Length must be at least 3 characters`,
            'string.pattern.base': `Please enter valid WhatsApp link`,
          }),
      },
    ],
    otherwise: Joi.optional(),
  }),
  // whatsappMsg: Joi.any().when('showWhatsapp', {
  //   switch: [
  //     {
  //       is: true,
  //       then: Joi.string().trim().min(10).max(40).label('whatsapp message').required().messages({
  //         'string.min': `Length must be at least 10 characters`,
  //         'string.max': `Length must be less than or equal to 40 characters`,
  //       }),
  //     },
  //   ],
  //   otherwise: Joi.optional(),
  // }),
  whatsappMsg: Joi.string().allow(''),
  showFb: Joi.boolean(),
  fbLink: Joi.any().when('showFb', {
    switch: [
      {
        is: true,
        then: Joi.string()
          .trim()
          .domain()
          .regex(/^.*facebook.*$/)
          .min(3)
          .label('facebook')
          .required()
          .messages({
            'string.min': `Length must be at least 3 characters`,
            'string.pattern.base': `Please enter valid Facebook link`,
          }),
      },
    ],
    otherwise: Joi.optional(),
  }),
  // fbMsg: Joi.any().when('showFb', {
  //   switch: [
  //     {
  //       is: true,
  //       then: Joi.string().trim().min(10).max(40).label('facebook message').required().messages({
  //         'string.min': `Length must be at least 10 characters`,
  //         'string.max': `Length must be less than or equal to 40 characters`,
  //       }),
  //     },
  //   ],
  //   otherwise: Joi.optional(),
  // }),
  fbMsg: Joi.string().allow(''),
  showInsta: Joi.boolean(),
  instaLink: Joi.any().when('showInsta', {
    switch: [
      {
        is: true,
        then: Joi.string()
          .trim()
          .domain()
          .regex(/^.*instagram.*$/)
          .min(3)
          .label('instagram')
          .required()
          .messages({
            'string.min': `Length must be at least 3 characters`,
            'string.pattern.base': `Please enter valid Instagram link`,
          }),
      },
    ],
    otherwise: Joi.optional(),
  }),
  // instaMsg: Joi.any().when('showInsta', {
  //   switch: [
  //     {
  //       is: true,
  //       then: Joi.string().trim().min(10).max(40).label('instagram message').required().messages({
  //         'string.min': `Length must be at least 10 characters`,
  //         'string.max': `Length must be less than or equal to 40 characters`,
  //       }),
  //     },
  //   ],
  //   otherwise: Joi.optional(),
  // }),
  instaMsg: Joi.string().allow(''),
  showQuickLinks: Joi.boolean(),
  // quickLinksHeading: Joi.any().when('showQuickLinks', {
  //   switch: [
  //     {
  //       is: true,
  //       then: Joi.string().trim().min(3).max(20).required().label('Heading'),
  //     },
  //   ],
  // }),
  quickLinks: Joi.array().items(
    Joi.object().keys({
      name: Joi.string().min(3).max(20).allow(null, '').messages({
        'string.empty': 'Name is not allowed to be empty',
        'string.min': `Length must be at least 3 characters`,
        'string.max': `Length must be less or equal to 20 characters`,
      }),
      url: Joi.string().uri().allow(null, '').messages({
        'string.empty': 'Url is not allowed to be empty',
        'string.uri': 'Url must be a valid uri',
      }),
    })
  ),
})

export const configureDefaultValue = {
  heading: '',
  subHeading: '',
  language: 'en',
  replyTime: '',
  replyTimeInputValue: ' ',
  msgTimeout: 60,
  showTeamMembers: true,
  singleConversation: false,
  emailFollowup: false,
  isName: true,
  isEmail: true,
  isNumber: false,
  submitBtnText: 'Submit',
  // submitMsg: "",
}

export const configureSchema = Joi.object({
  heading: Joi.string().trim().max(30).required().messages({
    'string.max': `Length must be less than or equal to 30 characters`,
  }),
  subHeading: Joi.string().trim().max(50).required().messages({
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  language: Joi.string().min(2).max(2).messages({
    'string.min': `Length must be at least 2 characters`,
    'string.max': `Length must be less than or equal to 2 characters`,
  }),
  replyTime: Joi.string().trim(),
  replyTimeInputValue: Joi.string().max(30).label('reply time input value').messages({
    'string.max': `Length must be less than or equal to 30 characters`,
  }),
  msgTimeout: Joi.number().min(60).max(999).required().messages({
    'number.base': `Must be a number`,
    'number.min': `Must be 60 or above`,
  }),
  showTeamMembers: Joi.boolean(),
  singleConversation: Joi.boolean(),
  emailFollowup: Joi.boolean(),
  isName: Joi.boolean(),
  isEmail: Joi.boolean(),
  isNumber: Joi.boolean(),
  submitBtnText: Joi.string().trim().empty('').max(20).label('submit button text').messages({
    'string.max': `Length must be less than or equal to 20 characters`,
  }),
  // submitMsg: Joi.string().trim().empty("").max(30),
})

export const advanceDefaultValue = {
  inAttach: false,
  outAttach: false,
  showRead: false,
  sneakPeek: false,
  isDisableMobile: true,
  enableVoiceMessage: true,
  processData: false,
  comMsg: '',
  optInBtn: '',
  optOutBtn: '',
}

export const advanceSchema = Joi.object({
  inAttach: Joi.boolean(),
  outAttach: Joi.boolean(),
  showRead: Joi.boolean(),
  sneakPeek: Joi.boolean(),
  isDisableMobile: Joi.boolean(),
  enableVoiceMessage: Joi.boolean(),
  processData: Joi.boolean(),
  comMsg: Joi.any().when('processData', {
    switch: [
      {
        is: true,
        then: Joi.string().trim().max(20).label('communication message').messages({
          'string.max': `Length must be less than or equal to 20 characters`,
        }),
      },
    ],
    otherwise: Joi.optional(),
  }),
  optInBtn: Joi.any().when('processData', {
    switch: [
      {
        is: true,
        then: Joi.string().trim().max(20).label('Opt-In').messages({
          'string.max': `Length must be less than or equal to 20 characters`,
        }),
      },
    ],
    otherwise: Joi.optional(),
  }),
  optOutBtn: Joi.any().when('processData', {
    switch: [
      {
        is: true,
        then: Joi.string().trim().max(20).label('Opt-Out').messages({
          'string.max': `Length must be less than or equal to 20 characters`,
        }),
      },
    ],
    otherwise: Joi.optional(),
  }),
})

export const offlineExperienceDefaultValue = {
  email: true,
  name: true,
  phone: true,
  message: true,
  setting: 0,
  thanksMsg: 'Thanks for submitting your query...',
}

export const offlineExperienceSchema = Joi.object({
  email: Joi.boolean(),
  name: Joi.boolean(),
  phone: Joi.boolean(),
  message: Joi.boolean(),
  setting: Joi.number(),
  thanksMsg: Joi.string().max(50).trim().label('thanks message').messages({
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
})
