import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {DELETE_SALES_ORDERS_PREFIX, GET_SALES_ORDERS_PREFIX} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {getSalesOrdersPrefixes, deleteSalesOrdersPrefix} from 'actions/salesOrders'
import SettingLayout from 'layouts/settingLayout'
import {startCase} from 'utils/helper'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {roleAccessDeniedContent} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import PrefixModal from './PrefixModal'

function PrefixList() {
  const dispatch = useDispatch()

  const [prefixModal, setPrefixModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [prefixId, setPrefixId] = useState()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {financePermissions} = useUserPermissions()
  const manageSalesOrderSettingsPermissions = financePermissions?.manage_sales_order_settings

  const {data: salesOrdersPrefixList = []} = useApiResponse({
    action: getSalesOrdersPrefixes,
    enabled: true,
    label: GET_SALES_ORDERS_PREFIX,
    storePath: 'salesOrders.salesOrdersPrefixList',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_SALES_ORDERS_PREFIX)) {
      dispatch(getSalesOrdersPrefixes())
    }
  }, [successLabels, dispatch])

  return (
    <SettingLayout
      header="Prefix"
      subHeader="Add prefix which you can use to create Sales Orders."
      headerButton={{
        onClick: () => manageSalesOrderSettingsPermissions && setPrefixModal(true),
        content: 'Add',
      }}
      table
      headerDivider
      lockRole={!manageSalesOrderSettingsPermissions}
    >
      <VmoTable selectable basic>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Name</VmoTableHeaderCell>
            <VmoTableHeaderCell>Action</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>
          {!salesOrdersPrefixList.length && (
            <tr>
              <td colSpan="6" className="emptyValue">
                No Data Available
              </td>
            </tr>
          )}

          {salesOrdersPrefixList.map(({prefix, id}, index) => (
            <VmoTableRow key={id} className="tableLink">
              <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
              <VmoTableCell>
                <VmoHeader as="h5">{startCase(prefix)}</VmoHeader>
              </VmoTableCell>
              <VmoTableCell>
                <VmoTooltip
                  content={!manageSalesOrderSettingsPermissions ? roleAccessDeniedContent : 'Delete'}
                  size="mini"
                  position="top center"
                  trigger={
                    <VmoButton
                      icon
                      onClick={e => {
                        if (manageSalesOrderSettingsPermissions) {
                          setPrefixId(id)
                          setDeleteModal(true)
                        }
                        e.stopPropagation()
                      }}
                    >
                      <SvgIcon path="common/delete" />
                    </VmoButton>
                  }
                />
              </VmoTableCell>
            </VmoTableRow>
          ))}
        </VmoTableBody>
      </VmoTable>
      {prefixModal && <PrefixModal open={prefixModal} toggle={() => setPrefixModal(false)} />}
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete this Prefix?"
          deleteAction={deleteSalesOrdersPrefix}
          idTobeDeleted={prefixId}
          type={DELETE_SALES_ORDERS_PREFIX}
        />
      )}
    </SettingLayout>
  )
}

export default PrefixList
