import {KADENCE_SETTING} from './endpoint'
import {
  GET_ALL_TASK_TEMPLATES,
  CREATE_TASK_TEMPLATE,
  EDIT_TASK_TEMPLATE,
  DELETE_TASK_TEMPLATE,
  GET_TASK_TEMPLATE,
  GET_ALL_TASK_AUTOMATIONS,
  CREATE_TASK_AUTOMATION,
  EDIT_TASK_AUTOMATION,
  DELETE_TASK_AUTOMATION,
  GET_TASK_AUTOMATION,
} from './types'
import {apiAction} from './api'

export function createTaskTemplate(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/task-templates`,
    method: 'POST',
    label: CREATE_TASK_TEMPLATE,
    data,
    showToast: true,
    successMessage: 'Task template created',
  })
}

export function getAllTaskTemplate() {
  return apiAction({
    url: `${KADENCE_SETTING}/task-templates`,
    method: 'GET',
    label: GET_ALL_TASK_TEMPLATES,
  })
}

export function getTaskTemplate(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/task-templates/${id}`,
    method: 'GET',
    label: GET_TASK_TEMPLATE,
  })
}

export function deleteTaskTemplate(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/task-templates/${id}`,
    method: 'DELETE',
    label: DELETE_TASK_TEMPLATE,
    showToast: true,
    successMessage: 'Task template deleted',
  })
}

export function editTaskTemplate(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/task-templates/${id}`,
    method: 'PUT',
    label: EDIT_TASK_TEMPLATE,
    data,
    showToast: true,
    successMessage: 'Task template updated',
  })
}

export function createTaskAutomation(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/tasks/task-automations`,
    method: 'POST',
    label: CREATE_TASK_AUTOMATION,
    data,
    showToast: true,
    successMessage: 'Task Automation created',
  })
}

export function getAllTaskAutomation() {
  return apiAction({
    url: `${KADENCE_SETTING}/tasks/task-automations`,
    method: 'GET',
    label: GET_ALL_TASK_AUTOMATIONS,
  })
}

export function getTaskAutomation(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/tasks/task-automations/${id}`,
    method: 'GET',
    label: GET_TASK_AUTOMATION,
  })
}

export function deleteTaskAutomation(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/tasks/task-automations/${id}`,
    method: 'DELETE',
    label: DELETE_TASK_AUTOMATION,
    showToast: true,
    successMessage: 'Task Automation deleted',
  })
}

export function editTaskAutomation(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/tasks/task-automations/${id}`,
    method: 'PUT',
    label: EDIT_TASK_AUTOMATION,
    data,
    showToast: true,
    successMessage: 'Task Automation updated',
  })
}
