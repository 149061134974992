import {apiAction} from './api'
import {KADENCE_ANALYTICS, KADENCE_ACCOUNT_V1} from './endpoint'
import {CONVERSATION_BY_CHANNELS, MY_ACTIVITIES, PROJECTS_BY_BUDGET_TYPE, PROJECTS_TIMES_BY_USER} from './types'

export function myActivities() {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/global/my-activities`,
    method: 'GET',
    label: MY_ACTIVITIES,
  })
}

export function conversationByChannel(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/home/inflowbychannel`,
    method: 'POST',
    label: CONVERSATION_BY_CHANNELS,
    data,
  })
}

export function projectsByBudgetType(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/home/projects-by-budget-type`,
    method: 'POST',
    label: PROJECTS_BY_BUDGET_TYPE,
    data,
  })
}

export function projectTimesByUser(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/home/project-times-by-user`,
    method: 'POST',
    label: PROJECTS_TIMES_BY_USER,
    data,
  })
}
