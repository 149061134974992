import Joi from 'joi'
import {inputNumberValidation} from 'validation'

export const createDealSchema = Joi.object({
  name: Joi.string().required().min(2).max(20).trim().messages({
    'string.empty': `Name is not allowed to be empty`,
    'any.required': `Name is a required field`,
    'string.min': `Name length must be at least 2 characters long`,
    'string.max': `Name length must be less than or equal to 20 characters`,
  }),
  stageId: Joi.number().required().messages({
    'number.base': 'Stage must be specified',
  }),
  closeDate: Joi.date().iso().allow('', null),
  assigneeId: Joi.number().required().messages({
    'number.base': 'Assignee must be specified',
  }),
  monetaryValue: Joi.number().min(0).allow(null, 0, ''),
  currencyId: Joi.number().required(),
  sourceId: Joi.number().allow('', null),
  priority: Joi.string().allow('', null),
  win: Joi.number().allow('', null).min(0).max(100).messages({
    'number.max': 'win must be less than or equal to 100',
  }),
  description: Joi.string().allow('', null).max(250).messages({
    'string.max': `Length must be less than or equal to 250 characters`,
  }),
  labelIds: Joi.array().items(Joi.number()).label('Tags').allow('', null),
})

export const updateDealSchema = Joi.object().keys({
  name: Joi.string(),
  stageId: Joi.number(),
  sourceId: Joi.number().allow(null),
  priority: Joi.string(),
  win: Joi.number().allow(null).min(0).max(100),
  monetaryValue: Joi.number().min(0).allow(null),
  currencyId: Joi.number().required(),
  description: Joi.string().allow('', null),
  closeDate: Joi.date().iso().allow(null),
  isFollowed: Joi.boolean(),
  assigneeId: Joi.number().allow('', null),
  labelIds: Joi.array().items(Joi.number()).label('Tags'),
})

export const dealLogInteractionsSchema = Joi.object().keys({
  category: Joi.string().required(),
  interactionTime: Joi.date().iso().required(),
  title: Joi.string().required(),
  description: Joi.string().allow('', null),
  duration: Joi.number(),
  outcome: Joi.string(),
  isFollowUp: Joi.boolean(),
  followUpType: Joi.string(),
  followUpTime: Joi.date().iso().allow(null),
})

export const dealMultipleUpdateSchema = Joi.object().keys({
  name: Joi.string(),
  stageId: Joi.number(),
  sourceId: Joi.number(),
  priority: Joi.string(),
  win: Joi.number().allow(null).min(0).max(100),
  monetaryValue: Joi.number().min(0),
  currency: Joi.string(),
  description: Joi.string().allow('', null),
  closeDate: Joi.date().iso().allow(null),
  labelIds: Joi.array().items(Joi.number()).label('Tags'),
})

export const filterDealSchema = Joi.object().keys({
  assigneeId: inputNumberValidation.allow('', null).label('Owner'),
  labelIds: Joi.number().allow('', null).label('Tags'),
  currency: Joi.string().allow('', null),
  company: Joi.string().allow('', null),
  isFollowed: Joi.string().allow('', null),
  monetaryValueFrom: Joi.number().min(0).allow('', null, 0),
  monetaryValueTo: Joi.number().min(0).allow('', null, 0),
  interactionsFrom: Joi.number().min(0).allow('', null, 0),
  interactionsTo: Joi.number().min(0).allow('', null, 0),
  lastContactedFrom: Joi.date().iso().allow(null),
  lastContactedTo: Joi.date().iso().allow(null),
  inactiveDaysFrom: Joi.number().min(0).allow('', null, 0),
  inactiveDaysTo: Joi.number().min(0).allow('', null, 0),
  createdAtFrom: Joi.date().iso().allow(null),
  createdAtTo: Joi.date().iso().allow(null),
  sourceId: Joi.number().allow('', null),
  status: Joi.string().allow('', null),
  stageId: Joi.number().allow('', null),
})

export const abandonedModalSchema = Joi.object({
  closedNote: Joi.string().trim().allow('', null),
  closedAt: Joi.date().iso().required(),
})
export const lostModalSchema = Joi.object({
  lossReasonId: Joi.number().required().messages({
    'number.base': 'Loss Reason is not allowed to be empty',
  }),
  closedNote: Joi.string().trim().allow('', null),
  closedAt: Joi.date().iso().required(),
})
