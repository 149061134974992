import {
  DEPARTMENTS_FOR_ANNOUNCEMENT,
  EMPLOYEES_FOR_ANNOUNCEMENT,
  GET_ALL_ANNOUNCEMENT,
  GET_SINGLE_ANNOUNCEMENT,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case EMPLOYEES_FOR_ANNOUNCEMENT:
      return {
        ...state,
        getEployeesListData: action.payload,
      }
    case DEPARTMENTS_FOR_ANNOUNCEMENT:
      return {
        ...state,
        getDepartmentsListData: action.payload,
      }
    case GET_ALL_ANNOUNCEMENT:
      return {
        ...state,
        getAllAnnouncementList: action.payload,
      }
    case GET_SINGLE_ANNOUNCEMENT:
      return {
        ...state,
        getSingleAnnouncementData: action.payload,
      }
    default:
      return state
  }
}
