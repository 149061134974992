/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {joiResolver} from '@hookform/resolvers'
import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoColorPicker,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'
import {ADD_DEPARTMENT, EDIT_DEPARTMENT, GET_USERS, GET_SINGLE_DEPARTMENT} from 'actions/types'

import {addDepartment, getSingleDepartment, editDepartment, getDepartment} from 'actions/employees'
import {removeDoubleQuotes} from 'utils/helper'
import {createEmployeesSettingSchema} from 'validation/Hr/employees.schema'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'

function CreateModal(props) {
  const {open, toggleModal, editId, departmentToggle} = props
  const dispatch = useDispatch()

  const [userOption, setUserOption] = useState([])
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const initialCreateInfo = {
    department: '',
    headOfDepartment: null,
    color: '',
  }

  const {errors, control, handleSubmit, watch, reset} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(createEmployeesSettingSchema),
    defaultValues: initialCreateInfo,
  })

  const watchColor = watch('color')

  useEffect(() => {
    return () => {
      departmentToggle('')
    }
  }, [departmentToggle])

  useEffect(() => {
    if (successLabels.includes(ADD_DEPARTMENT) || successLabels.includes(EDIT_DEPARTMENT)) {
      toggleModal(false)
      dispatch(getDepartment())
    }
  }, [successLabels, toggleModal, dispatch])

  const {data: getSingleDepartmentData = {}} = useApiResponse({
    action: getSingleDepartment,
    payload: editId,
    dependency: [editId],
    enabled: editId,
    label: GET_SINGLE_DEPARTMENT,
    storePath: 'employees.getSingleDepartmentData',
    onSuccess: () => {
      reset({
        department: getSingleDepartmentData?.department,
        color: getSingleDepartmentData?.color,
        headOfDepartment: getSingleDepartmentData.headOfDepartment,
      })
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list)
    },
  })

  const handleSave = data => {
    if (editId) {
      dispatch(editDepartment(editId, data))
    } else {
      dispatch(addDepartment(data))
    }
  }

  return (
    <VmoModal
      size="tiny"
      open={open}
      onClose={() => toggleModal(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
    >
      <VmoModalHeader>Create New Department</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Name</label>
            <Controller
              name="department"
              control={control}
              render={({onChange, value}) => (
                <VmoFormInput
                  type="text"
                  placeholder="department"
                  onChange={(e, {value}) => onChange(value)}
                  value={value}
                  error={
                    errors.department && {
                      content: removeDoubleQuotes(errors.department.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>

          <VmoFormField required>
            <div className="info-header">
              <label>Color</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="color will help to differentiate between departments"
                size="mini"
                psoition="top center"
              />
            </div>
            <div
              style={{
                padding: 5,
                background: watchColor,
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
              }}
              onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
            >
              <div
                style={{
                  width: '50px',
                  height: '15px',
                  borderRadius: '2px',
                  background: `#${watchColor}`,
                }}
              />
            </div>

            <div
              style={{
                position: 'absolute',
                zIndex: '2',
                visibility: isColorPickerOpen ? '' : 'hidden',
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                }}
                onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
              />
              <Controller
                control={control}
                name="color"
                render={({onChange, value}) => <VmoColorPicker color={value} onChange={value => onChange(value.hex)} />}
              />
            </div>
          </VmoFormField>

          <VmoFormField required>
            <label>Head of Department</label>
            <Controller
              name="headOfDepartment"
              render={({onChange, value, onBlur}) => (
                <VmoFormSelect
                  fluid
                  options={userOption}
                  selection
                  placeholder="Head Of Department"
                  onBlur={onBlur}
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  error={
                    errors?.headOfDepartment && {
                      content: removeDoubleQuotes(errors?.headOfDepartment?.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggleModal(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(data => handleSave(data))}>
          {editId ? 'Save' : 'Add'}
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default CreateModal
