import Joi from 'joi'
import {emailValidation, inputNumberValidation} from 'validation'

export const createMeetingSchema = Joi.object({
  meetingAvatar: Joi.any().allow(null),
  meetingHeading: Joi.string().max(60).required().trim().messages({
    'string.empty': `Meeting Heading is not allowed to be empty`,
    'string.max': `Meeting Heading length must be less then or equal to 60 characters`,
  }),
  // url: Joi.string().required(),
  ownerDetail: Joi.boolean(),
  assigneeId: inputNumberValidation.allow(null, '').label('Owner'),
  ownerDesignation: Joi.string().allow('', null),
  ownerEmail: emailValidation.allow(null, ''),
  ownerPhoneNo: Joi.string()
    .trim()
    .max(20)
    .regex(/^\+?[0-9]{4,20}$/)
    .allow(null, '')
    .messages({
      'string.pattern.base': 'Enter a valid phone number',
      'string.empty': `Phone cannot be an empty field`,
      'any.required': `Phone is required`,
    }),
  ownerSocialMedia: Joi.array().items(
    Joi.object({
      category: Joi.string().allow(null, ''),
      url: Joi.string().domain().allow(null, '').messages({
        'string.domain': `URL must contain a valid domain name`,
      }),
    })
  ),
  displayMessage: Joi.string().allow(null, ''),
  meetingDuration: Joi.array().required(),
  meetingLocation: Joi.string().allow(null, ''),
  inviteSubject: Joi.string().allow(null, ''),
  inviteDescription: Joi.string().allow(null, ''),
  meetingLanguage: Joi.string().allow(null, ''),
  meetingDateAndTimeFormat: Joi.string().allow(null, ''),
  meetingTimeZone: Joi.string().allow('', null),
  meetingAvailableTimes: Joi.array(),
  meetingScheduled: Joi.boolean(),
  meetingScheduleValue: Joi.number().allow(null, ''),
  meetingNoticeTime: Joi.number().allow(null, ''),
  meetingBufferTime: Joi.number().allow(null, ''),
  meetingTimeFrequency: Joi.number().allow(null, ''),
  mailNotification: Joi.boolean(),
  mailReminder: Joi.boolean(),
  videoConferenceLink: Joi.string().allow('', null),
  meetingFormFields: Joi.array(),
  formFieldWithKnownValue: Joi.boolean(),
  submitFormIfAllKnownValue: Joi.boolean(),
  termsAndCondition: Joi.boolean(),
  privacyTitle: Joi.string(),
  privacyText: Joi.string(),
  privacyCheck: Joi.string(),
  createdBy: inputNumberValidation.allow(null, ''),
})

export const sendSampleEmail = Joi.object({
  email: emailValidation.required(),
})
