import Joi from 'joi'

export const createBudgetPlannerSchema = Joi.object({
  name: Joi.string().trim().min(2).max(20).required().messages({
    'string.min': `Length must me at least 2 charactes`,
    'string.max': `Length must be less than or equal to 20 characters`,
  }),
  compareWith: Joi.string().required(),
  compareType: Joi.string().required(),
  rangePeriod: Joi.string().required(),
  startMonth: Joi.number().when('rangePeriod', {
    is: 'date',
    then: Joi.required(),
  }),
  startYear: Joi.number().when('rangePeriod', {
    is: 'date',
    then: Joi.required(),
  }),
  endMonth: Joi.number().when('rangePeriod', {
    is: 'date',
    then: Joi.required(),
  }),
  endYear: Joi.number().when('rangePeriod', {
    is: 'date',
    then: Joi.required(),
  }),
  rangeYear: Joi.number().when('rangePeriod', {
    is: 'year',
    then: Joi.required(),
  }),
  rangeBreakdown: Joi.string().required(),
  ranges: Joi.array().items(
    Joi.object().keys({
      month: Joi.number(),
      quarter: Joi.number(),
      year: Joi.number(),
      value: Joi.number(),
    })
  ),
  rangeTotal: Joi.number(),
  rangeBreakdownBy: Joi.string(),
  userId: Joi.number().allow('', null),
  contactId: Joi.number().allow('', null),
  projectId: Joi.number().allow('', null),
  currencyId: Joi.number().allow('', null),
})
