import Joi from 'joi'
import {startCase} from 'utils/helper'
import {FILTERS} from 'constants/campaign'

const getType = (type, label = 'label') => {
  switch (type) {
    case 'text':
      return Joi.string()
        .trim()
        .required()
        .messages({
          'string.empty': `${startCase(label)} is required`,
          'any.required': `${startCase(label)} is required`,
          'string.min': `${startCase(label)} must be at least 1 characters long`,
        })

    case 'number':
      return Joi.number()
        .required()
        .min(0)
        .messages({
          'string.empty': `${startCase(label)} is required`,
          'any.required': `${startCase(label)} is required`,
          'number.min': `Value must be greater or equal to 0`,
        })

    case 'date':
      return Joi.date()
        .required()
        .messages({
          'string.empty': `${startCase(label)} cannot be an empty field`,
          'any.required': `${startCase(label)} is required`,
          'string.min': `${startCase(label)} must be at least 1 characters long`,
        })

    case 'dropdown':
      return Joi.string()
        .trim()
        .required()
        .messages({
          'string.empty': `${startCase(label)} is required`,
          'any.required': `${startCase(label)} is required`,
        })

    default:
      return Joi.any().optional()
  }
}

/* ------------------------------ trigger message ----------------------------------*/
const filter = Joi.object().keys({
  key: Joi.string().trim().required().messages({
    'string.empty': `Field is required`,
    'any.required': `Field is required`,
    'string.min': `Field must be at least 1 characters long`,
  }),
  condition: Joi.string().trim().required().messages({
    'string.empty': `Condition cannot be an empty field`,
    'any.required': `Condition is required`,
    'string.min': `Condition must be at least 1 characters long`,
  }),
  primaryValue: Joi.any().when(Joi.ref('key'), {
    switch: FILTERS.map(item => ({
      is: item.key,
      then: getType(item.primaryValueType, item.primaryValueLabel),
    })),
    otherwise: Joi.optional(),
  }),
  secondryValue: Joi.any().when(Joi.ref('key'), {
    switch: FILTERS.map(item => ({
      is: item.key,
      then: getType(item.secondryValueType, item.secondryValueLabel),
    })),
    otherwise: Joi.optional(),
  }),
})

const ruleFilter = Joi.object().keys({
  operator: Joi.string().valid('or', 'and').optional(),
  filters: Joi.array().items(filter).required(),
})

export const campaignCreate = Joi.object({
  matchCase: Joi.string().valid('or', 'and').required(),
  rules: Joi.array().items(ruleFilter).required(),
})

export const oneTimeEmailUserAudienceSchema = Joi.object({
  campaignBy: Joi.string().valid('bySegment', 'byTag', 'byCsv'),
  segmentIds: Joi.array().when('campaignBy', {
    is: Joi.string().valid('bySegment'),
    then: Joi.array().items().min(1).required().label('Segment').messages({'array.min': `Segment must be specified`}),
  }),
  labelBy: Joi.string()
    .when('campaignBy', {
      is: Joi.string().valid('byTag'),
      then: Joi.string().valid('contacts', 'conversations'),
      otherwise: Joi.string().allow(null, ''),
    })
    .label('tag')
    .messages({'any.only': `tag must be specified`}),
  labelIds: Joi.array()
    .when('campaignBy', {is: Joi.string().valid('byTag'), then: Joi.array().items(Joi.number().integer()).min(1)})
    .label('tag')
    .messages({'array.min': `Tag must be specified`}),
  csv: Joi.any()
    .when('campaignBy', {
      is: Joi.string().valid('byCsv'),
      then: Joi.any().invalid(null, '').required(),
      otherwise: Joi.string().allow(null, ''),
    })
    .label('CSV File')
    .messages({'any.invalid': `Please choose a CSV File`}),
  labelByCsv: Joi.string().when('campaignBy', {
    is: Joi.string().valid('byCsv'),
    then: Joi.string().min(2).max(20).required().label('tag'),
    otherwise: Joi.string().allow(null, ''),
  }),
})

export const oneTimeMessageSchema = Joi.object({
  name: Joi.string().trim().min(1).max(40).messages({
    'string.empty': `Name cannot be an empty field`,
    'any.required': `Name is required`,
    'string.min': `Length must be at least 1 characters`,
    'string.max': `Length must be less than or equal to 40 characters`,
  }),
  userId: Joi.number(),
  type: Joi.number(),
  visibility: Joi.number(),
  matchCase: Joi.string().valid('or', 'and').required(),
  rules: Joi.array().items(ruleFilter).required(),
})

export const oneTimeEmailSchema = Joi.object({
  name: Joi.string().trim().min(1).max(40).messages({
    'string.empty': `Name cannot be an empty field`,
    'any.required': `Name is required`,
    'string.min': `Length must be at least 1 characters`,
    'string.max': `Length must be less than or equal to 40 characters`,
  }),
  type: Joi.number(),
  userId: Joi.number().messages({
    'number.base': `Team member needs to be selected`,
  }),
  // matchCase: Joi.string().valid('or', 'and').required(),
  // rules: Joi.array().items(ruleFilter).required(),
  timezone: Joi.string().label('Time Zone'),
  scheduleWhen: Joi.string().valid('now', 'later'),
  scheduleTime: Joi.date().label('Schedule Time'),
  campaign_email: Joi.object({
    subject: Joi.string().trim().max(20).required().label('Subject').messages({
      'string.max': `Length must be less than or equal to 20 characters`,
    }),
    name: Joi.string().allow('', null),
  }),
  emailSMTPId: Joi.number(),
  campaignBy: Joi.string().valid('bySegment', 'byTag', 'byCsv'),
  segmentIds: Joi.array().when('campaignBy', {
    is: Joi.string().valid('bySegment'),
    then: Joi.array().items().min(1).required().label('Segment').messages({'array.min': `Segment must be specified`}),
  }),
  labelBy: Joi.string()
    .when('campaignBy', {
      is: Joi.string().valid('byTag'),
      then: Joi.string().valid('contacts', 'conversations'),
      otherwise: Joi.string().allow(null, ''),
    })
    .label('tag')
    .messages({'any.only': `tag must be specified`}),
  labelIds: Joi.array()
    .when('campaignBy', {is: Joi.string().valid('byTag'), then: Joi.array().items(Joi.number().integer()).min(1)})
    .label('tag')
    .messages({'array.min': `Tag must be specified`}),
  csv: Joi.any()
    .when('campaignBy', {
      is: Joi.string().valid('byCsv'),
      then: Joi.any().invalid(null, '').required(),
      otherwise: Joi.string().allow(null, ''),
    })
    .label('CSV File')
    .messages({'any.invalid': `Please choose a CSV File`}),
  labelByCsv: Joi.string().when('campaignBy', {
    is: Joi.string().valid('byCsv'),
    then: Joi.string().min(2).max(20).required().label('tag'),
    otherwise: Joi.string().allow(null, ''),
  }),
})

export const oneTimeSmsSchema = Joi.object({
  name: Joi.string().trim().min(2).max(20).required(),
  description: Joi.string().allow('', null).optional().max(250).messages({
    'string.max': `Length must be less than or equal to 250 characters`,
  }),
  campaignBy: Joi.string().valid('bySegment', 'byTag', 'byCsv'),
  segmentIds: Joi.array().when('campaignBy', {
    is: Joi.string().valid('bySegment'),
    then: Joi.array().items().min(1).required().label('Segment').messages({'array.min': `Segment must be specified`}),
  }),
  labelBy: Joi.string()
    .when('campaignBy', {
      is: Joi.string().valid('byTag'),
      then: Joi.string().valid('contacts', 'conversations'),
    })
    .label('tag')
    .messages({'any.only': `tag must be specified`}),
  labelIds: Joi.array()
    .when('campaignBy', {is: Joi.string().valid('byTag'), then: Joi.array().items(Joi.number().integer()).min(1)})
    .label('tag')
    .messages({'array.min': `Tag must be specified`}),
  csv: Joi.any()
    .when('campaignBy', {
      is: Joi.string().valid('byCsv'),
      then: Joi.any().invalid(null, '').required(),
      otherwise: Joi.string().allow(null, ''),
    })
    .label('CSV File')
    .messages({'any.invalid': `Please choose a CSV File`}),
  inboxId: Joi.number()
    .integer()
    .required()
    .label('phone number')
    .messages({'number.base': `Number must be specified`}),
  placeholder: Joi.string().allow('').optional(),
  message: Joi.string().trim().min(2).max(320).required(),
  schedule: Joi.valid('custom', 'now'),
  timezone: Joi.string()
    .when('schedule', {is: Joi.valid('custom'), then: Joi.string().required()})
    .label('time zone')
    .messages({'string.empty': `Time zone must be specified`}),
  date: Joi.date().when('schedule', {is: Joi.valid('custom'), then: Joi.date().required()}),
  time: Joi.date().when('schedule', {is: Joi.valid('custom'), then: Joi.date().required()}),
})

export const oneTimeEmailStepSchema = Joi.object({
  name: Joi.string().trim().min(1).max(40),
  userId: Joi.number().label('user').messages({
    'number.base': `Team member needs to be selected`,
  }),
  timezone: Joi.string().label('timezone'),
  scheduleWhen: Joi.string().valid('now', 'later'),
  scheduleTime: Joi.date().label('schedule time'),
})
