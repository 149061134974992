import React from 'react'
import {MdFiberManualRecord} from 'react-icons/md'
import {
  VmoGrid,
  VmoGridColumn,
  VmoImage,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
} from 'vmo-library'
import logo from 'assets/logo.svg'
import {getFormattedDate} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

function StandardTemplate({data}) {
  const {
    setupData = {},
    buyerInfo: {contacts = []} = {},
    yourInfo = {},
    template = {},
    products = {},
    signature = {},
    preview,
  } = data

  const yourCompany = yourInfo?.company || {}

  return (
    <div className="quote-theme">
      <div className="quote-thm-wrap">
        <div className="quo-thm-three">
          <div className="quo-three-header" style={{backgroundColor: template.color}}>
            <div className="title d-flex">
              <h5>{template.header}</h5>
              <div className="date">
                {setupData?.quoteNo && <p>ID :{setupData.quoteNo} </p>}
                <MdFiberManualRecord />
                <p>{getFormattedDate(new Date())}</p>
              </div>
            </div>
            <div className="sender">
              <div className="com-info">
                <h2>{setupData.name}</h2>
                {/* <a href="#">www.veemo.com</a> */}
              </div>
              <div className="com-address">
                <p>
                  {yourCompany.streetAddress ? `${yourCompany.streetAddress}, ` : ''}
                  {yourCompany.locality ? `${yourCompany.locality}, ` : ''}
                  {yourCompany.state ? `${yourCompany.state}, ` : ''}
                  {yourCompany.country ? `${yourCompany.country}, ` : ''}
                  {yourCompany.postalCode ? yourCompany.postalCode : ''}
                </p>
              </div>
            </div>
          </div>
          <div className="quote-for">
            <div className="quote-for-card">
              {contacts?.map(contact => {
                return (
                  <div className="bill-for">
                    <h4>
                      {`${contact.firstName} ${contact.lastName}`}
                      {contact.jobTitle ? ` | ${contact.jobTitle}` : ''}
                    </h4>
                    {contact.phone && (
                      <p>
                        <SvgIcon path="settings/phone" /> {contact.phone}
                      </p>
                    )}
                    {contact.email && (
                      <a href="mailto:">
                        <SvgIcon path="common/mail" />
                        {contact.email}
                      </a>
                    )}
                  </div>
                )
              })}

              <div className="quote-amount">
                <h5>Quote Estimate Amount :</h5>
                <h2>$ {products.total}</h2>
                <p>Valid till, {getFormattedDate(setupData.validTill)}</p>
              </div>
            </div>
          </div>
          <div className="bill-item product-list">
            {products?.products?.length ? (
              <VmoTable>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>Product Name</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Item</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Unit Price</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Amount ($)</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {products.products.map(prod => {
                    return (
                      <VmoTableRow>
                        <VmoTableCell>{prod.productId}</VmoTableCell>
                        <VmoTableCell>{prod.quantity}</VmoTableCell>
                        <VmoTableCell>{prod.unitPrice}</VmoTableCell>
                        <VmoTableCell>{prod.unitTotal}</VmoTableCell>
                      </VmoTableRow>
                    )
                  })}
                  <VmoTableRow style={{fontSize: '16px', color: '#1c68b2'}}>
                    <VmoTableCell colspan="3">Total</VmoTableCell>
                    <VmoTableCell>$ {products.subTotal}</VmoTableCell>
                  </VmoTableRow>

                  {products?.otherCharges?.map((charge, index) => {
                    return (
                      <VmoTableRow key={`charge${index}`}>
                        <VmoTableCell
                          colspan="2"
                          // rowspan="3"
                          style={{verticalAlign: 'top'}}
                        >
                          {charge.name}
                        </VmoTableCell>
                        <VmoTableCell style={{textAlign: 'right'}}>
                          {charge.type}
                          {charge.subType === '%' ? `( ${charge.value} %)` : null}
                        </VmoTableCell>
                        <VmoTableCell>{charge.total}</VmoTableCell>
                      </VmoTableRow>
                    )
                  })}

                  <VmoTableRow style={{fontSize: '16px', color: '#1c68b2'}}>
                    <VmoTableCell
                      style={{
                        textAlign: 'right',
                        fontSize: '16px',
                        color: '#1c68b2',
                      }}
                    >
                      Quote Total
                    </VmoTableCell>
                    <VmoTableCell
                      style={{
                        fontSize: '16px',
                        color: '#1c68b2',
                        fontWeight: '600',
                      }}
                    >
                      $ {products.total}
                    </VmoTableCell>
                  </VmoTableRow>
                </VmoTableBody>
              </VmoTable>
            ) : null}

            {template.terms && (
              <div>
                <h5>Terms</h5>
                <p>{template.terms}</p>
              </div>
            )}

            {template.paymentProcess && (
              <div>
                <h5>Payment Process</h5>
                <p>{template.paymentProcess}</p>
              </div>
            )}

            {signature.signature === 'Add Signature' ? (
              <div className="sign my-4">
                <h6>Customer Acceptance</h6>
                <VmoGrid>
                  <VmoGridColumn width={8}>
                    <h5>Signature</h5>
                  </VmoGridColumn>
                  <VmoGridColumn width={4}>
                    <h5>Printed Name</h5>
                  </VmoGridColumn>
                  <VmoGridColumn width={4}>
                    <h5>Date</h5>
                  </VmoGridColumn>
                </VmoGrid>
              </div>
            ) : null}
          </div>
          <div className="veemo pt-0">
            <div className="logo">
              <VmoImage src={preview || logo} />
              <h2>{yourCompany.name}</h2>
            </div>
            <div className="com-address">
              <p>807, Drive-In road, Near sal hospital, Ahmedabad India - 380054</p>
              <p>
                <SvgIcon path="common/mail" /> info@veemo.com <SvgIcon path="settings/phone" className="ml-4" /> +91
                12345 67890
              </p>
              <a href="#">www.veemo.com</a>
              <p>{template.footer}</p>
            </div>
          </div>
          {/* <p className="help-line" style={{ background: "#1c68b2" }}>
            If you have any questions, Please contact on <b>+91 12345 45678</b>
            or mail us on
            <a href="mailto:">
              <b>info@gmail.com</b>
            </a>
          </p> */}
        </div>
      </div>
    </div>
  )
}

export default StandardTemplate
