const Joi = require('joi')

export const addRisksSchema = Joi.object().keys({
  riskSource: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  mitigationPlan: Joi.string(),
  probabilityValue: Joi.number(),
  impactValue: Joi.number(),
  isImpactOnCost: Joi.boolean(),
  isImpactOnPerformance: Joi.boolean(),
  isImpactOnSchedule: Joi.boolean(),
  status: Joi.string(),
  tasklistId: Joi.number().allow(null),
  taskId: Joi.number().allow(null),
})
