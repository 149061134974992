import {EMAIL_TEMPLATE, TEMPLATE_OVER_TIME} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case TEMPLATE_OVER_TIME:
      return {
        ...state,
        templateOverTimeData: action.payload,
      }
    case EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplateData: action.payload,
      }

    default:
      return state
  }
}
