/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {VmoDropdown} from 'vmo-library'
import moment from 'moment-timezone'
import _ from 'lodash'

const TimeZoneDropdown = ({value, onChange, format = null, realTime = false, ...props}) => {
  const [timeZoneList, setTimeZoneList] = useState([])

  const updateFormat = list => {
    if (!_.isEmpty(format)) {
      return `${list} ${moment.tz(list).format(format)}`
    }
    return list
  }

  useEffect(() => {
    if (realTime) {
      const time = setInterval(() => {
        const list = moment.tz.names().map(l => ({
          key: l,
          text: updateFormat(l),
          value: l,
        }))
        setTimeZoneList(list)
      }, 1000)
      return () => clearInterval(time)
    }
    const list = moment.tz.names().map(l => ({
      key: l,
      text: updateFormat(l),
      value: l,
    }))
    setTimeZoneList(list)
  }, [realTime, updateFormat])

  return <VmoDropdown value={value} options={timeZoneList} onChange={onChange} {...props} />
}

export default React.memo(TimeZoneDropdown)
