import React from 'react'
import ProjectMenu from 'navigation/sub-routes/Project/ProjectFinance/ProjectMenu'
import ProjectRoutes from 'navigation/sub-routes/Project/ProjectFinance/ProjectRoutes'

const Projects = () => {
  return (
    <>
      <ProjectMenu />
      <ProjectRoutes />
    </>
  )
}

export default Projects
