/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unknown-property */
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {FiChevronRight, FiDollarSign, FiExternalLink} from 'react-icons/fi'
import _ from 'lodash'
import moment from 'moment'
import {
  VmoAccordion,
  VmoAccordionContent,
  VmoAccordionTitle,
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoDropdown,
  VmoFeed,
  VmoFeedContent,
  VmoFeedEvent,
  VmoFeedLabel,
  VmoFeedSummary,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoGrid,
  VmoGridColumn,
  VmoIcon,
  VmoList,
  VmoListContent,
  VmoListDescription,
  VmoListHeader,
  VmoListItem,
  VmoTooltip,
} from 'vmo-library'

import {editCompany, uploadAttachmentsCompany} from 'actions/company'
import {getContactList} from 'actions/contacts'
import {get, GetFormattedDate, getPermissionTooltipContent, wrapText} from 'utils/helper'
import AccountUserImage from 'components/common/UserImage'
import CreateTask from 'components/pages/Projects/Tasks/CreateTask'
import Tags from 'components/common/Tags'
import {editTaskActivities, getTaskActivities} from 'actions/activities'
import ViewTask from 'components/pages/Projects/Tasks/ViewTask'
import {addNoteInCompanies} from 'actions/customers'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const CompaniesSummary = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const {companyId, getValues, errors, handleSubmit, updateCompany, control, Controller, noteText, setNoteText} = props
  const {singleCompany = {}, getTypeListData} = useSelector(state => state.companies)
  const {interactions: getAllInterActionsData = [], interactionTime: getInteractionTime} = singleCompany

  const {getUsersList = {}} = useSelector(state => state.settings)
  const refForm = useRef()
  const [activeIndex, setActiveIndex] = useState(0)
  const [userOption, setUserOption] = useState([])
  const [typeList, setTypeList] = useState([])
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false)
  const [isViewTaskOpen, setIsViewTaskOpen] = useState(false)

  const {contactsAndCompaniesPermissions, tasksPermissions} = useUserPermissions()
  const manageContactsCompanyPermissions = contactsAndCompaniesPermissions?.manage_contacts_company
  const manageTasksPermissions = tasksPermissions?.manage_tasks

  const renderCreateTaskModal = () => {
    if (isCreateTaskOpen) {
      return (
        <CreateTask
          setIsCreateTaskOpen={setIsCreateTaskOpen}
          isCreateTaskOpen={isCreateTaskOpen}
          conversation={get(['conversation'], props)}
          companyId={companyId}
          isDataAvailabel
        />
      )
    }
  }
  useEffect(() => {
    dispatch(getContactList())
  }, [location.state])

  useEffect(() => {
    if (getTypeListData) {
      const companyList = getTypeListData.map(list => ({
        key: list.id,
        value: list.id,
        text: list.type,
      }))
      setTypeList(companyList)
    }
  }, [getTypeListData])

  useEffect(() => {
    if (getUsersList) {
      const list = getUsersList?.users?.map(user => ({
        value: user.id,
        text: `${get(['firstName'], user, '')} ${get(['lastName'], user, '')}`,
        key: user.id,
      }))
      setUserOption(list || [])
    }
  }, [getUsersList])

  const renderNotes = () => {
    const notesList = get(['notes'], singleCompany, [])

    if (notesList.length > 0) {
      return notesList
        .filter((list, index) => index < 4)
        .map(list => (
          <VmoListItem>
            <VmoListContent floated="right">
              <span>{GetFormattedDate(get(['createdAt'], list, ''))}</span>
            </VmoListContent>

            <VmoListHeader>{get(['note'], list, '')}</VmoListHeader>
            <VmoListDescription>By : {get(['agent'], list, '')}</VmoListDescription>
          </VmoListItem>
        ))
    }
  }
  const summaryCardBody = () => {
    return (
      <VmoForm
        ref={refForm}
        className="editForm errorLabel"
        {...(manageContactsCompanyPermissions && {
          onSubmit: handleSubmit(updateCompany),
        })}
      >
        <VmoFormField>
          <h5>Last Contacted</h5>
          <label className="kadence-lead">{getInteractionTime ? GetFormattedDate(getInteractionTime) : null}</label>
        </VmoFormField>
        <VmoFormField>
          <h5>Interactions</h5>
          <label className="kadence-lead">
            {getAllInterActionsData.length > 0 ? getAllInterActionsData.length : null}
          </label>
        </VmoFormField>
        <VmoFormField>
          <h5>Inactive For</h5>
          <label className="kadence-lead">{getInteractionTime ? moment(getInteractionTime).fromNow(true) : '-'}</label>
        </VmoFormField>
        <VmoFormField>
          <div className="info-header">
            <label>Contact Type</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Select type of contact to add in company"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            control={control}
            name="contactTypeId"
            render={({value, onChange}) => (
              <VmoFormSelect
                placeholder="Contact Type"
                options={typeList}
                fluid
                search
                selection
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                  refForm.current.handleSubmit()
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.target.blur()
                  }
                }}
                disabled={!manageContactsCompanyPermissions}
              />
            )}
          />
        </VmoFormField>

        <VmoFormField>
          <div className="info-header">
            <label>Assignee</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Assign agent for company"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            control={control}
            name="assigneeId"
            render={({value, onChange}) => (
              <VmoDropdown
                fluid
                selection
                options={userOption}
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                  refForm.current.handleSubmit()
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.target.blur()
                  }
                }}
                disabled={!manageContactsCompanyPermissions}
              />
            )}
          />
        </VmoFormField>
      </VmoForm>
    )
  }

  const opportunitiesCardBody = () => {
    return (
      <VmoCardContent className="pt-0">
        {/* <VmoForm className="vmo-form">
          <VmoFormField>
            <VmoGrid className="text-center">
              <VmoGridColumn width="8" className="border-right">
                <label>Total Won</label>
                <p className="mb-0">$ 200</p>
              </VmoGridColumn>
              <VmoGridColumn width="8">
                <label>Win Rate</label>
                <p className="mb-0">10 %</p>
              </VmoGridColumn>
              <VmoGridColumn width="16">
                <VmoProgress progress="value" value={50} />
              </VmoGridColumn>
            </VmoGrid>
          </VmoFormField>
        </VmoForm>
        <p className="mb-2">Open</p>
        <VmoGrid>
          <VmoGridColumn width="8">
            <h6>500 Keyboards</h6>
            <p className="kadence-lead pb-2">Sales: Follow-up / Open</p>
          </VmoGridColumn>
          <VmoGridColumn width="8" className="text-right">
            <VmoTooltip
              content="External"
              size="mini"
              position="top center"
              trigger={
                <VmoButton icon className="bg-btn">
                  <FiExternalLink />
                </VmoButton>
              }
            />
            <VmoTooltip
              content="Delete"
              size="mini"
              position="top center"
              trigger={
                <VmoButton icon className="bg-btn">
                  <SvgIcon path="common/delete" />
                </VmoButton>
              }
            />
          </VmoGridColumn>
        </VmoGrid> */}
        {singleCompany?.deals?.length > 0 ? (
          singleCompany.deals.map(deal => (
            <VmoGrid key={deal.id}>
              <VmoGridColumn width="12">
                <h6>{deal.name}</h6>
                <p className="kadence-lead pb-2">Sales : {deal.status}</p>
              </VmoGridColumn>
              <VmoGridColumn width="4" className="text-right">
                <VmoTooltip
                  content="External"
                  size="mini"
                  position="top center"
                  trigger={
                    <VmoButton icon className="bg-btn">
                      <FiExternalLink />
                    </VmoButton>
                  }
                />
                <VmoTooltip
                  content="Delete"
                  size="mini"
                  position="top center"
                  trigger={
                    <VmoButton icon className="bg-btn">
                      <SvgIcon path="common/delete" />
                    </VmoButton>
                  }
                />
              </VmoGridColumn>
            </VmoGrid>
          ))
        ) : (
          <p>No Deals Attached</p>
        )}
      </VmoCardContent>
    )
  }
  return (
    <div className="callwidget-accord">
      <VmoCard fluid>
        <VmoCardContent>
          <div className="staticData">
            <div className="dataOne">
              <p>Open Conversation</p>
              {/* <h4>{get(['openConversations'], companyDetails, '-')}</h4> */}
              <h4>-</h4>
            </div>
            <div className="dataOne">
              <p>Total Conversation</p>
              <h4>-</h4>
              {/* <h4>{get(['totalConversations'], companyDetails, '-')}</h4> */}
            </div>
          </div>
        </VmoCardContent>
      </VmoCard>
      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 0}
              index={0}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {!activeIndex ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Summary
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 0}>{summaryCardBody()}</VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 1}
              index={1}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 1 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Deals ({_.size(_.get(singleCompany, 'deals', 0))})
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 1}>{opportunitiesCardBody()}</VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 2}
              index={2}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 2 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              People ({_.size(_.get(singleCompany, 'contacts', 0))})
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 2}>
              {_.size(_.get(singleCompany, 'contacts', 0)) === 0 ? (
                <p className="kadence-lead mb-0">No People</p>
              ) : (
                _.get(singleCompany, 'contacts', 0).map(list => (
                  <>
                    <Link to={`/contacts/${list.id}`} target="_blank" key={list.id}>
                      <VmoCard link target="_blank">
                        <VmoCardContent>
                          <VmoFeed>
                            <VmoFeedEvent>
                              <VmoFeedLabel
                                style={{
                                  textAlign: 'center',
                                  marginTop: '25px',
                                }}
                              >
                                <AccountUserImage profile={list} />
                              </VmoFeedLabel>
                              <VmoFeedContent
                                style={{
                                  marginTop: '15px',
                                }}
                              >
                                <VmoFeedSummary>
                                  {`${_.get(list, 'firstName') || ''}  ${_.get(list, 'lastName') || ''}`}
                                  <br />
                                  {`${_.get(list, 'title') || ''} at ${_.get(list, 'name') || ''}`}
                                </VmoFeedSummary>
                              </VmoFeedContent>
                            </VmoFeedEvent>
                          </VmoFeed>
                        </VmoCardContent>
                      </VmoCard>
                    </Link>
                    <SvgIcon path="common/close" />
                    <FiDollarSign />
                  </>
                ))
              )}
              {_.size(_.get(singleCompany, 'contacts', 0)) !== 0 && (
                <VmoButton
                  icon
                  onClick={() => {
                    history.push({
                      pathname: `/contacts`,
                      state: {
                        companyId: get(['id'], singleCompany),
                      },
                    })
                  }}
                >
                  <VmoIcon name="external alternate" />
                  View in List
                </VmoButton>
              )}
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 3}
              index={3}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 3 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Tags ({_.size(_.get(singleCompany, 'labelIds'))})
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 3}>
              {_.size(_.get(singleCompany, 'labelIds')) === 0 ? <p className="kadence-lead mb-0">No Tags</p> : null}
              <Tags
                id={companyId}
                data={singleCompany}
                tagsUpdate={editCompany}
                Controller={Controller}
                control={control}
                errors={errors}
                getValues={getValues}
                rolePermissions={manageContactsCompanyPermissions}
              />
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 4}
              index={4}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 4 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Notes{' '}
              {get(['notes'], singleCompany, []).length > 0 ? `(${get(['notes'], singleCompany, []).length})` : ''}
            </VmoAccordionTitle>
            <VmoAccordionContent className="p-0 pb-2" active={activeIndex === 4}>
              <VmoForm className="mx-3 mb-2" size="tiny">
                <VmoFormField>
                  <VmoFormInput
                    placeholder="Write note here..."
                    maxLength={30}
                    disabled={!manageContactsCompanyPermissions}
                    onChange={e => manageContactsCompanyPermissions && setNoteText(e.target.value)}
                    value={noteText}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        if (!manageContactsCompanyPermissions) return
                        const data = {
                          note: e.target.value,
                        }

                        if (e.target.value.length !== 0 && e.target.value.trim() !== '') {
                          dispatch(addNoteInCompanies(companyId, data))
                        }
                      }
                    }}
                  />
                </VmoFormField>
              </VmoForm>
              {/* {get(["notes"], singleCompany, []).length === 0 ? (
                <p className="kadence-lead px-4 mb-0">No Notes </p>
              ) : null} */}
              <VmoList selection className="recentConv">
                {renderNotes()}
              </VmoList>
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 5}
              index={5}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 5 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Tasks ({get(['tasks'], singleCompany, []).length})
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 5}>
              <VmoList className="recentConv">
                {get(['tasks'], singleCompany, []).length > 0
                  ? get(['tasks'], singleCompany, [])
                      .slice(0, 3)
                      .map(task => (
                        <VmoListItem>
                          <VmoIcon
                            style={{
                              paddingTop: '4px',
                            }}
                          >
                            <VmoCheckbox
                              onClick={() => {
                                if (!manageTasksPermissions) return

                                let data = {}

                                if (task.status === 'closed') {
                                  data = {
                                    status: 'open',
                                  }
                                } else {
                                  data = {
                                    status: 'closed',
                                  }
                                }

                                dispatch(editTaskActivities(task.id, data))
                              }}
                              checked={task.status === 'closed'}
                            />
                          </VmoIcon>
                          <VmoListContent>
                            <VmoListHeader
                              as="a"
                              style={
                                task.status === 'closed'
                                  ? {
                                      textDecoration: 'line-through',
                                    }
                                  : null
                              }
                              onClick={() => {
                                if (manageTasksPermissions) {
                                  dispatch(getTaskActivities(task.id))
                                  setIsViewTaskOpen(true)
                                }
                              }}
                            >
                              {task.subject ? wrapText(task.subject, 20) : ''}
                              <span
                                style={{
                                  float: 'right',
                                }}
                              >
                                {get(['assignee', 'firstName'], task, '')} {get(['assignee', 'lastName'], task, '')}
                              </span>
                            </VmoListHeader>
                            {task.status === 'closed' ? null : (
                              <VmoListDescription>
                                Due:
                                {GetFormattedDate(get(['endAt'], task, ''))}
                              </VmoListDescription>
                            )}
                          </VmoListContent>
                        </VmoListItem>
                      ))
                  : ''}
              </VmoList>
              {get(['tasks'], singleCompany, []).length === 0 ? <p className="kadence-lead mb-0">No Task</p> : null}
              <VmoTooltip
                content={
                  manageTasksPermissions
                    ? 'Add Task'
                    : getPermissionTooltipContent({roleAccess: manageTasksPermissions})
                }
                size="mini"
                position="top center"
                trigger={
                  <VmoButton
                    className="mt-3 ml-0 delete-forecast"
                    icon
                    onClick={() => manageTasksPermissions && setIsCreateTaskOpen(!isCreateTaskOpen)}
                  >
                    <SvgIcon path="common/plus" />
                  </VmoButton>
                }
              />
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 6}
              index={6}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 6 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Files ({_.size(_.get(singleCompany, 'attachments'))})
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 6}>
              <VmoCardContent className="pt-0">
                {get(['attachments'], singleCompany, []).map(item => (
                  <span>{item.name}</span>
                ))}
                {_.size(_.get(singleCompany, 'attachments')) === 0 && <p className="kadence-lead">No Files</p>}
                <input
                  fluid
                  type="file"
                  multiple
                  onChange={e => {
                    const data = {
                      file: e.target.files,
                    }
                    dispatch(uploadAttachmentsCompany(companyId, data))
                  }}
                />
              </VmoCardContent>
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>
      {renderCreateTaskModal()}
      {isViewTaskOpen && <ViewTask setIsViewTaskOpen={setIsViewTaskOpen} isViewTaskOpen={isViewTaskOpen} />}
    </div>
  )
}

export default CompaniesSummary
