import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import ProductList from 'components/pages/Sales/Products/ProductList'
import ProductsSettings from 'components/pages/Sales/Products/Settings/ProductsSettings'

const routes = [
  {path: '/products/all', component: ProductList, title: 'All products', exact: true},
  {path: '/products/products-settings', component: ProductsSettings, title: 'Settings', exact: true},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} products />
        <Component {...props} />
      </>
    )}
  />
)

const ProductsRoutes = () => (
  <Switch>
    <Route exact path="/products">
      <Redirect to="/products/all" />
    </Route>
    {routes.map(renderRoute)}
  </Switch>
)

export default ProductsRoutes
