export const paymentMethod = [
  {value: 'bank_transfer', key: 'bank_transfer', text: 'Bank transfer'},
  {value: 'credit', key: 'credit', text: 'Credit'},
  {value: 'card', key: 'card', text: 'Card payment'},
  {value: 'cash', key: 'cash', text: 'Cash'},
  {value: 'cheque', key: 'cheque', text: 'Cheque'},
]

export const unitList = [
  {value: 'hour', key: 'hour', text: 'Hour'},
  {value: 'minute', key: 'minute', text: 'Minute'},
  {value: 'pcs', key: 'pcs', text: 'pcs'},
  {value: 'day', key: 'day', text: 'Day'},
]
