/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {memo, useState} from 'react'
import {FiChevronRight} from 'react-icons/fi'
import {MdOutlineMoreVert} from 'react-icons/md'
import {
  VmoAccordion,
  VmoAccordionContent,
  VmoAccordionTitle,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoMenu,
  VmoMenuItem,
} from 'vmo-library'
import {get} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const TemplateTasksMenu = ({
  rolePermissions,
  tasklists,
  currentlySelectedTasklist,
  onTasklistClick,
  onAddTasklistClick,
  onEditTasklistClick,
  onDeleteTasklistClick,
}) => {
  const [active, setActive] = useState(true)

  const renderDropdown = task => {
    return (
      <VmoDropdownMenu>
        <VmoDropdownItem onClick={() => onEditTasklistClick(task.id)}>
          <SvgIcon path="common/edit2" /> Edit
        </VmoDropdownItem>
        <VmoDropdownItem onClick={() => onDeleteTasklistClick(task.id)}>
          <SvgIcon path="common/delete" /> Delete
        </VmoDropdownItem>
      </VmoDropdownMenu>
    )
  }

  const renderTasksList = () => {
    return tasklists.map(task => {
      return (
        <VmoMenuItem
          key={task.id}
          className="project-discussion-sidemenu pr-0"
          active={currentlySelectedTasklist === task.id}
          onClick={() => onTasklistClick(task.id)}
        >
          <div className="d-flex" style={{justifyContent: 'flex-start', width: '85%'}}>
            {get(['title'], task, '')}
          </div>
          {rolePermissions && (
            <VmoDropdown className="project-discussion-sidemenu-dropdown" icon={<MdOutlineMoreVert />}>
              {renderDropdown(task)}
            </VmoDropdown>
          )}
        </VmoMenuItem>
      )
    })
  }

  return (
    <VmoMenu vertical secondary className="projects-side-menu kadence-menu">
      <VmoAccordion className="mt-0">
        <VmoAccordionTitle className="d-flex">
          <div className="d-flex" style={{justifyContent: 'flex-start'}} onClick={() => setActive(active => !active)}>
            {active ? <SvgIcon path="common/down" /> : <FiChevronRight />}
            Active
          </div>
          {rolePermissions && <SvgIcon path="common/plus" onClick={onAddTasklistClick} />}
        </VmoAccordionTitle>
        <VmoAccordionContent active={active}>{renderTasksList()}</VmoAccordionContent>
      </VmoAccordion>
    </VmoMenu>
  )
}

export default memo(TemplateTasksMenu)
