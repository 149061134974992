/* eslint-disable camelcase */
import {combineReducers, createStore, applyMiddleware} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import apiMiddleware from 'middleware/api'
import apiReducer from 'reducers/api'
import resetPassword from 'reducers/resetPassword'
import appReducer from 'reducers/appReducer'
import loader from 'reducers/loader'
import loginUser from 'reducers/loginUser'
import createUserAcct from 'reducers/createUserAcct'
import verifyUser from 'reducers/verifyUser'
import customers from 'reducers/customers'
import settings from 'reducers/settings'
import getAssignee from 'reducers/getAssignee'
import settingsProductivity from 'reducers/settingsProductivity'
import segment from 'reducers/segment'
import emailInbox from 'reducers/emailInbox'
import mailInbox from 'reducers/mailInbox'
import attachment from 'reducers/attachment'
import contacts from 'reducers/contacts'
import settingsOrganisation from 'reducers/settingsOrganisation'
import settingsMessenger from 'reducers/settingsMessenger'
import inboxSetup from 'reducers/inboxSetup'
import settingsChannels from 'reducers/settingsChannels'
import settingAutomation from 'reducers/settingAutomation'
import account from 'reducers/account'
import multipleFields from 'reducers/multipleFields'
import activities from 'reducers/activities'
import companies from 'reducers/companies'
import productLibrary from 'reducers/productLibrary'
import settingCurrency from 'reducers/settingCurrency'
import custom_option from 'reducers/custom_option'
import deals from 'reducers/deals'
import settingsTasks from 'reducers/settingsTasks'
import campaign from 'reducers/campaign'
import quotes from 'reducers/quotes'
import sequences from 'reducers/sequences'
import liveVisitors from 'reducers/liveVisitors'
import analyticsKnowledgebase from 'reducers/analyticsKnowledgebase'
import analyticsOverview from 'reducers/analyticsOverview'
import analyticsInbox from 'reducers/analyticsInbox'
import analyticsChannels from 'reducers/analyticsChannels'
import analyticsCampaign from 'reducers/analyticsCampaign'
import analyticsSMS from 'reducers/analyticsSMS'
import analyticsMetrics from 'reducers/analyticsMetrics'
import analyticsUsers from 'reducers/analyticsUsers'
import analyticsSla from 'reducers/analyticsSla'
import analyticsContacts from 'reducers/analyticsContacts'
import analyticsVisitors from 'reducers/analyticsVisitors'
import reports_Tags from 'reducers/reports_Tags'
import reportsEmail from 'reducers/reportsEmail.js'
import dashboardsSupport from 'reducers/dashboardsSupport'
import dashboardsSales from 'reducers/dashboardsSales'
import forecast from 'reducers/forecast'
import settingsCustomerSatisfaction from 'reducers/settingsCustomerSatisfaction'
import analyticsCustomerSatisfaction from 'reducers/analyticsCustomerSatisfaction'
import reportsCompanies from 'reducers/reportsCompanies'
import reportsConversion from 'reducers/reportsConversion'
import reportsDeals from 'reducers/reportsDeals'
import reportsForecast from 'reducers/reportsForecast'
import reportsProducts from 'reducers/reportsProducts'
import reportsQuotes from 'reducers/reportsQuotes'
import reportsTasks from 'reducers/reportsTasks'
import reportsTemplate from 'reducers/reportsTemplate'
import vendors from 'reducers/vendors'
import purchases from 'reducers/purchases'
import reportsVendors from 'reducers/reportsVendors'
import reportsPurchases from 'reducers/reportsPurchases'
import purchaseOrders from 'reducers/purchaseOrders'
import projects from 'reducers/projects'
import salesOrders from 'reducers/salesOrders'
import settingsProjects from 'reducers/settingsProjects'
import invoices from 'reducers/invoices'
import budget from 'reducers/budget'
import meetings from 'reducers/meetings'
import automations from 'reducers/automations'
import calendar from 'reducers/calendar'
import employees from 'reducers/employees'
import reportsEmployees from 'reducers/reportsEmployees'
import expenses from 'reducers/expenses'
import reportsExpenses from 'reducers/reportsExpenses'
import timeOff from 'reducers/timeOff'
import reportsTimeOff from 'reducers/reportsTimeOff'
import holidays from 'reducers/holidays'
import announcement from 'reducers/announcement'
import loginEmployee from 'reducers/loginEmployee'
import appraisal from 'reducers/appraisal'
import clientPortal from 'reducers/clientPortal'
import settingsScoringRules from 'reducers/settingsScoringRules'
import billing from 'reducers/billing'
import roles from 'reducers/roles'
import timeTracking from 'reducers/timeTracking'
import reportsPurchaseOrders from 'reducers/reportsPurchaseOrders'
import reportsSalesOrders from 'reducers/reportsSalesOrders'
import reportsInvoices from 'reducers/reportsInvoices'
import budgeting from 'reducers/budgeting'
import proofs from 'reducers/proofs'
import analyticsProjects from 'reducers/analyticsProjects'
import resourcePlanning from 'reducers/resourcePlanning'
import home from 'reducers/home'

const rootReducer = combineReducers({
  apiReducer,
  resetPassword,
  appReducer,
  loader,
  loginUser,
  createUserAcct,
  verifyUser,
  emailInbox,
  mailInbox,
  customers,
  settings,
  getAssignee,
  settingsProductivity,
  settingsOrganisation,
  settingsChannels,
  settingsMessenger,
  settingsProjects,
  settingsCustomerSatisfaction,
  inboxSetup,
  attachment,
  segment,
  contacts,
  settingAutomation,
  account,
  multipleFields,
  activities,
  companies,
  productLibrary,
  settingCurrency,
  deals,
  custom_option,
  settingsTasks,
  campaign,
  quotes,
  sequences,
  liveVisitors,
  dashboardsSupport,
  analyticsOverview,
  analyticsInbox,
  analyticsChannels,
  analyticsContacts,
  analyticsVisitors,
  analyticsMetrics,
  analyticsUsers,
  analyticsSla,
  analyticsCampaign,
  analyticsSMS,
  analyticsCustomerSatisfaction,
  analyticsKnowledgebase,
  forecast,
  reports_Tags,
  dashboardsSales,
  reportsEmail,
  reportsCompanies,
  reportsConversion,
  reportsDeals,
  reportsForecast,
  reportsProducts,
  reportsQuotes,
  reportsTasks,
  reportsTemplate,
  vendors,
  projects,
  budgeting,
  purchases,
  reportsVendors,
  reportsPurchases,
  purchaseOrders,
  salesOrders,
  invoices,
  budget,
  meetings,
  automations,
  calendar,
  employees,
  reportsEmployees,
  expenses,
  reportsExpenses,
  timeOff,
  reportsTimeOff,
  holidays,
  announcement,
  loginEmployee,
  appraisal,
  clientPortal,
  settingsScoringRules,
  billing,
  roles,
  timeTracking,
  reportsPurchaseOrders,
  reportsSalesOrders,
  reportsInvoices,
  proofs,
  analyticsProjects,
  resourcePlanning,
  home,
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(apiMiddleware)))
window.store = store
export default store
