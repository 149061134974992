import {GET_ALL_ASSIGNEE} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_ASSIGNEE:
      return {data: action.payload}

    default:
      return state
  }
}
