import {createContext, useMemo} from 'react'
import {useSelector} from 'react-redux'

export const LearnMoreUrlContext = createContext()

const LearnMoreUrlProvider = ({children}) => {
  const {learnMoreData: {settings = {}} = {}} = useSelector(state => state.appReducer)

  const settingsLearnMoreUrl = useMemo(() => {
    const temp = {...settings}
    return temp
  }, [settings])

  return (
    <LearnMoreUrlContext.Provider
      value={{
        settingsLearnMoreUrl,
      }}
    >
      {children}
    </LearnMoreUrlContext.Provider>
  )
}

export default LearnMoreUrlProvider
