import React from 'react'
import {Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom/cjs/react-router-dom.min'
import {VmoTab, VmoTabPane} from 'vmo-library'
import Workload from './Steps/Workload/Workload'
import Overview from './Steps/Overview'

const steps = [
  {name: 'Overview', path: 'overview', icon: 'folder'},
  {name: 'Workload', path: 'workload', icon: 'folder'},
]

const ResourcePlanning = () => {
  const history = useHistory()
  const {pathname} = useLocation()
  const {url, path} = useRouteMatch()

  const currentPage = pathname.split('/')[2]
  return (
    <div>
      <div className="resource-planning-container">
        <VmoTab
          key={currentPage}
          panes={steps.map(item => ({
            menuItem: {
              key: item.name,
              icon: item.icon,
              content: item.name,
            },
            render: null,
          }))}
          menu={{color: 'blue', secondary: true, pointing: true}}
          activeIndex={steps.findIndex(item => item.path === currentPage)}
          onTabChange={(e, {activeIndex}) => {
            history.push(`${url}/${steps[activeIndex].path}`)
          }}
        />
      </div>

      <Switch>
        <Route
          path={`${path}/overview`}
          render={props => (
            <VmoTabPane>
              <Overview {...props} />
            </VmoTabPane>
          )}
        />
        <Route
          path={`${path}/workload`}
          render={props => (
            <VmoTabPane>
              <Workload {...props} />
            </VmoTabPane>
          )}
        />
      </Switch>
    </div>
  )
}

export default ResourcePlanning
