import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import _ from 'lodash'
import {
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoRadio,
} from 'vmo-library'

import {gatLabelTags} from 'actions/campaign'
import {KADENCE_ACCOUNT_V1} from 'actions/endpoint'
import {getAllSegment} from 'actions/segment'
import {GET_ALL_SEGMENT, GET_LABEL_TAGS} from 'actions/types'
import {removeDoubleQuotes} from 'utils/helper'

const UserAudience = props => {
  const {
    style,
    control,
    errors,
    clearErrors,
    watch,
    setValue,
    campaignBy,
    setCampaignBy,
    url,
    setUrl,
    totalCsvContacts,
    setTotalCsvContacts,
    editId,
  } = props

  const dispatch = useDispatch()

  const [allSegments, setAllSegments] = useState([])
  const [allTags, setAllTags] = useState([])
  const [tagLabelBy, setTagLabelBy] = useState('')

  const {successLabels = []} = useSelector(store => store.apiReducer)
  const {segmentListAll = []} = useSelector(state => state.segment)
  const {tagLabelList = []} = useSelector(state => state.campaign)

  const segmentIds = watch('segmentIds')
  const labelBy = watch('labelBy')
  const labelIds = watch('labelIds')

  useEffect(() => {
    dispatch(getAllSegment())
    dispatch(gatLabelTags())
  }, [dispatch])

  useEffect(() => {
    if (campaignBy) setValue('campaignBy', campaignBy)
  }, [campaignBy, setValue])

  useEffect(() => {
    if (labelBy === 'conversations') {
      setTagLabelBy('contactsByConversationTags')
    }
    if (labelBy === 'contacts') {
      setTagLabelBy('contactsByTags')
    }
  }, [labelBy, setValue])

  useEffect(() => {
    if (successLabels.includes(GET_ALL_SEGMENT)) {
      const list = segmentListAll.map(({id, name}) => {
        return {
          key: id,
          value: id,
          text: name,
        }
      })
      setAllSegments(list)
    }
  }, [successLabels, segmentListAll])

  useEffect(() => {
    if (successLabels.includes(GET_LABEL_TAGS)) {
      const list = tagLabelList.map(({id, label}) => {
        return {
          key: id,
          value: id,
          text: label,
        }
      })
      setAllTags(list)
    }
  }, [successLabels, tagLabelList])

  const totalSegmentContacts = () => {
    const segments = []
    segmentIds?.forEach(segmentId => {
      const segment = segmentListAll.filter(s => s.id === segmentId)
      if (segment.length > 0) segments.push(segment[0])
    })
    return segments?.map(s => s.totalContacts).reduce((a, b) => a + b, 0)
  }

  const totalTagContacts = () => {
    const labelTags = []
    labelIds?.forEach(labelId => {
      const labelTag = tagLabelList.filter(t => t.id === labelId)
      labelTags.push(labelTag[0])
    })
    return _.reduce(_.map(labelTags, tagLabelBy), (a, b) => +a + +b, 0)
  }

  const onChangeCsv = file => {
    const fileReader = new FileReader()
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result
        setTotalCsvContacts(csvOutput.split('\n').length - 2)
      }
      fileReader.readAsText(file)
    }
  }

  return (
    <VmoContainer style={style} className="stepWrap">
      <VmoCard fluid>
        <VmoCardContent>
          <h3 className="mb-0">User Audience</h3>
          <p className="mb-4">
            Select the conditions and requirements of the users to whom you wish to send this campaign Email.
          </p>
          <VmoForm className="errorLabel">
            <Controller name="campaignBy" control={control} render={null} />
            <VmoRadio
              value="bySegment"
              label="By Segment"
              checked={campaignBy === 'bySegment'}
              onChange={(e, {value}) => setCampaignBy(value)}
            />
            <div className="radio-subheading-box">
              <p className="kadence-lead">Select contacts for the campaign by segment</p>
              {campaignBy === 'bySegment' && (
                <VmoFormGroup >
                  <VmoFormField width={8} className="mt-0 mb-0">
                    <Controller
                      name="segmentIds"
                      control={control}
                      render={({value, onChange}) => (
                        <VmoFormSelect
                          placeholder="Select Segments"
                          className="mb-3"
                          search
                          multiple
                          value={value}
                          onChange={(e, {value}) => onChange(value)}
                          onClick={() => clearErrors('segmentIds')}
                          options={allSegments}
                          error={
                            errors.segmentIds && {
                              content: removeDoubleQuotes(errors.segmentIds.message),
                            }
                          }
                        />
                      )}
                    />
                  </VmoFormField>
                  <p className="kadence-lead pt-4">All Contacts ({totalSegmentContacts()})</p>
                </VmoFormGroup>
              )}
            </div>
            <VmoRadio
              value="byTag"
              label="By Tag"
              checked={campaignBy === 'byTag'}
              onChange={(e, {value}) => setCampaignBy(value)}
            />
            <div className="radio-subheading-box">
              <p className="kadence-lead">Select Customers by tag</p>
              {campaignBy === 'byTag' && (
                <VmoFormGroup >
                  <VmoFormField width={6}>
                    <Controller
                      name="labelBy"
                      control={control}
                      render={({value, onChange}) => (
                        <VmoFormSelect
                          placeholder="Select"
                          className="mb-3"
                          value={value}
                          onChange={(e, {value}) => onChange(value)}
                          onClick={() => clearErrors('labelBy')}
                          options={[
                            {
                              key: 1,
                              text: 'Select By Conversations',
                              value: 'conversations',
                            },
                            {
                              key: 2,
                              text: 'Select By Contacts',
                              value: 'contacts',
                            },
                          ]}
                          error={
                            errors.labelBy && {
                              content: removeDoubleQuotes(errors.labelBy.message),
                            }
                          }
                        />
                      )}
                    />
                  </VmoFormField>
                  {(labelBy === 'conversations' || labelBy === 'contacts') && (
                    <>
                      <VmoFormField width={6}>
                        <Controller
                          name="labelIds"
                          control={control}
                          render={({value, onChange}) => (
                            <VmoFormSelect
                              search
                              multiple
                              placeholder="Select Tag"
                              className="mb-3"
                              value={value}
                              onChange={(e, {value}) => onChange(value)}
                              onClick={() => clearErrors('labelIds')}
                              options={allTags}
                              error={
                                errors.labelIds && {
                                  content: removeDoubleQuotes(errors.labelIds.message),
                                }
                              }
                            />
                          )}
                        />
                      </VmoFormField>
                      <p className="kadence-lead pt-4">All Contacts ({totalTagContacts()})</p>
                    </>
                  )}
                </VmoFormGroup>
              )}
            </div>
            <VmoRadio
              label="Upload CSV"
              value="byCsv"
              checked={campaignBy === 'byCsv'}
              onChange={(e, {value}) => setCampaignBy(value)}
            />
            <div className="radio-subheading-box">
              <p className="kadence-lead">Select customers by uploading CSV</p>
              {campaignBy === 'byCsv' && (
                <>
                  {url && editId && (
                    <>
                      <a href={url} download>
                        Download Your CSV File
                      </a>
                      <button
                        type="button"
                        className="ml-3"
                        onClick={() => {
                          setUrl('')
                          setValue('csv', null)
                          setTotalCsvContacts(0)
                        }}
                      >
                        Remove
                      </button>
                      <span className="kadence-lead pl-3">All Contacts ({totalCsvContacts})</span>
                    </>
                  )}
                  {url === '' && (
                    <VmoFormGroup>
                      <VmoFormField width={6}>
                        <Controller
                          name="csv"
                          control={control}
                          render={({onChange}) => (
                            <VmoFormInput
                              type="file"
                              onChange={e => {
                                if (e.target.files[0].size < 5000000) {
                                  onChangeCsv(e.target.files[0])
                                  onChange(e.target.files[0])
                                } else {
                                  alert('File size must be less than 5Mb')
                                }
                              }}
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              error={
                                errors.csv && {
                                  content: removeDoubleQuotes(errors.csv.message),
                                }
                              }
                            />
                          )}
                        />
                      </VmoFormField>
                      <p className="kadence-lead pt-4">All Contacts ({totalCsvContacts})</p>
                    </VmoFormGroup>
                  )}
                  <div className="mt-2">
                    <a
                      href={`${KADENCE_ACCOUNT_V1}/settings/email-campaign/sample/csv`}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download Sample CSV File
                    </a>
                  </div>
                  <small style={{color: '#000', display: 'block'}}>
                    Max upload file size is 5 MB. File must be in CSV format.
                  </small>

                  <VmoFormField width={6} required className="mt-2">
                    <label>Tag name</label>
                    <Controller
                      name="labelByCsv"
                      control={control}
                      render={({value, onChange}) => (
                        <VmoFormInput
                          placeholder="Enter tag name"
                          value={value}
                          onChange={(e, {value}) => onChange(value)}
                          error={
                            errors.labelByCsv && {
                              content: removeDoubleQuotes(errors.labelByCsv.message),
                            }
                          }
                        />
                      )}
                    />
                  </VmoFormField>
                </>
              )}
            </div>
          </VmoForm>
        </VmoCardContent>
      </VmoCard>
    </VmoContainer>
  )
}

export default UserAudience
