import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {VmoButton, VmoModal, VmoModalActions, VmoModalContent, VmoModalHeader} from 'vmo-library'

import {STATUS_PROJECTS_TIMESHEET} from 'actions/types'
import {statusProjectsTimesheet} from 'actions/projects'
import SvgIcon from 'components/common/SvgIcon'

const StatusTimesheetModal = ({open, toggle, projectId, timesheetId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(STATUS_PROJECTS_TIMESHEET)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = () => {
    const data = {status: 'archived'}
    dispatch(statusProjectsTimesheet({projectId, timesheetId, data}))
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>Confirm</VmoModalHeader>
      <VmoModalContent>Are you sure you want to archive the timesheet?</VmoModalContent>
      <VmoModalContent>
        Note: You can always access the archived timesheets using filters and also unarchive them.
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={submitForm}>
          Yes
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default StatusTimesheetModal
