import {
  EXPORT_PROJECTS_TIMESHEET,
  GET_PROJECTS_FILTERS,
  GET_PROJECTS_LIST,
  GET_PROJECTS_LOOKUP,
  GET_PROJECTS_NOTEBOOKS,
  GET_PROJECTS_NOTEBOOK_NOTES,
  GET_PROJECTS_NOTEBOOK_SINGLE_NOTE,
  GET_PROJECTS_SINGLE_NOTEBOOK,
  GET_PROJECTS_SINGLE_TASKS_LIST,
  GET_PROJECTS_SINGLE_TIMESHEET,
  GET_PROJECTS_TASKS_LIST,
  GET_PROJECTS_TASKS_LIST_FILTER,
  GET_PROJECTS_TASKS_LIST_SINGLE_TASK,
  GET_PROJECTS_TASKS_LIST_TASKS,
  GET_PROJECTS_TIMESHEETS,
  GET_PROJECTS_TIMESHEET_SINGLE_TIME_LOG,
  GET_PROJECTS_TIMESHEET_SUMMARY,
  GET_PROJECTS_TIMESHEET_TIME_LOGS,
  GET_PROJECTS_TIME_TRACKING,
  GET_PROJECT_DISCUSSION_SINGLE_TOPIC,
  GET_PROJECT_DISCUSSION_TOPICS,
  GET_PROJECT_DISCUSSION_TOPIC_COMMENTS,
  GET_PROJECT_FINANCE_BILLING_EXPENSE_LIST,
  GET_PROJECT_FINANCE_BUDGET,
  GET_PROJECT_FINANCE_BUDGET_EXPENSE_LIST,
  GET_PROJECT_FINANCE_SINGLE_EXPENSE,
  GET_PROJECT_FINANCE_RATES_LIST,
  GET_PROJECT_FINANCE_SINGLE_RATE,
  GET_SINGLE_PROJECT,
  GET_PROJECTS_TASKS_LIST_SINGLE_STAGE,
  GET_PROJECT_FINANCE_BUDGET_PROFITABILITY,
  GET_PROJECT_FINANCE_BUDGET_OVERVIEW,
  GET_PROJECT_FINANCE_BILLING_UNBILLED_TIME__LIST,
  GET_PROJECT_FINANCE_BILLING_OPEN_INVOICES_LIST,
  GET_PROJECT_FINANCE_BILLING_COMPLETED_INVOICES_LIST,
  GET_PROJECT_FINANCE_BILLING_SINGLE_INVOICE,
  GET_PROJECT_FINANCE_BILLING_INVOICE_LOGS,
  GET_PROJECT_FINANCE_BUDGET_RETAINER_OVERVIEW,
  GET_ALL_PROJECT_RISKS,
  GET_SINGLE_PROJECT_RISK,
  GET_ALL_PROJECT_MILESTONE,
  GET_SINGLE_PROJECT_MILESTONE,
  GET_PROJECT_MILESTONE_COMMENTS,
  GET_ALL_PROJECT_FORMS,
  GET_SINGLE_PROJECT_FORM,
  GET_PUBLIC_PROJECT_FORMS,
  GET_PROJECTS_TEMPLATES_LIST,
  GET_PROJECTS_SINGLE_TEMPLATE,
  GET_PROJECTS_TEMPLATES_TASKLIST,
  GET_PROJECTS_TEMPLATES_SINGLE_TASKLIST,
  GET_PROJECTS_TEMPLATES_TASKLIST_TASKS,
  GET_PROJECTS_TEMPLATES_FILERS,
  GET_PROJECTS_TEMPLATES_TASKLIST_FILTERS,
  GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_TASK,
  GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_STAGE,
  GET_PROJECTS_TEMPLATES_FINANCE_BUDGET,
  GET_PROJECTS_TEMPLATES_FINANCE_RATES_LIST,
  GET_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE,
} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case GET_PROJECTS_LIST: {
      const {projects = [], ...rest} = payload
      return {
        ...state,
        projectsList: projects || [],
        projectsPage: {...rest},
      }
    }

    case GET_PROJECTS_LOOKUP:
      return {
        ...state,
        categories: payload.categories || [],
        users: payload.users || [],
        timesheets: payload.timesheets || [],
      }

    case GET_PROJECTS_FILTERS:
      return {...state, projectAssignees: payload.assignees}

    case GET_SINGLE_PROJECT:
      return {...state, projectData: payload}

    case GET_PROJECT_DISCUSSION_TOPICS:
      return {...state, topics: payload.topics}

    case GET_PROJECT_DISCUSSION_SINGLE_TOPIC:
      return {...state, topicData: payload}

    case GET_PROJECT_DISCUSSION_TOPIC_COMMENTS:
      return {...state, topicComments: payload}

    case 'SOCKET_APPEND_PROJECT_DISCUSSION_TOPIC_COMMENT': {
      const newComments = [...state.topicComments.comments]
      if (+state.topicComments.topicId === payload.topicId) {
        const indexOfComment = state.topicComments.comments.findIndex(comment => comment.id === payload.comment.id)
        if (indexOfComment !== -1) {
          newComments[indexOfComment].content = payload.comment.content
          newComments[indexOfComment].updatedAt = payload.comment.updatedAt
          newComments[indexOfComment].updatedBy = payload.comment.updatedBy
        } else {
          newComments.push(payload.comment)
        }
      }
      return {
        ...state,
        topicComments: {
          ...state.topicComments,
          comments: newComments,
        },
      }
    }

    case 'SOCKET_DELETE_PROJECT_DISCUSSION_TOPIC_COMMENT': {
      let newComments = [...state.topicComments.comments]
      if (+state.topicComments.topicId === payload.topicId) {
        const updatedComments = state.topicComments.comments.filter(comment => comment.id !== payload.id && comment)
        newComments = updatedComments
      }
      return {
        ...state,
        topicComments: {
          ...state.topicComments,
          comments: newComments,
        },
      }
    }

    case GET_PROJECTS_TASKS_LIST:
      return {...state, tasksList: payload.tasklists || []}

    case GET_PROJECTS_SINGLE_TASKS_LIST:
      return {...state, tasksListData: payload}

    case GET_PROJECTS_TASKS_LIST_FILTER:
      return {
        ...state,
        taskListStages: payload.stages || [],
        taskListAssignees: payload.assignees || [],
        taskListLabels: payload.labels || [],
      }

    case GET_PROJECTS_TASKS_LIST_TASKS:
      return {...state, getAllTasks: payload}

    case GET_PROJECTS_TASKS_LIST_SINGLE_TASK:
      return {...state, taskData: payload}

    case GET_PROJECTS_NOTEBOOKS:
      return {...state, notebooksList: payload}

    case GET_PROJECTS_SINGLE_NOTEBOOK:
      return {...state, notebookData: payload}

    case GET_PROJECTS_NOTEBOOK_NOTES:
      return {...state, allNotes: payload.notes}

    case GET_PROJECTS_NOTEBOOK_SINGLE_NOTE:
      return {...state, noteData: payload.note || {}}

    case GET_PROJECTS_TIMESHEETS:
      return {...state, timesheetList: payload}

    case GET_PROJECTS_SINGLE_TIMESHEET:
      return {...state, timesheetData: payload}

    case GET_PROJECTS_TIMESHEET_TIME_LOGS:
      return {...state, getAllTimeLogs: payload}

    case GET_PROJECTS_TIMESHEET_SUMMARY:
      return {...state, timesheetSummary: payload}

    case GET_PROJECTS_TIMESHEET_SINGLE_TIME_LOG:
      return {...state, timeLogData: payload}

    case EXPORT_PROJECTS_TIMESHEET:
      return {...state, exportTimesheetData: payload}

    case GET_PROJECTS_TIME_TRACKING:
      return {...state, timeTrackingList: payload}

    // Finance
    case GET_PROJECT_FINANCE_BUDGET:
      return {...state, isBudgetCreated: payload.isBudgetCreated || false, projectBudget: payload.projectBudget || {}}

    case GET_PROJECT_FINANCE_BUDGET_OVERVIEW:
      return {...state, projectBudgetOverviewData: payload || {}}

    case GET_PROJECT_FINANCE_BUDGET_PROFITABILITY:
      return {...state, projectBudgetProfitabilityData: payload || {}}

    case GET_PROJECT_FINANCE_BUDGET_RETAINER_OVERVIEW:
      return {...state, projectBudgetRetainerOverviewData: payload || {}}

    case GET_PROJECT_FINANCE_RATES_LIST:
      return {...state, ratesList: payload || []}

    case GET_PROJECT_FINANCE_SINGLE_RATE:
      return {...state, rateData: payload || {}}

    case GET_PROJECT_FINANCE_BUDGET_EXPENSE_LIST:
      return {...state, budgetExpenseList: payload || []}

    case GET_PROJECT_FINANCE_SINGLE_EXPENSE:
      return {...state, budgetExpenseData: payload || {}}

    case GET_PROJECT_FINANCE_BILLING_OPEN_INVOICES_LIST:
      return {...state, billingOpenInvoicesList: payload || []}

    case GET_PROJECT_FINANCE_BILLING_COMPLETED_INVOICES_LIST:
      return {...state, billingCompletedInvoicesList: payload || []}

    case GET_PROJECT_FINANCE_BILLING_UNBILLED_TIME__LIST:
      return {...state, billingUnbilledTimeList: payload || []}

    case GET_PROJECT_FINANCE_BILLING_EXPENSE_LIST:
      return {...state, billingExpenseList: payload || []}

    case GET_PROJECT_FINANCE_BILLING_SINGLE_INVOICE:
      return {...state, billingInvoiceData: payload || {}}

    case GET_PROJECT_FINANCE_BILLING_INVOICE_LOGS:
      return {...state, billingInvoiceLogs: payload || {}}

    case GET_PROJECTS_TASKS_LIST_SINGLE_STAGE:
      return {...state, stageData: payload || {}}

    // Risks

    case GET_ALL_PROJECT_RISKS:
      return {
        ...state,
        getRisksList: payload,
      }

    case GET_SINGLE_PROJECT_RISK:
      return {
        ...state,
        getSingleRiskData: payload,
      }

    case GET_ALL_PROJECT_MILESTONE:
      return {
        ...state,
        getMilestoneList: payload.milestones,
        getMilestoneCounts: payload.counts,
      }

    case GET_SINGLE_PROJECT_MILESTONE:
      return {
        ...state,
        getSingleMilestoneData: payload,
      }

    case GET_PROJECT_MILESTONE_COMMENTS:
      return {
        ...state,
        getMilestoneCommentsData: action.payload,
      }

    case GET_ALL_PROJECT_FORMS:
      return {
        ...state,
        getFormsList: payload.forms,
      }

    case GET_SINGLE_PROJECT_FORM:
      return {
        ...state,
        getSingleFormData: payload,
      }

    case GET_PUBLIC_PROJECT_FORMS:
      return {
        ...state,
        getAllData: payload,
        getQuestionsData: payload.questions,
        getSettingsData: payload.settings,
        getDesignData: payload.design,
      }

    // Projects templates
    case GET_PROJECTS_TEMPLATES_LIST:
      return {...state, projectsTemplatesList: payload || []}

    case GET_PROJECTS_SINGLE_TEMPLATE: {
      return {...state, projectsTemplateData: payload || {}}
    }

    case GET_PROJECTS_TEMPLATES_FILERS:
      return {...state, projectsTemplatesAssignees: payload.assignees || []}

    case GET_PROJECTS_TEMPLATES_TASKLIST:
      return {...state, projectsTemplatesTasklist: payload || {}}

    case GET_PROJECTS_TEMPLATES_SINGLE_TASKLIST:
      return {...state, projectsTemplatesTasklistData: payload || {}}

    case GET_PROJECTS_TEMPLATES_TASKLIST_FILTERS:
      return {...state, projectsTempaltesTasklistsFilters: payload || {}}

    case GET_PROJECTS_TEMPLATES_TASKLIST_TASKS:
      return {...state, projectsTemplatesTasklistTasks: payload || {}}

    case GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_TASK:
      return {...state, projectsTemplatesTasklistTaskData: payload || {}}

    case GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_STAGE:
      return {...state, projectsTemplatesTasklistStageData: payload || {}}

    case GET_PROJECTS_TEMPLATES_FINANCE_BUDGET:
      return {
        ...state,
        isTemplateBudgetCreated: payload.isBudgetCreated || false,
        templateBudget: payload.templateBudget || {},
      }

    case GET_PROJECTS_TEMPLATES_FINANCE_RATES_LIST:
      return {...state, projectsTemplatesFinanceRatesList: payload || []}

    case GET_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE:
      return {...state, projectsTemplatesFinanceRateData: payload || {}}

    default:
      return state
  }
}
