import {joiResolver} from '@hookform/resolvers'
import {getProjectsNotebookSingleNote, updateProjectsNotebookNoteDescription} from 'actions/projects'
import {
  CREATE_PROJECTS_NOTEBOOK_NOTE_COMMENT,
  DELETE_PROJECTS_NOTEBOOK_NOTE_COMMENT,
  GET_PROJECTS_NOTEBOOK_SINGLE_NOTE,
  UPDATE_PROJECTS_NOTEBOOK_NOTE_COMMENT,
  UPDATE_PROJECTS_NOTEBOOK_NOTE_DESCRIPTION,
} from 'actions/types'
import {get, getFormattedDate} from 'utils/helper'
import Joi from 'joi'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoCheckbox,
  VmoEditor,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTab,
  VmoTabPane,
} from 'vmo-library'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import Comments from './Comments'

const editorOptions = {
  height: 600,
  paragraphStyles: [
    'spaced',
    {
      name: 'Custom',
      class: '__se__customClass',
    },
  ],
  buttonList: [
    [
      'undo',
      'redo',
      'font',
      'fontSize',
      'formatBlock',
      'paragraphStyle',
      'bold',
      'underline',
      'italic',
      'strike',
      'subscript',
      'superscript',
      'fontColor',
      'hiliteColor',
      'textStyle',
      'removeFormat',
      'indent',
      'align',
      'horizontalRule',
      'list',
      'lineHeight',
      'table',
      'link',
      'image',
      'video',
      'fullScreen',
      'showBlocks',
      'codeView',
      'print',
    ],
  ],
}

const ViewNote = props => {
  const {open, toggle, projectId, noteId, rolePermissions} = props

  const dispatch = useDispatch()

  const [noteTitle, setNoteTitle] = useState('')
  const [isEditable, setIsEditable] = useState(false)
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
  const [comments, setComments] = useState([])

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {
    handleSubmit,
    control,
    formState: {isDirty},
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      description: '',
    },
    resolver: joiResolver(
      Joi.object({
        description: Joi.string().empty(''),
      })
    ),
  })

  const {callAction: callGetProjectsNotebookSingleNoteAction} = useApiResponse({
    action: getProjectsNotebookSingleNote,
    payload: {projectId, noteId},
    dependency: [projectId, noteId],
    enabled: projectId && noteId,
    label: GET_PROJECTS_NOTEBOOK_SINGLE_NOTE,
    storePath: 'projects.noteData',
    onSuccess: (noteData = {}) => {
      setNoteTitle(get(['title'], noteData, ''))
      reset({description: get(['description'], noteData, '')})

      const newArray = []
      const allComments = [...get(['comments'], noteData, [])]
      const commentsReverse = allComments.reverse()
      _.forEachRight(commentsReverse, function (value, index, array) {
        let temp = {}
        if (index === 0) {
          temp = {...value, showDateStamp: true}
        } else if (
          array[index - 1] &&
          getFormattedDate(value.createdAt) !== getFormattedDate(array[index - 1].createdAt)
        ) {
          temp = {...value, showDateStamp: true}
        } else {
          temp = {...value, showDateStamp: false}
        }
        newArray.push(temp)
      })
      setComments(newArray.reverse() || [])
    },
  })

  useEffect(() => {
    if (
      successLabels.includes(UPDATE_PROJECTS_NOTEBOOK_NOTE_DESCRIPTION) ||
      successLabels.includes(CREATE_PROJECTS_NOTEBOOK_NOTE_COMMENT) ||
      successLabels.includes(UPDATE_PROJECTS_NOTEBOOK_NOTE_COMMENT) ||
      successLabels.includes(DELETE_PROJECTS_NOTEBOOK_NOTE_COMMENT)
    ) {
      callGetProjectsNotebookSingleNoteAction()
    }
  }, [successLabels, callGetProjectsNotebookSingleNoteAction])

  const submitForm = data => {
    dispatch(updateProjectsNotebookNoteDescription({projectId, noteId, data}))
  }

  const closeModal = () => {
    if (isDirty) {
      setIsWarningModalOpen(isDirty)
    } else {
      toggle(false)
    }
  }

  const warningModal = () => {
    return (
      <VmoModal
        size="tiny"
        dimmer="blurring"
        open
        centered={false}
        closeIcon={<SvgIcon path="common/close" className="closeIcon" onClick={() => setIsWarningModalOpen(false)} />}
      >
        <VmoModalHeader className="vmoModalHeader">Warning</VmoModalHeader>
        <VmoModalContent>
          <h5>There are some unsaved changes !</h5>
          <p className="kadence-lead">Do you still wish to leave?</p>
        </VmoModalContent>
        <VmoModalActions>
          <VmoButton basic onClick={() => setIsWarningModalOpen(false)}>
            No
          </VmoButton>
          <VmoButton
            primary
            content="Yes"
            onClick={() => {
              setIsWarningModalOpen(false)
              toggle(false)
            }}
          />
        </VmoModalActions>
      </VmoModal>
    )
  }

  return (
    <>
      {isWarningModalOpen && warningModal()}

      <VmoModal
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        size="fullscreen"
        open={open}
        onClose={closeModal}
      >
        <VmoModalHeader style={{display: 'flex', alignItems: 'center'}}>
          <div>{noteTitle}</div>
          <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
            <VmoCheckbox
              style={{paddingLeft: '12px'}}
              label="Edit"
              checked={isEditable}
              onChange={(e, {checked}) => rolePermissions && setIsEditable(checked)}
            />
          </LockPermissionTooltip>
        </VmoModalHeader>
        <div className="d-flex" style={{alignItems: 'flex-start', justifyContent: 'flex-start'}}>
          <div className="notes-editor">
            <VmoModalContent>
              <Controller
                name="description"
                control={control}
                render={({value, onChange}) => (
                  <VmoEditor
                    width="100%"
                    setOptions={editorOptions}
                    setDefaultStyle="font-size: 15px; line-height: 1.5;"
                    setContents={value}
                    onChange={content => onChange(content)}
                    disable={!isEditable}
                  />
                )}
              />
            </VmoModalContent>
            <VmoModalActions style={{display: 'flex', justifyContent: 'flex-end'}} className="p-3">
              <VmoButton basic onClick={closeModal}>
                Cancel
              </VmoButton>
              <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
                <VmoButton
                  primary
                  disabled={!isDirty}
                  {...(rolePermissions && {
                    onClick: handleSubmit(submitForm),
                  })}
                >
                  Save
                </VmoButton>
              </LockPermissionTooltip>
            </VmoModalActions>
          </div>
          <VmoModalContent className="notes-editor-side">
            <VmoTab
              menu={{color: 'blue', secondary: true, pointing: true}}
              panes={[
                {
                  menuItem: 'Comments',
                  render: () => (
                    <VmoTabPane>
                      <Comments
                        comments={comments}
                        projectId={projectId}
                        noteId={noteId}
                        rolePermissions={rolePermissions}
                      />
                    </VmoTabPane>
                  ),
                },
              ]}
            />
          </VmoModalContent>
        </div>
      </VmoModal>
    </>
  )
}

export default ViewNote
