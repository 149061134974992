import React, {useCallback} from 'react'
import {FaCalendar} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {startCase} from 'utils/helper'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {GET_PROJECTS_USERS_TASKS_BY_USER_LIST} from 'actions/types'
import {getProjectsUsersTasksByUser} from 'actions/analytics_projects'
import SvgIcon from 'components/common/SvgIcon'
import DateRangeGroup from '../../DateRangeGroup'

const TaskByUsers = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)
  const {projectsUsersTasksByUserList = []} = useSelector(state => state.analyticsProjects)

  const actionsToDispatch = useCallback(
    data => {
      const {from, to} = data
      const payload = {from, to}
      dispatch(getProjectsUsersTasksByUser(payload))
    },
    [dispatch]
  )

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_USERS_TASKS_BY_USER_LIST)) {
      return <VmoTablePlaceholder columns={5} />
    }
    if (!type.includes(GET_PROJECTS_USERS_TASKS_BY_USER_LIST) && !projectsUsersTasksByUserList.length) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="5" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return projectsUsersTasksByUserList.map(user => (
      <VmoTableRow key={user.id} className="tableLink">
        <VmoTableCell>{startCase(user.name)}</VmoTableCell>
        <VmoTableCell>{user.openProject || 0}</VmoTableCell>
        <VmoTableCell>{user.assigneedTask || 0}</VmoTableCell>
        <VmoTableCell>{user.completedTask || 0}</VmoTableCell>
        <VmoTableCell>{user.overDueTask || 0}</VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="kadence-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <FaCalendar />
            <h5>Task by Users</h5>
          </div>
        </div>
        <div className="page-action">
          <DateRangeGroup actionsToDispatch={actionsToDispatch} />
        </div>
      </div>

      <VmoGrid>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Task by Users</VmoCardHeader>
              <VmoTooltip
                content="lorem ipsum"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>User</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Open Projects</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Assigned Tasks</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Completed Tasks</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Overdue Tasks</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>{renderTableBody()}</VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default TaskByUsers
