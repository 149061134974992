import {joiResolver} from '@hookform/resolvers'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {
  VmoButton,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormRadio,
  VmoFormSelect,
  VmoFormTextArea,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'

import {priorityList, statusList, taskTypes} from 'constants/variables'
import {startCase} from 'utils/helper'
import {createTaskSchema} from 'validation/Sales/sequence.schema'
import SvgIcon from 'components/common/SvgIcon'

const placeHolderList = [
  {value: '{{firstName}}', text: 'First Name'},
  {value: '{{lastName}}', text: 'Last Name'},
  {value: '{{companyName}}', text: 'Company Name'},
  {value: '{{jobTitle}}', text: 'Job title'},
]

const CreateTask = props => {
  const {handleAdd, addType, toggle, editData, open} = props
  const getType = type => {
    if (type === 'task_activity') {
      return 'activity'
    }
    if (type === 'task_meeting') {
      return 'meeting'
    }
    if (type === 'task_call') {
      return 'call'
    }
  }

  const {handleSubmit, errors, control, watch, setValue} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(createTaskSchema),
    defaultValues: editData || {
      type: getType(addType),
      subject: '',
      status: 'new',
      duration: '',
      priority: 'default',
      description: '',
      callType: 'inbound',
    },
  })

  const currentType = watch('type')
  const currentSubject = watch('subject')

  const createTask = data => {
    handleAdd(data)
    toggle(false)
  }

  const addPlaceholder = (string, stringToBeAdded) => {
    return string + stringToBeAdded
  }

  const createEditTaskForm = () => {
    return (
      <VmoForm className="errorLabel">
        <VmoFormField required>
          <label>Select Type</label>
          <Controller
            name="type"
            render={({value, onChange, onBlur}) => (
              <VmoFormSelect
                fluid
                options={taskTypes}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                onBlur={onBlur}
                error={
                  errors.type && {
                    content: errors.type.message,
                  }
                }
              />
            )}
            control={control}
          />
        </VmoFormField>

        <VmoFormField required>
          <label>
            Subject
            <div className="mailActivity" style={{float: 'right'}}>
              <VmoDropdown button icon="chevron down" text="Insert Merge Fields">
                <VmoDropdownMenu>
                  {placeHolderList.map(title => (
                    <VmoDropdownItem onClick={() => setValue('subject', addPlaceholder(currentSubject, title.value))}>
                      {title.text}
                    </VmoDropdownItem>
                  ))}
                </VmoDropdownMenu>
              </VmoDropdown>
            </div>
          </label>
          <Controller
            name="subject"
            control={control}
            render={({value, onChange, onBlur}) => (
              <VmoFormInput
                placeholder="Type your Subject.."
                maxLength={30}
                value={value}
                onChange={e => onChange(e.target.value)}
                onBlur={onBlur}
                error={
                  errors.subject && {
                    content: errors.subject.message,
                  }
                }
              />
            )}
          />
        </VmoFormField>

        {currentType === 'call' && (
          <VmoFormGroup className="fields" inline>
            <label>Direction</label>
            <Controller
              name="callType"
              render={({value, onChange}) => (
                <>
                  <VmoFormRadio
                    label="Inbound"
                    value="inbound"
                    checked={value === 'inbound'}
                    onClick={(e, {value}) => onChange(value)}
                    error={
                      errors.callType && {
                        content: errors.callType.message,
                      }
                    }
                  />
                  <VmoFormRadio
                    label="Outbound"
                    value="outbound"
                    checked={value === 'outbound'}
                    onClick={(e, {value}) => onChange(value)}
                    error={
                      errors.callType && {
                        content: errors.callType.message,
                      }
                    }
                  />
                </>
              )}
              control={control}
            />
          </VmoFormGroup>
        )}

        <VmoFormGroup widths="equal">
          <VmoFormField required>
            <label>Status</label>
            <Controller
              name="status"
              render={({value, onChange, onBlur}) => (
                <VmoFormSelect
                  selection
                  search
                  fluid
                  options={statusList}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  error={
                    errors.status && {
                      content: errors.status.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>

          <VmoFormField required>
            <label>Priority</label>
            <Controller
              name="priority"
              render={({value, onChange, onBlur}) => (
                <VmoFormSelect
                  fluid
                  search
                  selection
                  options={priorityList}
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  onBlur={onBlur}
                  error={
                    errors.priority && {
                      content: errors.priority.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoFormGroup>
        <VmoFormGroup widths="equal">
          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Duration</label>
              <VmoTooltip trigger={<SvgIcon path="common/question" />} content="Select number of days" size="mini" />
            </div>
            <Controller
              name="duration"
              control={control}
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  placeholder="Duration"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  onBlur={onBlur}
                  error={
                    errors.duration && {
                      content: errors.duration.message,
                    }
                  }
                />
              )}
            />
          </VmoFormField>
        </VmoFormGroup>
        <VmoFormField>
          <label>Description</label>
          <Controller
            name="description"
            render={({value, onChange, onBlur}) => (
              <VmoFormTextArea
                maxLength={200}
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                }}
                onBlur={onBlur}
                error={
                  errors.description && {
                    content: errors.description.message,
                  }
                }
              />
            )}
            control={control}
          />
        </VmoFormField>
      </VmoForm>
    )
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={open}
      onClose={() => toggle(false)}
      size="tiny"
      centered={false}
    >
      <VmoModalHeader className="vmoModalHeader">{startCase(addType)}</VmoModalHeader>
      <VmoModalContent scrolling>{createEditTaskForm()}</VmoModalContent>
      <VmoModalActions>
        <VmoButton basic className="mr-3" onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(data => createTask(data))}>
          Save
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default CreateTask
