import React from 'react'
import {useDispatch} from 'react-redux'
import _ from 'lodash'
import {VmoButton, VmoDropdown, VmoFormField, VmoFormInput, VmoIcon, VmoTooltip} from 'vmo-library'
import {removeDoubleQuotes} from 'utils/helper'
import {emailOpition} from 'constants/variables'
import SvgIcon from './SvgIcon'

const Email = props => {
  const dispatch = useDispatch()
  const {
    id,
    deleteEmail,
    refForm,
    control,
    Controller,
    errors,
    emailFields,
    appendEmail,
    removeEmail,
    disabled = false,
  } = props
  return emailFields.map((item, index) => {
    return (
      <div className="mutiple" key={item.id}>
        <VmoFormField className={index !== 0 && 'mt-3'}>
          <div className="hover-plusicon">
            <Controller
              control={control}
              name={`emails[${index}].category`}
              defaultValue={item.category}
              render={({onChange, value, onBlur}) => (
                <div className="mailActivity">
                  <VmoDropdown
                    className="email-component"
                    icon="chevron down"
                    button
                    placeholder="select category"
                    options={emailOpition}
                    onChange={(e, {value}) => {
                      onChange(value)
                      refForm.current.handleSubmit()
                    }}
                    defaultValue={item.category}
                    disabled={disabled}
                    error={
                      _.get(errors, `emails[${index}].category`) && {
                        content: removeDoubleQuotes(_.get(errors, `emails[${index}].category.message`)),
                      }
                    }
                  />
                </div>
              )}
            />
            <div className="d-flexb">
              {_.size(emailFields) > 1 && (
                <div className="ShowAddBtn">
                  <VmoButton
                    style={{padding: '6px'}}
                    disabled={disabled}
                    onClick={() => {
                      if (emailFields[index].id && emailFields[index].category && emailFields[index].email) {
                        dispatch(deleteEmail(id, emailFields[index].id))
                      }
                      removeEmail(index)
                    }}
                  >
                    <VmoTooltip
                      content="Delete Field"
                      size="mini"
                      position="left center"
                      trigger={<VmoIcon className="m-0" name="trash alternate outline" />}
                    />
                  </VmoButton>
                </div>
              )}
              {index === emailFields.length - 1 && _.size(emailFields) < 3 && (
                <div className="ShowAddBtn">
                  <VmoButton
                    style={{padding: '4px'}}
                    disabled={disabled}
                    onClick={() => {
                      appendEmail({
                        category: item.category,
                        email: '',
                      })
                    }}
                  >
                    <VmoTooltip
                      content="Add Email"
                      size="mini"
                      position="left center"
                      trigger={<SvgIcon path="common/plus" />}
                    />
                  </VmoButton>
                </div>
              )}
            </div>
          </div>
          <Controller
            control={control}
            name={`emails[${index}].email`}
            defaultValue={item.email}
            render={({onChange, value, onBlur}) => (
              <VmoFormInput
                defaultValue={item.email}
                placeholder="Add email"
                disabled={disabled}
                onChange={(e, {value}) => {
                  onChange(value)
                }}
                onBlur={e => {
                  onBlur(e)
                  refForm.current.handleSubmit()
                }}
                error={
                  _.get(errors, `emails[${index}].email`) && {
                    content: 'Enter a vaild email',
                  }
                }
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.target.blur()
                    e.preventDefault()
                    e.stopPropagation()
                  }
                }}
              />
            )}
          />
        </VmoFormField>
      </div>
    )
  })
}

export default Email
