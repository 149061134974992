import React from 'react'
import {VmoModal, VmoModalHeader, VmoModalActions, VmoButton, VmoRadio, VmoModalContent} from 'vmo-library'

const ExportLeadModal = props => {
  const {isModalOpen, setIsModalOpen} = props

  return (
    <VmoModal open={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)}>
      <VmoModalHeader>Export Contacts</VmoModalHeader>
      <VmoModalContent style={{display: 'grid'}}>
        <VmoRadio name="export" label="Export all selected Contacts (1 Lead selected)" />
        <VmoRadio name="export" label="Export all Contacts" className="my-4" />
        <VmoRadio name="export" label="Export all Contacts that match this filter" />
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </VmoButton>
        <VmoButton color="blue">Export</VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default ExportLeadModal
