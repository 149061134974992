const Joi = require('joi')

export const teamSchema = Joi.object({
  name: Joi.string().min(2).max(20).required().messages({
    'string.min': `Length must be at least 2 characters`,
    'string.max': `Length must be less than or equal to 20 characters`,
  }),
  assigneeIds: Joi.array().items(Joi.number().required()).messages({
    'array.includesRequiredUnknowns': `Team member must be specified`,
  }),
  description: Joi.string().allow('', null).max(200).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
})
