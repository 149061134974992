import {
  CONTACT_DEVICES_ANALYTICS,
  CONVERSATION_STATS,
  CONVERSATION_STATUS_ANALYTICS,
  HOMEPAGE_LIVE_COUNT,
  HOME_USERS_ANALYTICS,
  INFLOW_BY_CHANNEL,
  NPS_SCORE_ANALYTICS,
} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case INFLOW_BY_CHANNEL:
      return {
        ...state,
        inflowChannel: payload,
      }

    case NPS_SCORE_ANALYTICS:
      return {
        ...state,
        npsScoreAnalyticsData: payload,
      }

    case HOMEPAGE_LIVE_COUNT:
      return {...state, liveCountData: payload}

    case CONTACT_DEVICES_ANALYTICS:
      return {
        ...state,
        analyticsDevices: payload,
      }

    case CONVERSATION_STATS:
      return {
        ...state,
        conversationStatsData: payload || [],
      }

    case CONVERSATION_STATUS_ANALYTICS:
      return {
        ...state,
        conversationStatusData: payload || [],
      }

    case HOME_USERS_ANALYTICS:
      return {...state, homeUserAnalyticsData: payload}

    default:
      return state
  }
}
