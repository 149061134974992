import React from 'react'
import create from 'assets/create.svg'
import CreateFirst from 'components/common/CreateFirst'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import PurchaseOrdersMenu from 'navigation/sub-routes/Finance/PurchaseOrders/PurchaseOrdersMenu'
import PurchaseOrdersRoutes from 'navigation/sub-routes/Finance/PurchaseOrders/PurchaseOrdersRoutes'
import {getPermissionTooltipContent} from 'utils/helper'

function PurchaseOrders() {
  const {financeAccess} = useUserPermissions()
  const purchaseOrdersAccess = financeAccess?.purchaseOrders

  if (!purchaseOrdersAccess) {
    return (
      <CreateFirst
        header="Purchase Orders"
        buttonText="Create"
        tooltip={!purchaseOrdersAccess}
        tooltipContent={getPermissionTooltipContent({featureAccess: purchaseOrdersAccess})}
        imageSrc={create}
        subHeader="Purchase order helps to create and manage orders for purchasing products from suppliers."
        Feature1="Custom Templates"
        list1="Use custom templates to suits your brand"
        Feature2="Add Tax rate"
        list2="Include tax in purchase order and send to customers"
        Feature3="Purchase order tracking"
        list3="Track the status of your purchase order"
      />
    )
  }
  return (
    <>
      <PurchaseOrdersMenu />
      <PurchaseOrdersRoutes />
    </>
  )
}

export default PurchaseOrders
