/* eslint-disable no-useless-escape */
const Joi = require('joi')

export const addProofSchema = Joi.object().keys({
  name: Joi.string().trim().min(3).max(20).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 20 characters`,
  }),
  projectId: Joi.number().required(),
  tasklistId: Joi.number().allow(null),
  taskId: Joi.number().allow(null),
  description: Joi.string().trim().max(160).allow('', null).messages({
    'string.max': `Length must be less than or equal to 160 characters`,
  }),
  dueDate: Joi.date().iso().required(),
  links: Joi.array().items({
    link: Joi.string()
      .trim()
      .regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/)
      .when('options', {
        is: 'link',
        then: Joi.string()
          .trim()
          .regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/)
          .required()
          .messages({
            'string.pattern.base': 'Enter a valid url',
            'string.empty': 'link is not allowed to be empty',
          }),
      })
      .required()
      .messages({
        'string.pattern.base': 'Enter a valid url',
        'string.empty': 'link is not allowed to be empty',
      }),
  }),
  option: Joi.string().required(),
  attachmentIds: Joi.array().when('options', {
    is: 'file',
    then: Joi.array().items(Joi.number()).required(),
  }),
  assignees: Joi.array().items({
    status: Joi.string().required(),
    reviewerIds: Joi.array().items(Joi.number()).min(1).required().messages({
      'array.min': 'assignee must be specified',
    }),
  }),
})

export const approvalStatusSchema = Joi.object().keys({
  approvalStatus: Joi.string(),
  remarks: Joi.string().max(250).messages({
    'string.max': `Length must be less than or equal to 250 characters`,
  }),
})

export const newVersionSchema = Joi.object().keys({
  description: Joi.string().trim().max(160).allow('', null).messages({
    'string.max': `Length must be less than or equal to 160 characters`,
  }),
  option: Joi.string().required(),
  links: Joi.array().items({
    link: Joi.string()
      .trim()
      .regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/)
      .when('options', {
        is: 'link',
        then: Joi.string()
          .trim()
          .regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/)
          .required()
          .messages({
            'string.pattern.base': 'Enter a valid url',
            'string.empty': 'link is not allowed to be empty',
          }),
      })
      .required()
      .messages({
        'string.pattern.base': 'Enter a valid url',
        'string.empty': 'link is not allowed to be empty',
      }),
  }),
  attachmentIds: Joi.array().when('options', {
    is: 'file',
    then: Joi.array().items(Joi.number()).required(),
  }),
})

export const proofCommentSchema = Joi.object().keys({
  comment: Joi.string().required(),
})
