import {inputStringValidation} from 'validation'
import Joi from 'joi'

export const setupSchema = Joi.object({
  name: inputStringValidation.label('Name').messages({
    'string.empty': `Name is not allowed to be empty`,
    'any.required': `Name is a required field`,
    'string.min': `Name length must be at least 2 characters long`,
    'string.max': `Name length must be less than or equal to 20 characters`,
  }),
  dealId: Joi.number().label('Deal').messages({
    'number.base': `Deal is required`,
  }),
  quoteNo: Joi.string().trim().label('Quote Number').messages({
    'string.empty': `Quote Number is not allowed to be empty`,
  }),
  prefixId: Joi.number().integer().allow(null, '').label('Quote Prefix'),
  validTill: Joi.string().isoDate().label('Valid Until').messages({
    'string.empty': `Valid Until is required`,
  }),
})

export const buyerInfoSchema = Joi.object({
  firstName: inputStringValidation.required().messages({
    'string.empty': `First Name is not allowed to be empty`,
    'any.required': `First Name is a required field`,
    'string.min': `First Name length must be at least 2 characters long`,
    'string.max': `First Name length must be less than or equal to 20 characters`,
  }),
  lastName: inputStringValidation.required().messages({
    'string.empty': `Last Name is not allowed to be empty`,
    'any.required': `Last Name is a required field`,
    'string.min': `Last Name length must be at least 2 characters long`,
    'string.max': `Last Name length must be less than or equal to 20 characters`,
  }),
  jobTitle: Joi.string().trim().required().messages({
    'string.empty': `Job Title is not allowed to be empty`,
  }),
  phone: Joi.string()
    .trim()
    .max(20)
    .regex(/^\+?[0-9]{4,20}$/)
    .messages({
      'string.pattern.base': 'Enter a valid phone number',
      'string.empty': `Phone is not allowed to be empty`,
      'string.max': `phone is allowed to be max 20 characters `,
    }),
  email: Joi.string()
    .trim()
    .email({tlds: {allow: false}})
    .required()
    .messages({
      'string.empty': `Email is not allowed to be empty`,
      'any.required': `Email is a required field`,
      'string.email': `Email must be a valid email`,
      'any.validate': `Email cannot be an empty field`,
    }),
  id: Joi.number().allow(''),
  isSelected: Joi.boolean().allow(''),
})

export const buyerForm = Joi.object({
  company: Joi.object(),
  contacts: Joi.array(),
})

export const buyerInfoFormSchema = Joi.object({
  locality: Joi.string().trim().max(20).required().messages({
    'string.empty': `Company is not allowed to be empty`,
    'any.required': `Company is a required field`,
    'string.max': `Company length must be less than or equal to 20 characters`,
  }),
  country: Joi.string().trim().max(20).required().messages({
    'string.empty': `Country is not allowed to be empty`,
    'any.required': `Country is a required field`,
    'string.max': `Country length must be less than or equal to 20 characters`,
  }),
  state: Joi.string().trim().max(20).required().messages({
    'string.empty': `State is not allowed to be empty`,
    'any.required': `State is a required field`,
    'string.max': `State length must be less than or equal to 20 characters`,
  }),
  name: inputStringValidation.required().optional().messages({
    'string.empty': `Name is not allowed to be empty`,
    'string.min': `Name length must be atleast 4 characters long`,
    'string.max': `Name length must be lesser or equal to 20 characters long`,
  }),
  streetAddress: Joi.string().max(50).trim().required().optional().messages({
    'string.empty': `Address is not allowed to be empty`,
    'string.max': `Address length must be lesser or equal to 50 characters long`,
  }),
  postalCode: Joi.string().required().label('Postal code').messages({
    'string.empty': `Postal code is not allowed to be empty`,
  }),
})

export const yourInfoSchema = Joi.object({
  firstName: Joi.string().trim().required().messages({
    'string.empty': `First Name is not allowed to be empty`,
  }),
  lastName: Joi.string().trim().required().messages({
    'string.empty': `Last Name is not allowed to be empty`,
  }),
  jobTitle: Joi.string().trim().required().messages({
    'string.empty': `Job Title is not allowed to be empty`,
  }),
  phone: Joi.string()
    .trim()
    .max(20)
    .regex(/^\+?[0-9]{4,20}$/)
    .messages({
      'string.pattern.base': 'Enter a valid phone number',
      'string.empty': `Phone is not allowed to be empty`,
    }),
  email: Joi.string()
    .trim()
    .email({tlds: {allow: false}})
    .required()
    .messages({
      'string.empty': `Email is not allowed to be empty`,
      'any.required': `Email is a required field`,
      'string.email': `Email must be a valid email`,
      'any.validate': `Email cannot be an empty field`,
    }),
  company: Joi.object({
    locality: Joi.string().max(20).trim().empty('').messages({
      'string.empty': `Company is not allowed to be empty`,
      'any.required': `Company is a required field`,
      'string.max': `Company length must be less than or equal to 20 characters`,
    }),
    country: Joi.string().trim().max(20).empty('').messages({
      'string.empty': `Country is not allowed to be empty`,
      'any.required': `Country is a required field`,
      'string.max': `Country length must be less than or equal to 20 characters`,
    }),
    state: Joi.string().trim().max(20).empty('').messages({
      'string.empty': `State is not allowed to be empty`,
      'any.required': `State is a required field`,
      'string.max': `State length must be less than or equal to 20 characters`,
    }),
    name: inputStringValidation.required().messages({
      'string.empty': `Name is not allowed to be empty`,
      'string.min': `Name must be minimum 4 characters long`,
    }),

    streetAddress: Joi.string().max(50).trim().empty('').messages({
      'string.empty': `Address is not allowed to be empty`,
      'string.max': `Address length must be lesser or equal to 50 characters long`,
    }),
    postalCode: Joi.string().allow(null, '').label('Postal code'),
  }),
})

export const productSchema = Joi.object({
  products: Joi.array().min(1),
  otherCharges: Joi.array().items({
    type: Joi.string(),
    subType: Joi.string(),
    value: Joi.number().min(1),
    total: Joi.number(),
    name: Joi.string(),
  }),
  total: Joi.number(),
  subTotal: Joi.number(),
})
