import React from 'react'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import {FiDownload} from 'react-icons/fi'
import {
  VmoButton,
  VmoHeader,
  VmoLabel,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {invoiceListing} from 'actions/billing'
import {USER_PLATFORM_V1} from 'actions/endpoint'
import {INVOICE_LISTING} from 'actions/types'
import SettingLayout from 'layouts/settingLayout'
import {get, getFormattedDate, startCase} from 'utils/helper'
import {getRouteState} from 'utils/local-storage'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const getBadgeColorStatus = status => {
  if (status === 'draft') {
    return ''
  }
  if (status === 'paid') {
    return 'green'
  }
}

const Invoices = () => {
  const history = useHistory()

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const invoicesLearnMore = settingsLearnMoreUrl?.billing?.invoices?.learnMore

  const {isLoading, data: getListOfInvoiceData = []} = useApiResponse({
    action: invoiceListing,
    enabled: true,
    label: INVOICE_LISTING,
    storePath: 'billing.getListOfInvoiceData',
  })

  const renderTableBody = () => {
    if (isLoading) {
      return <VmoTablePlaceholder columns={7} row={4} />
    }
    if (!isLoading && getListOfInvoiceData.length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="7" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return getListOfInvoiceData.map((invoice, index) => (
      <VmoTableRow
        key={invoice.id}
        className="tableLink"
        onClick={() =>
          history.push({
            pathname: `/settings/invoices/${invoice.id}`,
            state: {
              ...getRouteState(),
              invoiceName: `${get(['data', [0], 'plan'], invoice, '-')}`,
            },
          })
        }
      >
        <VmoTableCell>{index + 1}</VmoTableCell>
        <VmoTableCell>
          <VmoHeader as="h5">
            {startCase(get(['data', [0], 'plan'], invoice) || get(['data', [0], 'addon'], invoice))}
          </VmoHeader>
        </VmoTableCell>
        <VmoTableCell>{get(['data', [0], 'quantity'], invoice, 0)}</VmoTableCell>
        <VmoTableCell>{`${getFormattedDate(invoice.createdAt)}`}</VmoTableCell>
        <VmoTableCell>{get(['total'], invoice) / 100}</VmoTableCell>
        <VmoTableCell>
          <VmoLabel color={getBadgeColorStatus(get(['status'], invoice, 1))}>
            {startCase(get(['status'], invoice, '--'))}
          </VmoLabel>
        </VmoTableCell>
        <VmoTableCell>
          {invoice.gatewayInvoiceId && (
            <VmoTooltip
              content="Download"
              size="mini"
              position="top center"
              trigger={
                <VmoButton
                  icon
                  circular
                  basic
                  className="vmoBtn"
                  onClick={e => {
                    e.stopPropagation()
                    window.open(`${USER_PLATFORM_V1}/billing/invoices/download/${invoice.gatewayInvoiceId}`, '_blank')
                  }}
                >
                  <FiDownload />
                </VmoButton>
              }
            />
          )}
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/invoice" />}
      header="Invoices"
      subHeader="Manage and download your invoices."
      learnMoreUrl={invoicesLearnMore}
      table
      headerDivider
    >
      <VmoTable basic selectable>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Plan</VmoTableHeaderCell>
            <VmoTableHeaderCell>User</VmoTableHeaderCell>
            <VmoTableHeaderCell>Created at</VmoTableHeaderCell>
            <VmoTableHeaderCell>Total</VmoTableHeaderCell>
            <VmoTableHeaderCell>Status</VmoTableHeaderCell>
            <VmoTableHeaderCell>Invoices</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>{renderTableBody()}</VmoTableBody>
      </VmoTable>
    </SettingLayout>
  )
}

export default Invoices
