import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {
  VmoButton,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {DELETE_TEMPLATE, GET_TEMPLATE} from 'actions/types'
import {deleteEmailTemplate, getEmailTemplate} from 'actions/settings'
import {get, getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import img from 'assets/create.svg'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import EmailTemplateForm from './EmailTemplateForm'

const EmailTemplate = () => {
  const history = useHistory()

  const [idToEdit, setIdToEdit] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)
  const [createCanResponse, setCreateCanResponse] = useState()

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const cannedResponsesLearnMore = settingsLearnMoreUrl?.productivity?.cannedResponses?.learnMore

  const {isLoading} = useApiLoader({label: GET_TEMPLATE})
  const {workflowAndAutomationAccess, productivityPermissions} = useUserPermissions()
  const cannedResponsesAccess = workflowAndAutomationAccess?.cannedResponses
  const cannedResponsesLimitAccess = workflowAndAutomationAccess?.cannedResponsesLimit
  const manageCannedReponsesPermissions = productivityPermissions?.manage_canned_reponses

  const {data: responseList = [], callAction: callGetEmailTemplateAction} = useApiResponse({
    action: getEmailTemplate,
    enabled: cannedResponsesAccess,
    storePath: 'settings.getResponseList',
  })

  const isFeatureLocked = useMemo(() => {
    return !cannedResponsesAccess || (cannedResponsesLimitAccess && responseList.length >= +cannedResponsesLimitAccess)
  }, [cannedResponsesAccess, cannedResponsesLimitAccess, responseList.length])

  useEffect(() => {
    if (successLabels.includes(DELETE_TEMPLATE)) {
      callGetEmailTemplateAction()
    }
  }, [successLabels, callGetEmailTemplateAction])

  const RenderCannedResponseList = () => {
    return responseList.map((response, index) => (
      <VmoTableRow
        onClick={() =>
          history.push({
            pathname: `/settings/email-templates/${response.id}`,
            // state: {
            //   ...get(
            //     ["routeState"],
            //     JSON.parse(window.localStorage.getItem("sales"))
            //   ),
            //   emailTmptName: get(["subject"], response),
            // },
          })
        }
        key={response.id}
        className="tableLink"
      >
        <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
        <VmoTableCell>
          <VmoHeader as="h5">{startCase(get(['title'], response, '-'))}</VmoHeader>
        </VmoTableCell>
        <VmoTableCell>
          {get(['createdBy'], response, '-')}
          <br />
          {getFormattedDate(response.createdAt)}
        </VmoTableCell>
        <VmoTableCell>{get(['updatedBy'], response, '-')}</VmoTableCell>
        <VmoTableCell>{get(['sendCount'], response, '-')}</VmoTableCell>
        <VmoTableCell>{get(['openCount'], response, '-')}</VmoTableCell>
        <VmoTableCell>{get(['replyCount'], response, '-')}</VmoTableCell>
        <VmoTableCell>
          <VmoTooltip
            content={
              manageCannedReponsesPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: manageCannedReponsesPermissions})
            }
            size="mini"
            position="top center"
            trigger={
              <VmoButton
                icon
                onClick={e => {
                  e.stopPropagation()
                  if (manageCannedReponsesPermissions) {
                    setIdToEdit(response.id)
                    setDeleteModal(true)
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </VmoButton>
            }
          />
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  if (isLoading && cannedResponsesAccess) {
    return <PageLoader />
  }

  if (createCanResponse) {
    return <EmailTemplateForm setCreateCanResponse={setCreateCanResponse} />
  }

  // Note :- temporary we make "email templates" to "canned response"
  if (responseList.length === 0 || !cannedResponsesAccess) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          src={<SvgIcon path="settings/canned-response" />}
          Feature1="Template Creation"
          Feature2="Content Customization"
          Feature3="Subject Line and Body"
          list1="Design the layout to match your brand and needs."
          list2="Add placeholders to template for easy creation"
          list3="Add subject line and email body for template."
          addFirst={() => cannedResponsesAccess && manageCannedReponsesPermissions && setCreateCanResponse(true)}
          tooltip={!cannedResponsesAccess || !manageCannedReponsesPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: cannedResponsesAccess,
            roleAccess: manageCannedReponsesPermissions,
          })}
          // buttonText="Add Email Templates"
          buttonText="Add Canned Response"
          img={img}
          // header="Email Templates"
          // subHeader="Email templates are pre-designed email formats that can be reused for various purposes"
          header="Canned Response"
          subHeader="Canned Response are pre-designed email formats that can be reused for various purposes"
        />
      </div>
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/canned-response" />}
      // header="Email Templates"
      // subHeader="Email templates are pre-written set of reply templates frequently used for daily customers  interactions"
      header="Canned Response"
      subHeader="Create and insert pre-written responses to common inquiries and save your valuable time"
      learnMoreUrl={cannedResponsesLearnMore}
      headerButton={{
        onClick: () => !isFeatureLocked && manageCannedReponsesPermissions && setCreateCanResponse(true),
        content: 'Add',
      }}
      lockFeature={isFeatureLocked}
      lockRole={!manageCannedReponsesPermissions}
      table
      headerDivider
    >
      <VmoTable basic selectable>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Title</VmoTableHeaderCell>
            <VmoTableHeaderCell>Created By</VmoTableHeaderCell>
            <VmoTableHeaderCell>Updated By</VmoTableHeaderCell>
            <VmoTableHeaderCell>Send</VmoTableHeaderCell>
            <VmoTableHeaderCell>Open</VmoTableHeaderCell>
            <VmoTableHeaderCell>Reply</VmoTableHeaderCell>
            <VmoTableHeaderCell>Action</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>
          {isLoadingData && (type.includes(GET_TEMPLATE) || type.includes('GET_ALL_MAIL_INBOX')) ? (
            <VmoTablePlaceholder columns={5} />
          ) : (
            responseList && <RenderCannedResponseList />
          )}
        </VmoTableBody>
      </VmoTable>
      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToEdit}
          deleteAction={deleteEmailTemplate}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          type={DELETE_TEMPLATE}
        />
      )}
    </SettingLayout>
  )
}
export default EmailTemplate
