/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Ref} from 'semantic-ui-react'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import _ from 'lodash'
import {
  VmoButton,
  VmoCheckbox,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
} from 'vmo-library'

import {columnsSelectionCompany} from 'actions/company'
import {columnsSelectionContact} from 'actions/contacts'
import {columnsSelectionDeals} from 'actions/deals'
import {startCase} from 'utils/helper'
import {listOfColumsCompany, listOfColumsDeals, listOfColumsContact} from 'constants/variables'
import SvgIcon from './SvgIcon'

const CustomizeTableColumn = props => {
  const {isModalOpen, setIsModalOpen, identifier} = props
  const dispatch = useDispatch()
  const {getAllContactColumnsList} = useSelector(state => state.contacts)
  const {getAllCompanyColumnsList} = useSelector(state => state.companies)
  const {getAllDealsColumnsList} = useSelector(state => state.deals)

  const [columnList, setColumnList] = useState([])

  const [checked, setChecked] = useState([])

  const saveActiveCol = () => {
    // if (checked.length > 0) {
    const temp = columnList.filter(({column}) => checked.some(o2 => column === o2))
    const result = _.map(temp, 'column')

    if (identifier === 'contacts') {
      dispatch(columnsSelectionContact({columns: result}))
    }
    if (identifier === 'company') {
      dispatch(columnsSelectionCompany({columns: result}))
    }
    if (identifier === 'deals') {
      dispatch(columnsSelectionDeals({columns: result}))
    }
  }
  const setSelectedColumnName = columnsList => {
    const temp = columnList.filter(({column}) => columnsList.includes(column))
    const temp2 = columnList.filter(({column}) => !columnsList.includes(column))
    return identifier !== 'deals'
      ? _.unionBy([{id: 1, column: 'name'}, {id: 2, column: 'emails'}, ...temp, ...temp2], 'column')
      : _.unionBy([{id: 1, column: 'name'}, ...temp, ...temp2], 'column')
  }

  useEffect(() => {
    if (getAllContactColumnsList && identifier === 'contacts') {
      setChecked(getAllContactColumnsList.columns)
      const updatedColumn = setSelectedColumnName(getAllContactColumnsList.columns)
      setColumnList(updatedColumn)
    }
  }, [getAllContactColumnsList])

  useEffect(() => {
    if (getAllCompanyColumnsList && identifier === 'company') {
      setChecked(getAllCompanyColumnsList.columns)
      const updatedColumn = setSelectedColumnName(getAllCompanyColumnsList.columns)
      setColumnList(updatedColumn)
    }
  }, [getAllCompanyColumnsList])

  useEffect(() => {
    if (getAllDealsColumnsList && identifier === 'deals') {
      setChecked(getAllDealsColumnsList.columns)
      const updatedColumn = setSelectedColumnName(getAllDealsColumnsList.columns)
      setColumnList(updatedColumn)
    }
  }, [getAllDealsColumnsList])

  const handleCheck = value => {
    const isChecked = checked.some(lead => lead === value)
    if (isChecked) {
      const removeChecked = checked.filter(lead => lead !== value)
      setChecked(removeChecked)
    } else {
      setChecked([...checked, value])
    }
  }

  const isChecked = value => {
    const check = checked.find(lead => lead === value)
    if (check) {
      return true
    }
    return false
  }

  useEffect(() => {
    switch (identifier) {
      case 'contacts':
        return setColumnList(listOfColumsContact)
      case 'company':
        return setColumnList(listOfColumsCompany)
      case 'deals':
        return setColumnList(listOfColumsDeals)
      default:
        return columnList
    }
  }, [])

  const onDragEnd = result => {
    const {destination, source, draggableId} = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const quotes = Object.assign([], columnList)
    const quote = columnList[source.index]
    quotes.splice(source.index, 1)
    quotes.splice(destination.index, 0, quote)

    setColumnList(quotes)
    // this.setState({
    //   quotes: quotes,
    // });
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? 'rgb(30 144 255 / 24%)' : '',

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  return (
    <VmoModal
      size="tiny"
      open={isModalOpen}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <VmoModalHeader>Select Additional Columns to display in the list</VmoModalHeader>
      <VmoModalContent scrolling className="customize-col">
        <DragDropContext onDragEnd={onDragEnd}>
          <VmoTable className="vmo-table">
            <VmoTableHeader>
              <VmoTableRow>
                <VmoTableHeaderCell />
                <VmoTableHeaderCell>Column Headers</VmoTableHeaderCell>
                <VmoTableHeaderCell style={{width: '140px'}}>Active Columns</VmoTableHeaderCell>
              </VmoTableRow>
            </VmoTableHeader>
            <Droppable droppableId="tableBody">
              {(provided, snapshot) => (
                <Ref innerRef={provided.innerRef}>
                  <VmoTableBody {...provided.droppableProps}>
                    {(columnList || []).map((col, i) => (
                      <Draggable
                        isDragDisabled={(col.column === 'name' || col.column === 'email') && true}
                        draggableId={`${col.id}`}
                        index={i}
                        key={col.id}
                      >
                        {(provided, snapshot) => (
                          <Ref innerRef={provided.innerRef}>
                            <VmoTableRow
                              key={i}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              className="dragBtnHide"
                            >
                              <VmoTableCell>
                                <div className="DragBtnShow">
                                  <SvgIcon path="common/move" />
                                </div>
                              </VmoTableCell>
                              <VmoTableCell>{startCase(col.column)}</VmoTableCell>
                              {col.column === 'email' || col.column === 'name' ? (
                                <VmoTableCell>Required</VmoTableCell>
                              ) : (
                                <VmoTableCell>
                                  <VmoCheckbox
                                    label=""
                                    onChange={() => handleCheck(col.column)}
                                    checked={isChecked(col.column)}
                                  />
                                </VmoTableCell>
                              )}
                            </VmoTableRow>
                          </Ref>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </VmoTableBody>
                </Ref>
              )}
            </Droppable>
          </VmoTable>
        </DragDropContext>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </VmoButton>
        <VmoButton disabled={checked.length === 0} primary onClick={() => saveActiveCol()}>
          Save
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default CustomizeTableColumn
