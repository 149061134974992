import React from 'react'
import {SettingMenu} from 'navigation/sub-routes/Settings/SettingMenu'
import SettingRoutes from 'navigation/sub-routes/Settings/SettingRoutes'

function Settings() {
  return (
    <>
      <SettingMenu />
      <SettingRoutes />
    </>
  )
}

export default Settings
