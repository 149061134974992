import React, {useEffect, useState} from 'react'
import FullPageLoader from 'components/common/FullPageLoader'
import {getAppToken} from 'utils/local-storage'

const token = getAppToken()

const AppProvider = ({children}) => {
  const [isLoading, setIsLoading] = useState(Boolean(token))

  useEffect(() => {
    if (getAppToken()) {
      const timeout = setTimeout(() => {
        setIsLoading(false)
      }, 2000)

      return () => clearTimeout(timeout)
    }
  }, [])

  return isLoading ? <FullPageLoader /> : children
}

export default AppProvider
