import {
  INFLOW_BY_CHANNEL_VS_TIME,
  INFLOW_BY_DATE_ACROSS_TIME,
  OPEN_CONVERSATION_INBOX,
  OPEN_CONVERSATION_INBOX_PRIORITY,
  OPEN_CONVERSATION_INBOX_STATUS,
} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case OPEN_CONVERSATION_INBOX_STATUS:
      return {...state, openConversationInboxStatusData: payload}

    case OPEN_CONVERSATION_INBOX_PRIORITY:
      return {...state, openConversationInboxPriorityData: payload}

    case OPEN_CONVERSATION_INBOX:
      return {...state, openConversationInboxData: payload}

    case INFLOW_BY_CHANNEL_VS_TIME:
      return {...state, inflowByChannelVsTimeData: payload}

    case INFLOW_BY_DATE_ACROSS_TIME:
      return {...state, inflowByDateCrossTimeData: payload}

    default:
      return state
  }
}
