import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {
  VmoButton,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoHeader,
  VmoLabel,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
} from 'vmo-library'
import {COPY_QUOTE, DELETE_QUOTE, GET_QUOTE_LIST, PUBLISH_QUOTE} from 'actions/types'
import {copyQuote, deleteQuote, getQuoteList, publishQuote} from 'actions/quotes'
import DeleteModal from 'components/common/DeleteModal'
import {platformEndpoint} from 'actions/endpoint'
import create from 'assets/create.svg'
import CreateFirst from 'components/common/CreateFirst'
import {getFormattedDate, getFormattedTime, getPermissionTooltipContent, startCase} from 'utils/helper'
import KadenceCustomPagination from 'components/common/KadenceCustomPagination'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

function openQuote(url) {
  const win = window.open(`${platformEndpoint}/view/quote/${url}`, '_blank')
  win.focus()
}

function QuotesList() {
  const {push} = useHistory()
  const {url} = useRouteMatch()
  const dispatch = useDispatch()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()

  // pagination
  const [sort, setSort] = useState({
    page: 1,
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_QUOTE_LIST})
  const {salesAndCrmAccess, quotesPermissions} = useUserPermissions()
  const quotesAccess = salesAndCrmAccess?.quotes
  const manageQuotesPermissions = quotesPermissions?.manage_quotes

  const {
    data: quotesList,
    totalPages,
    currentPage,
    pageSize,
    totalRecords,
  } = useApiResponse({storePath: 'quotes.quoteList', dataToExtract: 'quoteDetails'})

  useEffect(() => {
    if (quotesAccess && manageQuotesPermissions) {
      dispatch(getQuoteList(sort))
    }
  }, [quotesAccess, manageQuotesPermissions, dispatch, sort])

  useEffect(() => {
    if (successLabels.includes(DELETE_QUOTE)) {
      setEditId()
      setDeleteModal(false)

      dispatch(getQuoteList({page: 1}))
    }

    if (successLabels.includes(COPY_QUOTE)) {
      dispatch(getQuoteList({page: 1}))
    }

    if (successLabels.includes(PUBLISH_QUOTE)) {
      dispatch(getQuoteList({page: 1}))
    }
  }, [successLabels])

  if (isLoading && quotesAccess && manageQuotesPermissions) {
    return <PageLoader />
  }

  if (quotesList.length < 1 || !quotesAccess || !manageQuotesPermissions) {
    return (
      <div style={{height: '100%'}}>
        <CreateFirst
          src={<SvgIcon path="settings/users" />}
          Feature1="Customization"
          Feature2="Signature"
          Feature3="Quote Versioning"
          list1="Customize quotes appearance to match your brand's style"
          list2="Enable recipients to sign and accept quotes electronically."
          list3="Maintain different versions as negotiations during the sales process."
          addFirst={() => quotesAccess && manageQuotesPermissions && push(`${url}/add`)}
          buttonText="Create Quote"
          tooltip={!quotesAccess || !manageQuotesPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: quotesAccess,
            roleAccess: manageQuotesPermissions,
          })}
          img={create}
          header="Quote"
          subHeader="Emphasize customers with professional sales quotes. Create and send quotations directly for smoother communication"
        />
      </div>
    )
  }

  return (
    <div className="pageBodyWrap">
      <div className="pageHeader px-0">
        <div className="pageTitle">
          <div className="pageTitleIcon">
            <SvgIcon path="navigation/quotes" />
            <h5>All Quote ({totalRecords})</h5>
          </div>
        </div>

        <div className="pageAction">
          <LockPermissionTooltip isRoleAccessDenied={!manageQuotesPermissions}>
            <VmoButton
              primary
              className={!manageQuotesPermissions ? 'disabled-button' : ''}
              onClick={() => manageQuotesPermissions && push(`${url}/add`)}
            >
              Create Quote
            </VmoButton>
          </LockPermissionTooltip>
        </div>
      </div>
      <VmoTable basic selectable className="m-0">
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Quote No</VmoTableHeaderCell>
            <VmoTableHeaderCell>Name</VmoTableHeaderCell>
            <VmoTableHeaderCell>Assigned Deal</VmoTableHeaderCell>
            <VmoTableHeaderCell>Assigned To</VmoTableHeaderCell>
            <VmoTableHeaderCell>Valid Till</VmoTableHeaderCell>
            <VmoTableHeaderCell>Value</VmoTableHeaderCell>
            <VmoTableHeaderCell>Status</VmoTableHeaderCell>
            <VmoTableHeaderCell>Actions</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>
          {quotesList.map((quote, index) => {
            const {id, deal, name, total, validTill, status, urlLink, yourInfo} = quote
            return (
              <VmoTableRow key={id} className="tableLink" onClick={() => push(`quote/${id}`)}>
                <VmoTableCell className="pl-4">{pageSize * (currentPage - 1) + index + 1}</VmoTableCell>
                <VmoTableCell className="pl-4">{startCase(quote.quoteNo)}</VmoTableCell>
                <VmoTableCell>
                  <VmoHeader as="h5"> {startCase(name)}</VmoHeader>
                </VmoTableCell>
                <VmoTableCell>{deal?.name || '-'}</VmoTableCell>
                <VmoTableCell>
                  {yourInfo && yourInfo.firstName} {yourInfo && yourInfo.lastName}
                </VmoTableCell>
                <VmoTableCell>
                  {validTill ? `${getFormattedDate(validTill)} ${getFormattedTime(validTill)}` : '-'}
                </VmoTableCell>
                <VmoTableCell>{total}</VmoTableCell>
                <VmoTableCell>
                  <VmoLabel className="kadence-label">{status}</VmoLabel>
                </VmoTableCell>
                <VmoTableCell>
                  <VmoDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
                    <VmoDropdownMenu>
                      {status === 'draft' ? (
                        <LockPermissionTooltip isRoleAccessDenied={!manageQuotesPermissions}>
                          <VmoDropdownItem onClick={() => manageQuotesPermissions && dispatch(publishQuote(id))}>
                            <SvgIcon path="common/publish" /> Publish
                          </VmoDropdownItem>
                        </LockPermissionTooltip>
                      ) : (
                        <VmoDropdownItem onClick={() => openQuote(urlLink)}>
                          <SvgIcon path="common/page-view" /> View Page
                        </VmoDropdownItem>
                      )}
                      <LockPermissionTooltip isRoleAccessDenied={!manageQuotesPermissions}>
                        <VmoDropdownItem onClick={() => manageQuotesPermissions && dispatch(copyQuote(id))}>
                          <SvgIcon path="common/clone" /> Clone
                        </VmoDropdownItem>
                      </LockPermissionTooltip>
                      <LockPermissionTooltip isRoleAccessDenied={!manageQuotesPermissions}>
                        <VmoDropdownItem
                          onClick={() => {
                            if (manageQuotesPermissions) {
                              setEditId(id)
                              setDeleteModal(true)
                            }
                          }}
                        >
                          <SvgIcon path="common/delete" /> Delete
                        </VmoDropdownItem>
                      </LockPermissionTooltip>
                    </VmoDropdownMenu>
                  </VmoDropdown>
                </VmoTableCell>
              </VmoTableRow>
            )
          })}
        </VmoTableBody>
      </VmoTable>

      {totalPages > 1 && (
        <KadenceCustomPagination
          totalPages={totalPages}
          activePage={currentPage}
          onPageChange={page => {
            setSort(sort => ({...sort, page}))
          }}
        />
      )}

      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          deleteAction={deleteQuote}
          idTobeDeleted={editId}
          type={DELETE_QUOTE}
        />
      )}
    </div>
  )
}

export default QuotesList
