import React from 'react'
import {Controller} from 'react-hook-form'
import _ from 'lodash'
import {VmoButton, VmoDropdown, VmoFormField, VmoFormInput, VmoIcon, VmoTooltip} from 'vmo-library'
import SvgIcon from './SvgIcon'

const WebsiteCreateMultiple = props => {
  const {websiteFields, errors, appendWebsite, control, removeWebsite} = props
  return websiteFields.map((websiteItem, index) => (
    <div className="mutiple">
      <VmoFormField key={index} className={index !== 0 && 'mt-3'}>
        <div className="hover-plusicon d-flex">
          <Controller
            name={`websites[${index}].category`}
            defaultValue={websiteItem.category}
            render={({onChange, value, onBlur}) => (
              <div className="mailActivity">
                <VmoDropdown
                  className="email-component"
                  icon="chevron down"
                  button
                  options={[
                    {value: 'work', text: 'Work Website', key: 'work'},
                    {
                      value: 'personal',
                      text: 'Personal Website',
                      key: 'personal',
                    },
                    {value: 'other', text: 'Other Website', key: 'other'},
                  ]}
                  onBlur={onBlur}
                  placeholder="Select category"
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  error={
                    _.get(errors, `websites[${index}].category`) && {
                      content: _.get(errors, `websites[${index}].category.message`),
                    }
                  }
                />
              </div>
            )}
            control={control}
          />
          <div className="d-flexb">
            {_.size(websiteFields) > 1 && (
              <div className="ShowAddBtn">
                <VmoButton
                  style={{padding: '6px'}}
                  onClick={() => {
                    removeWebsite(index)
                  }}
                >
                  <VmoTooltip
                    content="Delete Field"
                    size="mini"
                    position="left center"
                    trigger={<VmoIcon className="m-0" name="trash alternate outline" />}
                  />
                </VmoButton>
              </div>
            )}
            {index === websiteFields.length - 1 && _.size(websiteFields) < 3 && (
              <div className="ShowAddBtn">
                <VmoButton
                  style={{padding: '4px'}}
                  onClick={() => {
                    appendWebsite({url: '', category: 'work'})
                  }}
                >
                  <VmoTooltip
                    content="Add Website"
                    size="mini"
                    position="left center"
                    trigger={<SvgIcon path="common/plus" />}
                  />
                </VmoButton>
              </div>
            )}
          </div>
        </div>
        <Controller
          name={`websites[${index}].url`}
          defaultValue={websiteItem.url}
          render={({onChange, value}) => (
            <VmoFormInput
              type="text"
              maxLength={200}
              fluid
              value={value}
              placeholder="Website URL"
              onChange={e => {
                onChange(e.target.value)
              }}
              icon={
                <i style={{padding: '12px'}} className="ShowAddBtn link icon">
                  <SvgIcon path="common/edit2" />
                </i>
              }
              error={
                _.get(errors, `websites[${index}].url`) && {
                  content: 'URL is invalid',
                }
              }
            />
          )}
          control={control}
        />
      </VmoFormField>
    </div>
  ))
}

export default WebsiteCreateMultiple
