import {apiAction} from './api'
import {KADENCE_PROJECTS} from './endpoint'
import {
  GET_PROJECTS_PLANNING_OVERVIEW_STATS_BY_USER,
  GET_PROJECTS_PLANNING_OVERVIEW_TIMEWISE_STATS,
  GET_PROJECTS_PLANNING_WORKLOADS_LIST,
  UPDATE_PROJECTS_TASKS_PROGRESS,
} from './types'

export function getProjectsPlanningOverviewTimewiseStats(params) {
  const keys = Object.keys(params)
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${KADENCE_PROJECTS}/planning/overview/timewise-stats?${query}`,
    method: 'GET',
    label: GET_PROJECTS_PLANNING_OVERVIEW_TIMEWISE_STATS,
  })
}

export function getProjectsPlanningOverviewStatsByUser(data) {
  return apiAction({
    url: `${KADENCE_PROJECTS}/planning/overview/stats-by-user`,
    method: 'GET',
    label: GET_PROJECTS_PLANNING_OVERVIEW_STATS_BY_USER,
    data,
  })
}

export function getProjectsPlanningWorkloadsList() {
  return apiAction({
    url: `${KADENCE_PROJECTS}/planning/workloads`,
    method: 'GET',
    label: GET_PROJECTS_PLANNING_WORKLOADS_LIST,
  })
}

export function updateProjectsTasksProgress(projectId, taskId, data) {
  return apiAction({
    url: `${KADENCE_PROJECTS}/tasks/${projectId}/${taskId}/progress`,
    method: 'PATCH',
    label: UPDATE_PROJECTS_TASKS_PROGRESS,
    data,
    showToast: true,
    successMessage: 'Task Progress Updated',
  })
}
