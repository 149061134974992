import {apiAction} from './api'
import {KADENCE_ANALYTICS} from './endpoint'
import {
  INFLOW_BY_CHANNEL_VS_TIME,
  INFLOW_BY_DATE_ACROSS_TIME,
  OPEN_CONVERSATION_INBOX,
  OPEN_CONVERSATION_INBOX_PRIORITY,
  OPEN_CONVERSATION_INBOX_STATUS,
} from './types'

export function openConversationsByInboxStatus(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/unresolvedconversationsbystatus`,
    method: 'POST',
    label: OPEN_CONVERSATION_INBOX_STATUS,
    data,
  })
}

export function openConversationsByInboxPriority(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/unresolvedconversationsbypriorities`,
    method: 'POST',
    label: OPEN_CONVERSATION_INBOX_PRIORITY,
    data,
  })
}

export function openConversationsByInbox(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/unresolvedconversationsbyinbox`,
    method: 'POST',
    label: OPEN_CONVERSATION_INBOX,
    data,
  })
}

export function inflowByChannelVsTime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/inflowbychannelversustime`,
    method: 'POST',
    label: INFLOW_BY_CHANNEL_VS_TIME,
    data,
  })
}

export function inflowByDateCrossTime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/inflowbydatecrosstime`,
    method: 'POST',
    label: INFLOW_BY_DATE_ACROSS_TIME,
    data,
  })
}
