import {KADENCE_ANALYTICS} from './endpoint'
import {apiAction} from './api'
import {BREAK_DOWN_BY_KPI, FORECAST_SUMMARY, GOAL_KPI_BREAK_DOWN} from './types'

export function breakDownByKpi(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/forecasts/kpiDetail`,
    method: 'POST',
    label: BREAK_DOWN_BY_KPI,
    data,
  })
}
export function goalKpiBreakdown(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/forecasts/goalAchived`,
    method: 'POST',
    label: GOAL_KPI_BREAK_DOWN,
    data,
  })
}
export function forecastSummary(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/forecasts/forecastSummary`,
    method: 'POST',
    label: FORECAST_SUMMARY,
    data,
  })
}
