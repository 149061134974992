const accEndpoint = process.env.REACT_APP_ACCOUNT_ENDPOINT
export const platformEndpoint = process.env.REACT_APP_PLATFORM_ENDPOINT
const socket = process.env.REACT_APP_SOCKET
const docs = process.env.REACT_APP_DOCS_ENDPOINT

export const USER_PLATFORM_V1 = `${accEndpoint}/v1/accounts`

export const KADENCE = `${platformEndpoint}/v1`

export const KADENCE_ANALYTICS = `${KADENCE}/analytics`

export const USER_ACCOUNTS = USER_PLATFORM_V1
export const USER_VERIFICATIONS = `${USER_PLATFORM_V1}/verifications`

export const KADENCE_ACCOUNT_V1 = `${platformEndpoint}/v1/kadence`
export const USER_USERS = `${USER_PLATFORM_V1}/users`
export const KADENCE_MEETINGS = `${platformEndpoint}/v1/meetings`

export const KADENCE_VENDORS = `${KADENCE_ACCOUNT_V1}/finance/vendors`
export const KADENCE_PURCHASES = `${KADENCE_ACCOUNT_V1}/finance/purchases`
export const KADENCE_PURCHASE_ORDERS = `${KADENCE_ACCOUNT_V1}/finance/purchase-orders`
export const KADENCE_SALES_ORDERS = `${KADENCE_ACCOUNT_V1}/finance/sales-orders`
export const KADENCE_INVOICES = `${KADENCE_ACCOUNT_V1}/finance/invoices`
export const KADENCE_BUDGET = `${KADENCE_ACCOUNT_V1}/finance/budget`

export const KADENCE_CONTACTS = `${KADENCE_ACCOUNT_V1}/customers/contacts`
export const KADENCE_COMPANY = `${KADENCE_ACCOUNT_V1}/customers/companies`
export const KADENCE_CALENDAR = `${KADENCE_ACCOUNT_V1}/customers/calendar`
export const KADENCE_CLIENT_PORTAL = `${KADENCE_ACCOUNT_V1}/customers/client_portal`

export const KADENCE_PRODUCTS = `${KADENCE_ACCOUNT_V1}/sales/products`
export const KADENCE_DEALS = `${KADENCE_ACCOUNT_V1}/sales/deals`
export const KADENCE_QUOTES = `${KADENCE_ACCOUNT_V1}/sales/quotes`

export const KADENCE_EMPLOYEES = `${KADENCE_ACCOUNT_V1}/human_resources/employees`
export const KADENCE_EXPENSES = `${KADENCE_ACCOUNT_V1}/human_resources/expenses`
export const KADENCE_TIMEOFF = `${KADENCE_ACCOUNT_V1}/human_resources/timeoffs`
export const KADENCE_ANNOUNCEMENT = `${KADENCE_ACCOUNT_V1}/human_resources/announcements`
export const KADENCE_APPRAISAL = `${KADENCE_ACCOUNT_V1}/human_resources/appraisals`

export const KADENCE_INBOXES = `${KADENCE_ACCOUNT_V1}/inboxes`
export const KADENCE_CONVERSATIONS = `${KADENCE_ACCOUNT_V1}/conversations`
export const KADENCE_TASK = `${KADENCE_ACCOUNT_V1}/tasks`
export const KADENCE_MAILBOX = `${KADENCE_ACCOUNT_V1}/mailboxes`
export const KADENCE_EMAIL_INTEGRATION = `${KADENCE_ACCOUNT_V1}/email-integration`

export const KADENCE_SETTING = `${KADENCE_ACCOUNT_V1}/settings`
export const KADENCE_ROLE = `${USER_PLATFORM_V1}`

export const KADENCE_CHANNEL = `${platformEndpoint}/channels`
export const KADENCE_CHANNEL_V1 = `${platformEndpoint}/v1/channels`

export const FORM = `${platformEndpoint}/v1/forms`

export const DOCS = `${docs}/v1/docs`
export const DOCS_ANALYTICS = `${DOCS}/analytics`

export const KADENCE_ATTACHMENTS = `${KADENCE_ACCOUNT_V1}/attachments`
export const KADENCE_PROJECTS = `${KADENCE}/projects`

export const KADENCE_EMPLOYEE = `${KADENCE}/employee`

export const SOCKET = socket
