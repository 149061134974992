import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiList} from 'react-icons/fi'
import {
  VmoButton,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTabPane,
  VmoTooltip,
} from 'vmo-library'
import {DELETE_EXPENSES_CATEGORY, GET_EXPENSES_CATEGORY} from 'actions/types'
import {deleteExpensesCategory, getExpensesCategory} from 'actions/expenses'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create.svg'
import {startCase} from 'utils/helper'
import ExpenseSidemenu from 'navigation/sub-routes/Hr/Expenses/ExpenseMenu'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import SvgIcon from 'components/common/SvgIcon'
import ExpensesCategoryModal from './CategoryModal'

const Category = () => {
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)
  const [categoryModal, setCategoryModal] = useState(false)
  const [categoryId, setCategoryId] = useState()

  const {getExpensesCategoryData} = useSelector(state => state.expenses)
  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_EXPENSES_CATEGORY})

  useEffect(() => {
    dispatch(getExpensesCategory())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(DELETE_EXPENSES_CATEGORY)) {
      dispatch(getExpensesCategory())
    }
  }, [dispatch, successLabels])

  const categoryProps = {
    open: categoryModal,
    toggleModal: setCategoryModal,
    categoryToggle: setCategoryId,
  }
  if (categoryId) {
    categoryProps.editId = categoryId
  }

  const actionButton = () => {
    return getExpensesCategoryData && !getExpensesCategoryData.length
      ? null
      : {
          success: {
            content: 'Add Category',
            onClick: () => {
              setCategoryId()
              setCategoryModal(true)
            },
          },
        }
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <ExpenseSidemenu />

      <SettingLayout
        header="Category Fields"
        subHeader="Manage your category fields to organise your expenses."
        table
        actionButton={actionButton()}
        headerDivider
      >
        {getExpensesCategoryData && !getExpensesCategoryData.length ? (
          <div className="mt-4">
            {categoryModal ? <ExpensesCategoryModal {...categoryProps} /> : null}
            <CreateFirst
              src={<FiList />}
              header="Category"
              subHeader="Diversifying assets helps reduce the chance of loss of capital. Investment in debt instruments assures fixed return and safety of capital, having this as part of your portfolio ensures capital preservation."
              addFirst={() => {
                setCategoryId()
                setCategoryModal(true)
              }}
              buttonText="Add Category"
              Feature1="Organize your assets better"
              Feature2="Keep track and reporting according to the category"
              Feature3="Assets prevention is better"
              list1="Sorting assets according to their properties help organize it better and helps in tracking if number of assets are more"
              list2="Veemo support features detailed analytics with respect to the properties of the assets so it helps the company in making decision based on statistics"
              list3="Managing assets in veemo support will help in case of asset theft or missing asset situations and hence is more reliable"
              imageSrc={create}
            />
          </div>
        ) : (
          <VmoTabPane attached={false}>
            {deleteModal && (
              <DeleteModal
                idTobeDeleted={categoryId}
                deleteAction={deleteExpensesCategory}
                isModalOpen={deleteModal}
                setIsModalOpen={setDeleteModal}
                modalText="Are you sure you want to delete this Category?"
                type={DELETE_EXPENSES_CATEGORY}
              />
            )}
            {categoryModal ? <ExpensesCategoryModal {...categoryProps} /> : null}
            <VmoTable basic selectable>
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Category name</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Description</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Created by</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Action</VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <VmoTableBody>
                {isLoadingData && type.includes(GET_EXPENSES_CATEGORY) ? (
                  <VmoTablePlaceholder columns={3} rows={3} />
                ) : (
                  getExpensesCategoryData &&
                  getExpensesCategoryData.map(({category, description, createdBy, id}, index) => (
                    <VmoTableRow
                      className="tableLink"
                      onClick={() => {
                        setCategoryModal(true)
                        setCategoryId(id)
                      }}
                      key={id}
                    >
                      <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
                      <VmoTableCell>
                        <VmoHeader as="h5">{startCase(category)}</VmoHeader>
                      </VmoTableCell>
                      <VmoTableCell>{description}</VmoTableCell>
                      <VmoTableCell>{startCase(createdBy?.name)}</VmoTableCell>
                      <VmoTableCell>
                        <VmoTooltip
                          content="Delete"
                          size="mini"
                          position="top center"
                          trigger={
                            <VmoButton
                              icon
                              onClick={e => {
                                setCategoryId(id)
                                setDeleteModal(true)
                                e.stopPropagation()
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </VmoButton>
                          }
                        />
                      </VmoTableCell>
                    </VmoTableRow>
                  ))
                )}
              </VmoTableBody>
            </VmoTable>
          </VmoTabPane>
        )}
      </SettingLayout>
    </>
  )
}

export default Category
