import {
  GET_CHANNEL,
  GET_CHANNELS_ALL,
  GET_FEEDBACK_FORM,
  GET_PHONE_NUMBER,
  GET_SHARED_SECRET_KEY,
  GET_SMS_CHANNEL_LIST,
  GET_SMS_SINGLE,
  GET_TWITTER_CODE,
  SINGLE_FEEDBACK_FORM,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_CHANNELS_ALL:
      return {
        ...state,
        getAllChannelList: action.payload,
      }

    case GET_TWITTER_CODE:
      return {
        ...state,
        twitterCodeData: action.payload,
      }

    case GET_CHANNEL:
      return {
        ...state,
        getChannelData: action.payload,
      }

    // SMS Channel Start
    case GET_SMS_CHANNEL_LIST:
      return {
        ...state,
        getSmsChannelList: action.payload,
      }

    case GET_PHONE_NUMBER:
      return {
        ...state,
        getPhoneNumberData: action.payload,
      }

    case GET_SMS_SINGLE:
      return {
        ...state,
        getSmsChannel: action.payload,
      }

    case GET_SHARED_SECRET_KEY:
      return {
        ...state,
        getSharedKeyData: action.payload,
      }
    // SMS Channel End

    // Contact Form Start
    case GET_FEEDBACK_FORM:
      return {
        ...state,
        feedbackFormList: action.payload,
      }

    case SINGLE_FEEDBACK_FORM:
      return {
        ...state,
        feedbackForm: action.payload,
      }
    // Contact Form End

    default:
      return state
  }
}
