import {KADENCE_SETTING} from './endpoint'
import {apiAction} from './api'
import {
  UPDATE_TIME_TRACKING,
  GET_TIME_TRACKING,
  GET_TIME_TRACKING_CONVERSATION,
  UPDATE_TIME_TRACKING_ENTRY,
  SUBMIT_TIME_TRACKING_AUTOMATIC,
  SUBMIT_TIME_TRACKING_MANUAL,
  GET_TIME_TRACKING_ENTRY_STATUS,
  GET_ENTERIES_CONVERSATION,
  DELETE_ENTRIES,
  MANUAL_TASK_ENTERIES,
  GET_TIME_TRACKING_ENTRY_STATUS_TASK,
  GET_ENTERIES_TASK,
  DELETE_ENTERIES_TASK,
  UPDATE_ENTERIES_TASK,
} from './types'

export function updateTimeTrackingSetting(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking`,
    method: 'POST',
    label: UPDATE_TIME_TRACKING,
    data,
    showToast: true,
    successMessage: 'Settings Updated',
  })
}

export function getTimeTrackingSetting() {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking`,
    method: 'GET',
    label: GET_TIME_TRACKING,
  })
}

export function getTimeTrackingConversation(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking/conversation/${id}`,
    method: 'GET',
    label: GET_TIME_TRACKING_CONVERSATION,
  })
}

export function updateTimeEntry(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking/entry`,
    method: 'PUT',
    data,
    label: UPDATE_TIME_TRACKING_ENTRY,
    showToast: true,
    successMessage: 'Entry updated',
  })
}

export function submitTimeTrackingManual(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking/manual`,
    method: 'POST',
    data,
    label: SUBMIT_TIME_TRACKING_MANUAL,
    successMessage: 'Entry submitted',
    showToast: data.status === 'completed' && data.duration > 0,
  })
}

export function submitTimeTrackingAutomatic(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking/automatic`,
    method: 'POST',
    data,
    label: SUBMIT_TIME_TRACKING_AUTOMATIC,
    successMessage: 'Entry submitted',
    showToast: true,
  })
}

export function getTimeTrackingStatus(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking/status/conversation/${id}`,
    method: 'GET',
    label: GET_TIME_TRACKING_ENTRY_STATUS,
  })
}

export function getTimeTrackingStatusTask(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking/status/task/${id}`,
    method: 'GET',
    label: GET_TIME_TRACKING_ENTRY_STATUS_TASK,
  })
}
export function getEnteriesByCoversation(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking/conversation/${id}`,
    method: 'GET',
    label: GET_ENTERIES_CONVERSATION,
  })
}

export function deleteTimeTrackingEntry(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking/entry/${id}`,
    method: 'DELETE',
    label: DELETE_ENTRIES,
    showToast: true,
    successMessage: 'Entry Deleted',
  })
}

export function submitTimeTrackingManualTask(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking/task/manual`,
    method: 'POST',
    label: MANUAL_TASK_ENTERIES,
    data,
    showToast: data.status === 'completed',
    successMessage: 'Entry added',
  })
}

export function getEnteriesByTask(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking/task/${id}/entries`,
    method: 'GET',
    label: GET_ENTERIES_TASK,
  })
}

export function deleteManualEnteriesByTask(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking/task/entry/${id}`,
    method: 'DELETE',
    label: DELETE_ENTERIES_TASK,
  })
}

export function updateManualEnteriesByTask(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/time-tracking/task/entry/${id}`,
    method: 'PUT',
    data,
    label: UPDATE_ENTERIES_TASK,
  })
}
