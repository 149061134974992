import React from 'react'
import Description from './Description'
import Header from './Header'
import NumberBox from './NumberBox'
import Rating from './rating'
import SubHeader from './SubHeader'
import TextArea from './TextArea'
import TextBox from './TextBox'

function AllSection({
  type,
  data,
  index,
  control,
  errors,
  watch,
  setValue,
  toggleModal,
  setToggleModal,
  activeSection,
  reset,
}) {
  switch (type) {
    case 'header':
      return (
        <Header
          type={type}
          key={data.id}
          data={watch(`templateSections[${activeSection}].sectionFields[${index}]`)}
          control={control}
          index={index}
          errors={errors}
          watch={watch}
          setValue={setValue}
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          activeSection={activeSection}
        />
      )
    case 'sub_header':
      return (
        <SubHeader
          type={type}
          key={data.id}
          data={watch(`templateSections[${activeSection}].sectionFields[${index}]`)}
          control={control}
          index={index}
          errors={errors}
          watch={watch}
          setValue={setValue}
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          activeSection={activeSection}
        />
      )
    case 'description':
      return (
        <Description
          type={type}
          key={data.id}
          data={watch(`templateSections[${activeSection}].sectionFields[${index}]`)}
          control={control}
          index={index}
          errors={errors}
          watch={watch}
          setValue={setValue}
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          activeSection={activeSection}
        />
      )
    case 'number_box':
      return (
        <NumberBox
          type={type}
          key={data.id}
          data={watch(`templateSections[${activeSection}].sectionFields[${index}]`)}
          control={control}
          index={index}
          errors={errors}
          watch={watch}
          setValue={setValue}
          reset={reset}
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          activeSection={activeSection}
        />
      )
    case 'text_box':
      return (
        <TextBox
          type={type}
          key={data.id}
          data={watch(`templateSections[${activeSection}].sectionFields[${index}]`)}
          control={control}
          index={index}
          errors={errors}
          watch={watch}
          setValue={setValue}
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          activeSection={activeSection}
        />
      )
    case 'text_area':
      return (
        <TextArea
          type={type}
          key={data.id}
          data={watch(`templateSections[${activeSection}].sectionFields[${index}]`)}
          control={control}
          index={index}
          errors={errors}
          watch={watch}
          setValue={setValue}
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          activeSection={activeSection}
        />
      )
    case 'rating':
      return (
        <Rating
          type={type}
          key={data.id}
          data={watch(`templateSections[${activeSection}].sectionFields[${index}]`)}
          control={control}
          index={index}
          errors={errors}
          watch={watch}
          setValue={setValue}
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          activeSection={activeSection}
        />
      )
    default:
      return null
  }
}

export default AllSection
