import {
  CREATE_INVOICES,
  GET_INVOICES_DATA,
  GET_INVOICES_LIST,
  GET_INVOICES_PREFIX,
  GET_NEW_INVOICES_NUMBER,
  UPDATE_INVOICES,
} from 'actions/types'

export default function (
  state = {InvoicesForm: {}, invoicesData: {}, uploadFile: {selectedFile: null, previewFile: null}},
  action
) {
  switch (action.type) {
    case GET_INVOICES_PREFIX:
      return {
        ...state,
        invoicesPrefixList: action.payload,
      }

    case GET_NEW_INVOICES_NUMBER:
      return {
        ...state,
        InvoicesForm: {
          ...state.PurchaseOrderForm,
          invoicesNumber: action.payload,
        },
      }

    case CREATE_INVOICES:
      return {
        ...state,
        invoicePublicLink: action.payload,
      }

    case UPDATE_INVOICES:
      return {
        ...state,
        invoicePublicLink: action.payload,
      }

    case GET_INVOICES_LIST:
      return {
        ...state,
        invoicesList: action.payload.invoiceDetail,
        invoicesPage: action.payload.page,
      }

    case GET_INVOICES_DATA:
      return {
        ...state,
        invoicesData: action.payload,
        productsData: action.payload.products,
      }

    case 'CLEAR_INVOICE_FORM':
      return {
        ...state,
        InvoicesForm: {},
      }

    case 'CLEAR_INVOICE_DATA':
      return {
        ...state,
        invoicesData: {},
      }

    case 'SETUP_SUBMIT':
      return {
        ...state,
        InvoicesForm: {
          ...state.InvoicesForm,
          setupData: action.payload,
        },
      }

    case 'PRODUCT_HANDLE_SUBMIT':
      return {
        ...state,
        InvoicesForm: {
          ...state.InvoicesForm,
          products: action.payload,
        },
      }

    case 'CUSTOMIZATION_SUBMIT':
      return {
        ...state,
        InvoicesForm: {
          ...state.InvoicesForm,
          template: action.payload,
        },
      }

    // * file/logo upload and views
    case 'SET_INVOICE_PREVIEW_FILE':
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          previewFile: action.payload,
        },
      }

    case 'SET_INVOICE_SELECTED_FILE': {
      // let prevFile = action.payload.get('logo')
      // const objectUrl = URL.createObjectURL(prevFile);

      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          selectedFile: typeof action.payload === 'string' ? 'uploaded logo' : action.payload.get('logo'),
        },
      }
    }

    case 'CLEAR_INVOICE_UPLOADFILE':
      return {
        ...state,
        uploadFile: {selectedFile: null, previewFile: null},
      }

    default:
      return state
  }
}
