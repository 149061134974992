/* eslint-disable no-nested-ternary */
import {GET_ALL_EMPLOYEES} from 'actions/types'
import React from 'react'
import {useSelector} from 'react-redux'
import {getFormattedDate} from 'utils/helper'
import {
  VmoLabel,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
} from 'vmo-library'

function AppraisalList() {
  const {myAppraisalData} = useSelector(state => state.employees)
  const {isLoadingData, type} = useSelector(state => state.apiReducer)

  const renderTableBody = () => {
    return myAppraisalData.map(({id, templateDetails, createdAt, status, reviewer, isPrivate}, index) => (
      <VmoTableRow key={id} className="tableLink">
        <VmoTableCell>{index + 1}</VmoTableCell>
        <VmoTableCell>{templateDetails.name}</VmoTableCell>
        <VmoTableCell>{getFormattedDate(createdAt)}</VmoTableCell>
        <VmoTableCell>
          <VmoLabel className="tag-label">{status.replace(/_/g, ' ')}</VmoLabel>
        </VmoTableCell>
        <VmoTableCell>{reviewer}</VmoTableCell>
        <VmoTableCell />
        <VmoTableCell />
        <VmoTableCell>{isPrivate === true ? 'Yes' : 'No'}</VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <>
      <VmoTable sortable basic selectable>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Template Name</VmoTableHeaderCell>
            <VmoTableHeaderCell>Appraisal Date</VmoTableHeaderCell>
            <VmoTableHeaderCell>Status</VmoTableHeaderCell>
            <VmoTableHeaderCell>Reviewer</VmoTableHeaderCell>
            <VmoTableHeaderCell>Points</VmoTableHeaderCell>
            <VmoTableHeaderCell>Weightage</VmoTableHeaderCell>
            <VmoTableHeaderCell>Private</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>
          {isLoadingData && type.includes(GET_ALL_EMPLOYEES) ? (
            <VmoTablePlaceholder columns={8} />
          ) : (myAppraisalData || []).length > 0 ? (
            renderTableBody()
          ) : (
            <tr>
              <td colSpan={10} className="emptyValue">
                No Data Available
              </td>
            </tr>
          )}
        </VmoTableBody>
      </VmoTable>
    </>
  )
}

export default AppraisalList
