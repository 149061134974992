import Joi from 'joi'

export const routingSchema = Joi.object({
  name: Joi.string().trim().min(4).max(30).required().messages({
    'string.min': `Length must be at least 4 characters`,
    'string.max': `Length must be less than or equal to 30 characters`,
  }),
  rule: Joi.string().label('routing rule').required(),
  inboxId: Joi.number().required().label('inbox'),
  assignLimit: Joi.number().min(1).max(500).integer().allow(null).label('assignee limit'),
})
