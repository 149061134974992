import React, {useEffect, useState} from 'react'
import {
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
} from 'vmo-library'
import {useSelector} from 'react-redux'
import {getProjectFinanceRatesList} from 'actions/projects'
import {GET_PROJECT_FINANCE_RATES_LIST, UPDATE_PROJECT_FINANCE_SINGLE_RATE} from 'actions/types'
import {getFormattedDate} from 'utils/helper'
import useApiResponse from 'hooks/impure/useApiResponse'
import ProjectRateModal from './ProjectRateModal'

const ProjectRates = ({projectId, budgetId}) => {
  const [isProjectRateModalOpen, setIsProjectRateModalOpen] = useState(false)
  const [idToUpdateRate, setIdToUpdateRate] = useState(null)

  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)

  const {data: ratesList = [], callAction} = useApiResponse({
    action: getProjectFinanceRatesList,
    payload: {projectId, budgetId},
    dependency: [projectId, budgetId],
    enabled: projectId && budgetId,
    storePath: 'projects.ratesList',
  })

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECT_FINANCE_SINGLE_RATE)) {
      setIsProjectRateModalOpen(false)
      callAction()
    }
  }, [successLabels, callAction])

  const renderTableBody = () => {
    if (!type.includes(GET_PROJECT_FINANCE_RATES_LIST) && ratesList.length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="4" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return ratesList.map(rate => (
      <VmoTableRow
        className="tableLink"
        key={rate.id}
        onClick={() => {
          setIdToUpdateRate(rate.id)
          setIsProjectRateModalOpen(true)
        }}
      >
        <VmoTableCell>
          <VmoHeader as="h5">{rate.user?.name}</VmoHeader>
        </VmoTableCell>
        <VmoTableCell>{rate.billableRate ? `$${rate.billableRate}` : '-'}</VmoTableCell>
        <VmoTableCell>{rate.costRate ? `$${rate.costRate}` : '-'}</VmoTableCell>
        <VmoTableCell>
          <div>{rate.updatedBy?.name}</div>
          <div>{rate.updatedAt ? getFormattedDate(rate.updatedAt) : '-'}</div>
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="kadence-side-wrap">
      <VmoTable basic>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>Name</VmoTableHeaderCell>
            <VmoTableHeaderCell>Billable Rate/H</VmoTableHeaderCell>
            <VmoTableHeaderCell>Cost Rate/H</VmoTableHeaderCell>
            <VmoTableHeaderCell>Last Updated</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>{renderTableBody()}</VmoTableBody>
      </VmoTable>

      {isProjectRateModalOpen && (
        <ProjectRateModal
          open={isProjectRateModalOpen}
          toggle={setIsProjectRateModalOpen}
          projectId={projectId}
          budgetId={budgetId}
          userId={idToUpdateRate}
        />
      )}
    </div>
  )
}

export default ProjectRates
