/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {Controller, useForm} from 'react-hook-form'
import {FiBriefcase} from 'react-icons/fi'
import {MdFormatQuote, MdPhone} from 'react-icons/md'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import _ from 'lodash'
import Axios from 'axios'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCarouselDotGroup,
  VmoCarouselProvider,
  VmoCarouselSlide,
  VmoCarouselSlider,
  VmoContainer,
  VmoFlag,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoGrid,
  VmoGridColumn,
  VmoHeader,
  VmoHeaderContent,
  VmoHeaderSubheader,
  VmoImage,
  VmoList,
  VmoListItem,
} from 'vmo-library'
import {removeDoubleQuotes} from 'utils/helper'
import {countryCodeList} from 'constants/variables'
import {createUserAccount} from 'actions'
import {CREATE_USER_ACCOUNT} from 'actions/types'
import logo from 'assets/omni24-full-logo.png'
import amazon1 from 'assets/logo-mokup1.png'
import amazon2 from 'assets/logo-mokup2.png'
import amazon3 from 'assets/logo-mokup3.png'
import amazon4 from 'assets/logo-mokup4.png'
import amazon5 from 'assets/logo-mokup5.png'
import KadenceCustomDropdown from 'components/common/KadenceCustomDropdown'
import user from 'assets/user.jpg'
import FullPageLoader from 'components/common/FullPageLoader'
import {signupSchema} from 'validation/signup.schema'
import SvgIcon from 'components/common/SvgIcon'

const carouselSlideLength = 3

function SignUp() {
  const url = new URL(window.location.href)
  const queryObj = Object.fromEntries(new URLSearchParams(url.search))

  const [countryCodeUpdated, setCountryCodeUpdated] = useState([])
  const [showPwd, setShowPwd] = useState(false)
  const [phoneCodeAxios, setPhoneCodeAxios] = useState('+91')
  const [source, setSource] = useState(null)

  const dispatch = useDispatch()
  const {signupData = {}} = useSelector(state => state.createUserAcct)
  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {handleSubmit, errors, control, trigger, setValue, watch, reset} = useForm({
    mode: 'onTouched',
    shouldFocusError: false,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(signupSchema),
    defaultValues: {
      ..._.pickBy(queryObj, ['email', 'phone', 'firstName', 'lastName', 'domain']),
      countryCode: '+91',
    },
  })

  const company = watch('company')
  const domain = watch('domain')
  const countryCode = watch('countryCode')

  const onSignup = data => {
    let payload = {
      email: data.email,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
      companyName: data.company,
      phoneCode: data.countryCode,
      phone: data.phone,
      domain: data.domain,
    }
    if (source) {
      payload = {...payload, ...source}
    }
    dispatch(createUserAccount(payload))
  }

  useEffect(() => {
    if (Object.keys(queryObj).length > 0) {
      const obj = _.pick(queryObj, ['firstName', 'lastName', 'email', 'phone', 'phoneCode', 'domain'])
      reset(obj)
      setSource({
        integration: _.omit(queryObj, ['firstName', 'lastName', 'email', 'phone', 'phoneCode', 'domain']),
      })
    }
  }, [JSON.stringify(queryObj)])

  useEffect(() => {
    if (phoneCodeAxios) {
      setValue('countryCode', phoneCodeAxios)
    }
  }, [phoneCodeAxios, setValue])

  useEffect(() => {}, [domain])

  useEffect(() => {
    if (company) {
      setValue('domain', company.toLowerCase().split('.').join('').split(' ').join(''), {shouldValidate: true})
    } else setValue('domain', '')
  }, [company])

  useEffect(() => {
    Axios.get('https://ipapi.co/json/').then(response => {
      const {data} = response
      setPhoneCodeAxios(data.country_calling_code)
    })
  }, [])

  useEffect(() => {
    let updatedList = _.sortBy(countryCodeList, function (item) {
      return item.dial_code === phoneCodeAxios ? 0 : 1
    })

    updatedList = updatedList.map(item => ({
      key: `${item.name} ${item.dial_code}`,
      value: item.dial_code,
      flag: item.code.toLowerCase(),
      text: `${item.name} ${item.dial_code}`,
      content: (
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '150px',
            display: 'inline-block',
          }}
        >{`(${item.dial_code})  ${item.name}`}</div>
      ),
    }))

    setCountryCodeUpdated(updatedList)
  }, [phoneCodeAxios])

  useEffect(() => {
    if (successLabels.includes(CREATE_USER_ACCOUNT)) {
      window.location.replace(`${signupData.domainURL}/emailverify?requestId=${signupData.requestId}`)
    }
  }, [successLabels])

  if (type.includes(CREATE_USER_ACCOUNT) || successLabels.includes(CREATE_USER_ACCOUNT)) {
    return <FullPageLoader />
  }

  return (
    <VmoContainer fluid>
      <VmoGrid className="signUpPage">
        <VmoGridColumn className="signUpPageRight">
          <div className="signInPage">
            <div className="signInBody">
              <VmoImage src={logo} className="veemo-logo" size="small" />
              <VmoCard className="loginCard">
                <VmoCardContent>
                  <h1>Start 14 Days Free Trial of {process.env.REACT_APP_NAME}</h1>
                  <p className="kadence-lead">No credit card required! Easy setup!</p>
                  <VmoForm size="large" onSubmit={handleSubmit(onSignup)}>
                    <VmoFormGroup widths="equal">
                      <Controller
                        name="firstName"
                        render={({value, onChange, onBlur}) => (
                          <VmoFormInput
                            type="text"
                            fluid
                            icon={<SvgIcon path="inbox/user" />}
                            iconPosition="left"
                            placeholder="First name"
                            className="errorLabelMl"
                            error={
                              errors.firstName && {
                                content: removeDoubleQuotes(errors.firstName.message),
                              }
                            }
                            onBlur={onBlur}
                            value={value}
                            onChange={e => {
                              onChange(e.target.value)
                            }}
                          />
                        )}
                        control={control}
                      />
                      <Controller
                        name="lastName"
                        control={control}
                        render={({value, onChange, onBlur}) => (
                          <VmoFormInput
                            fluid
                            type="text"
                            icon={<SvgIcon path="inbox/user" />}
                            iconPosition="left"
                            className="errorLabelMl"
                            placeholder="Last name"
                            error={
                              errors.lastName && {
                                content: removeDoubleQuotes(errors.lastName.message),
                              }
                            }
                            onBlur={onBlur}
                            value={value}
                            onChange={e => {
                              onChange(e.target.value)
                            }}
                          />
                        )}
                      />
                    </VmoFormGroup>
                    <Controller
                      name="email"
                      control={control}
                      render={({value, onChange, onBlur}) => (
                        <VmoFormInput
                          fluid
                          type="email"
                          icon={<SvgIcon path="common/mail" />}
                          iconPosition="left"
                          placeholder="Email address"
                          error={
                            errors.email && {
                              content: removeDoubleQuotes(errors.email.message),
                            }
                          }
                          onBlur={onBlur}
                          value={value}
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="password"
                      control={control}
                      render={({value, onChange, onBlur}) => (
                        <VmoFormInput
                          fluid
                          value={value}
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          onBlur={onBlur}
                          type={!showPwd ? 'password' : 'text'}
                          icon={<SvgIcon path="common/lock" />}
                          iconPosition="left"
                          placeholder="Enter password"
                          action={{
                            color: 'basic',
                            tabindex: -1,
                            icon: !showPwd ? 'eye slash' : 'eye',
                            onClick: e => {
                              e.preventDefault()
                              setShowPwd(!showPwd)
                            },
                          }}
                          error={
                            errors.password && {
                              content: removeDoubleQuotes(errors.password.message),
                            }
                          }
                        />
                      )}
                    />
                    <VmoFormField className="phone-no">
                      <Controller
                        name="phone"
                        control={control}
                        render={({value, onChange, onBlur}) => (
                          <VmoFormInput
                            type="text"
                            fluid
                            icon={<MdPhone />}
                            iconPosition="left"
                            placeholder="Enter Phone no"
                            value={value}
                            onChange={e => {
                              onChange(e.target.value)
                              trigger('phone')
                            }}
                            onBlur={onBlur}
                            error={
                              errors.phone && {
                                content: removeDoubleQuotes(errors.phone.message),
                              }
                            }
                          />
                        )}
                      />

                      <Controller
                        name="countryCode"
                        control={control}
                        render={({value, onChange, onBlur}) => (
                          <KadenceCustomDropdown
                            dropdownProps={{inline: true}}
                            options={countryCodeUpdated}
                            value={value}
                            onClick={value => {
                              onChange(value)
                            }}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      <VmoFlag
                        className="dd-flag"
                        name={countryCodeUpdated.find(item => item.value === countryCode)?.flag?.toLowerCase() || 'au'}
                      />
                      <span className="country-code"> {countryCode}</span>
                    </VmoFormField>

                    <Controller
                      name="company"
                      control={control}
                      render={({value, onChange, onBlur}) => (
                        <VmoFormInput
                          fluid
                          type="text"
                          icon={<FiBriefcase />}
                          value={value}
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          onBlur={onBlur}
                          iconPosition="left"
                          placeholder="Enter Company"
                          error={
                            errors.company && {
                              content: removeDoubleQuotes(errors.company.message),
                            }
                          }
                        />
                      )}
                    />
                    <div className="domainInput">
                      <Controller
                        name="domain"
                        control={control}
                        render={({value, onChange, onBlur}) => (
                          <VmoFormInput
                            type="text"
                            fluid
                            icon={<SvgIcon path="inbox/user" />}
                            iconPosition="left"
                            placeholder="Domain"
                            value={value}
                            onChange={e => {
                              onChange(e.target.value)
                            }}
                            onBlur={onBlur}
                            error={
                              errors.domain && {
                                content: removeDoubleQuotes(
                                  errors.domain.message || (errors.domain.type === 'validate' && 'Domain already taken')
                                ),
                              }
                            }
                          />
                        )}
                      />
                      <span className="domain-text">.kadence.io</span>
                    </div>
                    <VmoButton
                      primary
                      type="submit"
                      fluid
                      size="large"
                      disabled={isLoadingData && type.includes(CREATE_USER_ACCOUNT)}
                      loading={isLoadingData && type.includes(CREATE_USER_ACCOUNT)}
                    >
                      Signup for Free
                    </VmoButton>
                  </VmoForm>

                  <p className="kadence-lead mt-2 mb-0 tc-privacy-policy" style={{textAlign: 'center'}}>
                    By signing up, you agree to our <a>T & C</a> and <a href>Privacy policy</a>
                  </p>
                </VmoCardContent>
              </VmoCard>
            </div>
            <p className="kadence-lead mt-2">
              Already have an account?&nbsp;
              <Link
                to={{
                  pathname: '/signin',
                  integration: source,
                }}
              >
                Sign In
              </Link>
            </p>
          </div>
        </VmoGridColumn>
        <VmoGridColumn className="singupTestiMonial">
          <div className="signupTestiBody">
            <div>
              <div className="signup-head-text">
                Deliver world class customer support <span>faster than ever</span>
              </div>
              <span style={{fontSize: 18}}>
                Uniformly bring your team, data, and customers under one platform to help your business grow.
              </span>
            </div>

            <VmoCarouselProvider
              className="testi-slider"
              naturalSlideWidth={100}
              isIntrinsicHeight
              totalSlides={carouselSlideLength}
            >
              <VmoCarouselSlider>
                {Array.from({length: carouselSlideLength}, (_, index) => (
                  <VmoCarouselSlide index={index}>
                    <div className="clientTesti">
                      <p>
                        <MdFormatQuote />
                        Veemo Support has been great for us and helped us to reduce issues by
                        <span> 35% by automating our support</span> and merging all our channels in one platform
                      </p>

                      <VmoHeader as="h3">
                        <VmoImage avatar src={user} />
                        <VmoHeaderContent>
                          Nilay Sheth
                          <VmoHeaderSubheader>
                            Founder,
                            <a href="https://www.nivida.in/" target="_blank" rel="noreferrer">
                              nivida.in
                            </a>
                          </VmoHeaderSubheader>
                        </VmoHeaderContent>
                      </VmoHeader>
                    </div>
                  </VmoCarouselSlide>
                ))}
              </VmoCarouselSlider>
              <VmoCarouselDotGroup />
            </VmoCarouselProvider>

            <div className="my-3 our-happy-clients">
              <div className="client-img">
                <img src={amazon5} alt="amazon" />
                <img src={amazon1} alt="amazon" />
                <img src={amazon2} alt="amazon" />
                <img src={amazon3} alt="amazon" />
                <img src={amazon4} alt="amazon" />
              </div>
            </div>

            <div className="get-started-with">
              <h5>Get started with Kadence for free</h5>
              <div className="started-points">
                <VmoList className="m-0">
                  <VmoListItem>
                    <SvgIcon path="common/check-circle" />
                    <span>Scheduling on multiple calendars</span>
                  </VmoListItem>
                  <VmoListItem>
                    <SvgIcon path="common/check-circle" />
                    <span>Automated email sequences</span>
                  </VmoListItem>
                  <VmoListItem>
                    <SvgIcon path="common/check-circle" />
                    <span>Project management</span>
                  </VmoListItem>
                </VmoList>
                <VmoList className="m-0">
                  <VmoListItem>
                    <SvgIcon path="common/check-circle" />
                    <span>Unlimited email tracking</span>
                  </VmoListItem>
                  <VmoListItem>
                    <SvgIcon path="common/check-circle" />
                    <span>Advanced automation & reminders</span>
                  </VmoListItem>
                  <VmoListItem>
                    <SvgIcon path="common/check-circle" />
                    <span>Detailed reportings and analytics</span>
                  </VmoListItem>
                </VmoList>
              </div>
            </div>
          </div>
        </VmoGridColumn>
      </VmoGrid>
    </VmoContainer>
  )
}

export default SignUp
