import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import Employees from 'components/pages/HumanResourse/Employees/Employees'
import EmployeeDepartments from 'components/pages/HumanResourse/Employees/Settings/Department/EmployeeDepartments'
import EmployeesGeneral from 'components/pages/HumanResourse/Employees/Settings/GeneralSetting/EmployeesGeneral'

const routes = [
  {path: '/employees/all', component: Employees, title: 'All employees', exact: true},
  {path: '/employees/general', component: EmployeesGeneral, title: 'Employees', exact: false},
  {path: '/employees/departments', component: EmployeeDepartments, title: 'Employees', exact: false},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} />
        <Component {...props} />
      </>
    )}
  />
)

const EmployeeRoutes = () => (
  <Switch>
    <Route exact path="/employees">
      <Redirect to="/employees/all" />
    </Route>
    {routes.map(renderRoute)}
    <Route path="/employees/*">
      <Redirect to="/employees/all" />
    </Route>
  </Switch>
)

export default EmployeeRoutes
