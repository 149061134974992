/* eslint-disable no-fallthrough */
import _ from 'lodash'
import {
  GET_ALL_DEALS,
  ADD_DEAL,
  EXPORT_ALL_DEALS,
  SAMPLE_DEALS_IMPORT,
  IMPORT_DEALS,
  GET_COLUMNS_SELECTION_DEALS,
  COLUMNS_SELECTION_DEALS,
  GET_STAGE_DEALS,
  GET_SOURCE_DEALS,
  GET_SINGLE_DEALS,
  CONTACT_ALL_COMPANIES,
  GET_ALL_CONTACTS,
  EDIT_DEAL,
  ADD_NOTE_DEAL,
  ADD_DEAL_INTERACTION,
  MULTIPLE_UPDATE_DEAL,
  UNLINK_CONTACT,
  PRIMARY_CONTACT_CHANGE,
  ADD_CONTACT_DEAL,
  WIN_DEAL,
  LOSS_DEAL,
  ABANDON_DEAL,
  CONTACT_TYPE_LIST,
  GET_ALL_PIPELINES,
} from 'actions/types'
import {get} from 'utils/helper'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_DEALS: {
      return {
        ...state,
        getAllDealsList: action.payload,
        // dealsPage: action.payload.page,
      }
    }
    case GET_SINGLE_DEALS: {
      return {
        ...state,
        singleDeals: action.payload,
      }
    }
    case CONTACT_ALL_COMPANIES:
      return {...state, getAllContactCompanyList: action.payload}

    case ADD_DEAL: {
      return {
        ...state,
        // getAllDealsList: action.payload
        //   ? {
        //       page: state.getAllDealsList.page,
        //       deals: [action.payload, ...state.getAllDealsList.deals],
        //     }
        //   : state.getAllDealsList,
      }
    }
    case 'CLEAR_ADD_DEAL':
      return {...state, addDealMsg: null}

    case EDIT_DEAL: {
      if (_.hasIn(state, 'singleDeals')) {
        const temp = [...get(['getAllDealsList', 'deals'], state, [])]
        const updatedIndex = temp.findIndex(list => list.id === action.payload.id)
        temp.splice(updatedIndex, 1, action.payload)
        return {
          ...state,
          singleDeals: action.payload,
          getAllDealsList: temp,
        }
      }

      if (_.hasIn(_.get(state, 'getAllDealsList'), 'stages')) {
        const temp = state.getAllDealsList ? [...state.getAllDealsList.stages] : []
        const allStages = state.getAllDealsList ? state.getAllDealsList.allStages : {}

        const NewData = temp.map(list => {
          if (list.sequenceNo === _.get(action, 'payload.stage.sequenceNo')) {
            const tempArray = {
              ...list,
              deals: [action.payload, ..._.get(list, 'deals')],
            }
            return tempArray
          }
          return list
        })

        return {
          ...state,
          getAllDealsList: {allStages, stages: NewData},
        }
      }
    }

    case ADD_NOTE_DEAL:
      return {
        ...state,
        singleDeals: action.payload,
      }

    case EXPORT_ALL_DEALS: {
      return {
        ...state,
        exportAllDealsData: action.payload,
      }
    }
    case SAMPLE_DEALS_IMPORT:
      return {...state, sampleDealImport: action.payload}
    case IMPORT_DEALS:
      return {
        ...state,
        importDealsData: action.payload,
      }
    case COLUMNS_SELECTION_DEALS:
      return {
        ...state,
        getAllDealsColumnsList: action.payload,
      }
    case GET_COLUMNS_SELECTION_DEALS: {
      return {
        ...state,
        getAllDealsColumnsList: action.payload,
      }
    }

    case GET_STAGE_DEALS:
      return {
        ...state,
        getStageDeals: action.payload,
      }
    case GET_SOURCE_DEALS:
      return {
        ...state,
        getSourceDeals: action.payload,
      }

    case GET_ALL_CONTACTS:
      return {
        ...state,
        getAllContactsList: get(['payload', 'contacts'], action),
      }
    case ADD_DEAL_INTERACTION:
      return {
        ...state,
        singleDeals: action.payload,
      }

    case MULTIPLE_UPDATE_DEAL: {
      const temp = state.getAllDealsList ? [...state.getAllDealsList.deals] : []
      const page = state.getAllDealsList ? state.getAllDealsList.page : {}
      action.payload.forEach(updated => {
        const updatedIndex = temp.findIndex(list => list.id === updated.id)
        temp.splice(updatedIndex, 1, updated)
      })

      return {
        ...state,
        getAllDealsList: {page, deals: temp},
      }
    }

    case UNLINK_CONTACT:
      return {
        ...state,
        singleDeals: action.payload ? action.payload : state.singleDeals,
      }

    case PRIMARY_CONTACT_CHANGE:
      return {
        ...state,
        singleDeals: action.payload ? action.payload : state.singleDeals,
      }

    case ADD_CONTACT_DEAL:
      return {
        ...state,
        singleDeals: action.payload ? action.payload : state.singleDeals,
      }
    case WIN_DEAL:
      return {
        ...state,
      }
    case LOSS_DEAL:
      return {
        ...state,
      }

    case ABANDON_DEAL:
      return {
        ...state,
      }
    case CONTACT_TYPE_LIST:
      return {
        ...state,
        getTypeListData: action.payload,
      }

    case GET_ALL_PIPELINES:
      return {...state, getAllPipelineData: action.payload}

    // case DELETE_DEAL:
    //   let temp = state.getAllDealsList ? [...state.getAllDealsList.deals] : [];
    //   let ids = action.payload;

    //   return {
    //     ...state,
    //     getAllDealsList: {
    //       deals: temp.filter((contact) => ids.indexOf(contact.id) === -1),
    //       page: {
    //         ...state.getAllDealsList.page,
    //         total: _.subtract(_.get(state, "dealPage.total"), _.size(ids)),
    //       },
    //     },
    //   };
    default:
      return state
  }
}
