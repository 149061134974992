import {
  AVERAGE_REPLIES_RESOLUTION,
  AVERAGE_RESOLUTION_TIME_STATS,
  CUST_SAT_CONVO,
  FIRST_CONTACT_RESOLVED,
  FIRST_RESPONSE_TIME_STATS,
  NEW_CONTACTS_ANALYTICS,
  TAGGED_VS_NON_TAGGED_OVERTIME,
  TAGS_COUNT_TABLE,
  TOTAL_CONVERSATION,
  CONVERSATION_BY_INBOX,
} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case TOTAL_CONVERSATION:
      return {
        ...state,
        totalConversation: payload,
      }

    case CUST_SAT_CONVO:
      return {
        ...state,
        customSatisfactionData: payload,
      }

    case FIRST_RESPONSE_TIME_STATS:
      return {
        ...state,
        firstResponseTimeData: payload,
      }

    case FIRST_CONTACT_RESOLVED:
      return {
        ...state,
        firstContactResolvedData: payload,
      }

    case AVERAGE_REPLIES_RESOLUTION:
      return {
        ...state,
        averageRepliesResolutionData: payload,
      }

    case AVERAGE_RESOLUTION_TIME_STATS:
      return {
        ...state,
        averageResoltionTimeData: payload,
      }

    case NEW_CONTACTS_ANALYTICS:
      return {
        ...state,
        newContactsAnalyticsData: payload,
      }

    case TAGGED_VS_NON_TAGGED_OVERTIME:
      return {
        ...state,
        taggedVsNonTaggedOvertimeData: payload,
      }

    case TAGS_COUNT_TABLE:
      return {
        ...state,
        tagsCountTableData: payload,
      }

    case 'UPDATE_SELECTED_INBOX':
      return {
        ...state,
        analyticsSelectedInbox: payload,
      }

    case 'UPDATE_SELECTED_ASSIGNEE':
      return {
        ...state,
        analyticsSelectedAssignee: payload,
      }
    case CONVERSATION_BY_INBOX:
      return {
        ...state,
        conversationByInboxData: action.payload,
      }

    default:
      return state
  }
}
