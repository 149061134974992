import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoDivider,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoList,
  VmoListContent,
  VmoListHeader,
  VmoListItem,
  VmoRadio,
  VmoTooltip,
} from 'vmo-library'

import {GET_ALL_MAIL_INBOX, GET_CLIENT_PORTAL_TICKET_FORM, UPDATE_CLIENT_PORTAL_TICKET_FORM} from 'actions/types'
import {getClientPortalTicketForm, updateClientPortalTicketForm} from 'actions/client-portal'
import {getAllMailInbox} from 'actions/inbox'
import {get, removeDoubleQuotes} from 'utils/helper'
import SettingLayout from 'layouts/settingLayout'
import {ticketsSchema} from 'validation/Customers/clientPortal.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const ticketsDefaultValues = {
  enableTicketSubmit: false,
  heading: '',
  enableSubject: false,
  enableMessage: false,
  allowAttchement: false,
  submitButtonText: '',
  submitMessage: '',
  inboxId: '',
}

const Tickets = () => {
  const dispatch = useDispatch()

  const [inboxList, setInboxList] = useState([])

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {ticketForm = {}} = useSelector(state => state.clientPortal)
  const {inbox = []} = useSelector(state => state.mailInbox)

  const {clientPortalPermissions} = useUserPermissions()
  const settingsPermissions = clientPortalPermissions?.settings

  const {
    handleSubmit,
    control,
    errors,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(ticketsSchema),
    defaultValues: ticketsDefaultValues,
  })
  const watchEnableTicketSubmit = watch('enableTicketSubmit')

  useEffect(() => {
    dispatch(getClientPortalTicketForm())
    dispatch(getAllMailInbox())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(UPDATE_CLIENT_PORTAL_TICKET_FORM)) {
      dispatch(getClientPortalTicketForm())
    }
  }, [successLabels, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_ALL_MAIL_INBOX)) {
      const list = inbox.map(({id, name, emailVerified}) => ({
        key: id,
        value: id,
        text: name,
        content: (
          <div>
            {name}
            {!emailVerified && <small className="ml-2 kadence-lead">- not verified</small>}
          </div>
        ),
        disabled: !emailVerified,
      }))
      setInboxList(list)
    }
  }, [successLabels, inbox])

  const resetData = useCallback(
    ticketForm => {
      const data = {
        enableTicketSubmit: get(['enableTicketSubmit'], ticketForm, false),
        heading: get(['heading'], ticketForm, ''),
        enableSubject: get(['enableSubject'], ticketForm, false),
        enableMessage: get(['enableMessage'], ticketForm, false),
        allowAttchement: get(['allowAttchement'], ticketForm, false),
        submitButtonText: get(['submitButtonText'], ticketForm, ''),
        submitMessage: get(['submitMessage'], ticketForm, ''),
        inboxId: get(['inboxId'], ticketForm, ''),
      }
      reset(data)
    },
    [reset]
  )

  useEffect(() => {
    if (successLabels.includes(GET_CLIENT_PORTAL_TICKET_FORM)) {
      resetData(ticketForm)
    }
  }, [successLabels, ticketForm, resetData])

  return (
    <SettingLayout
      header="Ticket Settings"
      subHeader="Manage all settings related to ticket creation from client portal"
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Reset',
          disabled: !isDirty,
          onClick: () => resetData(ticketForm),
        },
        success: {
          content: 'Update',
          disabled: !isDirty,
          ...(settingsPermissions && {
            onClick: handleSubmit(data => dispatch(updateClientPortalTicketForm(data))),
          }),
        },
      }}
      lockRole={!settingsPermissions}
    >
      <div className="switchBox-kb">
        <div className="d-flex">
          <div>
            <h5>Create Ticket</h5>
            <p className="kadence-lead">Customers can raise a ticket if he/she encounters any issue</p>
          </div>
          <Controller
            name="enableTicketSubmit"
            control={control}
            render={({value, onChange}) => (
              <VmoRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
            )}
          />
        </div>
        <VmoDivider />
        {watchEnableTicketSubmit && (
          <VmoForm className="errorLabel">
            <VmoFormField width={8}>
              <label>Ticket Heading</label>
              <Controller
                name="heading"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormInput
                    maxLength={30}
                    placeholder="Enter Header which you want to show on top of ticket"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.heading && {content: removeDoubleQuotes(errors.heading.message)}}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField>
              <div className="info-header">
                <label>Form Fields</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                  content="These details are requested from the customer if he/she raises an issue"
                />
              </div>
              <VmoList relaxed className="swtichList">
                <VmoListItem>
                  <VmoListContent>
                    <VmoListHeader>
                      Subject
                      <Controller
                        name="enableSubject"
                        control={control}
                        render={({value, onChange}) => (
                          <VmoRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                        )}
                      />
                    </VmoListHeader>
                  </VmoListContent>
                </VmoListItem>
                <VmoListItem>
                  <VmoListContent>
                    <VmoListHeader>
                      Message
                      <Controller
                        name="enableMessage"
                        control={control}
                        render={({value, onChange}) => (
                          <VmoRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                        )}
                      />
                    </VmoListHeader>
                  </VmoListContent>
                </VmoListItem>
                <VmoListItem>
                  <VmoListContent>
                    <VmoListHeader>
                      Allow customers to attach files
                      <Controller
                        name="allowAttchement"
                        control={control}
                        render={({value, onChange}) => (
                          <VmoRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                        )}
                      />
                    </VmoListHeader>
                  </VmoListContent>
                </VmoListItem>
              </VmoList>
            </VmoFormField>
            <VmoFormField width={8}>
              <div className="info-header">
                <label>Ticket Submit Button text</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                  content="Specify a text for the submit button"
                />
              </div>
              <Controller
                name="submitButtonText"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormInput
                    maxLength={30}
                    placeholder="Submit"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.submitButtonText && {content: removeDoubleQuotes(errors.submitButtonText.message)}}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField width={8}>
              <div className="info-header">
                <label>Ticket Submit Message</label>
                {/* <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                  content="Lorem ipsam...."
                /> */}
              </div>
              <VmoFormField>
                <Controller
                  name="submitMessage"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormTextArea
                      maxLength={200}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={errors.submitMessage && {content: removeDoubleQuotes(errors.submitMessage.message)}}
                    />
                  )}
                />
              </VmoFormField>
              <p className="kadence-lead pt-3">Tip:- Message to be displayed after a user submits the ticket form</p>
            </VmoFormField>
            <VmoFormField width={8}>
              <div className="info-header">
                <label>Ticket receive email Inbox</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                  content="Inbox into which you want to receive tickets"
                />
              </div>
              <Controller
                name="inboxId"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormSelect
                    fluid
                    search
                    options={inboxList}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.inboxId && {content: removeDoubleQuotes(errors.inboxId.message)}}
                  />
                )}
              />
            </VmoFormField>
          </VmoForm>
        )}
      </div>
    </SettingLayout>
  )
}

export default Tickets
