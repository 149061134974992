import {apiAction} from './api'
import {KADENCE_VENDORS} from './endpoint'

import {
  GET_VENDORS_CATEGORY,
  CREATE_VENDORS_CATEGORY,
  DELETE_VENDORS_CATEGORY,
  GET_SINGLE_VENDORS_CATEGORY,
  EDIT_VENDORS_CATEGORY,
  CREATE_VENDORS_FIELD,
  GET_VENDORS_FIELD,
  GET_SINGLE_VENDORS_FIELD,
  EDIT_VENDORS_FIELD,
  DELETE_VENDORS_FIELD,
  GET_VENDOR_FIELD_BY_CATEGORY,
  CREATE_VENDORS,
  GET_VENDORS_LIST,
  DELETE_VENDORS,
  GET_VENDORS_DATA,
  UPDATE_VENDORS,
  EXPORT_ALL_VENDORS,
  DELETE_VENDOR_IMAGE,
} from './types'

export function getVendorsCategory() {
  return apiAction({
    url: `${KADENCE_VENDORS}/categories`,
    method: 'GET',
    label: GET_VENDORS_CATEGORY,
  })
}

export function createVendorsCategory(data) {
  return apiAction({
    url: `${KADENCE_VENDORS}/category`,
    method: 'POST',
    label: CREATE_VENDORS_CATEGORY,
    data,
    showToast: true,
    successMessage: 'Category Added',
  })
}

export function deleteVendorsCategory(id) {
  return apiAction({
    url: `${KADENCE_VENDORS}/category/${id}`,
    method: 'DELETE',
    label: DELETE_VENDORS_CATEGORY,
    showToast: true,
    successMessage: 'Category Deleted',
  })
}

export function getSingleVendorsCategory(id) {
  return apiAction({
    url: `${KADENCE_VENDORS}/category/${id}`,
    method: 'GET',
    label: GET_SINGLE_VENDORS_CATEGORY,
  })
}

export function editVendorsCategory(id, data) {
  return apiAction({
    url: `${KADENCE_VENDORS}/category/${id}`,
    method: 'PUT',
    label: EDIT_VENDORS_CATEGORY,
    data,
    showToast: true,
    successMessage: 'Category Updated',
  })
}

export function createVendorsField(data) {
  return apiAction({
    url: `${KADENCE_VENDORS}/field`,
    method: 'POST',
    label: CREATE_VENDORS_FIELD,
    data,
    showToast: true,
    successMessage: 'Field Created',
  })
}

export function getVendorsField(params = {}) {
  const keys = Object.keys(params)
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')

  return apiAction({
    url: `${KADENCE_VENDORS}/fields?${query}`,
    method: 'GET',
    label: GET_VENDORS_FIELD,
  })
}

export function getVendorFieldByCategory() {
  return apiAction({
    url: `${KADENCE_VENDORS}/fieldsbycategory`,
    method: 'GET',
    label: GET_VENDOR_FIELD_BY_CATEGORY,
  })
}

export function getSingleVendorsField(id) {
  return apiAction({
    url: `${KADENCE_VENDORS}/field/${id}`,
    method: 'GET',
    label: GET_SINGLE_VENDORS_FIELD,
  })
}

export function editVendorsField(id, data) {
  return apiAction({
    url: `${KADENCE_VENDORS}/field/${id}`,
    method: 'PUT',
    label: EDIT_VENDORS_FIELD,
    data,
    showToast: true,
    successMessage: 'Field Updated',
  })
}

export function deleteVendorsField(id) {
  return apiAction({
    url: `${KADENCE_VENDORS}/field/${id}`,
    method: 'DELETE',
    label: DELETE_VENDORS_FIELD,
    showToast: true,
    successMessage: 'Field Deleted',
  })
}

export function createVendors(data) {
  return apiAction({
    url: `${KADENCE_VENDORS}`,
    method: 'POST',
    label: CREATE_VENDORS,
    data,
    showToast: true,
    successMessage: 'Vendors Added',
  })
}

export function getVendorslist(params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params || {})
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }

  return apiAction({
    url: KADENCE_VENDORS + query,
    method: 'GET',
    label: GET_VENDORS_LIST,
  })
}

export function deleteVendors(id) {
  return apiAction({
    url: `${KADENCE_VENDORS}/${id}`,
    method: 'DELETE',
    label: DELETE_VENDORS,
    showToast: true,
    successMessage: 'Vendors Deleted',
  })
}

export function getVendorsData(id) {
  return apiAction({
    url: `${KADENCE_VENDORS}/${id}`,
    method: 'GET',
    label: GET_VENDORS_DATA,
  })
}

export function updateVendors(id, data) {
  return apiAction({
    url: `${KADENCE_VENDORS}/${id}`,
    method: 'PUT',
    label: UPDATE_VENDORS,
    data,
    showToast: true,
    successMessage: 'Vendors Updated',
  })
}

export function exportAllVendors() {
  return apiAction({
    url: `${KADENCE_VENDORS}/exports`,
    method: 'GET',
    label: EXPORT_ALL_VENDORS,
  })
}

export function deleteVendorImage(id) {
  return apiAction({
    url: `${KADENCE_VENDORS}/profile/${id}`,
    method: 'DELETE',
    label: DELETE_VENDOR_IMAGE,
    showToast: true,
    successMessage: 'Image Deleted',
  })
}
