import {
  GET_PROJECTS_HEALTH_DATA,
  GET_PROJECTS_HEALTH_LIST,
  GET_PROJECTS_PLANNING_REPORT_UTILIZATION,
  GET_PROJECTS_TIMESHEET_TIMELOGS_BY_PROJECT_LIST,
  GET_PROJECTS_TIMESHEET_TIMELOGS_BY_USER_LIST,
  GET_PROJECTS_TIMESHEET_TIMELOGS_CHART,
  GET_PROJECTS_USERS_TASKS_BY_USER_LIST,
} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case GET_PROJECTS_HEALTH_LIST:
      return {...state, projectsHealthList: payload || []}

    case GET_PROJECTS_HEALTH_DATA:
      return {...state, projectsHealthData: payload || {}}

    case 'UPDATE_SELECTED_PROJECT':
      return {
        ...state,
        analyticsSelectedProject: payload,
      }

    case GET_PROJECTS_TIMESHEET_TIMELOGS_CHART:
      return {...state, projectsTimesheetTimelogsChartData: payload || {}}

    case GET_PROJECTS_TIMESHEET_TIMELOGS_BY_PROJECT_LIST:
      return {...state, projectsTimesheetTimelogsByProjectList: payload || []}

    case GET_PROJECTS_TIMESHEET_TIMELOGS_BY_USER_LIST:
      return {...state, projectsTimesheetTimelogsByUserList: payload || []}

    case GET_PROJECTS_USERS_TASKS_BY_USER_LIST:
      return {...state, projectsUsersTasksByUserList: payload || []}

    case GET_PROJECTS_PLANNING_REPORT_UTILIZATION:
      return {...state, projectsPlanningReportUtilization: payload || []}

    default:
      return state
  }
}
