import {
  TOTAL_DEALS,
  OPEN_DEALS_BY_STAGES,
  OPEN_DEALS_OVER_TIME,
  WON_DEALS_OVER_TIME,
  LOST_DEALS_OVER_TIME,
  DEAL_STATUS_BREAKDOWN,
  DEAL_STAGE_BREAKDOWN,
  DEAL_BY_USERS,
  DEAL_BY_LOSS_REASON,
  DEAL_FUNNEL_REPORT,
  SALES_INFO,
  DEAL_ANALYTICS_BY_USERS,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case TOTAL_DEALS:
      return {
        ...state,
        totalDealsData: action.payload,
      }

    case OPEN_DEALS_BY_STAGES:
      return {
        ...state,
        openDealsByStagesData: action.payload,
      }

    case OPEN_DEALS_OVER_TIME:
      return {
        ...state,
        openDealsOverTimeData: action.payload,
      }
    case WON_DEALS_OVER_TIME:
      return {
        ...state,
        wonDealsOverTimeData: action.payload,
      }
    case LOST_DEALS_OVER_TIME:
      return {
        ...state,
        lostDealsOverTimeData: action.payload,
      }
    case DEAL_STAGE_BREAKDOWN:
      return {
        ...state,
        dealStageBreakdownData: action.payload,
      }
    case DEAL_STATUS_BREAKDOWN:
      return {
        ...state,
        dealStatusBreakdownData: action.payload,
      }
    case DEAL_BY_USERS:
      return {
        ...state,
        dealsByUsersData: action.payload,
      }
    case DEAL_BY_LOSS_REASON:
      return {
        ...state,
        dealsByLossReasonData: action.payload,
      }
    case DEAL_FUNNEL_REPORT:
      return {
        ...state,
        dealsFunnelReportData: action.payload,
      }
    case SALES_INFO:
      return {
        ...state,
        salesInfodata: action.payload,
      }
    case DEAL_ANALYTICS_BY_USERS:
      return {
        ...state,
        dealAnalyticsByUsersData: action.payload,
      }
    default:
      return state
  }
}
