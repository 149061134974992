import {GET_ALL_SEGMENT, GET_SEGMENT, GET_SEGMENTS_LIST, GET_STATIC_CONTACT} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_SEGMENTS_LIST:
      return {
        ...state,
        segmentList: {
          ...(state.segmentList || {}),
          [action.successData]: action.payload,
        },
      }
    case GET_SEGMENT:
      return {...state, segmentData: action.payload}
    case 'CLEAR_SEGMENT_DATA': {
      return {...state, segmentData: undefined}
    }
    case GET_STATIC_CONTACT: {
      return {...state, getStaticSegmentData: action.payload}
    }
    case 'CLEAR_STATIC_CONTACT': {
      return {...state, getStaticSegmentData: undefined}
    }
    case GET_ALL_SEGMENT:
      return {
        ...state,
        segmentListAll: action.payload || [],
      }
    default:
      return state
  }
}
