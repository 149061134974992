/* eslint-disable no-nested-ternary */
import React from 'react'
import {VmoModal, VmoModalHeader, VmoButton, VmoModalContent, VmoModalActions} from 'vmo-library'
import SvgIcon from 'components/common/SvgIcon'

function EmailDeleteModal(props) {
  const {isModalOpen, setIsModalOpen, onDeleteConversation, isTrashedConversation, isDraftConversation} = props
  return (
    <VmoModal
      size="tiny"
      centered={false}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
    >
      <VmoModalHeader>Delete This Conversations</VmoModalHeader>
      <VmoModalContent>
        <h5>
          {` Are you sure you want to delete this Conversations ${
            isTrashedConversation ? 'permanently' : isDraftConversation ? 'from Draft' : ''
          }`}
          ?
        </h5>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsModalOpen(false)}>
          No
        </VmoButton>
        <VmoButton onClick={onDeleteConversation} primary content="Yes" />
      </VmoModalActions>
    </VmoModal>
  )
}

export default EmailDeleteModal
