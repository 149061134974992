import ExpenseMenu from 'navigation/sub-routes/Hr/Expenses/ExpenseMenu'
import ExpenseRoutes from 'navigation/sub-routes/Hr/Expenses/ExpenseRoutes'
import React from 'react'

function Expenses() {
  return (
    <>
      <ExpenseMenu />
      <ExpenseRoutes />
    </>
  )
}

export default Expenses
