import Joi from 'joi'

export const AnnouncementSchema = Joi.object({
  displayTo: Joi.string().required(),
  departmentIds: Joi.array().when('displayTo', {is: 'departments', then: Joi.array().items(Joi.number()).required()}),
  tagIds: Joi.array().when('displayTo', {is: 'tags', then: Joi.array().items(Joi.number()).required()}),
  employeeIds: Joi.array().when('displayTo', {is: 'specific', then: Joi.array().items(Joi.number()).required()}),
  title: Joi.string().trim().min(2).max(20).required().messages({
    'string.min': `Length must me at least 2 charactes`,
    'string.max': `Length must be less than or equal to 20 characters`,
  }),
  enableUnPublish: Joi.boolean(),
  isScheduled: Joi.boolean(),
  unpublishDate: Joi.date().iso().allow('', null),
  scheduledDateTime: Joi.date().iso().allow('', null),
  announcement: Joi.string().required(),
  isDraft: Joi.boolean(),
})
