import {GET_BREAKDOWN_LIST, GET_BUDGET_DATA, GET_BUDGET_LIST} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_BREAKDOWN_LIST:
      return {
        ...state,
        getBreakdownListData: action.payload,
      }

    case GET_BUDGET_LIST:
      return {
        ...state,
        budgetPlannerList: action.payload,
        budgetPlannerPage: action.payload.page,
      }

    case GET_BUDGET_DATA:
      return {
        ...state,
        budgetPlannerData: action.payload,
      }

    default:
      return state
  }
}
