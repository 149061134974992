import React, {useEffect, useState} from 'react'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create-user.svg'
import {deleteProjectsTemplate, getProjectsTemplateslist} from 'actions/projects'
import {
  VmoButton,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useApiResponse from 'hooks/impure/useApiResponse'
import {
  CREATE_PROJECT_TEMPLATES,
  DELETE_PROJECTS_TEMPLATE,
  GET_PROJECTS_TEMPLATES_LIST,
  UPDATE_PROJECTS_TEMPLATE,
} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {useDispatch, useSelector} from 'react-redux'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import _ from 'lodash'
import SvgIcon from 'components/common/SvgIcon'
import CreateProjectTemplateModalStep from './CreateProjectTemplateModalStep'
import EditProjectTemplatesModal from './EditProjectTemplatesModal'
import CreateProjectModalStep from '../CreateProjectModalStep'

const ProjectTemplates = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [idToEdit, setIdToEdit] = useState(null)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [idToUseTemplate, setIdToUseTemplate] = useState(null)
  const [isUseTemplateModalOpen, setIsUseTemplateModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {projectsAndCollaborationAccess, projectsPermissions} = useUserPermissions()
  const projectsAccess = projectsAndCollaborationAccess?.projects
  const manageProjectsPermissions = projectsPermissions?.manage_projects

  const {data: projectsTemplatesList = [], isLoading: isLoadingProjectsTemplates} = useApiResponse({
    action: getProjectsTemplateslist,
    enabled: projectsAccess,
    dependency: [projectsAccess],
    label: GET_PROJECTS_TEMPLATES_LIST,
    storePath: 'projects.projectsTemplatesList',
  })

  const {isLoading} = useApiLoader({
    label: GET_PROJECTS_TEMPLATES_LIST,
  })

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECT_TEMPLATES) ||
      successLabels.includes(UPDATE_PROJECTS_TEMPLATE) ||
      successLabels.includes(DELETE_PROJECTS_TEMPLATE)
    ) {
      dispatch(getProjectsTemplateslist())
    }
  }, [successLabels, dispatch])

  const renderProjectTemplateModal = () => {
    if (isCreateModalOpen) {
      return <CreateProjectTemplateModalStep open={isCreateModalOpen} toggle={setIsCreateModalOpen} />
    }
    return null
  }

  const renderProjectsTemplates = () => {
    if (isLoadingProjectsTemplates) {
      return <VmoTablePlaceholder columns={4} />
    }
    return projectsTemplatesList.map(({id, title, budgetType, createdBy, createdAt}) => (
      <VmoTableRow className="tableLink" key={id} onClick={() => history.push(`/projects/templates/${id}/tasks`)}>
        <VmoTableCell>
          <VmoHeader as="h5">
            {startCase(title)}
            <VmoButton
              basic
              size="mini"
              className="ml-2 use-template"
              onClick={e => {
                e.stopPropagation()
                setIsUseTemplateModalOpen(true)
                setIdToUseTemplate(id)
              }}
            >
              Use Template
            </VmoButton>
          </VmoHeader>
        </VmoTableCell>
        <VmoTableCell>{_.startCase(budgetType)}</VmoTableCell>
        <VmoTableCell>
          <div>{`${createdBy?.firstName} ${createdBy.lastName}`}</div>
          <div>({getFormattedDate(createdAt)})</div>
        </VmoTableCell>
        <VmoTableCell>
          <div className="d-flex-js" style={{columnGap: 'var(--gap-medium)'}}>
            <VmoTooltip
              size="mini"
              position="top center"
              content={
                manageProjectsPermissions
                  ? 'Edit'
                  : getPermissionTooltipContent({
                      roleAccess: manageProjectsPermissions,
                    })
              }
              trigger={
                <VmoButton
                  basic
                  icon
                  onClick={e => {
                    e.stopPropagation()
                    if (manageProjectsPermissions) {
                      setIdToEdit(id)
                      setIsEditModalOpen(true)
                    }
                  }}
                >
                  <SvgIcon path="common/edit2" />
                </VmoButton>
              }
            />
            <VmoTooltip
              size="mini"
              position="top center"
              content={
                manageProjectsPermissions
                  ? 'Delete'
                  : getPermissionTooltipContent({
                      roleAccess: manageProjectsPermissions,
                    })
              }
              trigger={
                <VmoButton
                  basic
                  icon
                  onClick={e => {
                    e.stopPropagation()
                    if (manageProjectsPermissions) {
                      setIdToDelete(id)
                      setIsDeleteModalOpen(true)
                    }
                  }}
                >
                  <SvgIcon path="common/delete" />
                </VmoButton>
              }
            />
          </div>
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  if (isLoading && projectsAccess) {
    return <PageLoader />
  }

  if (!projectsAccess || !projectsTemplatesList.length) {
    return (
      <>
        {renderProjectTemplateModal()}
        <CreateFirst
          header="Template"
          subHeader="lorem ipsume"
          addFirst={() => setIsCreateModalOpen(true)}
          buttonText="Add Template"
          tooltip={!projectsAccess || !manageProjectsPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: projectsAccess,
            roleAccess: manageProjectsPermissions,
          })}
          imageSrc={create}
          Feature1="lorem ipsume"
          list1="lorem ipsume"
          Feature2="lorem ipsume"
          list2="lorem ipsume"
          Feature3="lorem ipsume"
          list3="lorem ipsume"
        />
      </>
    )
  }

  return (
    <>
      {renderProjectTemplateModal()}
      <div className="kadence-side-wrap" style={{height: '100%'}}>
        <div className="page-header pb-0">
          <div className="page-title">
            <div className="page-titleIcon">
              <SvgIcon path="settings/users" />
              <h5>Templates ({projectsTemplatesList?.length ?? 0})</h5>
            </div>
          </div>
          <div className="page-action">
            <LockPermissionTooltip isRoleAccessDenied={!manageProjectsPermissions}>
              <VmoButton
                primary
                content="Add Template"
                className={!manageProjectsPermissions ? 'disabled-button' : ''}
                onClick={() => manageProjectsPermissions && setIsCreateModalOpen(true)}
              />
            </LockPermissionTooltip>
          </div>
        </div>

        <VmoTable basic>
          <VmoTableHeader>
            <VmoTableRow>
              <VmoTableHeaderCell>Name</VmoTableHeaderCell>
              <VmoTableHeaderCell>Type</VmoTableHeaderCell>
              <VmoTableHeaderCell>Created By</VmoTableHeaderCell>
              <VmoTableHeaderCell>Actions</VmoTableHeaderCell>
            </VmoTableRow>
          </VmoTableHeader>
          <VmoTableBody className="templates-table-body">{renderProjectsTemplates()}</VmoTableBody>
        </VmoTable>
      </div>

      {isUseTemplateModalOpen && (
        <CreateProjectModalStep
          open={isUseTemplateModalOpen}
          toggle={setIsUseTemplateModalOpen}
          templateId={idToUseTemplate}
        />
      )}

      {isEditModalOpen && (
        <EditProjectTemplatesModal open={isEditModalOpen} toggle={setIsEditModalOpen} id={idToEdit} />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectsTemplate(idToDelete))}
          type={DELETE_PROJECTS_TEMPLATE}
        />
      )}
    </>
  )
}

export default ProjectTemplates
