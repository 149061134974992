import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {VmoDropdown, VmoDropdownMenu, VmoDropdownItem, VmoTooltip} from 'vmo-library'
import {ADD_SOCIAL, REMOVE_SOCIAL, UPDATE_SOCIAL} from 'actions/types'
import {get, startCase} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import EditSocialModal from './EditSocialModal'
import AddSocialModal from './AddSocialModal'
import IconList from './IconList'

const SocialIcons = props => {
  const {singleLead, singleCompany, singleContact, id, identifier, rolePermissions = true} = props
  const [listSocialIconDrpdown, setListSocialIconDrpdown] = useState(false)
  const [selectedSocial, setSelectedSocial] = useState('facebook')
  const [socialAdd, setSocialAdd] = useState(false)
  const [socialEdit, setSocialEdit] = useState(false)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  // const addSocialMsg = useSelector(state => get(['multipleFields', 'addSocialMsg'], state))

  // const updateSocialMsg = useSelector(state => get(['multipleFields', 'updateSocialMsg'], state))

  // const removeSocialMsg = useSelector(state => get(['multipleFields', 'removeSocialMsg'], state))

  const renderSocialList = () => {
    if (identifier === 'leads') {
      const selected = get(['socials'], singleLead, []).map(soc => soc.category)
      const list = ['facebook', 'twitter', 'linkedin'].filter(social => selected.indexOf(social) === -1)
      return list.map((list, i) => (
        <VmoDropdownItem
          key={i}
          onClick={() => {
            setSelectedSocial({category: list})
            setSocialAdd(!socialAdd)
          }}
        >
          {startCase(list)}
        </VmoDropdownItem>
      ))
    }
    if (identifier === 'contacts') {
      const selected = get(['socials'], singleContact, []).map(soc => soc.category)
      const list = ['facebook', 'twitter', 'linkedin'].filter(social => selected.indexOf(social) === -1)
      return list.map((list, i) => (
        <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
          <VmoDropdownItem
            key={i}
            onClick={() => {
              if (rolePermissions) {
                setSelectedSocial({category: list})
                setSocialAdd(!socialAdd)
              }
            }}
          >
            {startCase(list)}
          </VmoDropdownItem>
        </LockPermissionTooltip>
      ))
    }
    if (identifier === 'companies') {
      const selected = get(['socials'], singleCompany, []).map(soc => soc.category)
      const list = ['facebook', 'twitter', 'linkedin'].filter(social => selected.indexOf(social) === -1)
      return list.map((list, i) => (
        <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
          <VmoDropdownItem
            key={i}
            onClick={() => {
              if (rolePermissions) {
                setSelectedSocial({category: list})
                setSocialAdd(!socialAdd)
              }
            }}
          >
            {startCase(list)}
          </VmoDropdownItem>
        </LockPermissionTooltip>
      ))
    }
  }

  useEffect(() => {
    if (successLabels.includes(ADD_SOCIAL)) {
      setListSocialIconDrpdown(false)
      setSocialAdd(false)
    }
    if (successLabels.includes(UPDATE_SOCIAL)) {
      setListSocialIconDrpdown(false)
      setSocialEdit(false)
    }
    if (successLabels.includes(REMOVE_SOCIAL)) {
      setListSocialIconDrpdown(false)
    }
  }, [successLabels])

  return (
    <>
      <div className="socialIcons">
        {get(['socials'], singleLead, []).map((social, i) => (
          <IconList
            key={i}
            social={social}
            setSocialEdit={setSocialEdit}
            socialEdit={socialEdit}
            setSelectedSocial={setSelectedSocial}
            id={id}
            identifier={identifier}
          />
        ))}
        {get(['socials'], singleContact, []).map((social, i) => (
          <IconList
            key={i}
            social={social}
            setSocialEdit={setSocialEdit}
            socialEdit={socialEdit}
            setSelectedSocial={setSelectedSocial}
            id={id}
            identifier={identifier}
          />
        ))}
        {get(['socials'], singleCompany, []).map((social, i) => (
          <IconList
            key={i}
            social={social}
            setSocialEdit={setSocialEdit}
            socialEdit={socialEdit}
            setSelectedSocial={setSelectedSocial}
            id={id}
            identifier={identifier}
          />
        ))}
        {identifier === 'leads'
          ? get(['socials'], singleLead, []).length < 3 && (
              <VmoTooltip
                size="mini"
                position="right center"
                content="Add Social Media"
                trigger={
                  <VmoDropdown floating button direction="right" className="icon" icon={<SvgIcon path="common/plus" />}>
                    <VmoDropdownMenu>{renderSocialList()}</VmoDropdownMenu>
                  </VmoDropdown>
                }
              />
            )
          : identifier === 'contacts'
          ? get(['socials'], singleContact, []).length < 3 && (
              <VmoTooltip
                size="mini"
                position="right center"
                content="Add Social Media"
                trigger={
                  <VmoDropdown floating button direction="right" className="icon" icon={<SvgIcon path="common/plus" />}>
                    <VmoDropdownMenu>{renderSocialList()}</VmoDropdownMenu>
                  </VmoDropdown>
                }
              />
            )
          : identifier === 'companies'
          ? get(['socials'], singleCompany, []).length < 3 && (
              <VmoTooltip
                size="mini"
                position="right center"
                content="Add Social Media"
                trigger={
                  <VmoDropdown floating button direction="right" className="icon" icon={<SvgIcon path="common/plus" />}>
                    <VmoDropdownMenu>{renderSocialList()}</VmoDropdownMenu>
                  </VmoDropdown>
                }
              />
            )
          : null}
      </div>
      {socialAdd && (
        <AddSocialModal
          isModalOpen={socialAdd}
          setIsModalOpen={setSocialAdd}
          selectedSocial={selectedSocial}
          id={id}
          identifier={identifier}
        />
      )}
      {socialEdit && (
        <EditSocialModal
          isModalOpen={socialEdit}
          setIsModalOpen={setSocialEdit}
          selectedSocial={selectedSocial}
          socialEdit={socialEdit}
          setSocialEdit={setSocialEdit}
          id={id}
          identifier={identifier}
        />
      )}
    </>
  )
}

export default SocialIcons
