import React from 'react'
import create from 'assets/create.svg'
import CreateFirst from 'components/common/CreateFirst'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import VendorsMenu from 'navigation/sub-routes/Finance/Vendors/VendorsMenu'
import VendorsRoutes from 'navigation/sub-routes/Finance/Vendors/VendorsRoutes'
import {getPermissionTooltipContent} from 'utils/helper'

function Vendors() {
  const {financeAccess} = useUserPermissions()
  const vendorsAccess = financeAccess?.vendors

  if (!vendorsAccess) {
    return (
      <CreateFirst
        header="Vendor"
        buttonText="Create Vendor"
        tooltip={!vendorsAccess}
        tooltipContent={getPermissionTooltipContent({featureAccess: vendorsAccess})}
        imageSrc={create}
        subHeader="Vendors are business entities who deliver products to customers on time"
        Feature1="Vendors Information"
        list1="Store and manage detailed information about vendors"
        Feature2="Supplier Categorization"
        list2="Categorize suppliers based on several factors"
        Feature3="Export vendors"
        list3="Export vendors list in CSV format in one click "
      />
    )
  }
  return (
    <>
      <VendorsMenu />
      <VendorsRoutes />
    </>
  )
}

export default Vendors
