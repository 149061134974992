/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {DELETE_DEPARTMENT, GET_DEPARTMENT} from 'actions/types'
import {getDepartment, deleteDepartment} from 'actions/employees'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import {startCase} from 'utils/helper'
import EmployeeSidemenu from 'navigation/sub-routes/Hr/Employees/EmployeeMenu'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import CreateModal from './CreateModal'

const EmployeeDepartments = () => {
  const dispatch = useDispatch()

  const [departmentModal, setDepartmentModal] = useState(false)
  const [departmentId, setDepartmentId] = useState()
  const [deleteModal, setDeleteModal] = useState(false)

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {data: getDepartmentData = []} = useApiResponse({
    action: getDepartment,
    enabled: true,
    label: GET_DEPARTMENT,
    storePath: 'employees.getDepartmentData',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_DEPARTMENT)) {
      dispatch(getDepartment())
    }
  }, [dispatch, successLabels])

  const categoryProps = {
    open: departmentModal,
    toggleModal: setDepartmentModal,
    departmentToggle: setDepartmentId,
  }
  if (departmentId) {
    categoryProps.editId = departmentId
  }

  const renderTableBody = () => {
    return getDepartmentData.map(({id, updatedBy, createdBy, department, departmentHead}, index) => {
      return (
        <VmoTableRow
          key={id}
          className="tableLink"
          onClick={() => {
            setDepartmentModal(true)
            setDepartmentId(id)
          }}
        >
          <VmoTableCell>{index + 1}</VmoTableCell>
          <VmoTableCell>{startCase(department)}</VmoTableCell>
          <VmoTableCell>{departmentHead?.name}</VmoTableCell>
          <VmoTableCell>{createdBy?.name}</VmoTableCell>
          <VmoTableCell>{updatedBy?.name}</VmoTableCell>
          <VmoTableCell>
            <VmoTooltip
              content="Delete"
              size="mini"
              position="top center"
              trigger={
                <VmoButton
                  icon={<SvgIcon path="common/delete" />}
                  onClick={e => {
                    e.stopPropagation()
                    setDepartmentId(id)
                    setDeleteModal(true)
                  }}
                />
              }
            />
          </VmoTableCell>
        </VmoTableRow>
      )
    })
  }

  return (
    <>
      <EmployeeSidemenu />

      <SettingLayout
        header="Department Settings"
        subHeader="Manage list of existing departments. Click on add to create a new department in your company"
        table
        headerDivider
        headerButton={{
          onClick: () => {
            setDepartmentModal(true)
          },
          content: 'Add',
        }}
      >
        {departmentModal ? <CreateModal {...categoryProps} /> : null}

        <VmoTable sortable basic selectable>
          <VmoTableHeader>
            <VmoTableRow>
              <VmoTableHeaderCell>#</VmoTableHeaderCell>
              <VmoTableHeaderCell>Name</VmoTableHeaderCell>
              <VmoTableHeaderCell>Head of Department</VmoTableHeaderCell>
              <VmoTableHeaderCell>Created By</VmoTableHeaderCell>
              <VmoTableHeaderCell>Modified By</VmoTableHeaderCell>
              <VmoTableHeaderCell>Actions</VmoTableHeaderCell>
            </VmoTableRow>
          </VmoTableHeader>
          <VmoTableBody>
            {isLoadingData && type.includes(GET_DEPARTMENT) ? (
              <VmoTablePlaceholder columns={8} />
            ) : (getDepartmentData || []).length > 0 ? (
              renderTableBody()
            ) : (
              <tr>
                <td colSpan={6} className="emptyValue">
                  No Data Available
                </td>
              </tr>
            )}
          </VmoTableBody>
        </VmoTable>
        {deleteModal && (
          <DeleteModal
            isModalOpen={deleteModal}
            setIsModalOpen={setDeleteModal}
            modalText="Are you sure you want to delete ?"
            deleteAction={deleteDepartment}
            idTobeDeleted={departmentId}
            type={DELETE_DEPARTMENT}
          />
        )}
      </SettingLayout>
    </>
  )
}

export default EmployeeDepartments
