import React from 'react'
import {useDispatch} from 'react-redux'
import {VmoButton, VmoModal, VmoModalActions, VmoModalContent, VmoModalHeader} from 'vmo-library'
import {deleteProjectFinanceBudget} from 'actions/projects'
import SvgIcon from 'components/common/SvgIcon'

const DeleteProjectBudgetModal = ({open, toggle, projectId, budgetId}) => {
  const dispatch = useDispatch()

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>Delete Budget</VmoModalHeader>
      <VmoModalContent>
        <p>
          Are you sure you want to delete this budget? All historical data will be removed, this action cannot be
          undone.
        </p>
        <p>Alternatively you can choose to end the current budget, and the historical data will remain.</p>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton color="red" onClick={() => dispatch(deleteProjectFinanceBudget({projectId, budgetId}))}>
          Delete this budget
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default DeleteProjectBudgetModal
