import React from 'react'
import SvgIcon from 'components/common/SvgIcon'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

const CloneDealModal = ({cloneDeal, setCloneDeal}) => {
  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={cloneDeal}
      size="tiny"
      onClose={setCloneDeal}
    >
      <VmoModalHeader>Create Deal Pipeline</VmoModalHeader>
      <VmoModalContent>
        <VmoForm>
          <VmoFormField>
            <label>Pipeline Name</label>
            <VmoInput placeholder="Enter board name" />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onCick={setCloneDeal}>
          Cancel
        </VmoButton>
        <VmoButton primary>Create</VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default CloneDealModal
