import {
  TASKS_BY_STATUS,
  TASKS_BY_STATUS_OVER_TIME,
  TASKS_BY_TYPES,
  TASKS_BY_TYPES_OVER_TIME,
  TASK_BY_USERS,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case TASKS_BY_STATUS:
      return {
        ...state,
        tasksByStatusData: action.payload,
      }

    case TASKS_BY_STATUS_OVER_TIME:
      return {
        ...state,
        tasksByStatusOverTimeData: action.payload,
      }
    case TASKS_BY_TYPES:
      return {
        ...state,
        tasksByTypesData: action.payload,
      }
    case TASKS_BY_TYPES_OVER_TIME:
      return {
        ...state,
        tasksByTypesOverTimeData: action.payload,
      }
    case TASK_BY_USERS:
      return {
        ...state,
        tasksByUsersData: action.payload,
      }

    default:
      return state
  }
}
