/* eslint-disable no-case-declarations */
import {
  REMOVE_SOCIAL,
  UPDATE_SOCIAL,
  ADD_SOCIAL,
  GET_ALL_TAG_LABEL,
  GET_TAG_SETTING,
  EDIT_TAG_SETTING,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    // SOCIAL PART
    case ADD_SOCIAL:
      return {
        ...state,
        // singleLead:{...state.singleLead,socials:[...get(['singleLead','socials'],state),action.payload]},
      }

    case 'CLEAR_ADD_SOCIAL':
      return {...state, addSocialMsg: null}

    case UPDATE_SOCIAL:
      return {
        ...state,
      }

    case 'CLEAR_UPDATE_SOCIAL':
      return {...state, updateSocialMsg: null}

    case REMOVE_SOCIAL:
      return {
        ...state,
      }

    case 'CLEAR_REMOVE_SOCIAL':
      return {...state, removeSocialMsg: null}

    case GET_ALL_TAG_LABEL:
      return {...state, getTagList: action.payload}

    // case CREATE_TAG:
    //   const list = action.status === 200 ? [...state.getTagList, action.payload] : state.getTagList || []
    //   return {
    //     ...state,
    //     getTagList: list,
    //   }

    case 'CLEAR_CREATE_TAG':
      return {
        ...state,
        createTagMsg: null,
      }

    case GET_TAG_SETTING:
      return {
        ...state,
        getTagData: action.payload,
      }

    case EDIT_TAG_SETTING:
      return {
        ...state,
      }

    case 'CLEAR_TAG_SETTING':
      return {
        ...state,
        getTagMsg: null,
        editTagMsg: null,
      }

    default:
      return state
  }
}
