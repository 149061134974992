import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import {featureAccessDeniedContent, roleAccessDeniedContent} from 'constants/variables'
import React from 'react'
import {AiFillLock} from 'react-icons/ai'
import {VmoButton, VmoContainer, VmoDivider, VmoRadio, VmoTooltip} from 'vmo-library'

const SettingLayout = props => {
  const {
    header,
    subHeader,
    learnMoreUrl = '',
    actionButton,
    headerButton,
    children,
    paddingLeft,
    headerRadioButton = false,
    headerbackbtn = false,
    table = false,
    fullWidth = false,
    headerDivider = false,
    icon,
    lockRole = false,
    lockFeature = false,
  } = props

  const renderHeaderButton = () => {
    if (lockFeature || lockRole) {
      return (
        <VmoTooltip
          content={lockFeature ? featureAccessDeniedContent : roleAccessDeniedContent}
          position="top center"
          size="mini"
          trigger={<VmoButton className="disabled-button" primary {...headerButton} />}
        />
      )
    }
    return <VmoButton primary {...headerButton} />
  }

  const renderHeaderRadioButton = () => {
    if (lockFeature || lockRole) {
      return (
        <VmoTooltip
          content={lockFeature ? featureAccessDeniedContent : roleAccessDeniedContent}
          position="top center"
          size="mini"
          trigger={
            <div>
              <AiFillLock className="mr-2 permission-icon" />
              <VmoRadio toggle {...headerRadioButton} disabled />
            </div>
          }
        />
      )
    }
    return <VmoRadio toggle {...headerRadioButton} />
  }

  return (
    <div
      style={paddingLeft ? {paddingLeft: 0} : {}}
      className={`set-layout ${table ? 'set-layout-tabel' : ''} ${fullWidth ? 'set-layout-full' : ''}`}
    >
      <VmoContainer>
        <div className="settingHeader">
          {icon ? <div className="settingHeaderIcon">{icon}</div> : null}
          <div className="settingHeaderText">
            {header && (
              <>
                {headerbackbtn && (
                  <VmoButton className="backBtn" {...headerbackbtn}>
                    <SvgIcon path="common/left" /> Back
                  </VmoButton>
                )}
                <div className="title">
                  <div>
                    <h4>{header}</h4>
                  </div>
                  {headerButton && renderHeaderButton()}
                  {headerRadioButton && renderHeaderRadioButton()}
                </div>
              </>
            )}
            {(subHeader || learnMoreUrl) && (
              <p className="set-sub-header">
                {subHeader}
                {learnMoreUrl && (
                  <a href={learnMoreUrl} className="learn-more">
                    <SvgIcon path="common/book" /> learn more
                  </a>
                )}
              </p>
            )}
          </div>
        </div>
        {headerDivider && <VmoDivider />}
        <div className="set-my-profile">
          {children}
          {actionButton && (
            <div className="set-action-btn">
              {actionButton.cancel && <VmoButton basic {...actionButton.cancel} />}
              {actionButton.success && (
                <LockPermissionTooltip isFeatureAccessDenied={lockFeature} isRoleAccessDenied={lockRole}>
                  <VmoButton
                    primary
                    className={lockFeature || lockRole ? 'disabled-button' : ''}
                    {...actionButton.success}
                  />
                </LockPermissionTooltip>
              )}
            </div>
          )}
        </div>
      </VmoContainer>
    </div>
  )
}
export default SettingLayout
