/* eslint-disable no-nested-ternary */
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import {
  VmoAttachment,
  VmoButton,
  VmoButtonGroup,
  VmoCard,
  VmoCardContent,
  VmoChipInput,
  VmoDatePicker,
  VmoDropdown,
  VmoDropdownHeader,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoEditorSmall,
  VmoFormInput,
  VmoIcon,
  VmoInput,
  VmoLabel,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'
import {
  ADD_MESSAGE_TO_CONVERSATION,
  ATTACHMENT_POST,
  GET_MAIL_BOX,
  GET_SINGLE_TEMPLATE,
  GET_USER_TEMPLATES,
} from 'actions/types'
import {
  addMessageToConversation,
  deleteMessageOfConversation,
  getStatusList,
  getUserTemplates,
  singleUserTemplates,
} from 'actions/inbox'
import {uploadAttachments} from 'actions/settings_productivity'
import {extractTextContentFromHTML, get} from 'utils/helper'
import useEmailBody from 'hooks/pure/useEmailBody'
import AttachmentPreview from 'components/common/AttachmentPreview'
import SvgIcon from 'components/common/SvgIcon'

const {CancelToken} = axios
const requestToken = CancelToken.source()

export const InboxInLineComposeMail = props => {
  const {
    defaultFrom,
    defaultTo,
    defaultCC,
    defaultBCC,
    defaultSubject,
    defaultEditorValue,
    isDraftConversation,
    ConversationId,
    draftMessageId,
    fromInboxId,
    conversationType,
  } = props

  const dispatch = useDispatch()

  const {setEditorOpenFor} = useEmailBody()

  const [emailEditorValue, setEmailEditorValue] = useState(defaultEditorValue || '')
  const [emailEditorCurrentValue, setEmailEditorCurrentValue] = useState('')
  const [templateList, setTemplateList] = useState([])

  const [data, setData] = useState({
    sendLaterTime: new Date(),
    attachments: [],
    from: '',
    to: [],
    cc: [],
    bcc: [],
    sendTime: new Date(),
    subject: '',
  })

  const [toggleStates, setToggleStates] = useState({
    sendLater: false,
    isCCActive: false,
    isBCCActive: false,
    isSubjectActive: true,
    isSendLaterActive: false,
    moreCanOpen: false,
    timePicker: false,
  })

  const [defaultFontSize, setDefaultFontSize] = useState(14)
  const [defaultFontFamily, setDefaultFontFamily] = useState('Roboto')
  const [files, setFiles] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [timePickerDecider, setTimePickerDecider] = useState(null)

  const {type = [], isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {
    getUserTemplates: userTemplates = [],
    statusList = [],
    conversation = {},
  } = useSelector(state => state.emailInbox)
  const {singleResponse} = useSelector(state => state.settings)
  const {attachmentList = [], successData: attachmentSuccessData} = useSelector(state => state.attachment)
  const {getSingleInbox: inboxDetails = []} = useSelector(state => state.mailInbox)

  useEffect(() => {
    const newData = {...data}

    if (defaultFrom) {
      newData.from = defaultFrom
    }
    if (defaultTo) {
      newData.to = defaultTo
    }
    if (defaultCC) {
      newData.cc = defaultCC
    }
    if (defaultBCC) {
      newData.bcc = defaultBCC
    }
    if (defaultSubject) {
      newData.subject = defaultSubject
    }
    if (defaultEditorValue) {
      newData.editorValue = defaultEditorValue
      setEmailEditorCurrentValue(defaultEditorValue)
    }

    setData(newData)
  }, [
    JSON.stringify(defaultFrom),
    JSON.stringify(defaultTo),
    JSON.stringify(defaultCC),
    JSON.stringify(defaultBCC),
    JSON.stringify(defaultSubject),
  ])

  useEffect(() => {
    if (attachmentSuccessData === 'replyAttachment' && successLabels.includes(ATTACHMENT_POST)) {
      const newFiles = attachmentList.map(file => ({
        id: file.id,
        name: file.name,
        size: file.size,
      }))

      setData({
        ...data,
        attachments: [...data.attachments, ...newFiles],
      })
    }
  }, [successLabels, attachmentList, attachmentSuccessData, data])

  useEffect(() => {
    if (successLabels.includes(GET_USER_TEMPLATES)) {
      setTemplateList(userTemplates)
    }
  }, [successLabels, userTemplates])

  useEffect(() => {
    if (successLabels.includes(GET_MAIL_BOX)) {
      // Setting Signature in Compose Email Editor

      if (get(['settings', 'signatureEnabled'], inboxDetails, false) === true && inboxDetails.signature !== {}) {
        const editedSignature = `${emailEditorValue}<br><br>${inboxDetails.signature.body}`

        setEmailEditorValue(editedSignature)
      }

      // Checking if setting object is empty or not
      if (Object.keys(get(['settings'], inboxDetails, [])).length !== 0) {
        /// Setting Font of Inbox Setting

        if (inboxDetails.settings.fontSize !== null) {
          setDefaultFontSize(inboxDetails.settings.fontSize)
        }

        /// Setting Font of Inbox Setting
        if (inboxDetails.settings.fontFamily !== null) {
          setDefaultFontFamily(inboxDetails.settings.fontFamily)
        }
      } else {
        setDefaultFontSize(16)
        setDefaultFontFamily('Impact')
      }
    }
  }, [inboxDetails, successLabels, emailEditorValue])

  useEffect(() => {
    dispatch(getStatusList())
    return () => {
      setData({
        sendLaterTime: new Date(),
        attachments: [],
        from: '',
        to: [],
        cc: [],
        bcc: [],
        sendTime: new Date(),
        subject: '',
      })
      setToggleStates({
        sendLater: false,
        isCCActive: false,
        isBCCActive: false,
        isSubjectActive: false,
        isSendLaterActive: false,
        moreCanOpen: false,
        timePicker: false,
      })
    }
  }, [dispatch])

  const insertTemplateIntoEditor = (oldEditorState, setOldEditorState, newHtmlBody, placeholderValues) => {
    let newString = oldEditorState.concat(newHtmlBody) || ''
    const keysArray = Object.keys(placeholderValues)
    for (let i = 0; i < keysArray.length; i + 1) {
      newString = newString.split(`{{${keysArray[i]}}}`).join(placeholderValues[keysArray[i]])
    }
    setOldEditorState(newString)
  }

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_TEMPLATE)) {
      insertTemplateIntoEditor(
        emailEditorCurrentValue,
        setEmailEditorValue,
        singleResponse.body,
        get(['placeholderValues'], singleResponse)
      )
      setData({
        ...data,
        attachments: [...data.attachments, ...get(['attachments'], singleResponse, [])],
      })
    }
  }, [successLabels, data, singleResponse, emailEditorCurrentValue])

  const getStatusId = (list, status) => {
    return list.find(s => s.status === status).id
  }

  const sendMail = (status, draft, messageId, isSendLaterActive, toastMsg) => {
    const payload = {
      isDraft: draft,
      inboxId: fromInboxId,
      messageHtml: emailEditorCurrentValue,
      messageText: extractTextContentFromHTML(emailEditorCurrentValue),
    }

    if (['email', 'support_form', 'support_widget', 'internal_ticket', 'helpdesk'].includes(conversationType)) {
      payload.to = data.to.map(mail => ({
        email: mail,
      }))
      payload.cc = data.cc.map(mail => ({
        email: mail,
      }))
      payload.bcc = data.bcc.map(mail => ({
        email: mail,
      }))
    }

    if (messageId) {
      payload.messageId = messageId
    }

    if (files.length > 0) {
      payload.attachments = uploadedFiles.map(file => file.id)
    }

    if (isSendLaterActive) {
      payload.sendLater = true
      payload.sendTime = data.sendLaterTime
    }
    if (status) {
      payload.statusId = status
    }

    dispatch(addMessageToConversation(payload, ConversationId, toastMsg))
  }

  const handleSendMail = () => {
    if (_.isEqual(data.to, []) || emailEditorCurrentValue === '') {
      return true
    }
    return false
  }

  const date = moment(new Date())
  const roundUp =
    date.minute() || date.second() || date.millisecond() ? date.add(1, 'hour').startOf('hour') : date.startOf('hour')

  const renderSnooze = () => {
    if (toggleStates.timePicker) {
      return (
        <VmoModal
          centered={false}
          size="mini"
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          open={toggleStates.timePicker}
          onClose={() =>
            setToggleStates({
              ...toggleStates,
              timePicker: false,
            })
          }
        >
          <VmoModalHeader>
            {timePickerDecider === 'replyLater'
              ? 'Reply Later'
              : timePickerDecider === 'snooze'
              ? 'Snooze Date and Time'
              : ''}
          </VmoModalHeader>
          <VmoModalContent>
            <VmoDatePicker
              min={roundUp.toDate()}
              value={data.sendLaterTime}
              step={60}
              onChange={value => {
                if (timePickerDecider === 'replyLater') {
                  setData({
                    ...data,

                    sendLaterTime: value,
                  })
                }
              }}
            />
          </VmoModalContent>
          <VmoModalActions>
            <VmoButton
              color="light"
              onClick={() =>
                setToggleStates({
                  ...toggleStates,
                  timePicker: false,
                })
              }
            >
              Cancel
            </VmoButton>
            <VmoButton
              color="primary"
              onClick={() => {
                if (timePickerDecider === 'replyLater') {
                  setToggleStates({
                    ...toggleStates,
                    isSendLaterActive: true,
                  })

                  const draft = false
                  const id = null
                  const isSendLaterActive = true
                  sendMail(null, draft, id, isSendLaterActive, 'Mail Scheduled')
                }
                setToggleStates({...toggleStates, timePicker: true})
              }}
            >
              Send Later
            </VmoButton>
          </VmoModalActions>
        </VmoModal>
      )
    }
  }

  const searchFromTemplateList = searchString => {
    const updatedList = templateList.filter(template => template.subject.includes(searchString))
    setTemplateList(updatedList)
    if (searchString === '') {
      setTemplateList(userTemplates)
    }
  }

  const renderEmailInfo = () => {
    if (['email', 'support_form', 'support_widget', 'internal_ticket', 'helpdesk'].includes(conversationType)) {
      return (
        <>
          <div className="emailInput">
            <ul>
              <li>
                <div className="emlEdrHdr">From : &nbsp; </div>
                <VmoLabel>{data.from}</VmoLabel>
              </li>
              <li>
                <div className="emlEdrHdr">To :</div>

                <VmoChipInput defaultValue={data.to} onChange={value => setData({...data, to: value})} />

                {!toggleStates.isCCActive && (
                  <VmoButton
                    onClick={() =>
                      setToggleStates({
                        ...toggleStates,
                        isCCActive: true,
                      })
                    }
                    color="light"
                  >
                    Cc
                  </VmoButton>
                )}
                {!toggleStates.isBCCActive && (
                  <VmoButton
                    onClick={() =>
                      setToggleStates({
                        ...toggleStates,
                        isBCCActive: true,
                      })
                    }
                    color="light"
                  >
                    Bcc
                  </VmoButton>
                )}
                {!toggleStates.isSubjectActive && (
                  <VmoButton
                    onClick={() =>
                      setToggleStates({
                        ...toggleStates,
                        isSubjectActive: true,
                      })
                    }
                  >
                    Subject
                  </VmoButton>
                )}
              </li>
              {toggleStates.isCCActive && (
                <li>
                  <div className="emlEdrHdr">cc :</div>
                  <VmoChipInput defaultValue={data.cc} onChange={value => setData({...data, cc: value})} />
                </li>
              )}
              {toggleStates.isBCCActive && (
                <li>
                  <div className="emlEdrHdr">bcc :</div>
                  <VmoChipInput defaultValue={data.bcc} onChange={value => setData({...data, bcc: value})} />
                </li>
              )}
              {toggleStates.isSubjectActive && (
                <li>
                  <div className="emlEdrHdr">Subject :</div>
                  <VmoInput value={data.subject} onChange={e => setData({...data, subject: e.target.value})} />
                </li>
              )}
            </ul>
          </div>
          <VmoEditorSmall
            setContents={emailEditorValue}
            onChange={content => {
              setEmailEditorCurrentValue(content)
            }}
            setDefaultStyle={`font-size: ${defaultFontSize}px; font-family: ${defaultFontFamily};`}
          />
        </>
      )
    }
    return (
      <div className="emailInput px-3 pt-3">
        <VmoFormInput
          fluid
          placeholder="Type here..."
          defaultValue={data.editorValue}
          onChange={e => setEmailEditorCurrentValue(e.target.value)}
        />
        <span className="email-char"> {emailEditorCurrentValue.length} Characters </span>
      </div>
    )
  }

  return (
    <div className="composeEmail compEmail-inBody">
      <VmoCard fluid>
        <VmoCardContent className="p-0">
          {renderEmailInfo()}
          <div className="replyMailFooterBtn">
            {conversation && conversation?.type !== 'sms' && (
              <div className="d-flex">
                <VmoTooltip
                  position="top center"
                  content="Delete"
                  size="mini"
                  trigger={
                    <VmoButton
                      icon={<SvgIcon path="common/delete" />}
                      id="tooltipEditorDelete"
                      onClick={() => {
                        if (isDraftConversation) {
                          dispatch(deleteMessageOfConversation(ConversationId, draftMessageId))
                        }
                        setEditorOpenFor('')
                      }}
                    />
                  }
                />
                <AttachmentPreview
                  files={files}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  success="replyAttachment"
                  cancelToken={requestToken}
                />
                <VmoTooltip
                  position="bottom center"
                  size="mini"
                  content="Attachment"
                  trigger={
                    <VmoAttachment
                      content={<SvgIcon path="common/attachment" />}
                      multiple
                      onChange={e => {
                        const data = {
                          file: e,
                        }
                        if (e.length > 0) {
                          dispatch(uploadAttachments(data, 'replyAttachment', requestToken))
                        }
                      }}
                      onClick={e => {
                        e.target.value = null
                      }}
                      fileList={files => {
                        setFiles(files)
                      }}
                    />
                  }
                />
                <VmoDropdown
                  icon={
                    <VmoTooltip
                      size="mini"
                      position="bottom center"
                      content="Email Template"
                      trigger={<VmoIcon name="list" />}
                    />
                  }
                  button
                  direction="right"
                  floating
                  onClick={() => dispatch(getUserTemplates())}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 32,
                    width: 32,
                  }}
                  className="icon emailTemDrop"
                  upward
                >
                  <VmoDropdownMenu>
                    <VmoDropdownHeader>Insert Email Tempelates</VmoDropdownHeader>
                    <VmoInput
                      type="search"
                      name="search"
                      id="search"
                      icon="search"
                      iconPosition="left"
                      placeholder="Search email templates... "
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onChange={e => {
                        searchFromTemplateList(e.target.value)
                      }}
                    />
                    {templateList && templateList[0] ? (
                      templateList.map(temp => (
                        <VmoDropdownItem
                          key={temp.id}
                          onClick={() => {
                            if (isDraftConversation) {
                              dispatch(
                                singleUserTemplates(
                                  get(['id'], temp, '-'),
                                  ConversationId || null,
                                  'templateListCompose'
                                )
                              )
                            } else {
                              dispatch(singleUserTemplates(get(['id'], temp, '-'), null, 'templateListCompose'))
                            }
                          }}
                        >
                          {get(['subject'], temp, '-')}
                        </VmoDropdownItem>
                      ))
                    ) : (
                      <p className="pb-2">No Data Available</p>
                    )}{' '}
                  </VmoDropdownMenu>
                </VmoDropdown>
              </div>
            )}
            <div>
              <VmoButton
                basic
                className="mr-3"
                onClick={() => {
                  const draft = true

                  sendMail(null, draft, draftMessageId, toggleStates.isSendLaterActive, 'Saved as Draft')
                }}
                disabled={handleSendMail() || (isLoadingData && type.includes(ADD_MESSAGE_TO_CONVERSATION))}
              >
                Save as Draft
              </VmoButton>
              <VmoButtonGroup color="primary">
                <VmoButton
                  loading={isLoadingData && type.includes(ADD_MESSAGE_TO_CONVERSATION)}
                  id="caret"
                  onClick={() => {
                    const draft = false

                    sendMail(null, draft, draftMessageId, toggleStates.isSendLaterActive, 'Email Sent')
                  }}
                  disabled={handleSendMail() || (isLoadingData && type.includes(ADD_MESSAGE_TO_CONVERSATION))}
                >
                  Reply
                </VmoButton>
                <VmoDropdown
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  icon="chevron down"
                  upward
                  className="button icon"
                  floating
                  disabled={data.to.length === 0 || emailEditorCurrentValue === ''}
                >
                  <VmoDropdownMenu>
                    <VmoDropdownItem
                      onClick={() => {
                        const status = getStatusId(statusList, 'Closed')

                        const draft = false

                        sendMail(status, draft, draftMessageId, toggleStates.isSendLaterActive, 'Email Sent and Closed')
                      }}
                    >
                      Reply & Close
                    </VmoDropdownItem>
                    <VmoDropdownItem
                      onClick={() => {
                        setTimePickerDecider('replyLater')
                        setToggleStates({
                          ...toggleStates,
                          isSendLaterActive: true,
                        })
                        setToggleStates({
                          ...toggleStates,
                          timePicker: true,
                        })
                      }}
                    >
                      Send Later
                    </VmoDropdownItem>
                  </VmoDropdownMenu>
                </VmoDropdown>
              </VmoButtonGroup>
            </div>
          </div>
        </VmoCardContent>
      </VmoCard>
      {renderSnooze()}
    </div>
  )
}
