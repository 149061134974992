import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  VmoButton,
  VmoChipInput,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'

import {companyMultipleUpdate} from 'actions/company'
import {createTagSettings} from 'actions/multiple_fields'
import EmailCreateMultiple from 'components/common/EmailCreateMultiple'
import PhoneCreateMultiple from 'components/common/PhoneCreateMultiple'
import WebsiteCreateMultiple from 'components/common/WebsiteCreateMultiple'
import KadenceCustomDropdown from 'components/common/KadenceCustomDropdown'
import CreateLabel from 'components/common/CreateLabel'
import {get, removeDoubleQuotes, KadenceCountryList} from 'utils/helper'
import {multipleUpdateCompanySchema} from 'validation/Customers/company.schema'
import SvgIcon from 'components/common/SvgIcon'

const EditCompanyModal = props => {
  const {isModalOpen, setIsModalOpen, checked} = props
  const dispatch = useDispatch()

  const [userOption, setUserOption] = useState([])
  const [showInputs, setShowInputs] = useState({})
  const [createInfo, setCreateInfo] = useState({})
  const [labelOptionsState, setLabelOptionsState] = useState([])
  const [newLabelModal, setNewLabelModal] = useState(false)
  const [listOfLable, setListOfLabel] = useState([])
  const [contactTypeList, setContactTypeList] = useState([])

  const {getUsersList = {}} = useSelector(state => state.settings)

  const {getTypeListData} = useSelector(state => state.companies)

  const {getTagList} = useSelector(state => state.multipleFields)

  const initialCreateInfo = {
    emails: [{category: 'work', email: ''}],
    phones: [{category: 'work', phone: ''}],
    websites: [{category: 'work', url: ''}],
    address: {},
    contactTypeId: null,
    assigneeId: null,
    description: null,
    labelIds: [],
  }
  const {handleSubmit, errors, control, reset} = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(multipleUpdateCompanySchema),
    defaultValues: initialCreateInfo,
  })
  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: 'emails',
  })
  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: 'phones',
  })
  const {
    fields: websiteFields,
    append: appendWebsite,
    remove: removeWebsite,
  } = useFieldArray({
    control,
    name: 'websites',
  })

  useEffect(() => {
    return () => reset(initialCreateInfo)
  }, [])

  useEffect(() => {
    if (getTypeListData) {
      const list = getTypeListData.map(item => ({
        value: item.id,
        text: item.type,
        key: item.id,
      }))
      setContactTypeList(list)
    }
  }, [getTypeListData])
  useEffect(() => {
    if (getTagList) {
      const list = getTagList.map(user => ({
        value: user.id,
        text: user.label,
        key: user.id,
        label: {
          style: {background: user.color},
          empty: true,
          circular: true,
        },
      }))
      setLabelOptionsState(list)
    }
  }, [getTagList])

  useEffect(() => {
    if (getUsersList) {
      const list = getUsersList.users.map(user => ({
        value: user.id,
        text: `${get(['firstName'], user, '')} ${get(['lastName'], user, '')}`,
        key: user.id,
      }))
      setUserOption(list)
    }
  }, [getUsersList])

  useEffect(() => {
    setCreateInfo({})
    setShowInputs({
      email: false,
      phone: false,
      website: false,
      address: false,
      tags: false,
      contactType: false,
      assignee: false,
      description: false,
    })
  }, [isModalOpen])

  const createNewLabel = () => {
    if (newLabelModal) {
      return (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
        />
      )
    }
  }

  const handleMultipleUpdateCompany = data => {
    const getNewData = _.pickBy(
      {
        ...data,
        emails: _.filter(_.get(data, 'emails'), item => item.email !== '' && item.email !== undefined),
        phones: _.filter(_.get(data, 'phones'), item => item.phone !== '' && item.phone !== undefined),
        websites: _.filter(_.get(data, 'websites'), item => item.url !== '' && item.url !== undefined),
      },
      _.identity
    )
    const idData = {ids: checked, ...getNewData, ...createInfo}
    dispatch(companyMultipleUpdate(idData))
  }

  return (
    <VmoModal
      className="kadence-modal"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <VmoModalHeader>Edit Company Details</VmoModalHeader>
      <VmoModalContent scrolling className="vmo-scroll">
        <h4 className="pb-3">Selected Company ({checked.length})</h4>
        <VmoForm className="vmo-form">
          <VmoFormField>
            <label className="d-block">Email</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() => setShowInputs({...showInputs, email: !showInputs.email})}
            >
              Click to edit all
            </VmoButton>
            {showInputs.email && (
              <EmailCreateMultiple
                emailFields={emailFields}
                errors={errors}
                appendEmail={appendEmail}
                control={control}
                removeEmail={removeEmail}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Phone</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() => setShowInputs({...showInputs, phone: !showInputs.phone})}
            >
              Click to edit all
            </VmoButton>
            {showInputs.phone && (
              <PhoneCreateMultiple
                phoneFields={phoneFields}
                errors={errors}
                appendPhone={appendPhone}
                control={control}
                removePhone={removePhone}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Website</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() => setShowInputs({...showInputs, website: !showInputs.website})}
            >
              Click to edit all
            </VmoButton>
            {showInputs.website && (
              <WebsiteCreateMultiple
                websiteFields={websiteFields}
                errors={errors}
                appendWebsite={appendWebsite}
                control={control}
                removeWebsite={removeWebsite}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Tags</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() => setShowInputs({...showInputs, tags: !showInputs.tags})}
            >
              Click to edit all
            </VmoButton>
            {showInputs.tags && (
              <>
                <Controller
                  control={control}
                  name="labelIds"
                  render={({value, onChange}) => (
                    <VmoChipInput
                      defaultValue={listOfLable.map(tag => {
                        return {
                          id: tag.value,
                          text: (
                            <>
                              <div
                                style={{
                                  height: '10px',
                                  width: '10px',
                                  background: tag.label.style.background,
                                  borderRadius: '50%',
                                  marginRight: '10px',
                                  display: 'inline-block',
                                }}
                              />
                              {tag.text}
                            </>
                          ),
                        }
                      })}
                      onChange={value => {
                        onChange(value)
                        const valueIds = value.map(tag => tag.id)
                        const removeTag = listOfLable.filter(contact => valueIds.indexOf(contact.value) !== -1)
                        setListOfLabel(removeTag)
                        const id = _.map(removeTag, 'value')
                        setCreateInfo({...createInfo, labelIds: id})
                      }}
                      hideInputField
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="labelIds"
                  render={({value, onChange}) => (
                    <KadenceCustomDropdown
                      options={labelOptionsState}
                      multiple
                      dropdownProps={{
                        icon: (
                          <VmoTooltip
                            content="Tags"
                            size="mini"
                            position="bottom center"
                            trigger={<SvgIcon path="common/tag" id="TooltipTagInbox" />}
                          />
                        ),
                        direction: 'right',
                        closeOnChange: false,
                      }}
                      menu={{scrolling: true}}
                      input={{
                        icon: 'search',
                        iconPosition: 'left',
                        className: 'search',
                      }}
                      activeIndicator
                      value={listOfLable.map(labels => labels.value)}
                      onClick={value => {
                        onChange(value)
                        const updatedList = labelOptionsState.filter(val => value.indexOf(val.value) !== -1)
                        setListOfLabel(updatedList)
                      }}
                      extraAction={{
                        content: '+ Add Tag',
                        onClick: () => setNewLabelModal(true),
                      }}
                    />
                  )}
                />
              </>
            )}
          </VmoFormField>

          <VmoFormField>
            <label className="d-block">Assignee</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  assignee: !showInputs.assignee,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.assignee && (
              <Controller
                name="assigneeId"
                control={control}
                render={({onChange, value}) => (
                  <VmoFormSelect
                    placeholder="Assignee"
                    fluid
                    selection
                    value={value}
                    options={userOption}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                  />
                )}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Contact Type</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  contactType: !showInputs.contactType,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.contactType && (
              <Controller
                name="contactTypeId"
                control={control}
                render={({onChange, value}) => (
                  <VmoFormSelect
                    placeholder="Contact Type"
                    options={contactTypeList}
                    fluid
                    search
                    selection
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                  />
                )}
              />
            )}
          </VmoFormField>

          <VmoFormField>
            <label className="d-block">Address</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  address: !showInputs.address,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.address && (
              <>
                <Controller
                  name="address.streetAddress"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      value={value}
                      placeholder="Write your address"
                      className="mb-2"
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
                <Controller
                  name="address.locality"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      value={value}
                      placeholder="locality"
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
                <Controller
                  name="address.state"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      value={value}
                      placeholder="state"
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
                <Controller
                  name="address.country"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormSelect
                      search
                      fluid
                      value={value}
                      options={KadenceCountryList}
                      selection
                      placeholder="Country"
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
                <Controller
                  name="address.postalCode"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      placeholder="zipcode"
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.address?.postalCode && {
                          content: removeDoubleQuotes(errors.address?.postalCode.message),
                        }
                      }
                    />
                  )}
                />
              </>
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Description</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  description: !showInputs.description,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.description && (
              <Controller
                name="description"
                control={control}
                render={({onChange, value}) => (
                  <VmoFormTextArea
                    type="textarea"
                    value={value}
                    placeholder="Write your description here"
                    onChange={(e, {value}) => onChange(value)}
                  />
                )}
              />
            )}
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(handleMultipleUpdateCompany)}>
          Save
        </VmoButton>
      </VmoModalActions>
      {createNewLabel()}
    </VmoModal>
  )
}

export default EditCompanyModal
