import React, {useEffect, useState} from 'react'
import {VmoButton, VmoTab, VmoTabPane} from 'vmo-library'
import {useParams} from 'react-router-dom/cjs/react-router-dom.min'
import {useDispatch, useSelector} from 'react-redux'
import {
  COMPLETE_PROJECT_FINANCE_BILLING_INVOICE,
  CREATE_PROJECT_FINANCE_BILLING_INVOICE,
  CREATE_PROJECT_FINANCE_EXPENSE,
  DELETE_PROJECT_FINANCE_BILLING_INVOICE,
  REOPEN_PROJECT_FINANCE_BILLING_INVOICE,
} from 'actions/types'
import {
  getProjectFinanceBillingCompletedInvoicesList,
  getProjectFinanceBillingExpenseList,
  getProjectFinanceBillingOpenInvoicesList,
  getProjectFinanceBillingUnbilledTimeList,
} from 'actions/projects'
import ProjectBillingExpenseModal from './ProjectBillingExpenseModal'
import ProjectBillingInvoiceModal from './ProjectBillingInvoiceModal'
import ProjectBillingOpenInvoices from './OpenInvoices/ProjectBillingOpenInvoices'
import ProjectBillingCompletedInvoices from './CompletedInvoices/ProjectBillingCompletedInvoices'

const ProjectBilling = ({projectId, budgetId}) => {
  const dispatch = useDispatch()

  const [activeIndex, setActiveIndex] = useState(0)
  const [isBillingExpenseModalOpen, setIsBillingExpenseModalOpen] = useState(false)
  const [isBillingInvoiceModalOPen, setIsBillingInvoiceModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {billingCompletedInvoicesList = []} = useSelector(state => state.projects)

  useEffect(() => {
    if (projectId && budgetId) {
      dispatch(getProjectFinanceBillingUnbilledTimeList({projectId, budgetId}))
      dispatch(getProjectFinanceBillingExpenseList({projectId, budgetId}))
      dispatch(getProjectFinanceBillingOpenInvoicesList({projectId, budgetId}))
      dispatch(getProjectFinanceBillingCompletedInvoicesList({projectId, budgetId}))
    }
  }, [dispatch, projectId, budgetId])

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECT_FINANCE_EXPENSE)) {
      dispatch(getProjectFinanceBillingExpenseList({projectId, budgetId}))
      setIsBillingExpenseModalOpen(false)
    }
    if (successLabels.includes(CREATE_PROJECT_FINANCE_BILLING_INVOICE)) {
      setIsBillingInvoiceModalOpen(false)
      dispatch(getProjectFinanceBillingOpenInvoicesList({projectId, budgetId}))
    }
    if (successLabels.includes(DELETE_PROJECT_FINANCE_BILLING_INVOICE)) {
      if (billingCompletedInvoicesList.length === 1) {
        setActiveIndex(0)
      }
    }
    if (successLabels.includes(COMPLETE_PROJECT_FINANCE_BILLING_INVOICE)) {
      dispatch(getProjectFinanceBillingCompletedInvoicesList({projectId, budgetId}))
    }
    if (successLabels.includes(REOPEN_PROJECT_FINANCE_BILLING_INVOICE)) {
      setActiveIndex(0)
      dispatch(getProjectFinanceBillingOpenInvoicesList({projectId, budgetId}))
    }
  }, [successLabels, dispatch, projectId, budgetId])

  const panes = [
    {
      menuItem: {key: 'openInvoices', icon: 'folder open', content: 'Open Invoices'},
      render: () => {
        return (
          <VmoTabPane attached={false}>
            <ProjectBillingOpenInvoices projectId={projectId} budgetId={budgetId} />
          </VmoTabPane>
        )
      },
    },
    ...(billingCompletedInvoicesList.length
      ? [
          {
            menuItem: {key: 'completedInvoices', icon: 'window close', content: 'Completed Invoices'},
            render: () => {
              return (
                <VmoTabPane attached={false}>
                  <ProjectBillingCompletedInvoices projectId={projectId} budgetId={budgetId} />
                </VmoTabPane>
              )
            },
          },
        ]
      : []),
  ]

  return (
    <div className="kadence-side-wrap">
      <div className="project-finance-billing">
        <VmoTab
          panes={panes}
          menu={{color: 'blue', secondary: true, pointing: true}}
          activeIndex={activeIndex}
          onTabChange={(e, data) => setActiveIndex(data.activeIndex)}
        />
        <div className="project-finance-billing-action">
          <VmoButton basic onClick={() => setIsBillingExpenseModalOpen(true)}>
            Add Expense
          </VmoButton>
          <VmoButton primary onClick={() => setIsBillingInvoiceModalOpen(true)}>
            Add Invoice
          </VmoButton>
        </div>
      </div>

      {isBillingExpenseModalOpen && (
        <ProjectBillingExpenseModal
          open={isBillingExpenseModalOpen}
          toggle={setIsBillingExpenseModalOpen}
          projectId={projectId}
          budgetId={budgetId}
        />
      )}

      {isBillingInvoiceModalOPen && (
        <ProjectBillingInvoiceModal
          open={isBillingInvoiceModalOPen}
          toggle={setIsBillingInvoiceModalOpen}
          projectId={projectId}
          budgetId={budgetId}
        />
      )}
    </div>
  )
}

export default ProjectBilling
