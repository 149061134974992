import {
  GET_PROJECTS_PLANNING_OVERVIEW_STATS_BY_USER,
  GET_PROJECTS_PLANNING_OVERVIEW_TIMEWISE_STATS,
  GET_PROJECTS_PLANNING_WORKLOADS_LIST,
} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case GET_PROJECTS_PLANNING_OVERVIEW_TIMEWISE_STATS:
      return {...state, projectsPlanningOverviewTimewiseStats: payload || []}

    case GET_PROJECTS_PLANNING_OVERVIEW_STATS_BY_USER:
      return {...state, projectsPlanningOverviewStatsByUser: payload || {}}

    case GET_PROJECTS_PLANNING_WORKLOADS_LIST:
      return {...state, projectsPlanningWorkloadsList: payload || []}

    default:
      return state
  }
}
