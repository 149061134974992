import {GET_ALL_SEQUENCES, GET_SEQUENCE, SEQUENCE_CONTACTS, SEQUENCE_EMAILS, SEQUENCE_OVERVIEW} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_SEQUENCES:
      return {
        ...state,
        sequencesList: action.payload,
      }
    case GET_SEQUENCE:
      return {...state, sequenceData: action.payload}

    case SEQUENCE_CONTACTS:
      return {...state, sequenceContactData: action.payload}

    case SEQUENCE_OVERVIEW:
      return {...state, sequenceOverviewData: action.payload}

    case SEQUENCE_EMAILS:
      return {...state, sequenceEmailData: action.payload}

    default:
      return state
  }
}
