import Joi from 'joi'

export const createForecastSchema = Joi.object({
  name: Joi.string().required().trim().max(30).messages({
    'string.empty': 'Forecast Name is not allowed to be empty',
    'string.max': 'Forecast Name is allowed to be less than or equal to 30 characters',
  }),
  selection: Joi.string().required(),
  assigneeId: Joi.any().when('selection', {
    is: 'user',
    then: Joi.number().required().messages({
      'number.base': 'Assignee must be specified',
    }),
    otherwise: Joi.optional(),
  }),
  teamId: Joi.any().when('selection', {
    is: 'team',
    then: Joi.number().required().messages({
      'any.required': 'Team is not allowed to be empty',
    }),
    otherwise: Joi.optional(),
  }),
  kpi: Joi.string().required().messages({
    'string.empty': 'KPI must be specified',
  }),
  target: Joi.number().required().min(1).max(9999),
  pipelineId: Joi.number().allow(null, ''),
  timePeriod: Joi.string().allow(null, ''),
  startFrom: Joi.string().allow(null, ''),
  isRecurring: Joi.bool(),
  recurringTime: Joi.number().allow(null, ''),
})
