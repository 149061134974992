import {apiAction} from './api'
import {KADENCE_EMPLOYEES} from './endpoint'
import {
  ADD_DEPARTMENT,
  GET_DEPARTMENT,
  DELETE_DEPARTMENT,
  GET_SINGLE_DEPARTMENT,
  EDIT_DEPARTMENT,
  GET_EMPLOYEES_SETTING,
  UPDATE_EMPLOYEES_SETTING,
  CREATE_EMPLOYEE,
  GET_ALL_EMPLOYEES,
  DELETE_EMPLOYEES,
  GET_SINGLE_EMPLOYEE,
  UPDATE_EMPLOYEES,
  RESEND_EMPLOYEE_INVITATION,
} from './types'

export function addDepartment(data) {
  return apiAction({
    url: `${KADENCE_EMPLOYEES}/department`,
    method: 'POST',
    label: ADD_DEPARTMENT,
    data,
    showToast: true,
    successMessage: 'Department Added',
  })
}

export function getDepartment() {
  return apiAction({
    url: `${KADENCE_EMPLOYEES}/departments`,
    method: 'GET',
    label: GET_DEPARTMENT,
  })
}

export function deleteDepartment(id) {
  return apiAction({
    url: `${KADENCE_EMPLOYEES}/department/${id}`,
    method: 'DELETE',
    label: DELETE_DEPARTMENT,
    showToast: true,
    successMessage: 'Department Deleted',
  })
}

export function getSingleDepartment(id) {
  return apiAction({
    url: `${KADENCE_EMPLOYEES}/department/${id}`,
    method: 'GET',
    label: GET_SINGLE_DEPARTMENT,
  })
}

export function editDepartment(id, data) {
  return apiAction({
    url: `${KADENCE_EMPLOYEES}/department/${id}`,
    method: 'PUT',
    label: EDIT_DEPARTMENT,
    data,
    showToast: true,
    successMessage: 'Department Updated',
  })
}

// General Setting
export function getEmployeeSetting() {
  return apiAction({
    url: `${KADENCE_EMPLOYEES}/setting`,
    method: 'GET',
    label: GET_EMPLOYEES_SETTING,
  })
}

export function editEmployeeSetting(data) {
  return apiAction({
    url: `${KADENCE_EMPLOYEES}/setting`,
    method: 'PUT',
    label: UPDATE_EMPLOYEES_SETTING,
    data,
    showToast: true,
    successMessage: 'Updated',
  })
}
// end setting

export function createEmployees(data) {
  return apiAction({
    url: `${KADENCE_EMPLOYEES}`,
    method: 'POST',
    label: CREATE_EMPLOYEE,
    data,
    showToast: true,
    successMessage: 'Employees Added',
  })
}

export function getAllEmployees(params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params || {})
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }
  return apiAction({
    url: KADENCE_EMPLOYEES + query,
    method: 'GET',
    label: GET_ALL_EMPLOYEES,
  })
}

export function deleteEmployee(id) {
  return apiAction({
    url: `${KADENCE_EMPLOYEES}/${id}`,
    method: 'DELETE',
    label: DELETE_EMPLOYEES,
    showToast: true,
    successMessage: 'Employee Deleted',
  })
}

export function getSingleEmployee(id) {
  return apiAction({
    url: `${KADENCE_EMPLOYEES}/${id}`,
    method: 'GET',
    label: GET_SINGLE_EMPLOYEE,
  })
}

export function updateEmployee(id, data) {
  return apiAction({
    url: `${KADENCE_EMPLOYEES}/${id}`,
    method: 'PUT',
    label: UPDATE_EMPLOYEES,
    data,
    showToast: true,
    successMessage: 'Employee Updated',
  })
}

export function resendEmployeeInvitation(id) {
  return apiAction({
    url: `${KADENCE_EMPLOYEES}/${id}/resend-invitation`,
    method: 'GET',
    label: RESEND_EMPLOYEE_INVITATION,
    showToast: true,
    successMessage: 'Invitation sent',
  })
}
