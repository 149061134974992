import Joi from 'joi'
import {inputStringValidation} from 'validation'

export const smsSchema = Joi.object({
  name: inputStringValidation.required(),
  assigneeIds: Joi.array().items(Joi.alternatives(Joi.number(), Joi.string())).min(1).required().label('assignees'),
  provider: inputStringValidation.required(),
  webhookUrl: Joi.string().when('provider', {
    is: 'custom_sms',
    then: Joi.string().required().label('webhook url'),
    otherwise: Joi.string().allow('', null),
  }),
  accountSID: Joi.any()
    .when('provider', {
      is: 'twilio',
      then: Joi.string().required(),
    })
    .label('auth key'),
  authToken: Joi.string().required().label('auth token'),
  phoneNumber: Joi.string()
    .trim()
    .max(20)
    .regex(/^\+[0-9]{4,20}$/)
    .label('phone number')
    .required()
    .messages({
      'string.pattern.base': 'Enter a valid phone number',
      'string.empty': 'Phone Number is required',
      'string.max': `Length must be less than or equal to 20 characters`,
    }),
  sid: Joi.string().when('provider', {
    is: 'twilio',
    then: Joi.string().required(),
  }),
  autoResolvedDays: Joi.number(),
  threadingInterval: Joi.number().messages({
    'number.base': 'Threading Interval Must be specified',
  }),
})
