/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoContainer,
  VmoDatePicker,
  VmoDivider,
  VmoDropdown,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoInput,
  VmoLabel,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
} from 'vmo-library'
import {GET_ALL_EMPLOYEES, GET_DEPARTMENT, GET_REVEIWER_LIST, SEND_APPRAISAL} from 'actions/types'
import {getReveiwerList, getSingleAppraisalTemplate, sendAppraisal} from 'actions/appraisal'
import {getAllEmployees, getDepartment} from 'actions/employees'
import {getFormattedDate, removeDoubleQuotes} from 'utils/helper'
import SearchComponent from 'components/common/SearchComponent'
import {sendAppraisalSchema} from 'validation/Hr/appraisal.schema'
import useApiResponse from 'hooks/impure/useApiResponse'

const AppraisalSend = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id: editId} = useParams()

  const [ReveiwerOption, setReveiwerOption] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDateForEmployee, setEndDateForEmployee] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [ids, setIds] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  const [currentListParams, setCurrentListParams] = useState({
    department: '',
    text: '',
  })

  const {appraisalTemplateData} = useSelector(state => state.appraisal)
  const {successLabels = [], isLoadingData, type} = useSelector(state => state.apiReducer)

  const initialCreateInfo = {
    flow: '',
    reviewerId: null,
    isPrivate: false,
    setNotification: false,
    employeeIds: [],
  }

  const {errors, control, watch, setValue, handleSubmit} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(sendAppraisalSchema),
    defaultValues: initialCreateInfo,
  })

  const watchSetNotification = watch('setNotification')

  useEffect(() => {
    dispatch(getSingleAppraisalTemplate(editId))
  }, [dispatch, editId])

  useEffect(() => {
    if (successLabels.includes(SEND_APPRAISAL)) {
      history.push(`/appraisal/templates`)
    }
  }, [successLabels, history, dispatch])

  useApiResponse({
    action: getDepartment,
    enabled: true,
    label: GET_DEPARTMENT,
    storePath: 'employees.getDepartmentData',
    onSuccess: getDepartmentData => {
      const list = getDepartmentData.map(department => ({
        value: department.id,
        text: department.department,
        id: department.id,
      }))
      setDepartmentList(list)
    },
  })

  useApiResponse({
    action: getReveiwerList,
    enabled: true,
    label: GET_REVEIWER_LIST,
    storePath: 'appraisal.reveiwerList',
    onSuccess: reveiwerList => {
      const list = reveiwerList.map(employee => ({
        value: employee.id,
        text: `${employee.name}(${employee.employeeID})`,
        id: employee.id,
      }))
      setReveiwerOption(list)
    },
  })

  const {data: allEmployeesList = []} = useApiResponse({
    action: getAllEmployees,
    enabled: true,
    label: GET_ALL_EMPLOYEES,
    storePath: 'employees.allEmployeesList',
    onSuccess: () => {
      const list = allEmployeesList.map(({id}) => id)
      setIds(list)
    },
  })

  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getAllEmployees(newCurrentListParams))
  }, [currentListParams, dispatch])

  const handleCheck = id => {
    const isChecked = ids.some(elpoyeeId => elpoyeeId === id)

    if (isChecked) {
      const removeChecked = ids.filter(elpoyeeId => elpoyeeId !== id)
      setIds(removeChecked)
    } else {
      setIds([...ids, id])
    }
  }

  const isChecked = id => {
    const check = ids.find(elpoyeeId => elpoyeeId === id)
    if (check) {
      return true
    }
    return false
  }

  const isCheckedAll = () => {
    const allIds = (allEmployeesList || []).map(elpoyeeId => elpoyeeId.id)

    const checkAll = _.xor(allIds, ids).length === 0 && allIds.length !== 0
    if (checkAll) {
      return true
    }
    return false
  }

  const handleCheckAll = () => {
    const allIds = allEmployeesList.map(elpoyeeId => elpoyeeId.id)

    if (_.xor(allIds, ids).length === 0) {
      setIds([])
    } else setIds(allIds)
  }

  useEffect(() => {
    if (ids) {
      setValue('employeeIds', ids)
    }
  }, [ids, setValue])

  const renderTableBody = () => {
    return allEmployeesList.map(({id, firstName, lastName, department, employmentStatus, createdAt}, index) => {
      return (
        <VmoTableRow key={id} className="tableLink">
          <VmoTableCell>{index + 1}</VmoTableCell>
          <VmoTableCell>
            <VmoCheckbox className="mr-3" value={id} onChange={() => handleCheck(id)} checked={isChecked(id)} />
            {`${firstName} ${lastName || ''}`}
          </VmoTableCell>
          <VmoTableCell>{department?.department}</VmoTableCell>
          <VmoTableCell>manager</VmoTableCell>
          <VmoTableCell>{getFormattedDate(createdAt)}</VmoTableCell>
          <VmoTableCell>
            <VmoLabel className="tag-label">{employmentStatus}</VmoLabel>
          </VmoTableCell>
        </VmoTableRow>
      )
    })
  }

  return (
    <div className="kadence-full-page">
      <div className="campaignNavbar">
        <div>
          <VmoButton primary onClick={() => history.push('/appraisal/templates')}>
            Back
          </VmoButton>
        </div>
        <h3 className="m-0">Send Appraisals</h3>
        <div>
          <VmoButton primary onClick={handleSubmit(data => dispatch(sendAppraisal(editId, data)))}>
            Send Appraisals
          </VmoButton>
        </div>
      </div>

      <VmoForm className="errorLabel">
        <VmoContainer>
          <VmoCard fluid>
            <VmoCardContent>
              <div className="appraisalFormName">
                <h5>Appraisal Form Name:</h5>
                <span>{appraisalTemplateData?.name}</span>
              </div>
              <div className="appraisalFormDescription">
                <h5>Appraisal Form Description:</h5>
                <span>{appraisalTemplateData?.description}</span>
              </div>

              <VmoFormGroup>
                <VmoFormField required>
                  <label>Select Flow</label>
                  <Controller
                    name="flow"
                    render={({onChange, value}) => (
                      <VmoDropdown
                        selection
                        options={[
                          {value: 'upward', key: 'upward', text: 'Upward Appraisal'},
                          {value: 'downward', key: 'downward', text: 'Downward Appraisal'},
                        ]}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                      />
                    )}
                    control={control}
                  />
                  {errors.flow && <p className="errorLabelElement">{removeDoubleQuotes(errors?.flow?.message)}</p>}
                </VmoFormField>
                <VmoFormField required>
                  <label>Select Reviewer</label>
                  <Controller
                    name="reviewerId"
                    render={({onChange, value, onBlur}) => (
                      <VmoDropdown
                        options={ReveiwerOption}
                        selection
                        placeholder="Reveiwer Id"
                        onBlur={onBlur}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                      />
                    )}
                    control={control}
                  />
                  {errors.reviewerId && (
                    <p className="errorLabelElement">{removeDoubleQuotes(errors?.reviewerId?.message)}</p>
                  )}
                </VmoFormField>
              </VmoFormGroup>

              <VmoFormField>
                <Controller
                  control={control}
                  name="isPrivate"
                  render={({onChange, value}) => (
                    <VmoFormField>
                      <div className="d-flex">
                        <div>
                          <label className="m-0">Set as Private</label>
                          <p className="kadence-lead">Only admins with private appraisal access can view</p>
                        </div>
                        <VmoCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                      </div>
                    </VmoFormField>
                  )}
                />
              </VmoFormField>

              <VmoFormField>
                <Controller
                  control={control}
                  name="setNotification"
                  render={({onChange, value}) => (
                    <VmoFormField>
                      <div className="d-flex">
                        <div>
                          <label className="m-0">Set Notification</label>
                          <p className="kadence-lead">Enable notification for sending Appraisal</p>
                        </div>
                        <VmoCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                      </div>
                    </VmoFormField>
                  )}
                />
              </VmoFormField>

              {watchSetNotification ? (
                <VmoFormGroup>
                  <VmoFormField>
                    <label>Start Date</label>
                    <Controller
                      name="notificationTimeline.startDate"
                      render={({onChange, value}) => (
                        <VmoDatePicker
                          type="date"
                          value={typeof value === 'string' ? new Date(startDate) : startDate}
                          inputProps={{readOnly: true}}
                          time={false}
                          onChange={value => {
                            onChange(value)
                            setStartDate(value)
                          }}
                          error={
                            errors?.notificationTimeline?.startDate && {
                              content: removeDoubleQuotes(errors?.notificationTimeline?.startDate?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </VmoFormField>
                  <VmoFormField>
                    <label>End Date for Employees</label>
                    <Controller
                      name="notificationTimeline.endDateForEmployee"
                      render={({onChange, value}) => (
                        <VmoDatePicker
                          type="date"
                          value={typeof value === 'string' ? new Date(endDateForEmployee) : endDateForEmployee}
                          inputProps={{readOnly: true}}
                          time={false}
                          onChange={value => {
                            onChange(value)
                            setEndDateForEmployee(value)
                          }}
                          error={
                            errors?.notificationTimeline?.endDateForEmployee && {
                              content: removeDoubleQuotes(errors?.notificationTimeline?.endDateForEmployee?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </VmoFormField>
                  <VmoFormField>
                    <label>End Date</label>
                    <Controller
                      name="notificationTimeline.endDate"
                      render={({onChange, value}) => (
                        <VmoDatePicker
                          type="date"
                          value={typeof value === 'string' ? new Date(endDate) : endDate}
                          inputProps={{readOnly: true}}
                          time={false}
                          onChange={value => {
                            onChange(value)
                            setEndDate(value)
                          }}
                          error={
                            errors?.notificationTimeline?.endDate && {
                              content: removeDoubleQuotes(errors?.notificationTimeline?.endDate?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </VmoFormField>
                </VmoFormGroup>
              ) : null}
            </VmoCardContent>
          </VmoCard>

          <VmoCard fluid>
            <VmoCardContent>
              <h3 className="mb-0">Select the Reviewers</h3>
              <p className="mt-0 mb-4">Select reviewers who will be reviewing your appraisal</p>

              <VmoFormGroup widths="equal">
                <VmoFormField>
                  <label>Search Query</label>
                  <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
                </VmoFormField>

                <VmoFormField>
                  <label>By department</label>
                  <VmoDropdown
                    selection
                    defaultValue="all"
                    options={[{key: 'all', value: 'all', text: 'All Department'}, <VmoDivider />, ...departmentList]}
                    onChange={(e, {value}) => {
                      setCurrentListParams({
                        ...currentListParams,
                        department: value,
                      })
                    }}
                  />
                </VmoFormField>
              </VmoFormGroup>

              <VmoFormGroup widths="equal">
                <VmoFormField>
                  <label>Resigned after</label>
                  <VmoInput />
                </VmoFormField>

                <VmoFormField>
                  <label>Joined before</label>
                  <VmoInput />
                </VmoFormField>
              </VmoFormGroup>

              <VmoFormField>
                <label>Selection Type</label>
                <p className="kadence-lead">Selection types</p>
                <div>
                  <VmoCheckbox label="Select All on Current Page" checked={isCheckedAll()} onChange={handleCheckAll} />
                </div>
                {/* <div>
                  <VmoRadio
                    label="Select All From Entire List"
                    checked={active.selectAllFromEntirePage}
                    onClick={() =>
                      setActive({
                        ...active,
                        selectAllOnCurrentPage: false,
                        selectAllFromEntirePage: true,
                        deselectAll: false,
                      })
                    }
                  />
                </div>
                <div>
                  <VmoRadio
                    label="Deselect All"
                    checked={active.deselectAll}
                    onClick={() =>
                      setActive({
                        ...active,
                        selectAllOnCurrentPage: false,
                        selectAllFromEntirePage: false,
                        deselectAll: true,
                      })
                    }
                  />
                </div> */}
              </VmoFormField>

              <VmoTable sortable basic selectable>
                <VmoTableHeader>
                  <VmoTableHeaderCell>#</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Department</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Position</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Hired Date</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Employment Status</VmoTableHeaderCell>
                </VmoTableHeader>
                <VmoTableBody>
                  {isLoadingData && type.includes(GET_ALL_EMPLOYEES) ? (
                    <VmoTablePlaceholder columns={6} />
                  ) : (allEmployeesList || []).length > 0 ? (
                    renderTableBody()
                  ) : (
                    <tr>
                      <td colSpan={6} className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  )}
                </VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoContainer>
      </VmoForm>
    </div>
  )
}

export default AppraisalSend
