import React from 'react'
import {useSelector} from 'react-redux'
import {VmoContainer} from 'vmo-library'
import StandardTemplate from './StandardTemplate'

function PreviewInvoices({watch, addressTemplate = {}, liveNumber, style}) {
  const {InvoicesForm = {}} = useSelector(state => state.invoices)
  const {previewFile} = useSelector(state => state.invoices.uploadFile)

  const addressData = addressTemplate[0]

  // const addressTem = {...InvoicesForm, addressTemplate}

  const {template: {templateNo = 2} = {}} = InvoicesForm

  if ((liveNumber || templateNo) === 2) {
    return (
      <VmoContainer>
        <StandardTemplate data={{...InvoicesForm, ...watch, preview: previewFile, addressData, style}} />
      </VmoContainer>
    )
  }
}

export default PreviewInvoices
