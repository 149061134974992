import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import TemplateFinanceMenu from 'navigation/sub-routes/Project/Template/TemplateFinance/TemplateFinanceMenu'
import TemplateFinanceRoute from 'navigation/sub-routes/Project/Template/TemplateFinance/TemplateFinanceRoute'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getProjectsTemplatesFinanceBudget} from 'actions/projects'
import {GET_PROJECTS_TEMPLATES_FINANCE_BUDGET} from 'actions/types'

const TemplateFinance = () => {
  const {templateId} = useParams()

  const [budgetId, setBudgetId] = useState(null)

  useApiResponse({
    action: getProjectsTemplatesFinanceBudget,
    payload: templateId,
    dependency: templateId,
    enabled: templateId,
    label: GET_PROJECTS_TEMPLATES_FINANCE_BUDGET,
    storePath: 'projects.templateBudget',
    onSuccess: templateBudget => {
      setBudgetId(templateBudget?.id)
    },
  })

  return (
    <>
      <TemplateFinanceMenu />
      <TemplateFinanceRoute templateId={templateId} budgetId={budgetId} />
    </>
  )
}

export default TemplateFinance
