/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState, useEffect} from 'react'
import {atomOneLight} from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import SyntaxHighlighter from 'react-syntax-highlighter'
import {FiChevronRight} from 'react-icons/fi'
import _ from 'lodash'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCardGroup,
  VmoCardHeader,
  VmoCardMeta,
  VmoHeader,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoImage,
  VmoGrid,
  VmoGridColumn,
  VmoFormCheckbox,
  VmoFormInput,
  VmoFormField,
  VmoForm,
  VmoColorPicker,
  VmoAccordionTitle,
  VmoAccordionContent,
  VmoAccordion,
  VmoCheckbox,
} from 'vmo-library'
import cal from 'assets/inline-meet.svg'
import {copyToClipboardWithoutInput} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const EmbedInline = props => {
  const {selectedEmbed, setIsEmbedDetails, url} = props
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [copySuccess, setCopySuccess] = useState('COPY')
  const [widgetData, setWidgetData] = useState({
    hide_gdpr_banner: false,
    hide_event_type_details: false,
  })
  const [query, setQuery] = useState('')

  const [code, setCode] = useState(`
  <link
    rel="stylesheet"
    href="https://s3.amazonaws.com/app.veemo.io/crm-index.css"
  />
  <div 
    id="meetings-iframe-container"
    widget-type="${selectedEmbed}"
    data-src="${url}"
  ></div>
  <script
    type="text/javascript"
    src="https://s3.amazonaws.com/app.veemo.io/crm-index.js"
  ></script>`)

  useEffect(() => {
    if (!_.isEmpty(widgetData)) {
      const keys = Object.keys(widgetData || {})
      const query = keys
        .filter(key => widgetData[key])
        .map(key => `${key}=${widgetData[key]}`)
        .join('&')
      setQuery(query)
    }
  }, [widgetData])

  useEffect(() => {
    setCode(`
  <link
    rel="stylesheet"
    href="https://s3.amazonaws.com/app.veemo.io/crm-index.css"
  />
  <div 
    id="meetings-iframe-container"
    widget-type="${selectedEmbed}"
    data-src="${url}${query && `&${query}`}">
  </div>
  <script
    type="text/javascript"
    src="https://s3.amazonaws.com/app.veemo.io/crm-index.js"
  ></script>`)
  }, [query])

  return (
    <>
      <VmoHeader>Inline Embed</VmoHeader>
      <VmoModalContent className="cal-embd">
        <VmoGrid divided>
          <VmoGridColumn width={6}>
            <h5 className="m-0">Page Settings</h5>
            <p className="kadence-lead mb-4">
              Aculpa molestias fugiat necessitatibus ut voluptate, alias nisi pariatur neddcerk
            </p>
            <VmoForm>
              <VmoFormField>
                <VmoCheckbox
                  className="mb-0"
                  label="Show events type details"
                  checked={widgetData.hide_event_type_details}
                  onChange={() =>
                    setWidgetData({
                      ...widgetData,
                      hide_event_type_details: !widgetData.hide_event_type_details,
                    })
                  }
                />
                <p className="kadence-lead" style={{padding: '8px 0 0 26px', fontSize: '13px'}}>
                  Dolorum sit earum nesciunt ea, modi nulla laboriosam minima voluptatum!
                </p>
              </VmoFormField>
              <VmoFormField>
                <VmoCheckbox
                  label="Show GDPR Banner"
                  className="mb-0"
                  checked={widgetData.hide_gdpr_banner}
                  onChange={() =>
                    setWidgetData({
                      ...widgetData,
                      hide_gdpr_banner: !widgetData.hide_gdpr_banner,
                    })
                  }
                />
                <p className="kadence-lead" style={{padding: '8px 0 0 26px', fontSize: '13px'}}>
                  Dolorum sit earum nesciunt ea, modi nulla laboriosam minima voluptatum!
                </p>
              </VmoFormField>
              <VmoFormField>
                <label>Brand Color</label>
                <p className="kadence-lead">Dolorum sit earum nesciunt ea, modi nulla laboriosam minima voluptatum!</p>
                <div
                  style={{
                    padding: 5,
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                >
                  <div
                    style={{
                      width: '50px',
                      height: '15px',
                      borderRadius: '2px',
                      background: `#${widgetData.brandColor}`,
                    }}
                  />
                </div>
                {isColorPickerOpen && (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '2',
                    }}
                  >
                    <div
                      style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                      }}
                      onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                    />
                    <VmoColorPicker
                      color={`#${widgetData.brandColor}`}
                      onChange={value =>
                        setWidgetData({
                          ...widgetData,
                          brandColor: value.hex.substring(1),
                        })
                      }
                    />
                  </div>
                )}
              </VmoFormField>
            </VmoForm>
          </VmoGridColumn>
          <VmoGridColumn width={10} className="pl-4">
            <h5 className="m-0">Embed Code</h5>
            <p className="kadence-lead">
              Ansequatur accusamus hic quia alias eaque, nisi explicabo facilis! Consequuntur officiis provident nulla
              error necessitatibus!
            </p>
            <div className="codeTextView">
              <SyntaxHighlighter language="javascript" style={atomOneLight}>
                {code}
              </SyntaxHighlighter>
            </div>
            <p className="kadence-lead">
              Ansequatur accusamus hic quia alias eaque, nisi explicabo facilis! Consequuntur officiis provident nulla
              error necessitatibus!
            </p>

            <p className="kadence-lead">
              Ansequatur accusamus hic quia alias eaque, nisi explicabo facilis! Consequuntur officiis provident nulla
              error necessitatibus!
            </p>
          </VmoGridColumn>
        </VmoGrid>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton
          basic
          onClick={() => {
            setIsEmbedDetails(false)
          }}
        >
          Back
        </VmoButton>
        <VmoButton primary onClick={e => copyToClipboardWithoutInput(code, setCopySuccess)}>
          {copySuccess}
        </VmoButton>
      </VmoModalActions>
    </>
  )
}

const EmbedPopupWidget = props => {
  const {selectedEmbed, setIsEmbedDetails, url} = props

  const [activeIndex, setActiveIndex] = useState(0)
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [isButtonTextColor, setIsButtonTextColor] = useState(false)
  const [isButtonBackGColor, setIsButtonBackGColor] = useState(false)
  const [copySuccess, setCopySuccess] = useState('COPY')
  const [widgetData, setWidgetData] = useState({
    hide_gdpr_banner: false,
    hide_event_type_details: false,
    ButtonText: 'Schedule time with me',
  })
  const [query, setQuery] = useState('')

  const [code, setCode] = useState(`
  <link
    rel="stylesheet"
    href="https://s3.amazonaws.com/app.veemo.io/crm-index.css"
  />
  <div 
    id="meetings-iframe-container"
    widget-type="${selectedEmbed}"
    data-src="${url}"
  ></div>
  <script
    type="text/javascript"
    src="https://s3.amazonaws.com/app.veemo.io/crm-index.js"
  ></script>`)

  useEffect(() => {
    if (!_.isEmpty(widgetData)) {
      const keys = Object.keys(widgetData || {})
      const query = keys
        .filter(key => widgetData[key])
        .map(key => `${key}=${widgetData[key]}`)
        .join('&')

      setQuery(query)
    }
  }, [widgetData])

  useEffect(() => {
    if (query) {
      setCode(`
  <link
    rel="stylesheet"
    href="https://s3.amazonaws.com/app.veemo.io/crm-index.css"
  />
  <div 
    id="meetings-iframe-container"
    widget-type="${selectedEmbed}"
    data-src="${url}${query && `&${query}`}">
  </div>
  <script
    type="text/javascript"
    src="https://s3.amazonaws.com/app.veemo.io/crm-index.js"
  ></script>`)
    }
  }, [query])

  return (
    <>
      <VmoHeader>Popup Widget</VmoHeader>
      <VmoModalContent className="cal-embd">
        <VmoGrid divided>
          <VmoGridColumn width={6} className="pr-4">
            <VmoAccordion styled className="kadence-accordion">
              <VmoAccordionTitle
                active={activeIndex === 0}
                index={0}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeIndex === index ? -1 : index
                  setActiveIndex(newIndex)
                }}
              >
                <h5 className="m-0">Widget Settings</h5>
                <span>{!activeIndex ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
              </VmoAccordionTitle>
              <VmoAccordionContent active={activeIndex === 0}>
                <p className="kadence-lead mb-4">
                  Aculpa molestias fugiat necessitatibus ut voluptate, alias nisi pariatur neddcerk
                </p>
                <VmoForm>
                  <VmoFormField>
                    <label>Button Text</label>
                    <VmoFormInput
                      placeholder="e.g. Schedule time with me"
                      value={widgetData.ButtonText}
                      onChange={(e, {value}) => setWidgetData({...widgetData, ButtonText: value})}
                    />
                  </VmoFormField>

                  <VmoFormField>
                    <label>Button background color</label>
                    <p className="kadence-lead">
                      Dolorum sit earum nesciunt ea, modi nulla laboriosam minima voluptatum!
                    </p>
                    <div
                      style={{
                        padding: 5,
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsButtonBackGColor(!isButtonBackGColor)}
                    >
                      <div
                        style={{
                          width: '50px',
                          height: '15px',
                          borderRadius: '2px',
                          background: `#${widgetData.color}`,
                        }}
                      />
                    </div>
                    {isButtonBackGColor && (
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                        }}
                      >
                        <div
                          style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                          }}
                          onClick={() => setIsButtonBackGColor(!isButtonBackGColor)}
                        />
                        <VmoColorPicker
                          color={`#${widgetData.color}`}
                          onChange={value =>
                            setWidgetData({
                              ...widgetData,
                              color: value.hex.substring(1),
                            })
                          }
                        />
                      </div>
                    )}
                  </VmoFormField>
                  <VmoFormField>
                    <label>Button text color</label>
                    <p className="kadence-lead">
                      Dolorum sit earum nesciunt ea, modi nulla laboriosam minima voluptatum!
                    </p>
                    <div
                      style={{
                        padding: 5,
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsButtonTextColor(!isButtonTextColor)}
                    >
                      <div
                        style={{
                          width: '50px',
                          height: '15px',
                          borderRadius: '2px',
                          background: `#${widgetData.textColor}`,
                        }}
                      />
                    </div>
                    {isButtonTextColor && (
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                        }}
                      >
                        <div
                          style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                          }}
                          onClick={() => setIsButtonTextColor(!isButtonTextColor)}
                        />
                        <VmoColorPicker
                          color={`#${widgetData.textColor}`}
                          onChange={value =>
                            setWidgetData({
                              ...widgetData,
                              textColor: value.hex.substring(1),
                            })
                          }
                        />
                      </div>
                    )}
                  </VmoFormField>
                </VmoForm>
              </VmoAccordionContent>
              <VmoAccordionTitle
                active={activeIndex === 1}
                index={1}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeIndex === index ? -1 : index
                  setActiveIndex(newIndex)
                }}
              >
                <h5 className="m-0">Page Settings</h5>
                <span>{!activeIndex ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
              </VmoAccordionTitle>
              <VmoAccordionContent active={activeIndex === 1}>
                <p className="kadence-lead mb-4">
                  Aculpa molestias fugiat necessitatibus ut voluptate, alias nisi pariatur neddcerk
                </p>
                <VmoForm>
                  <VmoFormField>
                    <VmoFormCheckbox
                      className="mb-0"
                      label="Show events type details"
                      checked={widgetData.hide_event_type_details}
                      onChange={() =>
                        setWidgetData({
                          ...widgetData,
                          hide_event_type_details: !widgetData.hide_event_type_details,
                        })
                      }
                    />
                    <p className="kadence-lead" style={{padding: '8px 0 0 26px', fontSize: '13px'}}>
                      Dolorum sit earum nesciunt ea, modi nulla laboriosam minima voluptatum!
                    </p>
                  </VmoFormField>
                  <VmoFormField>
                    <VmoFormCheckbox
                      label="Show GDPR Banner"
                      className="mb-0"
                      checked={widgetData.hide_gdpr_banner}
                      onChange={() =>
                        setWidgetData({
                          ...widgetData,
                          hide_gdpr_banner: !widgetData.hide_gdpr_banner,
                        })
                      }
                    />
                    <p className="kadence-lead" style={{padding: '8px 0 0 26px', fontSize: '13px'}}>
                      Dolorum sit earum nesciunt ea, modi nulla laboriosam minima voluptatum!
                    </p>
                  </VmoFormField>
                  <VmoFormField>
                    <label>Brand Color</label>
                    <p className="kadence-lead">
                      Dolorum sit earum nesciunt ea, modi nulla laboriosam minima voluptatum!
                    </p>
                    <div
                      style={{
                        padding: 5,
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                    >
                      <div
                        style={{
                          width: '50px',
                          height: '15px',
                          borderRadius: '2px',
                          background: `#${widgetData.brandColor}`,
                        }}
                      />
                    </div>
                    {isColorPickerOpen && (
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                        }}
                      >
                        <div
                          style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                          }}
                          onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                        />
                        <VmoColorPicker
                          color={`#${widgetData.brandColor}`}
                          onChange={value =>
                            setWidgetData({
                              ...widgetData,
                              brandColor: value.hex.substring(1),
                            })
                          }
                        />
                      </div>
                    )}
                  </VmoFormField>
                </VmoForm>
              </VmoAccordionContent>
            </VmoAccordion>
          </VmoGridColumn>
          <VmoGridColumn width={10} className="pl-4">
            <h5 className="m-0">Embed Code</h5>
            <p className="kadence-lead">
              Ansequatur accusamus hic quia alias eaque, nisi explicabo facilis! Consequuntur officiis provident nulla
              error necessitatibus!
            </p>
            <div className="codeTextView">
              <SyntaxHighlighter language="javascript" style={atomOneLight}>
                {code}
              </SyntaxHighlighter>
            </div>
            <p className="kadence-lead">
              Ansequatur accusamus hic quia alias eaque, nisi explicabo facilis! Consequuntur officiis provident nulla
              error necessitatibus!
            </p>

            <p className="kadence-lead">
              Ansequatur accusamus hic quia alias eaque, nisi explicabo facilis! Consequuntur officiis provident nulla
              error necessitatibus!
            </p>
          </VmoGridColumn>
        </VmoGrid>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton
          basic
          onClick={() => {
            setIsEmbedDetails(false)
          }}
        >
          Back
        </VmoButton>
        <VmoButton primary onClick={e => copyToClipboardWithoutInput(code, setCopySuccess)}>
          {copySuccess}
        </VmoButton>
      </VmoModalActions>
    </>
  )
}

const EmbedPopupText = props => {
  const {selectedEmbed, setIsEmbedDetails, url} = props
  const [widgetData, setWidgetData] = useState({
    hide_gdpr_banner: false,
    hide_event_type_details: false,
    LinkText: 'Schedule time with me',
  })
  const [activeIndex, setActiveIndex] = useState(0)
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [copySuccess, setCopySuccess] = useState('COPY')
  const [code, setCode] = useState(`
  <link
    rel="stylesheet"
    href="https://s3.amazonaws.com/app.veemo.io/crm-index.css"
  />
  <div 
    id="meetings-iframe-container"
    widget-type="${selectedEmbed}"
    data-src="${url}"
  ></div>
  <script
    type="text/javascript"
    src="https://s3.amazonaws.com/app.veemo.io/crm-index.js"
  ></script>`)

  useEffect(() => {
    if (!_.isEmpty(widgetData)) {
      const keys = Object.keys(widgetData || {})
      const query = keys
        .filter(key => widgetData[key])
        .map(key => `${key}=${widgetData[key]}`)
        .join('&')

      setQuery(query)
    }
  }, [widgetData])

  useEffect(() => {
    if (query) {
      setCode(`
  <link
    rel="stylesheet"
    href="https://s3.amazonaws.com/app.veemo.io/crm-index.css"
  />
  <div 
    id="meetings-iframe-container"
    widget-type="${selectedEmbed}"
    data-src="${url}${query && `&${query}`}">
  </div>
  <script
    type="text/javascript"
    src="https://s3.amazonaws.com/app.veemo.io/crm-index.js"
  ></script>`)
    }
  }, [query])

  return (
    <>
      <VmoHeader>Popup Text</VmoHeader>
      <VmoModalContent className="cal-embd">
        <VmoGrid divided>
          <VmoGridColumn width={6} className="pr-4">
            <VmoAccordion styled className="kadence-accordion">
              <VmoAccordionTitle
                active={activeIndex === 0}
                index={0}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeIndex === index ? -1 : index
                  setActiveIndex(newIndex)
                }}
              >
                <h5 className="m-0">Text Link Settings</h5>
                <span>{!activeIndex ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
              </VmoAccordionTitle>
              <VmoAccordionContent active={activeIndex === 0}>
                <p className="kadence-lead mb-4">
                  Aculpa molestias fugiat necessitatibus ut voluptate, alias nisi pariatur neddcerk
                </p>
                <VmoForm>
                  <VmoFormField>
                    <label>Link Text</label>
                    <VmoFormInput
                      placeholder="e.g. Schedule time with me"
                      value={widgetData.LinkText}
                      onChange={(e, {value}) => setWidgetData({...widgetData, LinkText: value})}
                    />
                  </VmoFormField>
                </VmoForm>
              </VmoAccordionContent>
              <VmoAccordionTitle
                active={activeIndex === 1}
                index={1}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeIndex === index ? -1 : index
                  setActiveIndex(newIndex)
                }}
              >
                <h5 className="m-0">Page Settings</h5>
                <span>{!activeIndex ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
              </VmoAccordionTitle>
              <VmoAccordionContent active={activeIndex === 1}>
                <p className="kadence-lead mb-4">
                  Aculpa molestias fugiat necessitatibus ut voluptate, alias nisi pariatur neddcerk
                </p>
                <VmoForm>
                  <VmoFormField>
                    <VmoFormCheckbox
                      className="mb-0"
                      label="Show events type details"
                      checked={widgetData.hide_event_type_details}
                      onChange={() =>
                        setWidgetData({
                          ...widgetData,
                          hide_event_type_details: !widgetData.hide_event_type_details,
                        })
                      }
                    />
                    <p className="kadence-lead" style={{padding: '8px 0 0 26px', fontSize: '13px'}}>
                      Dolorum sit earum nesciunt ea, modi nulla laboriosam minima voluptatum!
                    </p>
                  </VmoFormField>
                  <VmoFormField>
                    <VmoFormCheckbox
                      label="Show GDPR Banner"
                      className="mb-0"
                      checked={widgetData.hide_gdpr_banner}
                      onChange={() =>
                        setWidgetData({
                          ...widgetData,
                          hide_gdpr_banner: !widgetData.hide_gdpr_banner,
                        })
                      }
                    />
                    <p className="kadence-lead" style={{padding: '8px 0 0 26px', fontSize: '13px'}}>
                      Dolorum sit earum nesciunt ea, modi nulla laboriosam minima voluptatum!
                    </p>
                  </VmoFormField>
                  <VmoFormField>
                    <label>Brand Color</label>
                    <p className="kadence-lead">
                      Dolorum sit earum nesciunt ea, modi nulla laboriosam minima voluptatum!
                    </p>
                    <div
                      style={{
                        padding: 5,
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                    >
                      <div
                        style={{
                          width: '50px',
                          height: '15px',
                          borderRadius: '2px',
                          background: `#${widgetData.brandColor}`,
                        }}
                      />
                    </div>
                    {isColorPickerOpen && (
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                        }}
                      >
                        <div
                          style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                          }}
                          onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                        />
                        <VmoColorPicker
                          color={`#${widgetData.brandColor}`}
                          onChange={value =>
                            setWidgetData({
                              ...widgetData,
                              brandColor: value.hex.substring(1),
                            })
                          }
                        />
                      </div>
                    )}
                  </VmoFormField>
                </VmoForm>
              </VmoAccordionContent>
            </VmoAccordion>
          </VmoGridColumn>
          <VmoGridColumn width={10} className="pl-4">
            <h5 className="m-0">Embed Code</h5>
            <p className="kadence-lead">
              Ansequatur accusamus hic quia alias eaque, nisi explicabo facilis! Consequuntur officiis provident nulla
              error necessitatibus!
            </p>
            <div className="codeTextView">
              <SyntaxHighlighter language="javascript" style={atomOneLight}>
                {code}
              </SyntaxHighlighter>
            </div>
            <p className="kadence-lead">
              Ansequatur accusamus hic quia alias eaque, nisi explicabo facilis! Consequuntur officiis provident nulla
              error necessitatibus!
            </p>

            <p className="kadence-lead">
              Ansequatur accusamus hic quia alias eaque, nisi explicabo facilis! Consequuntur officiis provident nulla
              error necessitatibus!
            </p>
          </VmoGridColumn>
        </VmoGrid>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton
          basic
          onClick={() => {
            setIsEmbedDetails(false)
          }}
        >
          Back
        </VmoButton>
        <VmoButton primary onClick={e => copyToClipboardWithoutInput(code, setCopySuccess)}>
          {copySuccess}
        </VmoButton>
      </VmoModalActions>
    </>
  )
}

const EmbedModal = props => {
  const {isModalOpen, setIsModalOpen} = props
  const [selectedEmbed, setSelectedEmbed] = useState('')
  const [isEmbedDetails, setIsEmbedDetails] = useState(false)

  const EmbedFunction = () => {
    switch (selectedEmbed) {
      case 'inline': {
        return <EmbedInline selectedEmbed={selectedEmbed} setIsEmbedDetails={setIsEmbedDetails} {...props} />
      }
      case 'popupWidget': {
        return <EmbedPopupWidget selectedEmbed={selectedEmbed} setIsEmbedDetails={setIsEmbedDetails} {...props} />
      }
      case 'popupText': {
        return <EmbedPopupText selectedEmbed={selectedEmbed} setIsEmbedDetails={setIsEmbedDetails} {...props} />
      }
      default: {
        return ''
      }
    }
  }

  return (
    <VmoModal
      open={isModalOpen}
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => {
        setIsModalOpen(!isModalOpen)
        setIsEmbedDetails(false)
        setSelectedEmbed('')
      }}
    >
      {!isEmbedDetails ? (
        <>
          <VmoHeader>Embed Code</VmoHeader>
          <VmoModalContent className="cal-embd" scrolling>
            <h4>How do you want to add calender in your site?</h4>
            <VmoCardGroup>
              <VmoCard
                link
                className={selectedEmbed === 'inline' && 'active'}
                onClick={() => setSelectedEmbed('inline')}
              >
                <VmoCardContent>
                  <VmoImage src={cal} />
                  <VmoCardHeader>Inline Embed</VmoCardHeader>
                  <VmoCardMeta>Consectetur at eum, cum eaque alias saepe? Porro, voluptatum praesentium!</VmoCardMeta>
                </VmoCardContent>
              </VmoCard>
              <VmoCard
                link
                className={selectedEmbed === 'popupWidget' && 'active'}
                onClick={() => setSelectedEmbed('popupWidget')}
              >
                <VmoCardContent>
                  <VmoImage src={cal} />
                  <VmoCardHeader>Popup Widget</VmoCardHeader>
                  <VmoCardMeta>Consectetur at eum, cum eaque alias saepe? Porro, voluptatum praesentium!</VmoCardMeta>
                </VmoCardContent>
              </VmoCard>
              <VmoCard
                link
                className={selectedEmbed === 'popupText' && 'active'}
                onClick={() => setSelectedEmbed('popupText')}
              >
                <VmoCardContent>
                  <VmoImage src={cal} />
                  <VmoCardHeader>Popup Text</VmoCardHeader>
                  <VmoCardMeta>Consectetur at eum, cum eaque alias saepe? Porro, voluptatum praesentium!</VmoCardMeta>
                </VmoCardContent>
              </VmoCard>
            </VmoCardGroup>
            <a href="" target="_blank">
              See Example of how calender can let customers schedule directily from your site
            </a>
          </VmoModalContent>
          <VmoModalActions>
            <VmoButton basic onClick={() => setIsModalOpen(!isModalOpen)}>
              Cancel
            </VmoButton>
            <VmoButton primary disabled={!selectedEmbed} onClick={() => setIsEmbedDetails(!isEmbedDetails)}>
              Next
            </VmoButton>
          </VmoModalActions>
        </>
      ) : (
        isEmbedDetails && EmbedFunction()
      )}
    </VmoModal>
  )
}

export default EmbedModal
