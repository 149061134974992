/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FaStar} from 'react-icons/fa'
import {useHistory, useLocation} from 'react-router'
import {FiExternalLink, FiGitMerge, FiStar} from 'react-icons/fi'
import _ from 'lodash'
import moment from 'moment'
import {
  VmoButton,
  VmoButtonGroup,
  VmoCheckbox,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoHeader,
  VmoLabel,
  VmoRating,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {
  CREATE_CONTACT,
  ATTACHMENT_POST_CONTACT,
  COLUMNS_SELECTION_CONTACT,
  DELETE_CONTACT,
  GET_ALL_CONTACTS,
  MULTIPLE_MERGE_CONTACT,
  MULTIPLE_UPDATE_CONTACT,
} from 'actions/types'

import {
  contactMultipleUpdate,
  deleteContact,
  getColumnsSelectionContact,
  getContactCompanyList,
  getContactList,
  getContactStatuses,
  getContactType,
  getSingleContact,
} from 'actions/contacts'
import {getTagLabel} from 'actions/multiple_fields'
import create from 'assets/create.svg'
import CustomizeTableColumn from 'components/common/CustomizeTableColumn'
import DeleteModal from 'components/common/DeleteModal'
import {
  get,
  getFormattedDate,
  GetFormattedDate,
  getPermissionTooltipContent,
  getSortType,
  startCase,
} from 'utils/helper'
import SearchComponent from 'components/common/SearchComponent'
import CreateFirst from 'components/common/CreateFirst'
import ContactMenu from 'pages/authenticated/Customers/Contacts'
import KadenceCustomPagination from 'components/common/KadenceCustomPagination'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getUsers} from 'actions/settings'
import ContactFilter from './ContactFilter'
import CreateContactModal from './ContactModal/CreateModal'
import EditContactModal from './ContactModal/EditModal'
import ExportLeadModal from './ContactModal/ExportModal'
import ImportModal from './ContactModal/ImportModal'
import MailLeadModal from './ContactModal/MailModal'
import MergeContactModal from './ContactModal/MergeModal'
import PreviewModal from './ContactModal/PreviewModal'
import SmsModal from './ContactModal/SmsModal'

const Contacts = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const [checked, setChecked] = useState([])
  const [isCustomizeColumn, setIsCustomizeColumn] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [getContactId, setGetContactId] = useState(0)
  const [isImportModal, setIsImportModal] = useState(false)
  const [isExportOpen, setIsExportOpen] = useState(false)
  const [isMergeModal, setIsMergeModal] = useState(false)
  const [isMailModal, setIsMailModal] = useState(false)
  const [isCreateModal, setIsCreateModal] = useState(false)
  const [isEditModal, setIsEditModal] = useState(false)
  const [previewModal, setPreviewModal] = useState(false)
  const [createSms, setCreateSms] = useState(false)
  const [singleIdForDelete, setSingleIdForDelete] = useState(null)
  const [activeClassFilter, setActiveClassFilter] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [sort, setSort] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
    ...get(['match', 'params'], props),
    text: '',
  })

  const {getAllContactsList = []} = useSelector(state => state.contacts)
  const {successLabels = [], isLoadingData, type} = useSelector(state => state.apiReducer)
  const {segmentList = []} = useSelector(state => state.segment)
  const {getSmsChannelList = []} = useSelector(state => state.emailInbox)
  const getAllContactColumnsList = useSelector(state => {
    return get(['contacts', 'getAllContactColumnsList', 'columns'], state, [])
  })

  const {totalPages, currentPage, totalRecords} = useApiResponse({storePath: 'contacts.contactPage'})

  const {isLoading} = useApiLoader({label: GET_ALL_CONTACTS})
  const {salesAndCrmAccess, contactsAndCompaniesPermissions} = useUserPermissions()
  const importAndExportAccess = salesAndCrmAccess?.importAndExport
  const manageContactsCompanyPermissions = contactsAndCompaniesPermissions?.manage_contacts_company
  const bulkActionsPermissions = contactsAndCompaniesPermissions?.bulk_actions
  const manageImportExportPermissions = contactsAndCompaniesPermissions?.manage_import_export

  const handleSort = clickedColumn => () => {
    if (sort.sortBy !== clickedColumn) {
      setSort({
        ...sort,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
      return
    }
    setSort({
      ...sort,
      sortType: sort.sortType === 'asc' ? 'desc' : 'asc',
    })
  }

  useEffect(() => {
    // let newList = _.omitBy({ ...sort }, (value, key) => {
    //   if (value === "none" || value === "") {
    //     return true;
    //   } else return false;
    // });

    dispatch(getContactList(location.state || sort))
  }, [sort])

  useEffect(() => {
    setSort({...sort, ...get(['match', 'params'], props)})
  }, [JSON.stringify(get(['match', 'params'], props))])

  const handleContactName = () => {
    if (get(['match', 'params', 'segmentId'], props)) {
      return get(
        ['name'],
        get(['contacts'], segmentList, []).find(
          item => item.id === Number(get(['match', 'params', 'segmentId'], props))
        ),
        'Contacts'
      )
    }
    return 'Contacts'
  }

  useEffect(() => {
    dispatch(getColumnsSelectionContact())
    dispatch(getContactStatuses())
    dispatch(getUsers())
    dispatch(getTagLabel())
    dispatch(getContactType())
    dispatch(getContactCompanyList())
    // dispatch(getContactList())
  }, [])

  useEffect(() => {
    if (successLabels.includes(CREATE_CONTACT)) {
      setIsCreateModal(false)
    }
    if (successLabels.includes(COLUMNS_SELECTION_CONTACT)) {
      setChecked([])
      setIsCustomizeColumn(false)
    }
    if (successLabels.includes(ATTACHMENT_POST_CONTACT)) {
      dispatch(getSingleContact(getContactId))
    }
    if (successLabels.includes(MULTIPLE_MERGE_CONTACT)) {
      setIsMergeModal(false)
      setChecked([])
    }
    if (successLabels.includes(DELETE_CONTACT)) {
      setChecked([])
    }
    if (successLabels.includes(MULTIPLE_UPDATE_CONTACT)) {
      setChecked([])
      setIsEditModal(false)
    }
  }, [successLabels])

  const handleCheck = id => {
    const isChecked = checked.some(lead => lead === id)

    if (isChecked) {
      const removeChecked = checked.filter(lead => lead !== id)
      setChecked(removeChecked)
    } else {
      setChecked([...checked, id])
    }
  }

  const isChecked = id => {
    const check = checked.find(lead => lead === id)
    if (check) {
      return true
    }
    return false
  }

  const isCheckedAll = () => {
    const allIds = (getAllContactsList || []).map(lead => lead.id)

    const checkAll = _.xor(allIds, checked).length === 0 && allIds.length !== 0
    if (checkAll) {
      return true
    }
    return false
  }

  const handleCheckAll = () => {
    const allIds = getAllContactsList.map(lead => lead.id)

    if (_.xor(allIds, checked).length === 0) {
      setChecked([])
    } else setChecked(allIds)
  }

  const renderOptionList = () => {
    if (checked.length > 1) {
      return (
        <VmoButtonGroup basic compact className="ml-3 conFilter">
          <VmoTooltip
            size="mini"
            position="top center"
            content={
              bulkActionsPermissions
                ? 'Compose Bulk Mail'
                : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
            }
            trigger={
              <VmoButton color="light" onClick={() => bulkActionsPermissions && setIsMailModal(!isMailModal)}>
                <SvgIcon path="common/mail" />
              </VmoButton>
            }
          />
          {checked.some(item => {
            return getAllContactsList.some(list => list.id === item && !list.isFollowed)
          }) ? (
            <VmoTooltip
              size="mini"
              position="top center"
              content={
                bulkActionsPermissions
                  ? 'Add contacts to favorite'
                  : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
              }
              trigger={
                <VmoButton
                  color="light"
                  onClick={() =>
                    bulkActionsPermissions &&
                    dispatch(
                      contactMultipleUpdate({
                        ids: checked,
                        isFollowed: true,
                      })
                    )
                  }
                >
                  <FiStar />
                </VmoButton>
              }
            />
          ) : (
            <VmoTooltip
              size="mini"
              position="top center"
              content={
                bulkActionsPermissions
                  ? 'Remove Selected Favorite'
                  : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
              }
              trigger={
                <VmoButton
                  color="light"
                  onClick={() =>
                    bulkActionsPermissions &&
                    dispatch(
                      contactMultipleUpdate({
                        ids: checked,
                        isFollowed: false,
                      })
                    )
                  }
                >
                  <FaStar style={{color: '#ecd60e'}} />
                </VmoButton>
              }
            />
          )}
          {/* <VmoButton
            color="light"
            onClick={() => dispatch(editContact({isFollowed: true}, [...checked]))}>
            <FiStar />
          </VmoButton> */}
          <VmoTooltip
            size="mini"
            position="top center"
            content={
              bulkActionsPermissions
                ? 'Edit Selected Contacts'
                : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
            }
            trigger={
              <VmoButton color="light" onClick={() => bulkActionsPermissions && setIsEditModal(!isEditModal)}>
                <SvgIcon path="common/edit2" />
              </VmoButton>
            }
          />
          <VmoTooltip
            size="mini"
            position="top center"
            content={
              bulkActionsPermissions
                ? 'Merge Selected Contacts'
                : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
            }
            trigger={
              <VmoButton color="light" onClick={() => bulkActionsPermissions && setIsMergeModal(!isMergeModal)}>
                <FiGitMerge />
              </VmoButton>
            }
          />
          <VmoTooltip
            size="mini"
            position="top center"
            content={
              bulkActionsPermissions
                ? 'Export Selected Contacts'
                : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
            }
            trigger={
              <VmoButton color="light" onClick={() => bulkActionsPermissions && setIsExportOpen(!isExportOpen)}>
                <FiExternalLink />
              </VmoButton>
            }
          />
          <VmoTooltip
            size="mini"
            position="top center"
            content={
              bulkActionsPermissions
                ? 'Delete Selected Contacts'
                : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
            }
            trigger={
              <VmoButton
                color="light"
                onClick={() => bulkActionsPermissions && setIsDeleteModalOpen(!isDeleteModalOpen)}
              >
                <SvgIcon path="common/delete" />
              </VmoButton>
            }
          />
        </VmoButtonGroup>
      )
    }
  }

  const customizeColumnLogic = (item, key) => {
    const conditionalObject = {
      company: startCase(get(['company', 'name'], item, '--')),
      phones: get(['phones', [0], 'phone'], item, '--'),
      owner: get(['owner', 'firstName'], item, '--'),
      status: get(['status', 'status'], item, '--'),
      inactiveDays: get(['interactionTime'], item) ? moment(get(['interactionTime'], item)).fromNow(true) : '--',
      statusDuration: get(['statusTime'], item) ? moment(get(['statusTime'], item)).fromNow(true) : '--',
      contactType: get(['contactType', 'type'], item),
      updatedAt: getFormattedDate(item.updatedAt),
      createdAt: getFormattedDate(item.createdAt),
      street: get(['address', 'streetAddress'], item, '--'),
      city: get(['address', 'locality'], item, '--'),
      state: get(['address', 'state'], item, '--'),
      country: get(['address', 'country'], item, '--'),
      zip: get(['address', 'postalCode'], item, '--'),
      websites: get(['websites', [0], 'url'], item, '--'),
      statusUpdated: get(['statusTime'], item) ? getFormattedDate(get(['statusTime'], item)) : '--',
      lastContacted: get(['interactionTime'], item) ? GetFormattedDate(get(['interactionTime'], item)) : '--',
      tags: get(['labels'], item, [])
        .slice(0, 2)
        .map(tag => {
          return (
            <>
              {tag.label}
              <br />
            </>
          )
        }),
    }

    if (Object.keys(conditionalObject).includes(key)) {
      return get([key], conditionalObject)
    }
    return item[key]
  }

  const handleSingleDelete = id => {
    setIsDeleteModalOpen(!isDeleteModalOpen)
    setSingleIdForDelete(id)
  }

  const renderLeadList = () => {
    return getAllContactsList.map(item => (
      <VmoTableRow
        key={item.id}
        className="tableLink"
        onClick={e => {
          if (e.target.className !== 'custom-control-label' && e.target.className !== 'custom-control-input')
            history.push(`/contacts/${item.id}`)
        }}
      >
        <VmoTableCell
          className="pl-4 pt-4 d-flex"
          style={{justifyContent: 'flex-start'}}
          key="checkbox"
          onClick={e => e.stopPropagation()}
        >
          <VmoTooltip
            size="mini"
            position="top center"
            content="Select"
            trigger={
              <VmoCheckbox
                id={`${item.id}label`}
                className="d-inline-block"
                onChange={() => handleCheck(item.id)}
                checked={isChecked(item.id)}
              />
            }
          />
          <VmoTooltip
            size="mini"
            position="top center"
            content={item.isFollowed ? 'Remove Favorite' : 'Add Favorite'}
            trigger={
              <VmoRating
                icon="star"
                className="ml-4"
                onClick={() => {
                  dispatch(
                    contactMultipleUpdate({
                      ids: [item.id],
                      isFollowed: !item.isFollowed,
                    })
                  )
                }}
                rating={item.isFollowed ? 1 : 0}
              />
            }
          />
        </VmoTableCell>
        <VmoTableCell key="firstName" className="prevCell">
          <VmoHeader as="h5">
            {startCase(`${get(['firstName'], item, '')} ${get(['lastName'], item, '')}`).trim() || `#${item.id}`}
          </VmoHeader>
          <div className="PrevActPart">
            <VmoLabel
              as="a"
              color="blue"
              onClick={e => {
                e.stopPropagation()
                setPreviewModal(!previewModal)
                setGetContactId(item.id)
              }}
            >
              Preview
            </VmoLabel>

            {getSmsChannelList.length > 0 && (
              <VmoTooltip
                content="Create Sms"
                position="top center"
                size="mini"
                trigger={
                  <VmoButton
                    icon={<SvgIcon path="common/mail" />}
                    onClick={e => {
                      e.stopPropagation()
                      setCreateSms(true)
                      setGetContactId(item.id)
                    }}
                  />
                }
              />
            )}

            {/* <VmoButton
              className="mx-3"
              icon={
                <VmoTooltip
                  content="Create Conversation"
                  position="top center"
                  size="mini"
                  trigger={<SvgIcon path="common/mail" />}
                />
              }
            /> */}
          </div>
        </VmoTableCell>

        <VmoTableCell>{get(['email'], item, '--')}</VmoTableCell>
        {getAllContactColumnsList.map(key => {
          return <VmoTableCell>{customizeColumnLogic(item, key)}</VmoTableCell>
        })}
        <VmoTableCell>
          <VmoTooltip
            content={
              manageContactsCompanyPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: manageContactsCompanyPermissions})
            }
            size="mini"
            position="top center"
            trigger={
              <VmoButton
                icon={<SvgIcon path="common/delete" />}
                onClick={e => {
                  e.stopPropagation()
                  if (manageContactsCompanyPermissions) {
                    handleSingleDelete(item.id)
                  }
                }}
              />
            }
          />
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {getAllContactsList?.length > 0 || sort.text || isFilterOpen ? (
        <>
          <ContactMenu />

          <div className="kadence-side-wrap" style={{paddingRight: isFilterOpen && 300}}>
            <div className="page-header">
              <div className="page-title">
                <div className="page-titleIcon">
                  <SvgIcon path="settings/users" />
                  <h5>
                    {checked && checked.length > 0
                      ? `${checked.length} out of ${getAllContactsList.length} Selected`
                      : `${handleContactName()}(${totalRecords})`}
                  </h5>
                  {renderOptionList()}
                </div>
              </div>
              <div className="page-action">
                <SearchComponent onSuccess={value => setSort({text: value})} />

                <VmoTooltip
                  size="mini"
                  position="top center"
                  content="Filters"
                  trigger={
                    <VmoButton
                      icon
                      className={isFilterOpen ? 'active' : null}
                      onClick={() => (!isFilterOpen ? setIsFilterOpen(!isFilterOpen) : null)}
                    >
                      <SvgIcon path="common/filter" />
                    </VmoButton>
                  }
                />

                <VmoTooltip
                  size="mini"
                  position="top center"
                  content="Settings"
                  trigger={
                    <VmoDropdown
                      floating
                      button
                      className="icon"
                      icon={<SvgIcon path="clients/contacts/contacts-dashboard/settings" />}
                    >
                      <VmoDropdownMenu className="left">
                        <VmoDropdownItem
                          text="Customize columns"
                          onClick={() => setIsCustomizeColumn(!isCustomizeColumn)}
                        />
                        <LockPermissionTooltip
                          isFeatureAccessDenied={!importAndExportAccess}
                          isRoleAccessDenied={!manageImportExportPermissions}
                        >
                          <VmoDropdownItem
                            text="Import from Excel or CSV file"
                            onClick={() =>
                              importAndExportAccess && manageImportExportPermissions && history.push('/settings/import')
                            }
                          />
                        </LockPermissionTooltip>
                        <LockPermissionTooltip
                          isFeatureAccessDenied={!importAndExportAccess}
                          isRoleAccessDenied={!manageImportExportPermissions}
                        >
                          <VmoDropdownItem
                            text="Export Contacts"
                            onClick={() =>
                              importAndExportAccess && manageImportExportPermissions && history.push('/settings/export')
                            }
                          />
                        </LockPermissionTooltip>
                      </VmoDropdownMenu>
                    </VmoDropdown>
                  }
                />
                <LockPermissionTooltip isRoleAccessDenied={!manageContactsCompanyPermissions}>
                  <VmoButton
                    primary
                    content="Add New"
                    className={!manageContactsCompanyPermissions ? 'disabled-button' : ''}
                    onClick={() => manageContactsCompanyPermissions && setIsCreateModal(!isCreateModal)}
                  />
                </LockPermissionTooltip>
              </div>
            </div>
            <div style={{display: isFilterOpen ? 'block' : 'none'}}>
              <ContactFilter
                isFilterOpen={isFilterOpen}
                setIsFilterOpen={setIsFilterOpen}
                setActiveClassFilter={setActiveClassFilter}
                {...props}
              />
            </div>
            <div className="tableScroll">
              <VmoTable selectable sortable basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell className="pl-4">
                      <VmoTooltip
                        size="mini"
                        position="top center"
                        content="Select All"
                        trigger={
                          <VmoCheckbox id="ckbxAll" label="" checked={isCheckedAll()} onChange={handleCheckAll} />
                        }
                      />
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={sort.sortBy === 'name' ? getSortType(sort.sortType) : null}
                      onClick={handleSort('name')}
                    >
                      Name
                    </VmoTableHeaderCell>

                    <VmoTableHeaderCell
                      sorted={sort.sortBy === 'emails' ? getSortType(sort.sortType) : null}
                      onClick={handleSort('emails')}
                    >
                      Email
                    </VmoTableHeaderCell>

                    {getAllContactColumnsList.map(col => {
                      return (
                        <VmoTableHeaderCell
                          sorted={sort.sortBy === col ? getSortType(sort.sortType) : null}
                          onClick={handleSort(col)}
                        >
                          {startCase(col === 'phones' ? 'phone' : col)}
                        </VmoTableHeaderCell>
                      )
                    })}
                    <VmoTableHeaderCell>Action</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {isLoadingData && type.includes(GET_ALL_CONTACTS) ? (
                    <VmoTablePlaceholder columns={8} />
                  ) : (getAllContactsList || []).length > 0 ? (
                    renderLeadList()
                  ) : (
                    <tr>
                      <td colSpan={8} className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  )}
                </VmoTableBody>
              </VmoTable>
              <KadenceCustomPagination
                totalPages={totalPages}
                activePage={currentPage}
                onPageChange={page => {
                  setSort(sort => ({...sort, page}))
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <div>
          <CreateFirst
            imageSrc={create}
            header="Contact"
            subHeader="Contacts are people who show interest in your business. Add and manage contacts in a centralized place"
            addFirst={() => setIsCreateModal(!isCreateModal)}
            buttonText="Create Contact"
            Feature1="Bulk Actions"
            Feature2="Import Contacts"
            Feature3="Columns Customization"
            list1="Perform a single action on multiple contacts at once"
            list2="Upload contacts in bulk from any source"
            list3="Add more columns to display on the list layout"
          />
        </div>
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          modalText="Are you sure you want to delete ?"
          deleteAction={deleteContact}
          idTobeDeleted={checked.length > 0 ? [...checked].join(',') : singleIdForDelete}
          type={DELETE_CONTACT}
        />
      )}
      {isCustomizeColumn && (
        <CustomizeTableColumn
          isModalOpen={isCustomizeColumn}
          setIsModalOpen={setIsCustomizeColumn}
          identifier="contacts"
        />
      )}
      {isCreateModal && <CreateContactModal isModalOpen={isCreateModal} setIsModalOpen={setIsCreateModal} />}
      {isImportModal && <ImportModal isModalOpen={isImportModal} setIsModalOpen={setIsImportModal} />}
      {isMailModal && <MailLeadModal isModalOpen={isMailModal} setIsModalOpen={setIsMailModal} checked={checked} />}
      {isExportOpen && <ExportLeadModal isModalOpen={isExportOpen} setIsModalOpen={setIsExportOpen} />}
      {isEditModal && <EditContactModal isModalOpen={isEditModal} setIsModalOpen={setIsEditModal} checked={checked} />}
      {previewModal && (
        <PreviewModal isModalOpen={previewModal} setIsModalOpen={setPreviewModal} getContactId={getContactId} />
      )}

      {createSms && <SmsModal open={createSms} toggle={setCreateSms} isContact userId={getContactId} />}

      {isMergeModal && (
        <MergeContactModal isModalOpen={isMergeModal} setIsModalOpen={setIsMergeModal} mergeId={checked} />
      )}
    </>
  )
}

export default Contacts
