import React from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom/cjs/react-router-dom.min'
import ProjectBudgetSummary from 'components/pages/Projects/Projects/Steps/Finance/ProjecBudgetSummary'
import ProjectBudgets from 'components/pages/Projects/Projects/Steps/Finance/Budgets/ProjectBudgets'
import ProjectRates from 'components/pages/Projects/Projects/Steps/Finance/Rates/ProjectRates'
import ProjectBilling from 'components/pages/Projects/Projects/Steps/Finance/Billing/ProjectBilling'

const ProjectFinanceRoutes = ({projectId, budgetId}) => {
  const {path, url} = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${path}/summary`}
        render={props => <ProjectBudgetSummary {...props} projectId={projectId} budgetId={budgetId} />}
      />
      <Route
        path={`${path}/budgets`}
        render={props => <ProjectBudgets {...props} projectId={projectId} budgetId={budgetId} />}
      />
      <Route
        path={`${path}/rates`}
        render={props => <ProjectRates {...props} projectId={projectId} budgetId={budgetId} />}
      />
      <Route
        path={`${path}/billing`}
        render={props => <ProjectBilling {...props} projectId={projectId} budgetId={budgetId} />}
      />
      <Route path={path} exact render={() => <Redirect to={`${url}/summary`} />} />
    </Switch>
  )
}

export default ProjectFinanceRoutes
