import Joi from 'joi'

export const CreateSequence = Joi.object({
  name: Joi.string().min(2).max(20).trim().required().messages({
    'string.empty': `Name is not allowed to be empty`,
    'any.required': `Name is a required field`,
    'string.min': `Name length must be at least 2 characters long`,
    'string.max': `Name length must be less than or equal to 20 characters`,
  }),
  inboxId: Joi.number().empty(''),
  contactIds: Joi.array().items(Joi.number()),
  contactSegmentIds: Joi.array().items(Joi.number()),
  contactSegmentCheckbox: Joi.boolean().default(false),
  contactCheckbox: Joi.boolean().default(false),
  startAt: Joi.string().isoDate(),
  timezone: Joi.string().required().empty(''),
  startTime: Joi.string().regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]:00$/),
  endTime: Joi.string().regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]:00$/),
  workingDays: Joi.array().items(Joi.number()).default([0, 1, 2, 3, 4, 5, 6]),
  holidays: Joi.array().items(Joi.date()),

  isSingleThread: Joi.boolean().default(true),
  addUnsubLink: Joi.boolean().default(false),
  updateContactAfterDays: Joi.number().integer().empty('').allow(null),
  pauseOnOutOfEmail: Joi.boolean().default(false),
  resumeAfterDays: Joi.number().integer().when('pauseOnOutOfEmail', {is: true, then: Joi.required()}),
  dailyLimit: Joi.number().integer().empty('').allow(null),
  steps: Joi.array().items(
    Joi.object({
      step: Joi.number().integer().required(),
      type: Joi.string().required().valid('email', 'task', 'sms'),
      sendAfterDays: Joi.number().integer(),
      sendAt: Joi.string().regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]:00$/),
      isAutoEmail: Joi.boolean().default(true),
      data: Joi.object(),
    })
  ),
  addContactLabels: Joi.array().items(Joi.number().integer()),
  contactUnsubLabels: Joi.array().items(Joi.number().integer()),
  firstStepLabels: Joi.array().items(Joi.number().integer()),

  stopsOnBookMeeting: Joi.boolean().default(true),
  stopsOnEmail: Joi.boolean().default(true),
  stopsOnText: Joi.boolean().default(true),
  stopsOnCloseDeal: Joi.boolean().default(true),
  taskStatus: Joi.string().required().messages({
    'string.empty': `Task Status is not allowed to be empty`,
    'any.required': `Task Status is a required field`,
  }),
  delayBetweenEmails: Joi.number().integer().required(),
  vmoBranding: Joi.boolean().default(true),
  stopsOnTaskStatus: Joi.boolean().default(true),
})

export const CreateSequence1 = Joi.object({
  inboxId: Joi.number().min(1).required().messages({
    'number.base': 'Inbox is required',
  }),
  contactIds: Joi.array().items(Joi.number()),
  leadIds: Joi.array().items(Joi.number()),
  contactSegmentIds: Joi.array().items(Joi.number()),
  leadSegmentIds: Joi.array().items(Joi.number()),
  leadSegmentCheckbox: Joi.boolean().default(false),
  contactSegmentCheckbox: Joi.boolean().default(false),
  leadCheckbox: Joi.boolean().default(false),
  contactCheckbox: Joi.boolean().default(false),
  startAt: Joi.string().isoDate(),
  timezone: Joi.string().required().empty(''),
  startTime: Joi.string().regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]:00$/),
  endTime: Joi.string().regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]:00$/),
  workingDays: Joi.array().items(Joi.number()).default([0, 1, 2, 3, 4, 5, 6]),
})

export const CreateSequence2 = Joi.object({
  name: Joi.string().trim().required().min(2).max(30).messages({
    'string.empty': `Name is not allowed to be empty`,
    'any.required': `Name is a required field`,
    'string.min': `Name length must be at least 2 characters long`,
    'string.max': `Name length must be less than or equal to 30 characters`,
  }),
})

export const CreateSequence3 = Joi.object({
  holidays: Joi.array().items(Joi.date()),
  isSingleThread: Joi.boolean().default(true),
  addUnsubLink: Joi.boolean().default(false),
  updateContactAfterDays: Joi.number().integer().empty('').allow(null),
  pauseOnOutOfEmail: Joi.boolean().default(false),
  addContactLabels: Joi.array().items(Joi.number().integer()),
  contactUnsubLabels: Joi.array().items(Joi.number().integer()),
  firstStepLabels: Joi.array().items(Joi.number().integer()),
  resumeAfterDays: Joi.number().integer().optional().messages({
    'any.required': `field is required`,
    'number.base': `field must be a number`,
  }),
  stopsOnBookMeeting: Joi.boolean().default(true),
  stopsOnEmail: Joi.boolean().default(true),
  stopsOnText: Joi.boolean().default(true),
  stopsOnCloseDeal: Joi.boolean().default(true),
  taskStatus: Joi.string().required().messages({
    'string.empty': `Task Status is not allowed to be empty`,
    'any.required': `Task Status is a required field`,
  }),
  delayBetweenEmails: Joi.number().integer().required(),
  dailyLimit: Joi.number().integer().empty('').allow(null),
  vmoBranding: Joi.boolean().default(true),
  stopsOnTaskStatus: Joi.boolean().default(true),
})

export const createTaskSchema = Joi.object({
  type: Joi.string().required(),
  subject: Joi.string().max(30).trim().required().messages({
    'string.empty': `Subject cannot be an empty field`,
    'any.required': `Subject is required`,
    'string.max': `maximum 30 characters are allowed`,
  }),
  priority: Joi.string().required(),
  description: Joi.string().allow('').max(200).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
  callType: Joi.string(),
  status: Joi.string().allow(''),
  duration: Joi.number().required().messages({
    'any.required': `Duration is required`,
    'number.base': `Duration must be a number`,
  }),
})

export const smsResolver = Joi.object({
  messageText: Joi.string().max(100).trim().required().messages({
    'string.empty': `message text is not allowed to be empty`,
    'any.required': `message text is a required field`,
    'string.max': `message text length must be less than or equal to 100 characters`,
  }),
  mailboxId: Joi.number().required().messages({
    'any.required': `mailbox is required`,
    'number.base': `mailbox is required`,
  }),
})
