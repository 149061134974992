import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveLine} from '@nivo/line'
import Moment from 'moment'
import {VmoCard, VmoCardContent, VmoCardHeader, VmoGrid, VmoGridColumn, VmoTooltip} from 'vmo-library'

import {GET_CAMPAIGN_LIST, SMS_SENT_DELIVERED_OVERTIME, SMS_SUCCESS_RATE_OVERTIME} from 'actions/types'
import {smsCampaignStatsAnalytics, smsSentDeliveredOvertime, smsSuccessRateOvertime} from 'actions/analytics_SMS'
import {getCampaignList} from 'actions/campaign'
import CustomLoader from 'components/common/CustomLoader'
import {getGridYValues, getGridYValuesBar, maxOfGraph, maxOfGraphBar} from 'utils/graph'
import {get} from 'utils/helper'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const SMS = () => {
  const dispatch = useDispatch()

  const [campaignIsAvailabel, setCampaignIsAvailabel] = useState(false)

  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {campaignList = []} = useSelector(state => state.campaign)
  const {
    smsCampaignStatsData = {},
    smsSentDeliveredOvertimeData = [],
    smsSuccessRateOvertimeData = [],
  } = useSelector(state => state.analyticsSMS)
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const campaignsAccess = analyticsAccess?.campaigns
  const campaignReportsPermissions = reportsPermissions?.campaign_reports

  useEffect(() => {
    if (campaignsAccess && campaignReportsPermissions) {
      dispatch(getCampaignList())
    }
  }, [campaignReportsPermissions, dispatch, campaignsAccess])

  useEffect(() => {
    if (successLabels.includes(GET_CAMPAIGN_LIST)) {
      if (campaignList.some(campaign => campaign.type === 'sms_campaign')) {
        setCampaignIsAvailabel(true)
      } else {
        setCampaignIsAvailabel(false)
        dispatch({type: 'CLEAR_SMS_CAMPAIGN'})
      }
    }
  }, [dispatch, campaignList, successLabels])

  const actionsToDispatch = useCallback(
    data => {
      dispatch(smsCampaignStatsAnalytics(data))
      dispatch(smsSentDeliveredOvertime(data))
      dispatch(smsSuccessRateOvertime(data))
    },
    [dispatch]
  )

  return (
    <div className="kadence-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="settings/sms" />
            <h5>SMS</h5>
          </div>
        </div>
        {campaignIsAvailabel && campaignsAccess && campaignReportsPermissions && (
          <div className="page-action">
            <DateRangeGroup actionsToDispatch={actionsToDispatch} />
          </div>
        )}
      </div>

      <VmoGrid>
        <VmoGridColumn width={4}>
          <VmoCard fluid className="valueBox">
            <VmoCardContent>
              <VmoCardHeader>Delivered</VmoCardHeader>
              {campaignIsAvailabel ? (
                <h3>
                  {get(['delivered', 'delivered'], smsCampaignStatsData, 0)}
                  <span
                    className={
                      get(['delivered', 'growth'], smsCampaignStatsData, 'positive') === 'positive' ? '' : 'text-danger'
                    }
                  >
                    <VmoTooltip
                      position="top center"
                      size="mini"
                      content="Compared to Previous Duration"
                      trigger={
                        <div>
                          {get(['delivered', 'growth'], smsCampaignStatsData, 'positive') === 'positive' ? (
                            <SvgIcon path="common/arrow-up-right" />
                          ) : (
                            <SvgIcon path="common/arrow-down-left" className="down-left" />
                          )}
                          {get(['delivered', 'percentage'], smsCampaignStatsData, 0)}%
                        </div>
                      }
                    />
                  </span>
                </h3>
              ) : (
                <h3>-</h3>
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={4}>
          <VmoCard fluid className="valueBox">
            <VmoCardContent>
              <VmoCardHeader>Sent</VmoCardHeader>
              {campaignIsAvailabel ? (
                <h3>
                  {get(['sent', 'sent'], smsCampaignStatsData, 0)}
                  <span
                    className={
                      get(['sent', 'growth'], smsCampaignStatsData, 'positive') === 'positive' ? '' : 'text-danger'
                    }
                  >
                    <VmoTooltip
                      position="top center"
                      size="mini"
                      content="Compared to Previous Duration"
                      trigger={
                        <div>
                          {get(['sent', 'growth'], smsCampaignStatsData, 'positive') === 'positive' ? (
                            <SvgIcon path="common/arrow-up-right" />
                          ) : (
                            <SvgIcon path="common/arrow-down-left" className="down-left" />
                          )}
                          {get(['sent', 'percentage'], smsCampaignStatsData, 0)}%
                        </div>
                      }
                    />
                  </span>
                </h3>
              ) : (
                <h3>-</h3>
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={4}>
          <VmoCard fluid className="valueBox">
            <VmoCardContent>
              <VmoCardHeader>
                Success Rate <small>(%)</small>
              </VmoCardHeader>
              {campaignIsAvailabel ? (
                <h3>
                  {get(['successRate', 'successRate'], smsCampaignStatsData, 0)}
                  <small>%</small>
                  <span
                    className={
                      get(['successRate', 'growth'], smsCampaignStatsData, 'positive') === 'positive'
                        ? ''
                        : 'text-danger'
                    }
                  >
                    <VmoTooltip
                      position="top center"
                      size="mini"
                      content="Compared to Previous Duration"
                      trigger={
                        <div>
                          {get(['successRate', 'growth'], smsCampaignStatsData, 'positive') === 'positive' ? (
                            <SvgIcon path="common/arrow-up-right" />
                          ) : (
                            <SvgIcon path="common/arrow-down-left" className="down-left" />
                          )}
                          {get(['successRate', 'percentage'], smsCampaignStatsData, 0)}%
                        </div>
                      }
                    />
                  </span>
                </h3>
              ) : (
                <h3>-</h3>
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={4}>
          <VmoCard fluid className="valueBox">
            <VmoCardContent>
              <VmoCardHeader>URL Clicks</VmoCardHeader>
              {campaignIsAvailabel ? (
                <h3>
                  {get(['urlClicks', 'urlClicks'], smsCampaignStatsData, 0)}
                  <span
                    className={
                      get(['urlClicks', 'growth'], smsCampaignStatsData, 'positive') === 'positive' ? '' : 'text-danger'
                    }
                  >
                    <VmoTooltip
                      position="top center"
                      size="mini"
                      content="Compared to Previous Duration"
                      trigger={
                        <div>
                          {get(['urlClicks', 'growth'], smsCampaignStatsData, 'positive') === 'positive' ? (
                            <SvgIcon path="common/arrow-up-right" />
                          ) : (
                            <SvgIcon path="common/arrow-down-left" className="down-left" />
                          )}
                          {get(['urlClicks', 'percentage'], smsCampaignStatsData, 0)}%
                        </div>
                      }
                    />
                  </span>
                </h3>
              ) : (
                <h3>-</h3>
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Sent Delivered Overtime</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of all the SMS delivered within a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 350}}>
              {type.includes(SMS_SENT_DELIVERED_OVERTIME) && <CustomLoader />}
              {!type.includes(SMS_SENT_DELIVERED_OVERTIME) &&
                smsSentDeliveredOvertimeData &&
                !smsSentDeliveredOvertimeData.map(({data}) => data.every(({y}) => y === 0)).includes(false) && (
                  <div className="empty-chart mt-3">No Data Available</div>
                )}
              {!type.includes(SMS_SENT_DELIVERED_OVERTIME) && (
                <ResponsiveLine
                  data={smsSentDeliveredOvertimeData}
                  margin={{
                    top: 27,
                    right: 27,
                    bottom: 60,
                    left: 60,
                  }}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(smsSentDeliveredOvertimeData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableSlices="x"
                  enableGridX={false}
                  gridYValues={getGridYValues(smsSentDeliveredOvertimeData)}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    format: tick => {
                      const diff = Moment(
                        get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem('kadence')))
                      ).diff(
                        Moment(get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem('kadence')))),
                        'days'
                      )
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    tickPadding: 5,
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(smsSentDeliveredOvertimeData),
                    legend: 'Number of users',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  colors={d => d.color}
                  lineWidth={2}
                  pointSize={3}
                  pointColor={{from: 'color', modifiers: []}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  enableArea
                  useMesh
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 30,
                      itemOpacity: 1,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                            itemOpacity: 0.75,
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionConfig="molasses"
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Success Rate Overtime</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of campaign success rate over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 270}}>
              {type.includes(SMS_SUCCESS_RATE_OVERTIME) && <CustomLoader />}
              {!type.includes(SMS_SUCCESS_RATE_OVERTIME) &&
                smsSuccessRateOvertimeData.every(d => d['Success Rate'] === 0) && (
                  <div className="empty-chart">No Data Available</div>
                )}
              {!type.includes(SMS_SUCCESS_RATE_OVERTIME) && (
                <ResponsiveBar
                  data={smsSuccessRateOvertimeData}
                  keys={['Success Rate']}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 70, left: 60}}
                  padding={0.3}
                  minValue={0}
                  maxValue={maxOfGraphBar(smsSuccessRateOvertimeData, 'Success Rate')}
                  gridYValues={getGridYValuesBar(smsSuccessRateOvertimeData, 'Success Rate')}
                  valueScale={{type: 'linear'}}
                  indexScale={{type: 'band', round: true}}
                  colors={d => d.data[`Success RateColor`]}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 60,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(smsSuccessRateOvertimeData, 'Success Rate'),
                    legend: 'Success Rate',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipHeight={1}
                  label={d => <tspan y={-8}>{d.value}</tspan>}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default SMS
