import {apiAction} from './api'
import {USER_ACCOUNTS, USER_USERS, KADENCE_SETTING} from './endpoint'
import {
  DELETE_USER,
  GET_USERS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_PASSWORD,
  GET_TEMPLATE,
  DELETE_TEMPLATE,
  GET_SINGLE_TEMPLATE,
  EDIT_SINGLE_TEMPLATE,
  DELETE_TEMPLATE_ATTACHMENT,
  CREATE_TEMPLATE,
  ALLOW_USER_ACCESS,
} from './types'

export function updateProfile(data) {
  return apiAction({
    url: `${USER_USERS}/me`,
    method: 'PUT',
    label: UPDATE_PROFILE,
    data,
    showToast: true,
    successMessage: 'Profile Updated',
  })
}

export function deleteProfilePicture() {
  return apiAction({
    url: `${USER_USERS}/avatar`,
    method: 'DELETE',
    label: 'DELETE_AVATAR',
    showToast: true,
    successMessage: 'Avatar Deleted',
  })
}

export function updateProfilePicture(data) {
  return apiAction({
    url: `${USER_USERS}/avatar`,
    method: 'POST',
    label: 'UPDATE_AVATAR',
    data,
    contentType: 'multipart/form-data',
    showToast: true,
    successMessage: 'Avatar Updated',
  })
}

export function changePasswordProfile(data) {
  return apiAction({
    url: `${USER_ACCOUNTS}/password`,
    method: 'PUT',
    label: UPDATE_PROFILE_PASSWORD,
    data,
    showToast: true,
    successMessage: 'Password Changed',
  })
}

export function getUsers(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')

  return apiAction({
    url: `${USER_ACCOUNTS}/users?${query}`,
    method: 'GET',
    label: GET_USERS,
  })
}

export function deleteUser(ids) {
  return apiAction({
    url: `${USER_ACCOUNTS}/users`,
    method: 'DELETE',
    label: DELETE_USER,
    data: ids,
    showToast: true,
    successMessage: 'User Deleted',
  })
}

export function allowUserAccess(id) {
  return apiAction({
    url: `${USER_ACCOUNTS}/users/access/${id}`,
    method: 'PUT',
    label: ALLOW_USER_ACCESS,
    showToast: true,
  })
}

export function getEmailTemplate(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/email-templates`,
    method: 'GET',
    label: GET_TEMPLATE,
    data,
  })
}

export function deleteEmailTemplate(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/email-templates/${id}`,
    method: 'DELETE',
    label: DELETE_TEMPLATE,
    showToast: true,
    successMessage: 'Email Template deleted',
  })
}

export function singleEmailTemplate(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/email-templates/${id}`,
    method: 'GET',
    label: GET_SINGLE_TEMPLATE,
  })
}

export function editEmailTemplate(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/email-templates/${id}`,
    method: 'PUT',
    label: EDIT_SINGLE_TEMPLATE,
    data,
    showToast: true,
    successMessage: 'Email Template updated',
  })
}

export function deleteTemplateAttachment(id, attachmentId) {
  const idString = attachmentId.join(',')

  return apiAction({
    url: `${KADENCE_SETTING}/email-templates/${id}/attachment/${idString}`,
    method: 'DELETE',
    label: DELETE_TEMPLATE_ATTACHMENT,
  })
}

export function createQuickReply(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/email-templates`,
    method: 'POST',
    label: CREATE_TEMPLATE,
    data,
    showToast: true,
    successMessage: 'Email Template created',
  })
}
