import React, {useEffect, useState} from 'react'
import {Controller, useFieldArray} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  VmoButton,
  VmoDatePicker,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoHeader,
  VmoHeaderSubheader,
  VmoIcon,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoRadio,
} from 'vmo-library'

import {GET_ALL_MAIL_INBOX} from 'actions/types'
import {getAllMailInbox} from 'actions/inbox'
import {get, getFormattedDate, removeDoubleQuotes, startCase} from 'utils/helper'
import {priorityList} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import {ENTITY_OPTIONS, fieldsForConditions, operatorsOptions, TRIGGER_OPTIONS} from '../../../constants/automation'

const statusList = [
  {value: 'published', text: 'Published', key: 'published'},
  {value: 'draft', text: 'Draft', key: 'draft'},
]

const typeList = [
  {text: 'Personal', value: 1, key: 'personal'},
  {text: 'Team', value: 2, key: 'team'},
]

const unitList = [
  {key: 'minute', value: 'minute', text: 'Mins'},
  {key: 'hour', value: 'hour', text: 'Hour'},
  {key: 'day', value: 'day', text: 'Day'},
  {key: 'month', value: 'month', text: 'Months'},
]

const AddTrigger = props => {
  const {
    open,
    toggle,
    handleSubmit,
    control,
    errors,
    clearErrors,
    setValue,
    watchEntity,
    watchTrigger,
    watchTriggerId,
    handleAdd,
    setAutomationData,
    watch,
    dropdownData,
    setEditData,
  } = props

  const dispatch = useDispatch()

  const [mailBoxList, setMailBoxList] = useState([])

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {inbox: mailboxes} = useSelector(state => state.mailInbox)

  const {
    fields: conditionsArray,
    append: conditionsAppend,
    remove: conditionsRemove,
  } = useFieldArray({
    control,
    name: 'conditionsJson.conditionsArray',
  })

  useEffect(() => {
    dispatch(getAllMailInbox())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_ALL_MAIL_INBOX)) {
      const list = mailboxes
        .filter(mailbox => mailbox.type === 'web' || mailbox.type === 'email')
        .map(mail => {
          return {
            key: mail.id,
            value: mail.id,
            text: `${get(['name'], mail, '')}`,
            type: mail.type,
            content: (
              <div>
                {get(['name'], mail, '')}
                {!mail.emailVerified && <small className="ml-2 kadence-lead">- not verified</small>}
              </div>
            ),
            disabled: !mail.emailVerified,
          }
        })
      setMailBoxList(list)
    }
  }, [successLabels, mailboxes])

  const getEntityOptions = () => {
    return ENTITY_OPTIONS.map(({key, value, text, description, icon}) => ({
      key,
      value,
      text,
      content: (
        <div className="d-flex" style={{justifyContent: 'flex-start'}}>
          <VmoIcon className="pr-4">{icon}</VmoIcon>
          <div>
            <VmoHeader as="h5" className="mb-0">
              {text}
            </VmoHeader>
            <VmoHeaderSubheader>{description}</VmoHeaderSubheader>
          </div>
        </div>
      ),
    }))
  }

  const getTriggerOptions = () => {
    return get([watchEntity], TRIGGER_OPTIONS, []).map(({key, value, text, description}) => ({
      key,
      value,
      text,
      content: (
        <div className="d-flex" style={{justifyContent: 'flex-start'}}>
          <VmoIcon className="pr-4">
            <SvgIcon path="common/mail" />
          </VmoIcon>
          <div>
            <VmoHeader as="h5" className="mb-0">
              {text}
            </VmoHeader>
            <VmoHeaderSubheader>{description}</VmoHeaderSubheader>
          </div>
        </div>
      ),
    }))
  }

  const getFieldOptions = () => {
    if (watchEntity === 'conversations' && watchTrigger === 'incoming' && watchTriggerId) {
      const type = mailBoxList.find(list => list.value === watchTriggerId)?.type
      if (type) {
        return get([watchEntity, watchTrigger, type], fieldsForConditions, [])
      }
    } else if (watchEntity === 'conversations' && watchTrigger === 'incoming' && !watchTriggerId) {
      return []
    } else {
      return get([watchEntity, watchTrigger], fieldsForConditions, [])
    }
  }

  const renderAppropriateField = (condition, index, defaultVal) => {
    if (
      [
        'sourceId',
        'priority',
        'contactId',
        'companyId',
        'stageId',
        'assigneeId',
        'labelIds',
        'lossReason',
        'country',
        'contactTypeId',
        'status',
        'type',
        'haveTags',
        'doNotHaveTags',
      ].includes(condition)
    ) {
      let optionsList
      switch (condition) {
        case 'sourceId':
          optionsList = dropdownData.sourceList
          break
        case 'priority':
          optionsList = priorityList
          break
        case 'contactId':
          optionsList = dropdownData.contactList
          break
        case 'companyId':
          optionsList = dropdownData.companyList
          break
        case 'stageId':
          optionsList = dropdownData.dealStageList
          break
        case 'assigneeId':
          optionsList = dropdownData.usersList
          break
        case 'labelIds':
          optionsList = dropdownData.tagLabelData
          break
        case 'lossReason':
          optionsList = dropdownData.lossReasonList
          break
        case 'country':
          optionsList = dropdownData.countryCodeUpdated
          break
        case 'contactTypeId':
          optionsList = dropdownData.contactTypeOptionList
          break
        case 'status':
          optionsList = statusList
          break
        case 'type':
          optionsList = typeList
          break
        case 'haveTags':
        case 'doNotHaveTags':
          optionsList = dropdownData.tagsList
          break
        default:
          break
      }
      return (
        <Controller
          name={`conditionsJson.conditionsArray[${index}].value`}
          control={control}
          defaultValue={defaultVal}
          render={({value, onChange}) => (
            <VmoFormSelect
              placeholder="Value"
              options={optionsList}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              onClick={() => clearErrors(`conditionsJson.conditionsArray[${index}].value`)}
              error={
                errors?.conditionsJson?.conditionsArray?.[index]?.value && {
                  content: errors.conditionsJson.conditionsArray[index].value.message,
                }
              }
            />
          )}
        />
      )
    }
    if (
      [
        'name',
        'description',
        'monetaryValue',
        'firstName',
        'lastName',
        'title',
        'interactions',
        'domain',
        'prefixId',
        'validTill',
        'Name',
        'total',
        'meetingName',
        'meetingHeading',
        'conversionRate',
        'repeatContact',
        'pageUrlMatch',
        'pageUrlContain',
        'referralUrl',
        'referralDomain',
        'contactEmailDomain',
        'subjectLike',
        'contactEmail',
        'company',
      ].includes(condition)
    ) {
      return (
        <Controller
          name={`conditionsJson.conditionsArray[${index}].value`}
          control={control}
          defaultValue={defaultVal}
          render={({value, onChange}) => (
            <VmoFormInput
              placeholder="Value"
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={
                errors?.conditionsJson?.conditionsArray?.[index]?.value && {
                  content: errors.conditionsJson.conditionsArray[index].value.message,
                }
              }
            />
          )}
        />
      )
    }
    if (condition === 'closeDate') {
      return (
        <>
          <Controller
            name={`conditionsJson.conditionsArray[${index}].value`}
            control={control}
            render={({value, onChange}) => (
              <VmoDatePicker
                className="vmo-picker"
                onChange={value => onChange(value)}
                min={new Date()}
                inputProps={{readOnly: true}}
                time={false}
              />
            )}
          />
          {errors?.conditionsJson?.conditionsArray?.[index]?.value && (
            <p className="errorLabelError pl-3">
              {removeDoubleQuotes(errors.conditionsJson.conditionsArray[index].value.message)}
            </p>
          )}
        </>
      )
    }
    // if (condition === 'newContact') {
    //   setValue(`conditionsJson.conditionsArray[${index}].value`, true)
    //   return null
    // }
    if (['conversationstatus', 'newConversation'].includes(condition)) {
      return (
        <VmoFormGroup>
          <VmoFormField>
            <Controller
              name={`conditionsJson.conditionsArray[${index}].value.unit`}
              control={control}
              defaultValue={defaultVal?.unit}
              render={({onChange}) => (
                <VmoFormSelect
                  options={unitList}
                  value={defaultVal?.unit}
                  onChange={(e, {value}) => onChange(value)}
                  onClick={() => clearErrors(`conditionsJson.conditionsArray[${index}].value.unit`)}
                  error={
                    errors?.conditionsJson?.conditionsArray?.[index]?.value?.unit && {
                      content: removeDoubleQuotes(errors.conditionsJson.conditionsArray[index].value.unit.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
          <VmoFormField>
            <Controller
              name={`conditionsJson.conditionsArray[${index}].value.time`}
              control={control}
              defaultValue={defaultVal?.time}
              render={({onChange}) => (
                <VmoFormInput
                  style={{width: '100px'}}
                  type="number"
                  value={defaultVal?.time}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors?.conditionsJson?.conditionsArray?.[index]?.value?.time && {
                      content: removeDoubleQuotes(errors.conditionsJson.conditionsArray[index].value.time.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
        </VmoFormGroup>
      )
    }
    if (condition === 'win') {
      return (
        <Controller
          name={`conditionsJson.conditionsArray[${index}].value`}
          control={control}
          defaultValue={defaultVal}
          render={({value, onChange}) => (
            <VmoFormInput
              type="number"
              placeholder="Value"
              value={value}
              onChange={(e, {value}) => onChange(value)}
              min={0}
              max={100}
              error={
                errors?.conditionsJson?.conditionsArray?.[index]?.value && {
                  content: errors.conditionsJson.conditionsArray[index].value.message,
                }
              }
            />
          )}
        />
      )
    }
    // return null
    return (
      <Controller
        name={`conditionsJson.conditionsArray[${index}].value`}
        control={control}
        defaultValue={defaultVal}
        render={({value, onChange}) => (
          <VmoFormSelect
            placeholder="Value"
            options={[]}
            value={value}
            onChange={(e, {value}) => onChange(value)}
            onClick={() => clearErrors(`conditionsJson.conditionsArray[${index}].value`)}
            error={
              errors?.conditionsJson?.conditionsArray?.[index]?.value && {
                content: errors.conditionsJson.conditionsArray[index].value.message,
              }
            }
          />
        )}
      />
    )
  }

  const submitForm = data => {
    const payload = {...data}

    const {
      conditionsJson: {conditionsArray = [], days},
    } = payload
    const string1 = <p>{`${startCase(payload.entity)} ${startCase(payload.trigger)}`}</p>
    let string2 = ``
    if (['open_inactive_for', 'inactive_for', 'new_inactive_for'].includes(payload.trigger)) {
      string2 = <p>{`${startCase(payload.trigger)} : ${days} days`}</p>
    } else {
      string2 = conditionsArray.map(condition => {
        if (
          [
            'sourceId',
            'priority',
            'contactId',
            'companyId',
            'stageId',
            'assigneeId',
            'labelIds',
            'lossReason',
            'country',
            'contactTypeId',
            'status',
            'type',
            'haveTags',
            'doNotHaveTags',
          ].includes(condition.field)
        ) {
          return (
            <p>{`${startCase(condition.field)} ${
              operatorsOptions.find(operator => operator.value === condition.operator)?.text
            } ${condition.value}`}</p>
          )
        }
        if (
          [
            'name',
            'description',
            'monetaryValue',
            'firstName',
            'lastName',
            'title',
            'interactions',
            'domain',
            'prefixId',
            'validTill',
            'Name',
            'total',
            'meetingName',
            'meetingHeading',
            'conversionRate',
            'repeatContact',
            'pageUrlMatch',
            'pageUrlContain',
            'referralUrl',
            'referralDomain',
            'contactEmailDomain',
            'subjectLike',
            'contactEmail',
            'company',
          ].includes(condition.field)
        ) {
          return (
            <p>{`${startCase(condition.field)} ${
              operatorsOptions.find(operator => operator.value === condition.operator)?.text
            } ${condition.value}`}</p>
          )
        }
        if (['conversationstatus', 'newConversation'].includes(condition.field)) {
          return (
            <p>{`${startCase(condition.field)} ${
              operatorsOptions.find(operator => operator.value === condition.operator)?.text
            } ${condition.value.time} ${condition.value.unit}`}</p>
          )
        }
        if (condition.field === 'closeDate') {
          return (
            <p>
              {`${startCase(condition.field)} ${
                operatorsOptions.find(operator => operator.value === condition.operator)?.text
              } ${getFormattedDate(condition.value)}`}
            </p>
          )
        }
        if (condition.field === 'win') {
          return (
            <p>{`${startCase(condition.field)} ${
              operatorsOptions.find(operator => operator.value === condition.operator)?.text
            } ${condition.value}%`}</p>
          )
        }
      })
    }
    const addString = (
      <div>
        {string1}
        {string2}
      </div>
    )
    handleAdd(addString)

    setAutomationData(automationData => ({...automationData, ...payload}))
    toggle(false)
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="large"
      open={open}
      onClose={() => {
        setEditData(null)
        toggle(false)
      }}
    >
      <VmoModalHeader>Trigger</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Select Entity</label>
            <Controller
              name="entity"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  options={getEntityOptions()}
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                    setValue('trigger', '')
                    setValue('triggerId', '')
                    setValue('conditionsJson.conditionsArray', [{field: '', operator: '', value: ''}])
                    setValue('conditionsJson.conditionsOperator', 'and')
                    setValue('conditionsJson.days', null)
                  }}
                  onClick={() => clearErrors('entity')}
                  error={errors.entity && {content: removeDoubleQuotes(errors.entity.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required>
            <label>Select Trigger</label>
            <Controller
              name="trigger"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  options={getTriggerOptions()}
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                    setValue('triggerId', '')
                    setValue('conditionsJson.conditionsArray', [{field: '', operator: '', value: ''}])
                    setValue('conditionsJson.conditionsOperator', 'and')
                    setValue('conditionsJson.days', null)
                  }}
                  onClick={() => clearErrors('trigger')}
                  error={errors.trigger && {content: removeDoubleQuotes(errors.trigger.message)}}
                />
              )}
            />
          </VmoFormField>
          {watchEntity === 'conversations' && watchTrigger === 'incoming' && (
            <VmoFormField required>
              <label>Select Inbox</label>
              <Controller
                name="triggerId"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormSelect
                    options={mailBoxList}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                      setValue('conditionsJson.conditionsArray', [{field: '', operator: '', value: ''}])
                      setValue('conditionsJson.conditionsOperator', 'and')
                      setValue('conditionsJson.days', null)
                    }}
                    onClick={() => clearErrors('triggerId')}
                    error={errors.triggerId && {content: removeDoubleQuotes(errors.triggerId.message)}}
                  />
                )}
              />
            </VmoFormField>
          )}
          {Array.isArray(watchTrigger.match(/inactive|open/)) && (
            <VmoFormField required>
              <label>Days</label>
              <Controller
                name="conditionsJson.days"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormInput
                    placeholder="Enter days"
                    type="number"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors?.conditionsJson?.days && {
                        content: errors.conditionsJson.days.message,
                      }
                    }
                  />
                )}
              />
            </VmoFormField>
          )}
          {!Array.isArray(watchTrigger.match(/inactive|open/)) && (
            <>
              {conditionsArray.map(({id, field, operator, value}, index) => {
                const conditionField = watch(`conditionsJson.conditionsArray[${index}].field`)
                return (
                  <VmoFormGroup key={id}>
                    <VmoFormField required>
                      <label>Field</label>
                      <Controller
                        name={`conditionsJson.conditionsArray[${index}].field`}
                        control={control}
                        defaultValue={field}
                        render={({value, onChange}) => (
                          <VmoFormSelect
                            selection
                            placeholder="Field"
                            options={getFieldOptions()}
                            value={value}
                            onChange={(e, {value}) => {
                              onChange(value)
                              setValue(`conditionsJson.conditionsArray[${index}].operator`, '')
                              setValue(`conditionsJson.conditionsArray[${index}].value`, '')
                            }}
                            onClick={() => clearErrors(`conditionsJson.conditionsArray[${index}].field`)}
                            error={
                              errors?.conditionsJson?.conditionsArray?.[index]?.field && {
                                content: errors.conditionsJson.conditionsArray[index].field.message,
                              }
                            }
                          />
                        )}
                      />
                    </VmoFormField>

                    {conditionField !== 'newContact' && (
                      <VmoFormField required>
                        <label>Operator</label>
                        <Controller
                          name={`conditionsJson.conditionsArray[${index}].operator`}
                          control={control}
                          defaultValue={operator}
                          render={({value, onChange}) => (
                            <VmoFormSelect
                              selection
                              placeholder="Operator"
                              options={_.take(operatorsOptions)}
                              value={value}
                              onChange={(e, {value}) => onChange(value)}
                              onClick={() => clearErrors(`conditionsJson.conditionsArray[${index}].operator`)}
                              error={
                                errors?.conditionsJson?.conditionsArray?.[index]?.operator && {
                                  content: errors.conditionsJson.conditionsArray[index].operator.message,
                                }
                              }
                            />
                          )}
                        />
                      </VmoFormField>
                    )}

                    {/* {conditionField === 'newContact' &&
                      setValue(`conditionsJson.conditionsArray[${index}].operator`, '=')} */}

                    {conditionField !== 'newContact' && (
                      <VmoFormField required>
                        <label>Value</label>
                        {renderAppropriateField(conditionField, index, value)}
                      </VmoFormField>
                    )}
                    {conditionsArray.length > 1 && (
                      <VmoFormField>
                        <VmoButton style={{marginTop: '24px'}} primary onClick={() => conditionsRemove(index)}>
                          Remove
                        </VmoButton>
                      </VmoFormField>
                    )}
                    {conditionsArray.length < 2 && index === conditionsArray.length - 1 && (
                      <VmoFormField>
                        <VmoButton
                          style={{marginTop: '24px'}}
                          primary
                          onClick={() => conditionsAppend({field: '', operator: '', value: ''})}
                        >
                          Add
                        </VmoButton>
                      </VmoFormField>
                    )}
                  </VmoFormGroup>
                )
              })}
              <VmoFormField required>
                <label>Filter Conditions By</label>
              </VmoFormField>
              <VmoFormField>
                <Controller
                  name="conditionsJson.conditionsOperator"
                  control={control}
                  render={({value, onChange}) => (
                    <>
                      <VmoRadio
                        label="and Condition"
                        value="and"
                        checked={value === 'and'}
                        onClick={(e, {value}) => onChange(value)}
                      />
                      <VmoRadio
                        className="ml-3"
                        label="or Condition"
                        value="or"
                        checked={value === 'or'}
                        onClick={(e, {value}) => onChange(value)}
                      />
                    </>
                  )}
                />
              </VmoFormField>
            </>
          )}
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton
          basic
          onClick={() => {
            setEditData(null)
            toggle(false)
          }}
        >
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          Save
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default AddTrigger
