/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  VmoForm,
  VmoFormField,
  VmoInput,
  VmoButton,
  VmoIcon,
  VmoCardGroup,
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
} from 'vmo-library'
import _ from 'lodash'

const ImportFile = props => {
  const {name, sampleImportAction, sampleData, sampleFileName, importData, currentStep, setCurrentStep, steps, format} =
    props

  const dispatch = useDispatch()

  const [uploadFile, setUploadFile] = useState(null)

  const [type, setType] = useState('')

  // useffect for loading all data
  useEffect(() => {
    dispatch(sampleImportAction())
  }, [])

  // function to Download data
  const downloadResponseCSV = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', type === 'csv' ? `${name}.csv` : `${name}.xlsx`) // or any other extension
    document.body.appendChild(link)
    link.click()
  }

  return (
    <>
      <div className="backHeader">
        <VmoButton
          icon
          onClick={() => {
            setCurrentStep(steps[steps.indexOf(currentStep) - 1])
          }}
        >
          <VmoIcon name="left arrow" />
        </VmoButton>
      </div>
      {currentStep === 'step2' && (
        <VmoCardGroup>
          {format.map(el => (
            <VmoCard
              link
              className="hoverCard"
              onClick={() => {
                setCurrentStep('step3')
                setType(el)
              }}
            >
              <VmoCardContent>
                <VmoCardHeader>{_.upperCase(el)}</VmoCardHeader>
              </VmoCardContent>
            </VmoCard>
          ))}
        </VmoCardGroup>
      )}

      {currentStep === 'step3' && (
        <VmoForm className="vmo-form">
          <VmoFormField>
            <label htmlFor="import">
              Import {name} {_.upperCase(type)}
            </label>
            <VmoInput
              type="file"
              onChange={e => {
                setUploadFile(e.target.files)
              }}
            />
            <small>Max upload file size is 2 MB</small>
            <VmoButton
              primary
              size="tiny"
              className="m-2"
              disabled={uploadFile === null}
              onClick={() => {
                const data = {
                  file: uploadFile,
                }
                dispatch(importData(data))
              }}
            >
              Upload File
            </VmoButton>
          </VmoFormField>
          <h5 className="mb-0">Download {_.upperCase(type)} example</h5>
          <p className="kadence-lead m-0">
            Download file :{' '}
            <VmoButton
              onClick={() => {
                dispatch(sampleImportAction())
                downloadResponseCSV(sampleData, sampleFileName)
              }}
            >
              Download sample {name}
            </VmoButton>
          </p>
        </VmoForm>
      )}
    </>
  )
}
export default ImportFile
