import React from 'react'
import {VmoCard, VmoCardContent, VmoForm, VmoLabel} from 'vmo-library'
import RenderFilter from './RenderFilter'

const RenderRules = ({rulesFields, rulesRemove, control, errors, clearErrors, watch, setValue}) => {
  return (rulesFields || []).map((val, rulesIndex) => {
    return (
      <React.Fragment key={val.id}>
        <VmoCard fluid>
          <VmoCardContent>
            <VmoForm className="errorLabel camerrorLabel">
              <RenderFilter
                rulesFields={rulesFields}
                rulesIndex={rulesIndex}
                rulesRemove={rulesRemove}
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                watch={watch}
                setValue={setValue}
              />
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
        {rulesIndex < rulesFields.length - 1 && (
          <VmoLabel basic className="andLable mb-4">
            {watch('matchCase') === 'and' ? 'AND' : 'OR'}
          </VmoLabel>
        )}
      </React.Fragment>
    )
  })
}

export default RenderRules
