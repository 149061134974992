import {
  VmoGrid,
  VmoGridColumn,
  VmoImage,
  VmoListItem,
  VmoPlaceholder,
  VmoPlaceholderHeader,
  VmoPlaceholderLine,
  VmoPlaceholderParagraph,
} from 'vmo-library'

export const ListPlaceholder = ({rows = 6}) => {
  return Array.from({length: rows}, () => (
    <VmoListItem>
      <VmoGrid>
        <VmoGridColumn floated="left" width={16}>
          <VmoPlaceholder>
            <VmoPlaceholderParagraph>
              <VmoPlaceholderLine />
              <VmoPlaceholderLine />
              <VmoPlaceholderLine />
            </VmoPlaceholderParagraph>
          </VmoPlaceholder>
        </VmoGridColumn>
      </VmoGrid>
    </VmoListItem>
  ))
}

export const ParagraphPlaceholder = ({paragraphs = 5}) => {
  return Array.from({length: paragraphs}, (_, i) => {
    return (
      <VmoPlaceholderParagraph key={i}>
        <VmoPlaceholderLine />
        <VmoPlaceholderLine />
        <VmoPlaceholderLine />
      </VmoPlaceholderParagraph>
    )
  })
}

export const EmailBodyContentPlaceholder = ({paragraphs = 5}) => {
  return (
    <VmoPlaceholder fluid className="m-3">
      <VmoPlaceholderHeader image>
        <VmoPlaceholderLine />
        <VmoPlaceholderLine />
      </VmoPlaceholderHeader>
      <ParagraphPlaceholder paragraphs={paragraphs} />
    </VmoPlaceholder>
  )
}

export const EmptyInboxPlaceholder = ({header, subHeader, imageSrc, sidebarText}) => {
  return (
    <div className="noData">
      {sidebarText ? (
        <p>{sidebarText}</p>
      ) : (
        <>
          <VmoImage src={imageSrc} />
          <h5>{header}</h5>
          <span>{subHeader}</span>
        </>
      )}
    </div>
  )
}
