import {apiAction} from './api'
import {KADENCE_ANALYTICS} from './endpoint'
import {SMS_CAMPAIGN_STATS, SMS_SENT_DELIVERED_OVERTIME, SMS_SUCCESS_RATE_OVERTIME} from './types'

export function smsCampaignStatsAnalytics(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/sms-campaign/smscampaignstats`,
    method: 'POST',
    label: SMS_CAMPAIGN_STATS,
    data,
  })
}

export function smsSentDeliveredOvertime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/sms-campaign/sentdeliveredovertime`,
    method: 'POST',
    label: SMS_SENT_DELIVERED_OVERTIME,
    data,
  })
}

export function smsSuccessRateOvertime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/sms-campaign/successrateovertime`,
    method: 'POST',
    label: SMS_SUCCESS_RATE_OVERTIME,
    data,
  })
}
