import {CHANGE_PASSWORD, NEW_PASSWORD_EMAIL, RESET_PASSWORD_VERIFICATION} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case NEW_PASSWORD_EMAIL: {
      //
      return {
        data: action.payload,
      }
    }

    case RESET_PASSWORD_VERIFICATION:
      return {
        data: action.payload,
      }

    case CHANGE_PASSWORD:
      return {data: action.payload}

    default:
      return state
  }
}
