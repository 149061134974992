import React from 'react'
import DashboardsMenu from 'navigation/sub-routes/Dashboards/DashboardsMenu'
import DashboardsRoutes from 'navigation/sub-routes/Dashboards/DashboardsRoutes'

function Dashboards() {
  return (
    <>
      <DashboardsMenu />
      <DashboardsRoutes />
    </>
  )
}

export default Dashboards
