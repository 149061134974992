import React from 'react'
import LockFeature from './LockFeature'
import LockRole from './LockRole'

const PermissionIcon = ({isFeatureAccessDenied, isRoleAccessDenied}) => {
  if (isFeatureAccessDenied) {
    return <LockFeature />
  }
  if (isRoleAccessDenied) {
    return <LockRole />
  }
  return null
}

export default PermissionIcon
