const CONTAINS = {
  title: 'Contains',
  key: 'contains',
  operator: "LIKE '%' || StRiNgRePlAcE || '%'",
}

const LESS_THEN = {
  title: 'Less then',
  key: 'less_then',
  operator: '< StRiNgRePlAcE',
}

const GREATER_THEN = {
  title: 'Greater then',
  key: 'greater_then',
  operator: '> StRiNgRePlAcE',
}

const EQUALS = {
  title: 'Equals',
  key: 'equals',
  operator: '= StRiNgRePlAcE',
}

const NOT_EQUALS = {
  title: 'Not Equals',
  key: 'not_equals',
  operator: '<> StRiNgRePlAcE',
}

const NOT_CONTAINS = {
  title: 'Does Not Contains',
  key: 'not_contains',
  operator: "@> StRiNgRePlAcE '",
}

const ON = {
  title: 'On',
  key: 'on',
  operator: '= StRiNgRePlAcE',
}

const AFTER = {
  title: 'After',
  key: 'after',
  operator: '> StRiNgRePlAcE',
}

const BEFORE = {
  title: 'Before',
  key: 'before',
  operator: '< StRiNgRePlAcE',
}

export const FILTERS = [
  {
    title: 'Current Page Url',
    key: 'current_page_url',
    tableName: 'page_visit',
    column: 'pageUrl',
    conditions: [CONTAINS, NOT_CONTAINS, EQUALS, NOT_EQUALS],
    primaryValueType: 'text',
    primaryValueLabel: 'page url',
  },
  {
    title: 'Number of page views',
    key: 'number_of_page_views',
    tableName: 'page_visit',
    column: 'pageUrl',
    conditions: [EQUALS, GREATER_THEN, LESS_THEN],
    primaryValueType: 'text',
    primaryValueLabel: 'page url',
    SecondryValue: true,
    secondryValueType: 'number',
    secondryValueLabel: 'number value for views',
  },
  {
    title: 'Time on current page',
    key: 'time_on_current_page',
    tableName: 'page_visit',
    column: 'pageUrl',
    conditions: [GREATER_THEN],
    primaryValueType: 'text',
    primaryValueLabel: 'page url',
    SecondryValue: true,
    secondryValueType: 'number',
    secondryValueLabel: 'time in seconds ',
  },
  {
    title: 'Number of visits',
    key: 'number_of_visits',
    tableName: 'page_visit',
    column: 'pageUrl',
    conditions: [EQUALS, GREATER_THEN, LESS_THEN],
    primaryValueType: 'number',
    primaryValueLabel: 'number of visit',
  },
  {
    title: 'State',
    key: 'state',
    tableName: 'page_visit',
    column: 'state',
    conditions: [EQUALS, NOT_EQUALS],
    primaryValueType: 'text',
    primaryValueLabel: 'state name',
  },
  {
    title: 'City',
    key: 'city',
    tableName: 'page_visit',
    column: 'state',
    conditions: [EQUALS, NOT_EQUALS],
    primaryValueType: 'text',
    primaryValueLabel: 'city name',
  },
  {
    title: 'Device Type',
    key: 'device_type',
    tableName: 'page_visit',
    column: 'device',
    conditions: [EQUALS, NOT_EQUALS],
    data: ['mobile', 'tablet', 'desktop'],
    primaryValueType: 'dropdown',
    primaryValueLabel: 'Select device type',
  },
  {
    title: 'First Seen',
    key: 'first_seen',
    tableName: 'contacts',
    column: 'createdAt',
    conditions: [ON, AFTER, BEFORE],
    primaryValueType: 'date',
    primaryValueLabel: 'date and time',
  },
  {
    title: 'Last Seen',
    key: 'last_seen',
    tableName: 'contacts',
    column: 'lastSeenAt',
    conditions: [ON, AFTER, BEFORE],
    primaryValueType: 'date',
    primaryValueLabel: 'date and time',
  },
]

export const msgPlaceholder = [
  {
    key: 'fname',
    text: 'First Name',
    value: `{{firstName}}`,
    toast: 'First Name Copied',
  },
  {
    key: 'lname',
    text: 'Last Name',
    value: `{{lastName}}`,
    toast: 'Last Name Copied',
  },
  {
    key: 'email',
    text: 'Email Address',
    value: `{{email}}`,
    toast: 'Email Address Copied',
  },
  {
    key: 'address',
    text: 'Address',
    value: `{{address}}`,
    toast: 'Address Copied',
  },
]
