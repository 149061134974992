import Joi from 'joi'

const subTask = Joi.object().keys({
  name: Joi.string().trim().max(30).required().label('Subtask name').messages({
    'string.empty': 'Sub Task Name is required',
    'string.max': 'Name is allowed to be less than or equal to 30 characters',
  }),
  assigneeId: Joi.number().allow(null),
  duration: Joi.number().min(1).max(365).integer().allow(null).label('Subtask Duration').messages({
    'number.base': 'Duration must be a number',
  }),
})

export const templateSchema = Joi.object({
  name: Joi.string().trim().max(30).messages({
    'string.empty': 'Name is not allowed to be empty',
    'string.max': 'Name is allowed to be less than or equal to 30 characters',
  }),
  description: Joi.string().max(200).allow(''),
  mailboxIds: null,
  priority: Joi.string().messages({
    'string.empty': 'Priority must be specified',
  }),
  status: Joi.string().messages({
    'string.empty': 'Status must be specified',
  }),
  duration: Joi.number().min(1).max(365).integer().messages({
    'number.base': 'Duration must be a number',
  }),
  assigneeId: Joi.number().min(1).messages({
    'number.base': 'Assignee must be specified',
  }),
  applyTo: Joi.string().messages({
    'string.empty': 'Available to is not allowed to be empty',
  }),
  subTasks: Joi.array().items(subTask).optional(),
})
