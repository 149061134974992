import NoDataChart from 'components/common/NoDataChart'
import SvgIcon from 'components/common/SvgIcon'
import React, {useState} from 'react'
import {FiActivity, FiChevronRight, FiFileText, FiList} from 'react-icons/fi'
import {
  VmoAccordion,
  VmoAccordionContent,
  VmoAccordionTitle,
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoContainer,
  VmoGrid,
  VmoGridColumn,
  VmoMenu,
  VmoMenuItem,
  VmoTooltip,
} from 'vmo-library'

const Overview = () => {
  const [active, setActive] = useState({
    overview: true,
    summary: true,
    activities: false,
    accorduan: '-1',
  })

  return (
    <>
      <VmoMenu vertical secondary className="projects-side-menu kadence-menu">
        <VmoMenuItem
          className="kadence-seg-item"
          active={active.summary}
          onClick={() => setActive({...active, summary: true, activities: false})}
        >
          <div className="d-flex-js">
            <FiList className="mr-1 mb-1" /> Summary
          </div>
        </VmoMenuItem>
        <VmoMenuItem
          className="kadence-seg-item"
          active={active.activities}
          onClick={() => setActive({...active, summary: false, activities: true})}
        >
          <div className="d-flex-js">
            <FiFileText className="mr-1 mb-1" /> Activities
          </div>
        </VmoMenuItem>
      </VmoMenu>

      <div className="project-discussion-side-wrap" style={{minHeight: '100%'}}>
        {active.summary ? (
          <>
            <VmoGrid>
              <VmoGridColumn width={16}>
                <VmoCard fluid className="chartBox">
                  <VmoCardContent className="headerBox">
                    <VmoCardHeader>Project Progress</VmoCardHeader>
                    <VmoTooltip
                      content="Breakdown of project progress over a time period"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </VmoCardContent>
                  <VmoCardContent className="pt-0" style={{height: 270}}>
                    <NoDataChart />
                  </VmoCardContent>
                </VmoCard>
              </VmoGridColumn>
            </VmoGrid>

            <VmoGrid columns="equal">
              <VmoGridColumn>
                <VmoCard fluid className="chartBox">
                  <VmoCardContent className="headerBox">
                    <VmoCardHeader>Tasks (16)</VmoCardHeader>
                    <VmoTooltip
                      content="Breakdown of tasks under project over a period of time"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </VmoCardContent>
                  <VmoCardContent className="pt-0" style={{height: 270}}>
                    <NoDataChart />
                  </VmoCardContent>
                </VmoCard>
              </VmoGridColumn>
              <VmoGridColumn>
                <VmoCard fluid className="chartBox">
                  <VmoCardContent className="headerBox">
                    <VmoCardHeader>Time On Tasks</VmoCardHeader>
                    <VmoTooltip
                      content="Graphical representation of time required for each task to complete"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </VmoCardContent>
                  <VmoCardContent className="pt-0" style={{height: 270}}>
                    <div className="empty-chart">No Data Available</div>
                  </VmoCardContent>
                </VmoCard>
              </VmoGridColumn>
              <VmoGridColumn>
                <VmoCard fluid className="chartBox">
                  <VmoCardContent className="headerBox">
                    <VmoCardHeader>Milestones (1)</VmoCardHeader>
                    <VmoTooltip
                      content="Breakdown of project milestone over specific period of time"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </VmoCardContent>
                  <VmoCardContent className="pt-0" style={{height: 270}}>
                    <NoDataChart />
                  </VmoCardContent>
                </VmoCard>
              </VmoGridColumn>
            </VmoGrid>

            <VmoGrid columns="equal">
              <VmoGridColumn>
                <VmoCard fluid className="chartBox">
                  <VmoCardContent className="headerBox">
                    <VmoCardHeader>Unresolved Conversations By Status</VmoCardHeader>
                    <VmoTooltip
                      content="An overview of number of unresolved conversations of specific status"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </VmoCardContent>
                  <VmoCardContent className="pt-0" style={{height: 270}}>
                    <NoDataChart />
                  </VmoCardContent>
                </VmoCard>
              </VmoGridColumn>
              <VmoGridColumn>
                <VmoCard fluid className="chartBox">
                  <VmoCardContent className="headerBox">
                    <VmoCardHeader>Unresolved Conversations By Priority</VmoCardHeader>
                    <VmoTooltip
                      content="An overview of number of unresolved conversations of specific priority"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </VmoCardContent>
                  <VmoCardContent className="pt-0" style={{height: 270}}>
                    <div className="empty-chart">No Data Available</div>
                  </VmoCardContent>
                </VmoCard>
              </VmoGridColumn>
              <VmoGridColumn>
                <VmoCard fluid className="chartBox">
                  <VmoCardContent className="headerBox">
                    <VmoCardHeader>Unresolved Conversations By Inbox</VmoCardHeader>
                    <VmoTooltip
                      content="An overview of number of unresolved conversations belonging to specific inbox"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </VmoCardContent>
                  <VmoCardContent className="pt-0" style={{height: 270}}>
                    <NoDataChart />
                  </VmoCardContent>
                </VmoCard>
              </VmoGridColumn>
            </VmoGrid>
          </>
        ) : null}

        {active.activities ? (
          <VmoContainer className="project-overview-activities">
            {['Nov 24', 'Nov 25', 'Nov 26'].map((e, index) => (
              <VmoCard fluid>
                <VmoCardContent>
                  <VmoAccordion className="mt-0">
                    <VmoAccordionTitle
                      className="d-flex"
                      style={{justifyContent: 'flex-start'}}
                      onClick={() =>
                        setActive({
                          ...active,
                          accorduan: active.accorduan === index ? '-1' : index,
                        })
                      }
                    >
                      {active.accorduan === index ? (
                        <SvgIcon path="common/down" style={{marginRight: 8}} />
                      ) : (
                        <FiChevronRight style={{marginRight: 8}} />
                      )}
                      {e}
                    </VmoAccordionTitle>
                    <VmoAccordionContent active={active.accorduan === index}>
                      {[...Array(20)].map(() => (
                        <div className="kadence-timeline product-overview-activites">
                          <div className="icon">
                            <FiActivity />
                          </div>
                          <div className="content">
                            <article>
                              Agent Name&nbsp;
                              <span style={{color: '#616fc7'}}>Activity Description</span>
                              <br />
                              Activity Value
                            </article>
                          </div>
                          <div className="time">Nov 19</div>
                        </div>
                      ))}
                    </VmoAccordionContent>
                  </VmoAccordion>
                </VmoCardContent>
              </VmoCard>
            ))}
          </VmoContainer>
        ) : null}
      </div>
    </>
  )
}

export default Overview
