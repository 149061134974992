import Joi from 'joi'
import {PROJECT_FINANCE_BUDGET_TYPES} from 'constants/projects'

export const createProjectTemplateDetailsSchema = Joi.object({
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  description: Joi.string().trim().max(500).allow('', null).messages({
    'string.max': `Length must be less than or equal to 500 characters`,
  }),
  budgetType: Joi.string()
    .valid(...Object.values(PROJECT_FINANCE_BUDGET_TYPES))
    .required(),
  rangeType: Joi.string().valid('financial', 'time').required(),
  budgetAmount: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.NO_BUDGET,
    then: Joi.number().default(0),
    otherwise: Joi.when('rangeType', {
      is: 'financial',
      then: Joi.number().min(1).required().messages({
        'number.min': 'Please enter a value greater than 0',
      }),
      otherwise: Joi.number().default(0),
    }),
  }),
  budgetTimeHour: Joi.number().integer(),
  budgetTimeMinute: Joi.number().integer(),
  timelogType: Joi.when('budgetType', {
    is: Joi.valid(PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER),
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  repeatUnit: Joi.when('budgetType', {
    is: Joi.valid(PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER),
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  carryUnderspend: Joi.boolean().default(false),
  carryOverspend: Joi.boolean().default(false),
  budgetProfitMargin: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    then: Joi.number().default(0),
    otherwise: Joi.any().default(0),
  }),
  budgetTargetProfit: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    then: Joi.number().default(0),
    otherwise: Joi.any().default(0),
  }),
  budgetTargetCosts: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    then: Joi.number().default(0),
    otherwise: Joi.any().default(0),
  }),
})

export const createProjectTemplateAdvanceOptionSchema = Joi.object({
  assignedTo: Joi.array().items(Joi.number()),
  managerId: Joi.number().allow(null),
  contactIds: Joi.array().items(Joi.number()),
  categoryId: Joi.any(),
})

export const editProjectTemplateDetailsSchema = Joi.object({
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  description: Joi.string().trim().max(500).allow('', null).messages({
    'string.max': `Length must be less than or equal to 500 characters`,
  }),
})

export const templateTaskListSchema = Joi.object({
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  subscribers: Joi.array(),
})

export const templateTasklistTaskSchema = Joi.object({
  type: Joi.string().required().messages({
    'string.empty': 'Type must be specified',
  }),
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  description: Joi.string().trim().allow('').max(200).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
  estimatedHrs: Joi.number().integer().default(0).label('Hour').messages({
    'number.base': 'Hour must be specified',
  }),
  estimatedMin: Joi.number().integer().default(0).label('Minute').messages({
    'number.base': 'Minute must be specified',
  }),
  stageId: Joi.number().required().messages({
    'number.base': `Stage must be specified`,
  }),
  labelIds: Joi.array().items(Joi.number()),
  assignedTo: Joi.number().allow(null),
})

export const templateFinanceBudgetSchema = Joi.object({
  budgetType: Joi.string()
    .valid(
      PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS,
      PROJECT_FINANCE_BUDGET_TYPES.RETAINER,
      PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE
    )
    .required(),
  rangeType: Joi.string().valid('financial', 'time').required(),
  budgetAmount: Joi.when('rangeType', {
    is: 'financial',
    then: Joi.number().min(1).required().messages({
      'number.min': 'Please enter a value greater than 0',
    }),
    otherwise: Joi.number().default(0),
  }),
  budgetTimeHour: Joi.number().integer(),
  budgetTimeMinute: Joi.number().integer(),
  timelogType: Joi.when('budgetType', {
    is: Joi.valid(PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER),
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  repeatUnit: Joi.when('budgetType', {
    is: Joi.valid(PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER),
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  carryUnderspend: Joi.boolean().default(false),
  carryOverspend: Joi.boolean().default(false),
  isDefaultBillableRateSet: Joi.boolean(),
  defaultBillableRate: Joi.number(),
  budgetProfitMargin: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    then: Joi.number().default(0),
    otherwise: Joi.any().default(0),
  }),
  budgetTargetProfit: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    then: Joi.number().default(0),
    otherwise: Joi.any().default(0),
  }),
  budgetTargetCosts: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    then: Joi.number().default(0),
    otherwise: Joi.any().default(0),
  }),
})
