import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveHeatMap} from '@nivo/heatmap'
import {ResponsivePie} from '@nivo/pie'
import {Progress} from 'semantic-ui-react'
import {VmoCard, VmoCardContent, VmoCardHeader, VmoGrid, VmoGridColumn, VmoTooltip} from 'vmo-library'

import {
  INFLOW_BY_CHANNEL_VS_TIME,
  INFLOW_BY_DATE_ACROSS_TIME,
  OPEN_CONVERSATION_INBOX,
  OPEN_CONVERSATION_INBOX_PRIORITY,
  OPEN_CONVERSATION_INBOX_STATUS,
} from 'actions/types'
import {
  inflowByChannelVsTime,
  inflowByDateCrossTime,
  openConversationsByInbox,
  openConversationsByInboxPriority,
  openConversationsByInboxStatus,
} from 'actions/analytics_overview'
import NoDataChart from 'components/common/NoDataChart'
import CustomLoader from 'components/common/CustomLoader'
import {getGridYValuesBar, getGridYValuesMultipleBar, maxOfGraphBar, maxOfGraphMultipleBar} from 'utils/graph'
import {wrapText, get} from 'utils/helper'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import {AssigneeDropdown, MailboxDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const ValueOutside = ({bars}) => {
  return bars.map(bar => {
    const {
      key,
      width,
      height,
      x,
      y,
      data: {value},
    } = bar
    return (
      <g key={key} transform={`translate(${x}, ${y})`}>
        <text transform={`translate(${width + 5}, ${height / 2 + 3})`} textAnchor="middle" fontSize="11px">
          {value !== 0 ? value : null}
        </text>
      </g>
    )
  })
}

const TotalLabels = ({bars, yScale}) => {
  const labelMargin = 15

  return bars.map(({data: {data, indexValue}, x, width}, i) => {
    const total = Object.keys(data)
      .filter(
        key =>
          key !== 'EmailColor' &&
          key !== 'Support FormColor' &&
          key !== 'TwitterColor' &&
          key !== 'FacebookColor' &&
          key !== 'InstagramColor' &&
          key !== 'Whats AppColor' &&
          key !== 'Internal TicketColor' &&
          key !== 'HelpdeskColor' &&
          key !== 'ChatColor' &&
          key !== 'time'
      )
      .reduce((a, key) => a + data[key], 0)

    if (!total) {
      return null
    }

    return (
      <g transform={`translate(${x}, ${yScale(total) - labelMargin})`} key={`${indexValue}-${i}`}>
        <text x={width / 2} y={labelMargin / 2} textAnchor="middle" alignmentBaseline="central" fontSize={10}>
          {total}
        </text>
      </g>
    )
  })
}

const Overview = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)
  const {
    openConversationInboxStatusData = [],
    openConversationInboxPriorityData = [],
    openConversationInboxData = [],
    inflowByChannelVsTimeData = [],
    inflowByDateCrossTimeData = {},
  } = useSelector(state => state.analyticsOverview)
  const {dataArray = [], timeArray = []} = inflowByDateCrossTimeData
  const {analyticsSelectedInbox, analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  const {reportsPermissions} = useUserPermissions()
  const conversationsReportsPermissions = reportsPermissions?.conversations_reports

  const totalInflowByDataCrossTimeData = () => {
    const values = dataArray.map(data => data.percentage).reduce((a, b) => a + b, 0)
    return values.toFixed(1)
  }

  const actionsToDispatch = useCallback(
    data => {
      dispatch(openConversationsByInboxStatus(data))
      dispatch(openConversationsByInboxPriority(data))
      dispatch(openConversationsByInbox(data))
      dispatch(inflowByChannelVsTime(data))
      dispatch(inflowByDateCrossTime(data))
    },
    [dispatch]
  )

  return (
    <div className="kadence-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="common/book" />
            <h5>Overview</h5>
          </div>
        </div>
        {conversationsReportsPermissions && (
          <div className="page-action">
            <MailboxDropdown />
            <AssigneeDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              teamId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'team'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              inboxId={
                analyticsSelectedInbox !== 'all' && analyticsSelectedInbox ? Number(analyticsSelectedInbox) : null
              }
            />
          </div>
        )}
      </div>

      <VmoGrid columns="equal">
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Unresolved Conversaritons by Status</VmoCardHeader>
              <VmoTooltip
                content="An overview of number of unresolved conversations of specific status"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 270}}>
              {type.includes(OPEN_CONVERSATION_INBOX_STATUS) && <CustomLoader />}
              {!type.includes(OPEN_CONVERSATION_INBOX_STATUS) &&
              !openConversationInboxStatusData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={openConversationInboxStatusData.map(item => ({
                    ...item,
                    label: `${item.label}: ${item.percentage.toFixed(1)}%`,
                  }))}
                  margin={{top: 30, right: 30, bottom: 30, left: 120}}
                  startAngle={-50}
                  innerRadius={0.6}
                  padAngle={1}
                  cornerRadius={1}
                  colors={d => d.data.color}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.4]],
                  }}
                  arcLinkLabel={e => `${e.id} (${e.value})`}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsStraightLength={10}
                  arcLinkLabelsDiagonalLength={5}
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{from: 'color'}}
                  enableArcLabels={false}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                  }}
                  animate
                  legends={[
                    {
                      anchor: 'left',
                      direction: 'column',
                      justify: false,
                      translateX: -120,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemWidth: 105,
                      itemHeight: 20,
                      itemTextColor: '#999',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(OPEN_CONVERSATION_INBOX_STATUS) && <NoDataChart />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Unresolved Conversaritons by Priority</VmoCardHeader>
              <VmoTooltip
                content="An overview of number of unresolved conversations of specific priority"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 270}}>
              {type.includes(OPEN_CONVERSATION_INBOX_PRIORITY) && <CustomLoader />}
              {!type.includes(OPEN_CONVERSATION_INBOX_PRIORITY) &&
                openConversationInboxPriorityData.every(({value}) => value === 0) && (
                  <div className="empty-chart" style={{marginLeft: '10px'}}>
                    No Data Available
                  </div>
                )}
              {!type.includes(OPEN_CONVERSATION_INBOX_PRIORITY) && (
                <ResponsiveBar
                  data={openConversationInboxPriorityData}
                  keys={['critical', 'high', 'medium', 'low', 'default']}
                  layout="horizontal"
                  indexBy="priority"
                  margin={{
                    top: 10,
                    right: 10,
                    bottom: 60,
                    left: 60,
                  }}
                  padding={0.3}
                  colors={d => d.data.color}
                  minValue={0}
                  maxValue={maxOfGraphBar(openConversationInboxPriorityData, 'value')}
                  enableGridX
                  enableGridY={false}
                  gridXValues={getGridYValuesBar(openConversationInboxPriorityData, 'value')}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Priority',
                    legendPosition: 'middle',
                    legendOffset: -50,
                  }}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(openConversationInboxPriorityData, 'value'),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  enableLabel={false}
                  labelSkipWidth={1}
                  layers={[
                    'grid',
                    'axes',
                    'bars',
                    'markers',
                    'legends',
                    'annotations',
                    props => <ValueOutside {...props} />,
                  ]}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Unresolved Conversations by Inbox</VmoCardHeader>
              <VmoTooltip
                content="An overview of number of unresolved conversations belonging to specific inbox"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 270}}>
              {type.includes(OPEN_CONVERSATION_INBOX) && <CustomLoader />}
              {!type.includes(OPEN_CONVERSATION_INBOX) && !openConversationInboxData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={openConversationInboxData.map(item => ({
                    ...item,
                    label: `${wrapText(item.label, 10)}: ${item.percentage.toFixed(1)}%`,
                  }))}
                  margin={{top: 30, right: 120, bottom: 30, left: 30}}
                  startAngle={-50}
                  innerRadius={0.7}
                  padAngle={1}
                  cornerRadius={1}
                  colors={d => d.data.color}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.4]],
                  }}
                  arcLinkLabel={e => `${e.id} (${e.value})`}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsStraightLength={10}
                  arcLinkLabelsDiagonalLength={5}
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{from: 'color'}}
                  enableArcLabels={false}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                  }}
                  animate
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 150,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 150,
                      itemHeight: 10,
                      itemTextColor: '#999',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(OPEN_CONVERSATION_INBOX) && <NoDataChart />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Incoming Messages</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of incoming messages based on their respective channels"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 380}}>
              {type.includes(INFLOW_BY_CHANNEL_VS_TIME) && <CustomLoader />}
              {!type.includes(INFLOW_BY_CHANNEL_VS_TIME) &&
                inflowByChannelVsTimeData.every(
                  val =>
                    val.Email === 0 &&
                    val['Support Form'] === 0 &&
                    val.Twitter === 0 &&
                    val.Facebook === 0 &&
                    val.Instagram === 0 &&
                    val['Whats App'] === 0 &&
                    val['Internal Ticket'] === 0 &&
                    val.Helpdesk === 0 &&
                    val.Chat === 0
                ) && <div className="empty-chart">No Data Available</div>}
              {!type.includes(INFLOW_BY_CHANNEL_VS_TIME) && (
                <ResponsiveBar
                  data={inflowByChannelVsTimeData}
                  keys={[
                    'Email',
                    'Support Form',
                    'Twitter',
                    'Facebook',
                    'Instagram',
                    'Whats App',
                    'Internal Ticket',
                    'Helpdesk',
                    'Chat',
                  ]}
                  indexBy="time"
                  margin={{top: 47, right: 27, bottom: 70, left: 60}}
                  padding={0.3}
                  minValue={0}
                  maxValue={maxOfGraphMultipleBar(inflowByChannelVsTimeData, [
                    'Email',
                    'Support Form',
                    'Twitter',
                    'Facebook',
                    'Instagram',
                    'Whats App',
                    'Internal Ticket',
                    'Helpdesk',
                    'Chat',
                  ])}
                  gridYValues={getGridYValuesMultipleBar(inflowByChannelVsTimeData, [
                    'Email',
                    'Support Form',
                    'Twitter',
                    'Facebook',
                    'Instagram',
                    'Whats App',
                    'Internal Ticket',
                    'Helpdesk',
                    'Chat',
                  ])}
                  valueScale={{type: 'linear'}}
                  indexScale={{type: 'band', round: true}}
                  colors={d => d.data[`${d.id}Color`]}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 60,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesMultipleBar(inflowByChannelVsTimeData, [
                      'Email',
                      'Support Form',
                      'Twitter',
                      'Facebook',
                      'Instagram',
                      'Whats App',
                      'Internal Ticket',
                      'Helpdesk',
                      'Chat',
                    ]),
                    legend: 'Channel',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  enableLabel={false}
                  labelSkipHeight={1}
                  layers={['grid', 'axes', 'bars', TotalLabels, 'markers', 'legends']}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 1,
                      itemWidth: 95,
                      itemHeight: 30,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Conversations Distribution</VmoCardHeader>
              <VmoTooltip
                content="Heatmap shows the distribution conversation over time"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
              <div className="heatMapBar" style={{margin: '15px 50px 0 auto'}}>
                least busy
                <div className="value" style={{background: 'rgba(29, 137, 255, 0.4)'}} />
                <div className="value" style={{background: 'rgba(29, 137, 255, 0.55)'}} />
                <div className="value" style={{background: 'rgba(29, 137, 255, 0.7)'}} />
                <div className="value" style={{background: 'rgba(29, 137, 255, 0.85)'}} />
                <div className="value  mr-3" />
                busiest
              </div>
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 500}}>
              {type.includes(INFLOW_BY_DATE_ACROSS_TIME) && <CustomLoader />}
              <VmoGrid>
                <VmoGridColumn width={14} style={{height: 500}}>
                  {!type.includes(INFLOW_BY_DATE_ACROSS_TIME) && (
                    <ResponsiveHeatMap
                      data={dataArray}
                      keys={timeArray}
                      indexBy="date"
                      margin={{
                        top: 20,
                        right: 0,
                        bottom: 30,
                        left: 80,
                      }}
                      cellOpacity={1}
                      padding={3}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        orient: 'top',
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 44,
                      }}
                      axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Time',
                        legendPosition: 'middle',
                        legendOffset: -70,
                      }}
                      colors={[
                        'rgba(29, 137, 255, 0.4)',
                        'rgba(29, 137, 255, 0.55)',
                        'rgba(29, 137, 255, 0.7)',
                        'rgba(29, 137, 255, 0.85)',
                        'rgba(29, 137, 255, 1)',
                      ]}
                      labelTextColor="#ffffff"
                      animate={false}
                      motionStiffness={100}
                      motionDamping={9}
                      hoverTarget="cell"
                      cellHoverOthersOpacity={1}
                    />
                  )}
                  {/* <div className="heatMapBelowbar" style={{marginTop: -20}}>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                  </div> */}
                </VmoGridColumn>
                <VmoGridColumn width={2} style={{height: 500}}>
                  {!type.includes(INFLOW_BY_DATE_ACROSS_TIME) && (
                    <>
                      <div style={{height: 20}} className="ml-4 mr-4">
                        Total ({totalInflowByDataCrossTimeData()}%)
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: 432,
                        }}
                        className="heatMapSidebar pt-0 ml-4 mr-4"
                      >
                        {dataArray.map((data, index) => {
                          return (
                            <div className="heatMapSidebarItem" key={index}>
                              <div
                                style={{
                                  position: 'relative',
                                  top: '50%',
                                }}
                              >
                                <Progress
                                  percent={data.percentage}
                                  size="tiny"
                                  color="blue"
                                  style={{
                                    margin: 0,
                                    width: 70,
                                    float: 'left',
                                  }}
                                />
                                <div
                                  style={{
                                    marginTop: -3,
                                    float: 'right',
                                  }}
                                >
                                  {get(['percentage'], data, 0).toFixed(1)}%
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )}
                </VmoGridColumn>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Overview
