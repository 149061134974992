import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {Controller} from 'react-hook-form'
import {
  VmoButton,
  VmoCheckbox,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

function NumberBox({setToggleModal, toggleModal, activeSection, index, control, data, watch, reset}) {
  const watchMaxPoints = watch(`templateSections[${activeSection}].sectionFields[${index}].withPoints`)

  return (
    <VmoModal
      open={toggleModal === 'number_box'}
      onClose={() => setToggleModal(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      centered={false}
    >
      <VmoModalHeader className="vmoModalHeader">Edit Number Box</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Question</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].question`}
              control={control}
              defaultValue={watch(
                `templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].question`
              )}
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  placeholder="Enter Question"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  onBlur={onBlur}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Input For</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].inputFor`}
              defaultValue={watch(
                `templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].inputFor`
              )}
              render={({value, onChange}) => (
                <VmoFormSelect
                  search
                  fluid
                  options={[
                    {key: 'both', value: 'both', text: 'Both'},
                    {key: 'employee', value: 'employee', text: 'Employee'},
                    {key: 'Reviewer', value: 'Reviewer', text: 'Reviewer'},
                  ]}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                />
              )}
              control={control}
            />
          </VmoFormField>
          <VmoFormField>
            <Controller
              control={control}
              name={`templateSections[${activeSection}].sectionFields[${index}].withPoints`}
              defaultValue={watch(`templateSections[${activeSection}].sectionFields[${index}].withPoints`)}
              render={({onChange, value}) => (
                <VmoFormField>
                  <div className="info-header">
                    <label>Enable Points?</label>
                  </div>
                  <VmoCheckbox checked={value} onChange={(e, {checked}) => onChange(checked)} />
                </VmoFormField>
              )}
            />
          </VmoFormField>
          {watchMaxPoints ? (
            <VmoFormField width={8}>
              <Controller
                name={`templateSections[${activeSection}].sectionFields[${index}].maxPoints`}
                defaultValue={watch(`templateSections[${activeSection}].sectionFields[${index}].maxPoints`)}
                render={({onChange, value}) => (
                  <VmoFormInput type="number" onChange={(e, {value}) => onChange(+value)} value={value} />
                )}
                control={control}
              />
            </VmoFormField>
          ) : null}
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton onClick={() => setToggleModal(false)}>Cancel</VmoButton>
        <VmoButton primary onClick={() => setToggleModal(false)}>
          Ok
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default NumberBox
