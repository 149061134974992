import React from 'react'
import {Controller} from 'react-hook-form'
import {
  VmoDatePicker,
  VmoDropdown,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoGrid,
  VmoGridColumn,
  VmoGridRow,
  VmoTooltip,
} from 'vmo-library'
import {removeDoubleQuotes, KadenceCountryList} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const EmployeePersonalInformation = props => {
  const {errors, control, DateOfBirth, setDateOfBirth} = props

  return (
    <VmoForm className="errorLabel">
      <VmoGrid>
        <VmoGridRow>
          <VmoGridColumn width={8}>
            <h5 className="mb-0">Personal Details</h5>
            <p className="mt-0 kadence-lead mb-4">Mentions all personal details of the employee</p>

            <VmoFormField width={15}>
              <div className="info-header mb-1">
                <label>ID type</label>
                <VmoTooltip
                  content="choose ID type provided by employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.identityType"
                render={({onChange, value}) => (
                  <VmoDropdown
                    fluid
                    selection
                    options={[
                      {value: 'national_id', key: 'national_id', text: 'National ID'},
                      {value: 'passport', key: 'passport', text: 'Passport'},
                      {value: 'others', key: 'others', text: 'Others'},
                    ]}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors?.personalInfo?.identityType && {
                        content: removeDoubleQuotes(errors?.personalInfo?.identityType?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </VmoFormField>

            <VmoFormField width={15}>
              <div className="info-header mb-1">
                <label>ID Number</label>
                <VmoTooltip
                  content="write the ID number of employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.identityNumber"
                render={({value, onChange, onBlur}) => (
                  <VmoFormInput
                    fluid
                    type="text"
                    placeholder="ID Number.."
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errors?.personalInfo?.identityNumber && {
                        content: errors?.personalInfo?.identityNumber?.message,
                      }
                    }
                  />
                )}
                control={control}
              />{' '}
            </VmoFormField>

            <VmoFormField width={15}>
              <div className="info-header mb-1">
                <label>Email</label>
                <VmoTooltip
                  content="email id of the employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.email"
                render={({value, onChange, onBlur}) => (
                  <VmoFormInput
                    fluid
                    type="email"
                    placeholder="Type your Email.."
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errors?.personalInfo?.email && {
                        content: errors?.personalInfo?.email?.message,
                      }
                    }
                  />
                )}
                control={control}
              />
            </VmoFormField>

            <VmoFormField width={15}>
              <div className="info-header mb-1">
                <label>Phone</label>
                <VmoTooltip
                  content="employees phone number"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.phone"
                render={({value, onChange, onBlur}) => (
                  <VmoFormInput
                    fluid
                    type="text"
                    name="phone"
                    placeholder="Type your number.."
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errors?.personalInfo?.phone && {
                        content: errors?.personalInfo?.phone?.message,
                      }
                    }
                  />
                )}
                control={control}
              />
            </VmoFormField>

            <VmoFormField width={15}>
              <div className="info-header mb-1">
                <label>Address</label>
                <VmoTooltip
                  content="specify address of the employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.address"
                control={control}
                render={({onChange, value}) => (
                  <VmoFormTextArea
                    type="textarea"
                    value={value}
                    placeholder="Write your address here"
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors?.personalInfo?.address && {
                        content: errors?.personalInfo?.address?.message,
                      }
                    }
                  />
                )}
              />
            </VmoFormField>

            <VmoFormField width={15}>
              <div className="info-header mb-1">
                <label>City</label>
                <VmoTooltip
                  content="mention city of employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.city"
                render={({onChange, value}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="city"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors?.personalInfo?.city && {
                        content: removeDoubleQuotes(errors?.personalInfo?.city?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </VmoFormField>

            <VmoFormField width={15}>
              <div className="info-header mb-1">
                <label>Country</label>
                <VmoTooltip
                  content="employees country name"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.country"
                render={({onChange, value, onBlur}) => (
                  <VmoFormSelect
                    search
                    fluid
                    options={KadenceCountryList}
                    selection
                    placeholder="Country"
                    onBlur={onBlur}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors?.personalInfo?.country && {
                        content: removeDoubleQuotes(errors?.personalInfo?.country?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </VmoFormField>
          </VmoGridColumn>

          <VmoGridColumn width={8} style={{marginTop: '61px'}}>
            <VmoFormField width={15}>
              <div className="info-header mb-1">
                <label>Highest Qualification</label>
                <VmoTooltip
                  content="mention highest qualification of employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.highestQualification"
                render={({onChange, value}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="highest Qualification"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors?.personalInfo?.highestQualification && {
                        content: removeDoubleQuotes(errors?.personalInfo?.highestQualification?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </VmoFormField>

            <VmoFormField width={15}>
              <div className="info-header mb-1">
                <label>Field of Study</label>
                <VmoTooltip
                  content="mention field of study for your employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.educationalField"
                render={({onChange, value}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="Educational Field"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors?.personalInfo?.educationalField && {
                        content: removeDoubleQuotes(errors?.personalInfo?.educationalField?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </VmoFormField>

            <VmoFormField width={15}>
              <div className="info-header mb-1">
                <label>University</label>
                <VmoTooltip
                  content="mention the university name"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.university"
                render={({onChange, value}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="University"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors?.personalInfo?.university && {
                        content: removeDoubleQuotes(errors?.personalInfo?.university?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </VmoFormField>

            <VmoFormField width={15}>
              <div className="info-header mb-1">
                <label>Date of Birth</label>
                <VmoTooltip
                  content="set employee DOB"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.dateOfBirth"
                render={({onChange, value}) => (
                  <VmoDatePicker
                    type="date"
                    value={typeof value === 'string' ? new Date(DateOfBirth) : DateOfBirth}
                    inputProps={{readOnly: true}}
                    time={false}
                    onChange={value => {
                      onChange(value)
                      setDateOfBirth(value)
                    }}
                    error={
                      errors?.personalInfo?.dateOfBirth && {
                        content: removeDoubleQuotes(errors?.personalInfo.dateOfBirth?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </VmoFormField>
          </VmoGridColumn>
        </VmoGridRow>
      </VmoGrid>
    </VmoForm>
  )
}

export default EmployeePersonalInformation
