/* eslint-disable react/no-this-in-sfc */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {Calendar, momentLocalizer, Navigate} from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'styles/big-cal.css'
// import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
// import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import TimeGrid from 'react-big-calendar/lib/TimeGrid'

import {GET_ALL_CALENDAR_EVENTS} from 'actions/types'
import {getTaskActivities} from 'actions/activities'
import ViewTask from 'components/pages/Projects/Tasks/ViewTask'
import ProjectViewTask from 'components/pages/Projects/Projects/Steps/Tasks/ViewTask'
import * as dates from 'date-arithmetic'

const localizer = momentLocalizer(moment)
// const DnDCalendar = withDragAndDrop(Calendar);

const CalendarView = props => {
  const {currentDate, calendarView, setCurrentDate, datePosition, setDatePosition, setCalendarView} = props

  const [editEventType, setEditEventType] = useState('')
  const [editEventTaskFrom, setEditEventTaskFrom] = useState('')
  const [editEventProjectId, setEditEventProjectId] = useState(null)
  const [editEventTaskId, setEditEventTaskId] = useState(null)

  const dispatch = useDispatch()

  const [events, setEvent] = useState([])

  const {calendarEvents = []} = useSelector(state => state.calendar)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  class MyWeek extends React.Component {
    render() {
      const {date} = this.props

      const range = MyWeek.range(date)

      return <TimeGrid {...this.props} range={range} eventOffset={15} />
    }
  }

  MyWeek.range = date => {
    const diff = currentDate.to.diff(currentDate.from, 'days')

    const start = date
    const end = dates.add(start, diff, 'day')

    let current = start
    const range = []

    while (dates.lte(current, end, 'day')) {
      range.push(current)
      current = dates.add(current, 1, 'day')
    }

    return range
  }

  MyWeek.navigate = (date, action) => {
    const diff = currentDate.to.diff(currentDate.from, 'days')
    switch (action) {
      case Navigate.PREVIOUS:
        return dates.add(date, -diff, 'day')

      case Navigate.NEXT:
        return dates.add(date, diff, 'day')

      default:
        return date
    }
  }

  MyWeek.title = date => {
    // return `My awesome week: ${date.toLocaleDateString()}`;
  }

  const handleSelectEvent = event => {
    if (['call', 'meeting', 'task', 'event', 'milestone'].includes(event.resource) && event.taskFrom === 'global') {
      dispatch(getTaskActivities(event.id))
    } else if (['task', 'event', 'milestone'].includes(event.resource) && event.taskFrom === 'project') {
      setEditEventProjectId(event.projectId)
      setEditEventTaskId(event.id)
    }
    setEditEventType(event.resource)
    setEditEventTaskFrom(event.taskFrom)
  }

  const handleRangeChange = date => {
    let range = {}
    if (Array.isArray(date)) {
      setDatePosition(
        new Date(
          (moment(date[0]).startOf('day').toDate().getTime() +
            moment(date[date.length - 1])
              .endOf('day')
              .toDate()
              .getTime()) /
            2
        )
      )

      range = {
        from: moment(date[0]),
        to: moment(date[date.length - 1]),
      }
    } else {
      setDatePosition(
        new Date(
          (moment(date.start).startOf('day').toDate().getTime() + moment(date.end).endOf('day').toDate().getTime()) / 2
        )
      )

      range = {
        from: moment(date.start),
        to: moment(date.end),
      }
    }

    setCurrentDate(range)
  }

  const handleStylingEvent = type => {
    if (type === 'call') {
      return {
        borderColor: '#1e90ff',
        background: '#dcefff',
        font: '#205080',
      }
    }
    if (type === 'meeting') {
      return {
        borderColor: '#9e61b9',
        background: '#f4dcff',
        font: '#7d4b94',
      }
    }
    if (type === 'activity') {
      return {
        borderColor: '#dc6060',
        background: '#ffdcdc',
        font: '#a95f5f',
      }
    }
    if (type === 'calendar_event') {
      return {
        borderColor: '#9ecc50',
        background: '#f2ffdc',
        font: '#7a9251',
      }
    }
  }

  useEffect(() => {
    if (successLabels.includes(GET_ALL_CALENDAR_EVENTS)) {
      const events = calendarEvents.map(event => ({
        ...event,
        start: moment(event.start)._d,
        end: moment(event.end)._d,
        title: `${moment(event.start).format('HH:mm')} ${event.title}`,
      }))
      setEvent(events)
    }
  }, [successLabels])

  return (
    <>
      <Calendar
        localizer={localizer}
        events={events}
        date={datePosition}
        onRangeChange={handleRangeChange}
        // defaultView="week"
        // onNavigate={handleRangeChange}
        onView={view => {
          setCalendarView(view)
        }}
        views={{
          day: true,
          week: MyWeek,
          month: true,
          agenda: true,
        }}
        view={calendarView}
        eventPropGetter={(e, start, end, isSelected) => ({
          className: 'calendar_event',
          style: handleStylingEvent(e.resource),
        })}
        dayPropGetter={date => ({
          className: 'calendar_day',
        })}
        slotPropGetter={date => ({className: 'calendar_slot'})}
        onSelectEvent={handleSelectEvent}
        formats={{
          agendaHeaderFormat: (date, localizer) =>
            `${moment(date.start).format('DD MMM YYYY')} - ${moment(date.end).format('DD MMM YYYY')}`,
        }}
        popup
      />

      {['call', 'meeting', 'task', 'event', 'milestone'].includes(editEventType) && editEventTaskFrom === 'global' && (
        <ViewTask
          setIsViewTaskOpen={setEditEventType}
          isViewTaskOpen={['call', 'meeting', 'task', 'event', 'milestone'].includes(editEventType)}
        />
      )}

      {['task', 'event', 'milestone'].includes(editEventType) && editEventTaskFrom === 'project' && (
        <ProjectViewTask
          open={editEventType}
          toggle={setEditEventType}
          projectId={editEventProjectId}
          taskId={editEventTaskId}
        />
      )}
    </>
  )
}

export default CalendarView
