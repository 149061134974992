import {ESTIMATED_REVENUE, WIN_RATIO} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case ESTIMATED_REVENUE:
      return {
        ...state,
        estimatedRevenueData: action.payload,
      }
    case WIN_RATIO:
      return {
        ...state,
        winratioData: action.payload,
      }
    default:
      return state
  }
}
