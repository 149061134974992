/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoModal,
  VmoModalHeader,
  VmoModalContent,
  VmoForm,
  VmoFormField,
  VmoTextArea,
  VmoModalActions,
  VmoButton,
  VmoDatePicker,
  VmoFormDropdown,
} from 'vmo-library'
import {GET_LOST_REASONS, LOSS_DEAL} from 'actions/types'
import {lossDeal} from 'actions/deals'
import {getLostReasons} from 'actions/custom_options'
import {startCase} from 'utils/helper'
import {lostModalSchema} from 'validation/Sales/deals.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const LostModal = props => {
  const dispatch = useDispatch()
  const {isModalOpen, setIsModalOpen, Id} = props
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {data: lostReasonsData = []} = useApiResponse({
    action: getLostReasons,
    enabled: true,
    label: GET_LOST_REASONS,
    storePath: 'custom_option.lostReasonsData',
  })

  useEffect(() => {
    if (successLabels.includes(LOSS_DEAL)) {
      setIsModalOpen(false)
    }
  }, [successLabels])

  const {control, errors, handleSubmit} = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      lossReasonId: null,
      closedNote: '',
      closedAt: new Date(),
    },
    resolver: joiResolver(lostModalSchema),
  })

  return (
    <VmoModal
      size="tiny"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <VmoModalHeader>Lost Deal</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Add a loss reason and add any additional notes below</label>
            <Controller
              control={control}
              name="lossReasonId"
              render={({onChange, value}) => (
                <VmoFormDropdown
                  placeholder="Loss Reasons"
                  fluid
                  selection
                  options={lostReasonsData.map(reason => ({
                    key: reason.id,
                    text: startCase(reason.reason),
                    value: reason.id,
                  }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.lossReasonId && {
                      content: errors.lossReasonId.message,
                    }
                  }
                />
              )}
            />
          </VmoFormField>
          <VmoFormField>
            <label>Add Note</label>
            <Controller
              control={control}
              name="closedNote"
              render={({onChange, value}) => (
                <VmoTextArea
                  placeholder="type here..."
                  className="mb-3"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                />
              )}
            />
            <p className="kadence-lead">
              Lost opportunities can be seen in the opportunities list by selecting the status filter and checking the
              'Lost' box
            </p>
          </VmoFormField>

          <VmoFormField required>
            <label>Closed Date</label>
            <Controller
              control={control}
              name="closedAt"
              render={({onChange, value}) => (
                <VmoDatePicker
                  inputProps={{readOnly: true}}
                  className="vmo-picker"
                  value={value}
                  onChange={value => onChange(value)}
                  min={new Date()}
                  time={false}
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton
          basic
          onClick={() => {
            setIsModalOpen(false)
          }}
        >
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(data => dispatch(lossDeal(Id, data)))}>
          Save
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default LostModal
