import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  VmoModal,
  VmoModalHeader,
  VmoModalContent,
  VmoForm,
  VmoFormField,
  VmoModalActions,
  VmoButton,
  VmoTextArea,
} from 'vmo-library'
import {addNoteCompany} from 'actions/company'
import {addNoteContact} from 'actions/contacts'
import {addNoteDeal} from 'actions/deals'
import SvgIcon from './SvgIcon'

const AddNote = props => {
  const {isNoteOpen, companyId, contactId, setIsNoteOpen, dealId} = props
  const [noteText, setNoteText] = useState('')

  const dispatch = useDispatch()

  const onEnter = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()

      const data = {
        note: noteText,
      }

      if (noteText.length !== 0 && noteText.trim() !== '') {
        if (companyId) {
          dispatch(addNoteCompany(companyId, data))
        }
        if (contactId) {
          dispatch(addNoteContact(contactId, data))
        }
        if (dealId) {
          dispatch(addNoteDeal(dealId, data))
        }
      }
    }
  }

  return (
    <VmoModal
      size="tiny"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isNoteOpen}
      onClose={() => setIsNoteOpen(!isNoteOpen)}
    >
      <VmoModalHeader>Add Note</VmoModalHeader>
      <VmoModalContent>
        <VmoForm>
          <VmoFormField>
            <VmoTextArea
              type="textarea"
              name="addNote"
              value={noteText}
              placeholder="Type your note.."
              onChange={e => {
                setNoteText(e.target.value)
              }}
              onKeyDown={e => onEnter(e)}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsNoteOpen(!isNoteOpen)}>
          Cancel
        </VmoButton>
        <VmoButton
          primary
          disabled={noteText.trim() === ''}
          onClick={() => {
            const data = {
              note: noteText,
            }

            if (noteText.length !== 0 && noteText.trim() !== '') {
              if (companyId) {
                dispatch(addNoteCompany(companyId, data))
              }
              if (contactId) {
                dispatch(addNoteContact(contactId, data))
              }
              if (dealId) {
                dispatch(addNoteDeal(dealId, data))
              }
            }
          }}
        >
          Post Note
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default AddNote
