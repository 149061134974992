import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveLine} from '@nivo/line'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoDropdown,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {TEMPLATE_OVER_TIME} from 'actions/types'
import {getEmailTemplate} from 'actions/settings'
import {emailTemplate, templateOverTime} from 'actions/reports_template'
import {startCase} from 'utils/helper'
import {getGridYValues, maxOfGraph} from 'utils/graph'
import CustomLoader from 'components/common/CustomLoader'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const TemplateAnalytics = () => {
  const dispatch = useDispatch()
  // states used in component
  const [usersList, setUsersList] = useState([])
  const [user, setUser] = useState('all')
  const [data, setData] = useState(JSON.parse(localStorage.getItem('kadence')).persistDateRangeDataToDispatch)

  const {type = []} = useSelector(state => state.apiReducer)
  const {templateOverTimeData = [], emailTemplateData = []} = useSelector(state => state.reportsTemplate)
  const {getResponseList: responseList} = useSelector(state => state.settings)
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const productivityAccess = analyticsAccess?.productivity
  const productivityReportsPermissions = reportsPermissions?.productivity_reports

  const dependencies = [user]

  // Actions to dispatch in Date function
  const actionsToDispatch = useCallback(data => {
    setData(data)
  }, [])

  useEffect(() => {
    if (!productivityReportsPermissions || !productivityAccess) return
    if (user !== 'all') {
      data.templateId = user
    }
    dispatch(templateOverTime(data))
    dispatch(emailTemplate(data))
  }, [productivityReportsPermissions, user, data, dispatch, productivityAccess])

  // UseEffect for users data
  useEffect(() => {
    if (responseList) {
      const usersList = responseList.map(list => ({
        value: list.id,
        key: list.id,
        text: `${list.title}`,
      }))

      setUsersList(usersList)
    }
  }, [responseList])

  useEffect(() => {
    if (productivityAccess && productivityReportsPermissions) {
      dispatch(getEmailTemplate())
    }
  }, [dispatch, productivityAccess, productivityReportsPermissions])

  const getUserListArray = () => {
    return [{value: 'all', text: 'All', key: 'all'}, ...usersList]
  }

  return (
    <div className="kadence-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="common/mail" />
            <h5>Template Analytics</h5>
          </div>
        </div>
        {productivityAccess && productivityReportsPermissions && (
          <div className="page-action">
            <VmoTooltip
              position="top center"
              size="mini"
              content="Templates"
              trigger={
                <VmoDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={getUserListArray()}
                  onChange={(e, {value}) => setUser(value)}
                  defaultValue={getUserListArray()[0].value}
                />
              }
            />

            <DateRangeGroup actionsToDispatch={actionsToDispatch} dependencies={dependencies} />
          </div>
        )}
      </div>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Time based chart of Template</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of Templates Over Time based Data"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(TEMPLATE_OVER_TIME) && <CustomLoader />}
              {!type.includes(TEMPLATE_OVER_TIME) && (
                <ResponsiveLine
                  data={templateOverTimeData}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(templateOverTimeData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(templateOverTimeData),
                    legend: 'Template',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  // colors={(d) => d.color}
                  lineWidth={3}
                  pointSize={7}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(templateOverTimeData)}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>

        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Template Data</VmoCardHeader>
              <VmoTooltip
                content="Check out list of templates along with their performance over time"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable responsive basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Created By</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Send</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Clicked</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Reply</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {emailTemplateData && emailTemplateData?.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    <>
                      {emailTemplateData
                        .sort((a, b) => a.id - b.id)
                        .map(template => (
                          <VmoTableRow key={template.id}>
                            <VmoTableCell>{startCase(template.title)}</VmoTableCell>
                            <VmoTableCell>{startCase(template.createdBy)}</VmoTableCell>
                            <VmoTableCell>{template.sendCount}</VmoTableCell>
                            <VmoTableCell>{template.openPercentage}%</VmoTableCell>
                            <VmoTableCell>{template.replyPercentage}%</VmoTableCell>
                          </VmoTableRow>
                        ))}
                    </>
                  )}
                </VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}
export default TemplateAnalytics
