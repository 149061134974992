import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {VmoTab} from 'vmo-library'
import {GET_TIME_TRACKING, UPDATE_TIME_TRACKING} from 'actions/types'
import {getTimeTrackingSetting, updateTimeTrackingSetting} from 'actions/time_tracking'
import {getUsers} from 'actions/settings'
import SettingLayout from 'layouts/settingLayout'
import {timeTrackingSchema} from 'validation/Settings/automations/time-tracking.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import BillingPane from './BillingPane'
import PrefrencesPane from './PrefrencesPane'

const TimeTracking = () => {
  const dispatch = useDispatch()
  const {timeTrackingData = {}} = useSelector(state => state.timeTracking)
  const {successLabels = [], isLoadingData, type = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const timeTrackingLearnMore = settingsLearnMoreUrl?.automations?.timeTracking?.learnMore

  const {clientManagementAccess} = useUserPermissions()
  const timeTrackingAccess = clientManagementAccess?.timeTracking

  const list = timeTrackingData?.users?.map(val => JSON.parse(val))

  const {handleSubmit, errors, control, reset, watch, formState} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(timeTrackingSchema),
    defaultValues: {
      isTimeTracking: false,
      isAutoForConversation: false,
      onConversation: false,
      onDraftAndReply: false,
      pauseTimerIdle: false,
      timerPauseLimit: 0,
      oneActivityAtTime: false,
      task: false,
      meeting: false,
      call: false,
      event: false,
      milestone: false,
      isBillable: false,
      users: [{id: '', cost: ''}],
      billingType: 'Specific_Cost',
    },
  })
  const isBillableActive = watch('isBillable')
  const isActive = watch('isTimeTracking')
  const billingType = watch('billingType')
  const pauseTimerIdle = watch('pauseTimerIdle')

  const {isDirty} = formState

  useEffect(() => {
    dispatch(getUsers())
    dispatch(getTimeTrackingSetting())
  }, [dispatch])

  const resetForm = () => {
    const data = {...timeTrackingData}
    if (!data.users) {
      data.users = []
    }
    reset(data)
  }

  useEffect(() => {
    if (successLabels.includes(GET_TIME_TRACKING)) {
      resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successLabels])

  const submitTimeTracking = data => {
    dispatch(updateTimeTrackingSetting(data))
  }

  const panes = [
    {
      menuItem: {
        key: 'prefrences',
        icon: <SvgIcon path="settings/preferences" />,
        content: 'Prefrences',
      },
      pane: {
        content: (
          <PrefrencesPane control={control} errors={errors} isActive={isActive} pauseTimerIdle={pauseTimerIdle} />
        ),
      },
    },
    {
      menuItem: {
        key: 'billing',
        icon: <SvgIcon path="settings/billing" />,
        content: 'Billing',
      },
      pane: {
        content: (
          <BillingPane
            control={control}
            errors={errors}
            isBillableActive={isBillableActive}
            billingType={billingType}
            list={list}
          />
        ),
      },
    },
  ]

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/time-tracking" />}
      header="Time Tracking"
      subHeader="Create and accurately track time of managing different tasks efficiently"
      learnMoreUrl={timeTrackingLearnMore}
      table
      headerDivider
      actionButton={{
        cancel: {
          content: 'Reset',
          disabled: isLoadingData && type.includes(UPDATE_TIME_TRACKING),
          onClick: () => {
            resetForm()
          },
        },
        success: {
          content: 'Update',
          loading: isLoadingData && type.includes(UPDATE_TIME_TRACKING),
          ...(timeTrackingAccess && {
            onClick: handleSubmit(submitTimeTracking),
          }),
          disabled: !isDirty || (isLoadingData && type.includes(UPDATE_TIME_TRACKING)),
        },
      }}
      lockFeature={!timeTrackingAccess}
    >
      <VmoTab renderActiveOnly={false} panes={panes} menu={{secondary: true, pointing: true, color: 'blue'}} />
    </SettingLayout>
  )
}
export default TimeTracking
