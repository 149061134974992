import {KADENCE_ANALYTICS} from './endpoint'
import {apiAction} from './api'
import {COMPANIES_STATS, COMPANIES_BY_STATUS} from './types'

export function companiesStats(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/companies/companystats`,
    method: 'POST',
    label: COMPANIES_STATS,
    data,
  })
}

export function companiesByType(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/companies/companiesbytype`,
    method: 'POST',
    label: COMPANIES_BY_STATUS,
    data,
  })
}
