import Joi from 'joi'
import {domainValidation, emailValidation, inputPhoneValidation} from 'validation'

const inputStringValidation = Joi.string().trim().min(2).max(20).messages({
  'string.min': `Length must me at least 2 charactes`,
  'string.max': `Length must be less than or equal to 20 characters`,
})

export const ScoringSchema = Joi.object({
  name: Joi.string().trim().min(2).max(40).required(),
  entity: Joi.string().required(),
  conditionsJson: Joi.object({
    conditionsArray: Joi.array().items(
      Joi.object().keys({
        field: Joi.string().required().messages({
          'string.base': 'Field must be specified',
          'string.empty': 'Field must be specified',
        }),
        operator: Joi.string().required().messages({
          'string.base': 'Field must be specified',
          'string.empty': 'Field must be specified',
        }),
        value: Joi.any().when(Joi.ref('field'), {
          switch: [
            {
              is: 'firstName',
              then: inputStringValidation.label('First Name').required(),
            },
            {
              is: 'lastName',
              then: inputStringValidation.label('Last Name').required(),
            },
            {
              is: 'email',
              then: emailValidation.required().label('Email'),
            },
            {
              is: 'phone',
              then: inputPhoneValidation.label('Phone').required(),
            },
            {
              is: 'description',
              then: inputStringValidation.label('Description').required(),
            },
            {
              is: 'title',
              then: inputStringValidation.label('Title').required(),
            },
            {
              is: 'company',
              then: inputStringValidation.label('company').required(),
            },
            {
              is: 'assignee',
              then: Joi.number().label('Assignee').required().messages({
                'number.base': 'Assignees must be specified',
              }),
            },
            {
              is: 'website',
              then: inputStringValidation.label('Website').required(),
            },
            {
              is: 'monetaryValue',
              then: Joi.number().label('Monetary Value').required(),
            },
            {
              is: 'locality',
              then: inputStringValidation.label('Locality').required(),
            },
            {
              is: 'state',
              then: inputStringValidation.label('State').required(),
            },
            {
              is: 'postalCode',
              then: Joi.number().label('Postal Code').required(),
            },
            {
              is: 'country',
              then: Joi.string().label('Country').required().messages({
                'number.base': 'Country must be specified',
              }),
            },
            {
              is: 'tags',
              then: Joi.number().label('Tags').required().messages({
                'number.base': 'Tags must be specified',
              }),
            },
            {
              is: 'statusId',
              then: Joi.number().label('Status').required().messages({
                'number.base': 'Status must be specified',
              }),
            },
            {
              is: 'sourcesId',
              then: Joi.number().label('Sources').required().messages({
                'number.base': 'Sources must be specified',
              }),
            },
            {
              is: 'socialLinks',
              then: inputStringValidation.label('Social Links').required(),
            },
            {
              is: 'name',
              then: inputStringValidation.label('Name').required(),
            },
            {
              is: 'domain',
              then: domainValidation.label('Domain').required(),
            },
            {
              is: 'contactType',
              then: Joi.number().label('Contact Type').required().messages({
                'number.base': 'Contact Type must be specified',
              }),
            },
            {
              is: 'stage',
              then: Joi.string().label('Stage').required().messages({
                'string.empty': 'Stage must be specified',
              }),
            },
            {
              is: 'win',
              then: Joi.number().label('win').required(),
            },
            {
              is: 'closedAt',
              then: Joi.date().label('Closed At'),
            },
          ],
        }),
      })
    ),
    conditionsOperator: Joi.string().valid('and', 'or'),
  }),
  isAddition: Joi.bool().required(),
  points: Joi.number().positive().required().messages({
    'number.base': 'Required',
  }),
})

export const emailInsightschema = Joi.object({
  entity: Joi.string().valid('contact').required(),
  insights: Joi.array().items(
    Joi.object().keys({
      field: Joi.string(),
      isActive: Joi.bool(),
      isAdd: Joi.bool(),
      points: Joi.number().when('isActive', {
        is: Joi.bool().valid(true),
        then: Joi.number().integer().positive().allow(0).required().messages({
          'number.positive': 'Points must be positive number',
          'number.base': 'Required',
        }),
        otherwise: Joi.number().positive().allow(0).optional().messages({
          'number.positive': 'Points must be positive number',
          'number.base': 'Required',
        }),
      }),
    })
  ),
})
