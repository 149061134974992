import React from 'react'
import UnSavedWrap from 'components/hoc/UnSavedWrap'

const UnsavedWrapLayout = ({children, unsavedWrap = false}) => {
  if (unsavedWrap) {
    return <UnSavedWrap>{children}</UnSavedWrap>
  }
  return children
}

export default UnsavedWrapLayout
