import Joi from 'joi'
import {emailValidation, inputStringValidation} from 'validation'

export const validationSchema = Joi.object({
  name: inputStringValidation.required(),
  email: emailValidation.required(),
  internalEmail: emailValidation.required(),

  settings: Joi.object({
    autoRespond: Joi.boolean().allow(null),
    fontFamily: Joi.string(),
    fontSize: Joi.number(),
    ackEmail: Joi.string(),
    autoResponseEmail: Joi.string(),
    signatureEnabled: Joi.boolean().allow(null),
    smtpEnabled: Joi.boolean().allow(null),
    scheduleAs: Joi.string(),
  }),
  smtp: Joi.object({
    serverName: Joi.string().allow(''),
    username: Joi.string().allow(''),
    password: Joi.string().allow(''),
    port: Joi.string().allow(''),
  }),
  assigneeIds: Joi.array(),
  teamIds: Joi.array(),
})

export const inviteeValidationSchmena = Joi.object({
  firstName: inputStringValidation.required(),
  lastName: inputStringValidation.required(),
  inviteeEmail: Joi.string()
    .email({tlds: {allow: false}})
    .required(),
  role: Joi.number().required().label('Role'),
})

export const renderInviteeSchema = Joi.object({
  firstName: inputStringValidation.required().label('first name'),
  lastName: inputStringValidation.required().label('last name'),
  inviteEmail: Joi.string()
    .email({tlds: {allow: false}})
    .required()
    .label('email'),
  role: Joi.number(),
})

export const emailSetupSchema = Joi.object({
  accountEmail: emailValidation.required().label('account email'),
  inboxName: inputStringValidation.required().label('email inbox'),
  assignee: Joi.array()
    .items(Joi.alternatives(Joi.number(), Joi.string()))
    .min(1)
    .messages({'array.min': `Assignee must be specified`}),
  teamIds: Joi.array(),
})

export const gmailSetupSchema = Joi.object({
  inboxName: inputStringValidation.required(),
  assigneeIds: Joi.array().items(Joi.number()).min(1).required().messages({
    'array.min': 'Assignee must be specified',
  }),
  teamIds: Joi.array().items(Joi.number()),
})
