import Joi from 'joi'

import {inputNumberValidation, inputStringValidation} from 'validation'

export const createPurchaseSchema = Joi.object({
  billNo: Joi.string().min(2).max(16).required().messages({
    'string.min': `Length must me at least 2 charactes`,
    'string.max': `Length must be less than or equal to 16 characters`,
  }),
  name: inputStringValidation.required(),
  assigneeId: inputNumberValidation.required().label('Author'),
  vendorId: inputNumberValidation.required().label('Issuer'),
  paymentMethod: Joi.string().label('Payment method'),
  dateOfPurchase: Joi.date().required().messages({
    'date.base': 'Date must be a valid date',
  }),
  currencyId: Joi.number(),
  comment: Joi.string().trim().max(160).required().messages({
    'string.max': `Length must be less than or equal to 160 characters`,
  }),
  isChargeableToClient: Joi.boolean(),
  discount: inputNumberValidation.integer().max(100).allow('', null),
  taxPercent: inputNumberValidation.integer().max(100).allow('', null),
  discountAmount: Joi.number(),
  taxAmount: Joi.number(),
  subTotal: Joi.number(),
  totalAmount: Joi.number(),
  items: Joi.array()
    .items({
      categoryId: Joi.number().required().label('Category').messages({
        'number.base': `Category is required`,
      }),
      itemName: Joi.string().min(2).max(16).required().messages({
        'string.empty': `item name is not allowed to be empty`,
        'string.min': `Length must me at least 2 charactes`,
        'string.max': `Length must be less than or equal to 16 characters`,
      }),
      description: Joi.string().trim().max(160).required().messages({
        'string.empty': `description cannot be an empty field`,
        'string.max': `Length must be less than or equal to 160 characters`,
      }),
      quantity: Joi.number().required(),
      unit: Joi.string().required().messages({
        'string.empty': `Add Unit`,
      }),
      unitPrice: Joi.number().required(),
      amount: Joi.number().required(),
    })
    .allow(''),
})

export const createPurchasesCategorySchema = Joi.object({
  category: inputStringValidation.required().messages({
    'string.empty': `category cannot be an empty field`,
    'any.required': `category is a required field`,
    'string.min': `category should have a minimum length of 2`,
    'string.max': `category should have length of 20 characters or less`,
  }),
})
