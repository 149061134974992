import {METRICS_FIRST_RESPONSE_BREAKDOWN_ANALYTICS, METRICS_RESOLUTION_BREAKDOWN_ANALYTICS} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case METRICS_FIRST_RESPONSE_BREAKDOWN_ANALYTICS:
      return {
        ...state,
        metricsFirstResponseBreakdown: payload,
      }

    case METRICS_RESOLUTION_BREAKDOWN_ANALYTICS:
      return {
        ...state,
        metricsResolutionBreakdown: payload,
      }

    default:
      return state
  }
}
