import React from 'react'
import {getUserInfo} from 'utils/local-storage'
import {get} from 'utils/helper'
import userAvatar from 'assets/user_avatar.png'

const DummyAvtarAccountUser = () => {
  const userData = getUserInfo()

  if (get(['firstName'], userData) || get(['lastName'], userData)) {
    if (get(['firstName'], userData) && get(['lastName'], userData)) {
      return (
        get(['firstName'], userData).slice(0, 1).toUpperCase() + get(['lastName'], userData).slice(0, 1).toUpperCase()
      )
    }
    return get(['firstName'], userData).slice(0, 2).toUpperCase()
  }
  return <img loading="lazy" src={userAvatar} className="img-fluid" alt="img" width="35" />
}

export default React.memo(DummyAvtarAccountUser)
