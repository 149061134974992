const Joi = require('joi')

export const addFormsSchema = Joi.object().keys({
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  description: Joi.string().trim().max(250).allow('', null).messages({
    'string.max': `Length must be less than or equal to 250 characters`,
  }),
})

export const updateFormsSchema = Joi.object().keys({
  design: Joi.object().keys({
    submitButtonText: Joi.string().optional(),
    submitButtonColor: Joi.string()
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i)
      .message('Please provide valid hex color code'),
    backgroundColor: Joi.string()
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i)
      .message('Please provide valid hex color code'),
  }),
  settings: Joi.object().keys({
    title: Joi.string().required(),
    description: Joi.string().optional().allow('', null),
    redirectUrl: Joi.string()
      .trim()
      .regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/)
      .allow('', null)
      .messages({'string.pattern.base': 'Enter a valid url'}),

    confirmMessage: Joi.string().optional().allow('', null),
    showQuestionNumber: Joi.boolean().default(false),
    indicateMandatory: Joi.boolean().default(true),
    showBranding: Joi.boolean().default(true),
    showProgressBar: Joi.boolean().default(true),
  }),
  questions: Joi.array().items(Joi.any()),
})

export const publicFormSchema = Joi.object().keys({
  answers: Joi.array()
    .items(
      Joi.object().keys({
        isRequired: Joi.boolean(),
        type: Joi.string(),
        description: Joi.string().allow('', null),
        question: Joi.string(),
        settings: Joi.any(),
        questionId: Joi.number().required(),
        answer: Joi.when('isRequired', {
          is: true,
          then: Joi.array().items(
            Joi.when(Joi.ref('...type'), {
              switch: [
                {
                  is: 'text',
                  then: Joi.string().required().messages({
                    'string.empty': 'Field must be specified',
                  }),
                },
                {
                  is: 'dropdown',
                  then: Joi.string().required().messages({
                    'string.empty': 'Field must be specified',
                  }),
                },
                {
                  is: 'number',
                  then: Joi.string()
                    .trim()
                    .max(20)
                    .regex(/^\+?[0-9]{4,20}$/)
                    .required()
                    .messages({
                      'string.pattern.base': 'Enter a valid phone number',
                      'string.empty': `Phone is not allowed to be empty`,
                    }),
                },
                {
                  is: 'date',
                  then: Joi.date().iso().required(),
                },
                {
                  is: 'checkbox',
                  then: Joi.string().required().messages({
                    'string.empty': 'Field must be specified',
                  }),
                },
                {
                  is: 'radio',
                  then: Joi.string().required().messages({
                    'string.empty': 'Field must be specified',
                  }),
                },
                {
                  is: 'radio',
                  then: Joi.string().required().messages({
                    'string.empty': 'Field must be specified',
                  }),
                },
                {
                  is: 'textarea',
                  then: Joi.string().required().messages({
                    'string.empty': 'Field must be specified',
                  }),
                },
              ],
            })
          ),
          otherwise: Joi.array().items(Joi.any()),
        }),
      })
    )
    .min(1)
    .required(),
})
