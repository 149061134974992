/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import DocumentTitle from 'components/common/DocumentTitle'
import AppLayout from 'layouts/AppLayout'
import DomainVerification from 'pages/unauthenticated/DomainVerification'
import Emailverify from 'pages/unauthenticated/Emailverify'
import Signin from 'pages/unauthenticated/SignIn'
import SignUp from 'pages/unauthenticated/SignUp'
import ThankYou from 'components/pages/ThankYou'
import {Switch, Redirect, Route} from 'react-router-dom'
import Settings from 'pages/authenticated/Settings'
import {Inbox} from 'pages/authenticated/Email'
import ContactCustomer from 'components/pages/Customers/Contacts/ContactsCustomer'
import CompaniesDetail from 'components/pages/Customers/Companies/CompanyDetail'
import Products from 'pages/authenticated/Sales/Products'
import OneTimeMessageSteps from 'components/pages/Marketing/Campaigns/OneTimeMessage/OneTimeMessageSteps'
import Deals from 'components/pages/Sales/Deals/Deals'
import DealsDetails from 'components/pages/Sales/Deals/DealsDetails'
import Tasks from 'pages/authenticated/Projects/Tasks'
import QuotesList from 'components/pages/Sales/Quotes/QuotesList'
import QuotesForm from 'components/pages/Sales/Quotes/QuotesForm'
import ActivityFeed from 'components/pages/Customers/ActivityFeed/ActivityFeed'
import Sequences from 'components/pages/Sales/Sequences/Sequences'
import SequencesCreate from 'components/pages/Sales/Sequences/SequencesCreate'
import SequencesDetails from 'components/pages/Sales/Sequences/SequencesDetails'
import SequencesInsights from 'components/pages/Sales/Sequences/SequencesInsights'
import Forecast from 'components/pages/Sales/Forecast/Forecast'
import UnSavedWrap from 'components/hoc/UnSavedWrap'
import OneTimeMessageTab from 'components/pages/Marketing/Campaigns/OneTimeMessage/OneTimeMessageTab'
import OneTimeEmailSteps from 'components/pages/Marketing/Campaigns/OneTimeEmail/OneTimeEmailSteps'
import OneTimeEmailTab from 'components/pages/Marketing/Campaigns/OneTimeEmail/OneTimeEmailTab'
import OneTimeSms from 'components/pages/Marketing/Campaigns/OneTimeSms'
import LiveVisitors from 'components/pages/Customers/LiveVisitors'
import Analytics from 'pages/authenticated/Analytics'
import CreateModal from 'components/pages/Finance/Vendors/CreateModal'
import Dashboards from 'pages/authenticated/Dashboards'
import PurchaseModal from 'components/pages/Finance/Purchases/PurchaseModal'
import Campaign from 'pages/authenticated/Campaign'
import InsideSteps from 'components/pages/Projects/Projects/InsideSteps'
import Index from 'components/pages/Finance/PurchaseOrders/PurchaseOrdersForm/Index'
import PurchaseOrdersEdit from 'components/pages/Finance/PurchaseOrders/PurchaseOrdersEdit'
import IndexSalesOrders from 'components/pages/Finance/SalesOrders/SalesOrdersForm/Index'
import SalesOrdersEdit from 'components/pages/Finance/SalesOrders/SalesOrdersEdit'
import Projects from 'pages/authenticated/Projects/Projects'
import TimeTracking from 'components/pages/Projects/TimeTracking/TimeTracking'
import InvoicesIndex from 'components/pages/Finance/Invoices/InvoicesForm/Index'
import InvoicesEdit from 'components/pages/Finance/Invoices/InvoicesEdit'
import BudgetsPlanner from 'components/pages/Finance/BudgetsPlanner/BudgetsPlanner'
import CreateBudgetPlanner from 'components/pages/Finance/BudgetsPlanner/CreateBudgetPlanner'
import Employees from 'pages/authenticated/Hr/Employees'
import TimeOff from 'pages/authenticated/Hr/TimeOff'
import Expenses from 'pages/authenticated/Hr/Expenses'
import Payroll from 'components/pages/HumanResourse/Payroll/Payroll'
import Contracts from 'components/pages/HumanResourse/Contracts/Contracts'
import CreateExpense from 'components/pages/HumanResourse/Expenses/CreateExpense'
import CreateEmployee from 'components/pages/HumanResourse/Employees/EmployeesForm/CreateEmployee'
import GettingStarted from 'pages/authenticated/GettingStarted'
import MeetingList from 'components/pages/Sales/Meetings/MeetingsList'
import MeetingCreate from 'components/pages/Sales/Meetings/MeetingCreate'
import MeetingUpdate from 'components/pages/Sales/Meetings/MeetingUpdate'
import Automations from 'pages/authenticated/Automations'
import AutomationBuilder from 'components/pages/Automations/AutomationBuilder'
import MeetingBook from 'components/pages/Sales/Meetings/MeetingBook'
import MeetingConfrimd from 'components/pages/Sales/Meetings/MeetingConfrimd'
import CalendarContainer from 'components/pages/Customers/Calendar/CalendarContainer'
import CreateAppraiselTemplate from 'components/pages/HumanResourse/Appraisal/CreateAppraiselTemplate'
import AppraisalTemplate from 'components/pages/HumanResourse/Appraisal/AppraisalTemplate'
import AppraisalSend from 'components/pages/HumanResourse/Appraisal/AppraisalSend'
import ViewAppraisal from 'components/pages/HumanResourse/Appraisal/ViewAppraisal'
import CreateTimeOffForm from 'components/pages/HumanResourse/TimeOff/CreateTimeOffForm'
import Announcements from 'components/pages/HumanResourse/Announcements/Announcements'
import CreateAnnouncement from 'components/pages/HumanResourse/Announcements/CreateAnnouncement'
import ClientPortal from 'pages/authenticated/Customers/ClientPortal'
import AnnouncementDetails from 'components/pages/Customers/ClientPortal/AnnouncementDetails'
import Contacts from 'components/pages/Customers/Contacts/ContactsList'
import Companies from 'components/pages/Customers/Companies/Companies'
import Purchases from 'pages/authenticated/Finance/Purchases'
import PurchaseOrders from 'pages/authenticated/Finance/PurchaseOrders'
import SalesOrders from 'pages/authenticated/Finance/SalesOrders'
import Invoices from 'pages/authenticated/Finance/Invoices'
import Vendors from 'pages/authenticated/Finance/Vendors'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import Home from 'pages/authenticated/Home'
import Budgets from 'pages/authenticated/Projects/Budgets'
import ProofsList from 'components/pages/Projects/Proofs/ProofsList'
import ApprovePage from 'components/pages/Projects/Proofs/ApprovePage'
import ResourcePlanning from 'components/pages/Projects/ResourcePlanning/ResourcePlanning'
import PublicForm from 'components/pages/Projects/Projects/Steps/Forms/PublicFrom/PublicForm'
import UpdateFormPage from 'components/pages/Projects/Projects/Steps/Forms/UpdateFormPage'
import ProjectTemplateInsideSteps from 'components/pages/Projects/Projects/ProjectTemplates/ProjectTemplateInsideSteps'
import UnsavedWrapLayout from 'layouts/UnsavedWrapLayout'
import ProtectedRoute from './ProtectedRoute'

// eslint-disable-next-line no-useless-escape
const subDomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i

const authRoutes = [
  {
    path: '/',
    exact: true,
    component: window.location.hostname.match(subDomainRegex) !== null ? Signin : DomainVerification,
    title: window.location.hostname.match(subDomainRegex) !== null ? 'Signin' : 'Domain Verification',
  },
  {
    path: '/signin',
    exact: true,
    component:
      window.location.hostname.match(subDomainRegex) !== null || window.localStorage.getItem('domain')
        ? Signin
        : DomainVerification,
    title:
      window.location.hostname.match(subDomainRegex) !== null || window.localStorage.getItem('domain')
        ? 'Signin'
        : 'Domain Verification',
  },
  {path: '/signup', exact: true, component: SignUp, title: 'Sign up'},
  {path: '/emailverify', exact: true, component: Emailverify, title: 'Email Verify'},
  {path: '/thankyou', exact: true, component: ThankYou, title: 'Thank You'},
]

const routesWithLayout = [
  {path: '/getting-started', exact: true, component: GettingStarted, title: 'Getting Started'},
  {path: '/home', exact: true, component: Home, title: 'Home'},
  {path: '/contacts/:id', exact: true, component: ContactCustomer, title: 'Contact Details', contacts: true},
  {path: '/contacts', component: Contacts, title: 'Contacts'},
  {path: '/companies/:id', exact: true, component: CompaniesDetail, title: 'Company Details', companies: true},
  {path: '/companies', component: Companies, title: 'Companies'},
  {path: '/portal', component: ClientPortal, title: 'Client Portal'},
  {path: '/calendar', exact: true, component: CalendarContainer, title: 'Calendar'},
  {path: '/activities-feed', exact: true, component: ActivityFeed, title: 'Activities Feed'},
  {path: '/live-visitors', exact: true, component: LiveVisitors, title: 'Live Visitors'},
  {path: '/inbox', exact: true, component: Inbox, title: 'Inbox'},
  {path: '/deals', exact: true, component: Deals, title: 'Deals'},
  {path: '/deals/:dealId', exact: true, component: DealsDetails, title: 'Deals details', deals: true},
  {path: '/campaigns', component: Campaign, title: 'Campaigns'},
  {path: '/meetings', exact: true, component: MeetingList, title: 'Meetings'},
  {path: '/forecasts', exact: true, component: Forecast, title: 'Forecasting'},
  {path: '/sequences', exact: true, component: Sequences, title: 'Sequences'},
  {path: '/quotes', exact: true, component: QuotesList, title: 'Quotes'},
  {path: '/products', component: Products, title: 'All Products'},
  {path: '/budgeting', exact: true, component: Budgets, title: 'Budgeting'},
  {path: '/tasks', exact: true, component: Tasks, title: 'Tasks'},
  {path: '/timesheets', exact: true, component: TimeTracking, title: 'Timesheets'},
  {path: '/proofs', component: ProofsList, title: 'Proofs'},
  {path: '/resource-planning', component: ResourcePlanning, title: 'Resource Planning'},
  {path: '/invoices', component: Invoices, title: 'Invoices'},
  {path: '/sales-orders', component: SalesOrders, title: 'Sales Orders'},
  {path: '/purchase-orders', component: PurchaseOrders, title: 'Purchases Orders'},
  {path: '/budgets-planner', exact: true, component: BudgetsPlanner, title: 'Budgets Planner'},
  {path: '/vendors', component: Vendors, title: 'Vendors'},
  {path: '/purchases', component: Purchases, title: 'Purchases'},
  {path: '/employees', component: Employees, title: 'Employees'},
  {path: '/time-off', component: TimeOff, title: 'TimeOff'},
  {path: '/expenses', component: Expenses, title: 'Expenses'},
  {path: '/payroll', exact: true, component: Payroll, title: 'Payroll'},
  {path: '/contracts', exact: true, component: Contracts, title: 'Contracts'},
  {path: '/announcements', exact: true, component: Announcements, title: 'Announcement'},
  {path: '/automations', exact: true, component: Automations, title: 'Automation'},
  {path: '/dashboards', component: Dashboards, title: 'Dashboards'},
  {path: '/reports', component: Analytics, title: 'Reports'},
  {path: '/settings', component: Settings, title: 'Settings'},
]

const routesWithoutLayout = [
  {
    path: '/portal/announcements/create',
    exact: true,
    component: AnnouncementDetails,
    title: 'Create Announcement',
    clientPortal: true,
  },
  {path: '/portal/announcements/edit/:id', exact: true, component: AnnouncementDetails, title: 'Edit Announcement'},
  {path: '/meeting/:meetingId', exact: true, component: MeetingUpdate, title: 'Meetings'},
  {path: '/projects/:projectId/form/:id', exact: true, component: UpdateFormPage, title: 'Form'},
  {path: '/proofs/approve/:id', exact: true, component: ApprovePage, title: 'Proofs', unsavedWrap: true},
  {path: '/employees/create', exact: true, component: CreateEmployee, title: 'Create Employees', unsavedWrap: true},
  {path: '/employees/update/:id', exact: true, component: CreateEmployee, title: 'Update Employees', unsavedWrap: true},
  {path: '/time-off/create', exact: true, component: CreateTimeOffForm, title: 'Create Time-off', unsavedWrap: true},
  {path: '/time-off/update/:id', exact: true, component: CreateTimeOffForm, title: 'Update Time-off'},
  {path: '/expenses/create', exact: true, component: CreateExpense, title: 'Create Expenses', unsavedWrap: true},
  {path: '/expenses/update/:id', exact: true, component: CreateExpense, title: 'Update Expenses', unsavedWrap: true},
  {
    path: '/announcements/create',
    exact: true,
    component: CreateAnnouncement,
    title: 'Create Announcements',
    unsavedWrap: true,
  },
  {
    path: '/announcements/update/:id',
    exact: true,
    component: CreateAnnouncement,
    title: 'Update Announcements',
    unsavedWrap: true,
  },
  {path: '/automations/builder', exact: true, component: AutomationBuilder, title: 'Automations'},
]

const publicRoutes = [
  {path: '/account/:domian/:userName', exact: true, component: MeetingBook, title: 'Meeting Booking'},
  {path: '/meetings/confirm/new', exact: true, component: MeetingConfrimd, title: 'Meetings Confirm New'},
  {path: '/public/form/:id', exact: true, component: PublicForm, title: 'Form'},
]

const ConditionalRouteWithLayout = ({
  component: Component,
  condition = false,
  fallbackRoute,
  unsavedWrap = false,
  ...rest
}) => {
  return (
    <ProtectedRoute
      key={rest.path}
      {...rest}
      render={props =>
        condition ? (
          <AppLayout>
            <UnsavedWrapLayout unsavedWrap={unsavedWrap}>
              <Component {...props} />
            </UnsavedWrapLayout>
          </AppLayout>
        ) : (
          <Redirect to={fallbackRoute} />
        )
      }
    />
  )
}

const ConditionalRouteWithoutLayout = ({
  component: Component,
  condition = false,
  fallbackRoute,
  unsavedWrap = false,
  ...rest
}) => {
  return (
    <ProtectedRoute
      key={rest.path}
      {...rest}
      render={props =>
        condition ? (
          <UnsavedWrapLayout unsavedWrap={unsavedWrap}>
            <Component {...props} />
          </UnsavedWrapLayout>
        ) : (
          <Redirect to={fallbackRoute} />
        )
      }
    />
  )
}

const Routes = () => {
  const {
    salesAndCrmAccess,
    financeAccess,
    projectsAndCollaborationAccess,
    workflowAndAutomationAccess,
    meetingsPermissions,
    sequencesPermissions,
    quotesPermissions,
    projectsPermissions,
    financePermissions,
  } = useUserPermissions()
  const campaignsAccess = salesAndCrmAccess?.campaigns
  const salesSequencesAccess = salesAndCrmAccess?.salesSequences
  const quotesAccess = salesAndCrmAccess?.quotes
  const invoicesAccess = financeAccess?.invoices
  const salesOrdersAccess = financeAccess?.salesOrders
  const purchaseOrdersAccess = financeAccess?.purchaseOrders
  const budgetPlannerAccess = financeAccess?.budgetPlanner
  const vendorsAccess = financeAccess?.vendors
  const purchasesAccess = financeAccess?.purchases
  const projectsAccess = projectsAndCollaborationAccess?.projects
  const advanceWorkflowsAccess = workflowAndAutomationAccess?.advanceWorkflows
  const manageMeetingsPermissions = meetingsPermissions?.manage_meetings
  const manageSequencesPermissions = sequencesPermissions?.manage_sequences
  const manageQuotesPermissions = quotesPermissions?.manage_quotes
  const manageInvoicesPermissions = financePermissions?.manage_invoices
  const manageSalesOrdersPermissions = financePermissions?.manage_sales_orders
  const managePurchaseOrdersPermissions = financePermissions?.manage_purchase_orders
  const manageVendorsPermissions = financePermissions?.manage_vendors
  const managePurchasesPermissions = financePermissions?.manage_purchases

  const conditionalRoutesWithLayout = [
    {
      path: '/sequence/:id/insights',
      exact: true,
      component: SequencesInsights,
      condition: salesSequencesAccess,
      fallbackRoute: '/sequences',
      title: 'Sequences',
    },
    {
      path: '/projects/templates/:templateId',
      component: ProjectTemplateInsideSteps,
      condition: projectsAccess,
      fallbackRoute: '/projects/templates',
      title: 'Templates Steps',
    },
  ]

  const conditionalRoutesWithoutLayout = [
    {
      path: '/campaigns/message',
      exact: true,
      component: OneTimeMessageSteps,
      condition: campaignsAccess,
      fallbackRoute: '/campaigns',
      title: 'Message',
      unsavedWrap: true,
      campaign: true,
    },
    {
      path: '/campaigns/message/:id',
      exact: true,
      component: OneTimeMessageTab,
      condition: campaignsAccess,
      fallbackRoute: '/campaigns',
      title: 'Message',
      unsavedWrap: true,
      campaign: true,
    },
    {
      path: '/campaigns/email',
      exact: true,
      component: OneTimeEmailSteps,
      condition: campaignsAccess,
      fallbackRoute: '/campaigns',
      title: 'Email',
      unsavedWrap: true,
      campaign: true,
    },
    {
      path: '/campaigns/email/:id',
      exact: true,
      component: OneTimeEmailTab,
      condition: campaignsAccess,
      fallbackRoute: '/campaigns',
      title: 'Email',
      unsavedWrap: true,
      campaign: true,
    },
    {
      path: '/campaigns/sms',
      exact: true,
      component: OneTimeSms,
      condition: campaignsAccess,
      fallbackRoute: '/campaigns',
      title: 'SMS',
      unsavedWrap: true,
      campaign: true,
    },
    {
      path: '/campaigns/sms/:id',
      exact: true,
      component: OneTimeSms,
      condition: campaignsAccess,
      fallbackRoute: '/campaigns',
      title: 'SMS',
      unsavedWrap: true,
      campaign: true,
    },
    {
      path: '/meetings/create',
      exact: true,
      component: MeetingCreate,
      condition: manageMeetingsPermissions,
      fallbackRoute: '/meetings',
      title: 'Meetings Create',
    },
    {
      path: '/sequences/create',
      exact: true,
      component: SequencesCreate,
      condition: salesSequencesAccess && manageSequencesPermissions,
      fallbackRoute: '/sequences',
      title: 'Sequences Create',
      unsavedWrap: true,
    },
    {
      path: '/sequence/:id',
      exact: true,
      component: SequencesDetails,
      condition: salesSequencesAccess,
      fallbackRoute: '/sequences',
      title: 'Sequences',
      unsavedWrap: true,
    },
    {
      path: '/quotes/add',
      exact: true,
      component: QuotesForm,
      condition: quotesAccess && manageQuotesPermissions,
      fallbackRoute: '/quotes',
      title: 'Quotes Add',
      unsavedWrap: true,
    },
    {
      path: '/quote/:id',
      exact: true,
      component: QuotesForm,
      condition: quotesAccess,
      fallbackRoute: '/quotes',
      title: 'Quotes',
      unsavedWrap: true,
    },
    {
      path: '/invoices/create',
      exact: true,
      component: InvoicesIndex,
      condition: invoicesAccess && manageInvoicesPermissions,
      fallbackRoute: '/invoices',
      title: 'Create Invoices',
      unsavedWrap: true,
    },
    {
      path: '/invoices/update/:id',
      exact: true,
      component: InvoicesEdit,
      condition: invoicesAccess,
      fallbackRoute: '/invoices',
      title: 'Invoices',
      unsavedWrap: true,
    },
    {
      path: '/sales-orders/create',
      exact: true,
      component: IndexSalesOrders,
      condition: salesOrdersAccess && manageSalesOrdersPermissions,
      fallbackRoute: '/sales-orders',
      title: 'Create Sales Orders',
      unsavedWrap: true,
    },
    {
      path: '/sales-orders/update/:id',
      exact: true,
      component: SalesOrdersEdit,
      condition: salesOrdersAccess,
      fallbackRoute: '/sales-orders',
      title: 'Sales Orders',
      unsavedWrap: true,
    },
    {
      path: '/purchase-orders/create',
      exact: true,
      component: Index,
      condition: purchaseOrdersAccess && managePurchaseOrdersPermissions,
      fallbackRoute: '/purchase-orders',
      title: 'Create Purchase Orders',
      unsavedWrap: true,
    },
    {
      path: '/purchase-orders/update/:id',
      exact: true,
      component: PurchaseOrdersEdit,
      condition: purchaseOrdersAccess,
      fallbackRoute: '/purchase-orders',
      title: 'Purchase Orders',
      unsavedWrap: true,
    },
    {
      path: '/budgets-planner/new',
      exact: true,
      component: CreateBudgetPlanner,
      condition: budgetPlannerAccess,
      fallbackRoute: '/budgets-planner',
      title: 'Create Budgets Planner',
      unsavedWrap: true,
    },
    {
      path: '/budgets-planner/new/:id',
      exact: true,
      component: CreateBudgetPlanner,
      condition: budgetPlannerAccess,
      fallbackRoute: '/budgets-planner',
      title: 'Update Budgets Planner',
      unsavedWrap: true,
    },
    {
      path: '/vendors/create',
      exact: true,
      component: CreateModal,
      condition: vendorsAccess && manageVendorsPermissions,
      fallbackRoute: '/vendors',
      title: 'Vendors Create',
      unsavedWrap: true,
    },
    {
      path: '/vendors/update/:id',
      exact: true,
      component: CreateModal,
      condition: vendorsAccess,
      fallbackRoute: '/vendors',
      title: 'Vendors',
    },
    {
      path: '/purchases/add',
      exact: true,
      component: PurchaseModal,
      condition: purchasesAccess && managePurchasesPermissions,
      fallbackRoute: '/purchases',
      title: 'Add purchases',
      unsavedWrap: true,
    },
    {
      path: '/purchases/update/:id',
      exact: true,
      component: PurchaseModal,
      condition: purchasesAccess,
      fallbackRoute: '/purchases',
      title: 'Purchases',
      unsavedWrap: true,
    },
    {
      path: '/automations/builder',
      exact: true,
      component: AutomationBuilder,
      condition: advanceWorkflowsAccess,
      fallbackRoute: '/automations',
      title: 'Automations',
    },
    {
      path: '/automations/builder/:id',
      exact: true,
      component: AutomationBuilder,
      condition: advanceWorkflowsAccess,
      fallbackRoute: '/automations',
      title: 'Automations',
    },
  ]

  return (
    <Switch>
      {authRoutes.map(route => (
        <ProtectedRoute key={route.path} {...route} />
      ))}

      {conditionalRoutesWithLayout.map(route => (
        <ConditionalRouteWithLayout key={route.path} {...route} />
      ))}

      {conditionalRoutesWithoutLayout.map(route => (
        <ConditionalRouteWithoutLayout key={route.path} {...route} />
      ))}

      {routesWithoutLayout.map(({component: Component, unsavedWrap = false, ...route}) => (
        <ProtectedRoute
          key={route.path}
          {...route}
          render={props => (
            <UnsavedWrapLayout unsavedWrap={unsavedWrap}>
              <Component {...props} />
            </UnsavedWrapLayout>
          )}
        />
      ))}

      {routesWithLayout.map(({component: Component, ...rest}) => (
        <ProtectedRoute
          key={rest.path}
          {...rest}
          render={props => (
            <AppLayout>
              <Component {...props} />
            </AppLayout>
          )}
        />
      ))}

      {publicRoutes.map(({path, exact = false, component: Component, title}) => (
        <Route
          key={path}
          path={path}
          exact={exact}
          render={props => (
            <>
              <DocumentTitle title={title} />
              <Component {...props} />
            </>
          )}
        />
      ))}

      {/* Start:- Warning: do not move below 2 routes to anywhere
      1.> /projects/:projectId(\d+)
      2.> /projects
      */}
      <ProtectedRoute
        path="/projects/:projectId(\d+)"
        render={props => (
          <>
            {projectsAccess ? (
              <>
                <AppLayout {...props}>
                  <DocumentTitle title="Projects Steps" />
                  <InsideSteps {...props} />
                </AppLayout>
              </>
            ) : (
              <Redirect to="/projects" />
            )}
          </>
        )}
      />

      <ProtectedRoute
        path="/projects"
        render={props => (
          <AppLayout {...props}>
            <DocumentTitle title="Projects" />
            <Projects {...props} />
          </AppLayout>
        )}
      />
      {/* End Warning */}

      {/* <ProtectedRoute
        exact
        path="/appraisal"
        render={props => (
          <AppLayout>
            <DocumentTitle title="Appraisal" />
            <Appraisal {...props} />
          </AppLayout>
        )}
      />

      <ProtectedRoute
        exact
        path="/appraisal/manage"
        render={props => (
          <AppLayout>
            <DocumentTitle title="Appraisal" />
            <ManageAppraisal {...props} />
          </AppLayout>
        )}
      /> */}

      <ProtectedRoute
        exact
        path="/appraisal/1"
        render={props => (
          <>
            <DocumentTitle title="Appraisal" />
            <UnSavedWrap>
              <ViewAppraisal {...props} />
            </UnSavedWrap>
          </>
        )}
      />

      <ProtectedRoute
        exact
        path="/appraisal/templates"
        render={props => (
          <AppLayout>
            <DocumentTitle title="Appraisal" />
            <AppraisalTemplate {...props} />
          </AppLayout>
        )}
      />

      <ProtectedRoute
        exact
        path="/appraisal/send/:id"
        render={props => (
          <>
            <DocumentTitle title="Appraisal" />
            <UnSavedWrap>
              <AppraisalSend {...props} />
            </UnSavedWrap>
          </>
        )}
      />

      <ProtectedRoute
        path="/appraisal/create"
        render={props => (
          <>
            <DocumentTitle title="Create Appraisal" />
            <UnSavedWrap>
              <CreateAppraiselTemplate {...props} />
            </UnSavedWrap>
          </>
        )}
      />

      <ProtectedRoute
        exact
        path="/appraisal/update/:id"
        render={props => (
          <>
            <DocumentTitle title="Update Appraisal" />
            <UnSavedWrap>
              <CreateAppraiselTemplate {...props} />
            </UnSavedWrap>
          </>
        )}
      />

      <Redirect to="/home" />
    </Switch>
  )
}

export default Routes
