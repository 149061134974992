import {
  EDIT_CONTACT,
  GET_ALL_COMPANIES,
  GET_COMPANY_DETAILS,
  GET_CONTACT_DETAILS,
  GET_CONTACT_LIST,
  GET_CONTACT_LIST_ALL,
  GET_LIVEUSER_CONTACTS,
  GET_NOTE_CONTACTS,
  LIST_COMPANY_CONTACTS,
  LIVE_COUNT_DETAIL,
  SEARCH_CONTACT,
  UPDATE_CONTACT_LIST,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_LIVEUSER_CONTACTS: {
      const pageData = {
        page: action.payload.page,
        size: 20,
        totalItems: action.payload.totalContacts,
        totalPages: action.payload.totalPages,
      }
      return {
        ...state,
        getLiveUserContactData: action.payload.contacts || [],
        getLiveUserNumberOfPages: pageData,
      }
    }
    case UPDATE_CONTACT_LIST: {
      const pageData = {
        page: action.payload.page,
        size: 20,
        totalItems: action.payload.totalContacts,
        totalPages: action.payload.totalPages,
      }
      return {
        ...state,
        getContactListData: action.payload,
        getNumberOfPages: pageData,
      }
    }

    case LIVE_COUNT_DETAIL:
      return {
        ...state,
        getLiveCount: action.payload,
      }

    case GET_ALL_COMPANIES: {
      return {
        ...state,
        getCompaniesData: action.payload,
      }
    }
    case GET_COMPANY_DETAILS:
      return {
        ...state,
        getCompanyDetails: action.payload,
      }
    case GET_CONTACT_LIST: {
      return {
        ...state,
        getContactListData: action.payload,
      }
    }

    case GET_CONTACT_LIST_ALL: {
      return {
        ...state,
        getContactListAllData: action.payload,
      }
    }

    case GET_CONTACT_DETAILS:
      return {
        ...state,
        getContactDetails: action.payload,
      }

    case SEARCH_CONTACT:
      return {
        ...state,
        getContactListData: action.payload,
      }

    case 'UPDATE_SEARCH_FILTER_LIST':
      return {
        ...state,
        getContactListData: action.payload,
      }

    case EDIT_CONTACT: {
      const list = state.getContactListData ? [...state.getContactListData.contacts] : []
      const updatedIndex = list.findIndex(contact => contact.id === action.payload.id)

      if (updatedIndex !== -1) list.splice(updatedIndex, 1, action.payload)

      return {
        ...state,
        updateContactProfileMsg: action.status || action.error,
        getContactDetails: action.payload,
        getContactListData: {
          ...state.getContactListData,
          contacts: list,
        },
      }
    }

    case GET_NOTE_CONTACTS:
      return {
        ...state,
        noteFeed: action.payload,
      }

    case LIST_COMPANY_CONTACTS:
      return {
        ...state,
        companyList: action.payload,
      }

    default:
      return state
  }
}
