import Joi from 'joi'

export const emailSurveySchema = Joi.object({
  subject: Joi.string().max(40).trim().required().messages({
    'string.max': `Length must be less than or equal to 40 characters`,
  }),
  inboxIds: Joi.array().min(1).required().label('inbox'),
  ratingsType: Joi.string().allow('', null).max(40).messages({
    'string.max': `Length must be less than or equal to 40 characters`,
  }),
  heading: Joi.string().allow('', null).max(40).messages({
    'string.max': `Length must be less than or equal to 40 characters`,
  }),
  subHeading: Joi.string().allow('', null).max(40).messages({
    'string.max': `Length must be less than or equal to 40 characters`,
  }),
  footer: Joi.string().allow('', null).max(40).messages({
    'string.max': `Length must be less than or equal to 40 characters`,
  }),
  finishText: Joi.string().allow('', null),
  commentEnabledBelow: Joi.number().integer().allow(null),
  commentTitle: Joi.string().allow('', null).max(60).messages({
    'string.max': `Length must be less than or equal to 60 characters`,
  }),
  commentEnabled: Joi.boolean().allow(null),
  allowMultipleFeedback: Joi.boolean(),
})
