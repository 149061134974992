import {KADENCE_ANALYTICS} from './endpoint'
import {apiAction} from './api'
import {
  TOTAL_DEALS,
  OPEN_DEALS_BY_STAGES,
  OPEN_DEALS_OVER_TIME,
  WON_DEALS_OVER_TIME,
  LOST_DEALS_OVER_TIME,
  DEAL_STAGE_BREAKDOWN,
  DEAL_STATUS_BREAKDOWN,
  DEAL_BY_USERS,
  DEAL_BY_LOSS_REASON,
  DEAL_FUNNEL_REPORT,
  SALES_INFO,
  DEAL_ANALYTICS_BY_USERS,
} from './types'

export function totalDeals(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/deals/totaldeals`,
    method: 'POST',
    label: TOTAL_DEALS,
    data,
  })
}

export function openDealsByStage(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/deals/opendealsbystages`,
    method: 'POST',
    label: OPEN_DEALS_BY_STAGES,
    data,
  })
}

export function openDealsOverTime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/deals/opendealsovertime`,
    method: 'POST',
    label: OPEN_DEALS_OVER_TIME,
    data,
  })
}

export function wonDealsOverTime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/deals/wondealsovertime`,
    method: 'POST',
    label: WON_DEALS_OVER_TIME,
    data,
  })
}

export function lostDealsOverTime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/deals/lostdealsovertime`,
    method: 'POST',
    label: LOST_DEALS_OVER_TIME,
    data,
  })
}

export function dealStatusBreakdown(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/deals/dealstatusbreakdownstats`,
    method: 'POST',
    label: DEAL_STATUS_BREAKDOWN,
    data,
  })
}

export function dealStageBreakdown(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/deals/dealstagebreakdownstats`,
    method: 'POST',
    label: DEAL_STAGE_BREAKDOWN,
    data,
  })
}
export function dealsValueByUser(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/deals/dealsvaluebyuser`,
    method: 'POST',
    label: DEAL_BY_USERS,
    data,
  })
}
export function dealsByLossReason(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/deals/dealsbylossreason`,
    method: 'POST',
    label: DEAL_BY_LOSS_REASON,
    data,
  })
}
export function dealsFunnelReport(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/deals/dealsfunnelreport`,
    method: 'POST',
    label: DEAL_FUNNEL_REPORT,
    data,
  })
}
export function salesInfo(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/deals/salesinfo`,
    method: 'POST',
    label: SALES_INFO,
    data,
  })
}
export function dealAnalyticsByUsers(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/deals/dealanalyticsbuusers`,
    method: 'POST',
    label: DEAL_ANALYTICS_BY_USERS,
    data,
  })
}
