import {
  ADD_CONTACT_TYPE,
  UPDATE_CONTACT_TYPE,
  DELETE_CONTACT_TYPE,
  GET_LOST_REASONS,
  DELETE_LOST_REASONS,
  UPDATE_LOST_REASONS,
  ADD_LOST_REASONS,
  ADD_SOURCES,
  GET_SOURCES,
  UPDATE_SOURCES,
  DELETE_SOURCES,
  UPDATE_CONTACT_STATUS,
  DELETE_CONTACT_STATUS,
  GET_CONTACT_STATUSES,
  ADD_CONTACT_STATUS,
} from './types'
import {apiAction} from './api'
import {KADENCE_CONTACTS, KADENCE_DEALS} from './endpoint'

export function addContactType(data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/types`,
    method: 'POST',
    label: ADD_CONTACT_TYPE,
    data,
    showToast: true,
    successMessage: 'Contact Type Created',
  })
}

export function updateContactType(id, data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/types/${id}`,
    method: 'PUT',
    label: UPDATE_CONTACT_TYPE,
    data,
    showToast: true,
    successMessage: 'Contact Type Updated',
  })
}

export function deleteContactType(id) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/types/${id}`,
    method: 'DELETE',
    label: DELETE_CONTACT_TYPE,
    showToast: true,
    successMessage: 'Contact Type Deleted',
  })
}

export function addLostReasons(data) {
  return apiAction({
    url: `${KADENCE_DEALS}/lossreasons`,
    method: 'POST',
    label: ADD_LOST_REASONS,
    data,
    showToast: true,
    successMessage: 'Loss Reason Created',
  })
}

export function getLostReasons() {
  return apiAction({
    url: `${KADENCE_DEALS}/lossreasons`,
    method: 'GET',
    label: GET_LOST_REASONS,
  })
}

export function updateLostReasons(id, data) {
  return apiAction({
    url: `${KADENCE_DEALS}/lossreasons/${id}`,
    method: 'PUT',
    label: UPDATE_LOST_REASONS,
    data,
    showToast: true,
    successMessage: 'Loss Reason Updated',
  })
}

export function deleteLostReasons(id) {
  return apiAction({
    url: `${KADENCE_DEALS}/lossreasons/${id}`,
    method: 'DELETE',
    label: DELETE_LOST_REASONS,
    showToast: true,
    successMessage: 'Loss Reason Deleted',
  })
}

export function addSources(data) {
  return apiAction({
    url: `${KADENCE_DEALS}/sources`,
    method: 'POST',
    label: ADD_SOURCES,
    data,
    showToast: true,
    successMessage: 'Deals Source Created',
  })
}

export function getSources() {
  return apiAction({
    url: `${KADENCE_DEALS}/sources`,
    method: 'GET',
    label: GET_SOURCES,
  })
}

export function updateSources(id, data) {
  return apiAction({
    url: `${KADENCE_DEALS}/sources/${id}`,
    method: 'PUT',
    label: UPDATE_SOURCES,
    data,
    showToast: true,
    successMessage: 'Deals Source Updated',
  })
}

export function deleteSources(id) {
  return apiAction({
    url: `${KADENCE_DEALS}/sources/${id}`,
    method: 'DELETE',
    label: DELETE_SOURCES,
    showToast: true,
    successMessage: 'Deals Source Deleted',
  })
}

export function getContactStatus() {
  return apiAction({
    url: `${KADENCE_CONTACTS}/statuses`,
    method: 'GET',
    label: GET_CONTACT_STATUSES,
  })
}

export function updateContactStatus(id, data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/statuses/${id}`,
    method: 'PUT',
    label: UPDATE_CONTACT_STATUS,
    data,
    showToast: true,
    successMessage: 'Contact Status Updated',
  })
}

export function deleteContactStatus(id) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/statuses/${id}`,
    method: 'DELETE',
    label: DELETE_CONTACT_STATUS,
    showToast: true,
    successMessage: 'Contact Status Deleted',
  })
}

export function addContactStatus(data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/statuses`,
    method: 'POST',
    label: ADD_CONTACT_STATUS,
    data,
    showToast: true,
    successMessage: 'Contact Status Created',
  })
}
