import {KADENCE_CALENDAR} from './endpoint'
import {GET_ALL_CALENDAR_EVENTS} from './types'
import {apiAction} from './api'

export function getCalendarEvent(params = {}) {
  const keys = Object.keys(params)
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${KADENCE_CALENDAR}/events?${query}`,
    method: 'GET',
    label: GET_ALL_CALENDAR_EVENTS,
  })
}
