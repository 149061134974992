/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import {get} from 'utils/helper'
import agentAvatar from 'assets/user_avatar.png'

const UserImage = ({profile, onClick, style, className}) => {
  const dummyAvtar = profile => {
    if (get(['firstName'], profile) && get(['lastName'], profile)) {
      return profile.firstName.slice(0, 1).toUpperCase() + profile.lastName.slice(0, 1).toUpperCase()
    }
    if (get(['firstName'], profile) && !get(['lastName'], profile)) {
      return profile.firstName.slice(0, 2).toUpperCase()
    }
    return <img alt="img" src={agentAvatar} className="agentImage" />
  }

  if (get(['avatar'], profile)) {
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    return <img style={style} onClick={onClick} alt="img" src={get(['avatar'], profile)} className="agentImage" />
  }
  return (
    <div className={className ? `${className} dummyAvtar` : `dummyAvtar`} onClick={onClick} style={style}>
      {dummyAvtar(profile)}
    </div>
  )
}

export default UserImage
