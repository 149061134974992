import {apiAction} from './api'
import {KADENCE_ANNOUNCEMENT} from './endpoint'
import {
  EMPLOYEES_FOR_ANNOUNCEMENT,
  DEPARTMENTS_FOR_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT,
  GET_ALL_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  GET_SINGLE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
} from './types'

export function getAllEmployees() {
  return apiAction({
    url: `${KADENCE_ANNOUNCEMENT}/employee-list`,
    method: 'GET',
    label: EMPLOYEES_FOR_ANNOUNCEMENT,
  })
}

export function getAllDepartments() {
  return apiAction({
    url: `${KADENCE_ANNOUNCEMENT}/department-list`,
    method: 'GET',
    label: DEPARTMENTS_FOR_ANNOUNCEMENT,
  })
}

export function CreateAnnouncements(data) {
  return apiAction({
    url: `${KADENCE_ANNOUNCEMENT}`,
    method: 'POST',
    label: CREATE_ANNOUNCEMENT,
    data,
    showToast: true,
    successMessage: 'Announcement Added',
  })
}

export function getAllAnnouncements(params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params || {})
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }
  return apiAction({
    url: KADENCE_ANNOUNCEMENT + query,
    method: 'GET',
    label: GET_ALL_ANNOUNCEMENT,
  })
}

export function deleteAnnouncement(id) {
  return apiAction({
    url: `${KADENCE_ANNOUNCEMENT}/${id}`,
    method: 'DELETE',
    label: DELETE_ANNOUNCEMENT,
    showToast: true,
    successMessage: 'Announcement Deleted',
  })
}

export function getSingleAnnouncement(id) {
  return apiAction({
    url: `${KADENCE_ANNOUNCEMENT}/${id}`,
    method: 'GET',
    label: GET_SINGLE_ANNOUNCEMENT,
  })
}

export function updateAnnouncement(id, data) {
  return apiAction({
    url: `${KADENCE_ANNOUNCEMENT}/${id}`,
    method: 'PUT',
    label: UPDATE_ANNOUNCEMENT,
    data,
    showToast: true,
    successMessage: 'Announcement Updated',
  })
}
