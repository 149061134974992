import TimeOffMenu from 'navigation/sub-routes/Hr/TimeOff/TimeOffMenu'
import TimeOffRoutes from 'navigation/sub-routes/Hr/TimeOff/TimeOffRoutes'
import React from 'react'

function TimeOff() {
  return (
    <>
      <TimeOffMenu />
      <TimeOffRoutes />
    </>
  )
}

export default TimeOff
