import {
  SALES_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS,
  TOTAL_SALES_ORDERS,
  SALES_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS,
  NUMBER_OF_SALES_ORDER_BY_USERS,
  AMOUNT_OF_SALES_ORDER_BY_USERS,
  SALES_ORDER_COUNT_OVERTIME,
  SALES_ORDER_AMOUNT_OVERTIME,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case TOTAL_SALES_ORDERS:
      return {
        ...state,
        totalSalesOrdersData: action.payload,
      }

    case SALES_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS:
      return {
        ...state,
        numberSalesOrdersData: action.payload,
      }

    case SALES_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS:
      return {
        ...state,
        amountSalesOrdersData: action.payload,
      }

    case NUMBER_OF_SALES_ORDER_BY_USERS:
      return {
        ...state,
        salesOrderNumberByUserData: action.payload,
      }

    case AMOUNT_OF_SALES_ORDER_BY_USERS:
      return {
        ...state,
        salesOrderAmountByUserData: action.payload,
      }

    case SALES_ORDER_COUNT_OVERTIME:
      return {
        ...state,
        salesOrderCountOvertimeData: action.payload,
      }

    case SALES_ORDER_AMOUNT_OVERTIME:
      return {
        ...state,
        salesOrderAmountOvertimeData: action.payload,
      }

    default:
      return state
  }
}
