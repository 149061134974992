import React from 'react'
import {useParams} from 'react-router'
import EmailTemplateForm from './EmailTemplateForm'

function EmailTemplateDetails(props) {
  const {id} = useParams()
  return <EmailTemplateForm {...props} editId={id} />
}

export default EmailTemplateDetails
