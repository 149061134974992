import {apiAction} from './api'
import {KADENCE_CONTACTS, KADENCE_COMPANY} from './endpoint'
import {
  ADD_SEGMENT,
  DELETE_SEGMENT,
  GET_ALL_SEGMENT,
  GET_SEGMENT,
  GET_SEGMENTS_LIST,
  GET_STATIC_CONTACT,
  UPDATE_SEGMENT,
} from './types'

const handleSection = type => {
  if (type === 'contacts') {
    return KADENCE_CONTACTS
  }
  if (type === 'companies') {
    return KADENCE_COMPANY
  }
}

export function getSegmentListWithNumbers(type) {
  return apiAction({
    url: `${handleSection(type)}/segmentswithnumbers`,
    method: 'GET',
    label: GET_SEGMENTS_LIST,
    successData: type,
  })
}

export function getSegmentList(type) {
  return apiAction({
    url: `${handleSection(type)}/segments`,
    method: 'GET',
    label: GET_SEGMENTS_LIST,
    successData: type,
  })
}

export function addSegment(type, data) {
  return apiAction({
    url: `${handleSection(type)}/segments`,
    method: 'POST',
    label: ADD_SEGMENT,
    data,
    successMessage: 'List created',
    showToast: true,
  })
}

export function updateSegment(type, data, id) {
  return apiAction({
    url: `${handleSection(type)}/segments/${id}`,
    method: 'PUT',
    label: UPDATE_SEGMENT,
    data,
  })
}

export function getSegment(type, id) {
  return apiAction({
    url: `${handleSection(type)}/segments/${id}`,
    method: 'GET',
    label: GET_SEGMENT,
  })
}

export function deleteSegment(type, id) {
  return apiAction({
    url: `${handleSection(type)}/segments/${id}`,
    method: 'DELETE',
    label: DELETE_SEGMENT,
    successMessage: 'List deleted',
    showToast: true,
  })
}
export function getStaticSegmentContactList(type, params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params)
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }

  return apiAction({
    url: handleSection(type) + query,
    method: 'GET',
    label: GET_STATIC_CONTACT,
    tokenIdentity: 'crm',
  })
}

export function getAllSegment() {
  return apiAction({
    url: `${KADENCE_CONTACTS}/segments`,
    method: 'GET',
    label: GET_ALL_SEGMENT,
  })
}
