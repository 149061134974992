import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  VmoButton,
  VmoChipInput,
  VmoDropdown,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoGrid,
  VmoGridColumn,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'

import {contactMultipleUpdate} from 'actions/contacts'
import {createTagSettings} from 'actions/multiple_fields'
import {get, removeDoubleQuotes, KadenceCountryList} from 'utils/helper'
import EmailCreateMultiple from 'components/common/EmailCreateMultiple'
import PhoneCreateMultiple from 'components/common/PhoneCreateMultiple'
import WebsiteCreateMultiple from 'components/common/WebsiteCreateMultiple'
import CreateLabel from 'components/common/CreateLabel'
import KadenceCustomDropdown from 'components/common/KadenceCustomDropdown'
import {multipleEditContactSchema} from 'validation/Customers/contacts.schema'
import SvgIcon from 'components/common/SvgIcon'

const EditContactModal = props => {
  const {isModalOpen, setIsModalOpen, checked} = props
  const dispatch = useDispatch()

  const [userOption, setUserOption] = useState([])
  const [showInputs, setShowInputs] = useState({})
  const [createInfo, setCreateInfo] = useState({})
  const [labelOptionsState, setLabelOptionsState] = useState([])
  const [contactCompanyList, setContactCompanyList] = useState([])
  const [newLabelModal, setNewLabelModal] = useState(false)
  const [listOfLable, setListOfLabel] = useState([])
  const [tagsOptionList, setTagsOptionList] = useState([])
  const [statusOptionList, setStatusOptionList] = useState([])
  const [typeOptionList, setTypeOptionList] = useState([])
  // UseForm
  const initialCreateInfo = {
    firstName: '',
    lastName: '',
    emails: [{category: 'work', email: ''}],
    phones: [{category: 'work', phone: ''}],
    title: '',
    // companyId: null,
    websites: [{category: 'work', url: ''}],
    address: {},
    statusId: null,
    // visibility: "",
    assigneeId: null,
    // socials: [{ category: "facebook", url: "" }],
    description: '',
    contactTypeId: null,
    labelIds: [],
  }
  const {handleSubmit, errors, control} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(multipleEditContactSchema),
    defaultValues: initialCreateInfo,
  })
  const {getUsersList = {}} = useSelector(state => state.settings)
  const {contactStatusListData, getTypeListData, getAllContactCompanyList} = useSelector(state => state.contacts)

  const {getTagList} = useSelector(state => state.multipleFields)

  useEffect(() => {
    if (getTypeListData) {
      const list = getTypeListData.map(item => ({
        value: item.id,
        text: item.type,
        key: item.id,
      }))
      setTypeOptionList(list)
    }
  }, [getTypeListData])
  useEffect(() => {
    if (getTagList) {
      const list = getTagList.map(user => ({
        value: user.id,
        text: user.label,
        key: user.id,
        label: {
          style: {background: user.color},
          empty: true,
          circular: true,
        },
      }))
      setLabelOptionsState(list)
      setTagsOptionList(list)
    }
  }, [getTagList])
  useEffect(() => {
    if (contactStatusListData) {
      const list = contactStatusListData.map(user => ({
        value: user.id,
        text: user.status,
        key: user.id,
      }))
      setStatusOptionList(list)
    }
  }, [contactStatusListData])
  useEffect(() => {
    if (getAllContactCompanyList) {
      const companyList = getAllContactCompanyList.map(list => ({
        key: list.id,
        value: list.id,
        text: list.name,
      }))
      setContactCompanyList(companyList)
    }
  }, [getAllContactCompanyList])

  useEffect(() => {
    if (getUsersList) {
      const list = getUsersList.users.map(user => ({
        value: user.id,
        text: `${get(['firstName'], user, '')} ${get(['lastName'], user, '')}`,
        key: user.id,
      }))
      setUserOption(list)
    }
  }, [getUsersList])

  useEffect(() => {
    setCreateInfo({})
    // setEmailList([{ email: "", category: "work" }]);
    // setPhoneList([{ phone: "", category: "work" }]);
    // setWebsiteList([{ url: "", category: "work" }]);
    setShowInputs({
      firstName: false,
      lastName: false,
      company: false,
      title: false,
      email: false,
      phone: false,
      website: false,
      address: false,
      tags: false,
      status: false,
      contactType: false,
      assignee: false,
      description: false,
    })
  }, [isModalOpen])

  const createNewLabel = () => {
    if (newLabelModal) {
      return (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
          // leadIds={[leadId]}
        />
      )
    }
  }
  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: 'emails',
  })

  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: 'phones',
  })

  const {
    fields: websiteFields,
    append: appendWebsite,
    remove: removeWebsite,
  } = useFieldArray({
    control,
    name: 'websites',
  })

  const handleMultipleUpdateContact = data => {
    const getNewData = _.pickBy(
      {
        ...data,
        emails: _.filter(_.get(data, 'emails'), item => item.email !== '' && item.email !== undefined),
        phones: _.filter(_.get(data, 'phones'), item => item.phone !== '' && item.phone !== undefined),
        websites: _.filter(_.get(data, 'websites'), item => item.url !== '' && item.url !== undefined),
      },
      _.identity
    )
    const dispatchData = {ids: checked, ...getNewData, ...createInfo}

    dispatch(contactMultipleUpdate(dispatchData))
  }
  return (
    <VmoModal
      className="kadence-modal"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <VmoModalHeader>Edit Contact Details</VmoModalHeader>
      <VmoModalContent scrolling className="vmo-scroll">
        <h4 className="pb-3">Selected Contact ({checked.length})</h4>

        <VmoForm className="vmo-form">
          <VmoFormField>
            <label className="d-block">First Name</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  firstName: !showInputs.firstName,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.firstName && (
              <Controller
                name="firstName"
                render={({onChange, value}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="Type name..."
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors.firstName && {
                        content: removeDoubleQuotes(errors.firstName.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Last Name</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() => setShowInputs({...showInputs, lastName: !showInputs.lastName})}
            >
              Click to edit all
            </VmoButton>
            {showInputs.lastName && (
              <Controller
                name="lastName"
                render={({onChange, value}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="Type name..."
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors.lastName && {
                        content: removeDoubleQuotes(errors.lastName.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Company</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() => setShowInputs({...showInputs, company: !showInputs.company})}
            >
              Click to edit all
            </VmoButton>
            {showInputs.company && (
              <VmoFormField>
                <label>Company</label>
                <VmoDropdown
                  placeholder="Company"
                  selection
                  fluid
                  search
                  clearable
                  additionPosition="bottom"
                  allowAdditions
                  additionLabel={<i>Add Company: </i>}
                  onChange={(e, {value}) => {
                    if (typeof value === 'number') {
                      setCreateInfo({...createInfo, companyId: value})
                    } else {
                      setCreateInfo({...createInfo, company: value})
                    }
                  }}
                  onAddItem={(e, {value}) => {
                    setContactCompanyList([{value, key: value, text: value}, ...contactCompanyList])
                  }}
                  value={createInfo.company ? get(['company'], createInfo) : get(['companyId'], createInfo)}
                  options={contactCompanyList}
                />
                {/* <Controller
                  name="companyId"
                  render={({ onChange, value, onBlur }) => (
                    <VmoDropdown
                      selection
                      fluid
                      search
                      clearable
                      onBlur={onBlur}
                      additionPosition={"bottom"}
                      value={value}
                      options={contactCompanyList}
                      placeholder="Enter company..."
                      onChange={(e, { value }) => {
                        onChange(value);
                      }}
                      error={
                        errors.companyId && {
                          content: errors.companyId.message,
                        }
                      }
                    />
                  )}
                  control={control}
                /> */}
              </VmoFormField>
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Title</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() => setShowInputs({...showInputs, title: !showInputs.title})}
            >
              Click to edit all
            </VmoButton>
            {showInputs.title && (
              <Controller
                name="title"
                render={({onChange, value}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="Type title..."
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors.title && {
                        content: removeDoubleQuotes(errors.title.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Email</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() => setShowInputs({...showInputs, email: !showInputs.email})}
            >
              Click to edit all
            </VmoButton>
            {showInputs.email && (
              <EmailCreateMultiple
                emailFields={emailFields}
                errors={errors}
                appendEmail={appendEmail}
                control={control}
                removeEmail={removeEmail}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Phone</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() => setShowInputs({...showInputs, phone: !showInputs.phone})}
            >
              Click to edit all
            </VmoButton>
            {showInputs.phone && (
              <PhoneCreateMultiple
                phoneFields={phoneFields}
                errors={errors}
                appendPhone={appendPhone}
                control={control}
                removePhone={removePhone}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Website</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() => setShowInputs({...showInputs, website: !showInputs.website})}
            >
              Click to edit all
            </VmoButton>
            {showInputs.website && (
              <WebsiteCreateMultiple
                websiteFields={websiteFields}
                errors={errors}
                appendWebsite={appendWebsite}
                control={control}
                removeWebsite={removeWebsite}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Tags</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() => setShowInputs({...showInputs, tags: !showInputs.tags})}
            >
              Click to edit all
            </VmoButton>
            {showInputs.tags && (
              <>
                <Controller
                  control={control}
                  name="labelIds"
                  render={({value, onChange}) => (
                    <VmoChipInput
                      defaultValue={listOfLable.map(tag => {
                        return {
                          id: tag.value,
                          text: (
                            <>
                              <div
                                style={{
                                  height: '10px',
                                  width: '10px',
                                  background: tag.label.style.background,
                                  borderRadius: '50%',
                                  marginRight: '10px',
                                  display: 'inline-block',
                                }}
                              />
                              {tag.text}
                            </>
                          ),
                        }
                      })}
                      onChange={value => {
                        onChange(value)
                        const valueIds = value.map(tag => tag.id)
                        const removeTag = listOfLable.filter(contact => valueIds.indexOf(contact.value) !== -1)
                        setListOfLabel(removeTag)
                        const id = _.map(removeTag, 'value')
                        setCreateInfo({...createInfo, labelIds: id})
                      }}
                      hideInputField
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="labelIds"
                  render={({value, onChange}) => (
                    <KadenceCustomDropdown
                      options={labelOptionsState}
                      multiple
                      dropdownProps={{
                        icon: (
                          <VmoTooltip
                            content="Tags"
                            size="mini"
                            position="bottom center"
                            trigger={<SvgIcon path="common/tag" id="TooltipTagInbox" />}
                          />
                        ),
                        direction: 'right',
                        closeOnChange: false,
                      }}
                      menu={{scrolling: true}}
                      input={{
                        icon: 'search',
                        iconPosition: 'left',
                        className: 'search',
                      }}
                      activeIndicator
                      value={listOfLable.map(labels => labels.value)}
                      onClick={value => {
                        onChange(value)
                        const updatedList = labelOptionsState.filter(val => value.indexOf(val.value) !== -1)
                        setListOfLabel(updatedList)
                      }}
                      extraAction={{
                        content: '+ Add Tag',
                        onClick: () => setNewLabelModal(true),
                      }}
                    />
                  )}
                />
              </>
            )}
          </VmoFormField>

          <VmoFormField>
            <label className="d-block">Assignee</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  assignee: !showInputs.assignee,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.assignee && (
              <Controller
                name="assigneeId"
                render={({onChange, value, onBlur}) => (
                  <VmoFormSelect
                    fluid
                    options={userOption}
                    selection
                    placeholder="Owners"
                    onBlur={onBlur}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors?.assigneeId && {
                        content: removeDoubleQuotes(errors?.assigneeId?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Contact Type</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  contactType: !showInputs.contactType,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.contactType && (
              <Controller
                name="contactTypeId"
                render={({onChange, value, onBlur}) => (
                  <VmoFormSelect
                    fluid
                    options={typeOptionList}
                    selection
                    placeholder="Contact type"
                    onBlur={onBlur}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors?.contactTypeId && {
                        content: removeDoubleQuotes(errors?.contactTypeId?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            )}
          </VmoFormField>

          <VmoFormField>
            <label className="d-block">Status</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  status: !showInputs.status,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.status && (
              <Controller
                name="statusId"
                render={({onChange, value, onBlur}) => (
                  <VmoFormSelect
                    fluid
                    options={statusOptionList}
                    selection
                    placeholder="Status"
                    onBlur={onBlur}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors?.statusId && {
                        content: removeDoubleQuotes(errors?.statusId?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Address</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  address: !showInputs.address,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.address && (
              <>
                <Controller
                  name="address.streetAddress"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      fluid
                      value={value}
                      placeholder="Street"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.address?.streetAddress && {
                          content: removeDoubleQuotes(errors?.address?.streetAddress?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
                <VmoGrid className="mt-2">
                  <VmoGridColumn width={8}>
                    <Controller
                      name="address.locality"
                      render={({onChange, value}) => (
                        <VmoFormInput
                          type="text"
                          fluid
                          value={value}
                          placeholder="Locality"
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          error={
                            errors?.address?.locality && {
                              content: removeDoubleQuotes(errors?.address?.locality?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </VmoGridColumn>
                  <VmoGridColumn width={8}>
                    <Controller
                      name="address.state"
                      render={({onChange, value}) => (
                        <VmoFormInput
                          type="text"
                          fluid
                          value={value}
                          placeholder="State"
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          error={
                            errors?.address?.state && {
                              content: removeDoubleQuotes(errors?.address?.state?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </VmoGridColumn>
                </VmoGrid>
                <VmoGrid>
                  <VmoGridColumn width={8}>
                    <Controller
                      name="address.country"
                      render={({onChange, value, onBlur}) => (
                        <VmoFormSelect
                          search
                          fluid
                          options={KadenceCountryList}
                          selection
                          onBlur={onBlur}
                          placeholder="Country"
                          value={value}
                          onChange={(e, {value}) => {
                            onChange(value)
                          }}
                          error={
                            errors?.address?.country && {
                              content: removeDoubleQuotes(errors?.address?.country?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </VmoGridColumn>
                  <VmoGridColumn width={8}>
                    <Controller
                      name="address.postalCode"
                      render={({onChange, value, onBlur}) => (
                        <VmoFormInput
                          type="text"
                          fluid
                          value={value}
                          onBlur={onBlur}
                          placeholder="Postal Code"
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          error={
                            errors?.address?.postalCode && {
                              content: removeDoubleQuotes(errors?.address?.postalCode?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </VmoGridColumn>
                </VmoGrid>
              </>
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Description</label>
            <VmoButton
              color="link"
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  description: !showInputs.description,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.description && (
              <Controller
                name="description"
                render={({onChange, value}) => (
                  <VmoFormTextArea
                    type="text"
                    fluid
                    value={value}
                    placeholder="Description"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors.description && {
                        content: removeDoubleQuotes(errors.description.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            )}
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </VmoButton>
        {/* <VmoButton
          color="primary"
          onClick={handleSubmit(handleMultipleUpdateContact)}
        >
          Save
        </VmoButton> */}
        <VmoButton primary onClick={handleSubmit(handleMultipleUpdateContact)}>
          Add
        </VmoButton>
      </VmoModalActions>
      {createNewLabel()}
    </VmoModal>
  )
}

export default EditContactModal
