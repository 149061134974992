import {HOME_STATS, TOP_TASKS, STATUS_WISE_DEALS} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case HOME_STATS:
      return {
        ...state,
        homeStatsData: action.payload,
      }
    case TOP_TASKS:
      return {
        ...state,
        topTasksData: action.payload,
      }
    case STATUS_WISE_DEALS:
      return {
        ...state,
        statusWiseDealsData: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
