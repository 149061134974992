import React, {useEffect, useState} from 'react'
import ProjectFinanceMenu from 'navigation/sub-routes/Project/ProjectFinance/ProjectFinanceMenu'
import ProjectFinanceRoutes from 'navigation/sub-routes/Project/ProjectFinance/ProjectFinanceRoutes'
import {getProjectFinanceBudget} from 'actions/projects'
import {useLocation, useParams} from 'react-router-dom/cjs/react-router-dom.min'
import {GET_PROJECT_FINANCE_BUDGET} from 'actions/types'
import useApiResponse from 'hooks/impure/useApiResponse'

const ProjectFinance = () => {
  const {projectId} = useParams()
  const {state = {}} = useLocation()

  const [budgetId, setBudgetId] = useState(null)

  useApiResponse({
    action: getProjectFinanceBudget,
    payload: projectId,
    dependency: [projectId],
    enabled: projectId,
    label: GET_PROJECT_FINANCE_BUDGET,
    storePath: 'projects.projectBudget',
    onSuccess: (projectBudget = {}) => {
      setBudgetId(projectBudget?.id)
    },
  })

  useEffect(() => {
    if (state?.budgetId) {
      setBudgetId(state.budgetId)
    }
  }, [state])

  return (
    <div>
      <ProjectFinanceMenu />
      <ProjectFinanceRoutes projectId={projectId} budgetId={budgetId} />
    </div>
  )
}

export default ProjectFinance
