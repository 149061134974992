import React, {useEffect} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {VmoDropdown, VmoForm, VmoFormCheckbox, VmoFormField} from 'vmo-library'
import {getEmployeeSetting, editEmployeeSetting} from 'actions/employees'
import {removeDoubleQuotes} from 'utils/helper'
import SettingLayout from 'layouts/settingLayout'
import {GET_EMPLOYEES_SETTING, UPDATE_EMPLOYEES_SETTING} from 'actions/types'
import EmployeeSidemenu from 'navigation/sub-routes/Hr/Employees/EmployeeMenu'
import {settingsSchema} from 'validation/Hr/employees.schema'

const EmployeesGeneral = () => {
  const dispatch = useDispatch()

  const {employeeSetting} = useSelector(state => state.employees)
  const {successLabels = [], isLoadingData, type} = useSelector(state => state.apiReducer)

  const initailData = {
    employeeEditing: _.get(employeeSetting, 'employeeEditing'),
    workingHours: _.get(employeeSetting, 'workingHours'),
  }

  const {
    handleSubmit,
    control,
    reset,
    errors,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(settingsSchema),
    defaultValues: initailData,
  })

  useEffect(() => {
    dispatch(getEmployeeSetting())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_EMPLOYEES_SETTING)) {
      reset(initailData)
    }
  }, [successLabels])

  return (
    <>
      <EmployeeSidemenu />

      <SettingLayout
        header="General Settings"
        subHeader="Manage all general settings related to employee"
        table={false}
        headerDivider
        actionButton={{
          cancel: {
            disabled: !isDirty || (isLoadingData && type.includes(UPDATE_EMPLOYEES_SETTING)),
            content: 'Reset',
            onClick: () => reset(initailData),
          },
          success: {
            loading: isLoadingData && type.includes(UPDATE_EMPLOYEES_SETTING),
            content: 'Save',
            onClick: handleSubmit(data => {
              dispatch(editEmployeeSetting(data))
            }),
            disabled: !isDirty || (isLoadingData && type.includes(UPDATE_EMPLOYEES_SETTING)),
          },
        }}
      >
        <VmoForm>
          <VmoFormField>
            <div className="d-flex">
              <div>
                <label>Employee Editing</label>
                <p className="kadence-lead mb-0">Allow employees to update their own data</p>
              </div>
              <Controller
                control={control}
                name="employeeEditing"
                render={({onChange, value}) => (
                  <VmoFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                )}
              />
            </div>
          </VmoFormField>

          <VmoFormField width={4}>
            <label>Working Hours</label>
            <Controller
              name="workingHours"
              render={({onChange, value}) => (
                <VmoDropdown
                  fluid
                  selection
                  options={[
                    {value: '36H/W', key: '36H/W', text: '36 hours / week'},
                    {value: '38H/W', key: '38H/W', text: '38 hours / week'},
                    {value: '40H/W', key: '40H/W', text: '40 hours / week'},
                  ]}
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  error={
                    errors?.workingHours && {
                      content: removeDoubleQuotes(errors?.workingHours?.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoForm>
      </SettingLayout>
    </>
  )
}

export default EmployeesGeneral
