import {GET_ALL_ROLES, GET_ROLE, GET_ROLES_STRUCTURE, GET_USER_ROLE_PERMISSIONS} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case GET_ALL_ROLES:
      return {...state, getRoleList: payload || []}

    case GET_ROLE:
      return {
        ...state,
        getRoleData: payload || {},
      }

    case GET_ROLES_STRUCTURE:
      return {...state, rolesStructure: payload || {}}

    case GET_USER_ROLE_PERMISSIONS:
      return {...state, permissions: payload || {}}

    default:
      return state
  }
}
