import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

import {CREATE_SALES_ORDERS_PREFIX} from 'actions/types'
import {getSalesOrdersPrefixes, createSalesOrdersPrefix} from 'actions/salesOrders'
import {settingSchema} from 'validation/Finance/salesOrders.schema'
import SvgIcon from 'components/common/SvgIcon'

function PrefixModal({open, toggle}) {
  const dispatch = useDispatch()

  const {handleSubmit, errors, control} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(settingSchema),
    defaultValues: {
      prefix: '',
    },
  })

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(CREATE_SALES_ORDERS_PREFIX)) {
      toggle()
      dispatch(getSalesOrdersPrefixes())
    }
  }, [successLabels, dispatch])

  const handleSave = data => {
    dispatch(createSalesOrdersPrefix(data))
  }

  return (
    <VmoModal
      open={open}
      onClose={toggle}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      centered={false}
    >
      <VmoModalHeader className="vmoModalHeader">Create Prefix</VmoModalHeader>

      <VmoModalContent>
        <VmoForm className="errorLabel" onSubmit={handleSubmit(handleSave)}>
          <VmoFormField required>
            <label>Prefix name</label>
            <Controller
              name="prefix"
              render={({value, onChange}) => (
                <VmoFormInput
                  maxLength={20}
                  autoFocus
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.prefix && {
                      content: errors.prefix.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>

      <VmoModalActions>
        <VmoButton disabled={isLoadingData && type.includes(CREATE_SALES_ORDERS_PREFIX)} basic onClick={toggle}>
          Cancel
        </VmoButton>

        <VmoButton
          loading={isLoadingData && type.includes(CREATE_SALES_ORDERS_PREFIX)}
          disabled={isLoadingData && type.includes(CREATE_SALES_ORDERS_PREFIX)}
          primary
          onClick={handleSubmit(handleSave)}
        >
          Create
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default PrefixModal
