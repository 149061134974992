/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiAlertCircle, FiAlertTriangle, FiAtSign, FiClock, FiSend, FiStar, FiUserCheck, FiUserX} from 'react-icons/fi'
import Joyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride'
import {setMetaData, getMetaData} from 'actions'
import {
  getAllMailInbox,
  getConversation,
  getMailBoxConversation,
  getStatusList,
  inboxCounts,
  updateConversationisRead,
} from 'actions/inbox'
import {getTags} from 'actions/settings_productivity'
import {CREATE_TAG, GET_CONVERSATION_DATA, SEND_MAIL, SET_METADATA, UPDATE_CONTACT_ONLINE_STATUS} from 'actions/types'
import {get, startCase, wrapText} from 'utils/helper'
import {EmailGridView} from 'components/pages/EmailInbox/EmailGridView'
import {InboxNavigation} from 'components/pages/EmailInbox/InboxNavigation'
import {getAppToken, getMetadataInfo, setMetadataInfo} from 'utils/local-storage'
import io from 'socket'
import {useLocation} from 'react-router-dom'
import EmailEditorBox from 'components/common/EmailEditorBox'
import EmailBodyContextProvider from 'context/email-body'
import {getTimeTrackingSetting, getTimeTrackingStatus} from 'actions/time_tracking'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'

export function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

const customtStyles = {
  options: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    primaryColor: '#2772ea',
    textColor: '#001F3D',
    width: '400px',
    zIndex: 1000,
  },
  tooltip: {
    padding: '16px',
  },
  tooltipContainer: {
    textAlign: 'left',
    position: 'relative',
    bottom: 5,
  },
  tooltipContent: {
    padding: '25px 0px 20px 0px',
    textAlign: 'left',
  },
  buttonNext: {
    color: '#fff',
    padding: '8px 20px',
  },
  buttonBack: {
    marginRight: 10,
  },
  buttonSkip: {
    fontSize: 14,
    position: 'relative',
    right: 8,
    top: 2,
  },
  buttonClose: {
    position: 'absolute',
    top: 3,
    right: 3,
    width: 12,
  },
}

const inboxToursSteps = [
  {
    placement: 'center',
    target: 'body',
    content: 'Get a quick overview of your team inbox.',
    locale: {
      skip: <span onClick={e => e.stopPropagation()}> 1 of 5 </span>,
    },
    title: 'Email Inbox',
  },
  {
    placement: 'right-end',
    title: 'Assign to me',
    target: '.inboxtour__step-one',
    content: 'Manage all conversations assigned to you across all inboxes.',
    locale: {
      skip: <span onClick={e => e.stopPropagation()}> 2 of 5 </span>,
    },
  },
  {
    placement: 'left-start',
    title: 'Assign conversations',
    target: '.inboxtour__step-two',
    content: 'Give your team more control by assigning conversations to right users.',
    locale: {
      skip: <span onClick={e => e.stopPropagation()}> 3 of 5 </span>,
    },
  },
  {
    placement: 'right-end',
    title: 'Status',
    target: '.inboxtour__step-three',
    content: 'Keep track of your conversations by assigning right status.',
    locale: {
      skip: <span onClick={e => e.stopPropagation()}> 4 of 5 </span>,
    },
  },
  {
    target: '.inboxtour__step-four',
    content: 'Get a complete view of your customer activities in one place',
    title: 'Customer Activities',
    placement: 'left-start',
  },
]

export function Inbox(props) {
  const notificationAudio = new Audio('/juntos.mp3')

  const dispatch = useDispatch()
  const location = useLocation()
  const {state: {POState, SOState, InvoiceState, quoteState} = {}} = useLocation()
  const [obj, setObj] = useState(null)
  const [selectedInbox, setSelectedInbox] = useState({
    type: 'default',
    name: 'all_inbox',
    label: 'All Inbox',
  })
  const [selectedEmail, setSelectedEmail] = useState(null)
  const [isOpenMailComposer, setIsOpenMailComposer] = useState(false)
  const [checkedConversations, setCheckedConversations] = useState([])
  const [searchConvseration, setSearchConvseration] = useState('')
  const sideBar = JSON.parse(window.localStorage.getItem('sidebarToggle'))
  const [customerProfile, setCustomerProfile] = useState(sideBar)
  const [lastIndex, setLastIndex] = useState(1)
  const [currentlySelectedItem, setCurrentlySelectdItem] = useState(0)
  const [defaultValue, setDefaultValue] = useState('')
  const [typingConversationId, setTypingConversationId] = useState([])

  // React-Joyride steps state
  const [joyRide, setJoyRide] = useState({
    steps: [],
    stepIndex: 0,
    run: false,
  })

  // Body of compose email
  const ConversationId = useSelector(state => {
    return get(['emailInbox', 'conversation', 'id'], state)
  })
  const {
    myUnreadCount,
    allUnreadCount,
    draftCount,
    unassignedCount,
    products,
    conversations = [],
    conversation = {},
  } = useSelector(state => state.emailInbox)
  const {inbox: mailboxes = []} = useSelector(state => state.mailInbox)
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {timeTrackingData = {}} = useSelector(state => state.timeTracking)

  useEffect(() => {
    if (POState?.length) {
      setDefaultValue(POState)
      setIsOpenMailComposer(true)
    }
    if (SOState?.length) {
      setDefaultValue(SOState)
      setIsOpenMailComposer(true)
    }
    if (quoteState?.length) {
      setDefaultValue(quoteState)
      setIsOpenMailComposer(true)
    }
    if (InvoiceState?.length) {
      setDefaultValue(InvoiceState)
      setIsOpenMailComposer(true)
    }
  }, [location.state, POState?.length, SOState?.length, quoteState?.length, InvoiceState?.length])

  // Functions ----------------------->
  const renderInboxName = () => {
    if (selectedInbox.type === 'default') {
      if (selectedInbox.name === 'unassign') {
        return 'Unassigned'
      }
      if (selectedInbox.name === 'slaBreached') {
        return 'SLA Breached'
      }
      if (selectedInbox.name === 'assign_me') {
        return 'Assigned to Me'
      }
      return startCase(selectedInbox.name)
    }
    return wrapText(selectedInbox.label, 12)
  }

  const renderInboxIcon = () => {
    if (selectedInbox.type === 'default') {
      switch (selectedInbox.name) {
        case 'all_inbox': {
          return <SvgIcon path="analytics/reports/inbox" />
        }
        case 'unassign': {
          return <FiUserX />
        }
        case 'overdue': {
          return <FiClock />
        }
        case 'slaBreached': {
          return <FiAlertTriangle />
        }
        case 'sent': {
          return <FiSend />
        }
        case 'snooze': {
          return <FiClock />
        }
        case 'trash': {
          return <SvgIcon path="common/delete" />
        }
        case 'draft': {
          return <SvgIcon path="common/file" />
        }
        case 'spam': {
          return <FiAlertCircle />
        }
        case 'assign_me': {
          return <FiUserCheck />
        }
        case 'mention': {
          return <FiAtSign />
        }
        case 'following': {
          return <FiStar />
        }
        case 'search_results': {
          return <SvgIcon path="common/search" />
        }
        default: {
          return <SvgIcon path="analytics/reports/inbox" />
        }
      }
    } else if (selectedInbox.type === 'inbox') {
      return <SvgIcon path="common/mail" />
    } else if (selectedInbox.type === 'teammate') {
      return <SvgIcon path="settings/users" />
    } else {
      return <SvgIcon path="common/folder" />
    }
  }

  function onClickItem(id, conversation) {
    dispatch({
      type: 'CLEAR_CONVERSATION',
    })

    const indexofSelected = conversations.findIndex(index => index.id === id)

    if (indexofSelected > 0) {
      setLastIndex(indexofSelected)
    } else {
      setLastIndex(indexofSelected + 1)
    }

    setCurrentlySelectdItem(id)
    setSelectedEmail(conversations.filter(item => item.id === id)[0] || conversation)

    const data = {
      conversationIds: [id],
      isRead: true,
    }
    dispatch(updateConversationisRead(data))
    dispatch(getConversation(id))
  }
  function updateCheckedConversation(action, id) {
    let list = [...checkedConversations]

    switch (action) {
      case 'add': {
        list.push(id)
        break
      }
      case 'remove': {
        list = list.filter(ids => ids !== id)
        break
      }
      case 'addAll': {
        list = conversations.map(conv => conv.id)

        break
      }
      case 'removeAll': {
        list = []
        break
      }
      default:
        break
    }

    setCheckedConversations(list)
  }

  const setSelectedConversation = useCallback(
    (conversationId, toRemoveConversation) => {
      if (conversations.length > 0) {
        const indexofSelected = conversations.findIndex(index => index.id === conversationId)

        if (indexofSelected !== -1 || (indexofSelected !== -1 && toRemoveConversation && conversations.length > 0)) {
          setLastIndex(indexofSelected)
          //
          setSelectedEmail(conversations[indexofSelected])
          setCurrentlySelectdItem(conversations[indexofSelected].id)
          dispatch(getConversation(conversations[indexofSelected].id))
        }
      }
      if (toRemoveConversation) {
        if (conversations.length >= lastIndex && lastIndex >= 1) {
          setLastIndex(lastIndex - 1)
          setSelectedEmail(conversations[lastIndex - 1])
          setCurrentlySelectdItem(conversations[lastIndex - 1].id)
          dispatch(getConversation(conversations[lastIndex - 1].id))
        }
      }
    },
    [conversation, lastIndex, conversations, dispatch]
  )

  const loadSelectedInboxData = useCallback(
    selectedInbox => {
      let dupObj = {}
      if (selectedInbox.type === 'inbox') {
        const inboxId = selectedInbox.name
        dupObj = {
          inboxId,
          isTrashed: false,
          isArchived: false,
          isSpam: false,
          page: 1,
        }
        dispatch({type: 'CLEAR_CONVERSATION_LIST'})
        setObj(dupObj)
      }
      if (selectedInbox.type === 'default') {
        switch (selectedInbox.name) {
          case 'all_inbox': {
            setObj({})
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            break
          }
          case 'assign_me': {
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            dupObj.assignedToMe = true
            setObj(dupObj)
            break
          }
          case 'unassign': {
            dupObj.unassigned = true
            setObj(dupObj)
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            break
          }
          case 'sent': {
            dupObj.sent = true
            setObj(dupObj)
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            break
          }
          case 'overdue': {
            dupObj.overdue = true
            setObj(dupObj)
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            break
          }
          case 'offline': {
            dupObj.offline = true
            setObj(dupObj)
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            break
          }
          case 'slaBreached': {
            dupObj.slaBreached = true
            setObj(dupObj)
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            break
          }
          case 'snooze': {
            dupObj.snooze = true
            setObj(dupObj)
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            break
          }
          case 'trash': {
            dupObj.isTrashed = true
            setObj(dupObj)
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            break
          }
          case 'spam': {
            dupObj.isSpam = true
            setObj(dupObj)
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            break
          }
          case 'draft': {
            dupObj.isDraft = true
            setObj(dupObj)
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            break
          }
          case 'following': {
            dupObj.isStarred = true
            setObj(dupObj)
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            break
          }
          case 'mention': {
            dupObj.myMentions = true
            setObj(dupObj)
            dispatch({type: 'CLEAR_CONVERSATION_LIST'})
            break
          }

          default:
            break
        }
      }
      if (selectedInbox.type === 'teammate') {
        dupObj.assigneeId = selectedInbox.name
        setObj(dupObj)
        dispatch({type: 'CLEAR_CONVERSATION_LIST'})
      }
      if (selectedInbox.type === 'tag') {
        dupObj.labelId = selectedInbox.name
        setObj(dupObj)
        dispatch({type: 'CLEAR_CONVERSATION_LIST'})
      }

      if (selectedInbox.type === 'mention') {
        dupObj.myMentions = selectedInbox.name
        setObj(dupObj)
        dispatch({type: 'CLEAR_CONVERSATION_LIST'})
      }

      if (selectedInbox.type === 'status') {
        dupObj.statusId = selectedInbox.name
        setObj(dupObj)
        dispatch({type: 'CLEAR_CONVERSATION_LIST'})
      }

      if (selectedInbox.type === 'priority') {
        dupObj.priority = selectedInbox.name
        setObj(dupObj)
        dispatch({type: 'CLEAR_CONVERSATION_LIST'})
      }
    },
    [dispatch]
  )

  const refreshInboxData = toRemoveConversation => {
    dispatch(inboxCounts())
    loadSelectedInboxData(selectedInbox)
    setSelectedConversation(ConversationId, toRemoveConversation)
  }

  const handleJoyRideCallback = data => {
    const {action, index, status, type} = data

    if ([EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setJoyRide({...joyRide, run: false})
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour

      setJoyRide({
        ...joyRide,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      })
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setJoyRide({...joyRide, run: false})
      const data = {
        inboxTour: true,
      }
      dispatch(setMetaData(data))
    }
  }

  useEffect(() => {
    if (obj) {
      dispatch(getMailBoxConversation(obj))
    }
  }, [obj, dispatch])

  useEffect(() => {
    dispatch(getUsers())
    dispatch(getAllMailInbox())
    dispatch(getTags())
    dispatch(getMetaData())
    dispatch(getStatusList())
    dispatch(getTimeTrackingSetting())
  }, [dispatch])

  useEffect(() => {
    if (get(['inboxId'], location.state) && get(['conversation'], location.state)) {
      onClickItem(get(['inboxId'], location.state), get(['conversation'], location.state))
    }
  }, [location.state])

  useEffect(() => {
    const logic = getMetadataInfo()?.inboxTour || false

    if (!logic) {
      setJoyRide({
        ...joyRide,
        run: false,
        steps: inboxToursSteps,
      })
    }
  }, [])

  // Sidebar localStorage setItem
  useEffect(() => {
    if (sideBar === null) {
      window.localStorage.setItem('sidebarToggle', true)
      setCustomerProfile(true)
    }
  }, [sideBar])

  useEffect(() => {
    io.emit('join_rooms', {
      token: getAppToken(),
    })
  }, [])

  useEffect(() => {
    return () => {
      dispatch({type: 'CLEAR_CONVERSATION_LIST'})
    }
  }, [dispatch])

  useEffect(() => {
    io.on('conversation', conv => {
      let isAppend = false

      const ids = conversations.map(c => c.id)

      if (get(['id'], conv) && ids.indexOf(get(['id'], conv)) === -1) {
        dispatch(inboxCounts())
        const keys = Object.keys(obj)

        if (keys.length) {
          for (let i = 0; i < keys.length; i + 1) {
            if (keys[i] === 'isDraft') {
              if (conv.isDraft === obj.isDraft) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'isRead') {
              if (conv.isRead === obj.isRead) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'isSpam') {
              if (conv.isSpam === obj.isSpam) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'isStarred') {
              if (conv.isStarred === obj.isStarred) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'isTrashed') {
              if (conv.isTrashed === obj.isTrashed) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'overdue') {
              if (conv.overdue === obj.overdue) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'slaBreached') {
              if (conv.slaBreached === obj.slaBreached) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'sent') {
              if (conv.sent === obj.sent) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'isArchived') {
              if (conv.isArchived === obj.isArchived) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'statusId') {
              if (conv.statusId === obj.statusId) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'priority') {
              if (conv.priority === obj.priority) {
                isAppend = true
              } else isAppend = false
            }

            if (keys[i] === 'assigneeId') {
              if (conv.assignees && conv.assignees.map(id => id.id).indexOf(obj.assigneeId) !== -1) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'label') {
              if (conv.labels && conv.labels.map(id => id.slugs).indexOf(obj.label) !== -1) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'unassigned') {
              if (!conv.assignees) {
                isAppend = true
              } else isAppend = false
            }
            if (keys[i] === 'next') {
              isAppend = true
            }
          }
        } else isAppend = true
      }
      if (isAppend) {
        notificationAudio.play()
        dispatch({
          type: 'SOCKET_APPEND_CONVERSATION',
          payload: conv,
        })
      }
    })

    return () => {
      io.off('conversation')
    }
  })

  useEffect(() => {
    io.on('message', data => {
      if (data.isInbound) {
        notificationAudio.play()
        dispatch({type: 'SOCKET_APPEND_MESSAGE', payload: data})

        if (ConversationId === data.conversationId) {
          const payload = {
            conversationIds: [data.conversationId],
            isRead: true,
          }
          dispatch(updateConversationisRead(payload))
        }
      }
    })

    io.on('userMessageRead', data => {
      const newMsgs = get(['messages'], conversation, []).map(value =>
        data.id === value.id ? {...value, isReadCustomer: true} : value
      )
      dispatch({type: 'UPDATE_CONVERSATION_MESSAGES', payload: newMsgs})
    })

    io.on('merged_conversations', con => {
      if (con.length > 0) {
        dispatch({
          type: 'SOCKET_MERGED_CONVERSATIONS',
          payload: con,
        })
      }
    })

    io.on('update_conversation', conversation => {
      if (ConversationId === conversation.id) {
        dispatch({
          type: 'SOCKET_UPDATE_CONVERSATION',
          payload: conversation,
        })
      }
    })

    io.on('sneak', data => {
      if (!typingConversationId.includes(data.conversationId)) {
        setTypingConversationId([...typingConversationId, data.conversationId])
        if (data.text.length === 0) setTypingConversationId([])
        setTimeout(() => {
          setTypingConversationId(typingConversationId.filter(item => item !== data.conversationId))
        }, 2000)
      }
    })

    io.on('online', data => {
      if (conversations) {
        const updatedConversations = conversations.map(conversation =>
          conversation.contactId === data.id
            ? {...conversation, contact: {...conversation.contact, online: true}}
            : conversation
        )
        dispatch({
          type: UPDATE_CONTACT_ONLINE_STATUS,
          payload: updatedConversations,
        })
      }
    })

    io.on('offline', data => {
      if (conversations) {
        const updatedConversations = conversations.map(conversation =>
          conversation.contactId === data.id
            ? {...conversation, contact: {...conversation.contact, online: false}}
            : conversation
        )
        dispatch({
          type: UPDATE_CONTACT_ONLINE_STATUS,
          payload: updatedConversations,
        })
      }
    })

    return () => {
      io.off('message')
      io.off('userMessageRead')
      io.off('merged_conversations')
      io.off('update_conversation')
      io.off('sneak')
      io.off('online')
      io.off('offline')
    }
  })

  useEffect(() => {
    if (successLabels.includes(SEND_MAIL)) {
      refreshInboxData(false)
      setIsOpenMailComposer(false)
    }

    if (successLabels.includes(SET_METADATA)) {
      let metadata = getMetadataInfo()
      metadata = {...metadata, inboxTour: true}
      setMetadataInfo(metadata)
    }
    if (successLabels.includes(CREATE_TAG)) {
      refreshInboxData(false)
    }
  }, [successLabels])

  useEffect(() => {
    if (get(['inboxId'], location.state)) return

    if (successLabels.includes(GET_CONVERSATION_DATA)) {
      if (conversations.length > 0) {
        setSelectedConversation(conversations[0].id)
      } else {
        setCustomerProfile(false)
        window.localStorage.setItem('sidebarToggle', false)
        dispatch({type: 'CLEAR_CONVERSATION'})
      }
    }
  }, [successLabels, location.state])

  useEffect(() => {
    loadSelectedInboxData(selectedInbox)
    dispatch(inboxCounts())
  }, [selectedInbox])

  useEffect(() => {
    // setIsOpenMailComposer(false)
    setCheckedConversations([])
  }, [selectedInbox])

  useEffect(() => {
    if (ConversationId && timeTrackingData.isTimeTracking && !timeTrackingData.isAutoForConversation) {
      dispatch(getTimeTrackingStatus(ConversationId))
    }
  }, [ConversationId])

  // --- handle all refresh cases ---//
  return (
    <div className=".inboxtour__step-zero ">
      <Joyride
        continuous
        callback={handleJoyRideCallback}
        run={joyRide.run}
        showSkipButton
        steps={joyRide.steps}
        stepIndex={joyRide.stepIndex}
        styles={customtStyles}
        locale={{last: 'Done'}}
      />
      <div className="email-wrap">
        <InboxNavigation
          products={products}
          refreshConversation={refreshInboxData}
          inboxName={location.inboxName}
          setSelectedInbox={setSelectedInbox}
          selectedInbox={selectedInbox}
          mailBox={mailboxes}
          totalUnreadCount={allUnreadCount}
          draftCount={draftCount}
          assignMeUnReadCount={myUnreadCount}
          unAssignUnReadCount={unassignedCount}
          setIsOpenMailComposer={setIsOpenMailComposer}
          conversation={conversation}
          setSearchConvseration={setSearchConvseration}
        />
        <EmailBodyContextProvider>
          <EmailGridView
            obj={obj}
            setObj={setObj}
            setLastIndex={setLastIndex}
            setSelectedInbox={setSelectedInbox}
            selectedInbox={selectedInbox}
            currentlySelectedItem={currentlySelectedItem}
            setCurrentlySelectdItem={setCurrentlySelectdItem}
            conversation={conversation}
            setSelectedEmail={setSelectedEmail}
            selectedEmail={selectedEmail}
            checkedConversations={checkedConversations}
            setCheckedConversations={setCheckedConversations}
            refreshInboxData={refreshInboxData}
            customerProfile={customerProfile}
            setCustomerProfile={setCustomerProfile}
            conversations={conversations}
            updateCheckedConversation={updateCheckedConversation}
            onClickItem={onClickItem}
            renderInboxIcon={renderInboxIcon}
            renderInboxName={renderInboxName}
            typingConversationId={typingConversationId}
            setSelectedConversation={setSelectedConversation}
            setSearchConvseration={setSearchConvseration}
            searchConvseration={searchConvseration}
            {...props}
          />
        </EmailBodyContextProvider>
      </div>
      {isOpenMailComposer && (
        <EmailEditorBox
          selectedInbox={selectedInbox}
          setIsOpenMailComposer={setIsOpenMailComposer}
          stopDraftOnClose
          defaultEditorValue={defaultValue}
          isOpenMailComposer={isOpenMailComposer}
        />
      )}
    </div>
  )
}
