import React from 'react'
import create from 'assets/create.svg'
import CreateFirst from 'components/common/CreateFirst'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import InvoicesMenu from 'navigation/sub-routes/Finance/Invoices/InvoicesMenu'
import InvoicesRoutes from 'navigation/sub-routes/Finance/Invoices/InvoicesRoutes'
import {getPermissionTooltipContent} from 'utils/helper'

function Invoices() {
  const {financeAccess} = useUserPermissions()
  const invoicesAccess = financeAccess?.invoices

  if (!invoicesAccess) {
    return (
      <CreateFirst
        header="Invoices"
        buttonText="Create"
        tooltip={!invoicesAccess}
        tooltipContent={getPermissionTooltipContent({featureAccess: invoicesAccess})}
        imageSrc={create}
        subHeader="Invoices helps you to create product invoice and receive payment faster in a minute"
        Feature1="Custom Templates"
        list1="Choose multiple invoice templates to suit your customers."
        Feature2="Add Taxes and Discounts"
        list2="Apply taxes and discounts to invoice based on policies."
        Feature3="Payment Terms"
        list3="Easily add payment terms and conditions"
      />
    )
  }

  return (
    <>
      <InvoicesMenu />
      <InvoicesRoutes />
    </>
  )
}

export default Invoices
