import axios from 'axios'
import {get, startCase} from 'utils/helper'
import {API} from 'actions/types'
import {accessDenied, apiError, apiStart, apiEnd, apiSuccess} from 'actions/api'
import {getAppToken} from 'utils/local-storage'

const apiMiddleware =
  ({dispatch}) =>
  next =>
  action => {
    if (action) next(action)
    if (action && action.type !== API) return

    const {
      url = '',
      method = 'GET',
      data = null,
      params = null,
      label = '',
      isTokenSkipped = false,
      successData = null,
      contentType = null,
      cancelToken = null,
      showToast = false,
      successMessage = null,
    } = action.payload

    const authorizationKey = !isTokenSkipped
      ? {
          Authorization: `Bearer ${getAppToken()}`,
          'Content-Type': contentType || 'application/json',
        }
      : ''

    const setData = data => {
      if (!contentType) {
        return data
      }
      if (contentType === 'multipart/form-data') {
        const keys = Object.keys(data)

        const form = new FormData()

        for (let i = 0; i < keys.length; i += 1) {
          if (keys[i] === 'file') {
            for (let j = 0; j < data[keys[i]].length; j += 1) {
              form.append(`files[${j}]`, data[keys[i]][j])
            }
          } else {
            form.append(keys[i], data[keys[i]])
          }
        }
        return form
      }
    }

    if (label) {
      dispatch(apiStart(label, successData))
    }

    axios
      .request({
        url,
        method,
        headers: authorizationKey,
        onUploadProgress: ProgressEvent => {
          const {loaded, total} = ProgressEvent
          const percent = Math.floor((loaded * 100) / total)

          dispatch({
            type: 'API_PROGRESS',
            payload: {loaded, total, percent},
          })
        },
        // data: setData(data),
        ...(setData(data) && {data: setData(data)}),
        params,
        cancelToken: cancelToken?.token,
      })
      .then(({data}) => {
        dispatch({
          type: label,
          payload: data.payload ? data.payload : data || [],
          status: data.statusCode || null,
          successData: successData || null,
          message: data.message || null,
          page: data.page || null,
        })

        dispatch(
          apiSuccess({
            status: 200,
            showToast,
            successMessage,
            label,
          })
        )
      })
      .catch(error => {
        dispatch(
          apiError({
            status: 400,
            showToast,
            errorMessage: startCase(get(['response', 'data', 'message'], error, 'Error')),
            label,
          })
        )

        if (error.response && error.response.status === 403) {
          if (get(['response', 'data', 'message', 'logout'], error)) {
            dispatch(accessDenied(window.location.pathname))
          }
        }
      })
      .finally(() => {
        if (label) {
          dispatch(apiEnd(label))
        }
      })
  }

export default apiMiddleware
