import {
  GET_QUOTE_LIST,
  GET_QUOTE_PREFIX,
  GET_SINGLE_QUOTE,
  GET_NEW_QUOTE_NUMBER,
  GET_QUOTE_PUBLIC_LINK,
  CREATE_QUOTE,
  UPDATE_QUOTE,
} from 'actions/types'

export default function quoteReducer(
  state = {
    quoteForm: {},
    quoteList: {
      quoteDetails: [],
    },
    quoteDetails: {},
    uploadFile: {selectedFile: null, previewFile: null},
  },
  action
) {
  switch (action.type) {
    case GET_QUOTE_PREFIX:
      return {
        ...state,
        prefixList: action.payload,
      }

    case GET_QUOTE_LIST:
      return {
        ...state,
        quoteList: action.payload,
      }

    case GET_SINGLE_QUOTE:
      return {
        ...state,
        quoteDetails: action.payload,
      }

    case GET_NEW_QUOTE_NUMBER:
      return {
        ...state,
        quoteForm: {
          ...state.quoteForm,
          quoteNo: action.payload,
        },
      }

    case GET_QUOTE_PUBLIC_LINK:
      return {
        ...state,
        quotePublicLink: action.payload,
      }

    case CREATE_QUOTE:
      return {
        ...state,
        quotePublicLink: action.payload,
      }

    case UPDATE_QUOTE:
      return {
        ...state,
        quotePublicLink: action.payload,
      }

    case 'CLEAR_QUOTE_PUBLIC_LINK':
      return {
        ...state,
        quotePublicLink: null,
      }

    case 'CLEAR_QUOTE_FORM':
      return {
        ...state,
        quoteForm: {},
      }

    case 'CLEAR_QUOTE_DETAILS':
      return {
        ...state,
        quoteDetails: {},
      }

    case 'SETUP_SUBMIT':
      return {
        ...state,
        quoteForm: {
          ...state.quoteForm,
          setupData: action.payload,
        },
      }

    case 'BUYER_INFO_SUBMIT': {
      return {
        ...state,
        quoteForm: {
          ...state.quoteForm,
          buyerInfo: action.payload,
        },
      }
    }

    case 'YOUR_INFO_SUBMIT': {
      return {
        ...state,
        quoteForm: {
          ...state.quoteForm,
          yourInfo: action.payload,
        },
      }
    }

    case 'CUSTOMIZATION_SUBMIT':
      return {
        ...state,
        quoteForm: {
          ...state.quoteForm,
          template: action.payload,
        },
      }

    case 'PRODUCT_HANDLE_SUBMIT':
      return {
        ...state,
        quoteForm: {
          ...state.quoteForm,
          products: action.payload,
        },
      }

    case 'SIGNATURE_SUBMIT':
      return {
        ...state,
        quoteForm: {
          ...state.quoteForm,
          signature: action.payload,
        },
      }

    // * file/logo upload and views
    case 'SET_PREVIEW_FILE':
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          previewFile: action.payload,
        },
      }

    case 'SET_SELECTED_FILE': {
      // let prevFile = action.payload.get('logo')
      // const objectUrl = URL.createObjectURL(prevFile);

      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          selectedFile: typeof action.payload === 'string' ? 'uploaded logo' : action.payload.get('logo'),
        },
      }
    }

    case 'CLEAR_QUOTE_UPLOADFILE':
      return {
        ...state,
        uploadFile: {selectedFile: null, previewFile: null},
      }

    default:
      return state
  }
}
