import {DELETE_HOLIDAY_GROUP, GET_HOLIDAY_GROUP, GET_SINGLE_HOLIDAY, GET_SINGLE_HOLIDAY_GROUP} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_HOLIDAY_GROUP:
      return {
        ...state,
        getHolidaysGroupData: action.payload,
      }
    case DELETE_HOLIDAY_GROUP: {
      return {
        ...state,
        getHolidaysGroupData: state.getHolidaysGroupData.filter(holiday => holiday.id !== action.payload),
      }
    }
    case GET_SINGLE_HOLIDAY_GROUP:
      return {
        ...state,
        getSingleHolidayGroupData: action.payload,
      }
    case GET_SINGLE_HOLIDAY:
      return {
        ...state,
        getSingleHolidayData: action.payload,
      }
    default:
      return state
  }
}
