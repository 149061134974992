const importAll = r => {
  const svgs = {}
  r.keys().forEach(key => {
    const path = key.replace('./', '')
    // Dynamically load SVG file as a React component
    const svgComponent = () => import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../assets/svgs/${path}`)
    // Add SVG component loader to the svgs object
    svgs[path] = svgComponent
  })
  return svgs
}

// image
// const importAll = (r) => {
//   let svgs = {};
//   r.keys().map((item, index) => { svgs[item.replace('./', '').replace('.svg', '')] = r(item); });
//   return svgs;
// };

const svgs = importAll(require.context('assets/svgs', true, /\.svg$/))

export default svgs
