import SvgIcon from 'components/common/SvgIcon'
import React, {useEffect} from 'react'
import {Controller} from 'react-hook-form'
import {removeDoubleQuotes} from 'utils/helper'
import {VmoFormField, VmoFormInput, VmoFormTextArea, VmoRadio, VmoTooltip} from 'vmo-library'

function Settings({control, title, description, setValue, errors}) {
  useEffect(() => {
    setValue('settings.title', title)
    setValue('settings.description', description)
  }, [title, description, setValue])

  return (
    <div className="settingsConfig">
      <VmoFormField required>
        <div className="info-header">
          <label>Title</label>
          <VmoTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Specify title of form"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="settings.title"
          render={({value, onChange}) => (
            <VmoFormInput
              type="text"
              maxLength={50}
              placeholder="Form Title..."
              value={value}
              onChange={e => {
                onChange(e.target.value)
              }}
              error={
                errors?.settings?.title && {
                  content: removeDoubleQuotes(errors?.settings?.title?.message),
                }
              }
            />
          )}
          control={control}
        />
      </VmoFormField>
      <VmoFormField>
        <div className="info-header">
          <label>Description</label>
          <VmoTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Specify a brief description about form"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="settings.description"
          render={({onChange, value}) => (
            <VmoFormTextArea
              type="text"
              // maxLength={250}
              value={value}
              placeholder="Description"
              onChange={e => {
                onChange(e.target.value)
              }}
            />
          )}
          control={control}
        />
      </VmoFormField>
      <VmoFormField>
        <div className="info-header">
          <label>Redirect URL</label>
          <VmoTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Add a redirect URl in the form"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="settings.redirectUrl"
          render={({value, onChange}) => (
            <VmoFormInput
              type="text"
              placeholder="https://"
              value={value}
              onChange={(e, {value}) => {
                onChange(value)
              }}
              error={
                errors?.settings?.redirectUrl && {
                  content: removeDoubleQuotes(errors?.settings?.redirectUrl?.message),
                }
              }
            />
          )}
          control={control}
        />
      </VmoFormField>
      <VmoFormField>
        <div className="info-header">
          <label>Confirmation Message</label>
          <VmoTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Confirmation message will be sent after successful form submission"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="settings.confirmMessage"
          render={({onChange, value}) => (
            <VmoFormTextArea
              type="text"
              value={value}
              onChange={e => {
                onChange(e.target.value)
              }}
            />
          )}
          control={control}
        />
      </VmoFormField>
      <VmoFormField>
        <Controller
          name="settings.showQuestionNumber"
          control={control}
          render={({value, onChange}) => (
            <VmoRadio
              toggle
              label="Show Question Number"
              checked={value}
              onChange={(e, {checked}) => onChange(checked)}
            />
          )}
        />
      </VmoFormField>
      <VmoFormField>
        <Controller
          name="settings.indicateMandatory"
          control={control}
          render={({value, onChange}) => (
            <VmoRadio
              toggle
              label="Indicate Mandatory"
              checked={value}
              onChange={(e, {checked}) => onChange(checked)}
            />
          )}
        />
      </VmoFormField>
      <VmoFormField>
        <Controller
          name="settings.showBranding"
          control={control}
          render={({value, onChange}) => (
            <VmoRadio toggle label="Show Branding" checked={value} onChange={(e, {checked}) => onChange(checked)} />
          )}
        />
      </VmoFormField>
      <VmoFormField>
        <Controller
          name="settings.showProgressBar"
          control={control}
          render={({value, onChange}) => (
            <VmoRadio toggle label="Show Progress Bar" checked={value} onChange={(e, {checked}) => onChange(checked)} />
          )}
        />
      </VmoFormField>
    </div>
  )
}

export default Settings
