import {
  DELETE_DEPARTMENT,
  GET_ALL_EMPLOYEES,
  GET_DEPARTMENT,
  GET_EMPLOYEES_SETTING,
  GET_SINGLE_DEPARTMENT,
  GET_SINGLE_EMPLOYEE,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_DEPARTMENT:
      return {
        ...state,
        getDepartmentData: action.payload,
      }
    case DELETE_DEPARTMENT: {
      return {
        ...state,
        getDepartmentData: state.getDepartmentData.filter(department => department.id !== action.payload),
      }
    }
    case GET_SINGLE_DEPARTMENT:
      return {
        ...state,
        getSingleDepartmentData: action.payload,
      }
    case GET_EMPLOYEES_SETTING: {
      return {
        ...state,
        employeeSetting: action.payload,
      }
    }
    case GET_ALL_EMPLOYEES: {
      return {
        ...state,
        allEmployeesList: action.payload.employees,
        employeesPage: action.payload.page,
      }
    }
    case GET_SINGLE_EMPLOYEE:
      return {
        ...state,
        employeesData: action.payload,
        myAppraisalData: action.payload.myAppraisal,
      }
    default:
      return state
  }
}
