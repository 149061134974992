/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import {MdClose} from 'react-icons/md'
import moment from 'moment'
import {VmoButton} from 'vmo-library'
import {get, getFormattedDate, startCase} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'

const getPlanName = plan => {
  if (plan) {
    if (plan.includes('Monthly')) {
      return plan.replace('Monthly', '')
    }
    if (plan.includes('Yearly')) {
      return plan.replace('Yearly', '')
    }
    return plan
  }
  return null
}

const PlanDetails = props => {
  const {
    currentSubscriptionData,
    setStartCancelPlan,
    setStep,
    isChangeCardDetail,
    setIsChangeCardDetail,
    showTrialing,
    setShowTrialing,
    updateBillingInfo,
    setUpdateBillingInfo,
    rolePermissions,
  } = props

  const leftDays = () => {
    const days = moment(currentSubscriptionData?.subscription?.endAt).diff(moment(), 'days')
    if (days >= 1) {
      return days
    }
    return `less than ${days + 1}`
  }

  if (currentSubscriptionData?.subscription?.status === 'active') {
    return (
      <div className="planBox">
        <h1>
          Plan Details
          {get(['subscription', 'isCancelled'], currentSubscriptionData) && (
            <div className="d-flex" style={{flexDirection: 'column'}}>
              <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
                <VmoButton tiny className="basic-fill-btn" onClick={() => rolePermissions && setStartCancelPlan(true)}>
                  Resume Cancelled Plan
                </VmoButton>
              </LockPermissionTooltip>
              {rolePermissions && <small>{`Remaining ${leftDays()} Days`}</small>}
            </div>
          )}
          {!get(['subscription', 'isCancelled'], currentSubscriptionData) && (
            <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
              <VmoButton tiny className="basic-fill-btn" onClick={() => rolePermissions && setStep('step2')}>
                Change Plan
              </VmoButton>
            </LockPermissionTooltip>
          )}
        </h1>
        <div className="planInfo">
          <div className="plan-bars">
            <div className="plan-detail">
              <p>Plan Name:</p>
              <h5>
                {startCase(getPlanName(get(['subscription', 'activePlan'], currentSubscriptionData)))}
                {currentSubscriptionData.subscriptionSchedule && (
                  <span className="omni-lead">
                    (Will be changed to
                    <span className="mx-1" style={{color: '#49a8ff'}}>{`${startCase(
                      getPlanName(currentSubscriptionData.subscriptionSchedule.plan)
                    )}`}</span>
                    plan from next subscription)
                  </span>
                )}
              </h5>
            </div>
            <div className="plan-detail">
              <p>Agents:</p>
              <h5>{get(['subscription', 'quantity'], currentSubscriptionData)}</h5>
            </div>
            <div className="plan-detail">
              <p>Time Period:</p>
              <h5>
                {`${getFormattedDate(get(['subscription', 'startAt'], currentSubscriptionData))} - ${getFormattedDate(
                  get(['subscription', 'endAt'], currentSubscriptionData)
                )}`}
              </h5>
            </div>
            <div className="plan-detail">
              <p>Billing Cycle Type:</p>
              <h5>{startCase(get(['subscription', 'interval'], currentSubscriptionData))}</h5>
            </div>
          </div>
          <div className="plan-extra">
            <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
              <a onClick={() => rolePermissions && setIsChangeCardDetail(!isChangeCardDetail)}>Change Card Details</a>
            </LockPermissionTooltip>
            <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
              <a onClick={() => rolePermissions && setUpdateBillingInfo(!updateBillingInfo)}>Update Billing Info</a>
            </LockPermissionTooltip>
          </div>
        </div>
      </div>
    )
  }

  if (currentSubscriptionData?.subscription?.status === 'trialing' && showTrialing) {
    return (
      <div className="planBox trialBox">
        <h1 className="d-flex">
          Plan Details <MdClose onClick={() => setShowTrialing(false)} />
        </h1>
        <div className="planInfo">
          <div className="plan-bars">
            <div className="plan-detail">
              <p>Plan Name:</p>
              <h5>
                {startCase(getPlanName(get(['subscription', 'activePlan'], currentSubscriptionData)))} - Trial{' '}
                {currentSubscriptionData.subscriptionSchedule && (
                  <span className="kadence-lead">
                    (Will be changed to
                    <span style={{color: '#49a8ff'}}>{` ${startCase(
                      getPlanName(currentSubscriptionData.subscriptionSchedule.plan)
                    )} `}</span>
                    plan from next subscription)
                  </span>
                )}
              </h5>
            </div>
            <div className="plan-detail">
              <p>Agents:</p>
              <h5>{get(['subscription', 'quantity'], currentSubscriptionData)}</h5>
            </div>
            <div className="plan-detail">
              <p>Time Period:</p>
              <h5>
                {`${getFormattedDate(get(['subscription', 'startAt'], currentSubscriptionData))} - ${getFormattedDate(
                  get(['subscription', 'endAt'], currentSubscriptionData)
                )}`}
              </h5>
            </div>
            <div className="plan-detail">
              <p>Billing Cycle Type:</p>
              <h5>{startCase(get(['subscription', 'interval'], currentSubscriptionData))}</h5>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default PlanDetails
