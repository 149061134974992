import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {
  VmoButton,
  VmoCheckbox,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableFooter,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
} from 'vmo-library'
import {useSelector} from 'react-redux'
import {MOVE_PROJECT_FINANCE_BILLING_ITEMS} from 'actions/types'
import {getFormattedDate} from 'utils/helper'
import ProjectBillingMoveTimeModal from './ProjectBillingMoveTimeModal'

const ProjectBillingTimeTableList = ({timeList = [], currency = '', projectId, budgetId, invoiceId}) => {
  const [isMoveTimeModalOpen, setIsMoveTimeModalOpen] = useState(false)
  const [checked, setChecked] = useState([])

  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(MOVE_PROJECT_FINANCE_BILLING_ITEMS)) {
      setChecked([])
      setIsMoveTimeModalOpen(false)
    }
  }, [successLabels])

  const isCheckedAll = () => {
    const allIds = timeList.map(lead => lead.id)

    const checkAll = _.xor(allIds, checked).length === 0 && allIds.length !== 0
    if (checkAll) {
      return true
    }
    return false
  }

  const handleCheckAll = () => {
    const allIds = timeList.map(lead => lead.id)

    if (_.xor(allIds, checked).length === 0) {
      setChecked([])
    } else setChecked(allIds)
  }

  const handleCheck = id => {
    const isChecked = checked.some(lead => lead === id)

    if (isChecked) {
      const removeChecked = checked.filter(lead => lead !== id)
      setChecked(removeChecked)
    } else {
      setChecked([...checked, id])
    }
  }

  const isChecked = id => {
    const check = checked.find(lead => lead === id)
    if (check) {
      return true
    }
    return false
  }

  const getHourMinuteFormatFromMinutes = minutes => {
    const hour = Math.floor(minutes / 60)
    const minute = minutes % 60
    return `${hour} hrs ${minute} mins`
  }

  const getTotalTime = () => {
    const totalMinutes = timeList.reduce((acc, item) => acc + item.loggedHrs * 60 + item.loggedMins, 0)
    return getHourMinuteFormatFromMinutes(totalMinutes)
  }

  const getTotalCost = () => {
    return timeList.reduce((acc, item) => acc + item.cost, 0).toFixed(2)
  }

  const renderTableBody = () => {
    if (timeList.length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="6" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return timeList.map(time => (
      <VmoTableRow key={time.id} className="tableLink">
        <VmoTableCell>
          <VmoCheckbox checked={isChecked(time.id)} onChange={() => handleCheck(time.id)} />
        </VmoTableCell>
        <VmoTableCell>{getFormattedDate(time.date)}</VmoTableCell>
        <VmoTableCell>
          {time.createdByDetails?.firstName} {time.createdByDetails?.lastName}
        </VmoTableCell>
        <VmoTableCell>
          {time.tasklistDetails && (
            <p>
              {time.tasklistDetails?.title} - {time.taskDetails?.title}
            </p>
          )}
          <p>{time.description || 'No description'}</p>
        </VmoTableCell>
        <VmoTableCell>{getHourMinuteFormatFromMinutes(time.loggedHrs * 60 + time.loggedMins)}</VmoTableCell>
        <VmoTableCell>
          {currency} {time.cost.toFixed(2)}
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <>
      <VmoTable basic>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>
              <VmoCheckbox checked={isCheckedAll()} onChange={handleCheckAll} />
            </VmoTableHeaderCell>
            <VmoTableHeaderCell>Date</VmoTableHeaderCell>
            <VmoTableHeaderCell>Who</VmoTableHeaderCell>
            <VmoTableHeaderCell>Description</VmoTableHeaderCell>
            <VmoTableHeaderCell>Time</VmoTableHeaderCell>
            <VmoTableHeaderCell>Cost</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>{renderTableBody()}</VmoTableBody>
        {timeList.length !== 0 && (
          <VmoTableFooter>
            <VmoTableRow>
              <VmoTableCell colSpan="3" />
              <VmoTableCell textAlign="right">Sub-Total:</VmoTableCell>
              <VmoTableCell>{getTotalTime()}</VmoTableCell>
              <VmoTableCell>
                {currency} {getTotalCost()}
              </VmoTableCell>
            </VmoTableRow>
          </VmoTableFooter>
        )}
      </VmoTable>

      {timeList.length !== 0 && (
        <VmoButton basic primary disabled={!checked.length} onClick={() => setIsMoveTimeModalOpen(true)}>
          Move Items
        </VmoButton>
      )}

      {isMoveTimeModalOpen && (
        <ProjectBillingMoveTimeModal
          open={isMoveTimeModalOpen}
          toggle={setIsMoveTimeModalOpen}
          projectId={projectId}
          budgetId={budgetId}
          timeLogIds={checked}
          invoiceId={invoiceId}
        />
      )}
    </>
  )
}

export default ProjectBillingTimeTableList
