import {apiAction} from './api'
import {USER_USERS} from './endpoint'
import {
  ADD_ROLE,
  DELETE_ROLE,
  GET_ALL_ROLES,
  GET_ROLE,
  GET_ROLES_STRUCTURE,
  GET_USER_ROLE_PERMISSIONS,
  UPDATE_ROLE,
} from './types'

export function getAllRoles() {
  return apiAction({
    url: `${USER_USERS}/roles`,
    method: 'GET',
    label: GET_ALL_ROLES,
  })
}

export function getRole(id) {
  return apiAction({
    url: `${USER_USERS}/role/${id}`,
    method: 'GET',
    label: GET_ROLE,
  })
}

export function addRole(data) {
  return apiAction({
    url: `${USER_USERS}/role`,
    method: 'POST',
    label: ADD_ROLE,
    data,
    showToast: true,
    successMessage: 'Added Successfully',
  })
}

export function updateRole(id, data) {
  return apiAction({
    url: `${USER_USERS}/role/${id}`,
    method: 'PUT',
    label: UPDATE_ROLE,
    data,
    showToast: true,
    successMessage: 'Updated Successfully',
  })
}

export function deleteRole(id, data) {
  return apiAction({
    url: `${USER_USERS}/role/${id}`,
    method: 'DELETE',
    label: DELETE_ROLE,
    data,
    showToast: true,
    successMessage: 'Deleted Successfully',
  })
}

export function getRolesStructure() {
  return apiAction({
    url: `${USER_USERS}/roles/structure`,
    method: 'GET',
    label: GET_ROLES_STRUCTURE,
  })
}

export function getUserRolePermissions() {
  return apiAction({
    url: `${USER_USERS}/user/permisions`,
    method: 'GET',
    label: GET_USER_ROLE_PERMISSIONS,
  })
}
