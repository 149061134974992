import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import {
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormRadio,
  VmoFormSelect,
  VmoTooltip,
} from 'vmo-library'

import {GET_USERS} from 'actions/types'
import {getUsers} from 'actions/settings'
import {getMetaData} from 'actions'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const Configuration = props => {
  const {style, control, errors, clearErrors} = props

  const dispatch = useDispatch()

  const [assigneeFilter, setAssigneeFilter] = useState([])

  const {metadata = {}} = useSelector(state => state.createUserAcct)

  useEffect(() => {
    dispatch(getMetaData())
  }, [dispatch])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setAssigneeFilter(list || [])
    },
  })

  return (
    <VmoContainer style={style} className="stepWrap">
      <VmoCard fluid>
        <VmoCardContent>
          <h3 className="mb-0">Configuration</h3>
          <p className="mb-4">Configure your message type campaign as per your requirement</p>
          <VmoForm className="errorLabel">
            <VmoFormField required width={8}>
              <label>Name</label>
              <Controller
                name="name"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormInput
                    placeholder="Name"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors.name && {
                        content: removeDoubleQuotes(errors.name.message),
                      }
                    }
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField required width={8}>
              <div className="info-header">
                <label className="label-class">Choose a team member</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                  content="Assign team members for the campaign"
                />
              </div>
              <Controller
                name="userId"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormSelect
                    placeholder="Select team member"
                    options={assigneeFilter}
                    value={value}
                    onClick={() => errors.userId && clearErrors('userId')}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors.userId && {
                        content: errors.userId.message,
                      }
                    }
                  />
                )}
              />
            </VmoFormField>
          </VmoForm>

          <VmoForm className="mt-4">
            <VmoFormField>
              <div className="info-header">
                <label>When to show message</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                  content="Select when you want to show the campaign"
                />
              </div>
              <Controller
                control={control}
                name="visibility"
                error={
                  errors.visibility && {
                    content: errors.visibility.message,
                  }
                }
                render={({value, onChange}) => (
                  <>
                    <VmoFormField>
                      <VmoFormRadio
                        label="Always"
                        value={1}
                        onChange={(e, {value}) => onChange(value)}
                        checked={value === 1}
                      />
                    </VmoFormField>
                    <VmoFormField>
                      <VmoFormRadio
                        label="Working hours only"
                        value={2}
                        disabled={!metadata.bussinessHoursStatus}
                        onChange={(e, {value}) => onChange(value)}
                        checked={value === 2}
                      />
                    </VmoFormField>
                    <VmoFormField>
                      <VmoFormRadio
                        label="After hours only"
                        disabled={!metadata.bussinessHoursStatus}
                        value={3}
                        onChange={(e, {value}) => onChange(value)}
                        checked={value === 3}
                      />
                    </VmoFormField>
                  </>
                )}
              />
            </VmoFormField>
          </VmoForm>
        </VmoCardContent>
      </VmoCard>
    </VmoContainer>
  )
}

export default Configuration
