/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {createRef, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {MdAutorenew} from 'react-icons/md'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoDivider,
  VmoDropdown,
  VmoEditorSmall,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormRadio,
  VmoFormSelect,
  VmoInput,
  VmoList,
  VmoListItem,
  VmoMessage,
  VmoMessageHeader,
  VmoTab,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTabPane,
  VmoTooltip,
} from 'vmo-library'
import {
  ADD_NEW_INVITEE,
  DELETE_USER,
  GET_USERS,
  GET_MAIL_BOX,
  GET_TEAMS_LIST,
  UPDATE_INBOX,
  GET_ALL_ROLES,
} from 'actions/types'
import {addNewInvitee, getMailBox, resendInvitation, setSMTPSetting} from 'actions'
import {deleteUser, getUsers} from 'actions/settings'
import {getAllTeams} from 'actions/settings_organisation'
import {updateInbox} from 'actions/inbox'
import {copyToClipboard, get, removeDoubleQuotes} from 'utils/helper'
import {fontOptions, fontSizeOptions} from 'constants/variables'
import SettingLayout from 'layouts/settingLayout'
import {KADENCE_ATTACHMENTS} from 'actions/endpoint'
import axios from 'axios'
import {getAppToken} from 'utils/local-storage'
import {inviteeValidationSchmena, validationSchema} from 'validation/Settings/channels/emailInbox.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getAllRoles} from 'actions/roles'

export const InboxEdit = props => {
  const {match, warningModal, setWarningModal} = props
  const dispatch = useDispatch()
  const history = useHistory()

  const internalEmail = useRef(null)
  const textAreaRef = useRef(null)

  const {inboxId} = match.params

  const [userRoles, setUserRoles] = useState([])
  const [allAssigness, setAllAssigness] = useState([])
  const [teamOptions, setTeamOptions] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [copyMsg, setCopyMsg] = useState('Copy')
  const [addInvitee, setAddInvitee] = useState(false)
  const [pendingInviteeView, setPendingInviteeView] = useState(false)
  const [signatureEditorState, setSignatureEditorState] = useState('')
  const [autoRespondEditorState, setAutoRespondEditorState] = useState('')

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {getSingleInbox = {}} = useSelector(state => state.mailInbox)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const emailUpdateLearnMore = settingsLearnMoreUrl?.channels?.email?.updateLearnMore

  const {channelsPermissions} = useUserPermissions()
  const manageEmailsPermissions = channelsPermissions?.manage_emails

  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(validationSchema),
    defaultValues: {
      name: '',
      email: '',
      internalEmail: '',
      settings: {
        fontSize: 16,
        fontFamily: 'Roboto',
        scheduleAs: 'broadcast',
        signatureEnabled: false,
        autoRespond: false,
        smtpEnabled: false,
      },
      smtp: {serverName: '', userName: '', password: '', port: 0},
    },
  })
  const watchedValue = watch()

  const {
    handleSubmit: handleSubmitInvitee,
    errors: errorsInvitee,
    control: controlInvitee,
    reset: resetInvitee,
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(inviteeValidationSchmena),
    defaultValues: {
      firstName: '',
      lastName: '',
      inviteeEmail: '',
      role: 'agent',
    },
  })

  const {data: getUsersList = {}} = useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
        content: (
          <>
            {`${user.firstName} ${user.lastName}`}
            <small className="ml-2 kadence-lead">({user.email})</small>
          </>
        ),
      }))
      setAllAssigness(list || [])
    },
  })
  const {pendingUsers: pendingAssignees = []} = getUsersList || {}

  useApiResponse({
    action: getAllTeams,
    enabled: true,
    label: GET_TEAMS_LIST,
    storePath: 'settingsOrganisation.teamsList',
    onSuccess: teamsList => {
      const list = teamsList.map(team => ({
        key: team.id,
        value: team.id,
        text: team.name,
      }))
      setTeamOptions(list || [])
    },
  })

  const {data: getRoleList = []} = useApiResponse({
    action: getAllRoles,
    enabled: true,
    storePath: 'roles.getRoleList',
  })

  useEffect(() => {
    dispatch(getMailBox(inboxId))
  }, [inboxId, dispatch])

  const resetData = () => {
    reset({
      name: get(['name'], getSingleInbox, ''),
      email: get(['email'], getSingleInbox, ''),
      internalEmail: get(['internalEmail'], getSingleInbox, ''),
      settings: {
        fontSize: get(['settings', 'fontSize'], getSingleInbox, 16),
        fontFamily: get(['settings', 'fontFamily'], getSingleInbox, 'Roboto'),
        scheduleAs: get(['settings', 'scheduleAs'], getSingleInbox, 'round_robin'),
        signatureEnabled: get(['settings', 'signatureEnabled'], getSingleInbox, false),
        autoRespond: get(['settings', 'autoRespond'], getSingleInbox, false),
        smtpEnabled: get(['settings', 'smtpEnabled'], getSingleInbox, false),
      },
      smtp: {
        serverName: get(['smtp', 'serverName'], getSingleInbox, ''),
        username: get(['smtp', 'username'], getSingleInbox, ''),
        password: get(['smtp', 'password'], getSingleInbox, ''),
        port: get(['smtp', 'port'], getSingleInbox, ''),
      },
      assigneeIds: get(['assigneeIds'], getSingleInbox, []),
      teamIds: get(['teamIds'], getSingleInbox, []),
    })
  }

  useEffect(() => {
    if (successLabels.includes(GET_MAIL_BOX)) {
      resetData()
      setAutoRespondEditorState(get(['settings', 'autoResponseEmail'], getSingleInbox, ''))

      setSignatureEditorState(get(['signature', 'body'], getSingleInbox, ''))
    }
  }, [successLabels, getSingleInbox])

  useEffect(() => {
    if (successLabels.includes(UPDATE_INBOX)) {
      history.push('/settings/emails')
      const {inboxId} = match.params
      dispatch(getMailBox(inboxId))
    }
    if (successLabels.includes(DELETE_USER)) {
      dispatch(getMailBox(inboxId))
    }
  }, [successLabels, history, dispatch, inboxId, match])

  useEffect(() => {
    if (successLabels.includes(ADD_NEW_INVITEE)) {
      resetInvitee({
        firstName: '',
        lastName: '',
        inviteeEmail: '',
        role: 'agent',
      })

      dispatch(getMailBox(inboxId))
    }
  }, [successLabels, dispatch, inboxId, resetInvitee])

  useEffect(() => {
    if (successLabels.includes(GET_ALL_ROLES)) {
      const list = getRoleList.map(role => ({
        value: role.id,
        text: role.role,
        id: role.id,
      }))
      list.shift()
      setUserRoles(list)
    }
  }, [successLabels, getRoleList])

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    if (files && files[0]) {
      const formData = new FormData()
      formData.append('files[0]', files[0])
      axios({
        method: 'post',
        url: `${KADENCE_ATTACHMENTS}/inserted-image`,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${getAppToken()}`},
      }).then(function (res) {
        const response = {
          result: [
            {
              url: res.data.payload,
            },
          ],
        }
        uploadHandler(response)
      })
    }
  }

  const renderInvitees = () => {
    if (pendingAssignees.length !== 0) {
      return (
        <VmoFormField width={8}>
          <VmoTable basic>
            <VmoTableHeader>
              <VmoTableRow>
                <VmoTableHeaderCell>#</VmoTableHeaderCell>
                <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                <VmoTableHeaderCell>Email ID</VmoTableHeaderCell>
                <VmoTableHeaderCell>Action</VmoTableHeaderCell>
              </VmoTableRow>
            </VmoTableHeader>
            <VmoTableBody>
              {pendingAssignees.map((invitee, index) => (
                <VmoTableRow key={index}>
                  <VmoTableCell scope="row">{index + 1}</VmoTableCell>
                  <VmoTableCell>{`${invitee.firstName} ${invitee.lastName}`}</VmoTableCell>
                  <VmoTableCell>{invitee.email}</VmoTableCell>
                  <VmoTableCell style={{display: 'flex'}}>
                    <VmoButton
                      icon={<MdAutorenew />}
                      color="light"
                      onClick={() => {
                        dispatch(resendInvitation(invitee.id))
                      }}
                    />
                    <VmoButton
                      icon={<SvgIcon path="common/delete" />}
                      color="light"
                      onClick={() => {
                        dispatch(deleteUser(invitee.id))
                      }}
                    />
                  </VmoTableCell>
                </VmoTableRow>
              ))}
            </VmoTableBody>
          </VmoTable>
        </VmoFormField>
      )
    }
    return (
      <VmoMessage>
        <VmoMessageHeader>No Pending Invites</VmoMessageHeader>
        <p className="kadence-lead">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique atque consequuntur iste quod
        </p>
      </VmoMessage>
    )
  }

  const addToInviteeList = data => {
    const payload = [
      {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.inviteeEmail,
        roleId: data.role,
        inboxIds: [Number(inboxId)],
      },
    ]
    dispatch(addNewInvitee(payload))
  }

  const renderInviteeFields = () => {
    return (
      <>
        <VmoFormField width={8}>
          <VmoForm className="errorLabel">
            <VmoFormGroup widths="equal">
              <Controller
                name="firstName"
                control={controlInvitee}
                render={({onChange, value, onBlur}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="First Name"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errorsInvitee.firstName && {
                        content: removeDoubleQuotes(errorsInvitee.firstName.message),
                      }
                    }
                  />
                )}
              />
              <Controller
                name="lastName"
                control={controlInvitee}
                render={({onChange, value, onBlur}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="Last Name"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errorsInvitee.lastName && {
                        content: removeDoubleQuotes(errorsInvitee.lastName.message),
                      }
                    }
                  />
                )}
              />
            </VmoFormGroup>
            <VmoFormGroup widths="equal">
              <Controller
                name="inviteeEmail"
                control={controlInvitee}
                render={({onChange, value, onBlur}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="Email"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errorsInvitee.inviteeEmail && {
                        content: removeDoubleQuotes(errorsInvitee.inviteeEmail.message),
                      }
                    }
                  />
                )}
              />
              <Controller
                name="role"
                control={controlInvitee}
                render={({onChange, value, onBlur}) => (
                  <VmoFormSelect
                    selection
                    fluid
                    value={value}
                    options={userRoles}
                    placeholder="Role"
                    onBlur={onBlur}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errorsInvitee.role && {
                        content: removeDoubleQuotes(errorsInvitee.role.message),
                      }
                    }
                  />
                )}
              />
            </VmoFormGroup>
            <VmoButton primary onClick={handleSubmitInvitee(addToInviteeList)}>
              Send
            </VmoButton>
          </VmoForm>
        </VmoFormField>
      </>
    )
  }

  /// Signature Editor
  const renderSignatureEditor = () => {
    if (watchedValue.settings.signatureEnabled) {
      return (
        <VmoCardContent className="setEditor">
          <VmoEditorSmall
            setContents={signatureEditorState}
            onChange={content => {
              setSignatureEditorState(content)
            }}
            width="100%"
            setOptions={{
              buttonList: [['font', 'fontSize', 'bold', 'underline', 'italic', 'fontColor', 'hiliteColor', 'indent']],
            }}
          />
        </VmoCardContent>
      )
    }
    return null
  }

  // Auto Response Editor
  const renderAutoRespondEditor = () => {
    return (
      watchedValue.settings.autoRespond && (
        <VmoCardContent className="setEditor">
          <VmoEditorSmall
            setContents={autoRespondEditorState}
            onChange={content => {
              setAutoRespondEditorState(content)
            }}
            onImageUploadBefore={handleImageUploadBefore}
            width="100%"
            setOptions={{
              fontSize: [10, 12, 16, 18, 100],

              height: 200,
              buttonList: [['font', 'fontSize', 'bold', 'underline', 'italic', 'fontColor', 'hiliteColor', 'indent']],
            }}
          />
        </VmoCardContent>
      )
    )
  }

  const renderDns = () => {
    if (getSingleInbox !== null) {
      if (get(['domain', 'dns'], getSingleInbox)) {
        return getSingleInbox.domain.dns.map(dns => {
          const valueRef = createRef(null)
          return (
            <tr>
              <td>{dns.host}</td>
              <td>{dns.type}</td>
              <td>
                <VmoFormInput
                  type="text"
                  value={dns.data}
                  ref={valueRef}
                  action={{
                    color: 'blue',
                    labelPosition: 'right',
                    icon: 'copy',
                    content: copyMsg,
                    onClick: e => copyToClipboard(e, valueRef, setCopyMsg),
                  }}
                />
              </td>
            </tr>
          )
        })
      }
      return null
    }
    return null
  }

  const panes = [
    {
      menuItem: {key: 'general', icon: <SvgIcon path="settings/general" />, content: 'General'},
      render: () => (
        <VmoTabPane>
          <VmoForm className="errorLabel mt-4">
            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label htmlFor="inobxName">Inbox Name</label>
                  <VmoTooltip
                    content="Specify your inbox name"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="name"
                  control={control}
                  render={({onChange, value, onBlur}) => (
                    <VmoFormInput
                      maxLength={25}
                      id="name"
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      onBlur={onBlur}
                      error={
                        errors.name && {
                          content: removeDoubleQuotes(errors.name.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField>
                <div className="info-header">
                  <label htmlFor="inobxEmail">Inbox Email</label>
                  <VmoTooltip
                    content="Email address linked to your Inbox"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="email"
                  control={control}
                  render={({onChange, value, onBlur}) => (
                    <VmoFormInput
                      id="inboxEmail"
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      onBlur={onBlur}
                      error={
                        errors.email && {
                          content: removeDoubleQuotes(errors.email.message),
                        }
                      }
                      readOnly={!!get(['email'], getSingleInbox)}
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>
            <VmoFormGroup>
              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Inbox Address</label>
                  <VmoTooltip
                    content="Address to your email inbox"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="internalEmail"
                  render={({value}) => (
                    <VmoInput
                      value={value}
                      ref={internalEmail}
                      action={{
                        color: 'blue',
                        labelPosition: 'right',
                        icon: 'copy',
                        content: copyMsg,
                        onClick: e => copyToClipboard(e, internalEmail, setCopyMsg),
                      }}
                      error={
                        errors.internalEmail && {
                          content: errors.internalEmail.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>
            <VmoFormGroup>
              <VmoFormField width={8}>
                <div className="info-header">
                  <label htmlFor="inobxAdd">Assignee</label>
                  <VmoTooltip
                    content="Assignees linked to your Inbox. Only added assignees will have access to the Inbox"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="assigneeIds"
                  render={({value, onChange, onBlur}) => (
                    <VmoFormSelect
                      selection
                      fluid
                      multiple
                      value={value}
                      options={allAssigness}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      onBlur={onBlur}
                      error={
                        errors.assigneeIds && {
                          content: errors.assigneeIds.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Teams</label>
                  <VmoTooltip
                    content="Teams linked to your Inbox. Only added teams will have access to your Inbox"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="teamIds"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormSelect
                      selection
                      fluid
                      multiple
                      value={value}
                      options={teamOptions}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.teamIds && {
                          content: errors.teamIds.message,
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>
            <VmoFormField width={8}>
              <VmoList horizontal style={{marginTop: '61px'}}>
                <LockPermissionTooltip isRoleAccessDenied={!manageEmailsPermissions}>
                  <VmoListItem
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      if (manageEmailsPermissions) {
                        setAddInvitee(!addInvitee)
                        if (pendingInviteeView) setPendingInviteeView(false)
                      }
                    }}
                  >
                    Add Invite
                  </VmoListItem>
                </LockPermissionTooltip>
                <LockPermissionTooltip isRoleAccessDenied={!manageEmailsPermissions}>
                  <VmoListItem
                    as="a"
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      if (manageEmailsPermissions) {
                        setPendingInviteeView(!pendingInviteeView)
                        if (addInvitee) setAddInvitee(false)
                      }
                    }}
                  >
                    View Pending Invite
                  </VmoListItem>
                </LockPermissionTooltip>
              </VmoList>
            </VmoFormField>
            <VmoFormGroup>
              {addInvitee && renderInviteeFields()}
              {pendingInviteeView && renderInvitees()}
            </VmoFormGroup>
            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Schedule As</label>
                  <VmoTooltip
                    content="Schedule the pattern of receiving Incoming tickets/queries for your Assignees/Teams"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="settings.scheduleAs"
                  control={control}
                  render={({value, onChange, onBlur}) => (
                    <VmoFormSelect
                      selection
                      value={value}
                      options={[
                        {
                          value: 'broadcast',
                          key: 'broadcast',
                          text: 'Broadcast',
                        },
                        {
                          value: 'round_robin',
                          key: 'round_robin',
                          text: 'Round Robin',
                        },
                      ]}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      onBlur={onBlur}
                      error={
                        get(['settings', 'scheduleAs'], errors) && {
                          content: get(['settings', 'scheduleAs'], errors).message,
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField>
                <div className="info-header">
                  <label>Font Family</label>
                  <VmoTooltip
                    content="Select font which you would like your Inbox messages to have"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="settings.fontFamily"
                  control={control}
                  render={({value, onChange, onBlur}) => (
                    <VmoFormSelect
                      selection
                      value={value}
                      options={fontOptions}
                      onChange={(e, {value}) => onChange(value)}
                      onBlur={onBlur}
                      error={
                        get(['settings', 'fontFamily'], errors) && {
                          content: get(['settings', 'fontFamily'], errors).message,
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>
            <VmoFormGroup>
              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Font Size</label>
                  <VmoTooltip
                    content="Select a font size for all the content inside your email inbox"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="settings.fontSize"
                  control={control}
                  render={({value, onChange, onBlur}) => (
                    <VmoFormSelect
                      selection
                      value={value}
                      options={fontSizeOptions}
                      onChange={(e, {value}) => onChange(value)}
                      onBlur={onBlur}
                      error={
                        get(['settings', 'fontSize'], errors) && {
                          content: get(['settings', 'fontSize'], errors).message,
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>
          </VmoForm>

          <VmoCard fluid className="switchBox">
            <VmoCardContent>
              <div className="d-flex">
                <div>
                  <h5>Signature</h5>
                  <p className="kadence-lead">
                    Enable and setup your personal signature for every email or messages you send
                  </p>
                </div>
                <Controller
                  name="settings.signatureEnabled"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormRadio
                      toggle
                      checked={value}
                      onChange={(e, {checked}) => onChange(checked)}
                      error={
                        get(['settings', 'signatureEnabled'], errors) && {
                          content: get(['settings', 'signatureEnabled'], errors).message,
                        }
                      }
                    />
                  )}
                />
              </div>
            </VmoCardContent>
            {renderSignatureEditor()}
          </VmoCard>

          <VmoCard fluid className="switchBox">
            <VmoCardContent>
              <div className="d-flex">
                <div>
                  <h5>Auto Respond</h5>
                  <p className="kadence-lead">Setup a auto respond any emails without entertaining manually</p>
                </div>
                <Controller
                  name="settings.autoRespond"
                  control={control}
                  render={({value, onChange}) => {
                    return (
                      <VmoFormRadio
                        toggle
                        checked={value}
                        onChange={(e, {checked}) => onChange(checked)}
                        error={
                          get(['settings', 'autoRespond'], errors) && {
                            content: get(['settings', 'autoRespond'], errors).message,
                          }
                        }
                      />
                    )
                  }}
                />
              </div>
            </VmoCardContent>
            {renderAutoRespondEditor()}
          </VmoCard>
        </VmoTabPane>
      ),
    },
    {
      menuItem: {
        key: 'incoming',
        icon: <SvgIcon path="settings/incoming-email" />,
        content: 'Incoming Emails',
      },
      render: () => (
        <VmoTabPane>
          <h4 className="mt-4">Receive emails to your Veemo Inbox by following below instructions</h4>
          <p className="kadence-lead">
            Forward a copy of all <b>{getSingleInbox.email}</b> to this address :
          </p>
          <VmoInput
            type="text"
            value={getSingleInbox.internalEmail}
            ref={textAreaRef}
            action={{
              color: 'blue',
              labelPosition: 'right',
              icon: 'copy',
              content: copyMsg,
              onClick: e => copyToClipboard(e, textAreaRef, setCopyMsg),
            }}
          />
          <p className="lead mt-3">
            Email the details to your IT admin: <a href="">Email to IT Admin</a>
          </p>
          <h5 className="mt-5">Checkout below links toturial for How to forward your email inbox :</h5>
          <ul className="mt-3">
            <li>
              For Gmail : <a href="">Setup your GMAIL Account</a>
            </li>
            <li>
              For Outlook : <a href="">Setup your OUTLOOK Account</a>
            </li>
            <li>
              For Yahoo : <a href="">Setup your YAHOO Account</a>
            </li>
          </ul>
        </VmoTabPane>
      ),
    },
    {
      menuItem: {
        key: 'outgoing',
        icon: <SvgIcon path="settings/outgoing-email" />,
        content: 'Outgoing Emails',
      },
      render: () => (
        <VmoTabPane>
          <VmoForm className="mt-4">
            <VmoFormField>
              <p className="kadence-lead">
                Make sure your emails go straight to the inbox by using these authentication tools.
              </p>
              <Controller
                name="settings.smtpEnabled"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormRadio
                    label="Veemo will send my emails"
                    onChange={() => {
                      onChange(!value)
                    }}
                    checked={value}
                  />
                )}
              />
            </VmoFormField>
            <VmoTable basic selectable>
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell>Host</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Type</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Value</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Action</VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <VmoTableBody>{renderDns()}</VmoTableBody>
            </VmoTable>
            <VmoDivider hidden />
            <VmoButton primary basic>
              Test settings
            </VmoButton>
            <VmoButton>Step-by-Step Instruction</VmoButton>
            <VmoDivider hidden />
            <VmoFormField>
              <Controller
                name="smtpEnabled"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormRadio
                    label="Use my custom outgoing (SMTP) setting"
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    checked={!value}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField inline>
              <label htmlFor="Outgoing">Outgoing Server</label>
              <Controller
                name="serverName"
                control={control}
                render={({value, onChange, onBlur}) => (
                  <VmoFormInput
                    id="Outgoing"
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField inline>
              <label>Username</label>
              <Controller
                name="username"
                control={control}
                render={({value, onChange, onBlur}) => (
                  <VmoFormInput
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    onBlur={onBlur}
                    error={
                      errorsInvitee.username && {
                        content: errorsInvitee.username.message,
                      }
                    }
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField inline>
              <label>Password</label>
              <Controller
                name="password"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormInput
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errorsInvitee.password && {
                        content: errorsInvitee.password.message,
                      }
                    }
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField inline>
              <label>Port</label>
              <Controller
                name="port"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormInput
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errorsInvitee.port && {
                        content: errorsInvitee.port.message,
                      }
                    }
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField inline>
              <label>Security</label>
              <VmoDropdown />
            </VmoFormField>
          </VmoForm>
        </VmoTabPane>
      ),
    },
  ]

  // Update Inbox with autorespond,signature,acknowledge wiht body, flag and name of inbox
  const updateMailInbox = data => {
    const signatureStringfied = signatureEditorState
    const autoResonseStringfied = autoRespondEditorState

    data.signature = {body: signatureStringfied}
    data.settings.autoResponseEmail = autoResonseStringfied

    const {id} = getSingleInbox

    dispatch(updateInbox(data, id))
    setWarningModal(false)
  }

  const changeSMTPSettings = data => {
    const payload = {
      server: data.smtp.serverName,
      userName: data.smtp.userName,
      password: data.smtp.password,
      port: data.smtp.port,
    }

    dispatch(setSMTPSetting(payload, getSingleInbox.id))
  }

  const actionButton = () => {
    if (activeIndex === 0) {
      return {
        cancel: {
          content: 'Cancel',
          disabled: isLoadingData && type.includes(UPDATE_INBOX),
          onClick: () => {
            if (!warningModal) {
              setWarningModal(false)
              history.push('/settings/emails')
              resetData()
            } else if (warningModal) {
              history.push('/settings/emails')
            }
          },
        },
        success: {
          content: 'Update',
          disabled: !isDirty || (isLoadingData && type.includes(UPDATE_INBOX)),
          loading: isLoadingData && type.includes(UPDATE_INBOX),
          ...(manageEmailsPermissions && {
            onClick: handleSubmit(updateMailInbox),
          }),
        },
      }
    }

    if (activeIndex === 2) {
      return {
        success: {
          content: 'Update',
          loading: isLoadingData && type.includes(UPDATE_INBOX),
          disabled: isLoadingData && type.includes(UPDATE_INBOX),
          ...(manageEmailsPermissions && {
            onClick: handleSubmit(changeSMTPSettings),
          }),
        },
      }
    }

    return null
  }

  if (getSingleInbox !== null) {
    return (
      <SettingLayout
        header={[
          `${getSingleInbox.name ? getSingleInbox.name : '-'}`,
          <small className="ml-3">({getSingleInbox.email ? getSingleInbox.email : '-'})</small>,
        ]}
        subHeader="Manage and create all your Inbox Emails here."
        learnMoreUrl={emailUpdateLearnMore}
        table={false}
        headerDivider
        actionButton={actionButton()}
        headerbackbtn={{
          onClick: () => history.push('/settings/emails'),
        }}
        lockRole={!manageEmailsPermissions}
      >
        <VmoTab
          panes={panes}
          activeIndex={activeIndex}
          menu={{color: 'blue', secondary: true, pointing: true}}
          onTabChange={(e, {activeIndex}) => {
            setActiveIndex(activeIndex)
            setCopyMsg('Copy')
          }}
        />
      </SettingLayout>
    )
  }
  return null
}
