import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {GET_CLIENT_PORTAL_SETTINGS, UPDATE_CLIENT_PORTAL_SETTINGS} from 'actions/types'
import ClientPortalMenu from 'navigation/sub-routes/Customers/ClientPortal/ClientPortalMenu'
import ClientPortalRoute from 'navigation/sub-routes/Customers/ClientPortal/ClientPortalRoute'
import {getClientPortalSettings, updateClientPortalSettings} from 'actions/client-portal'
import CreateFirst from 'components/common/CreateFirst'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {getPermissionTooltipContent} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

function ClientPortal() {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {isClientPortalCreated = false} = useSelector(state => state.clientPortal)

  const {isLoading} = useApiLoader({label: GET_CLIENT_PORTAL_SETTINGS})
  const {clientManagementAccess} = useUserPermissions()
  const clientPortalAccess = clientManagementAccess?.clientPortal

  useEffect(() => {
    if (clientPortalAccess) {
      dispatch(getClientPortalSettings())
    }
  }, [clientPortalAccess, dispatch])

  useEffect(() => {
    if (successLabels.includes(UPDATE_CLIENT_PORTAL_SETTINGS)) {
      dispatch(getClientPortalSettings())
    }
  }, [successLabels, dispatch])

  if (isLoading && clientPortalAccess) {
    return <PageLoader />
  }

  if (!isClientPortalCreated || !clientPortalAccess) {
    return (
      <CreateFirst
        src={<SvgIcon path="inbox/user" />}
        header="Client Portal"
        subHeader="A landing pad for your customers’ entire service experience. It’s a digital gateway where clients can access all the information they need during their customer journey"
        addFirst={() => clientPortalAccess && dispatch(updateClientPortalSettings({isClientPortalCreated: true}))}
        tooltip={!clientPortalAccess}
        tooltipContent={getPermissionTooltipContent({featureAccess: clientPortalAccess})}
        buttonText="Create Client Portal"
        Feature1="Create Announcement"
        Feature2="Customize Client Portal"
        Feature3="Mange Tickets"
        list1="Generate an announcement and publish it according to your scheduled time"
        list2="Customize your client portal with all the newly available customizing features"
        list3="Manage all the tickets from your client portal"
        // imageSrc={create}
      />
    )
  }

  return (
    <>
      <ClientPortalMenu />
      <ClientPortalRoute />
    </>
  )
}

export default ClientPortal
