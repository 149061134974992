import Joi from 'joi'

export const chatSurveySchema = Joi.object({
  subject: Joi.string().max(40).trim().required().label('subject').messages({
    'string.max': `Length must be less than or equal to 40 characters`,
  }),
  inboxIds: Joi.array().min(1).required().label('inbox'),
  ratingsType: Joi.string().allow('', null).max(40).messages({
    'string.max': `Length must be less than or equal to 40 characters`,
  }),
  commentEnabled: Joi.boolean().allow(null),
  allowMultipleFeedback: Joi.boolean(),
})
