import _ from 'lodash'
import {get} from 'utils/helper'
import {
  ADD_MESSAGE_TO_CONVERSATION,
  ADD_SMS_CHANNEL,
  ATTACHMENT_GET,
  DELETE_MSG_OF_CONVERSATION,
  DELETE_SMS,
  GET_CONVERSATION,
  GET_CONVERSATION_DATA,
  GET_PHONE_NUMBER,
  GET_SERACHED_CONVERSATION_DATA,
  GET_SHARED_SECRET_KEY,
  GET_SMS_CHANNEL_LIST,
  GET_SMS_SINGLE,
  GET_STATUS_LIST,
  GET_USER_TEMPLATES,
  INBOX_COUNTS,
  MASS_ACTION,
  NAVBAR_SEARCH,
  PUT_CONVERSATION,
  SEND_MAIL,
  SEND_NOTE,
  SNOOZE_CONVERSATION,
  TICKET_ID_LIST,
  UPDATE_CONVERSATION_AND_REFRESH,
  UPDATE_SMS,
  EDIT_MESSAGE,
  CONVERSATION_ATTACHMENT_UPLOAD,
  UPDATE_CONVERSATION_IS_READ,
  UPDATE_CONTACT_ONLINE_STATUS,
  EMAIL_ID_PAIRS,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case 'SOCKET_APPEND_CONVERSATION':
      return {
        ...state,
        conversations: [action.payload, ...get(['conversations'], state, [])],
      }

    case 'SOCKET_UPDATE_CONVERSATION': {
      // To Update In List Conversation
      const conversationsList = [...(state.conversations || [])]
      const indexToUpdate = conversationsList.findIndex(list => list.id === action.payload.id)
      if (indexToUpdate > -1) {
        conversationsList[indexToUpdate].message.messageText = action.payload.message?.messageText
        conversationsList[indexToUpdate].message.mime = action.payload.message?.mime
        conversationsList[indexToUpdate].updatedAt = action.payload.updatedAt
      }
      return {
        ...state,
        conversation: action.payload,
        conversations: conversationsList,
      }
    }

    case GET_CONVERSATION_DATA:
      return {
        ...state,
        conversations:
          get(['conversations'], state) && get(['payload', 'conversations'], action)
            ? _.uniqBy(
                [...get(['conversations'], state, []), ...get(['payload', 'conversations'], action, [])],
                con => con.id
              )
            : get(['payload', 'conversations'], action, []),
        page: get(['payload', 'page'], action),
      }

    case TICKET_ID_LIST:
      return {
        ...state,
        ticketIdsListData: action.payload,
      }

    case INBOX_COUNTS:
      return {
        ...state,
        countData: action.payload,
      }

    case 'CLEAR_CONVERSATION_LIST':
      return {
        ...state,
        conversations: [],
        conversation: null,
        page: 1,
      }

    case 'CLEAR_GET_CONVERSATION_MESSAGE':
      return {
        ...state,
        getConversationMessage: null,
      }

    case GET_SERACHED_CONVERSATION_DATA:
      return {
        ...state,
        searchedConversations: get(['payload'], action, []),
      }

    case GET_CONVERSATION:
      return {
        ...state,
        conversation: get(['payload'], action, []),
        isLoadMoreMsgs: true,
      }

    case 'SOCKET_MERGED_CONVERSATIONS': {
      return {
        ...state,
        conversations: get(['emailInbox', 'conversations'], state, []).filter(
          conversation => (action.payload || []).indexOf(conversation.id) === -1
        ),
      }
    }

    case SEND_MAIL: {
      return {
        ...state,
        emailDelivered: action.payload,
      }
    }

    case SEND_NOTE: {
      return {
        ...state,
        conversation: action.payload,
      }
    }

    case ADD_MESSAGE_TO_CONVERSATION: {
      // To Update In List Conversation
      const conversationsList = [...(state.conversations || [])]
      const updatedIndex = conversationsList.findIndex(list => list.id === action.payload.id)
      if (updatedIndex > 0) {
        // To Move Conversaion First
        const updatedConv = conversationsList[updatedIndex]
        conversationsList.splice(updatedIndex, 1)
        conversationsList.unshift(updatedConv)
      }
      return {
        ...state,
        // conversation: action.payload,
        conversations: conversationsList,
      }
    }

    case SNOOZE_CONVERSATION:
      return {
        ...state,
        conversation: state.conversation ? action.payload : state.conversation,
      }

    case PUT_CONVERSATION: {
      const updatedState = {
        ...state,
        updateConversationType: action.successData,
      }

      if (Array.isArray(action.payload)) {
        /// To Update In List Conversation
        const temp = [...(state.conversations || [])]
        action.payload.forEach(updated => {
          const updatedIndex = temp.findIndex(list => list.id === updated.id)
          temp.splice(updatedIndex, 1, updated)
        })
        updatedState.conversations = temp
      }
      if (action.payload && !Array.isArray(action.payload)) {
        /// To Update current conversation
        updatedState.conversation = action.payload

        /// Check if present in list then update list also
        const index = (state.conversations || []).findIndex(conv => conv.id === action.payload.id)

        if (index !== -1) {
          const list = [...state.conversations]
          list.splice(index, 1, action.payload)
          updatedState.conversations = list
        }
      }

      return updatedState
    }

    case UPDATE_CONVERSATION_IS_READ: {
      const updatedState = [...state.conversations]

      if (Array.isArray(action.payload)) {
        /// To Update In List Conversation To set isRead true

        const updatedIndex = state.conversations.findIndex(list => list.id === action.payload[0].id)
        if (updatedIndex !== -1) updatedState[updatedIndex].isRead = action.payload[0].isRead
      }

      return {
        ...state,
        updatedState,
      }
    }

    case UPDATE_CONVERSATION_AND_REFRESH: {
      return {
        ...state,
        updateConversationToRefreshSuccessData: action.successData,
      }
    }

    case 'CLEAR_UPDATE_CONVERSATION_AND_REFRESH': {
      return {
        ...state,
        updateConversationToRefreshSuccessData: null,
      }
    }

    case 'CLEAR_CONVERSATION': {
      return {
        ...state,
        conversation: null,
      }
    }

    case ATTACHMENT_GET:
      return {
        ...state,
        attachmentBody: action.payload,
      }

    case 'CLEAR_DOWNLOAD_BODY':
      return {
        ...state,
        attachmentBody: null,
      }

    case 'UPDATE_CONVERSATIONS_LIST':
      return {
        ...state,
        conversations: action.payload,
      }

    case GET_USER_TEMPLATES:
      return {
        ...state,
        getUserTemplates: action.payload,
      }

    case MASS_ACTION: {
      const temp = state.conversations ? [...state.conversations] : []
      action.payload.forEach(updated => {
        const updatedIndex = temp.findIndex(list => list.id === updated.id)
        temp.splice(updatedIndex, 1, updated)
      })

      return {
        ...state,
        conversations: temp,
      }
    }

    case NAVBAR_SEARCH:
      return {
        ...state,
        navbarSearch: action.payload,
      }

    case GET_STATUS_LIST:
      return {
        ...state,
        statusList: action.payload,
      }

    case GET_SMS_CHANNEL_LIST:
      return {
        ...state,
        getSmsChannelList: action.payload,
      }

    case GET_SMS_SINGLE:
      return {
        ...state,
        getSmsChannel: action.payload,
      }

    case UPDATE_SMS:
      return {
        ...state,
        updateSmsChannel: action.payload,
      }

    case DELETE_SMS:
      return {
        ...state,
        deleteSmsChannel: action.payload,
      }

    case ADD_SMS_CHANNEL:
      return {
        ...state,
        addSmsChannel: action.payload,
      }

    case GET_PHONE_NUMBER:
      return {
        ...state,
        getPhoneNumberData: action.payload,
      }

    case GET_SHARED_SECRET_KEY:
      return {
        ...state,
        getSharedKeyData: action.payload,
      }

    case 'UPDATE_CONVERSATION_MESSAGES': {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: [...action.payload],
        },
      }
    }

    case 'SOCKET_APPEND_MESSAGE': {
      const newConversations = [...state.conversations]
      const indexToUpdate = state.conversations.findIndex(item => item.id === action.payload.conversationId)
      if (indexToUpdate > -1) {
        newConversations[indexToUpdate].message.messageText = action.payload.messageText
        newConversations[indexToUpdate].message.mime = action.payload.mime
        if (action.payload.isInbound && state.conversation.id !== action.payload.conversationId) {
          newConversations[indexToUpdate].isRead = action.payload.isRead
        }
        if (indexToUpdate !== 0) {
          const updatedConv = newConversations[indexToUpdate]
          newConversations.splice(indexToUpdate, 1)
          newConversations.unshift(updatedConv)
        }
      }
      const convMsg = [...state.conversation.messages] || []
      if (state.conversation.id === action.payload.conversationId) {
        convMsg.unshift(action.payload)
      }
      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: convMsg,
        },
        conversations: newConversations,
      }
    }

    case EDIT_MESSAGE: {
      const conversationsList = [...(state.conversations || [])]
      const updatedIndex = conversationsList.findIndex(list => list.id === action.payload.id)
      if (updatedIndex > -1) {
        conversationsList[updatedIndex].message.messageText = action.payload.message.messageText
      }
      return {
        ...state,
        conversation: action.payload,
        conversations: conversationsList,
      }
    }

    case DELETE_MSG_OF_CONVERSATION: {
      const conversationsList = [...(state.conversations || [])]
      const updatedIndex = conversationsList.findIndex(list => list.id === action.payload.id)
      if (updatedIndex > -1) {
        conversationsList[updatedIndex].message.messageText = action.payload.message.messageText
        conversationsList[updatedIndex].message.mime = action.payload.message.mime
      }
      return {
        ...state,
        conversation: action.payload,
        conversations: conversationsList,
      }
    }

    case UPDATE_CONTACT_ONLINE_STATUS: {
      return {
        ...state,
        conversations: action.payload,
      }
    }

    case CONVERSATION_ATTACHMENT_UPLOAD: {
      const newConversations = [...state.conversations]
      const indexToUpdate = state.conversations.findIndex(item => item.id === action.payload.conversationId)
      if (indexToUpdate > -1) {
        newConversations[indexToUpdate].message.mime = action.payload.mime
        if (action.payload.isInbound && state.conversation.id !== action.payload.conversationId) {
          newConversations[indexToUpdate].isRead = action.payload.isRead
        }
        if (indexToUpdate !== 0) {
          const updatedConv = newConversations[indexToUpdate]
          newConversations.splice(indexToUpdate, 1)
          newConversations.unshift(updatedConv)
        }
      }
      const convMsg = [...state.conversation.messages] || []
      if (state.conversation.id === action.payload.conversationId) {
        convMsg.unshift(action.payload)
      }
      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: convMsg,
        },
        conversations: newConversations,
      }
    }

    case EMAIL_ID_PAIRS:
      return {
        ...state,
        emailIdPairsData: (action.payload || []).map(info => ({
          text: `${info.name}(${info.email})`,
          value: {
            email: info.email,
          },
          key: info.email + info.contactId && info.contactId,
        })),
      }

    default:
      return state
  }
}
