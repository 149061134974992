/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {
  VmoGridColumn,
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoCardGroup,
  VmoListItem,
  VmoList,
  VmoDivider,
} from 'vmo-library'

import {sampleContactImportFile, importContacts} from 'actions/contacts'
import {sampleCompanyImportFile, importCompanies} from 'actions/company'
import {sampleDealImportFile, importDeals} from 'actions/deals'
import SettingLayout from 'layouts/settingLayout'
import {FaUser} from 'react-icons/fa'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import ImportFile from './ImportFile'

const steps = ['step1', 'step2', 'step3']

const Imports = () => {
  // States for different modals of import contacts companies deals
  const [currentStep, setCurrentStep] = useState('step1')
  const [currentState, setCurrentState] = useState('company')

  // sample data of contacts companies deals
  const {sampleContactImport} = useSelector(state => state.contacts)
  const {sampleCompanyImport} = useSelector(state => state.companies)
  const {sampleDealImport} = useSelector(state => state.deals)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const importLearnMore = settingsLearnMoreUrl?.dataManagement?.import?.learnMore

  const {salesAndCrmAccess, contactsAndCompaniesPermissions, dealsPermissions} = useUserPermissions()
  const importAndExportAccess = salesAndCrmAccess?.importAndExport
  const manageImportExportPermissions = contactsAndCompaniesPermissions?.manage_import_export
  const manageImportExportDealsPermissions = dealsPermissions?.manage_import_export_deals

  const data = {
    contact: {
      name: 'Contact',
      sampleImportAction: sampleContactImportFile,
      sampleData: sampleContactImport,
      sampleFileName: 'sample_contact',
      importData: importContacts,
      format: ['csv', 'excel'],
    },
    company: {
      name: 'Company',
      sampleImportAction: sampleCompanyImportFile,
      sampleData: sampleCompanyImport,
      sampleFileName: 'sample_company',
      importData: importCompanies,
      format: ['csv', 'excel'],
    },
    deal: {
      name: 'Deal',
      sampleImportAction: sampleDealImportFile,
      sampleData: sampleDealImport,
      sampleFileName: 'sample_deal',
      importData: importDeals,
      format: ['csv', 'excel'],
    },
  }

  return (
    <SettingLayout
      header="Import Data"
      subHeader="Import data from Google Contacts, CSV files, or Excel files."
      icon={<SvgIcon path="settings/import" />}
      headerDivider
    >
      <div className="setBodyContent">
        {currentStep === 'step1' && (
          <VmoCardGroup>
            <LockPermissionTooltip
              isFeatureAccessDenied={!importAndExportAccess}
              isRoleAccessDenied={!manageImportExportPermissions}
            >
              <VmoCard
                link
                className="hoverCard"
                onClick={() => {
                  if (importAndExportAccess && manageImportExportPermissions) {
                    setCurrentState('company')
                    setCurrentStep('step2')
                  }
                }}
              >
                <VmoCardContent>
                  <SvgIcon path="navigation/companies" />
                  <VmoCardHeader>IMPORT COMPANIES</VmoCardHeader>
                </VmoCardContent>
              </VmoCard>
            </LockPermissionTooltip>
            <LockPermissionTooltip
              isFeatureAccessDenied={!importAndExportAccess}
              isRoleAccessDenied={!manageImportExportPermissions}
            >
              <VmoCard
                link
                className="hoverCard"
                onClick={() => {
                  if (importAndExportAccess && manageImportExportPermissions) {
                    setCurrentState('contact')
                    setCurrentStep('step2')
                  }
                }}
              >
                <VmoCardContent>
                  <FaUser />
                  <VmoCardHeader>IMPORT CONTACTS</VmoCardHeader>
                </VmoCardContent>
              </VmoCard>
            </LockPermissionTooltip>
            <LockPermissionTooltip
              isFeatureAccessDenied={!importAndExportAccess}
              isRoleAccessDenied={!manageImportExportDealsPermissions}
            >
              <VmoCard
                link
                className="hoverCard"
                onClick={() => {
                  if (importAndExportAccess && manageImportExportDealsPermissions) {
                    setCurrentState('deal')
                    setCurrentStep('step2')
                  }
                }}
              >
                <VmoCardContent>
                  <SvgIcon path="settings/deals" />
                  <VmoCardHeader>IMPORT DEALS</VmoCardHeader>
                </VmoCardContent>
              </VmoCard>
            </LockPermissionTooltip>
          </VmoCardGroup>
        )}

        {steps.indexOf(currentStep) > 0 && (
          <ImportFile
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            steps={steps}
            format={data[currentState].format}
            name={data[currentState].name}
            sampleImportAction={data[currentState].sampleImportAction}
            sampleData={data[currentState].sampleData}
            sampleFileName={data[currentState].sampleFileName}
            importData={data[currentState].importData}
          />
        )}
        <VmoGridColumn width={5}>
          <h5 className="mt-5 mb-2">Past Imports</h5>
          <p className="kadence-lead">
            You have no imports on record. Any imports initiated henceforth will show up here!
          </p>
          <h5 className="mt-5 mb-2">Tips For a Smooth Import</h5>
          <VmoList bulleted relaxed="very">
            <VmoListItem className="kadence-lead">
              Import Companies first, then People and then Deals. Importing in this order will ensure that all records
              are related correctly. Leads can be imported at any time
            </VmoListItem>
            <VmoListItem className="kadence-lead">
              We recommend using our formatted Excel and CSV templates to ensure your data is formatted properly. You'll
              be given the option to download the templates during the import process. Learn more about the import
              process <a href={importLearnMore}>here</a>
            </VmoListItem>
            <VmoListItem className="kadence-lead">
              We support file sizes up to 3MB. If your file is larger than 3MB, we suggest you break it up into multiple
              files. It's a good idea to do a test with a smaller import file first to make sure your file works.
            </VmoListItem>
            <VmoListItem className="kadence-lead">
              The first row of your import file must be the column headers you wish to import to.
            </VmoListItem>
          </VmoList>
        </VmoGridColumn>
      </div>
    </SettingLayout>
  )
}
export default Imports
