import {GET_ALL_PROOFS, GET_COMMENTS, GET_SINGLE_PROOF} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_PROOFS:
      return {
        ...state,
        getProofsListData: action.payload.proofs,
        getProofslistPage: action.page,
      }

    case GET_SINGLE_PROOF:
      return {
        ...state,
        getSingleProofData: action.payload,
        getCommentsList: action.payload.comments,
        getVersionsList: action.payload.versions,
        getApproverDetails: action.payload.approverDetails,
        getReviewerDetails: action.payload.reviewerDetails,
        getRemarksDetails: action.payload.remarks,
      }

    case GET_COMMENTS:
      return {
        ...state,
        getCommentsData: action.payload,
      }

    default:
      return {...state}
  }
}
