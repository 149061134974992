import React, {useState} from 'react'
import {withRouter} from 'react-router'
import {
  VmoContainer,
  VmoGrid,
  VmoModal,
  VmoModalContent,
  VmoModalActions,
  VmoButton,
  VmoGridRow,
  VmoImage,
  VmoCard,
  VmoCardContent,
  VmoGridColumn,
  VmoCardHeader,
  VmoIcon,
  VmoList,
  VmoListItem,
  VmoListIcon,
  VmoListContent,
} from 'vmo-library'
import topImage from 'assets/getting-started.svg'
import {FiAtSign, FiBookmark, FiChevronRight, FiInbox, FiUploadCloud} from 'react-icons/fi'
import {TiTicket} from 'react-icons/ti'
import inbox01 from 'assets/inbox01.png'
import SvgIcon from 'components/common/SvgIcon'

const slideData = [
  {
    key: 1,
    image: inbox01,
    head: 'Inbox Page',
    subHead: 'Central hub where conversations from all connected channels will appear',
    features: [
      'Collaborate among team members with internal notes',
      'Sort conversations based on requirement',
      'Perform bulk action on emails',
    ],
  },
  {
    key: 2,
    image: inbox01,
    head: 'Sales Page',
    subHead: 'Streamline sales process and increase conversions with less efforts',
    features: [
      'Complete visualization of sales pipeline',
      'Track and manage leads throughout sales process',
      'Create professional sales quotes',
    ],
  },
  {
    key: 3,
    image: inbox01,
    head: 'Support Page',
    subHead: 'Assist Customers with 24/7 service through multiple channels',
    features: [
      'Create knowledge base and make customer self served',
      'Keep track of customer issues using tickets',
      'User-friendly interface for easy navigation',
    ],
  },
  {
    key: 4,
    image: inbox01,
    head: 'Project management',
    subHead: 'Collaborative workspace for timely project delivery and improve project performance',
    features: [
      'Visualize project timelines for smooth project execution',
      'Create Multiple project templates easily',
      'Track employees work hour using timesheet',
    ],
  },
  {
    key: 4,
    image: inbox01,
    head: 'Finance Page',
    subHead: 'Productive system to assists your business transactions, invoices and expenses',
    features: [
      'Budgeting tools to track income and expenses.',
      'Bill management to stay on top of recurring payments.',
      'Create multiple budget plans for one company',
    ],
  },
  {
    key: 5,
    image: inbox01,
    head: 'Human Resource Page',
    subHead: 'Streamline all human resource related activities within an organization',
    features: [
      'Track leave requests & time-off accurately',
      'Employee portal to access personal information and benefits',
      'Multiple appraisal template for quicker response',
    ],
  },
  {
    key: 6,
    image: inbox01,
    head: 'Reporting And Analytics',
    subHead: 'Track and measure performance of business across multiple apps including marketing, sales and support',
    features: [
      'Track Sales activities and performance',
      'Analyze customer feedback & satisfaction report',
      'Analyze campaign performance with key metrics',
    ],
  },
]

function GettingStarted() {
  const [productTour, setProductTour] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  const slide1 = () => (
    <>
      <div className="FeatureModHeader">
        <h5>Welcome to Kadence</h5>
        <p className="kadence-lead">Omni Channel Customer Service & Help Desk Software</p>
      </div>

      <VmoGrid relaxed columns="equal">
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <FiInbox />
            </VmoIcon>
            <h4>Team Inbox</h4>
            <span>Connect multiple channels & bring conversations at one place</span>
          </div>
        </VmoGridColumn>
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <TiTicket />
            </VmoIcon>
            <h4>Sales Page</h4>
            <span>Drive conversions & boost revenue with sales page.</span>
          </div>
        </VmoGridColumn>
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <FiUploadCloud />
            </VmoIcon>
            <h4>Support page</h4>
            <span>Improve customer service with Support page.</span>
          </div>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid relaxed columns="equal">
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <FiBookmark />
            </VmoIcon>
            <h4>Project Management</h4>
            <span>Collaborate on Task & manage Timesheet efficiently</span>
          </div>
        </VmoGridColumn>
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <SvgIcon path="settings/users" />
            </VmoIcon>
            <h4>Finance page</h4>
            <span>Organize, track & manage expenses in one place.</span>
          </div>
        </VmoGridColumn>
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <SvgIcon path="common/mail" />
            </VmoIcon>
            <h4>Human Resource page</h4>
            <span>Streamline HR processes & communication with employees</span>
          </div>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid relaxed columns="equal">
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <FiAtSign />
            </VmoIcon>
            <h4>Reporting and Analytics</h4>
            <span>Analyze various metrics & KPIs related to business</span>
          </div>
        </VmoGridColumn>
      </VmoGrid>
    </>
  )

  const slide = ({image, head, subHead, features = []}) => (
    <>
      <div className="featureTour">
        <div className="info">
          <div className="featureInfoName mb-2">
            <FiInbox />
            <div>
              <h5>{head}</h5>
              <p className="kadence-lead">{subHead}</p>
            </div>
          </div>
        </div>
        <VmoImage src={image} alt="img" className="fea-img" />

        <div className="info mb-3">
          <VmoList>
            {features.map(feature => (
              <VmoListItem key={feature}>
                <VmoListIcon name="check circle outline" />
                <VmoListContent>{feature}</VmoListContent>
              </VmoListItem>
            ))}
          </VmoList>
        </div>
      </div>
    </>
  )

  const actionCancelModal = action => {
    if (action === 'prev') {
      setCurrentSlide(currentSlide => currentSlide - 1)
    }
    if (action === 'next') {
      setCurrentSlide(currentSlide => currentSlide + 1)
    }
    if (action === 'completed') {
      setProductTour(false)
    }
  }

  const carousel = [slide1(), ...slideData.map(data => slide(data))]

  const renderProductTourModal = () => {
    return (
      <VmoModal className="productTourModal" open={productTour}>
        <VmoModalContent>{carousel[currentSlide]}</VmoModalContent>
        <VmoModalActions className="d-flex pt-0">
          {currentSlide > 0 && (
            <VmoButton basic className="ml-0 mr-3" onClick={() => actionCancelModal('prev')}>
              Prev
            </VmoButton>
          )}
          {carousel.length - 1 > currentSlide &&
            (currentSlide <= 0 ? (
              <VmoButton className="start-tour-btn" primary onClick={() => actionCancelModal('next')}>
                Start Kadence Product Tour
              </VmoButton>
            ) : (
              <VmoButton primary className="mr-0" onClick={() => actionCancelModal('next')}>
                Next
              </VmoButton>
            ))}
          {carousel.length - 1 === currentSlide && (
            <VmoButton className="mr-0" primary onClick={() => actionCancelModal('completed')}>
              Done
            </VmoButton>
          )}
        </VmoModalActions>
      </VmoModal>
    )
  }

  return (
    <div style={{width: '100%', background: '#F9FBFD'}}>
      <VmoGrid style={{width: '100%', background: '#FFF'}} className="m-0">
        <VmoGridRow className="p-0">
          <VmoContainer style={{width: '70%', padding: '24px 0'}}>
            <div className="d-flex">
              <div className="d-flex" style={{justifyContent: 'flex-start'}}>
                <VmoImage className="mr-4" src={topImage} alt="getting-started.svg" />
                <div>
                  <p className="kadence-lead mb-1">Tues, Jan 10,2023</p>
                  <h2 className="m-0">Good Afternoon, Username</h2>
                  <span>Just grab quick lunch break to refuel, then back to work at full speed in Kadence!</span>
                </div>
              </div>
              <div className="d-flex">
                <VmoButton
                  className="take-a-tour-btn mr-5"
                  onClick={() => {
                    setProductTour(true)
                    setCurrentSlide(0)
                  }}
                >
                  Take a tour!
                </VmoButton>
                <div style={{textAlign: 'end'}}>
                  <p className="kadence-lead mb-0">Trail ends in 7 Days</p>
                  <p>
                    Check out our <a href="https://www.xyz.com">pricing</a>
                  </p>
                </div>
              </div>
            </div>
          </VmoContainer>
        </VmoGridRow>
      </VmoGrid>

      <VmoContainer style={{width: '70%', marginTop: 24}}>
        <VmoGrid className="m-0">
          <VmoGridRow stretched className="mb-3 pt-0">
            <VmoGridColumn width={6} className="pl-0">
              <VmoCard fluid>
                <VmoCardContent style={{padding: 24}}>
                  <VmoCardHeader>
                    <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                      <span>
                        <SvgIcon path="common/file" />
                      </span>
                      <h4 className="m-0">Resources</h4>
                    </div>
                  </VmoCardHeader>
                  <div className="gs-community">
                    <h5>Community</h5>
                    <span>
                      <SvgIcon path="common/file" /> See the Roadmap
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Submit your product idea
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Join our product research community
                    </span>
                  </div>
                  <div className="gs-quick-links">
                    <h5>Quick Links</h5>
                    <span>
                      <SvgIcon path="common/file" /> Add Channels
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Auto Replies
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Join our product research community
                    </span>
                  </div>
                  <div className="gs-help">
                    <h5>Help</h5>
                    <span>
                      <SvgIcon path="common/file" /> See the Roadmap
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Submit your product idea
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Join our product research community
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Join our product research community
                    </span>
                  </div>
                  <div className="gs-follow">
                    <h5>Follow Veemo</h5>
                    <span>
                      <SvgIcon path="common/file" /> See the Roadmap
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Submit your product idea
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Join our product research community
                    </span>
                  </div>
                </VmoCardContent>
              </VmoCard>
            </VmoGridColumn>

            <VmoGridColumn width={10} className="pr-0">
              <VmoGrid columns="equal">
                <VmoGridRow>
                  <VmoGridColumn>
                    <VmoCard fluid>
                      <VmoCardContent style={{padding: 24}}>
                        <VmoCardHeader style={{marginBottom: 12}}>
                          <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                            <span>
                              <SvgIcon path="common/file" />
                            </span>
                            <h4 className="m-0">Inbox</h4>
                          </div>
                        </VmoCardHeader>

                        <div className="setup-content">
                          <span>Manage emails from multiple channels into a unified inbox</span>
                        </div>
                      </VmoCardContent>
                    </VmoCard>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <VmoCard fluid>
                      <VmoCardContent style={{padding: 24}}>
                        <VmoCardHeader style={{marginBottom: 12}}>
                          <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                            <span>
                              <SvgIcon path="common/file" />
                            </span>
                            <h4 className="m-0">Sales</h4>
                          </div>
                        </VmoCardHeader>

                        <div className="setup-content">
                          <div className="setup-links">
                            <span>Deals</span>
                            <FiChevronRight />
                          </div>
                          <div className="setup-links">
                            <span>Meetings</span>
                            <FiChevronRight />
                          </div>
                          <div className="setup-links">
                            <span>Quotes</span>
                            <FiChevronRight />
                          </div>
                        </div>
                      </VmoCardContent>
                    </VmoCard>
                  </VmoGridColumn>
                </VmoGridRow>
                <VmoGridRow>
                  <VmoGridColumn>
                    <VmoCard fluid>
                      <VmoCardContent style={{padding: 24}}>
                        <VmoCardHeader style={{marginBottom: 12}}>
                          <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                            <span>
                              <SvgIcon path="common/file" />
                            </span>
                            <h4 className="m-0">Support</h4>
                          </div>
                        </VmoCardHeader>

                        <div className="setup-content">
                          <div className="setup-links">
                            <span>Knowledge base</span>
                            <FiChevronRight />
                          </div>
                          <div className="setup-links">
                            <span>Feedback surveys</span>
                            <FiChevronRight />
                          </div>
                          <div className="setup-links">
                            <span>Contact center</span>
                            <FiChevronRight />
                          </div>
                        </div>
                      </VmoCardContent>
                    </VmoCard>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <VmoCard fluid>
                      <VmoCardContent style={{padding: 24}}>
                        <VmoCardHeader style={{marginBottom: 12}}>
                          <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                            <span>
                              <SvgIcon path="common/file" />
                            </span>
                            <h4 className="m-0">Projects</h4>
                          </div>
                        </VmoCardHeader>

                        <div className="setup-content">
                          <div className="setup-links">
                            <span>Tasks</span>
                            <FiChevronRight />
                          </div>
                          <div className="setup-links">
                            <span>Retainers</span>
                            <FiChevronRight />
                          </div>
                          <div className="setup-links">
                            <span>Time tracking</span>
                            <FiChevronRight />
                          </div>
                        </div>
                      </VmoCardContent>
                    </VmoCard>
                  </VmoGridColumn>
                </VmoGridRow>
                <VmoGridRow>
                  <VmoGridColumn>
                    <VmoCard fluid>
                      <VmoCardContent style={{padding: 24}}>
                        <VmoCardHeader style={{marginBottom: 12}}>
                          <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                            <span>
                              <SvgIcon path="common/file" />
                            </span>
                            <h4 className="m-0">Finance</h4>
                          </div>
                        </VmoCardHeader>

                        <div className="setup-content">
                          <div className="setup-links">
                            <span>Sales order</span>
                            <FiChevronRight />
                          </div>
                          <div className="setup-links">
                            <span>Budget Planner</span>
                            <FiChevronRight />
                          </div>
                          <div className="setup-links">
                            <span>Purchases</span>
                            <FiChevronRight />
                          </div>
                        </div>
                      </VmoCardContent>
                    </VmoCard>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <VmoCard fluid>
                      <VmoCardContent style={{padding: 24}}>
                        <VmoCardHeader style={{marginBottom: 12}}>
                          <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                            <span>
                              <SvgIcon path="common/file" />
                            </span>
                            <h4 className="m-0">Human Resources</h4>
                          </div>
                        </VmoCardHeader>

                        <div className="setup-content">
                          <div className="setup-links">
                            <span>Employees</span>
                            <FiChevronRight />
                          </div>
                          <div className="setup-links">
                            <span>Expenses</span>
                            <FiChevronRight />
                          </div>
                          <div className="setup-links">
                            <span>Contracts</span>
                            <FiChevronRight />
                          </div>
                        </div>
                      </VmoCardContent>
                    </VmoCard>
                  </VmoGridColumn>
                </VmoGridRow>
              </VmoGrid>
            </VmoGridColumn>
          </VmoGridRow>
        </VmoGrid>
        {productTour && renderProductTourModal()}
      </VmoContainer>
    </div>
  )
}

export default withRouter(GettingStarted)
