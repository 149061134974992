import {apiAction} from './api'
import {HOME_STATS, TOP_TASKS, STATUS_WISE_DEALS} from './types'
import {KADENCE_ANALYTICS} from './endpoint'

export function homeStats(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/home/homestats`,
    method: 'POST',
    label: HOME_STATS,
    data,
  })
}

export function statusWiseDeals(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/home/statuswisedeals`,
    method: 'POST',
    label: STATUS_WISE_DEALS,
    data,
  })
}

export function topTasks(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/home/toptasks`,
    method: 'POST',
    label: TOP_TASKS,
    data,
  })
}
