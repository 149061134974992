import React, {useState} from 'react'
import {get} from 'utils/helper'
import {EmailBody} from './EmailBody'
import {EmailListBar} from './EmailListBar'
import {MassAction} from './MassAction'
import {CustomerProfile} from './CustomerProfile'

export const EmailGridView = props => {
  const {
    checkedConversations,
    customerProfile,
    selectedEmail,
    setCustomerProfile,
    conversation,
    setSelectedConversation,
  } = props

  const [bulkSelect, setBulkSelect] = useState(false)

  return (
    <>
      <EmailListBar {...props} bulkSelect={bulkSelect} setBulkSelect={setBulkSelect} />
      {checkedConversations.length > 0 ? (
        <MassAction {...props} setBulkSelect={setBulkSelect} />
      ) : (
        <div className="email-02">
          <EmailBody {...props} />
        </div>
      )}
      {customerProfile && (
        <CustomerProfile
          contactId={get(['contact', 'id'], selectedEmail)}
          setCustomerProfile={setCustomerProfile}
          conversationType={conversation?.type}
          setSelectedConversation={setSelectedConversation}
          type="inbox"
        />
      )}
    </>
  )
}
