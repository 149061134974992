import {CHANNEL_DISTRIBUTION, INBOX_ACTIVITIES, INFLOW_BY_CHANNEL_ACROSS_TIME} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action
  switch (type) {
    case INFLOW_BY_CHANNEL_ACROSS_TIME:
      return {...state, inflowByChannelAcrossTime: payload}

    case CHANNEL_DISTRIBUTION:
      return {
        ...state,
        channelDistrbution: payload,
      }

    case INBOX_ACTIVITIES:
      return {...state, inboxActivities: payload}

    default:
      return state
  }
}
