/* eslint-disable no-case-declarations */
import _ from 'lodash'
import {
  GET_ALL_COMPANIES,
  CREATE_COMPANY,
  GET_SINGLE_COMPANY,
  EDIT_COMPANY,
  ADD_COMPANY_INTERACTION,
  ADD_NOTE_COMPANY,
  GET_ALL_COMPANY_NOTES,
  GET_ALL_ACTIVITIES_COMPANY,
  DELETE_COMPANY,
  MULTIPLE_UPDATE_COMPANY,
  ATTACHMENT_POST_COMPANY,
  EXPORT_ALL_COMPANIES,
  SAMPLE_COMPANIES_IMPORT,
  IMPORT_COMPANIES,
  ADD_PHONE_COMPANY,
  ADD_EMAIL_COMPANY,
  ADD_COMPANY_WEBSITE,
  UPDATE_PHONE_COMPANY,
  UPDATE_EMAIL_COMPANY,
  UPDATE_COMPANY_WEBSITE,
  DELETE_COMPANY_EMAIL,
  DELETE_COMPANY_PHONE,
  DELETE_COMPANY_WEBSITE,
  COLUMNS_SELECTION_COMPANY,
  GET_COLUMNS_SELECTION_COMPANY,
  MULTIPLE_MERGE_COMPANY,
  CONTACT_TYPE_LIST,
  ADD_SOCIAL,
  UPDATE_SOCIAL,
  REMOVE_SOCIAL,
  GET_ALL_COMPANIES_IDS,
} from 'actions/types'
import {get} from 'utils/helper'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_COMPANIES: {
      const {companies = [], ...rest} = action.payload
      return {
        ...state,
        getAllCompanyList: companies ?? [],
        companyPage: {...rest},
      }
    }

    case GET_ALL_COMPANIES_IDS:
      return {...state, companiesIdsList: action.payload}

    case CREATE_COMPANY:
      return {
        ...state,
        getAllCompanyList: action.payload ? [action.payload, ...state.getAllCompanyList] : state.getAllCompanyList,
      }

    case GET_SINGLE_COMPANY:
      return {
        ...state,
        singleCompany: action.payload,
      }

    case CONTACT_TYPE_LIST:
      return {
        ...state,
        getTypeListData: action.payload,
      }
    case EDIT_COMPANY: {
      const temp = [...get(['getAllCompanyList'], state, [])]
      const updatedIndex = temp.findIndex(list => list.id === action.payload.id)
      temp.splice(updatedIndex, 1, action.payload)
      return {
        ...state,
        singleCompany: action.payload ? action.payload : state.singleCompany,
        getAllCompanyList: temp,
      }
    }

    case ADD_COMPANY_INTERACTION:
      return {
        ...state,
        singleCompany: action.payload,
      }

    case ADD_NOTE_COMPANY:
      return {
        ...state,
        singleCompany: action.payload,
      }

    case GET_ALL_COMPANY_NOTES:
      return {...state, getAllCompanyNoteData: action.payload}

    case GET_ALL_ACTIVITIES_COMPANY:
      return {
        ...state,
        getAllCompanyActivitiesData: action.payload,
      }

    case DELETE_COMPANY:
      const temp = state.getAllCompanyList ? [...state.getAllCompanyList] : []
      const ids = action.payload
      return {
        ...state,
        getAllCompanyList: temp.filter(contact => ids.indexOf(contact.id) === -1),
        companyPage: {
          ...state.companyPage,
          total: _.subtract(_.get(state, 'companyPage.total'), _.size(ids)),
        },
      }

    case MULTIPLE_UPDATE_COMPANY: {
      const temp = state.getAllCompanyList ? [...state.getAllCompanyList] : []
      action.payload.forEach(updated => {
        const updatedIndex = temp.findIndex(list => list.id === updated.id)
        temp.splice(updatedIndex, 1, updated)
      })
      return {
        ...state,
        getAllCompanyList: temp,
      }
    }

    case ATTACHMENT_POST_COMPANY:
      return {
        ...state,
      }

    case EXPORT_ALL_COMPANIES:
      return {
        ...state,
        exportAllCompanies: action.payload,
      }
    case SAMPLE_COMPANIES_IMPORT:
      return {...state, sampleCompanyImport: action.payload}
    case IMPORT_COMPANIES:
      return {
        ...state,
        importCompaniesData: action.payload,
      }

    case ADD_PHONE_COMPANY:
      const addphone = [...get(['getAllCompanyList'], state, [])]
      const updatedAddPhoneIndex = addphone.findIndex(list => list.id === action.payload.id)
      addphone.splice(updatedAddPhoneIndex, 1, action.payload)
      return {
        ...state,
        getAllCompanyList: addphone,
        singleCompany: action.payload ? action.payload : state.singleCompany,
      }

    case ADD_EMAIL_COMPANY:
      const addEmail = [...get(['getAllCompanyList'], state, [])]
      const updatedAddEmailIndex = addEmail.findIndex(list => list.id === action.payload.id)
      addEmail.splice(updatedAddEmailIndex, 1, action.payload)
      return {
        ...state,
        getAllCompanyList: addEmail,
        singleCompany: action.payload ? action.payload : state.singleCompany,
      }

    case ADD_COMPANY_WEBSITE:
      const addWebsite = [...get(['getAllCompanyList'], state, [])]
      const updatedAddWebsiteIndex = addWebsite.findIndex(list => list.id === action.payload.id)
      addWebsite.splice(updatedAddWebsiteIndex, 1, action.payload)
      return {
        ...state,
        getAllCompanyList: addWebsite,
        singleCompany: action.payload ? action.payload : state.singleCompany,
      }
    case UPDATE_PHONE_COMPANY:
      const updatePhone = [...get(['getAllCompanyList'], state, [])]
      const updatedUpdatePhoneIndex = updatePhone.findIndex(list => list.id === action.payload.id)
      updatePhone.splice(updatedUpdatePhoneIndex, 1, action.payload)
      return {
        ...state,
        getAllCompanyList: updatePhone,
        singleCompany: action.payload ? action.payload : state.singleCompany,
      }

    case UPDATE_EMAIL_COMPANY:
      const updateEmail = [...get(['getAllCompanyList'], state, [])]
      const updatedUpdateEmailIndex = updateEmail.findIndex(list => list.id === action.payload.id)
      updateEmail.splice(updatedUpdateEmailIndex, 1, action.payload)
      return {
        ...state,
        getAllCompanyList: updateEmail,
        singleCompany: action.payload ? action.payload : state.singleCompany,
      }

    case UPDATE_COMPANY_WEBSITE:
      const updateWebsite = [...get(['getAllCompanyList'], state, [])]
      const updatedUpdateWebsiteIndex = updateWebsite.findIndex(list => list.id === action.payload.id)
      updateWebsite.splice(updatedUpdateWebsiteIndex, 1, action.payload)
      return {
        ...state,
        getAllCompanyList: updateWebsite,
        singleCompany: action.payload ? action.payload : state.singleCompany,
      }

    case DELETE_COMPANY_EMAIL:
      const deleteEmail = [...get(['getAllCompanyList'], state, [])]
      const updatedDeleteEmailIndex = deleteEmail.findIndex(list => list.id === action.payload.id)
      deleteEmail.splice(updatedDeleteEmailIndex, 1, action.payload)
      return {
        ...state,
        getAllCompanyList: deleteEmail,
        singleCompany: action.payload ? action.payload : state.singleCompany,
      }

    case DELETE_COMPANY_PHONE:
      const deletePhone = [...get(['getAllCompanyList'], state, [])]
      const updatedDeletePhoneIndex = deletePhone.findIndex(list => list.id === action.payload.id)
      deletePhone.splice(updatedDeletePhoneIndex, 1, action.payload)
      return {
        ...state,
        getAllCompanyList: deletePhone,
        singleCompany: action.payload ? action.payload : state.singleCompany,
      }

    case DELETE_COMPANY_WEBSITE:
      const deleteWebsite = [...get(['getAllCompanyList'], state, [])]
      const updatedDeleteWebsiteIndex = deleteWebsite.findIndex(list => list.id === action.payload.id)
      deleteWebsite.splice(updatedDeleteWebsiteIndex, 1, action.payload)
      return {
        ...state,
        getAllCompanyList: deleteWebsite,
        singleCompany: action.payload ? action.payload : state.singleCompany,
      }

    case COLUMNS_SELECTION_COMPANY:
      return {
        ...state,
        getAllCompanyColumnsList: action.payload,
      }
    case GET_COLUMNS_SELECTION_COMPANY: {
      return {
        ...state,
        getAllCompanyColumnsList: action.payload,
      }
    }

    case MULTIPLE_MERGE_COMPANY:
      let updatedList = []
      const getCompanyList = state.getAllCompanyList ? [...state.getAllCompanyList] : []
      // getCompanyList.forEach((updated) => {
      //  updatedList = action.payload.ids.filter((list) => list.id !== updated.id);
      updatedList = getCompanyList.filter(contact => action.payload.ids.indexOf(contact.id) === -1)
      return {
        ...state,
        getAllCompanyList: [action.payload.company, ...updatedList],
        companyPage: {
          ...state.companyPage,
          total: _.subtract(_.get(state, 'companyPage.total'), _.size(action.payload.ids)) + 1,
        },
      }

    case ADD_SOCIAL:
      return {
        ...state,
        singleCompany: action.payload,
      }

    case UPDATE_SOCIAL:
      return {
        ...state,
        singleCompany: action.payload,
      }

    case REMOVE_SOCIAL:
      return {
        ...state,
        singleCompany: action.payload,
      }
    default:
      return state
  }
}
