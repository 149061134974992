import Joi from 'joi'
import {
  emailValidation,
  inputNumberValidation,
  inputPhoneValidation,
  inputStringValidation,
  phoneValidationLocal,
} from 'validation'

export const createContactSchema = Joi.object({
  address: Joi.object({
    streetAddress: Joi.any().allow('', null).label('Street'),
    locality: Joi.any().allow('', null).label('Locality'),
    state: Joi.string().allow('', null).label('State'),
    country: Joi.string().allow('', null).label('Country'),
    postalCode: Joi.string().allow(null, '').label('Postal code'),
  }),
  // // companyId: Joi.number().allow(null).label("Company"),
  // company: Joi.string().allow("").label("Company"),
  description: Joi.string().allow('').label('Description'),
  statusId: Joi.number().allow(null).label('Status'),
  email: emailValidation.required(),
  phone: phoneValidationLocal.allow('', null),
  emails: Joi.array()
    .items(
      Joi.object({
        category: Joi.string().allow('').required(),
        email: emailValidation.allow('').required(),
        id: Joi.string().optional(),
      })
    )
    .allow(null)
    .optional(),
  phones: Joi.array()
    .items(
      Joi.object({
        category: Joi.string().allow('').required(),
        phone: phoneValidationLocal.allow('').required(),
        id: Joi.string().optional(),
      })
    )
    .allow(null)
    .optional(),
  socials: Joi.array()
    .items(
      Joi.object({
        category: Joi.string().allow('').required(),
        url: Joi.string().uri().allow('').required(),
      })
    )
    .allow(null)
    .optional(),
  firstName: inputStringValidation.required().label('First name'),
  labelIds: Joi.array().items(Joi.number().allow(null)).label('Tags'),
  lastName: inputStringValidation.required().label('Last name'),
  title: Joi.string().allow('').label('Title'),
  // visibility: Joi.string().allow(""),
  website: Joi.string().uri().allow('', null),
  websites: Joi.array()
    .items(
      Joi.object({
        category: Joi.string().allow('').required(),
        url: Joi.string().uri().allow('').required(),
      })
    )
    .allow(null)
    .optional(),
  contactTypeId: Joi.number().allow(null).label('Contact Type'),
  assigneeId: inputNumberValidation.allow(null).label('Owner'),
  allowPortalAccess: Joi.boolean(),
})

export const multipleEditContactSchema = Joi.object({
  address: Joi.object({
    streetAddress: Joi.any().allow('', null).label('Street'),
    locality: Joi.any().allow('', null).label('Locality'),
    state: Joi.string().allow('', null).label('State'),
    country: Joi.string().allow('', null).label('Country'),
    postalCode: Joi.string().allow(null, '').label('Postal code'),
  }),
  assigneeId: inputNumberValidation.allow(null).label('Owner'),
  // // companyId: Joi.number().allow(null).label("Company"),
  // // company: Joi.number().allow("").label("Company"),
  description: Joi.string().allow('').label('Description'),
  emails: Joi.array()
    .items(
      Joi.object({
        category: Joi.string().allow('').required(),
        email: emailValidation.allow('').required(),
        id: Joi.string().optional(),
      })
    )
    .allow(null),
  phones: Joi.array()
    .items(
      Joi.object({
        category: Joi.string().allow('').required(),
        phone: inputPhoneValidation.allow('').required(),
        id: Joi.string().optional(),
      })
    )
    .allow(null),
  firstName: inputStringValidation.allow('').label('First name'),
  labelIds: Joi.array().items(Joi.number().allow(null)).label('Tags'),
  lastName: inputStringValidation.allow('').label('Last name'),
  statusId: Joi.number().allow(null).label('Status'),
  title: Joi.string().allow('', null).label('Title'),
  websites: Joi.array()
    .items(
      Joi.object({
        category: Joi.string().allow('').required(),
        url: Joi.string().uri().allow('').required(),
      })
    )
    .allow(null),
  contactTypeId: Joi.number().allow(null).label('Contact Type'),
})

export const editContactSchema = Joi.object({
  address: Joi.object({
    streetAddress: Joi.any().allow('', null).label('Street'),
    locality: Joi.any().allow('', null).label('Locality'),
    state: Joi.string().allow('', null).label('State'),
    country: Joi.string().allow('', null).label('Country'),
    postalCode: Joi.string().allow(null, '').label('Postal code'),
  }),
  assigneeId: inputNumberValidation.allow(null).label('Owner'),
  // // companyId: Joi.number().allow(null).label("Company"),
  // // company: Joi.number().allow("").label("Company"),
  description: Joi.string().allow('', null).label('Description'),
  email: emailValidation.required(),
  phone: phoneValidationLocal.allow('', null),
  emails: Joi.array()
    .items(
      Joi.object({
        category: Joi.string().allow('').required(),
        email: emailValidation.allow('').required(),
        id: Joi.string().optional(),
      })
    )
    .allow(null),
  phones: Joi.array()
    .items(
      Joi.object({
        category: Joi.string().allow('').required(),
        phone: inputPhoneValidation.allow('').required(),
        id: Joi.string().optional(),
      })
    )
    .allow(null),
  firstName: inputStringValidation.required().label('First name'),
  labelIds: Joi.array().items(Joi.number().allow(null)).label('Tags'),
  lastName: inputStringValidation.required().label('Last name'),
  statusId: Joi.number().allow(null).label('Status'),
  title: Joi.string().allow('', null).label('Title'),
  website: Joi.string().uri().allow('', null),
  websites: Joi.array()
    .items(
      Joi.object({
        category: Joi.string().allow('').required(),
        url: Joi.string().uri().allow('').required(),
      })
    )
    .allow(null),
  contactTypeId: Joi.number().allow(null).label('Contact Type'),
  allowPortalAccess: Joi.boolean().default(false),
})

export const filterContactSchema = Joi.object().keys({
  assigneeId: inputNumberValidation.allow('', null).label('Owner'),
  labelIds: Joi.number().allow('', null).label('Tags'),
  companyId: Joi.number().allow('', null),
  isFollowed: Joi.string().allow('', null),
  interactionsFrom: Joi.number().min(0).allow('', null, 0),
  interactionsTo: Joi.number().min(0).allow('', null, 0),
  lastContactedFrom: Joi.date().iso().allow(null),
  lastContactedTo: Joi.date().iso().allow(null),
  inactiveDaysFrom: Joi.number().min(0).allow('', null, 0),
  inactiveDaysTo: Joi.number().min(0).allow('', null, 0),
  createdAtFrom: Joi.date().iso().allow(null),
  createdAtTo: Joi.date().iso().allow(null),
  contactTypeId: Joi.number().allow('', null),
  statusId: Joi.number().allow('', null),
  locality: Joi.any().allow('', null).label('Locality'),
  state: Joi.string().allow('', null).label('State'),
  country: Joi.string().allow('', null).label('Country'),
  postalCode: Joi.string().allow(null, '').label('Postal code'),
})

export const contactDetailsRightSchema = Joi.object({
  phones: Joi.object({
    category: Joi.string().allow('').required(),
    phone: inputPhoneValidation.required().allow('', null),
    id: Joi.string().optional(),
  }),
  company: Joi.string().allow('', null).optional().label('company name'),
  address: Joi.object({
    locality: inputStringValidation.allow('', null).optional().label('locality').messages({
      'string.empty': `Locality cannot be an empty field`,
      'string.min': `Locality should have a minimum length of 4`,
      'any.required': `Locality is a required field`,
    }),
  }).allow('', null),
})

export const smsResolver = Joi.object({
  messageText: Joi.string().max(100).trim().required().messages({
    'string.empty': `message text is not allowed to be empty`,
    'any.required': `message text is a required field`,
    'string.max': `message text length must be less than or equal to 100 characters`,
  }),
  phone: inputPhoneValidation.required(),
  mailboxId: Joi.number().required().messages({
    'any.required': `mailbox is required`,
    'number.base': `mailbox is required`,
  }),
})

export const customerProfileSchema = Joi.object({
  name: Joi.string().allow(''),
  email: Joi.string()
    .email({tlds: {allow: false}})
    .allow(''),
  phone: Joi.string()
    .trim()
    .max(20)
    .allow('')
    .regex(/^\+?[0-9]{4,20}$/)
    .messages({
      'string.pattern.base': 'Enter a valid phone number',
    }),
  website: Joi.string().trim().allow('').optional().label('company url'),
  company: Joi.string().trim().allow('').optional().label('company name'),
})
