import React from 'react'
import {VmoTable, VmoTableHeader, VmoTableRow, VmoTableHeaderCell, VmoTableBody, VmoTableCell} from 'vmo-library'
import {getFormattedDate} from 'utils/helper'

function StandardTemplate({data}) {
  const {setupData = {}, template = {}, products = {}, addressTemplate = {}, style, preview} = data

  const {billingAddress, shippingAddress} = setupData

  const addressData = addressTemplate[0]

  return (
    <div className="pruchase-order" style={style}>
      <div className="pruchase-order-wrap">
        <div className="d-flex">
          <div className="purchase-logo">
            <img src={preview} alt="logo" />
          </div>
          <div className="purchase-name">
            <span>{template?.template?.header}</span>
          </div>
        </div>
        <div className="d-flex mt-2" style={{alignItems: 'flex-start'}}>
          <div className="purchase-order-number">
            <span>
              Sales Order: <strong>{setupData?.soNumber}</strong>
            </span>
            <span>Due Date: {getFormattedDate(setupData?.dueDate)}</span>
            <span>SO Status: {setupData?.status}</span>
          </div>
          <div className="purchase-order-company-section">
            <strong>Company Name: {addressData?.companyName}</strong>
            <span>
              <strong>Street Address: </strong>
              {addressData?.streetAddress}
            </span>
            <span>
              <strong>City: </strong>
              {addressData?.city},<strong>State: </strong>
              {addressData?.state},<strong>Zip: </strong>
              {addressData?.postalCode}
            </span>
            <span>
              <strong>Phone:</strong> {addressData?.phone}
            </span>
            <span>
              <strong>Email:</strong> {addressData?.email}
            </span>
          </div>
        </div>

        <VmoTable className="purchase-order-top-table">
          <VmoTableHeader>
            <VmoTableHeaderCell style={{background: template.template?.color}}>BILLING ADDRESS</VmoTableHeaderCell>
            <VmoTableHeaderCell style={{background: template.template?.color}}>SHIPPING ADDRESS</VmoTableHeaderCell>
          </VmoTableHeader>
          <VmoTableBody>
            <VmoTableRow>
              <VmoTableCell>
                <div className="purchase-order-to-section">
                  <span>
                    <strong>Street Address: </strong>
                    {billingAddress?.streetAddress}
                  </span>
                  <span>
                    <strong>City: </strong>
                    {billingAddress?.locality}
                  </span>
                  <span>
                    <strong>State: </strong>
                    {billingAddress?.state}
                  </span>
                  <span>
                    <strong>Country: </strong>
                    {billingAddress?.country}
                  </span>
                  <span>
                    <strong>Postal Code: </strong> {billingAddress?.postalCode}
                  </span>
                </div>
              </VmoTableCell>
              <VmoTableCell>
                <div className="purchase-order-shipTo-section">
                  <span>
                    <strong>Street Address: </strong>
                    {shippingAddress?.streetAddress}
                  </span>
                  <span>
                    <strong>City: </strong>
                    {shippingAddress?.locality}
                  </span>
                  <span>
                    <strong>State: </strong>
                    {shippingAddress?.state}
                  </span>
                  <span>
                    <strong>Country: </strong>
                    {shippingAddress?.country}
                  </span>
                  <span>
                    <strong>Postal Code: </strong> {shippingAddress?.postalCode}
                  </span>
                </div>
              </VmoTableCell>
            </VmoTableRow>
          </VmoTableBody>
        </VmoTable>

        <VmoTable className="purchase-order-top-table">
          <VmoTableHeader>
            <VmoTableHeaderCell style={{background: template.template?.color}}>Due Date</VmoTableHeaderCell>
            <VmoTableHeaderCell style={{background: template.template?.color}}>Shipped Via</VmoTableHeaderCell>
            <VmoTableHeaderCell style={{background: template.template?.color}}>Quote Number</VmoTableHeaderCell>
          </VmoTableHeader>
          <VmoTableBody>
            <VmoTableRow>
              <VmoTableCell>{getFormattedDate(setupData?.dueDate)}</VmoTableCell>
              <VmoTableCell>{setupData?.carrier}</VmoTableCell>
              <VmoTableCell>{setupData?.quoteId}</VmoTableCell>
            </VmoTableRow>
          </VmoTableBody>
        </VmoTable>

        <VmoTable basic className="purchase-order-top-table with-border">
          <VmoTableHeader>
            <VmoTableHeaderCell style={{width: 60, background: template.template?.color}}>
              Product Id
            </VmoTableHeaderCell>
            <VmoTableHeaderCell style={{width: 60, background: template.template?.color}}>Qty</VmoTableHeaderCell>
            <VmoTableHeaderCell style={{width: 130, background: template.template?.color}}>
              Unit Price
            </VmoTableHeaderCell>
            <VmoTableHeaderCell style={{background: template.template?.color}}>Unit Amount</VmoTableHeaderCell>
            <VmoTableHeaderCell style={{width: 200, background: template.template?.color}}>Discount</VmoTableHeaderCell>
            <VmoTableHeaderCell style={{width: 200, background: template.template?.color}}>Tax</VmoTableHeaderCell>
            <VmoTableHeaderCell style={{width: 90, background: template.template?.color}}>Total</VmoTableHeaderCell>
          </VmoTableHeader>
          <VmoTableBody>
            {products?.products?.map(prod => {
              return (
                <VmoTableRow>
                  <VmoTableCell>{prod.productId}</VmoTableCell>
                  <VmoTableCell>{prod.quantity}</VmoTableCell>
                  <VmoTableCell>{prod.unitPrice}</VmoTableCell>
                  <VmoTableCell>{prod.unitAmount}</VmoTableCell>
                  <VmoTableCell>{prod.discountValue}%</VmoTableCell>
                  <VmoTableCell>{prod.taxValue}%</VmoTableCell>
                  <VmoTableCell>{prod.unitTotal}</VmoTableCell>
                </VmoTableRow>
              )
            })}
            <VmoTableRow>
              <VmoTableCell className="purchase-order-terms-description" rowSpan="6" colSpan="3">
                <div className="purchase-order-terms">
                  <span>Terms and Conditions</span>
                  <span>{products?.terms}</span>
                </div>
                <div className="purchase-order-description">
                  <span>Description</span>
                  <span>{setupData?.description}</span>
                </div>
              </VmoTableCell>
              <VmoTableCell>
                <strong>Sub Total</strong>
              </VmoTableCell>
              <VmoTableCell style={{borderRight: '1px solid #e2e7f2'}}>{products?.subTotal}</VmoTableCell>
            </VmoTableRow>
            <VmoTableRow>
              <VmoTableCell>
                <strong>Discount</strong>
              </VmoTableCell>
              <VmoTableCell style={{borderRight: '1px solid #e2e7f2'}}>{products?.discount}%</VmoTableCell>
            </VmoTableRow>
            <VmoTableRow>
              <VmoTableCell>
                <strong>Tax</strong>
              </VmoTableCell>
              <VmoTableCell style={{borderRight: '1px solid #e2e7f2'}}>{products?.tax}%</VmoTableCell>
            </VmoTableRow>
            <VmoTableRow>
              <VmoTableCell>
                <strong>Adjustment</strong>
              </VmoTableCell>
              <VmoTableCell style={{borderRight: '1px solid #e2e7f2'}}>{products?.adjustment}</VmoTableCell>
            </VmoTableRow>
            <VmoTableRow>
              <VmoTableCell>
                <strong>Total</strong>
              </VmoTableCell>
              <VmoTableCell style={{borderRight: '1px solid #e2e7f2'}}>{products?.total}</VmoTableCell>
            </VmoTableRow>
          </VmoTableBody>
        </VmoTable>
        <div style={{textAlign: 'center'}}>
          <h6>{template.template?.footer}</h6>
        </div>
      </div>
    </div>
  )
}

export default StandardTemplate
