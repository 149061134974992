import React from 'react'
import {VmoLoader} from 'vmo-library'

const CustomLoader = () => {
  return (
    <VmoLoader
      active
      inline="centered"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#152e4d',
      }}
    />
  )
}

export default CustomLoader
