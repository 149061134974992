import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

import {COPY_PROJECTS_NOTEBOOK} from 'actions/types'
import {copyProjectsNotebook} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const CopyNotebookModal = ({open, toggle, projectId, notebookId, notebookTitle}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsList = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        title: Joi.string().trim().min(3).max(50).required().messages({
          'string.min': `Length must me at least 3 charactes`,
          'string.max': `Length must be less than or equal to 50 characters`,
        }),
        projectId: Joi.number().required().messages({
          'number.base': 'Project must be specified',
        }),
      })
    ),
    defaultValues: {
      title: '',
      projectId: null,
    },
  })

  useEffect(() => {
    if (notebookTitle && projectId) {
      const data = {title: `Copy of ${notebookTitle}`, projectId: Number(projectId)}
      reset(data)
    }
  }, [projectId, notebookTitle, reset])

  useEffect(() => {
    if (successLabels.includes(COPY_PROJECTS_NOTEBOOK)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    dispatch(copyProjectsNotebook({projectId, notebookId, data}))
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>Copy notebook</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Title</label>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required>
            <label>Project</label>
            <Controller
              name="projectId"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  options={projectsList.map(({id, title}) => ({key: id, value: id, text: title}))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.projectId && {content: removeDoubleQuotes(errors.projectId.message)}}
                />
              )}
            />
          </VmoFormField>
          <p>Note: Copying this notebook to the same project will not copy its comments.</p>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          Ok
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default CopyNotebookModal
