import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useRouteMatch} from 'react-router'

import {ADD_SURVEY, DELETE_SURVEY, EDIT_SURVEY, GET_CHAT_SURVEY} from 'actions/types'
import {getChatSurvey} from 'actions/settings_customer_satisfaction'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create.svg'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {getPermissionTooltipContent} from 'utils/helper'
import ChatSurveyForm from './ChatSurveyForm'

const ChatSurvey = () => {
  const {url} = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const {chatSurveySingle} = useSelector(state => state.settingsCustomerSatisfaction)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const [chatSurvey, setChatSurvey] = useState(Boolean(chatSurveySingle))
  const [chatSurveyCreate, setChatSurveyCreate] = useState(false)

  const {isLoading} = useApiLoader({label: GET_CHAT_SURVEY})
  const {generalAccess, productivityPermissions} = useUserPermissions()
  const chatSurveysAccess = generalAccess?.chatSurveys
  const customerSatficationsPermissions = productivityPermissions?.customer_satfications

  useEffect(() => {
    if (chatSurveysAccess) {
      dispatch(getChatSurvey())
    }
  }, [chatSurveysAccess, dispatch])

  useEffect(() => {
    if (successLabels.includes(ADD_SURVEY)) {
      dispatch(getChatSurvey())
      setChatSurvey(false)
    }
    if (successLabels.includes(DELETE_SURVEY)) {
      dispatch(getChatSurvey())
    }
    if (successLabels.includes(EDIT_SURVEY)) {
      dispatch(getChatSurvey())
    }
    if (successLabels.includes(GET_CHAT_SURVEY)) {
      setChatSurvey(Boolean(chatSurveySingle))
    }
  }, [successLabels, dispatch, chatSurveySingle])

  useEffect(() => {
    if (chatSurvey) {
      history.push(`${url}/${chatSurveySingle.id}`)
    }
  }, [chatSurvey, history, url, chatSurveySingle])

  if (isLoading && chatSurveysAccess) {
    return <PageLoader />
  }

  if (chatSurveyCreate) {
    return <ChatSurveyForm toggleCreate={setChatSurveyCreate} />
  }

  if (!chatSurvey || !chatSurveysAccess) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="Chat Survey"
          subHeader="Collect feedbacks from users who have engaged in chat conversations with your team"
          addFirst={() => chatSurveysAccess && customerSatficationsPermissions && setChatSurveyCreate(true)}
          buttonText="Add Chat Survey"
          tooltip={!chatSurveysAccess || !customerSatficationsPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: chatSurveysAccess,
            roleAccess: customerSatficationsPermissions,
          })}
          imageSrc={create}
          Feature1="Post-Chat Surveys"
          list1="Send automated follow-up survey who engaged in the chat"
          Feature2="Rating and Feedback"
          list2="Include rating scales to collect feedback from users."
          Feature3="Integration with Chat Platform"
          list3="Streamline the process of sending surveys through chat"
        />
      </div>
    )
  }

  return null
}

export default ChatSurvey
