import {deleteProjectsTemplatesFinanceBudget, getProjectsTemplatesFinanceBudget} from 'actions/projects'
import {CREATE_PROJECTS_TEMPLATES_FINANCE_BUDGET, DELETE_PROJECTS_TEMPLATES_FINANCE_BUDGET} from 'actions/types'
import {PROJECT_FINANCE_BUDGET_TYPES, projectBudgetsBasedOnOptions} from 'constants/projects'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {FiMoreVertical} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom/cjs/react-router-dom.min'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoGrid,
  VmoGridColumn,
} from 'vmo-library'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import CreateTemplateBudgetModal from './CreateTemplateBudgetModal'

const TemplateFinanceBudgetSummary = ({templateId, budgetId}) => {
  const dispatch = useDispatch()
  const {pathname} = useLocation()
  const history = useHistory()

  const [isCreateBudgetModalOpen, setIsCreateBudgetModalOpen] = useState(false)
  const [isDeleteBudgetModalOpen, setIsDeleteBudgetModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {isTemplateBudgetCreated = false, templateBudget = {}} = useSelector(state => state.projects)

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECTS_TEMPLATES_FINANCE_BUDGET)) {
      setIsCreateBudgetModalOpen(false)
      dispatch(getProjectsTemplatesFinanceBudget(templateId))
    }
    if (successLabels.includes(DELETE_PROJECTS_TEMPLATES_FINANCE_BUDGET)) {
      dispatch(getProjectsTemplatesFinanceBudget(templateId))
    }
  }, [successLabels, dispatch, templateId])

  const renderBudgetModal = () => {
    if (isCreateBudgetModalOpen) {
      return (
        <CreateTemplateBudgetModal
          open={isCreateBudgetModalOpen}
          toggle={value => setIsCreateBudgetModalOpen(value)}
          templateId={templateId}
        />
      )
    }
  }

  if (!isTemplateBudgetCreated) {
    return (
      <div className="kadence-side-wrap">
        <CreateFirst
          header="Budgets"
          subHeader="Project managers can create, track, and monitor budgets for their projects with ease."
          addFirst={() => setIsCreateBudgetModalOpen(true)}
          buttonText="Create budget"
          Feature1="Budget Creation"
          Feature2="Budget Limit"
          Feature3="Multiple currencies"
          list1="Create detailed project budgets with line items for expenses"
          list2="Set budget limits to prevent cost overruns"
          list3="Support multiple currencies for global projects"
        />
        {renderBudgetModal()}
      </div>
    )
  }

  return (
    <div className="kadence-side-wrap">
      <div className="project-finance-budget-header">
        <div className="budget-header-left">
          <div>
            <span>Type</span>
            <div>{_.startCase(templateBudget.budgetType)}</div>
          </div>
          {[PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER].includes(
            templateBudget.budgetType
          ) && (
            <div>
              <span>Based on</span>
              <div>
                {projectBudgetsBasedOnOptions.find(option => option.value === templateBudget.timelogType)?.text}
              </div>
            </div>
          )}
          {templateBudget.budgetType === PROJECT_FINANCE_BUDGET_TYPES.RETAINER && (
            <div>
              <span>Period Length</span>
              <div>{_.startCase(templateBudget.repeatUnit)}</div>
            </div>
          )}
        </div>
        <div className="d-flex">
          <VmoButton className="basic-fill-btn mr-3" onClick={() => history.push(pathname.replace(/[^/]*$/, 'rates'))}>
            Edit rates
          </VmoButton>
          <VmoDropdown button icon={<FiMoreVertical />} className="icon">
            <VmoDropdownMenu direction="left">
              <VmoDropdownItem onClick={() => setIsDeleteBudgetModalOpen(true)} className="text-danger">
                Delete budget
              </VmoDropdownItem>
            </VmoDropdownMenu>
          </VmoDropdown>
        </div>
      </div>

      <VmoGrid className="my-2">
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Budget Overview</VmoCardHeader>
            </VmoCardContent>
            <VmoCardContent className="project-budget-overview">
              <div className="project-budget-overview-left">
                <p className="period-total">Current period total</p>
                <p className="period-amount">${templateBudget.budgetAmount}</p>
              </div>
              <div className="project-budget-overview-right">
                <div className="d-flex">
                  <div>Used</div>
                  <div>Remaining</div>
                </div>
                <div className="d-flex">
                  <div>$0 (0%)</div>
                  <div>$0 (0%)</div>
                </div>
                <div className="budget-progress">
                  <div className="budget-progress-completed" style={{width: `0%`}} />
                </div>
              </div>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      {isDeleteBudgetModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteBudgetModalOpen}
          setIsModalOpen={setIsDeleteBudgetModalOpen}
          onDelete={() => dispatch(deleteProjectsTemplatesFinanceBudget({templateId, budgetId}))}
          type={DELETE_PROJECTS_TEMPLATES_FINANCE_BUDGET}
        />
      )}
    </div>
  )
}

export default TemplateFinanceBudgetSummary
