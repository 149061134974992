import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {VmoCard, VmoCardContent, VmoForm, VmoGrid, VmoGridColumn} from 'vmo-library'

import {getUser} from 'actions/settings_organisation'
import {getUserInfo} from 'utils/local-storage'
import {get} from 'utils/helper'
import {ControlledCompanyInfoFormField, ControlledYourInfoFormField} from './BuyerInfo'
import PreviewQuote from '../Templates/PreviewQuote'

const companyFields = [
  {name: 'company.name', label: 'Name'},
  {name: 'company.streetAddress', label: 'Street Address'},
  {name: 'company.locality', label: 'Locality'},
  {name: 'company.state', label: 'State'},
  {name: 'company.country', label: 'Country'},
  {name: 'company.postalCode', label: 'Postal Code'},
]

const personalDetailsFields = [
  {name: 'firstName', label: 'First Name'},
  {name: 'lastName', label: 'Last Name'},
  {name: 'jobTitle', label: 'Job Title'},
  {name: 'email', label: 'Email'},
  {name: 'phone', label: 'Phone'},
]

function YourInfo({formData, quoteId, setWarningModal}) {
  const {
    control,
    errors,
    reset,
    setValue,
    watch,
    formState: {isDirty},
  } = formData
  const watchAll = watch()
  const dispatch = useDispatch()

  const {getUserData: {id: userId, firstName, lastName, email, phone} = {}} = useSelector(state => state.settings)

  const {
    quoteForm: {yourInfo},
    quoteDetails: {yourInfo: yourInfoData},
  } = useSelector(state => state.quotes)

  useEffect(() => {
    dispatch(getUser(get(['id'], getUserInfo())))
  }, [])

  useEffect(() => {
    setWarningModal(isDirty)
  }, [isDirty])

  useEffect(() => {
    if (yourInfo) reset(yourInfo)
  }, [yourInfo])

  useEffect(() => {
    if (!quoteId && userId) {
      setValue('firstName', firstName)
      setValue('lastName', lastName)
      setValue('email', email)
      setValue('phone', phone)
    }
  }, [userId])

  return (
    <div className="stepWrap">
      <VmoGrid>
        <VmoGridColumn width={8}>
          <VmoCard fluid>
            <VmoCardContent>
              <h3 className="mb-0">Your Information</h3>
              <p className="mt-0 mb-3 card-description">Add all personal informations to display on the quote</p>

              <VmoForm className="errorLabel ui grid ">
                {personalDetailsFields.map(({label, name}) => (
                  <ControlledYourInfoFormField
                    key={name}
                    classes="eight wide column m-0 py-0"
                    control={control}
                    errors={errors}
                    label={label}
                    name={name}
                  />
                ))}
              </VmoForm>
            </VmoCardContent>
          </VmoCard>

          <VmoCard fluid>
            <VmoCardContent>
              <h3 className="mb-0">Company Information</h3>
              <p className="mt-0 mb-3 card-description">Add company informations to display on the quote</p>

              <VmoForm className="errorLabel ui grid">
                {companyFields.map(({label, name}) => (
                  <ControlledCompanyInfoFormField
                    classes="eight wide column m-0 py-0"
                    key={name}
                    control={control}
                    errors={errors}
                    label={label}
                    name={name}
                  />
                ))}
              </VmoForm>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>

        <VmoGridColumn width={8} className="quotePreBox">
          <PreviewQuote watch={{yourInfo: watchAll}} />
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default YourInfo
