import React, {useEffect} from 'react'
import EmailEditor from 'react-email-editor'

const EmailEditorTemplate = ({defaultValue, emailRef, ...props}) => {
  useEffect(() => {
    if (defaultValue && emailRef?.current?.editor) {
      const templateJson = JSON.parse(defaultValue)
      emailRef.current.editor.loadDesign(templateJson)
    }
  }, [defaultValue, emailRef])

  return (
    <EmailEditor
      ref={emailRef}
      minHeight="calc(100vh - 205px)"
      appearance={{
        theme: 'light',
        panels: {
          tools: {
            dock: 'left',
          },
        },
      }}
      options={{
        id: 'editor-container',
        projectId: 26885,
        displayMode: 'email',
        customCSS: [
          `#u_body.u_body{
        background:#f8f9fb  !important;
        min-height: calc(100vh - 1px) !important;
      }
      .actions-container {
        top: 0;
        height: 42px;
        right: 0;
    }
      .blockbuilder-preview{
        overflow: auto;
        background-image:none;
        padding: 1px 0px 0px 20px !important;
      }
      .blockbuilder-preferences{
        border-right: none !important;
        box-shadow: 0 12px 32px 0 rgb(136 148 171 / 24%);
      }
     
      .blockbuilder-tools-panel{
        flex-direction: column !important;
        padding-right: 20px;
        background: #fff;
      }
      .blockbuilder-tools-panel .nav-tabs{
        width: 100%;
        flex-direction: row !important;
        background: #fff;
        border-bottom: 1px solid #f5f5f5 !important;
      }
      .blockbuilder-tools-panel .nav-tabs .nav-item {
        min-width: auto !important;
        border-bottom: 1px solid #f5f5f5;
      }
      .blockbuilder-tools-panel .nav-tabs .nav-item:last-child{
        display: none;
      }
      .blockbuilder-tools-panel .tab-content .tab-pane.active, .blockbuilder-options-content {
        background: #fff !important;
      }
      .blockbuilder-tools-panel .nav-tabs .nav-item .nav-link {
        border-bottom: 3px solid #fff;
      }
      .blockbuilder-tools-panel .nav-tabs .nav-item .nav-link:hover {
        color: rgb(39 114 234) !important;
        background-color: rgb(255 255 255) !important;
      }
      .blockbuilder-tools-panel .nav-tabs .nav-item .nav-link.active {
        background-color: rgb(255 255 255) !important;
        color: rgb(39 114 234) !important;
        border-bottom: 3px solid #2772ea;
      }
      .blockbuilder-content-tool, .blockbuilder-classic-tool{
        margin: 0 2px 24px 0 !important;  
      }
      .blockbuilder-placeholder-empty{
        background-color: #fff !important;
        outline: #c3cee1 dashed 1px !important;
        font-size: 14px !important;
        color: #00344f !important;
      }
      .blockbuilder-options-content .nav-tabs .nav-item .nav-link.active {
        background: #fff !important;
        border: none;
        border-bottom: 2px solid #2772ea !important;
        color: #2772ea !important;
      }
      .blockbuilder-options-content .nav-tabs .nav-item .nav-link:hover{
        background: #fff !important;
        border: none;
        color: #2772ea !important;
      }
      .blockbuilder-options-content .nav-tabs .nav-item .nav-link{
        color: #8c9aa7 !important;
      }
      `,
          '',
        ],
      }}
      {...props}
    />
  )
}

export default EmailEditorTemplate
