import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveLine} from '@nivo/line'
import Moment from 'moment'
import {VmoCard, VmoCardContent, VmoCardHeader, VmoGrid, VmoGridColumn, VmoTooltip} from 'vmo-library'

import {CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS} from 'actions/types'
import {campaignStatesAnalytics, campaignTotalSendUniqueVisitorsAnalytics} from 'actions/analytics_campaign'
import {getGridYValues, getGridYValuesBar, maxOfGraph, maxOfGraphBar} from 'utils/graph'
import {startCase, get} from 'utils/helper'
import CustomLoader from 'components/common/CustomLoader'
import {CampaignListDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const Campaign = props => {
  const {
    headerLogo,
    header,
    campaignType,
    campaignStatus,
    lineChartHeader,
    showBarChart = false,
    campaignIsAvailabel = false,
  } = props

  const dispatch = useDispatch()

  const [lineChart, setLineChart] = useState([])
  const [barChart, setBarChart] = useState([])

  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {campaignUniqueVisitors = {}} = useSelector(state => state.analyticsCampaign)
  const {selectedCampaignId} = useSelector(state => state.analyticsCampaign)
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const campaignsAccess = analyticsAccess?.campaigns
  const campaignReportsPermissions = reportsPermissions?.campaign_reports

  useEffect(() => {
    if (successLabels.includes(CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS)) {
      const line = []
      for (const [key, val] of Object.entries(campaignUniqueVisitors)) {
        if (key === 'sent' || key === 'unique') {
          line.push({id: startCase(key), data: val})
        } else if (key === 'reply') {
          const temp = val.map(obj => {
            return {time: obj.x, value: obj.y}
          })
          setBarChart(temp)
        }
      }
      setLineChart(line)
    }
  }, [campaignUniqueVisitors, successLabels])

  const actionsToDispatch = useCallback(
    data => {
      if (campaignType) {
        data.campaignType = campaignType
      }
      dispatch(campaignStatesAnalytics(data))
      dispatch(campaignTotalSendUniqueVisitorsAnalytics(data))
    },
    [dispatch, campaignType]
  )

  const diff = Moment(get(['persistDateRangeEndDate'], JSON.parse(localStorage.getItem('kadence')))).diff(
    get(['persistDateRangeStartDate'], JSON.parse(localStorage.getItem('kadence'))),
    'days'
  )

  return (
    <div className="kadence-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            {headerLogo}
            <h5>{header}</h5>
          </div>
        </div>
        {campaignIsAvailabel && campaignsAccess && campaignReportsPermissions && (
          <div className="page-action">
            <CampaignListDropdown campaignType={campaignType} />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              campaignId={selectedCampaignId && selectedCampaignId !== 'all' ? Number(selectedCampaignId) : null}
            />
          </div>
        )}
      </div>

      <VmoGrid columns="equal">
        {campaignStatus.map((obj, index) => {
          return (
            <VmoGridColumn key={obj?.name + index}>
              <VmoCard fluid>
                <VmoCardContent className="valueBox">
                  <VmoCardHeader>
                    {obj.label} <small>{obj.name === 'engagementRate' ? '(%)' : ''}</small>
                  </VmoCardHeader>
                  {campaignIsAvailabel ? (
                    <h3>
                      {obj.name === 'engagementRate' ? Number.parseFloat(obj.value).toFixed(2) : obj.value}
                      <small>{obj.name === 'engagementRate' ? ' %' : ''}</small>
                      <span className={get(['growth'], obj, 'negative') === 'positive' ? '' : 'text-danger'}>
                        <VmoTooltip
                          position="top center"
                          size="mini"
                          content="Compared to Previous Duration"
                          trigger={
                            <div>
                              {get(['growth'], obj, 'negative') === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], obj, 0)}%
                            </div>
                          }
                        />
                      </span>
                    </h3>
                  ) : (
                    <h3>-</h3>
                  )}
                </VmoCardContent>
              </VmoCard>
            </VmoGridColumn>
          )
        })}
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>{lineChartHeader}</VmoCardHeader>
              <VmoTooltip
                content="Graphically represents total email campaign sent to unique contacts over a period of time"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 270}}>
              {type.includes(CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS) && <CustomLoader />}
              {!type.includes(CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS) &&
                !lineChart.map(({data}) => data.every(({y}) => y === 0)).includes(false) && (
                  <div className="empty-chart">No Data Available</div>
                )}

              {!type.includes(CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS) && (
                <ResponsiveLine
                  data={lineChart}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: '0',
                    max: maxOfGraph(lineChart),
                    stacked: false,
                    reverse: false,
                  }}
                  colors={{scheme: 'category10'}}
                  enableSlices="x"
                  enableGridX={false}
                  gridYValues={getGridYValues(lineChart)}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    format: tick => {
                      if (diff >= 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    tickPadding: 5,
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Count',
                    tickValues: getGridYValues(lineChart),
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  lineWidth={2}
                  pointSize={3}
                  pointColor="#ffffff"
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-12}
                  enableArea
                  useMesh
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 30,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      {showBarChart && (
        <VmoGrid>
          <VmoGridColumn width={16}>
            <VmoCard fluid className="chartBox">
              <VmoCardContent className="headerBox">
                <VmoCardHeader>Total Replied</VmoCardHeader>
                <VmoTooltip
                  content="count of replies received on chat campaigns over time period"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </VmoCardContent>
              <VmoCardContent className="pt-0" style={{height: 270}}>
                {type.includes(CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS) && <CustomLoader />}
                {!type.includes(CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS) &&
                  barChart.every(({value}) => value === 0) && <div className="empty-chart">No Data Available</div>}
                {!type.includes(CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS) && (
                  <ResponsiveBar
                    data={barChart}
                    indexBy="time"
                    margin={{
                      top: 37,
                      right: 27,
                      bottom: 60,
                      left: 60,
                    }}
                    padding={0.3}
                    colors={{scheme: 'accent'}}
                    enableLabel
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    minValue={0}
                    maxValue={maxOfGraphBar(barChart, 'value')}
                    gridYValues={getGridYValuesBar(barChart, 'value')}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      format: tick => {
                        if (diff >= 0 && diff < 22) {
                          return tick
                        }
                        return Number(tick.match(/\d+/)) % 2 ? '' : tick
                      },
                      legend: 'Time',
                      legendPosition: 'middle',
                      legendOffset: 40,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      tickValues: getGridYValuesBar(barChart, 'value'),
                      legend: 'Count',
                      legendPosition: 'middle',
                      legendOffset: -40,
                    }}
                    labelSkipHeight={1}
                    label={d => <tspan y={-8}>{d.value}</tspan>}
                    labelTextColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    animate
                    motionStiffness={90}
                    motionDamping={15}
                  />
                )}
              </VmoCardContent>
            </VmoCard>
          </VmoGridColumn>
        </VmoGrid>
      )}
    </div>
  )
}

export default Campaign
