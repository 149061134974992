import {
  GET_VENDORS_CATEGORY,
  GET_SINGLE_VENDORS_CATEGORY,
  DELETE_VENDORS_CATEGORY,
  CREATE_VENDORS_CATEGORY,
  GET_SINGLE_VENDORS_FIELD,
  CREATE_VENDORS_FIELD,
  GET_VENDORS_FIELD,
  EDIT_VENDORS_FIELD,
  DELETE_VENDORS_FIELD,
  GET_VENDOR_FIELD_BY_CATEGORY,
  GET_VENDORS_LIST,
  GET_VENDORS_DATA,
  EXPORT_ALL_VENDORS,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_VENDORS_CATEGORY:
      return {
        ...state,
        getVendorsCategoryData: action.payload,
      }
    case GET_SINGLE_VENDORS_CATEGORY:
      return {
        ...state,
        getSingleVendorsCategoryData: action.payload,
      }
    case DELETE_VENDORS_CATEGORY: {
      return {
        ...state,
        getVendorsCategoryData: state.getVendorsCategoryData.filter(category => category.id !== action.payload),
      }
    }
    case CREATE_VENDORS_CATEGORY:
      return {
        ...state,
        getVendorsCategoryData: [action.payload, ...state.getVendorsCategoryData],
      }
    case GET_SINGLE_VENDORS_FIELD:
      return {
        ...state,
        getSingleVendorsFieldData: action.payload,
      }
    case GET_VENDOR_FIELD_BY_CATEGORY:
      return {...state, fieldsByCategory: action.payload}

    case CREATE_VENDORS_FIELD:
      return {
        ...state,
        getVendorsFieldData: [action.payload, ...state.getVendorsFieldData],
      }
    case GET_VENDORS_FIELD:
      return {
        ...state,
        getVendorsFieldData: action.payload,
      }
    case EDIT_VENDORS_FIELD:
      return {
        ...state,
        getVendorsFieldData: state.getVendorsFieldData.map(field => {
          return field.id === action.payload.id ? {...action.payload} : field
        }),
      }
    case DELETE_VENDORS_FIELD:
      return {
        ...state,
        getVendorsFieldData: state.getVendorsFieldData.filter(field => field.id !== action.payload),
      }

    case GET_VENDORS_LIST:
      return {
        ...state,
        vendorsList: action.payload || {},
      }

    case GET_VENDORS_DATA:
      return {
        ...state,
        vendorsData: action.payload,
      }

    case EXPORT_ALL_VENDORS:
      return {
        ...state,
        exportData: action.payload,
      }

    case 'CLEAR_SELECTED_VENDORS':
      return {
        ...state,
        exportData: null,
      }

    case 'CLEAR_VENDORS_DATA':
      return {
        ...state,
        vendorsData: {},
      }
    default:
      return state
  }
}
