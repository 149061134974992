import React, {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {ContentState, convertFromHTML, Editor, EditorState} from 'draft-js'
import {stateToHTML} from 'draft-js-export-html'
import {
  VmoButton,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoIcon,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'

import {
  createProjectsNotebookNoteComment,
  deleteProjectsNotebookNoteComment,
  updateProjectsNotebookNoteComment,
} from 'actions/projects'
import {
  extractTextContentFromHTML,
  get,
  getFormattedDate,
  getFormattedTime,
  getPermissionTooltipContent,
} from 'utils/helper'
import {DELETE_PROJECTS_NOTEBOOK_NOTE_COMMENT} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {MdOutlineMoreVert} from 'react-icons/md'
import SvgIcon from 'components/common/SvgIcon'

const Comments = ({comments, projectId, noteId, rolePermissions}) => {
  const dispatch = useDispatch()

  const messagesEndRef = useRef(null)

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [commentEditId, setCommentEditId] = useState(null)
  const [isEditCommentModalOpen, setIsEditCommentModalOpen] = useState(false)
  const [commentDeleteId, setCommentDeleteId] = useState(null)
  const [isDeleteCommentModalOpen, setIsDeleteCommentModalOpen] = useState(false)

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView()
    }
  }, [comments])

  const getContent = () => {
    return extractTextContentFromHTML(stateToHTML(editorState.getCurrentContent()))
  }

  const setEditorClear = () => {
    const editorStateaa = EditorState.push(editorState, ContentState.createFromText(''))
    return setEditorState(EditorState.moveFocusToEnd(editorStateaa))
  }

  const sendMessageData = () => {
    const data = {comment: getContent().trim()}
    if (commentEditId) {
      dispatch(updateProjectsNotebookNoteComment({projectId, commentId: commentEditId, data}))
      setCommentEditId(null)
      setIsEditCommentModalOpen(false)
    } else {
      dispatch(createProjectsNotebookNoteComment({projectId, noteId, data}))
    }
    setEditorClear()
  }

  const renderEditComment = () => {
    return (
      <VmoModal
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        size="tiny"
        open={isEditCommentModalOpen}
        onClose={() => setIsEditCommentModalOpen(false)}
      >
        <VmoModalHeader>Edit Comment</VmoModalHeader>
        <VmoModalContent>
          <Editor editorState={editorState} onChange={editorState => setEditorState(editorState)} />
        </VmoModalContent>
        <VmoModalActions>
          <VmoButton
            basic
            onClick={() => {
              setEditorClear()
              setIsEditCommentModalOpen(false)
            }}
          >
            Cancel
          </VmoButton>
          <VmoButton primary onClick={sendMessageData}>
            Update
          </VmoButton>
        </VmoModalActions>
      </VmoModal>
    )
  }

  return (
    <>
      {isEditCommentModalOpen && renderEditComment()}

      <div className="project-notes-chat">
        {comments.map(comment => (
          <div key={comment.id} ref={messagesEndRef}>
            {comment.showDateStamp && (
              <div className="ml-3">
                <strong>{getFormattedDate(comment.createdAt)}</strong>
              </div>
            )}
            <div className="project-discussion-chatting mb-0 pl-3 pt-3">
              <div
                className="project-discussion-userProfile"
                style={{
                  '--user-bg': 'green',
                  width: 24,
                  height: 24,
                  fontSize: 11,
                }}
              >
                {`${get(['createdBy', 'firstName'], comment, '')[0].toUpperCase()}${get(
                  ['createdBy', 'lastName'],
                  comment,
                  ''
                )[0].toUpperCase()}`}
              </div>
              <div className="project-discussion-username-chat">
                <div className="d-flex">
                  <span style={{'--user-bg': 'green'}}>{`${get(['createdBy', 'firstName'], comment)} ${get(
                    ['createdBy', 'lastName'],
                    comment
                  )}`}</span>
                  <span style={{color: 'rgba(0,0,0,0.6)', fontSize: 11}}>{getFormattedTime(comment.createdAt)}</span>
                </div>
                <span>{get(['comment'], comment, '')}</span>
              </div>
              {rolePermissions && (
                <VmoDropdown
                  className="showPrev icon mr-2 mt-3"
                  direction="left"
                  style={{marginLeft: 'auto'}}
                  icon={<MdOutlineMoreVert />}
                >
                  <VmoDropdownMenu>
                    <VmoDropdownItem
                      onClick={() => {
                        const temp = EditorState.createWithContent(
                          ContentState.createFromBlockArray(convertFromHTML(comment.comment))
                        )
                        setEditorState(temp)
                        setCommentEditId(comment.id)
                        setIsEditCommentModalOpen(true)
                      }}
                    >
                      <SvgIcon path="common/edit2" /> Edit
                    </VmoDropdownItem>
                    <VmoDropdownItem
                      onClick={() => {
                        setCommentDeleteId(comment.id)
                        setIsDeleteCommentModalOpen(true)
                      }}
                    >
                      <SvgIcon path="common/delete" /> Delete
                    </VmoDropdownItem>
                  </VmoDropdownMenu>
                </VmoDropdown>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="project-discussion-editor">
        <div className="edtrBody">
          <div>
            <Editor
              placeholder="Type here..."
              editorState={editorState}
              onChange={editorState => setEditorState(editorState)}
              readOnly={!rolePermissions}
            />
          </div>
          <div className="sendMsgBtn">
            {rolePermissions ? (
              <VmoButton className="mr-0 ml-3" primary onClick={sendMessageData}>
                Send
              </VmoButton>
            ) : (
              <VmoTooltip
                content={getPermissionTooltipContent({roleAccess: rolePermissions})}
                position="bottom right"
                size="mini"
                trigger={
                  <VmoButton className={`mr-0 ml-3 ${!rolePermissions ? 'disabled-button' : ''}`} primary>
                    Send
                  </VmoButton>
                }
              />
            )}
          </div>
        </div>
      </div>

      {isDeleteCommentModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteCommentModalOpen}
          onDelete={() => dispatch(deleteProjectsNotebookNoteComment({projectId, commentId: commentDeleteId}))}
          setIsModalOpen={setIsDeleteCommentModalOpen}
          type={DELETE_PROJECTS_NOTEBOOK_NOTE_COMMENT}
        />
      )}
    </>
  )
}

export default Comments
