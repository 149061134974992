import Joi from 'joi'

import {inputNumberValidation, inputStringValidation} from 'validation'

export const createEmployeesSettingSchema = Joi.object({
  department: inputStringValidation.required(),
  color: Joi.string().allow('', null),
  headOfDepartment: inputNumberValidation.required(),
})

export const settingsSchema = Joi.object({
  employeeEditing: Joi.boolean(),
  workingHours: Joi.string().valid('36H/W', '38H/W', '40H/W'),
})

export const employeesFormSchema = Joi.object({
  employeeID: inputStringValidation.label('Employee id').required(),
  firstName: inputStringValidation.required(),
  lastName: inputStringValidation.allow('', null),
  title: Joi.string().trim().min(2).max(60).allow('', null).messages({
    'string.min': `Length must me at least 2 charactes`,
    'string.max': `Length must be less than or equal to 60 characters`,
  }),
  workEmail: Joi.string()
    .trim()
    .email({tlds: {allow: false}})
    .required()
    .messages({
      'string.empty': `Email is not allowed to be empty`,
      'any.required': `Email is a required field`,
      'string.email': `Email must be a valid email`,
      'any.validate': `Email cannot be an empty field`,
    }),
  workPhone: Joi.string()
    .allow('', null)
    .trim()
    .max(20)
    .regex(/^\+?[0-9]{4,20}$/)
    .messages({
      'string.pattern.base': 'Enter a valid phone number',
      'string.empty': `Phone is not allowed to be empty`,
    }),
  workMobileNo: Joi.string()
    .allow('', null)
    .trim()
    .max(20)
    .regex(/^\+?[0-9]{4,20}$/)
    .messages({
      'string.pattern.base': 'Enter a valid phone number',
      'string.empty': `Phone is not allowed to be empty`,
    }),
  workAddressId: inputNumberValidation.allow(null),
  managerId: inputNumberValidation.allow(null),
  departmentId: inputNumberValidation.label('Department id').required(),
  joiningDate: Joi.date().allow(null),
  labelIds: Joi.array().items(Joi.number()).allow(null),
  offTimeApprover: inputNumberValidation.allow(null),
  expenseApprover: inputNumberValidation.allow(null),
  employmentType: Joi.string().allow('', null),
  employmentStatus: Joi.string().allow('', null),
  leavingDate: Joi.date().allow(null),
  allowPortalAccess: Joi.boolean(),
  personalInfo: Joi.object().keys({
    identityType: Joi.string().allow('', null),
    identityNumber: Joi.string().trim().min(4).max(20).allow('', null).messages({
      'string.min': `Length must me at least 4 charactes`,
      'string.max': `Length must be less than or equal to 20 characters`,
    }),
    address: Joi.string().max(200).allow('', null).messages({
      'string.max': `Length must be less than or equal to 200 characters`,
    }),
    city: Joi.string().max(60).allow('', null).messages({
      'string.max': `Length must be less than or equal to 60 characters`,
    }),
    country: Joi.string().allow('', null),
    email: Joi.string()
      .allow('', null)
      .trim()
      .email({tlds: {allow: false}})
      .messages({
        'string.empty': `Email is not allowed to be empty`,
        'any.required': `Email is a required field`,
        'string.email': `Email must be a valid email`,
        'any.validate': `Email cannot be an empty field`,
      }),
    phone: Joi.string()
      .allow('', null)
      .trim()
      .max(20)
      .regex(/^\+?[0-9]{4,20}$/)
      .messages({
        'string.pattern.base': 'Enter a valid phone number',
        'string.empty': `Phone is not allowed to be empty`,
      }),
    highestQualification: Joi.string().max(60).allow('', null).messages({
      'string.max': `Length must be less than or equal to 60 characters`,
    }),
    educationalField: Joi.string().max(60).allow('', null).messages({
      'string.max': `Length must be less than or equal to 60 characters`,
    }),
    university: Joi.string().max(60).allow('', null).messages({
      'string.max': `Length must be less than or equal to 60 characters`,
    }),
    dateOfBirth: Joi.date().allow(null),
  }),
  payrollInfo: Joi.object().keys({
    salaryEffectiveDate: Joi.date().iso().allow(null),
    salary: Joi.number().allow(null),
    salaryPeriod: Joi.string().valid('perYear', 'perMonth', 'perHour').allow('', null),
    paymentMethod: Joi.string().valid('bank', 'cheque', 'cash').allow('', null),
    bankName: Joi.string().max(60).allow('', null).messages({
      'string.max': `Length must be less than or equal to 60 characters`,
    }),
    bankBranch: Joi.string().max(60).allow('', null).messages({
      'string.max': `Length must be less than or equal to 60 characters`,
    }),
    bankAccountNumber: Joi.string().allow('', null),
    code: Joi.string().max(20).allow('', null).messages({
      'string.max': `Length must be less than or equal to 20 characters`,
    }),
  }),
})
