import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiList} from 'react-icons/fi'
import {
  VmoButton,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTabPane,
  VmoTooltip,
} from 'vmo-library'
import {DELETE_VENDORS_CATEGORY, EDIT_VENDORS_CATEGORY, GET_VENDORS_CATEGORY} from 'actions/types'
import {deleteVendorsCategory, getVendorsCategory} from 'actions/vendors'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create.svg'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {roleAccessDeniedContent} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import VendorCategoryModal from './CategoryModal'

const Category = () => {
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)
  const [categoryModal, setCategoryModal] = useState(false)
  const [categoryId, setCategoryId] = useState()

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {financePermissions} = useUserPermissions()
  const manageVendorSettingsPermissions = financePermissions?.manage_vendor_settings

  const {isLoading} = useApiLoader({label: GET_VENDORS_CATEGORY})

  const {data: getVendorsCategoryData = {}} = useApiResponse({
    action: getVendorsCategory,
    label: GET_VENDORS_CATEGORY,
    enabled: true,
    storePath: 'vendors.getVendorsCategoryData',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_VENDORS_CATEGORY) || successLabels.includes(EDIT_VENDORS_CATEGORY)) {
      dispatch(getVendorsCategory())
    }
  }, [successLabels, dispatch])

  const categoryProps = {
    open: categoryModal,
    toggleModal: setCategoryModal,
    categoryToggle: setCategoryId,
  }
  if (categoryId) {
    categoryProps.editId = categoryId
  }

  const actionButton = () => {
    return getVendorsCategoryData && !getVendorsCategoryData.length
      ? null
      : {
          success: {
            content: 'Add Category',
            onClick: () => {
              if (manageVendorSettingsPermissions) {
                setCategoryId()
                setCategoryModal(true)
              }
            },
          },
        }
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <SettingLayout
      header="Category Fields"
      subHeader="Manage category fields to organise your vendors."
      table
      actionButton={actionButton()}
      headerDivider
      lockRole={!manageVendorSettingsPermissions}
    >
      {getVendorsCategoryData && !getVendorsCategoryData.length ? (
        <div className="mt-4">
          {categoryModal ? <VendorCategoryModal {...categoryProps} /> : null}
          <CreateFirst
            src={<FiList />}
            header="Category"
            subHeader="Diversifying assets helps reduce the chance of loss of capital. Investment in debt instruments assures fixed return and safety of capital, having this as part of your portfolio ensures capital preservation."
            addFirst={() => {
              if (manageVendorSettingsPermissions) {
                setCategoryId()
                setCategoryModal(true)
              }
            }}
            tooltip={!manageVendorSettingsPermissions}
            tooltipContent={getPermissionTooltipContent({
              roleAccess: manageVendorSettingsPermissions,
            })}
            buttonText="Add Category"
            Feature1="Organize your assets better"
            Feature2="Keep track and reporting according to the category"
            Feature3="Assets prevention is better"
            list1="Sorting assets according to their properties help organize it better and helps in tracking if number of assets are more"
            list2="Veemo support features detailed analytics with respect to the properties of the assets so it helps the company in making decision based on statistics"
            list3="Managing assets in veemo support will help in case of asset theft or missing asset situations and hence is more reliable"
            imageSrc={create}
          />
        </div>
      ) : (
        <VmoTabPane attached={false}>
          {deleteModal && (
            <DeleteModal
              idTobeDeleted={categoryId}
              deleteAction={deleteVendorsCategory}
              isModalOpen={deleteModal}
              setIsModalOpen={setDeleteModal}
              modalText="Are you sure you want to delete this Category?"
              type={DELETE_VENDORS_CATEGORY}
            />
          )}
          {categoryModal ? <VendorCategoryModal {...categoryProps} /> : null}
          <VmoTable basic selectable>
            <VmoTableHeader>
              <VmoTableRow>
                <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
                <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                <VmoTableHeaderCell>Action</VmoTableHeaderCell>
              </VmoTableRow>
            </VmoTableHeader>
            <VmoTableBody>
              {isLoadingData && type.includes(GET_VENDORS_CATEGORY) ? (
                <VmoTablePlaceholder columns={3} rows={3} />
              ) : (
                getVendorsCategoryData &&
                getVendorsCategoryData.map(({category, id}, index) => (
                  <VmoTableRow
                    className="tableLink"
                    onClick={() => {
                      setCategoryModal(true)
                      setCategoryId(id)
                    }}
                    key={id}
                  >
                    <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
                    <VmoTableCell>
                      <VmoHeader as="h5">{startCase(category)}</VmoHeader>
                    </VmoTableCell>
                    <VmoTableCell>
                      <VmoTooltip
                        content={!manageVendorSettingsPermissions ? roleAccessDeniedContent : 'Delete'}
                        size="mini"
                        position="top center"
                        trigger={
                          <VmoButton
                            icon
                            onClick={e => {
                              if (manageVendorSettingsPermissions) {
                                setCategoryId(id)
                                setDeleteModal(true)
                              }
                              e.stopPropagation()
                            }}
                          >
                            <SvgIcon path="common/delete" />
                          </VmoButton>
                        }
                      />
                    </VmoTableCell>
                  </VmoTableRow>
                ))
              )}
            </VmoTableBody>
          </VmoTable>
        </VmoTabPane>
      )}
    </SettingLayout>
  )
}

export default Category
