import {
  GET_CLIENT_PORTAL_SETTINGS,
  GET_CLIENT_PORTAL_ANNOUNCEMENTS,
  GET_CLIENT_PORTAL_SINGLE_ANNOUNCEMENT,
  UPLOAD_CLIENT_PORTAL_LOGO,
  GET_CLIENT_PORTAL_TICKET_FORM,
} from 'actions/types'

const initialState = {
  announcementsList: {
    announcements: [],
  },
}

export default function (state = initialState, action) {
  const {type, payload} = action

  switch (type) {
    case GET_CLIENT_PORTAL_ANNOUNCEMENTS:
      return {
        ...state,
        announcementsList: payload || initialState.announcementsList,
      }

    case GET_CLIENT_PORTAL_SINGLE_ANNOUNCEMENT:
      return {...state, announcementData: payload}

    case GET_CLIENT_PORTAL_SETTINGS:
      return {
        ...state,
        generalSetting: payload.generalSetting || {},
        isClientPortalCreated: payload.isClientPortalCreated || false,
      }

    case UPLOAD_CLIENT_PORTAL_LOGO:
      return {...state, logo: payload}

    case GET_CLIENT_PORTAL_TICKET_FORM:
      return {...state, ticketForm: payload}

    default:
      return state
  }
}
