import {
  DELETE_TASK_AUTOMATION,
  DELETE_TASK_TEMPLATE,
  GET_ALL_TASK_AUTOMATIONS,
  GET_ALL_TASK_TEMPLATES,
  GET_TASK_AUTOMATION,
  GET_TASK_TEMPLATE,
} from 'actions/types'
import {get} from 'utils/helper'

export default function (state = {}, action) {
  switch (action.type) {
    // Task Templates--------------------->

    case GET_ALL_TASK_TEMPLATES:
      return {
        ...state,
        taskTemplateList: action.payload,
      }
    case GET_TASK_TEMPLATE:
      return {
        ...state,
        taskTemplate: action.payload,
      }

    case DELETE_TASK_TEMPLATE:
      return {
        ...state,
        taskTemplateList: get(['taskTemplateList'], state)
          ? state.taskTemplateList.filter(temp => temp.id !== action.payload)
          : get(['taskTemplateList'], state),
      }

    case GET_ALL_TASK_AUTOMATIONS:
      return {
        ...state,
        taskAutomationList: action.payload,
      }
    case GET_TASK_AUTOMATION:
      return {
        ...state,
        taskAutomation: action.payload,
      }

    case DELETE_TASK_AUTOMATION:
      return {
        ...state,
        taskAutomationList:
          action.status === 200 && state.taskAutomationList
            ? state.taskAutomationList.filter(automation => automation.id !== action.payload)
            : state.taskAutomationList,
      }

    default:
      return state
  }
}
