import React from 'react'
import {useParams} from 'react-router'
import TemplateForm from './TemplateForm'

const TemplateDetails = props => {
  const {id} = useParams()

  return <TemplateForm editId={id} {...props} />
}

export default TemplateDetails
