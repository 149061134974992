import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {Controller} from 'react-hook-form'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

function Description({setToggleModal, toggleModal, activeSection, index, control, data}) {
  return (
    <VmoModal
      open={toggleModal === 'description'}
      onClose={() => setToggleModal(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      centered={false}
    >
      <VmoModalHeader className="vmoModalHeader">Edit Description</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Description</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].label`}
              control={control}
              defaultValue={data?.label}
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  placeholder="Enter Text"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  onBlur={onBlur}
                  //   error={
                  //     _.get(errors, `fields[${index}].label`) && {
                  //       content: removeDoubleQuotes(_.get(errors, `fields[${index}].label.message`)),
                  //     }
                  //   }
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton onClick={() => setToggleModal(false)}>Cancel</VmoButton>
        <VmoButton primary onClick={() => setToggleModal(false)}>
          Ok
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default Description
