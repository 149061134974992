export const maxOfGraph = data => {
  if (!data) return 10
  let max = data.reduce((acc, el) => {
    const max2 = el.data.reduce((accu, el2) => (+el2.y > accu ? +el2.y : accu), 0)

    return max2 > acc ? max2 : acc
  }, 0)

  if (max > 10) {
    const str = Math.ceil(max).toString()

    max = Number(Number(str.slice(0, 1)) + 1 + new Array(str.length).join('0'))
  } else if (max > 5) max = 10
  else max = 5

  return max
}

export const getGridYValues = data => {
  if (!data) return [0, 2, 4, 6, 8, 10]
  const max = maxOfGraph(data)
  const slice = Math.ceil(max / 5)
  return [0, 1, 2, 3, 4, 5].map(grid => slice * grid)
}

export const maxOfGraphBar = (data, keyValue) => {
  if (!data) return 10
  let max = data.reduce((acc, el) => {
    return +el[keyValue] > acc ? +el[keyValue] : acc
  }, 0)

  if (max > 10) {
    const str = Math.ceil(max).toString()

    max = Number(Number(str.slice(0, 1)) + 1 + new Array(str.length).join('0'))
  } else if (max > 5) max = 10
  else max = 5
  return max
}

export const getGridYValuesBar = (data, keyValue) => {
  if (!data) return [0, 2, 4, 6, 8, 10]
  const max = maxOfGraphBar(data, keyValue)
  const slice = Math.ceil(max / 5)
  return [0, 1, 2, 3, 4, 5].map(grid => slice * grid)
}

export const maxOfGraphMultipleBar = (data, keys) => {
  if (!data || !keys) return 10

  let max = data.reduce((acc, el) => {
    const max = keys.reduce((acc2, val) => el[val] + acc2, 0)
    return max > acc ? max : acc
  }, 0)

  if (max > 10) {
    const str = Math.ceil(max).toString()

    max = Number(Number(str.slice(0, 1)) + 1 + new Array(str.length).join('0'))
  } else if (max > 5) max = 10
  else max = 5

  return max
}

export const getGridYValuesMultipleBar = (data, keys) => {
  if (!data) return [0, 2, 4, 6, 8, 10]
  const max = maxOfGraphMultipleBar(data, keys)
  const slice = Math.ceil(max / 5)
  return [0, 1, 2, 3, 4, 5].map(grid => slice * grid)
}
