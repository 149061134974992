import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoRadio,
  VmoTabPane,
} from 'vmo-library'
import {sampleMail} from 'actions/meeting'
import {MEETING_SAMPLE_MAIL} from 'actions/types'
import {sendSampleEmail} from 'validation/Sales/meeting.schema'
import SvgIcon from 'components/common/SvgIcon'

const SampleMailModal = ({isModalOpen, setIsModalOpen}) => {
  const dispatch = useDispatch()
  const {isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const {errors, control, handleSubmit} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    defaultValues: {email: ''},
    resolver: joiResolver(sendSampleEmail),
  })

  const SendMailBtn = data => {
    dispatch(sampleMail(data))
  }
  useEffect(() => {
    if (successLabels.includes(MEETING_SAMPLE_MAIL)) {
      setIsModalOpen()
    }
  }, [successLabels, setIsModalOpen])

  return (
    <VmoModal
      open={isModalOpen}
      size="tiny"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={setIsModalOpen}
    >
      <VmoModalHeader>Add Your Email</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Add Email</label>
            <Controller
              name="email"
              control={control}
              render={({onChange, value}) => (
                <VmoFormInput
                  onChange={(e, {value}) => onChange(value)}
                  value={value}
                  fluid
                  error={
                    errors.email && {
                      content: errors.email.message,
                    }
                  }
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={setIsModalOpen}>
          Cancel
        </VmoButton>
        <VmoButton
          primary
          type="submit"
          loading={isLoadingData}
          disabled={isLoadingData}
          onClick={handleSubmit(SendMailBtn)}
        >
          Send Mail
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

const Notification = props => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {control, Controller, style} = props

  return (
    <VmoTabPane style={style}>
      <VmoContainer>
        <VmoCard fluid>
          <VmoCardContent>
            <h3 className="mb-0">Email Notification</h3>
            <p className="mt-0 mb-4 kadence-lead">Manage all settings related to email notification</p>
            <VmoForm className="errorLabel">
              <VmoFormField width={12}>
                <VmoCard fluid className="switchBox">
                  <VmoCardContent>
                    <div className="d-flex">
                      <div>
                        <h5>
                          Send confirmation emails to prospects and customer immediately after they book meeting through
                          this link.
                        </h5>
                        <p>
                          <VmoButton basic onClick={() => setIsModalOpen(true)} className="mr-2">
                            Send test email
                          </VmoButton>
                          Test email will be sent.
                        </p>
                      </div>
                      <Controller
                        name="mailNotification"
                        render={({onChange, value}) => (
                          <VmoRadio
                            toggle
                            style={{float: 'right'}}
                            onChange={(e, {checked}) => onChange(checked)}
                            checked={value}
                          />
                        )}
                        control={control}
                      />
                    </div>
                  </VmoCardContent>

                  <VmoCardContent>
                    <div className="d-flex">
                      <div>
                        <h5>
                          Send pre-meeting reminder emails to prospect and customer who book meeting through this link.
                        </h5>
                      </div>
                      <Controller
                        name="mailReminder"
                        render={({onChange, value}) => (
                          <VmoRadio
                            toggle
                            style={{float: 'right'}}
                            onChange={(e, {checked}) => onChange(checked)}
                            checked={value}
                          />
                        )}
                        control={control}
                      />
                    </div>
                  </VmoCardContent>
                </VmoCard>
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
      </VmoContainer>
      {isModalOpen && <SampleMailModal setIsModalOpen={() => setIsModalOpen(!isModalOpen)} isModalOpen={isModalOpen} />}
    </VmoTabPane>
  )
}

export default Notification
