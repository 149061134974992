import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {VmoModal, VmoModalHeader, VmoModalContent, VmoInput, VmoButton, VmoModalActions} from 'vmo-library'
import {updateSocial} from 'actions/multiple_fields'
import {startCase} from 'utils/helper'

const EditSocialModal = props => {
  const {isModalOpen, setIsModalOpen, selectedSocial, id, identifier} = props

  const dispatch = useDispatch()

  const [url, setUrl] = useState('')

  useEffect(() => {
    if (selectedSocial) {
      setUrl(selectedSocial.url)
    }
  }, [selectedSocial])

  return (
    <VmoModal size="mini" open={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)}>
      <VmoModalHeader>Edit {startCase(selectedSocial.category)}</VmoModalHeader>
      <VmoModalContent>
        <VmoInput
          iconPosition="left"
          icon={selectedSocial.category}
          // label={startCase(selectedSocial.category)}
          value={url}
          onChange={e => setUrl(e.target.value)}
        />
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic color="light" onClick={() => setIsModalOpen(false)}>
          Cancel
        </VmoButton>
        <VmoButton
          color="blue"
          disabled={(url || '').trim() === ''}
          onClick={() => {
            const data = {url, category: selectedSocial.category}

            dispatch(updateSocial(id, data, selectedSocial.id, identifier))
            setIsModalOpen(!isModalOpen)
          }}
        >
          Save
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default EditSocialModal
