import {apiAction} from './api'
import {KADENCE_ANALYTICS} from './endpoint'
import {CHANNEL_DISTRIBUTION, INBOX_ACTIVITIES, INFLOW_BY_CHANNEL_ACROSS_TIME} from './types'

export function inflowByChannelAcrossTime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/inflowbychannelacrosstime`,
    method: 'POST',
    label: INFLOW_BY_CHANNEL_ACROSS_TIME,
    data,
  })
}

export function channelDistribution(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/channeldistributionoverpriorities`,
    method: 'POST',
    label: CHANNEL_DISTRIBUTION,
    data,
  })
}

export function inboxActivities(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/sla/inboxactivitiestable`,
    method: 'POST',
    label: INBOX_ACTIVITIES,
    data,
  })
}
