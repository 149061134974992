import {GET_PROJECTS_BUDGETS_LIST} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action
  switch (type) {
    case GET_PROJECTS_BUDGETS_LIST:
      return {...state, projectsBudgetsList: payload || []}

    default:
      return state
  }
}
