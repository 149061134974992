import {
  GET_USER,
  GET_USERS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_PASSWORD,
  GET_TEMPLATE,
  GET_SINGLE_TEMPLATE,
} from 'actions/types'
import {setAppToken} from 'utils/local-storage'

export default function (state = {}, action) {
  switch (action.type) {
    case UPDATE_PROFILE_PASSWORD:
      setAppToken(action.payload.token)
      return state

    case 'UPDATE_AVATAR':
      return {
        ...state,
        updateAvatarData: action.payload.avatar,
      }

    case UPDATE_PROFILE:
      return {
        ...state,
        getUserData: action.payload,
      }

    case GET_USERS:
      return {...state, getUsersList: action.payload}

    case GET_USER:
      return {...state, getUserData: action.payload}

    case GET_TEMPLATE:
      return {...state, getResponseList: action.payload}

    case GET_SINGLE_TEMPLATE: {
      return {
        ...state,
        singleResponse: action.payload,
      }
    }

    default:
      return state
  }
}
