import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {VmoChipInput, VmoTooltip} from 'vmo-library'

import {CREATE_TAG} from 'actions/types'
import {createTagSettings} from 'actions/multiple_fields'
import {get, getPermissionTooltipContent} from 'utils/helper'
import CreateLabel from 'components/common/CreateLabel'
import KadenceCustomDropdown from 'components/common/KadenceCustomDropdown'
import SvgIcon from './SvgIcon'

const Tags = props => {
  const dispatch = useDispatch()
  const {id, tagsUpdate, data, control, Controller, errors, getValues, rolePermissions = true} = props
  const {labels} = data
  const [newLabelModal, setNewLabelModal] = useState(false)
  const [listOfLable, setListOfLabel] = useState([])
  const [labelOptionsState, setLabelOptionsState] = useState([])
  const getTagList = useSelector(state => {
    return get(['multipleFields', 'getTagList'], state)
  })
  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (labels) {
      const formated = labels.map(label => ({
        value: label.id,
        text: label.label,
        color: label.color,
      }))
      setListOfLabel(formated || [])
    } else {
      setListOfLabel([])
    }
  }, [labels])

  useEffect(() => {
    if (getTagList) {
      const newData = getTagList.map(label => ({
        value: label.id,
        key: label.id,
        text: label.label,
        label: {
          style: {background: label.color},
          empty: true,
          circular: true,
        },
      }))
      setLabelOptionsState(newData)
    }
  }, [getTagList])

  const createNewLabel = () => {
    if (newLabelModal) {
      return (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
        />
      )
    }
  }
  useEffect(() => {
    if (successLabels.includes(CREATE_TAG)) {
      setNewLabelModal(false)
    }
  }, [successLabels])
  return (
    <div>
      <VmoChipInput
        defaultValue={listOfLable.map(tag => {
          return {
            id: tag.value,
            text: (
              <>
                <div
                  key={tag.value}
                  style={{
                    height: '10px',
                    width: '10px',
                    background: tag.color,
                    borderRadius: '50%',
                    marginRight: '10px',
                    display: 'inline-block',
                  }}
                />
                {tag.text}
              </>
            ),
          }
        })}
        onChange={value => {
          if (rolePermissions) {
            const valueIds = value.map(tag => tag.id)
            dispatch(tagsUpdate({labelIds: valueIds}, id))
          }
        }}
        hideInputField
      />
      <Controller
        control={control}
        name="labelIds"
        render={({value, onChange}) => (
          <KadenceCustomDropdown
            options={labelOptionsState}
            multiple
            dropdownProps={{
              icon: (
                <VmoTooltip
                  content={rolePermissions ? 'Tags' : getPermissionTooltipContent({roleAccess: rolePermissions})}
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/tag" id="TooltipTagInbox" />}
                />
              ),
              direction: 'right',
              closeOnChange: false,
              className: 'tags-dropdown-customer mt-3',
            }}
            menu={{scrolling: true}}
            input={{
              icon: 'search',
              iconPosition: 'left',
              className: 'search',
            }}
            activeIndicator
            item={{disabled: !rolePermissions}}
            value={listOfLable.map(labels => labels.value)}
            onClick={value => {
              if (!rolePermissions) return
              onChange(value)
              if (value !== get(['labelIds'], data) && !errors.labelIds) {
                const data = getValues()
                dispatch(tagsUpdate(_.pick(data, 'labelIds'), id))
              }
            }}
            extraAction={{
              content: '+ Add Tag',
              onClick: () => rolePermissions && setNewLabelModal(true),
              disabled: !rolePermissions,
            }}
          />
        )}
      />
      {createNewLabel()}
    </div>
  )
}

export default Tags
