import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import TimeOff from 'components/pages/HumanResourse/TimeOff/TimeOff'
import TimeOffTypesList from 'components/pages/HumanResourse/TimeOff/Settings/TimeOffTypes/TimeOffTypesList'

const routes = [
  {path: '/time-off/all', component: TimeOff, title: 'All TimeOff', exact: true},
  {path: '/time-off/types', component: TimeOffTypesList, title: 'TimeOff', exact: false},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} />
        <Component {...props} />
      </>
    )}
  />
)

const TimeOffRoutes = () => (
  <Switch>
    <Route exact path="/time-off">
      <Redirect to="/time-off/all" />
    </Route>
    {routes.map(renderRoute)}
    <Route path="/time-off/*">
      <Redirect to="/time-off/all" />
    </Route>
  </Switch>
)

export default TimeOffRoutes
