import React from 'react'
import {FaUserLock} from 'react-icons/fa'
import {VmoTooltip} from 'vmo-library'
import {roleAccessDeniedContent} from 'constants/variables'

const LockRole = () => {
  return <VmoTooltip content={roleAccessDeniedContent} position="top center" size="mini" trigger={<FaUserLock />} />
}

export default LockRole
