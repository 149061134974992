import {apiAction} from './api'
import {KADENCE_PROJECTS} from './endpoint'
import {GET_PROJECTS_BUDGETS_LIST} from './types'

export function getProjectsBudtesList(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${KADENCE_PROJECTS}/budgets?${query}`,
    method: 'GET',
    label: GET_PROJECTS_BUDGETS_LIST,
  })
}
