import React from 'react'
import {Controller} from 'react-hook-form'
import _ from 'lodash'
import {VmoButton, VmoDropdown, VmoFormField, VmoFormInput, VmoIcon, VmoTooltip} from 'vmo-library'
import SvgIcon from './SvgIcon'

const PhoneCreateMultiple = props => {
  const {phoneFields, errors, appendPhone, control, removePhone} = props
  return phoneFields.map((phoneItem, index) => (
    <div className="mutiple">
      <VmoFormField key={index} className={index !== 0 && 'mt-3'}>
        <div className="hover-plusicon d-flex">
          <Controller
            name={`phones[${index}].category`}
            defaultValue={phoneItem.category}
            render={({onChange, value, onBlur}) => (
              <div className="mailActivity">
                <VmoDropdown
                  className="email-component"
                  icon="chevron down"
                  button
                  options={[
                    {value: 'work', text: 'Work Phone', key: 'work'},
                    {
                      value: 'mobile',
                      text: 'Mobile Phone',
                      key: 'mobile',
                    },
                    {value: 'other', text: 'Other Phone', key: 'other'},
                  ]}
                  onBlur={onBlur}
                  placeholder="Select category"
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  error={
                    _.get(errors, `phones[${index}].category`) && {
                      content: _.get(errors, `phones[${index}].category.message`),
                    }
                  }
                />
              </div>
            )}
            control={control}
          />

          <div className="d-flexb">
            {_.size(phoneFields) > 1 && (
              <div className="ShowAddBtn">
                <VmoButton
                  style={{padding: '6px'}}
                  onClick={() => {
                    removePhone(index)
                  }}
                >
                  <VmoTooltip
                    content="Delete Field"
                    size="mini"
                    position="left center"
                    trigger={<VmoIcon className="m-0" name="trash alternate outline" />}
                  />
                </VmoButton>
              </div>
            )}
            {index === phoneFields.length - 1 && _.size(phoneFields) < 3 && (
              <div className="ShowAddBtn">
                <VmoButton
                  style={{padding: '4px'}}
                  onClick={() => {
                    appendPhone({
                      category: phoneItem.category,
                      phone: '',
                    })
                  }}
                >
                  <VmoTooltip
                    content="Add Phone no"
                    size="mini"
                    position="left center"
                    trigger={<SvgIcon path="common/plus" />}
                  />
                </VmoButton>
              </div>
            )}
          </div>
        </div>
        <Controller
          name={`phones[${index}].phone`}
          defaultValue={phoneItem.phone}
          render={({onChange, value}) => (
            <VmoFormInput
              type="text"
              maxLength={20}
              fluid
              value={value}
              placeholder="Phone"
              onChange={e => {
                onChange(e.target.value)
              }}
              icon={
                <i style={{padding: '12px'}} className="ShowAddBtn link icon">
                  <SvgIcon path="common/edit2" />
                </i>
              }
              error={
                _.get(errors, `phones[${index}].phone`) && {
                  content: 'Invalid Phone Number',
                }
              }
            />
          )}
          control={control}
        />
      </VmoFormField>
    </div>
  ))
}

export default PhoneCreateMultiple
