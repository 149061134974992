import {KADENCE_SETTING, KADENCE_ATTACHMENTS, DOCS} from './endpoint'
import {
  ATTACHMENT_POST,
  CREATE_TAG,
  GET_CAMPAIGN_TEMPLATE_LIST,
  GET_CANNED_RESPONSE_LIST,
  GET_TAGS,
  GET_TRAIL_LOGS,
} from './types'
import {apiAction} from './api'

export function getTags() {
  return apiAction({
    url: `${KADENCE_SETTING}/labels`,
    method: 'GET',
    label: GET_TAGS,
  })
}

export function createTagSettings(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/labels`,
    method: 'POST',
    label: CREATE_TAG,
    data,
    showToast: true,
    successMessage: 'Tag Created',
  })
}

export function getCannedResposeList() {
  return apiAction({
    url: `${KADENCE_SETTING}/cannedResponses`,
    method: 'GET',
    label: GET_CANNED_RESPONSE_LIST,
  })
}

export function uploadAttachments(data, successData, cancelToken) {
  return apiAction({
    url: KADENCE_ATTACHMENTS,
    method: 'POST',
    label: ATTACHMENT_POST,
    data,
    contentType: 'multipart/form-data',
    successData,
    cancelToken,
  })
}

export function getCampaignTemplateList() {
  return apiAction({
    url: `${KADENCE_SETTING}/email-templates`,
    method: 'GET',
    label: GET_CAMPAIGN_TEMPLATE_LIST,
  })
}

export function getTrailLogs(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/audit-logs`,
    method: 'POST',
    label: GET_TRAIL_LOGS,
    data,
  })
}
