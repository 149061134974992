import React, {useEffect, useState} from 'react'
import {useHistory, useRouteMatch} from 'react-router'
import {useSelector} from 'react-redux'
import {
  VmoButton,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {DELETE_TEAMS, GET_TEAMS_LIST} from 'actions/types'
import {deleteTeam, getAllTeams} from 'actions/settings_organisation'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create-user.svg'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import TeamsDetails from './TeamsDetails'

const Teams = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  const [isTeamOpen, setIsTeamOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState(0)

  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const teamsLearnMore = settingsLearnMoreUrl?.myOrganization?.teams?.learnMore

  const {isLoading} = useApiLoader({label: GET_TEAMS_LIST})
  const {myOrganizationDefaultPermissions} = useUserPermissions()

  const {data: teamsList = {}, callAction} = useApiResponse({
    action: getAllTeams,
    enabled: true,
    storePath: 'settingsOrganisation.teamsList',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_TEAMS)) {
      callAction()
    }
  }, [successLabels, callAction])

  if (isLoading) {
    return <PageLoader />
  }

  if (isTeamOpen) {
    return <TeamsDetails isTeamOpen={isTeamOpen} toggleModal={setIsTeamOpen} />
  }

  if (!teamsList.length) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="Teams"
          subHeader="Teams are groups of users who work together on specific projects or tasks"
          addFirst={() => myOrganizationDefaultPermissions && setIsTeamOpen(true)}
          buttonText="Add Teams"
          tooltip={!myOrganizationDefaultPermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})}
          imageSrc={create}
          Feature1="Team Members"
          list1="Add users in your teams for more productivity"
          Feature2="Project Collaboration"
          list2="Assign teams to ensure right user is involved"
          Feature3="Team management"
          list3="Managing and editing teams in one click"
          isTeamOpen={isTeamOpen}
        />
      </div>
    )
  }

  const renderTableBody = () => {
    if (type.includes(GET_TEAMS_LIST)) {
      return <VmoTablePlaceholder columns={5} />
    }
    return teamsList.map(({id, name, description, users}, index) => (
      <VmoTableRow key={id} className="tableLink" onClick={() => history.push(`${url}/${id}`)}>
        <VmoTableCell>{index + 1}</VmoTableCell>
        <VmoTableCell>
          <VmoHeader as="h5">{startCase(name)}</VmoHeader>
        </VmoTableCell>
        <VmoTableCell>{description?.slice(0, 20) || '-'}</VmoTableCell>
        <VmoTableCell>
          {users.map((user, i) => (
            <div key={i}>{user}</div>
          ))}
        </VmoTableCell>
        <VmoTableCell>
          <VmoTooltip
            size="mini"
            position="top center"
            content={
              myOrganizationDefaultPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})
            }
            trigger={
              <VmoButton
                basic
                circular
                icon
                className="tableLink-delete-icon"
                onClick={e => {
                  e.stopPropagation()
                  if (myOrganizationDefaultPermissions) {
                    setDeleteModal(true)
                    setIdToDelete(id)
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </VmoButton>
            }
          />
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/teams" />}
      header="Teams"
      subHeader="Segregate group of users by create multiple teams"
      learnMoreUrl={teamsLearnMore}
      headerButton={{
        onClick: () => myOrganizationDefaultPermissions && setIsTeamOpen(true),
        content: 'Add',
      }}
      table
      headerDivider
      lockRole={!myOrganizationDefaultPermissions}
    >
      <VmoTable basic selectable>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Team</VmoTableHeaderCell>
            <VmoTableHeaderCell>Description</VmoTableHeaderCell>
            <VmoTableHeaderCell>Assigned Users</VmoTableHeaderCell>
            <VmoTableHeaderCell>Action</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>{renderTableBody()}</VmoTableBody>
      </VmoTable>

      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToDelete}
          deleteAction={deleteTeam}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete team?"
          type={DELETE_TEAMS}
        />
      )}
    </SettingLayout>
  )
}

export default Teams
