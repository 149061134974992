import React from 'react'
import {VmoModal, VmoModalHeader, VmoModalContent, VmoModalDescription, VmoModalActions, VmoButton} from 'vmo-library'

const LogoutModal = props => {
  const {tokenModal, setTokenModal} = props

  return (
    <VmoModal
      size="tiny"
      onClose={() => {
        setTokenModal(false)
      }}
      open={tokenModal}
    >
      <VmoModalHeader>Session Expired</VmoModalHeader>
      <VmoModalContent>
        <VmoModalDescription>
          <p>Token is expired and you are logged out</p>
        </VmoModalDescription>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton
          content="OK"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            setTokenModal(false)
          }}
          primary
        />
      </VmoModalActions>
    </VmoModal>
  )
}

export default LogoutModal
