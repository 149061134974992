import Joi from 'joi'

export const chatPages = Joi.object({
  pageBgColor: Joi.object().required(),
  pageCompanyUrl: Joi.string()
    .trim()
    .allow('')
    .regex(
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    )
    .messages({
      'string.pattern.base': 'Enter a valid URL',
    }),
  pageHeader: Joi.string().trim().allow('').max(25).label('page header').messages({
    'string.max': `Length must be less than or equal to 25 characters`,
  }),
  pageWelcomeMsg: Joi.string().trim().allow('').max(25).label('page welcome message').messages({
    'string.max': `Length must be less than or equal to 25 characters`,
  }),
  pageMetaTitle: Joi.string().trim().allow('').max(25).label('page meta title').messages({
    'string.max': `Length must be less than or equal to 25 characters`,
  }),
  pageMetaDescription: Joi.string().trim().allow('').max(150).label('page meta description').messages({
    'string.max': `Length must be less than or equal to 150 characters`,
  }),
})
