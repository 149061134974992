import Joi from 'joi'

export const taskListSchema = Joi.object({
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  // workflowId: Joi.number().required().messages({
  //   'number.base': 'Workflow must be specified',
  // }),
  subscribers: Joi.array().min(1).required(),
})

export const taskSchema = Joi.object({
  type: Joi.string().required().messages({
    'string.empty': 'Type must be specified',
  }),
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  description: Joi.string().trim().allow('').max(200).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
  estimatedHrs: Joi.number().integer().default(0).label('Hour').messages({
    'number.base': 'Hour must be specified',
  }),
  estimatedMin: Joi.number().integer().default(0).label('Minute').messages({
    'number.base': 'Minute must be specified',
  }),
  startDate: Joi.date().required().messages({
    'date.base': 'Please enter valid date',
  }),
  endDate: Joi.date().min(Joi.ref('startDate')).required().messages({
    'date.base': 'Please enter valid date',
    'date.min': 'Expiry date must be greater then start date',
  }),
  stageId: Joi.number().required().messages({
    'number.base': `Stage must be specified`,
  }),
  labelIds: Joi.array().items(Joi.number()),
  assignedTo: Joi.number().required().messages({
    'number.base': 'Assignees must be specified',
  }),
})

export const viewTaskSchema = Joi.object({
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  description: Joi.string().trim().allow('').max(200).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
  estimatedHrs: Joi.number().integer().default(0).label('Hour').messages({
    'number.base': 'Hour must be specified',
  }),
  estimatedMin: Joi.number().integer().default(0).label('Minute').messages({
    'number.base': 'Minute must be specified',
  }),
  startDate: Joi.date().required().messages({
    'date.base': 'Please enter valid date',
  }),
  endDate: Joi.date().min(Joi.ref('startDate')).required().messages({
    'date.base': 'Please enter valid date',
    'date.min': 'Expiry date must be greater then start date',
  }),
  stageId: Joi.number().required().messages({
    'number.base': `Stage must be specified`,
  }),
  labelIds: Joi.array().items(Joi.number()),
  assignedTo: Joi.number().required().messages({
    'number.base': 'Assignees must be specified',
  }),
})

export const copyTaskSchema = Joi.object({
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  projectId: Joi.number().required().messages({
    'number.base': 'Project must be specified',
  }),
  tasklistId: Joi.number().required().messages({
    'number.base': 'Task list must be specified',
  }),
  stageId: Joi.number().required().messages({
    'number.base': 'Stage must be specified',
  }),
})

export const moveTaskSchema = Joi.object({
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  projectId: Joi.number().required().messages({
    'number.base': 'Project must be specified',
  }),
  tasklistId: Joi.number().required().messages({
    'number.base': 'Task list must be specified',
  }),
})
