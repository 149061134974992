import Joi from 'joi'
import {inputNumberValidation, inputStringValidation, textAreaValidation} from 'validation'

export const createTaskSchema = Joi.object({
  subject: inputStringValidation.min(2).required().messages({
    'string.empty': `Subject is not allowded to be empty`,
    'string.min': `Subject should have a minimum length of 2 characters`,
    'string.max': `Subject should have a maximum length of 20 characters or less`,
    'any.required': `Subject is a required field`,
  }),
  type: Joi.string().required(),
  status: Joi.string().required(),
  startAt: Joi.date().timestamp().allow(''),
  endAt: Joi.when('type', {
    is: 'activity',
    then: Joi.date().timestamp().required(),
    otherwise: Joi.date().timestamp().min(Joi.ref('startAt')).required().messages({
      'date.min': `must be greater than or equal to start Time`,
    }),
  }),
  priority: Joi.string().required(),
  assigneeId: Joi.number().allow('unass').required(),
  description: textAreaValidation.allow('').max(200),
  callType: Joi.string(),
  subTasks: Joi.any(),
  taskFor: Joi.string(),
  taskForId: Joi.number().allow(null),
})

export const editTaskSchema = Joi.object({
  subject: inputStringValidation.required(),
  status: Joi.string().required(),
  endAt: Joi.date().required(),
  priority: Joi.string().required(),
  assigneeId: inputNumberValidation.allow(null, 'unass').required(),
  description: textAreaValidation.allow(''),
  callType: Joi.string(),
  startAt: Joi.date().allow(''),
  subTasks: Joi.array().optional(),
})

export const taskEnteriesSchema = Joi.object({
  title: Joi.string().trim().min(2).max(50).messages({
    'string.empty': `Title is not allowed to be empty`,
    'string.min': `Title length must be atleast 2 characters long`,
    'string.max': `Title length must be less than 20 characters`,
  }),
  duration: Joi.number().required().messages({
    'number.base': `Number is not allowed to be empty`,
  }),
})
