import Joi from 'joi'

export const EmailTemplateFormSchema = Joi.object({
  title: Joi.string().trim().max(30).required().label('Response Title'),
  subject: Joi.string().trim().max(30).required().label('Subject'),
  body: Joi.string().empty(''),
  applyTo: Joi.string(),
  inboxIds: Joi.array().allow(null),
  placeholders: Joi.array(),
  attachmentIds: Joi.array().items(Joi.number()),
  attachments: Joi.array(),
})
