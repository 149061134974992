import React from 'react'
import {useSelector} from 'react-redux'
import {
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
} from 'vmo-library'
import {GET_PROJECTS_HEALTH_LIST} from 'actions/types'
import {get, getFormattedDate, startCase} from 'utils/helper'

const ProjectHealthList = ({projectsHealthList = [], onProjectClick}) => {
  const {type = []} = useSelector(state => state.apiReducer)

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_HEALTH_LIST)) {
      return <VmoTablePlaceholder columns={6} />
    }
    if (!type.includes(GET_PROJECTS_HEALTH_LIST) && !projectsHealthList.length) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="6" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return projectsHealthList.map(project => (
      <VmoTableRow className="tableLink" key={project.id} onClick={() => onProjectClick(project.id)}>
        <VmoTableCell>
          <VmoHeader as="h5">{startCase(project.title)}</VmoHeader>
        </VmoTableCell>
        <VmoTableCell>
          {get(['contactDetails'], project, []).map(contact => (
            <div>{contact}</div>
          ))}
        </VmoTableCell>
        <VmoTableCell>{project.managerName}</VmoTableCell>
        <VmoTableCell>
          {getFormattedDate(project.startDate)} - {getFormattedDate(project.endDate)}
        </VmoTableCell>
        <VmoTableCell>
          <div className="project-task-completion-cell">
            <div
              className="progress"
              style={{width: `${get(['taskCompletion', 'completedPercentage'], project, 0)}}%`}}
            />
            <div className="content">
              <span>{get(['taskCompletion', 'completedPercentage'], project, 0)}%</span>
              <span>{get(['taskCompletion', 'taskLeft'], project, '')}</span>
            </div>
          </div>
        </VmoTableCell>
        <VmoTableCell>
          {project.isBudgetCreated ? (
            <div style={{width: '130px'}}>
              <h5 className="mb-1">{get(['budget', 'usedPercentage'], project, 0).toFixed(2)}%</h5>
              <div className="budget-progress">
                <div
                  className="budget-progress-completed"
                  style={{width: `${get(['budget', 'usedPercentage'], project, 0).toFixed(2)}%`}}
                />
              </div>
              <p className="mt-1">
                ${get(['budget', 'usedAmount'], project, 0)} 0f ${get(['budget', 'budgetAmount'], project, 0)}
              </p>
            </div>
          ) : (
            '-'
          )}
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <VmoTable basic>
      <VmoTableHeader>
        <VmoTableRow>
          <VmoTableHeaderCell>Name</VmoTableHeaderCell>
          <VmoTableHeaderCell>Contacts</VmoTableHeaderCell>
          <VmoTableHeaderCell>Owner</VmoTableHeaderCell>
          <VmoTableHeaderCell>Dates</VmoTableHeaderCell>
          <VmoTableHeaderCell>Task Completion</VmoTableHeaderCell>
          <VmoTableHeaderCell>Budget</VmoTableHeaderCell>
        </VmoTableRow>
      </VmoTableHeader>
      <VmoTableBody>{renderTableBody()}</VmoTableBody>
    </VmoTable>
  )
}

export default ProjectHealthList
