import {emailValidation, inputNumberValidation, inputPhoneValidation, inputStringValidation} from 'validation'
import joi from 'joi'

export const createCompanySchema = joi.object().keys({
  name: inputStringValidation.required(),
  domain: joi.string().domain().allow('', null).optional(),
  emails: joi
    .array()
    .items(
      joi.object({
        category: joi.string().allow('').required(),
        email: emailValidation.allow('').required(),
      })
    )
    .allow(null)
    .optional(),
  phones: joi
    .array()
    .items(
      joi.object({
        category: joi.string().allow('').required(),
        phone: inputPhoneValidation.allow('').required(),
      })
    )
    .allow(null)
    .optional(),
  websites: joi
    .array()
    .items(
      joi.object({
        category: joi.string().allow('').required(),
        url: joi.string().uri().allow('').required(),
      })
    )
    .allow(null)
    .optional(),
  address: joi
    .object({
      streetAddress: joi.string().allow('', null),
      locality: joi.string().allow('', null),
      state: joi.string().allow('', null),
      postalCode: joi.string().allow(null, '').label('Postal code'),
      country: joi.string().allow('', null),
    })
    .allow(null)
    .optional(),
  contactTypeId: joi.number().allow(null).optional(),
  assigneeId: joi.number().allow(null).optional(),
  socials: joi
    .array()
    .items(
      joi.object({
        category: joi.string().allow('').required(),
        url: joi.string().uri().allow('').required(),
      })
    )
    .allow(null)
    .optional(),
  description: joi.string().allow('', null).optional(),
  labelIds: joi.array().items(joi.number()).label('Tags'),
})

export const multipleUpdateCompanySchema = joi.object().keys({
  emails: joi
    .array()
    .items(
      joi.object({
        category: joi.string().allow('').required(),
        email: emailValidation.allow('').required(),
      })
    )
    .allow(null)
    .optional(),
  phones: joi
    .array()
    .items(
      joi.object({
        category: joi.string().allow('').required(),
        phone: inputPhoneValidation.allow('').required(),
      })
    )
    .allow(null)
    .optional(),
  websites: joi
    .array()
    .items(
      joi.object({
        category: joi.string().allow('').required(),
        url: joi.string().uri().allow('').required(),
      })
    )
    .allow(null)
    .optional(),
  address: joi
    .object({
      streetAddress: joi.string(),
      locality: joi.string(),
      state: joi.string(),
      postalCode: joi.string().allow(null, '').label('Postal code'),
      country: joi.string(),
    })
    .allow(null)
    .optional(),
  contactTypeId: joi.number().allow(null).optional(),
  assigneeId: joi.number().allow(null).optional(),
  description: joi.string().allow('', null).optional(),
  labelIds: joi.array().items(joi.number()).label('Tags'),
})

export const updateCompanySchema = joi.object().keys({
  name: inputStringValidation,
  domain: joi.string().domain().allow('', null),
  emails: joi
    .array()
    .items(
      joi.object({
        category: joi.string().allow('').required(),
        email: emailValidation.allow('').required(),
      })
    )
    .allow(null)
    .optional(),
  phones: joi
    .array()
    .items(
      joi.object({
        category: joi.string().allow('').required(),
        phone: inputPhoneValidation.allow('').required(),
      })
    )
    .allow(null)
    .optional(),
  websites: joi
    .array()
    .items(
      joi.object({
        category: joi.string().allow('').required(),
        url: joi.string().uri().allow('').required(),
      })
    )
    .allow(null)
    .optional(),
  address: joi.object({
    streetAddress: joi.any().allow('', null),
    locality: joi.any().allow('', null),
    state: joi.string().allow('', null),
    country: joi.string().allow('', null),
    postalCode: joi.string().allow(null, '').label('Postal code'),
  }),
  contactTypeId: joi.number().allow(null),
  assigneeId: joi.number().allow(null),
  socials: joi
    .array()
    .items(
      joi.object({
        category: joi.string().allow(''),
        url: joi.string().uri().allow(''),
      })
    )
    .allow(null)
    .optional(),
  description: joi.string().allow('', null).optional(),
  labelIds: joi.array().items(joi.number()),
})

export const filterCompanySchema = joi.object().keys({
  assigneeId: inputNumberValidation.allow('', null).label('Owner'),
  labelIds: joi.number().allow('', null).label('Tags'),
  isFollowed: joi.string().allow('', null),
  interactionsFrom: joi.number().min(0).allow('', null, 0),
  interactionsTo: joi.number().min(0).allow('', null, 0),
  lastContactedFrom: joi.date().iso().allow(null),
  lastContactedTo: joi.date().iso().allow(null),
  inactiveDaysFrom: joi.number().min(0).allow('', null, 0),
  inactiveDaysTo: joi.number().min(0).allow('', null, 0),
  createdAtFrom: joi.date().iso().allow(null),
  createdAtTo: joi.date().iso().allow(null),
  contactTypeId: joi.number().allow('', null),
  locality: joi.any().allow('', null).label('Locality'),
  state: joi.string().allow('', null).label('State'),
  country: joi.string().allow('', null).label('Country'),
  postalCode: joi.string().allow(null, '').label('Postal code'),
})
