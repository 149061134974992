import {KADENCE_ANALYTICS} from './endpoint'
import {apiAction} from './api'
import {
  EMPLOYEES_STATS,
  EMPLOYEES_BY_STATUS,
  EMPLOYEES_BY_TYPE,
  EMPLOYEES_BY_DEPARTMENT,
  EMPLOYEES_TIME_TREND,
  NEW_EMPLOYEE_BY_DEPARTMENT,
} from './types'

export function employeesStats(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/hr/employees/count-stats`,
    method: 'POST',
    label: EMPLOYEES_STATS,
    data,
  })
}

export function employeesByStatus(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/hr/employees/count-by-status`,
    method: 'POST',
    label: EMPLOYEES_BY_STATUS,
    data,
  })
}

export function employeesByType(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/hr/employees/count-by-type`,
    method: 'POST',
    label: EMPLOYEES_BY_TYPE,
    data,
  })
}

export function employeesByDepartment(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/hr/employees/count-by-department`,
    method: 'POST',
    label: EMPLOYEES_BY_DEPARTMENT,
    data,
  })
}

export function EmployeeTimetrend(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/hr/employees/status-timetrend`,
    method: 'POST',
    label: EMPLOYEES_TIME_TREND,
    data,
  })
}

export function newEmployeeByDepartment(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/hr/employees/new-employee-by-department`,
    method: 'POST',
    label: NEW_EMPLOYEE_BY_DEPARTMENT,
    data,
  })
}
