import {ADD_GMAIL_INBOX, ADD_INBOX} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case ADD_INBOX:
      return {
        ...state,
        newInboxData: action.payload,
      }

    case ADD_GMAIL_INBOX:
      return {
        ...state,
        newGmailInboxData: action.payload,
      }

    default:
      return state
  }
}
