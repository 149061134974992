/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {FiFileText} from 'react-icons/fi'
import {MdEmail} from 'react-icons/md'
import _ from 'lodash'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoHeader,
  VmoHeaderSubheader,
  VmoLabel,
  VmoMenu,
  VmoMenuItem,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {
  DELETE_CAMPAIGN,
  DELETE_SMS_CAMPAIGN,
  GET_CAMPAIGN_LIST,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_STATUS,
  UPDATE_SMS_CAMPAIGN_STATUS,
} from 'actions/types'
import {
  deleteCampaign,
  deleteSmsCampaign,
  getCampaignList,
  updateCampaignStatus,
  updateSmsCampaignStatus,
} from 'actions/campaign'
import {get, GetFormattedDate, startCase} from 'utils/helper'
import create from 'assets/create-user.svg'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'

const Campaign = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isStatusPopupOpen, setStatusPopupOpen] = useState(false)
  const [statusActivation, setStatusActivation] = useState(false)
  const [statusToEdited, setStatusToEdited] = useState(0)
  const [statusToEditType, setStatusToEditType] = useState('1')
  const [isSmsCampaign, setIsSmsCampaign] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState(0)

  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {campaignList = []} = useSelector(state => state.campaign)

  const {isLoading} = useApiLoader({label: GET_CAMPAIGN_LIST})
  const {salesAndCrmAccess} = useUserPermissions()
  const campaignsAccess = salesAndCrmAccess?.campaigns

  useEffect(() => {
    if (campaignsAccess) {
      dispatch(getCampaignList())
    }
  }, [campaignsAccess, dispatch])

  useEffect(() => {
    if (
      successLabels.includes(DELETE_CAMPAIGN) ||
      successLabels.includes(UPDATE_CAMPAIGN) ||
      successLabels.includes(UPDATE_CAMPAIGN_STATUS) ||
      successLabels.includes(UPDATE_SMS_CAMPAIGN_STATUS) ||
      successLabels.includes(DELETE_SMS_CAMPAIGN)
    ) {
      dispatch(getCampaignList())
    }
  }, [dispatch, successLabels])

  const RenderDropdown = ({style}) => {
    return (
      <div className="mailActivity">
        <VmoDropdown icon="chevron down" style={style} button text="Create Campaign">
          <VmoDropdownMenu className="creCampgDd">
            <LockPermissionTooltip isFeatureAccessDenied={!campaignsAccess}>
              <VmoDropdownItem onClick={() => campaignsAccess && history.push(`/campaigns/message`)}>
                <div className="cmpIcon">
                  <SvgIcon path="settings/sms" />
                </div>
                <div className="cmpContent">
                  <h5>Message Campaign</h5>
                  <p className="kadence-lead">Send messages that drive people towards specific goals</p>
                </div>
              </VmoDropdownItem>
            </LockPermissionTooltip>

            <LockPermissionTooltip isFeatureAccessDenied={!campaignsAccess}>
              <VmoDropdownItem onClick={() => campaignsAccess && history.push(`/campaigns/email`)}>
                <div className="cmpIcon">
                  <MdEmail />
                </div>
                <div className="cmpContent">
                  <h5>Email Campaign</h5>
                  <p className="kadence-lead">Deliver high-context emails served hot, not cold</p>
                </div>
              </VmoDropdownItem>
            </LockPermissionTooltip>

            <LockPermissionTooltip isFeatureAccessDenied={!campaignsAccess}>
              <VmoDropdownItem onClick={() => campaignsAccess && history.push(`/campaigns/sms`)}>
                <div className="cmpIcon">
                  <SvgIcon path="settings/sms" />
                </div>
                <div className="cmpContent">
                  <h5>SMS Campaign</h5>
                  <p className="kadence-lead">Send SMS that drive people towards specific goals</p>
                </div>
              </VmoDropdownItem>
            </LockPermissionTooltip>
          </VmoDropdownMenu>
        </VmoDropdown>
      </div>
    )
  }

  if (isLoading && campaignsAccess) {
    return <PageLoader />
  }

  if (!campaignList.length) {
    return (
      <CreateFirst
        header="Campaigns"
        subHeader="Create professional captivating marketing campaigns to bring more leads and improve conversion rate"
        imageSrc={create}
        hideButton
        dropdown={<RenderDropdown style={{minWidth: '166px'}} />}
        Feature1="Schedule campaigns"
        list1="Schedule campaign to sent it at optimal time"
        Feature2="Campaign Templates"
        list2="Build professionally campaign template for more conversion rate"
        Feature3="Targeted Marketing"
        list3="Create targeted campaigns to reach specific audiences"
      />
    )
  }

  const renderCampaignList = () => {
    if (isLoadingData && type.includes(GET_CAMPAIGN_LIST)) {
      return <VmoTablePlaceholder columns={9} rows={7} />
    }
    if (!isLoadingData && campaignList.length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="9" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return campaignList.map((list, index) => (
      <VmoTableRow
        className="tableLink"
        key={`${list?.id}${index}`.toString()}
        onClick={() => {
          if (list.type === '1') {
            return history.push(`campaigns/message/${list.id}`)
          }
          if (list.type === '3') {
            return history.push(`campaigns/email/${list.id}`)
          }
          if (list.type === 'sms_campaign') {
            return history.push(`campaigns/sms/${list.id}`)
          }
        }}
      >
        <VmoTableCell>{index + 1}</VmoTableCell>
        <VmoTableCell>
          <VmoHeader as="h5">{startCase(list.name)}</VmoHeader>
        </VmoTableCell>
        <VmoTableCell>
          {list.type === '1' && (
            <VmoLabel color="blue" className="kadence-label" style={{fontSize: '11px'}}>
              MESSAGE
            </VmoLabel>
          )}
          {list.type === '3' && (
            <VmoLabel color="yellow" className="kadence-label" style={{fontSize: '11px'}}>
              Email
            </VmoLabel>
          )}
          {list.type === 'sms_campaign' && (
            <VmoLabel color="green" className="kadence-label" style={{fontSize: '11px'}}>
              SMS
            </VmoLabel>
          )}
        </VmoTableCell>
        <VmoTableCell>{get(['sent'], list, 0)}</VmoTableCell>
        <VmoTableCell>{get(['read'], list, 0)}</VmoTableCell>
        <VmoTableCell>{get(['reply'], list, 0)}</VmoTableCell>
        <VmoTableCell>
          <VmoHeader className="camplistHeader" as="h5">
            {list.user}
            <VmoHeaderSubheader>
              {get(['createdAt'], list) ? GetFormattedDate(get(['createdAt'], list)) : '-'}
            </VmoHeaderSubheader>
          </VmoHeader>
        </VmoTableCell>
        <VmoTableCell>
          <VmoCheckbox
            toggle
            checked={get(['status'], list)}
            onChange={(e, value) => {
              e.preventDefault()
              e.stopPropagation()
              setStatusPopupOpen(true)
              setStatusToEditType(list.type)
              setStatusToEdited(list.id)
              setStatusActivation(value.checked)
            }}
          />
        </VmoTableCell>
        <VmoTableCell>
          <VmoTooltip
            content="Delete"
            position="top center"
            size="mini"
            trigger={
              <VmoButton
                basic
                icon
                className="tableLink-delete-icon"
                onClick={e => {
                  setIsDelete(true)
                  if (list.type === 'sms_campaign') {
                    setIsSmsCampaign(true)
                  } else {
                    setIsSmsCampaign(false)
                  }
                  setUserIdToDelete(list.id)
                  e.stopPropagation()
                }}
              >
                <SvgIcon path="common/delete" />
              </VmoButton>
            }
          />
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <>
      <VmoMenu vertical secondary className="kadence-menu">
        <VmoMenuItem
          className="liveView"
          onClick={() => history.push('/campaigns')}
          active={window.location.pathname === '/campaigns'}
        >
          <SvgIcon path="settings/users" /> All Campaign
        </VmoMenuItem>
      </VmoMenu>

      <div className="kadence-side-wrap">
        <div className="page-header">
          <div className="page-title">
            <div className="page-titleIcon">
              <FiFileText />
              <h5>Campaigns ({_.size(campaignList) !== 0 && _.size(campaignList)})</h5>
            </div>
          </div>
          <div className="page-action">
            <RenderDropdown />
          </div>
        </div>

        <VmoCard fluid className="mt-0">
          <VmoCardContent className="p-0">
            <VmoTable basic selectable className="mt-0">
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell>#</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Type</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Sent</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Seen</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Reply</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Created by</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Active/In-Active</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Action</VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <VmoTableBody>{renderCampaignList()}</VmoTableBody>
            </VmoTable>
          </VmoCardContent>
        </VmoCard>

        {isStatusPopupOpen && (
          <VmoModal
            closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
            size="tiny"
            centered={false}
            open={isStatusPopupOpen}
            onClose={() => setStatusPopupOpen(false)}
          >
            <VmoModalHeader className="vmoModalHeader">Change Status</VmoModalHeader>
            <VmoModalContent>Are you sure you want change status ?</VmoModalContent>
            <VmoModalActions>
              <VmoButton basic onClick={() => setStatusPopupOpen(false)}>
                Cancel
              </VmoButton>
              <VmoButton
                primary
                onClick={() => {
                  if (statusToEditType === '1' || statusToEditType === '3') {
                    dispatch(
                      updateCampaignStatus(statusToEdited, {
                        status: statusActivation,
                      })
                    )
                    setStatusPopupOpen(false)
                  }
                  if (statusToEditType === 'sms_campaign') {
                    dispatch(
                      updateSmsCampaignStatus(statusToEdited, {
                        status: statusActivation,
                      })
                    )
                    setStatusPopupOpen(false)
                  }
                }}
              >
                Confirm
              </VmoButton>
            </VmoModalActions>
          </VmoModal>
        )}

        {isDelete && (
          <DeleteModal
            isModalOpen={isDelete}
            onDelete={() =>
              isSmsCampaign ? dispatch(deleteSmsCampaign(userIdToDelete)) : dispatch(deleteCampaign(userIdToDelete))
            }
            setIsModalOpen={setIsDelete}
            modalText="Are you sure you want to delete campaign?"
          />
        )}
      </div>
    </>
  )
}

export default Campaign
