import {
  CAMPAIGN_ATTACHMENT_UPLOAD,
  GET_CAMPAIGN_DATA,
  GET_CAMPAIGN_FILTERS,
  GET_CAMPAIGN_LIST,
  GET_LABEL_TAGS,
  GET_SINGLE_SMS_CAMPAIGN,
} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case GET_CAMPAIGN_FILTERS:
      return {...state, campaignFilters: payload}

    case GET_CAMPAIGN_LIST:
      return {...state, campaignList: payload || []}

    case GET_CAMPAIGN_DATA: {
      return {...state, campaignData: payload}
    }

    case CAMPAIGN_ATTACHMENT_UPLOAD: {
      return {
        ...state,
        attachment: {
          mediaName: payload.mediaName,
          mediaSize: payload.mediaSize,
          mediaUrl: payload.mediaUrl,
          mime: payload.mime,
        },
      }
    }

    case 'CLEAR_CAMPAIGN_ATTACHMENT_UPLOAD': {
      return {
        ...state,
        attachment: null,
      }
    }

    case GET_SINGLE_SMS_CAMPAIGN:
      return {...state, smsCampaignData: payload}

    case GET_LABEL_TAGS:
      return {...state, tagLabelList: payload}

    default:
      return state
  }
}
