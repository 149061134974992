import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {VmoButton, VmoModal, VmoModalActions, VmoModalContent, VmoModalHeader} from 'vmo-library'

import {STATUS_PROJECTS_TASKS_LIST_TASK} from 'actions/types'
import {statusProjectsTasksListTask} from 'actions/projects'
import SvgIcon from 'components/common/SvgIcon'

const StatusTaskModal = ({open, toggle, taskStatus, projectId, taskId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(STATUS_PROJECTS_TASKS_LIST_TASK)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = () => {
    let data
    if (taskStatus === 'active') {
      data = {status: 'archived'}
    } else {
      data = {status: 'active'}
    }
    dispatch(statusProjectsTasksListTask({projectId, taskId, data}))
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>Confirm</VmoModalHeader>
      <VmoModalContent>{`Are you sure you want to ${
        taskStatus === 'active' ? 'archive' : 'unarchive'
      } the task and its subtasks?`}</VmoModalContent>
      <VmoModalContent>
        {`Note: You can always access the ${
          taskStatus === 'active' ? 'archived' : 'active'
        } tasks using filters and also ${taskStatus === 'active' ? 'unarchive' : 'archive'} them.`}
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={submitForm}>
          Yes
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default StatusTaskModal
