import Joi from 'joi'

export const facebookSchema = Joi.object({
  assignees: Joi.array(),
  inbox: {
    name: Joi.string().max(30).required().label('Inbox Name').messages({
      'string.max': `Length must be less than or equal to 30 characters`,
    }),
  },
  permissions: {
    posts: Joi.boolean(),
    messages: Joi.boolean(),
  },
  threadingInterval: Joi.number().allow(null),
})
