import {
  GET_GENERAL_SETTING_ORGANIZATION,
  GET_TEAMS_LIST,
  GET_TEAM_DATA,
  GET_ALL_ADDRESSESS,
  GET_ADDRESS_DATA,
} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action
  switch (type) {
    case GET_TEAMS_LIST:
      return {
        ...state,
        teamsList: payload,
      }

    case GET_TEAM_DATA:
      return {
        ...state,
        teamsData: payload,
      }

    case GET_ADDRESS_DATA:
      return {
        ...state,
        addressData: payload,
      }

    case 'CLEAR_ADDRESS_DATA':
      return {
        ...state,
        addressData: {},
      }

    case GET_ALL_ADDRESSESS:
      return {
        ...state,
        allAddressess: payload,
      }

    case 'CLEAR_TEAM_DATA':
      return {
        ...state,
        teamsData: null,
      }

    case GET_GENERAL_SETTING_ORGANIZATION:
      return {...state, getAccountsSettingsData: payload}

    default:
      return state
  }
}
