import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router'
import {FiCalendar, FiChevronRight} from 'react-icons/fi'
import {VmoAccordion, VmoAccordionContent, VmoAccordionTitle, VmoMenu, VmoMenuItem} from 'vmo-library'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import PermissionIcon from 'components/common/PermissionIcon'
import SvgIcon from 'components/common/SvgIcon'
import {useRouteMatch} from 'react-router-dom'
import {isActiveNavlink} from 'utils/helper'

const ReportsMenu = () => {
  const history = useHistory()
  const location = useLocation()
  const {url} = useRouteMatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const [expand, setExpand] = useState([])
  // const [activeIndex, setActiveIndex] = useState({
  //   conversations: true,
  //   clients: false,
  //   performance: false,
  //   campaigns: false,
  //   customerSupport: false,
  //   sales: false,
  //   finance: false,
  //   projects: false,
  //   hr: false,
  // })

  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const emailTrackingAccess = analyticsAccess?.emailTracking
  const dealConversionsAccess = analyticsAccess?.dealConversions
  const forecastingAccess = analyticsAccess?.forecasting
  const productsAccess = analyticsAccess?.products
  const quotesAccess = analyticsAccess?.quotes
  const metricsAccess = analyticsAccess?.metrics
  const usersAccess = analyticsAccess?.users
  const tasksAccess = analyticsAccess?.tasks
  const slaAccess = analyticsAccess?.sla
  const campaignsAccess = analyticsAccess?.campaigns
  const productivityAccess = analyticsAccess?.productivity
  const financeAccess = analyticsAccess?.finance
  const projectsAccess = analyticsAccess?.projects

  const conversationsReportsPermissions = reportsPermissions?.conversations_reports
  const customersReportsPermissions = reportsPermissions?.customers_reports
  const dealsReportsPermissions = reportsPermissions?.deals_reports
  const revenueReportsPermissions = reportsPermissions?.revenue_reports
  const performanceReportsPermissions = reportsPermissions?.performance_reports
  const campaignReportsPermissions = reportsPermissions?.campaign_reports
  const productivityReportsPermissions = reportsPermissions?.productivity_reports
  const financeReportsPermissions = reportsPermissions?.finance_reports
  const projectsReportsPermissions = reportsPermissions?.projects_reports

  const menus = [
    {
      key: 'conversations',
      title: 'Conversations',
      items: [
        {
          path: '/overview',
          title: 'Overview',
          icon: <SvgIcon path="analytics/reports/overview" />,
          isRoleAccessDenied: !conversationsReportsPermissions,
        },
        {
          path: '/inbox',
          title: 'Inbox',
          icon: <SvgIcon path="analytics/reports/inbox" />,
          isRoleAccessDenied: !conversationsReportsPermissions,
        },
        {
          path: '/channels',
          title: 'Channels',
          icon: <SvgIcon path="analytics/reports/channels" />,
          isRoleAccessDenied: !conversationsReportsPermissions,
        },
        {
          path: '/tags',
          title: 'Tags',
          icon: <SvgIcon path="analytics/reports/tags" />,
          isRoleAccessDenied: !conversationsReportsPermissions,
        },
        {
          path: '/emailtracking',
          title: 'Email Tracking',
          icon: <SvgIcon path="analytics/reports/email-tracking" />,
          isRoleAccessDenied: !conversationsReportsPermissions,
          isFeatureAccessDenied: !emailTrackingAccess,
        },
      ],
    },
    {
      key: 'clients',
      title: 'Clients',
      items: [
        {
          path: '/contacts',
          title: 'Contacts',
          icon: <SvgIcon path="analytics/reports/contacts" />,
          isRoleAccessDenied: !customersReportsPermissions,
        },
        {
          path: '/company',
          title: 'Companies',
          icon: <SvgIcon path="analytics/reports/companies" />,
          isRoleAccessDenied: !customersReportsPermissions,
        },
        /*      {
          path: '/visitors',
          title: 'Visitors',
          icon: <SvgIcon path="analytics/reports/visitors" />,
          isRoleAccessDenied: !customersReportsPermissions,
        }, */
      ],
    },
    {
      key: 'sales',
      title: 'Sales',
      items: [
        {
          path: '/deals',
          title: 'Deals',
          icon: <SvgIcon path="analytics/reports/deals" />,
          isRoleAccessDenied: !dealsReportsPermissions,
        },
        {
          path: '/conversion',
          title: 'Conversion',
          icon: <SvgIcon path="analytics/reports/conversion" />,
          isRoleAccessDenied: !dealsReportsPermissions,
          isFeatureAccessDenied: !dealConversionsAccess,
        },
        {
          path: '/forecast',
          title: 'Forecast',
          icon: <SvgIcon path="analytics/reports/forecast" />,
          isRoleAccessDenied: !revenueReportsPermissions,
          isFeatureAccessDenied: !forecastingAccess,
        },
        {
          path: '/products',
          title: 'Products',
          icon: <SvgIcon path="analytics/reports/products" />,
          isRoleAccessDenied: !revenueReportsPermissions,
          isFeatureAccessDenied: !productsAccess,
        },
        {
          path: '/quotes',
          title: 'Quotes',
          icon: <SvgIcon path="analytics/reports/quotes" />,
          isRoleAccessDenied: !revenueReportsPermissions,
          isFeatureAccessDenied: !quotesAccess,
        },
      ],
    },
    {
      key: 'projects',
      title: 'Projects',
      items: [
        {
          path: '/project-health',
          title: 'Project Health',
          icon: <SvgIcon path="analytics/reports/project-health" />,
        },
        {
          path: '/project-timesheet',
          title: 'Time Sheet by Projects',
          icon: <SvgIcon path="analytics/reports/time-sheet-projects" />,
        },
        {
          path: '/project-timesheet-users',
          title: 'Time Sheet by Users',
          icon: <SvgIcon path="analytics/reports/time-sheet-users" />,
        },
        {
          path: '/project-task-users',
          title: 'Task by Users',
          icon: <SvgIcon path="analytics/reports/task-users" />,
        },
        {
          path: '/resource-utilization',
          title: 'Resource Utilization',
          icon: <SvgIcon path="analytics/reports/resource-utilization" />,
        },
        /*        {
          path: '/projects',
          title: 'Projects',
          icon: <SvgIcon path="analytics/reports/projects" />,
          isFeatureAccessDenied: !projectsAccess,
          isRoleAccessDenied: !projectsReportsPermissions,
        },
        {
          path: '/resources',
          title: 'Resources',
          icon: <SvgIcon path="analytics/reports/resources" />,
          isFeatureAccessDenied: !projectsAccess,
          isRoleAccessDenied: !projectsReportsPermissions,
        }, */
      ],
    },
    {
      key: 'finance',
      title: 'Finance',
      items: [
        {
          path: '/vendors',
          title: 'Vendors',
          icon: <SvgIcon path="analytics/reports/vendor" />,
          isFeatureAccessDenied: !financeAccess,
          isRoleAccessDenied: !financeReportsPermissions,
        },
        {
          path: '/purchases',
          title: 'Purchases',
          icon: <SvgIcon path="analytics/reports/purchases" />,
          isFeatureAccessDenied: !financeAccess,
          isRoleAccessDenied: !financeReportsPermissions,
        },
        {
          path: '/purchase-orders',
          title: 'Purchase Orders',
          icon: <SvgIcon path="analytics/reports/purchase-orders" />,
          isFeatureAccessDenied: !financeAccess,
          isRoleAccessDenied: !financeReportsPermissions,
        },
        {
          path: '/sales-orders',
          title: 'Sales Orders',
          icon: <SvgIcon path="analytics/reports/sales-orders" />,
          isFeatureAccessDenied: !financeAccess,
          isRoleAccessDenied: !financeReportsPermissions,
        },
        {
          path: '/invoices',
          title: 'Invoices',
          icon: <SvgIcon path="analytics/reports/invoice" />,
          isFeatureAccessDenied: !financeAccess,
          isRoleAccessDenied: !financeReportsPermissions,
        },
        /*       {
          path: '/budgets-planner',
          title: 'Budgets Planner',
          icon: <SvgIcon path="analytics/reports/budgets-planner" />,
          isFeatureAccessDenied: !financeAccess,
          isRoleAccessDenied: !financeReportsPermissions,
        }, */
      ],
    },
    {
      key: 'performance',
      title: 'Performance',
      items: [
        {
          path: '/metrics',
          title: 'Metrics',
          icon: <SvgIcon path="analytics/reports/metrics" />,
          isFeatureAccessDenied: !metricsAccess,
          isRoleAccessDenied: !performanceReportsPermissions,
        },
        {
          path: '/users',
          title: 'Users',
          icon: <SvgIcon path="analytics/reports/users" />,
          isFeatureAccessDenied: !usersAccess,
          isRoleAccessDenied: !performanceReportsPermissions,
        },
        {
          path: '/sla',
          title: 'SLA',
          icon: <SvgIcon path="analytics/reports/sla" />,
          isFeatureAccessDenied: !slaAccess,
          isRoleAccessDenied: !performanceReportsPermissions,
        },
        {
          path: '/tasks',
          title: 'Task',
          icon: <SvgIcon path="analytics/reports/task" />,
          isFeatureAccessDenied: !tasksAccess,
          isRoleAccessDenied: !performanceReportsPermissions,
        },
        {
          path: '/template',
          title: 'Email Template',
          icon: <SvgIcon path="analytics/reports/email-templates" />,
          isFeatureAccessDenied: !productivityAccess,
          isRoleAccessDenied: !productivityReportsPermissions,
        },
      ],
    },
    {
      key: 'hr',
      title: 'HR',
      items: [
        {
          path: '/employees',
          title: 'Employees',
          icon: <FiCalendar />,
        },
        {
          path: '/timeOff',
          title: 'Time Off',
          icon: <FiCalendar />,
        },
        {
          path: '/expenses',
          title: 'Expenses',
          icon: <FiCalendar />,
        },
      ],
    },
  ]

  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }

  const isActive = key => !expand.includes(key)

  return (
    <>
      <VmoMenu vertical secondary className="kadence-menu scrollbar-setting">
        {menus.map(({key, title, items = []}) => (
          <VmoAccordion key={key}>
            <VmoAccordionTitle onClick={() => handleMenuExpand(key)}>
              <div className="d-flex-ac">
                {isActive(key) ? <SvgIcon path="common/down" /> : <FiChevronRight />} {title}
              </div>
            </VmoAccordionTitle>
            <VmoAccordionContent active={isActive(key)}>
              {items.map(({path, title, icon, isFeatureAccessDenied = false, isRoleAccessDenied = false}) => (
                <VmoMenuItem
                  key={path}
                  active={isActiveNavlink(`${url}${path}`)}
                  onClick={() => history.push(`${url}${path}`)}
                >
                  {icon}
                  {title}
                  {(isFeatureAccessDenied || isRoleAccessDenied) && (
                    <span>
                      <PermissionIcon
                        isFeatureAccessDenied={isFeatureAccessDenied}
                        isRoleAccessDenied={isRoleAccessDenied}
                      />
                    </span>
                  )}
                </VmoMenuItem>
              ))}
            </VmoAccordionContent>
          </VmoAccordion>
        ))}

        {/* <VmoAccordion>
          <VmoAccordionTitle
            onClick={() =>
              setActiveIndex({
                ...activeIndex,
                campaigns: !activeIndex.campaigns,
              })
            }
          >
            <div className="d-flex-ac">
              {activeIndex.campaigns ? <FiChevronDown /> : <FiChevronRight />}
              Campaigns
            </div>
          </VmoAccordionTitle>
          <VmoAccordionContent active={activeIndex.campaigns}>
            <VmoMenuItem
              onClick={() => history.push(`/reports/chat`)}
              active={window.location.pathname.includes(`/reports/chat`)}
            >
              <HiOutlineChatAlt2 /> Chat
              {(!campaignsAccess || !campaignReportsPermissions) && (
                <span>
                  <PermissionIcon
                    isFeatureAccessDenied={!campaignsAccess}
                    isRoleAccessDenied={!campaignReportsPermissions}
                  />
                </span>
              )}
            </VmoMenuItem>
            <VmoMenuItem
              onClick={() => history.push(`/reports/email`)}
              active={window.location.pathname.includes(`/reports/email`)}
            >
              <SvgIcon path="common/mail" /> Email
              {(!campaignsAccess || !campaignReportsPermissions) && (
                <span>
                  <PermissionIcon
                    isFeatureAccessDenied={!campaignsAccess}
                    isRoleAccessDenied={!campaignReportsPermissions}
                  />
                </span>
              )}
            </VmoMenuItem>
            <VmoMenuItem
              onClick={() => history.push(`/reports/sms`)}
              active={window.location.pathname.includes(`/reports/sms`)}
            >
              <RiMessage2Line /> Sms
              {(!campaignsAccess || !campaignReportsPermissions) && (
                <span>
                  <PermissionIcon
                    isFeatureAccessDenied={!campaignsAccess}
                    isRoleAccessDenied={!campaignReportsPermissions}
                  />
                </span>
              )}
            </VmoMenuItem>
          </VmoAccordionContent>
        </VmoAccordion> */}

        {/* <VmoAccordion>
          <VmoAccordionTitle
            onClick={() =>
              setActiveIndex({
                ...activeIndex,
                hr: !activeIndex.hr,
              })
            }
          >
            <div className="d-flex-ac">
              {activeIndex.hr ? <FiChevronDown /> : <FiChevronRight />}
              HR
            </div>
          </VmoAccordionTitle>
          <VmoAccordionContent active={activeIndex.hr}>
            <VmoMenuItem
              onClick={() => history.push(`/reports/employees`)}
              active={window.location.pathname.includes(`/reports/employees`)}
            >
              <FiCalendar /> Employees
            </VmoMenuItem>
            <VmoMenuItem
              onClick={() => history.push(`/reports/timeOff`)}
              active={window.location.pathname.includes(`/reports/timeOff`)}
            >
              <FiCalendar /> Time Off
            </VmoMenuItem>
            <VmoMenuItem
              onClick={() => history.push(`/reports/expenses`)}
              active={window.location.pathname.includes(`/reports/expenses`)}
            >
              <FiCalendar /> Expenses
            </VmoMenuItem>
          </VmoAccordionContent>
        </VmoAccordion> */}
      </VmoMenu>
    </>
  )
}

export default ReportsMenu
