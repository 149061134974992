import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
} from 'vmo-library'
import {GET_SMS_CHANNEL_LIST} from 'actions/types'
import {getSMSList} from 'actions/settings_channels'
import CreateFirst from 'components/common/CreateFirst'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create-user.svg'
import {getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import SmsDetail from './SmsDetail'

const Sms = props => {
  const history = useHistory()

  const [createSms, setCreateSms] = useState(false)

  const {isLoadingData, type = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const smsLearnMore = settingsLearnMoreUrl?.channels?.sms?.learnMore

  const {isLoading} = useApiLoader({label: GET_SMS_CHANNEL_LIST})

  const {channelsPermissions} = useUserPermissions()
  const manageSmsPermissions = channelsPermissions?.manage_sms

  const {data: getSmsChannelList = []} = useApiResponse({
    action: getSMSList,
    enabled: true,
    storePath: 'settingsChannels.getSmsChannelList',
  })

  // Main Components

  if (isLoading) {
    return <PageLoader />
  }
  if (createSms) {
    return <SmsDetail setCreateSms={setCreateSms} {...props} />
  }

  if (getSmsChannelList.length < 1) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="SMS"
          subHeader="Enabling quick and direct communication with customers through text messages"
          addFirst={() => manageSmsPermissions && setCreateSms(true)}
          buttonText="Add SMS"
          tooltip={!manageSmsPermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: manageSmsPermissions})}
          Feature1="Easy to set-up"
          Feature2="Two-Way Communication"
          Feature3="Multiple Channels"
          list1="Simple and easy way to add SMS channels"
          list2="Enable interactive communication between  customers"
          list3="Manage Multiple SMS channel at one place"
          imageSrc={create}
        />
      </div>
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/sms" />}
      header="SMS"
      subHeader="All your created SMS channels"
      learnMoreUrl={smsLearnMore}
      headerButton={{
        onClick: () => manageSmsPermissions && setCreateSms(!createSms),
        content: 'Add',
      }}
      table
      headerDivider
      lockRole={!manageSmsPermissions}
    >
      <VmoTable basic selectable>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Name</VmoTableHeaderCell>
            <VmoTableHeaderCell>Number</VmoTableHeaderCell>
            <VmoTableHeaderCell>Created Date</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>
          {isLoadingData && type.includes(GET_SMS_CHANNEL_LIST) ? (
            <VmoTablePlaceholder columns={4} />
          ) : (
            getSmsChannelList.map((sms, index) => (
              <VmoTableRow key={index} className="tableLink" onClick={() => history.push(`/settings/sms/${sms.id}`)}>
                <VmoTableCell>{index + 1}</VmoTableCell>
                <VmoTableCell>
                  <VmoHeader as="h5">{startCase(sms.name)}</VmoHeader>
                </VmoTableCell>
                <VmoTableCell>{sms.phoneNumber}</VmoTableCell>
                <VmoTableCell>{getFormattedDate(sms.createdAt)}</VmoTableCell>
              </VmoTableRow>
            ))
          )}
        </VmoTableBody>
      </VmoTable>
    </SettingLayout>
  )
}

export default Sms
