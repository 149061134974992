import React from 'react'
import {Controller} from 'react-hook-form'
import {VmoDropdown, VmoForm, VmoFormField, VmoTooltip} from 'vmo-library'
import {removeDoubleQuotes} from 'utils/helper'
import {EmploymentStatus, EmploymentType} from 'constants/hr/employees'
import SvgIcon from 'components/common/SvgIcon'

const EmployeeHrInformation = props => {
  const {errors, control} = props

  return (
    <VmoForm className="errorLabel">
      <VmoFormField width={4}>
        <div className="info-header mb-1">
          <label>Employment Type</label>
          <VmoTooltip
            content="Select the type of your employee"
            trigger={<SvgIcon path="common/question" />}
            size="mini"
            position="top center"
          />
        </div>
        <Controller
          name="employmentType"
          render={({onChange, value}) => (
            <VmoDropdown
              fluid
              selection
              options={EmploymentType}
              value={value}
              onChange={(e, {value}) => {
                onChange(value)
              }}
              error={
                errors?.employmentType && {
                  content: removeDoubleQuotes(errors?.employmentType?.message),
                }
              }
            />
          )}
          control={control}
        />
      </VmoFormField>

      <VmoFormField width={4}>
        <div className="info-header mb-1">
          <label>Employment Status</label>
          <VmoTooltip
            content="Set status condition of employee"
            trigger={<SvgIcon path="common/question" />}
            size="mini"
            position="top center"
          />
        </div>
        <Controller
          name="employmentStatus"
          render={({onChange, value}) => (
            <VmoDropdown
              fluid
              selection
              options={EmploymentStatus}
              value={value}
              onChange={(e, {value}) => {
                onChange(value)
              }}
              error={
                errors?.employmentStatus && {
                  content: removeDoubleQuotes(errors?.employmentStatus?.message),
                }
              }
            />
          )}
          control={control}
        />
      </VmoFormField>
    </VmoForm>
  )
}

export default EmployeeHrInformation
