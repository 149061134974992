import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoHeader,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
} from 'vmo-library'
import SvgIcon from 'components/common/SvgIcon'

const meetingType = [
  {key: 1, value: 1, text: 'Personal'},
  {key: 2, value: 2, text: 'Team'},
]

const CreateMeetingModal = props => {
  const {isModalOpen, setIsModalOpen} = props
  const history = useHistory()

  const [data, setData] = useState({
    meetingName: '',
    type: 1,
    calendarId: '',
  })
  const [calendarListState, setCalendarList] = useState([])
  const {meetingsList = {}} = useSelector(state => state.meetings)
  const {calendarList} = meetingsList

  useEffect(() => {
    if (calendarList) {
      const temp = calendarList.map(list => ({
        key: list.calendarId,
        text: `${list.family_name} (${list.email})`,
        value: list.calendarId,
      }))

      setCalendarList(temp)
    }
  }, [calendarList])

  return (
    <VmoModal
      size="tiny"
      open={isModalOpen}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <VmoHeader className="vmoModalHeader">Create Meeting</VmoHeader>
      <VmoModalContent>
        <VmoForm>
          <VmoFormField required>
            <label>Meeting Name</label>
            <VmoFormInput
              type="text"
              fluid
              maxLength={30}
              defaultValue={data.meetingName}
              placeholder="Type meeting name..."
              onChange={(e, {value}) => {
                setData({...data, meetingName: value})
              }}
            />
            <span className="charType-user">{data.meetingName.length > 0 ? data.meetingName.length : 0}/30</span>
          </VmoFormField>
          <VmoFormField required>
            <label>Type</label>
            <VmoFormSelect
              fluid
              options={meetingType}
              selection
              placeholder="Type"
              defaultValue={data.type}
              onChange={(e, {value}) => {
                setData({
                  ...data,
                  type: value,
                })
              }}
            />
          </VmoFormField>
          <VmoFormField required>
            <label>Select Calendar</label>
            <VmoFormSelect
              fluid
              options={calendarListState}
              selection
              placeholder="Calendar"
              defaultValue={data.calendarId}
              onChange={(e, {value}) => {
                setData({
                  ...data,
                  calendarId: value,
                })
              }}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsModalOpen(!isModalOpen)}>
          Cancel
        </VmoButton>
        <VmoButton
          disabled={!(data.meetingName.trim() && data.type && data.calendarId)}
          primary
          onClick={() => {
            history.push({
              pathname: `/meetings/create`,
              state: data,
            })
          }}
        >
          Create
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default CreateMeetingModal
