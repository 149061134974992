import {
  BREAKDOWN_BY_DEPARTMENT,
  BREAKDOWN_BY_TYPES,
  EMPLOYEE_STATS,
  LEAVES_BY_DEPARTMENT,
  LEAVES_BY_TYPE,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case BREAKDOWN_BY_TYPES:
      return {
        ...state,
        breakdownByTypesData: action.payload,
      }

    case BREAKDOWN_BY_DEPARTMENT:
      return {
        ...state,
        breakdownByDepartmentData: action.payload,
      }

    case LEAVES_BY_DEPARTMENT:
      return {
        ...state,
        LeavesByDepartmentData: action.payload,
      }

    case LEAVES_BY_TYPE:
      return {
        ...state,
        LeavesByTypeData: action.payload,
      }

    case EMPLOYEE_STATS:
      return {
        ...state,
        EmployeeStatsData: action.payload,
      }

    default:
      return state
  }
}
