import Joi from 'joi'

export const addTriggerSchema = trigger => {
  return Joi.object({
    entity: Joi.string().required().messages({
      'string.empty': 'Entity must be specified',
    }),
    trigger: Joi.string().required().messages({
      'string.empty': 'Trigger must be specified',
    }),
    triggerId: Joi.when('trigger', {
      is: 'incoming',
      then: Joi.number().required().messages({
        'number.base': 'Inbox must be specified',
      }),
      otherwise: Joi.any().allow(null, ''),
    }),
    ...(Array.isArray(trigger.match(/inactive|open/))
      ? {
          conditionsJson: Joi.object({
            days: Joi.number().integer().positive().allow(0).required().messages({
              'number.base': `Days must be a number`,
              'any.required': `Days must be a number`,
            }),
            conditionsArray: Joi.array().optional(),
            conditionsOperator: Joi.string().allow(null, '').optional(),
          }),
        }
      : {
          conditionsJson: Joi.object({
            conditionsArray: Joi.array()
              .items(
                Joi.object({
                  field: Joi.string().required().messages({
                    'string.empty': `Field must be specified`,
                  }),
                  // operator: Joi.string().required().messages({
                  //   'string.empty': `Operator must be specified`,
                  // }),
                  operator: Joi.string().when('field', {
                    is: 'newContact',
                    then: Joi.string().empty('').default('='),
                    otherwise: Joi.string().required().messages({
                      'string.empty': `Operator must be specified`,
                    }),
                  }),
                  // value: Joi.any().required().empty('').messages({
                  //   'any.required': `Field must be specified`,
                  // }),
                  value: Joi.any().when('field', {
                    switch: [
                      {
                        is: 'conversationstatus',
                        then: Joi.object({
                          unit: Joi.string().required().label('Unit').messages({
                            'string.base': 'Unit must be specified',
                            'any.required': 'Unit must be specified',
                          }),
                          time: Joi.number().required().label('Time'),
                        }),
                      },
                      {
                        is: 'newConversation',
                        then: Joi.object({
                          unit: Joi.string().required().label('Unit').messages({
                            'string.base': 'Unit must be specified',
                            'any.required': 'Unit must be specified',
                          }),
                          time: Joi.number().required().label('Time'),
                        }),
                      },
                      {
                        is: 'newContact',
                        then: Joi.any().empty('').default(true),
                      },
                      {
                        is: 'win',
                        then: Joi.number().integer().min(0).max(100).required().messages({
                          'number.min': 'Value must be greater than on equal 0',
                          'number.max': 'Value must be less than or equal 100',
                          'number.base': 'Value must be specified',
                        }),
                      },
                    ],
                    otherwise: Joi.any().required().empty('').messages({
                      'any.required': `Field must be specified`,
                    }),
                  }),
                })
              )
              .min(1)
              .required(),
            conditionsOperator: Joi.string().required(),
            days: Joi.number().allow(null, '').optional(),
          }),
        }),
  })
}

export const addActionSchema = Joi.object({
  actionEntity: Joi.string().required().messages({
    'string.empty': 'Entity must be specified',
  }),
  actionEvent: Joi.string().required().messages({
    'string.empty': 'Event must be specified',
  }),
  actionJson: Joi.object({
    performOnlinked: Joi.boolean().optional(),
    actionsArray: Joi.array()
      .items(
        Joi.object({
          field: Joi.string().trim().required().messages({
            'string.empty': `Field must be required field`,
          }),
          // value: Joi.any().required().empty('').messages({
          //   'any.required': `Field must be specified`,
          // }),
          value: Joi.when('field', {
            is: 'deleteConversation',
            then: Joi.any().empty('').default(true),
            otherwise: Joi.any().required().empty('').messages({
              'any.required': `Field must be specified`,
            }),
          }),
        })
      )
      .min(1)
      .required(),
  }),
})

export const addActionPerformedOnLinkSchema = Joi.object({
  actionEntity: Joi.string().required().messages({
    'string.empty': 'Entity must be specified',
  }),
  actionEvent: Joi.string().required().messages({
    'string.empty': 'Event must be specified',
  }),
  actionJson: Joi.object({
    performOnlinked: Joi.boolean(),
  }),
})

export const addActionQuoteSchema = Joi.object({
  actionEntity: Joi.string().required().messages({
    'string.empty': 'Entity must be specified',
  }),
  actionEvent: Joi.string().required().messages({
    'string.empty': 'Event must be specified',
  }),
  actionJson: Joi.object({
    name: Joi.string().trim().required().max(30).required().messages({
      'string.empty': `name cannot be an empty field`,
      'any.required': `name is required`,
      'string.max': `Maximum 30 characters allowed`,
    }),
    performOnlinked: Joi.boolean().optional(),
  }),
})

export const addActionUserIdsSchema = Joi.object({
  actionEntity: Joi.string().required().messages({
    'string.empty': 'Entity must be specified',
  }),
  actionEvent: Joi.string().required().messages({
    'string.empty': 'Event must be specified',
  }),
  actionJson: Joi.object({
    userIds: Joi.array().items(Joi.number()).min(1).required().messages({
      'array.min': 'Assignee must be specified',
    }),
  }),
})

export const addActionSendExternalSchema = Joi.object({
  actionEntity: Joi.string().required().messages({
    'string.empty': 'Entity must be specified',
  }),
  actionEvent: Joi.string().required().messages({
    'string.empty': 'Event must be specified',
  }),
  actionJson: Joi.object({
    subject: Joi.string().trim().required().max(30).required().messages({
      'string.empty': `subject cannot be an empty field`,
      'any.required': `subject is required`,
      'string.max': `Maximum 30 characters allowed`,
    }),
    body: Joi.string().trim().required().max(30).required().messages({
      'string.empty': `Body cannot be an empty field`,
      'any.required': `Body is required`,
      'string.max': `Maximum 30 characters allowed`,
    }),
  }),
})

export const automationTitleSchema = Joi.object({
  name: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  description: Joi.string().trim().allow('').max(250).messages({
    'string.max': `Length must be less than or equal to 250 characters`,
  }),
})
