import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import {useParams} from 'react-router'
import {
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormSelect,
  VmoFormInput,
  VmoDatePicker,
  VmoFormTextArea,
  VmoGridColumn,
  VmoTooltip,
} from 'vmo-library'
import {GET_USERS, GET_VENDORS_LIST, GET_PREFIX, GET_ALL_CONTACTS_IDS} from 'actions/types'
import {getAllContactsIds} from 'actions/contacts'
import {getNewPoNumber, getPrefixes} from 'actions/PurchaseOrders'
import {getVendorslist} from 'actions/vendors'
import {removeDoubleQuotes, KadenceCountryList} from 'utils/helper'
import {Carrier, Status} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'
import PreviewPurchaseOrder from '../Templates/PreviewPurchaseOrder'

const Setup = props => {
  const {
    errors,
    control,
    watch,
    setValue,
    style,
    poDate,
    setPoDate,
    dueDate,
    setDueDate,
    prefixOptions,
    setPrefixOptions,
  } = props
  const dispatch = useDispatch()
  const {id: PurchaseOrdersId} = useParams()

  const [userOption, setUserOption] = useState([])
  const [contactOption, setcontactOption] = useState([])
  const [vendorList, setVendorList] = useState([])

  const {
    PurchaseOrderForm: {poNumber},
  } = useSelector(state => state.purchaseOrders)

  const watchPrefixId = watch('prefixId')
  const watchAll = watch()

  useEffect(() => {
    if (!PurchaseOrdersId && !poNumber && watchPrefixId) {
      dispatch(getNewPoNumber(watchPrefixId))
    }
  }, [watchPrefixId, PurchaseOrdersId, poNumber, dispatch])

  useEffect(() => {
    if (poNumber) {
      setValue('poNumber', poNumber, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }
  }, [poNumber, setValue])

  useApiResponse({
    action: getPrefixes,
    enabled: true,
    label: GET_PREFIX,
    storePath: 'purchaseOrders.prefixListData',
    onSuccess: prefixListData => {
      const prefixes = prefixListData?.map(({id, prefix}) => ({
        key: id,
        value: id,
        text: prefix,
      }))
      setPrefixOptions(prefixes)
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list)
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList.map(contact => ({
        value: contact.id,
        text: contact.name,
        id: contact.id,
      }))
      setcontactOption(list)
    },
  })

  useApiResponse({
    action: getVendorslist,
    enabled: true,
    label: GET_VENDORS_LIST,
    storePath: 'vendors.vendorsList',
    dataToExtract: 'vendors',
    onSuccess: vendorsListData => {
      setVendorList(
        vendorsListData?.vendors?.map(({name, id}) => {
          return {key: id, value: id, text: name}
        })
      )
    },
  })

  return (
    <VmoContainer style={style}>
      <VmoCard fluid>
        <VmoCardContent style={{padding: '18px'}}>
          <h3 className="mb-0">Purchase Order Information</h3>
          <p className="mt-0 mb-4 card-description">Create purchase order by mentioning all the required information</p>
          <VmoForm className="errorLabel">
            <VmoFormGroup>
              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Purchase Order Owner</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="set ownership of the purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="assigneeId"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      fluid
                      options={userOption}
                      selection
                      placeholder="Owner"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.assigneeId && {
                          content: removeDoubleQuotes(errors?.assigneeId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField width={4} style={{paddingLeft: '0'}}>
                <div className="info-header ml-3">
                  <label>Prefix Id</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select a prefix id"
                    size="mini"
                    psoition="top center"
                  />
                </div>

                <Controller
                  control={control}
                  name="prefixId"
                  render={({value, onChange}) => (
                    <VmoFormSelect
                      width={8}
                      placeholder="ID Prefix"
                      options={prefixOptions}
                      value={value}
                      search
                      clearable
                      noResultsMessage={
                        setPrefixOptions.length ? 'No prefixes found' : 'You have not created any Prefixes.'
                      }
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField width={4} required>
                <div className="info-header">
                  <label className="label-class">PO Number</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify purchase order number"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="poNumber"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      placeholder="PO Number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors?.poNumber && {
                          content: removeDoubleQuotes(errors?.poNumber.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField required>
                <div className="info-header">
                  <label className="label-class">Subject</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify subject of purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="subject"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      maxLength={20}
                      placeholder="Subject"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.subject && {
                          content: removeDoubleQuotes(errors.subject.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Vendor Name</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Mention the vendor name for the purchase"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="vendorId"
                  render={({onChange, value}) => (
                    <VmoFormSelect
                      fluid
                      selection
                      options={vendorList}
                      placeholder="Vendor Name"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.vendorId && {
                          content: removeDoubleQuotes(errors?.vendorId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Requisition Number</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Enter purchase order requisition number"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="requisitionNumber"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      maxLength={20}
                      placeholder="Requisition Number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.requisitionNumber && {
                          content: removeDoubleQuotes(errors.requisitionNumber.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Tracking Number</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Mention the tracking number for purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="trackingNumber"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      maxLength={20}
                      placeholder="Tracking Number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.trackingNumber && {
                          content: removeDoubleQuotes(errors.trackingNumber.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Contact Name</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention the contact name for the purchase"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="contactId"
                  render={({onChange, value}) => (
                    <VmoFormSelect
                      fluid
                      selection
                      options={contactOption}
                      placeholder="Contact Name"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.contactId && {
                          content: removeDoubleQuotes(errors?.contactId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>PO Date</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Mention the PO date"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="poDate"
                  render={({onChange, value}) => (
                    <VmoDatePicker
                      type="date"
                      value={typeof value === 'string' ? new Date(poDate) : poDate}
                      inputProps={{readOnly: true}}
                      time={false}
                      onChange={value => {
                        onChange(value)
                        setPoDate(value)
                      }}
                      error={
                        errors?.poDate && {
                          content: removeDoubleQuotes(errors?.poDate?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Due Date</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="set deadline of the order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="dueDate"
                  render={({onChange, value}) => (
                    <VmoDatePicker
                      type="date"
                      value={typeof value === 'string' ? new Date(dueDate) : dueDate}
                      inputProps={{readOnly: true}}
                      time={false}
                      onChange={value => {
                        onChange(value)
                        setDueDate(value)
                      }}
                      error={
                        errors?.dueDate && {
                          content: removeDoubleQuotes(errors?.dueDate?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Carrier</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="set carrier of the purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="carrier"
                  render={({onChange, value}) => (
                    <VmoFormSelect
                      fluid
                      selection
                      options={Carrier}
                      placeholder="Carrier"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.carrier && {
                          content: removeDoubleQuotes(errors?.carrier?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Excise Duty</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set the excise duty"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="exciseDuty"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="number"
                      maxLength={20}
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.exciseDuty && {
                          content: removeDoubleQuotes(errors.exciseDuty.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Sales Commission</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Add if there is any extra commission"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="salesCommission"
                  control={control}
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.salesCommission && {
                          content: removeDoubleQuotes(errors.salesCommission.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup>
              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Status</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="set current status of the purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="status"
                  render={({onChange, value}) => (
                    <VmoFormSelect
                      fluid
                      selection
                      options={Status}
                      placeholder="Status"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.status && {
                          content: removeDoubleQuotes(errors?.status?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Description</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="write brief description about purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="description"
                  render={({onChange, value}) => (
                    <VmoFormTextArea
                      type="text"
                      value={value}
                      maxLength={200}
                      placeholder="Description"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.description && {
                          content: removeDoubleQuotes(errors.description.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>
          </VmoForm>
        </VmoCardContent>
      </VmoCard>
      <VmoCard fluid>
        <VmoCardContent>
          <h3 className="mb-0">Address Information</h3>
          <p className="mt-0 mb-4 card-description">Add billing and shopping address for the purchase order</p>
          <VmoForm className="errorLabel">
            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing Street</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention the billing street"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.streetAddress"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      fluid
                      maxLength={20}
                      value={value}
                      placeholder="Street"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.billingAddress?.streetAddress && {
                          content: removeDoubleQuotes(errors?.billingAddress?.streetAddress?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping Street</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention the shipping address"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.streetAddress"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      fluid
                      value={value}
                      placeholder="Street"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.shippingAddress?.streetAddress && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.streetAddress?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing City</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention billing city"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.locality"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      maxLength={20}
                      fluid
                      value={value}
                      placeholder="Locality"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.billingAddress?.locality && {
                          content: removeDoubleQuotes(errors?.billingAddress?.locality?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping City</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Mention shipping city"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.locality"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      maxLength={20}
                      fluid
                      value={value}
                      placeholder="Locality"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.shippingAddress?.locality && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.locality?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing State</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify state of product shipping"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.state"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      fluid
                      value={value}
                      maxLength={20}
                      placeholder="State"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.billingAddress?.state && {
                          content: removeDoubleQuotes(errors?.billingAddress?.state?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping State</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention shipping state"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.state"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      type="text"
                      maxLength={20}
                      fluid
                      value={value}
                      placeholder="State"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.shippingAddress?.state && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.state?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing Postal Code</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention postal code for billing"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.postalCode"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormInput
                      type="text"
                      maxLength={10}
                      fluid
                      placeholder="Postal Code"
                      onBlur={onBlur}
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.billingAddress?.postalCode && {
                          content: removeDoubleQuotes(errors?.billingAddress?.postalCode?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping Postal Code</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention shipping code"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.postalCode"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormInput
                      type="text"
                      fluid
                      maxLength={10}
                      placeholder="Postal Code"
                      onBlur={onBlur}
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.shippingAddress?.postalCode && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.postalCode?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <div className="info-header">
                  <label>Billing Country</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify country name"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="billingAddress.country"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      search
                      fluid
                      options={KadenceCountryList}
                      selection
                      placeholder="Country"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.billingAddress?.country && {
                          content: removeDoubleQuotes(errors?.billingAddress?.country?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Shipping Country</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention the shipping country"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="shippingAddress.country"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      search
                      fluid
                      options={KadenceCountryList}
                      selection
                      placeholder="Country"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.shippingAddress?.country && {
                          content: removeDoubleQuotes(errors?.shippingAddress?.country?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>
          </VmoForm>
        </VmoCardContent>
      </VmoCard>
      <VmoGridColumn width={8} className="quotePreBox" style={{display: 'none'}}>
        <PreviewPurchaseOrder watch={{setupData: watchAll}} />
      </VmoGridColumn>
    </VmoContainer>
  )
}

export default Setup
