import React from 'react'
import {useSelector} from 'react-redux'
import {FiCheckCircle} from 'react-icons/fi'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCardDescription,
  VmoCardHeader,
  VmoCardMeta,
  VmoGridColumn,
  VmoMenu,
  VmoMenuItem,
} from 'vmo-library'
import {get, startCase} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'

const getPlanTime = plan => {
  if (plan.includes('Yearly')) {
    return '/user/year'
  }
  return '/user/month'
}

const getPlanDescription = planName => {
  if (planName === 'standard') {
    return 'Lorem Ipsum is simply dummy text'
  }
  if (planName === 'business') {
    return 'Lorem Ipsum is simply dummy text'
  }
  if (planName === 'ultimate') {
    return 'Lorem Ipsum is simply dummy text'
  }
  return null
}

const getPlanFeatureContent = planName => {
  if (planName === 'standard') {
    return 'Get Started with'
  }
  if (planName === 'business') {
    return 'All Standard Features and'
  }
  if (planName === 'ultimate') {
    return 'All Business Features and'
  }
  return null
}

const PlanList = props => {
  const {subInterval, setPaymentData, setStep, rolePermissions} = props
  const {getKadencePlansData = {}} = useSelector(state => state.billing)

  const renderPlanCard = plan => {
    return (
      <VmoCard fluid className="planBar">
        <VmoCardContent>
          <VmoCardHeader>{startCase(get(['name'], plan, ''))}</VmoCardHeader>
          <VmoCardMeta style={{margin: '0 0 18px'}}>{getPlanDescription(plan.name)}</VmoCardMeta>
          <VmoButton style={{margin: '0 0 18px'}} className="basic-fill-btn" fluid>
            Choose Plan
          </VmoButton>
          <span>$ {get(['amount'], plan, '-')}</span> {getPlanTime(get(['plan'], plan, ''))}
        </VmoCardContent>
        <VmoCardContent extra>
          <VmoCardDescription>{getPlanFeatureContent(plan.name)}</VmoCardDescription>
          <VmoMenu secondary vertical fluid>
            {plan.features.map(feature => (
              <VmoMenuItem>
                <FiCheckCircle /> {feature}
              </VmoMenuItem>
            ))}
          </VmoMenu>
        </VmoCardContent>
        <VmoCardContent extra>
          Reporting, automation and <br /> integrations for support teams
        </VmoCardContent>
      </VmoCard>
    )
  }

  return get(['plans', subInterval], getKadencePlansData, []).map(plan => {
    return (
      <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
        <VmoGridColumn
          onClick={() => {
            if (rolePermissions) {
              setPaymentData(paymentData => ({...paymentData, plan: plan.plan}))
              setStep('step2')
            }
          }}
        >
          {renderPlanCard(plan)}
        </VmoGridColumn>
      </LockPermissionTooltip>
    )
  })
}

export default PlanList
