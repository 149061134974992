import {CREATE_USER_ACCOUNT, GET_META_DATA} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case CREATE_USER_ACCOUNT: {
      return {
        ...state,
        signupData: action.payload,
      }
    }

    case GET_META_DATA:
      return {
        ...state,
        metadata: action.payload,
      }

    default:
      return state
  }
}
