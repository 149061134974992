import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import SalesOrderPrefixList from 'components/pages/Finance/SalesOrders/Settings/Prefix/PrefixList'
import SalesOrders from 'components/pages/Finance/SalesOrders/SalesOrders'

const routes = [
  {path: '/sales-orders/all', component: SalesOrders, title: 'All Sales Orders', exact: true},
  {path: '/sales-orders/prefix', component: SalesOrderPrefixList, title: 'Prefix', exact: false},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} salesOrders />
        <Component {...props} />
      </>
    )}
  />
)

const SalesOrdersRoutes = () => (
  <Switch>
    <Route exact path="/sales-orders">
      <Redirect to="/sales-orders/all" />
    </Route>
    {routes.map(renderRoute)}
    <Route path="/sales-orders">
      <Redirect to="/sales-orders/all" />
    </Route>
  </Switch>
)

export default SalesOrdersRoutes
