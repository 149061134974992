import React from 'react'

const FullPageLoader = () => {
  return (
    <div className="scene">
      <svg
        version="1.1"
        id="dc-spinner"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="38"
        height="38"
        viewBox="0 0 38 38"
        preserveAspectRatio="xMinYMin meet"
      >
        <svg width="40" height="40" x="15" y="1" viewBox="0 0 224 55" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M31.1211 13.3253C34.6547 11.8616 36.3327 7.81055 34.869 4.27694C33.4053 0.743324 29.3543 -0.934699 25.8206 0.528972C22.287 1.99264 20.609 6.04374 22.0727 9.57735C23.5363 13.111 27.5875 14.789 31.1211 13.3253Z"
            fill="#000"
          />
          <path
            d="M56.5313 8.72086L30.692 53.4734C30.4646 53.8696 30.1369 54.1988 29.7418 54.4277C29.3466 54.6567 28.8982 54.7772 28.4416 54.7772C27.985 54.7772 27.5366 54.6567 27.1414 54.4277C26.7463 54.1988 26.4185 53.8696 26.1912 53.4734L0.351926 8.6783C0.0639602 8.18289 -0.0521715 7.60606 0.0216534 7.03772C0.0954782 6.46937 0.355136 5.94142 0.760093 5.53616C1.16505 5.1309 1.69258 4.87107 2.2605 4.79719C2.82841 4.72331 3.40478 4.83953 3.89981 5.12771L16.8407 12.6035C17.4989 12.9762 18.0142 13.5577 18.3054 14.2562C18.5966 14.9546 18.6471 15.7303 18.4488 16.4606C18.0602 17.8444 17.956 19.2926 18.1424 20.7179C18.3394 22.1223 18.8214 23.4716 19.5587 24.6826C20.2961 25.8937 21.2732 26.941 22.4301 27.7601C23.5869 28.5792 24.899 29.1528 26.2856 29.4456C27.6722 29.7383 29.104 29.7441 30.4929 29.4625C31.8818 29.1809 33.1984 28.6179 34.3618 27.8081C35.5252 26.9984 36.5107 25.959 37.2578 24.7538C38.0049 23.5487 38.4977 22.2034 38.7058 20.8006C38.914 19.3978 38.8333 17.9672 38.4684 16.5968C38.2551 15.8544 38.2939 15.0621 38.5788 14.3441C38.8636 13.6261 39.3784 13.0229 40.0425 12.629L52.9833 5.15325C53.4799 4.8596 54.0601 4.73988 54.6322 4.81301C55.2044 4.88615 55.7359 5.14796 56.1428 5.5571C56.5497 5.96625 56.8087 6.4994 56.8791 7.07233C56.9494 7.64526 56.8271 8.22531 56.5313 8.72086Z"
            fill="#1B84E5"
          />
        </svg>

        <path
          fill="#eee"
          d="M20,35c-8.271,0-15-6.729-15-15S11.729,5,20,5s15,6.729,15,15S28.271,35,20,35z M20,5.203
        C11.841,5.203,5.203,11.841,5.203,20c0,8.159,6.638,14.797,14.797,14.797S34.797,28.159,34.797,20
        C34.797,11.841,28.159,5.203,20,5.203z"
        />

        <path
          fill="#eee"
          d="M20,33.125c-7.237,0-13.125-5.888-13.125-13.125S12.763,6.875,20,6.875S33.125,12.763,33.125,20
        S27.237,33.125,20,33.125z M20,7.078C12.875,7.078,7.078,12.875,7.078,20c0,7.125,5.797,12.922,12.922,12.922
        S32.922,27.125,32.922,20C32.922,12.875,27.125,7.078,20,7.078z"
        />

        <path
          fill="#2772EA"
          stroke="#2772EA"
          strokeWidth="0.6027"
          strokeMiterlimit="10"
          d="M5.203,20
			  c0-8.159,6.638-14.797,14.797-14.797V5C11.729,5,5,11.729,5,20s6.729,15,15,15v-0.203C11.841,34.797,5.203,28.159,5.203,20z"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 20 20"
            to="360 20 20"
            calcMode="spline"
            keySplines="0.4, 0, 0.2, 1"
            keyTimes="0;1"
            dur="2s"
            repeatCount="indefinite"
          />
        </path>

        <path
          fill="#72a4f2"
          stroke="#72a4f2"
          strokeWidth="0.2027"
          strokeMiterlimit="10"
          d="M7.078,20
        c0-7.125,5.797-12.922,12.922-12.922V6.875C12.763,6.875,6.875,12.763,6.875,20S12.763,33.125,20,33.125v-0.203
        C12.875,32.922,7.078,27.125,7.078,20z"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 20 20"
            to="360 20 20"
            dur="1.8s"
            repeatCount="indefinite"
          />
        </path>
      </svg>

      <div id="progress-bar-container">
        <div className="progress-bar-child progress" />
        <div className="progress-bar-child shrinker timelapse" />
      </div>
    </div>
  )
}

export default FullPageLoader
