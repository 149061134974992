import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'
import {COPY_PROJECT_MILESTONE} from 'actions/types'
import {copyProjectMilestone} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import {milestoneDuplicateSchema} from 'validation/Projects/projects/milestone.schema'
import SvgIcon from 'components/common/SvgIcon'

const MilestoneDuplicateModal = ({open, toggle, projectId, milestoneId, milestoneName}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {handleSubmit, control, errors} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(milestoneDuplicateSchema),
    defaultValues: {
      name: `${milestoneName} (copy)`,
    },
  })

  useEffect(() => {
    if (successLabels.includes(COPY_PROJECT_MILESTONE)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    dispatch(copyProjectMilestone({data, milestoneId, projectId}))
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>Duplicate Milestone</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Name</label>
            <Controller
              name="name"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.name && {content: removeDoubleQuotes(errors.name.message)}}
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          Duplicate Milestone
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default MilestoneDuplicateModal
