import React from 'react'
import {Controller} from 'react-hook-form'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'
import _ from 'lodash'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

function Header({setToggleModal, toggleModal, activeSection, index, control, data, errors}) {
  return (
    <VmoModal
      open={toggleModal === 'header'}
      onClose={() => setToggleModal(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      centered={false}
    >
      <VmoModalHeader className="vmoModalHeader">Edit Header</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Header</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].label`}
              control={control}
              defaultValue={data?.label}
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  placeholder="Enter Text"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  onBlur={onBlur}
                  // error={
                  //   _.get(errors, `templateSections[${activeSection}].sectionFields[${index}].label`) && {
                  //     content: removeDoubleQuotes(
                  //       _.get(errors, `templateSections[${activeSection}].sectionFields[${index}].label.message`)
                  //     ),
                  //   }
                  // }
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton onClick={() => setToggleModal(false)}>Cancel</VmoButton>
        <VmoButton primary onClick={() => setToggleModal(false)}>
          Ok
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default Header
