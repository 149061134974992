import {
  CONFIGURE_LOGO_UPLOAD,
  GET_ADVANCED_WIDGET,
  GET_APPEARENCE_WIDGET,
  GET_CONFIGURE_WIDGET,
  GET_INSTALLATION_WIDGET,
  GET_OFFLINE_EXPERIENCE,
  UPDATE_ADVANCED_WIDGET,
  UPDATE_APPEARENCE_WIDGET,
  UPDATE_CONFIGURE_WIDGET,
  UPDATE_OFFLINE_EXPERIENCE,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_INSTALLATION_WIDGET: {
      return {
        ...state,
        installation: [...(action.payload || [])],
      }
    }

    case GET_APPEARENCE_WIDGET: {
      return {
        ...state,
        appearence: {
          ...state.appearence,
          ...action.payload,
        },
      }
    }

    case UPDATE_APPEARENCE_WIDGET: {
      return {
        ...state,
        appearence: {
          ...state.appearence,
          ...action.data,
        },
      }
    }

    // Configure Start
    case GET_CONFIGURE_WIDGET: {
      return {
        ...state,
        configure: {
          ...state.configure,
          ...action.payload,
        },
      }
    }

    case UPDATE_CONFIGURE_WIDGET: {
      return {
        ...state,
        configure: {
          ...state.configure,
          ...action.data,
        },
      }
    }
    // Configure End

    case CONFIGURE_LOGO_UPLOAD: {
      return {
        ...state,
        attachmentInfo: action.payload,
        // mediaName: attachmentInfo.name,
        // mediaSize: action.payload.file.mediaSize,
        // mediaUrl: action.payload.url,
        // mime: action.payload.file.mime,
      }
    }

    // Advanced Start
    case GET_ADVANCED_WIDGET: {
      return {
        ...state,
        advanced: {
          ...state.advanced,
          ...action.payload,
        },
      }
    }

    case UPDATE_ADVANCED_WIDGET: {
      return {
        ...state,
        advanced: {
          ...state.advanced,
          ...action.data,
        },
      }
    }
    // Advanced End

    // Offline Experiece Start
    case GET_OFFLINE_EXPERIENCE: {
      return {
        ...state,
        offlineExperienceData: action.payload,
      }
    }
    case UPDATE_OFFLINE_EXPERIENCE: {
      return {
        ...state,
        offlineExperienceData: action.payload,
      }
    }
    // Offline Experiece End

    default:
      return state
  }
}
