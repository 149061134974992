import React from 'react'
import ContactMenu from 'navigation/sub-routes/Customers/Contacts/ContactMenu'

function Contacts() {
  return (
    <>
      <ContactMenu />
    </>
  )
}

export default Contacts
