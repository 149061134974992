import {
  CREATE_EXPENSES_CATEGORY,
  DELETE_EXPENSES_CATEGORY,
  GET_ALL_EXPENSES,
  GET_EMPLOYEE_LIST,
  GET_EXPENSES_CATEGORY,
  GET_PROJECT_LIST,
  GET_SINGLE_EXPENSE,
  GET_SINGLE_EXPENSES_CATEGORY,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_EXPENSES_CATEGORY:
      return {
        ...state,
        getExpensesCategoryData: action.payload,
      }
    case GET_SINGLE_EXPENSES_CATEGORY:
      return {
        ...state,
        getSingleExpensesCategoryData: action.payload,
      }
    case DELETE_EXPENSES_CATEGORY: {
      return {
        ...state,
        getExpensesCategoryData: state.getExpensesCategoryData.filter(category => category.id !== action.payload),
      }
    }
    case CREATE_EXPENSES_CATEGORY:
      return {
        ...state,
        getExpensesCategoryData: action.payload,
      }
    case GET_EMPLOYEE_LIST:
      return {
        ...state,
        getEmployeeOptionData: action.payload,
      }
    case GET_PROJECT_LIST:
      return {
        ...state,
        getProjectOptionData: action.payload,
      }
    case GET_ALL_EXPENSES: {
      return {
        ...state,
        allExpensesList: action.payload.expenses,
        expensesPage: action.payload.page,
      }
    }
    case GET_SINGLE_EXPENSE:
      return {
        ...state,
        expensesData: action.payload,
      }
    default:
      return state
  }
}
