import {GET_LOST_REASONS, GET_SOURCES, GET_CONTACT_STATUSES} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_SOURCES: {
      return {...state, sourcesData: action.payload}
    }
    case GET_LOST_REASONS: {
      return {...state, lostReasonsData: action.payload}
    }
    case GET_CONTACT_STATUSES: {
      return {...state, contactStatusData: action.payload}
    }

    default:
      return state
  }
}
