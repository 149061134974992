import {
  GET_ALL_DEALS,
  ADD_DEAL,
  EXPORT_ALL_DEALS,
  SAMPLE_DEALS_IMPORT,
  IMPORT_DEALS,
  COLUMNS_SELECTION_DEALS,
  GET_COLUMNS_SELECTION_DEALS,
  GET_STAGE_DEALS,
  GET_SOURCE_DEALS,
  GET_SINGLE_DEALS,
  EDIT_DEAL,
  ADD_NOTE_DEAL,
  ADD_DEAL_INTERACTION,
  MULTIPLE_UPDATE_DEAL,
  UNLINK_CONTACT,
  PRIMARY_CONTACT_CHANGE,
  ADD_CONTACT_DEAL,
  LOSS_DEAL,
  ABANDON_DEAL,
  WIN_DEAL,
  DELETE_DEAL,
  CREATE_PIPELINE,
  UPDATE_PIPELINE,
  GET_ALL_PIPELINES,
  DELETE_PIPELINE,
  ATTACHMENT_POST_DEAL,
  UNLINK_PRODUCT,
} from './types'
import {apiAction} from './api'
import {KADENCE_DEALS} from './endpoint'

export function getAllDeals(params, id) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${KADENCE_DEALS}?${query}`,
    method: 'GET',
    label: GET_ALL_DEALS,
  })
}

export function addDeal(data) {
  return apiAction({
    url: KADENCE_DEALS,
    method: 'POST',
    label: ADD_DEAL,
    data,
    showToast: true,
    successMessage: 'Created Deal',
  })
}

export function deleteDeal(id) {
  return apiAction({
    url: `${KADENCE_DEALS}/${id}`,
    method: 'DELETE',
    label: DELETE_DEAL,
    showToast: true,
    successMessage: 'Deleted Deal',
  })
}

export function addNoteDeal(id, data) {
  return apiAction({
    url: `${KADENCE_DEALS}/${id}/notes`,
    method: 'POST',
    label: ADD_NOTE_DEAL,
    data,
    showToast: true,
    successMessage: 'Note Created',
  })
}

export function editDeal(formInfo, id) {
  return apiAction({
    url: `${KADENCE_DEALS}/${id}`,
    method: 'PUT',
    label: EDIT_DEAL,
    data: formInfo,
    showToast: true,
    successMessage: 'Updated',
  })
}

export function exportAllDeals() {
  return apiAction({
    url: `${KADENCE_DEALS}/export`,
    method: 'GET',
    label: EXPORT_ALL_DEALS,
  })
}

export function sampleDealImportFile() {
  return apiAction({
    url: `${KADENCE_DEALS}/import/sample`,
    label: SAMPLE_DEALS_IMPORT,
    method: 'GET',
  })
}

export function importDeals(data) {
  return apiAction({
    url: `${KADENCE_DEALS}/import`,
    label: IMPORT_DEALS,
    method: 'POST',
    data,
    contentType: 'multipart/form-data',
    showToast: true,
    successMessage: 'Imported deals',
  })
}

export function columnsSelectionDeals(activeCol) {
  return apiAction({
    url: `${KADENCE_DEALS}/columns`,
    method: 'PUT',
    label: COLUMNS_SELECTION_DEALS,
    data: activeCol,
    showToast: true,
    successMessage: 'Updated',
  })
}

export function getColumnsSelectionDeals() {
  return apiAction({
    url: `${KADENCE_DEALS}/columns`,
    method: 'GET',
    label: GET_COLUMNS_SELECTION_DEALS,
  })
}

export function getSourceDeals() {
  return apiAction({
    url: `${KADENCE_DEALS}/sources`,
    method: 'GET',
    label: GET_SOURCE_DEALS,
  })
}

export function getStageDeals(id) {
  return apiAction({
    url: `${KADENCE_DEALS}/stages?pipelineId=${id}`,
    method: 'GET',
    label: GET_STAGE_DEALS,
  })
}

export function getSingleDeals(id, userId = 'all') {
  return apiAction({
    url: `${KADENCE_DEALS}/${id}?userId=${userId}`,
    method: 'GET',
    label: GET_SINGLE_DEALS,
  })
}

export function addInteractionDeal(dealId, data) {
  return apiAction({
    url: `${KADENCE_DEALS}/${dealId}/interactions`,
    method: 'POST',
    label: ADD_DEAL_INTERACTION,
    data,
    showToast: true,
    successMessage: 'Interaction Created',
  })
}

export function dealMultipleUpdate(data) {
  return apiAction({
    url: `${KADENCE_DEALS}/multiple`,
    method: 'PUT',
    label: MULTIPLE_UPDATE_DEAL,
    data,
    showToast: true,
    successMessage: 'Updated',
  })
}

export function unlinkProduct(dealId, linkId) {
  return apiAction({
    url: `${KADENCE_DEALS}/${dealId}/productunlink/${linkId}`,
    method: 'POST',
    label: UNLINK_PRODUCT,
    showToast: true,
    successMessage: 'Unlink Product updated',
  })
}
export function unlinkContact(dealId, linkId) {
  return apiAction({
    url: `${KADENCE_DEALS}/${dealId}/unlink/${linkId}`,
    method: 'POST',
    label: UNLINK_CONTACT,
    showToast: true,
    successMessage: 'Unlink Contact updated',
  })
}

export function primaryContactChanger(dealId, id) {
  return apiAction({
    url: `${KADENCE_DEALS}/${dealId}/primary/${id}`,
    method: 'PUT',
    label: PRIMARY_CONTACT_CHANGE,
    showToast: true,
    successMessage: 'Primary contact updated',
  })
}

export function addContactDeal(dealId, data) {
  return apiAction({
    url: `${KADENCE_DEALS}/${dealId}/link`,
    method: 'POST',
    label: ADD_CONTACT_DEAL,
    data,
    showToast: true,
    successMessage: 'Added Contact Deal',
  })
}

export function winDeal(id, data) {
  return apiAction({
    url: `${KADENCE_DEALS}/${id}/win`,
    method: 'PUT',
    label: WIN_DEAL,
    data,
    showToast: true,
    successMessage: 'Deal Won',
  })
}

export function lossDeal(id, data) {
  return apiAction({
    url: `${KADENCE_DEALS}/${id}/loss`,
    method: 'PUT',
    label: LOSS_DEAL,
    data,
    showToast: true,
    successMessage: 'Deal Lost',
  })
}

export function abandonDeal(id, data) {
  return apiAction({
    url: `${KADENCE_DEALS}/${id}/abandon`,
    method: 'PUT',
    label: ABANDON_DEAL,
    data,
    showToast: true,
    successMessage: 'Deal Abandoned',
  })
}

export function createPipeline(data) {
  return apiAction({
    url: `${KADENCE_DEALS}/pipelines`,
    method: 'POST',
    label: CREATE_PIPELINE,
    data,
    showToast: true,
    successMessage: 'Created',
  })
}

export function updatePipeline(id, data) {
  return apiAction({
    url: `${KADENCE_DEALS}/pipelines/${id}`,
    method: 'PUT',
    label: UPDATE_PIPELINE,
    data,
    showToast: true,
    successMessage: 'Updated',
  })
}

export function getPipelines() {
  return apiAction({
    url: `${KADENCE_DEALS}/pipelines`,
    method: 'GET',
    label: GET_ALL_PIPELINES,
  })
}

export function deletePipelines(id) {
  return apiAction({
    url: `${KADENCE_DEALS}/pipelines/${id}`,
    method: 'DELETE',
    label: DELETE_PIPELINE,
    showToast: true,
    successMessage: 'Deleted',
  })
}
export function uploadAttachmentsDeals(id, data) {
  return apiAction({
    url: `${KADENCE_DEALS}/${id}/attachments`,
    method: 'POST',
    label: ATTACHMENT_POST_DEAL,
    data,
    contentType: 'multipart/form-data',
    showToast: true,
    successMessage: 'File Uploaded',
  })
}
