import {useContext} from 'react'
import {EmailBodyContext} from 'context/email-body'

const useEmailBody = () => {
  const context = useContext(EmailBodyContext)

  return {...context}
}

export default useEmailBody
