/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {VmoButton, VmoHeader, VmoTable, VmoTableHeader, VmoTablePlaceholder, VmoTableRow, VmoTooltip} from 'vmo-library'
import {DELETE_TAG, GET_ALL_TAG_LABEL} from 'actions/types'
import {createTagSettings, deleteTags, editTag, getTag, getTagLabel} from 'actions/multiple_fields'
import img from 'assets/create.svg'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import CreateLabel from 'components/common/CreateLabel'
import CreateFirst from 'components/common/CreateFirst'
import {startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const Tags = () => {
  const dispatch = useDispatch()

  const [createTag, setCreateTag] = useState(false)
  const [editTagModal, setEditTagModal] = useState(false)
  const [idToEdit, setIdToEdit] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {data: getTagList = [], callAction} = useApiResponse({
    action: getTagLabel,
    enabled: true,
    label: GET_ALL_TAG_LABEL,
    storePath: 'multipleFields.getTagList',
  })

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const tagsLearnMore = settingsLearnMoreUrl?.productivity?.tags?.learnMore

  const {isLoading} = useApiLoader({label: GET_ALL_TAG_LABEL})

  useEffect(() => {
    if (successLabels.includes(DELETE_TAG)) {
      callAction()
    }
  }, [successLabels, callAction])

  const renderTagList = () => {
    return getTagList.map((tag, index) => (
      <tr
        className="tableLink"
        onClick={() => {
          setEditTagModal(true)
          setIdToEdit(tag.id)
          dispatch(getTag(tag.id))
        }}
        key={tag.id}
      >
        <td className="pl-4">{index + 1}</td>
        <td>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div
              style={{
                height: '12px',
                width: '12px',
                background: tag.color,
                borderRadius: '50%',
                marginRight: '10px',
                display: 'inline-block',
              }}
            />
            <VmoHeader as="h5" className="m-0">
              {startCase(tag.label || '-')}
            </VmoHeader>
          </div>
        </td>
        <td onClick={e => e.stopPropagation()}>
          <VmoTooltip
            content="Delete"
            size="mini"
            position="top center"
            trigger={
              <VmoButton
                icon
                onClick={() => {
                  setIdToEdit(tag.id)
                  setDeleteModal(true)
                }}
              >
                <SvgIcon path="common/delete" />
              </VmoButton>
            }
          />
        </td>
      </tr>
    ))
  }

  if (isLoading) {
    return <PageLoader />
  }

  return getTagList.length > 0 ? (
    <SettingLayout
      icon={<SvgIcon path="settings/tags" />}
      header="Tags"
      subHeader="Manage all settings related to tags. Click on add to create multiple tags"
      learnMoreUrl={tagsLearnMore}
      headerButton={{
        onClick: () => setCreateTag(true),
        content: 'Add',
      }}
      table
      headerDivider
    >
      <VmoTable basic selectable>
        <VmoTableHeader>
          <VmoTableRow>
            <th className="pl-4">#</th>
            <th>Tags</th>
            <th>Action</th>
          </VmoTableRow>
        </VmoTableHeader>
        <tbody>
          {isLoadingData && type.includes(GET_ALL_TAG_LABEL) ? <VmoTablePlaceholder columns={3} /> : renderTagList()}
        </tbody>
      </VmoTable>
      {createTag && (
        <CreateLabel
          setIsModalOpen={setCreateTag}
          isModalOpen={createTag}
          createMethod={data => dispatch(createTagSettings(data))}
        />
      )}
      {editTagModal && (
        <CreateLabel setIsModalOpen={setEditTagModal} isModalOpen={editTagModal} action={editTag} editId={idToEdit} />
      )}
      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToEdit}
          deleteAction={deleteTags}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          type={DELETE_TAG}
        />
      )}
    </SettingLayout>
  ) : (
    <>
      {createTag && (
        <CreateLabel
          setIsModalOpen={setCreateTag}
          isModalOpen={createTag}
          createMethod={data => dispatch(createTagSettings(data))}
        />
      )}
      <SettingLayout>
        <CreateFirst
          src={<SvgIcon path="settings/tags" />}
          Feature1="Assign colors"
          Feature2="Manage tags at one place"
          Feature3="Better ticket management"
          list1="Assign a color to your customized tag to differentiate better"
          list2="Manage tags all at this place and editing a tag will update it at all the places wherever it is applied"
          list3="Assigning tags creates a better workflow and simplifies management of tickets and customers"
          addFirst={() => setCreateTag(true)}
          buttonText="Add Tag"
          img={img}
          header="Tag"
          subHeader="Tags are property labels which are generally used in categorizing and."
        />
      </SettingLayout>
    </>
  )
}
export default Tags
