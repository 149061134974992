import {joiResolver} from '@hookform/resolvers'
import SvgIcon from 'components/common/SvgIcon'
import Joi from 'joi'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {removeDoubleQuotes} from 'utils/helper'
import {
  VmoButton,
  VmoDatePicker,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

const CustomDateSelectorModal = ({open, onCancel, onApply}) => {
  const {handleSubmit, control, errors} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        startDate: Joi.date().required(),
        endDate: Joi.date().greater(Joi.ref('startDate')).required().messages({
          'date.greater': 'End date must be greater than start time',
        }),
      })
    ),
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
    },
  })

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => onCancel()}
    >
      <VmoModalHeader>Custom Dates</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormGroup>
            <VmoFormField required>
              <label>Start Date</label>
              <Controller
                name="startDate"
                control={control}
                render={({value, onChange}) => (
                  <VmoDatePicker
                    time={false}
                    value={value ? new Date(value) : value}
                    onChange={value => onChange(value)}
                    inputProps={{readOnly: true}}
                  />
                )}
              />
              {errors.startDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.startDate.message)}</p>}
            </VmoFormField>
            <VmoFormField required>
              <label>End Date</label>
              <Controller
                name="endDate"
                control={control}
                render={({value, onChange}) => (
                  <VmoDatePicker
                    time={false}
                    value={value ? new Date(value) : value}
                    onChange={value => onChange(value)}
                    inputProps={{readOnly: true}}
                  />
                )}
              />
              {errors.endDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.endDate.message)}</p>}
            </VmoFormField>
          </VmoFormGroup>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={onCancel}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(onApply)}>
          Apply
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default CustomDateSelectorModal
