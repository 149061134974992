import React from 'react'
import {withRouter} from 'react-router'
import {NavBar} from 'navigation/NavBar'

const AppLayout = ({children}) => {
  return (
    <>
      <NavBar />
      <div className="kadence-body">{children}</div>
    </>
  )
}

export default withRouter(AppLayout)
