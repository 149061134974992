import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  VmoCheckbox,
  VmoDropdown,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoGrid,
  VmoGridColumn,
  VmoTooltip,
} from 'vmo-library'

import {editContact} from 'actions/contacts'
import {deleteContactEmail, deleteContactPhone, deleteContactWebsite} from 'actions/multiple_fields'
import {removeDoubleQuotes} from 'utils/helper'
import {countryCodeList} from 'constants/variables'
import Email from 'components/common/Email'
import Phone from 'components/common/Phone'
import Website from 'components/common/Website'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const ContactInfo = props => {
  const dispatch = useDispatch()
  const {
    contactId,
    errors,
    control,
    Controller,
    handleSubmit,
    updateContact,
    emailFields,
    appendEmail,
    removeEmail,
    phoneFields,
    appendPhone,
    removePhone,
    websiteFields,
    appendwebsite,
    removeWebsite,
  } = props

  const refForm = useRef()

  const [description, setDescription] = useState('')
  const [countryCodeUpdated, setCountryCodeUpdated] = useState([])
  const [company, setCompany] = useState('')
  const [contactCompanyList, setContactCompanyList] = useState([])

  const {singleContact = {}, getAllContactCompanyList} = useSelector(state => state.contacts)

  const {contactsAndCompaniesPermissions} = useUserPermissions()
  const manageContactsCompanyPermissions = contactsAndCompaniesPermissions?.manage_contacts_company

  useEffect(() => {
    if (getAllContactCompanyList) {
      const companyList = getAllContactCompanyList.map(list => ({
        key: list.id,
        value: list.id,
        text: list.name,
      }))
      setContactCompanyList(companyList)
    }
  }, [getAllContactCompanyList])
  useEffect(() => {
    const updatedList = countryCodeList.map(item => ({
      key: `${item.name}`,
      value: item.name,
      flag: item.code.toLowerCase(),
      text: `${item.name}`,
      content: `${item.name}`,
    }))

    setCountryCodeUpdated(updatedList)
  }, [])
  useEffect(() => {
    setCompany(_.get(singleContact, 'companyId'))
  }, [singleContact])

  return (
    <VmoForm
      ref={refForm}
      className="errorLabel vmo-form"
      {...(manageContactsCompanyPermissions && {
        onSubmit: handleSubmit(updateContact),
      })}
    >
      <VmoFormField className="mutiple mb-1">
        <label>First Name</label>
        <Controller
          name="firstName"
          render={({onChange, value, onBlur}) => (
            <VmoFormInput
              type="text"
              maxLength={20}
              fluid
              value={value}
              placeholder="Type name..."
              onChange={e => {
                onChange(e.target.value)
              }}
              onBlur={e => {
                onBlur(e)
                refForm.current.handleSubmit()
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.target.blur()
                }
              }}
              disabled={!manageContactsCompanyPermissions}
              error={
                errors.firstName && {
                  content: removeDoubleQuotes(errors.firstName.message),
                }
              }
            />
          )}
          control={control}
        />
      </VmoFormField>
      <VmoFormField className="mutiple mb-1">
        <label>Last Name</label>

        <Controller
          name="lastName"
          render={({onChange, value, onBlur}) => (
            <VmoFormInput
              type="text"
              maxLength={20}
              fluid
              value={value}
              placeholder="Last Name"
              onChange={e => {
                onChange(e.target.value)
              }}
              onBlur={e => {
                onBlur(e)
                refForm.current.handleSubmit()
              }}
              disabled={!manageContactsCompanyPermissions}
              error={
                errors.lastName && {
                  content: removeDoubleQuotes(errors.lastName.message),
                }
              }
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.target.blur()
                }
              }}
            />
          )}
          control={control}
        />
      </VmoFormField>
      <VmoFormField>
        <label>Email</label>
        <Controller
          name="email"
          control={control}
          render={({onChange, value, onBlur}) => (
            <VmoFormInput
              type="email"
              fluid
              value={value}
              placeholder="Email"
              onChange={e => onChange(e.target.value)}
              onBlur={e => {
                onBlur(e)
                refForm.current.handleSubmit()
              }}
              disabled={!manageContactsCompanyPermissions}
              error={
                errors.email && {
                  content: removeDoubleQuotes(errors.email.message),
                }
              }
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.target.blur()
                }
              }}
            />
          )}
        />
      </VmoFormField>
      <VmoFormField>
        <Email
          id={contactId}
          deleteEmail={deleteContactEmail}
          refForm={refForm}
          control={control}
          Controller={Controller}
          errors={errors}
          emailFields={emailFields}
          appendEmail={appendEmail}
          removeEmail={removeEmail}
          disabled={!manageContactsCompanyPermissions}
        />
      </VmoFormField>
      <VmoFormField>
        <div className="info-header">
          <label className="m-0">Company</label>
          <VmoTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Select company of this contact"
            size="mini"
            psoition="top center"
          />
        </div>
        <VmoDropdown
          placeholder="Company"
          selection
          fluid
          search
          additionPosition="bottom"
          allowAdditions
          additionLabel={<i>Add Company: </i>}
          onChange={(e, {value}) => {
            setCompany(value)
            if (typeof value === 'number') {
              dispatch(editContact({companyId: value}, contactId))
            } else {
              dispatch(editContact({company: value}, contactId))
            }
          }}
          onAddItem={(e, {value}) => {
            setCompany(value)
            setContactCompanyList([{value, key: value, text: value}, ...contactCompanyList])
          }}
          value={company}
          options={contactCompanyList}
          disabled={!manageContactsCompanyPermissions}
        />
      </VmoFormField>
      <VmoFormField>
        <label>Title</label>

        <Controller
          name="title"
          render={({onChange, value, onBlur}) => (
            <VmoFormInput
              type="text"
              maxLength={20}
              fluid
              value={value}
              placeholder="Title"
              onChange={e => {
                onChange(e.target.value)
              }}
              onBlur={e => {
                onBlur(e)
                refForm.current.handleSubmit()
              }}
              disabled={!manageContactsCompanyPermissions}
              error={
                errors.title && {
                  content: removeDoubleQuotes(errors.title.message),
                }
              }
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.target.blur()
                }
              }}
            />
          )}
          control={control}
        />
      </VmoFormField>
      <VmoFormField>
        <div className="d-flex">
          <div className="info-header">
            <label className="m-0">Allow access to client portal</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Allow contact to access the client portal i.e all latest updates & announcements"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="allowPortalAccess"
            control={control}
            render={({value, onChange, onBlur}) => (
              <VmoCheckbox
                toggle
                checked={value}
                onChange={(e, {checked}) => manageContactsCompanyPermissions && onChange(checked)}
                onBlur={e => {
                  onBlur(e)
                  refForm.current.handleSubmit()
                }}
              />
            )}
          />
        </div>
      </VmoFormField>
      <VmoFormField>
        <label>Website</label>
        <Controller
          name="website"
          control={control}
          render={({onChange, value, onBlur}) => (
            <VmoFormInput
              type="website"
              maxLength={200}
              fluid
              value={value}
              placeholder="Website"
              onChange={e => onChange(e.target.value)}
              onBlur={e => {
                onBlur(e)
                refForm.current.handleSubmit()
              }}
              disabled={!manageContactsCompanyPermissions}
              error={
                errors.website && {
                  content: removeDoubleQuotes(errors.website.message),
                }
              }
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.target.blur()
                }
              }}
            />
          )}
        />
      </VmoFormField>
      <VmoFormField>
        <Website
          id={contactId}
          deleteWebsite={deleteContactWebsite}
          refForm={refForm}
          control={control}
          Controller={Controller}
          errors={errors}
          websiteFields={websiteFields}
          appendwebsite={appendwebsite}
          removeWebsite={removeWebsite}
          disabled={!manageContactsCompanyPermissions}
        />
      </VmoFormField>
      <VmoFormField>
        <label>Phone</label>
        <Controller
          name="phone"
          control={control}
          render={({onChange, value, onBlur}) => (
            <VmoFormInput
              maxLength={20}
              type="phone"
              fluid
              value={value}
              placeholder="Phone"
              onChange={e => onChange(e.target.value)}
              onBlur={e => {
                onBlur(e)
                refForm.current.handleSubmit()
              }}
              disabled={!manageContactsCompanyPermissions}
              error={
                errors.phone && {
                  content: removeDoubleQuotes(errors.phone.message),
                }
              }
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.target.blur()
                }
              }}
            />
          )}
        />
      </VmoFormField>
      <VmoFormField>
        <Phone
          id={contactId}
          deletePhone={deleteContactPhone}
          refForm={refForm}
          control={control}
          Controller={Controller}
          errors={errors}
          phoneFields={phoneFields}
          appendPhone={appendPhone}
          removePhone={removePhone}
          disabled={!manageContactsCompanyPermissions}
        />
      </VmoFormField>
      <VmoFormField className="mb-1">
        <label>Address</label>
        <VmoFormField>
          <Controller
            name="address.streetAddress"
            render={({onChange, value, onBlur}) => (
              <VmoFormInput
                className="m-0"
                maxLength={20}
                type="text"
                fluid
                value={value}
                placeholder="Street Address"
                onChange={e => {
                  onChange(e.target.value)
                }}
                onBlur={e => {
                  onBlur(e)
                  refForm.current.handleSubmit()
                }}
                disabled={!manageContactsCompanyPermissions}
                error={
                  errors?.address?.streetAddress && {
                    content: removeDoubleQuotes(errors?.address?.streetAddress?.message),
                  }
                }
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.target.blur()
                  }
                }}
              />
            )}
            control={control}
          />
        </VmoFormField>
        <VmoGrid className="mt-2">
          <VmoGridColumn width={8}>
            <VmoFormField>
              <Controller
                name="address.locality"
                render={({onChange, value, onBlur}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    maxLength={20}
                    value={value}
                    placeholder="Locality"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={e => {
                      onBlur(e)
                      refForm.current.handleSubmit()
                    }}
                    disabled={!manageContactsCompanyPermissions}
                    error={
                      errors?.address?.locality && {
                        content: removeDoubleQuotes(errors?.address?.locality?.message),
                      }
                    }
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        e.target.blur()
                      }
                    }}
                  />
                )}
                control={control}
              />
            </VmoFormField>
          </VmoGridColumn>
          <VmoGridColumn width={8}>
            <VmoFormField>
              <Controller
                name="address.state"
                render={({onChange, value, onBlur}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    maxLength={20}
                    value={value}
                    placeholder="State"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={e => {
                      onBlur(e)
                      refForm.current.handleSubmit()
                    }}
                    disabled={!manageContactsCompanyPermissions}
                    error={
                      errors?.address?.state && {
                        content: removeDoubleQuotes(errors?.address?.state?.message),
                      }
                    }
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        e.target.blur()
                      }
                    }}
                  />
                )}
                control={control}
              />
            </VmoFormField>
          </VmoGridColumn>
        </VmoGrid>
        <VmoGrid>
          <VmoGridColumn width={16}>
            <Controller
              name="address.country"
              render={({onChange, value, onBlur}) => (
                <VmoFormSelect
                  search
                  fluid
                  options={countryCodeUpdated}
                  selection
                  placeholder="Country"
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                    refForm.current.handleSubmit()
                  }}
                  onBlur={onBlur}
                  disabled={!manageContactsCompanyPermissions}
                  error={
                    errors?.address?.country && {
                      content: removeDoubleQuotes(errors?.address?.country?.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoGridColumn>
          <VmoGridColumn width={16}>
            <VmoFormField>
              <Controller
                name="address.postalCode"
                render={({onChange, value, onBlur}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    value={value}
                    maxLength={20}
                    placeholder="Postal code"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={e => {
                      onBlur(e)
                      refForm.current.handleSubmit()
                    }}
                    disabled={!manageContactsCompanyPermissions}
                    error={
                      errors?.address?.postalCode && {
                        content: removeDoubleQuotes(errors?.address?.postalCode?.message),
                      }
                    }
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        e.target.blur()
                      }
                    }}
                  />
                )}
                control={control}
              />
            </VmoFormField>
          </VmoGridColumn>
        </VmoGrid>
      </VmoFormField>
      <VmoFormField>
        <label>Description</label>
        <Controller
          name="description"
          render={({onChange, value, onBlur}) => (
            <VmoFormTextArea
              style={{resize: 'none'}}
              type="text"
              value={value}
              maxLength={200}
              placeholder="Description"
              onChange={e => {
                onChange(e.target.value)
              }}
              onKeyPress={e => {
                if (e.key === 'Enter' && e.shiftKey) {
                  setDescription(`${description}\t`)
                } else if (e.key === 'Enter') {
                  e.target.blur()
                }
              }}
              onBlur={e => {
                onBlur(e)
                refForm.current.handleSubmit()
              }}
              disabled={!manageContactsCompanyPermissions}
              error={
                errors?.description && {
                  content: removeDoubleQuotes(errors?.description?.message),
                }
              }
            />
          )}
          control={control}
        />
      </VmoFormField>
      <VmoFormField>
        {singleContact.metadata &&
          Object.entries(singleContact.metadata).map(data => (
            <>
              <label>{data[0]}</label>
              <VmoFormInput value={data[1]} disabled />
            </>
          ))}
      </VmoFormField>
    </VmoForm>
  )
}

export default ContactInfo
