import {ADDED_BY_SOURCE_OVERTIME, ADDED_VS_DELETED, CONTACT_STATS} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case CONTACT_STATS:
      return {...state, contactStats: payload}

    case ADDED_VS_DELETED:
      return {...state, addedVsDeleted: payload}

    case ADDED_BY_SOURCE_OVERTIME:
      return {...state, addedBySourceOvertime: payload}

    default:
      return state
  }
}
