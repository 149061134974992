import React, {useState} from 'react'
import {FiChevronRight} from 'react-icons/fi'
import {VmoMenu, VmoMenuItem, VmoAccordion, VmoAccordionTitle, VmoAccordionContent} from 'vmo-library'
import AddSegment from 'components/pages/Customers/Segment/AddSegment'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import {useDispatch} from 'react-redux'
import {getContactList} from 'actions/contacts'
import SvgIcon from 'components/common/SvgIcon'

const ContactMenu = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [activeContacts, setActiveContacts] = useState(true)

  return (
    <>
      <VmoMenu vertical secondary className="kadence-menu">
        <VmoAccordion className="mt-0">
          <VmoAccordionTitle style={{justifyContent: 'flex-start'}} onClick={() => setActiveContacts(!activeContacts)}>
            {activeContacts ? <SvgIcon path="common/down" /> : <FiChevronRight />}
            Contacts
          </VmoAccordionTitle>
          <VmoAccordionContent active={activeContacts}>
            <VmoMenuItem
              active={window.location.pathname === `/contacts`}
              onClick={() => {
                history.push(`/contacts`)
                dispatch(getContactList())
              }}
            >
              <SvgIcon path="clients/contacts/all-contacts" /> All Contacts
            </VmoMenuItem>
            {/* <VmoMenuItem
              active={window.location.pathname === `/contacts/newcontact`}
              onClick={() => history.push(`/contacts/newcontact`)}
            >
              <SvgIcon path="settings/users" /> New
            </VmoMenuItem> */}
            {/* <VmoMenuItem>
              <SvgIcon path="settings/users" /> Active
            </VmoMenuItem>
            <VmoMenuItem>
              <SvgIcon path="settings/users" /> Slipping Away
            </VmoMenuItem>
            <VmoMenuItem>
              <SvgIcon path="settings/users" /> Unengaged Leads
            </VmoMenuItem> */}
          </VmoAccordionContent>
          <VmoAccordionContent active={activeContacts}>
            <AddSegment type="contacts" {...props} />
          </VmoAccordionContent>
        </VmoAccordion>
      </VmoMenu>
    </>
  )
}

export default ContactMenu
