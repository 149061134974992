/* eslint-disable no-nested-ternary */
import {KADENCE_COMPANY, KADENCE_CONTACTS, KADENCE_SETTING} from './endpoint'
import {
  ADD_PHONE_COMPANY,
  UPDATE_PHONE_COMPANY,
  ADD_EMAIL_COMPANY,
  UPDATE_EMAIL_COMPANY,
  ADD_SOCIAL,
  UPDATE_SOCIAL,
  REMOVE_SOCIAL,
  ADD_PHONE_CONTACT,
  UPDATE_PHONE_CONTACT,
  ADD_EMAIL_CONTACT,
  UPDATE_EMAIL_CONTACT,
  GET_TAG_SETTING,
  EDIT_TAG_SETTING,
  DELETE_TAG,
  CREATE_TAG,
  GET_ALL_TAG_LABEL,
  DELETE_COMPANY_PHONE,
  DELETE_COMPANY_EMAIL,
  ADD_COMPANY_WEBSITE,
  UPDATE_COMPANY_WEBSITE,
  DELETE_COMPANY_WEBSITE,
  DELETE_CONTACT_PHONE,
  DELETE_CONTACT_EMAIL,
  ADD_CONTACT_WEBSITE,
  UPDATE_CONTACT_WEBSITE,
  DELETE_CONTACT_WEBSITE,
} from './types'
import {apiAction} from './api'

/// Company------------------------->
// add phone company
export function addCompanyPhone(companyId, data) {
  return apiAction({
    url: `${KADENCE_COMPANY}/${companyId}/phones`,
    method: 'POST',
    label: ADD_PHONE_COMPANY,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Added a new phone',
  })
}

// update phone company
export function updateCompanyPhone(companyId, phoneId, data) {
  return apiAction({
    url: `${KADENCE_COMPANY}/${companyId}/phones/${phoneId}`,
    method: 'PUT',
    label: UPDATE_PHONE_COMPANY,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Updated',
  })
}

// delete phone company
export function deleteCompanyPhone(companyId, phoneId, data) {
  return apiAction({
    url: `${KADENCE_COMPANY}/${companyId}/phones/${phoneId}`,
    method: 'DELETE',
    label: DELETE_COMPANY_PHONE,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Deleted',
  })
}

// add email company
export function addCompanyEmail(companyId, data) {
  return apiAction({
    url: `${KADENCE_COMPANY}/${companyId}/emails`,
    method: 'POST',
    label: ADD_EMAIL_COMPANY,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Added a new email',
  })
}

// update email comapany
export function updateCompanyEmail(companyId, emailId, data) {
  return apiAction({
    url: `${KADENCE_COMPANY}/${companyId}/emails/${emailId}`,
    method: 'PUT',
    label: UPDATE_EMAIL_COMPANY,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Updated',
  })
}

// delete email company
export function deleteCompanyEmail(companyId, emailId, data) {
  return apiAction({
    url: `${KADENCE_COMPANY}/${companyId}/emails/${emailId}`,
    method: 'DELETE',
    label: DELETE_COMPANY_EMAIL,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Deleted',
  })
}

// add website company
export function addCompanyWebsite(companyId, data) {
  return apiAction({
    url: `${KADENCE_COMPANY}/${companyId}/websites`,
    method: 'POST',
    label: ADD_COMPANY_WEBSITE,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Added a new website',
  })
}

// update company website
export function updateCompanyWebsite(companyId, websiteId, data) {
  return apiAction({
    url: `${KADENCE_COMPANY}/${companyId}/websites/${websiteId}`,
    method: 'PUT',
    label: UPDATE_COMPANY_WEBSITE,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Updated',
  })
}

// delete website company
export function deleteCompanyWebsite(companyId, websiteId, data) {
  return apiAction({
    url: `${KADENCE_COMPANY}/${companyId}/websites/${websiteId}`,
    method: 'DELETE',
    label: DELETE_COMPANY_WEBSITE,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Deleted',
  })
}

// contacts -------------------->
// add phone contact
export function addContactPhone(contactId, data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/${contactId}/phones`,
    method: 'POST',
    label: ADD_PHONE_CONTACT,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Added a new phone',
  })
}

// update phone contact
export function updateContactPhone(contactId, phoneId, data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/${contactId}/phones/${phoneId}`,
    method: 'PUT',
    label: UPDATE_PHONE_CONTACT,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Updated',
  })
}

// delete phone contact
export function deleteContactPhone(contactId, phoneId, data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/${contactId}/phones/${phoneId}`,
    method: 'DELETE',
    label: DELETE_CONTACT_PHONE,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Deleted',
  })
}

// add email contact
export function addContactEmail(contactId, data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/${contactId}/emails`,
    method: 'POST',
    label: ADD_EMAIL_CONTACT,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Added a new email',
  })
}

// update email contact
export function updateContactEmail(contactId, emailId, data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/${contactId}/emails/${emailId}`,
    method: 'PUT',
    label: UPDATE_EMAIL_CONTACT,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Updated',
  })
}

// delete email contact
export function deleteContactEmail(contactId, emailId, data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/${contactId}/emails/${emailId}`,
    method: 'DELETE',
    label: DELETE_CONTACT_EMAIL,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Deleted',
  })
}

// add website contact
export function addContactWebsite(contactId, data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/${contactId}/websites`,
    method: 'POST',
    label: ADD_CONTACT_WEBSITE,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Added a new website',
  })
}

// update contact website
export function updateContactWebsite(contactId, phoneId, data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/${contactId}/websites/${phoneId}`,
    method: 'PUT',
    label: UPDATE_CONTACT_WEBSITE,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Updated',
  })
}

// delete website contact
export function deleteContactWebsite(contactId, websiteId, data) {
  return apiAction({
    url: `${KADENCE_CONTACTS}/${contactId}/websites/${websiteId}`,
    method: 'DELETE',
    label: DELETE_CONTACT_WEBSITE,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Deleted',
  })
}

export function removeSocial(id, socialId, identifier) {
  const url =
    identifier === 'companies'
      ? `${KADENCE_COMPANY}/${id}/socials/${socialId}`
      : identifier === 'contacts'
      ? `${KADENCE_CONTACTS}/${id}/socials/${socialId}`
      : ''

  return apiAction({
    url,
    method: 'DELETE',
    label: REMOVE_SOCIAL,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Deleted',
  })
}

export function updateSocial(id, data, socialId, identifier) {
  const url =
    identifier === 'companies'
      ? `${KADENCE_COMPANY}/${id}/socials/${socialId}`
      : identifier === 'contacts'
      ? `${KADENCE_CONTACTS}/${id}/socials/${socialId}`
      : ''

  return apiAction({
    url,
    method: 'PUT',
    label: UPDATE_SOCIAL,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Updated',
  })
}
export function addSocial(id, data, identifier) {
  const url =
    identifier === 'companies'
      ? `${KADENCE_COMPANY}/${id}/socials`
      : identifier === 'contacts'
      ? `${KADENCE_CONTACTS}/${id}/socials`
      : ''

  return apiAction({
    url,
    method: 'POST',
    label: ADD_SOCIAL,
    data,
    tokenIdentity: 'crm',
    showToast: true,
    successMessage: 'Added a new social',
  })
}

// TAGS

export function getTagLabel() {
  return apiAction({
    url: `${KADENCE_SETTING}/labels`,
    method: 'GET',
    label: GET_ALL_TAG_LABEL,
  })
}

export function createTagSettings(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/labels`,
    method: 'POST',
    label: CREATE_TAG,
    data,
    showToast: true,
    successMessage: 'Tag Created',
  })
}

export function deleteTags(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/labels/${id}`,
    method: 'DELETE',
    label: DELETE_TAG,
    showToast: true,
    successMessage: 'Tag Deleted',
  })
}

export function getTag(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/labels/${id}`,
    method: 'GET',
    label: GET_TAG_SETTING,
  })
}

export function editTag(data, id) {
  return apiAction({
    url: `${KADENCE_SETTING}/labels/${id}`,
    method: 'PUT',
    label: EDIT_TAG_SETTING,
    data,
    showToast: true,
    successMessage: 'Tag Updated',
  })
}
