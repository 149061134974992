import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm, Controller} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoModalHeader,
  VmoModalContent,
  VmoButton,
  VmoModal,
  VmoModalActions,
  VmoFormField,
  VmoForm,
  VmoFormInput,
} from 'vmo-library'
import {get} from 'utils/helper'
import {taskEnteriesSchema} from 'validation/Projects/task.schema'

const UpdateModal = props => {
  const dispatch = useDispatch()

  const {
    isLoadingData,
    type: reducerType,
    successLabels = [],
  } = useSelector(state => {
    return get(['apiReducer'], state)
  })
  const {idTobeUpdated, updateAction, isModalOpen, setIsModalOpen, setValue, taskId, deleteModelHeader, type} = props

  useEffect(() => {
    if (successLabels.includes(type)) {
      setIsModalOpen(false)
    }
  }, [successLabels, type, setIsModalOpen])

  const initialtaskEntries = {
    title: setValue.description,
    duration: setValue.duration,
  }

  const {
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
  } = useForm({
    resolver: joiResolver(taskEnteriesSchema),
    mode: 'onTouched',
    submitFocusError: true,
    defaultValues: initialtaskEntries,
  })

  const submitEntries = data => {
    if (taskId) {
      dispatch(
        updateAction(idTobeUpdated, {
          description: data.title,
          updatedDuration: data.duration,
        })
      )
    } else {
      dispatch(
        updateAction({
          entryId: idTobeUpdated,
          description: data.title,
          updatedDuration: data.duration,
        })
      )
    }

    setIsModalOpen(false)
  }
  return (
    <VmoModal closeIcon size="tiny" centered={false} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <VmoModalHeader className="vmoModalHeader">{deleteModelHeader || 'Update Entries Task'}</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel" style={{overflow: 'hidden'}}>
          <VmoFormField height={4}>
            <label>Title</label>
            <Controller
              name="title"
              control={control2}
              render={({onChange, value}) => (
                <VmoFormInput
                  value={value}
                  placeholder="Enter Title.."
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors2.title && {
                      content: errors2.title.message,
                    }
                  }
                />
              )}
            />
          </VmoFormField>
          <div style={{display: 'flex', alignItems: 'center'}} className="pb-4">
            <VmoFormField widths={2}>
              <label>Duration</label>
              <Controller
                name="duration"
                control={control2}
                render={({onChange, value}) => (
                  <VmoFormInput
                    value={value}
                    placeholder="Duration"
                    onChange={e => onChange(e.target.value)}
                    error={
                      errors2.duration && {
                        content: errors2.duration.message,
                      }
                    }
                  />
                )}
              />
            </VmoFormField>
          </div>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary loading={isLoadingData && reducerType.includes(type)} onClick={handleSubmit2(submitEntries)}>
          Update
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default UpdateModal
