import React, {useState} from 'react'
import {VmoFormInput} from 'vmo-library'
import SvgIcon from './SvgIcon'

const SearchComponent = props => {
  const {onSuccess} = props
  const [time, setTime] = useState(0)
  const [searchText, setSearchText] = useState('')

  const doSearch = evt => {
    const searchText = evt.target.value // this is the search text
    if (time) clearTimeout(time)
    setTime(
      setTimeout(() => {
        onSuccess(searchText)
      }, 300)
    )
  }

  return (
    <VmoFormInput
      onChange={e => {
        doSearch(e)
        setSearchText(e.target.value)
      }}
      value={searchText}
      icon={
        searchText.length > 0 ? (
          <i className="link icon">
            <SvgIcon
              path="common/close"
              style={{marginTop: '10px'}}
              onClick={() => {
                setSearchText('')
                onSuccess('')
              }}
            />
          </i>
        ) : (
          <i className="search icon" />
        )
      }
      placeholder="Search"
    />
  )
}

export default SearchComponent
