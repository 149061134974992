import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {Controller} from 'react-hook-form'
import {
  VmoCard,
  VmoCardContent,
  VmoCardDescription,
  VmoCardHeader,
  VmoFormField,
  VmoGrid,
  VmoGridColumn,
  VmoIcon,
  VmoTooltip,
} from 'vmo-library'

const BudgetTypeForm = ({control, budgetTypes = []}) => {
  return (
    <VmoFormField required>
      <div className="info-header">
        <label>Select budget type</label>

        <VmoTooltip
          trigger={<SvgIcon path="common/question" />}
          content="Select a budget type of your project"
          size="mini"
          psoition="top center"
        />
      </div>
      <Controller
        name="budgetType"
        control={control}
        render={({value, onChange}) => (
          <VmoGrid columns="equal">
            {budgetTypes.map(({title, type, description}) => (
              <VmoGridColumn>
                <VmoCard
                  fluid
                  className={`project-radio-box text-center ${value === type ? 'checked' : ''}`}
                  onClick={() => onChange(type)}
                >
                  <VmoCardContent>
                    <VmoIcon name="folder" style={{fontSize: '32px'}} />
                    <VmoCardHeader>{title}</VmoCardHeader>
                    <VmoCardDescription>{description}</VmoCardDescription>
                    <div className="circle">
                      <VmoIcon name="check" />
                    </div>
                  </VmoCardContent>
                </VmoCard>
              </VmoGridColumn>
            ))}
          </VmoGrid>
        )}
      />
    </VmoFormField>
  )
}

export default BudgetTypeForm
