/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {myActivities} from 'actions/home'
import {MY_ACTIVITIES} from 'actions/types'
import {FiChevronRight} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {getFormattedDate, startCase} from 'utils/helper'
import {
  VmoLabel,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import SvgIcon from 'components/common/SvgIcon'

function MyActivities() {
  const dispatch = useDispatch()

  const [activeAccordion, setActiveAccordion] = useState(-1)

  const {myActivitiesData = {}} = useSelector(state => state.home)
  const {dealData = [], projectTaskData = [], proofData = [], taskData = []} = myActivitiesData
  const {type = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    dispatch(myActivities())
  }, [dispatch])

  const renderTableBody = () => {
    if (type.includes(MY_ACTIVITIES)) {
      return <VmoTablePlaceholder columns={4} />
    }
    return (
      <>
        <VmoTableRow style={{cursor: 'pointer'}} onClick={() => setActiveAccordion(activeAccordion === 1 ? '-1' : 1)}>
          <VmoTableCell colspan="4" className="d-flex" style={{justifyContent: 'flex-start'}}>
            <h5>
              {activeAccordion === 1 ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}
              <SvgIcon path="navigation/projects" /> <span>Projects</span>
            </h5>
          </VmoTableCell>
        </VmoTableRow>
        {projectTaskData?.length > 0 ? (
          projectTaskData?.map(({title, stageData, createdby, endDate, type, projectDetails}, index) => (
            <VmoTableRow
              key={index}
              style={{
                display: activeAccordion === 1 ? '' : 'none',
              }}
            >
              <VmoTableCell className="pl-4 d-flex" style={{justifyContent: 'flex-start'}}>
                <div className="myActivities-title">
                  <span>{startCase(title)}</span>
                  <div className="extra-field">
                    <VmoTooltip
                      content="Type"
                      size="mini"
                      position="top center"
                      trigger={<VmoLabel color="#988e8e">{startCase(type)}</VmoLabel>}
                    />
                    <VmoTooltip
                      content="Project Title"
                      size="mini"
                      position="top center"
                      trigger={<VmoLabel color="#988e8e">{startCase(projectDetails?.title)}</VmoLabel>}
                    />
                  </div>
                </div>
              </VmoTableCell>
              <VmoTableCell>{startCase(stageData?.name)}</VmoTableCell>
              <VmoTableCell>{getFormattedDate(endDate)}</VmoTableCell>
              <VmoTableCell>{createdby}</VmoTableCell>
            </VmoTableRow>
          ))
        ) : (
          <VmoTableRow
            style={{
              display: activeAccordion === 1 ? '' : 'none',
            }}
          >
            <VmoTableCell colSpan="4" className="emptyValue">
              No Data Available
            </VmoTableCell>
          </VmoTableRow>
        )}

        <VmoTableRow style={{cursor: 'pointer'}} onClick={() => setActiveAccordion(activeAccordion === 2 ? '-1' : 2)}>
          <VmoTableCell className="d-flex" style={{justifyContent: 'flex-start', borderTop: 'none'}}>
            <h5>
              {activeAccordion === 2 ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}
              <SvgIcon path="navigation/deals" /> <span>Deals</span>
            </h5>
          </VmoTableCell>
        </VmoTableRow>
        {dealData?.length > 0 ? (
          dealData?.map(({title, stageData, createdby, endDate, monetaryValue}, index) => (
            <VmoTableRow
              key={index}
              style={{
                display: activeAccordion === 2 ? '' : 'none',
              }}
            >
              <VmoTableCell className="pl-4 d-flex" style={{justifyContent: 'flex-start'}}>
                <div className="myActivities-title">
                  <span>{startCase(title)}</span>

                  <VmoTooltip
                    content="Deals Value"
                    size="mini"
                    position="top center"
                    trigger={<VmoLabel color="#988e8e">{monetaryValue}</VmoLabel>}
                  />
                </div>
              </VmoTableCell>
              <VmoTableCell>{startCase(stageData?.stage)}</VmoTableCell>
              <VmoTableCell>{getFormattedDate(endDate)}</VmoTableCell>
              <VmoTableCell>{createdby}</VmoTableCell>
            </VmoTableRow>
          ))
        ) : (
          <VmoTableRow
            style={{
              display: activeAccordion === 2 ? '' : 'none',
            }}
          >
            <VmoTableCell colSpan="4" className="emptyValue">
              No Data Available
            </VmoTableCell>
          </VmoTableRow>
        )}

        <VmoTableRow style={{cursor: 'pointer'}} onClick={() => setActiveAccordion(activeAccordion === 3 ? '-1' : 3)}>
          <VmoTableCell className="d-flex" style={{justifyContent: 'flex-start', borderTop: 'none'}}>
            <h5>
              {activeAccordion === 3 ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}
              <SvgIcon path="navigation/tasks" /> <span>Tasks</span>
            </h5>
          </VmoTableCell>
        </VmoTableRow>
        {taskData?.length > 0 ? (
          taskData?.map(({title, stage, createdby, endAt, type}, index) => (
            <VmoTableRow
              key={index}
              style={{
                display: activeAccordion === 3 ? '' : 'none',
              }}
            >
              <VmoTableCell className="pl-4 d-flex" style={{justifyContent: 'flex-start'}}>
                <div className="myActivities-title">
                  <span>{startCase(title)}</span>
                  <VmoTooltip
                    content="Type"
                    size="mini"
                    position="top center"
                    trigger={<VmoLabel color="#988e8e">{startCase(type)}</VmoLabel>}
                  />
                </div>
              </VmoTableCell>
              <VmoTableCell>{startCase(stage)}</VmoTableCell>
              <VmoTableCell>{getFormattedDate(endAt)}</VmoTableCell>
              <VmoTableCell>{createdby}</VmoTableCell>
            </VmoTableRow>
          ))
        ) : (
          <VmoTableRow
            style={{
              display: activeAccordion === 3 ? '' : 'none',
            }}
          >
            <VmoTableCell colSpan="4" className="emptyValue">
              No Data Available
            </VmoTableCell>
          </VmoTableRow>
        )}

        <VmoTableRow style={{cursor: 'pointer'}} onClick={() => setActiveAccordion(activeAccordion === 4 ? '-1' : 4)}>
          <VmoTableCell className="d-flex" style={{justifyContent: 'flex-start', borderTop: 'none'}}>
            <h5>
              {activeAccordion === 4 ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}
              <SvgIcon path="navigation/proofs" /> <span>Proofs</span>
            </h5>
          </VmoTableCell>
        </VmoTableRow>
        {proofData?.length > 0 ? (
          proofData?.map(({title, stageData, createdby, dueDate, projectDetails}, index) => (
            <VmoTableRow
              key={index}
              style={{
                display: activeAccordion === 4 ? '' : 'none',
              }}
            >
              <VmoTableCell className="pl-4 d-flex" style={{justifyContent: 'flex-start'}}>
                <div className="myActivities-title">
                  <span>{startCase(title)}</span>
                  <VmoTooltip
                    content="Project Title"
                    size="mini"
                    position="top center"
                    trigger={<VmoLabel color="#988e8e">{startCase(projectDetails?.title)}</VmoLabel>}
                  />
                </div>
              </VmoTableCell>
              <VmoTableCell>{startCase(stageData?.name)}</VmoTableCell>
              <VmoTableCell>{getFormattedDate(dueDate)}</VmoTableCell>
              <VmoTableCell>{createdby}</VmoTableCell>
            </VmoTableRow>
          ))
        ) : (
          <VmoTableRow
            style={{
              display: activeAccordion === 4 ? '' : 'none',
            }}
          >
            <VmoTableCell colSpan="4" className="emptyValue">
              No Data Available
            </VmoTableCell>
          </VmoTableRow>
        )}
      </>
    )
  }

  return (
    <div className="myActivitiesBody">
      <div className="myActivityContent">
        <VmoTable basic style={{border: 'none', marginTop: '20px'}}>
          <VmoTableHeader>
            <VmoTableRow>
              <VmoTableHeaderCell style={{borderBottom: 'none', color: '#001f3d', opacity: '0.7', fontSize: '13px'}}>
                Activities
              </VmoTableHeaderCell>
              <VmoTableHeaderCell style={{borderBottom: 'none', color: '#001f3d', opacity: '0.7', fontSize: '13px'}}>
                Stage
              </VmoTableHeaderCell>
              <VmoTableHeaderCell style={{borderBottom: 'none', color: '#001f3d', opacity: '0.7', fontSize: '13px'}}>
                Due Date
              </VmoTableHeaderCell>
              <VmoTableHeaderCell style={{borderBottom: 'none', color: '#001f3d', opacity: '0.7', fontSize: '13px'}}>
                Created By
              </VmoTableHeaderCell>{' '}
            </VmoTableRow>
          </VmoTableHeader>

          <VmoTableBody>{renderTableBody()}</VmoTableBody>
        </VmoTable>
      </div>
    </div>
  )
}

export default MyActivities
