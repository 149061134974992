import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min'
import {VmoDropdown, VmoDropdownItem, VmoDropdownMenu, VmoTab, VmoTabPane} from 'vmo-library'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useApiResponse from 'hooks/impure/useApiResponse'
import {deleteProjectsTemplate, getProjectsSingleTemplate} from 'actions/projects'
import {get, startCase} from 'utils/helper'
import {FiPenTool, FiTrash2} from 'react-icons/fi'
import {DELETE_PROJECTS_TEMPLATE, UPDATE_PROJECTS_TEMPLATE} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import TemplateTasks from './TemplateSteps/Tasks/TemplateTasks'
import TemplateFinance from './TemplateSteps/Finance/TemplateFinance'
import EditProjectTemplatesModal from './EditProjectTemplatesModal'

const steps = [
  {name: 'Tasks', path: 'tasks', icon: 'tasks'},
  {name: 'Finance', path: 'finance', icon: 'money bill alternate'},
]

const ProjectTemplateInsideSteps = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {templateId} = useParams()
  const {pathname} = useLocation()
  const {url, path} = useRouteMatch()

  const currentPage = pathname.split('/')[4]
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsPermissions} = useUserPermissions()
  const manageProjectsPermissions = projectsPermissions?.manage_projects

  const {data: projectsTemplateData = {}, callAction} = useApiResponse({
    action: getProjectsSingleTemplate,
    payload: templateId,
    dependency: [templateId],
    enabled: templateId,
    storePath: 'projects.projectsTemplateData',
  })

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECTS_TEMPLATE)) {
      callAction()
    }
  }, [successLabels, callAction])

  useEffect(() => {
    if (successLabels.includes(DELETE_PROJECTS_TEMPLATE)) {
      setIsDeleteModalOpen(false)
      history.push('/projects/templates')
    }
  }, [successLabels, history])

  return (
    <>
      <div className="project-steps-container">
        <VmoTab
          key={currentPage}
          panes={steps.map(item => ({
            menuItem: {
              key: item.name,
              icon: item.icon,
              content: item.name,
            },
            render: null,
          }))}
          menu={{color: 'blue', secondary: true, pointing: true}}
          activeIndex={steps.findIndex(item => item.path === currentPage)}
          onTabChange={(e, {activeIndex}) => {
            history.push(`${url}/${steps[activeIndex].path}`)
          }}
        />

        <Switch>
          <Route
            path={`${path}/tasks`}
            exact
            render={props => (
              <VmoTabPane>
                <TemplateTasks {...props} rolePermissions={manageProjectsPermissions} />
              </VmoTabPane>
            )}
          />

          <Route
            path={`${path}/finance`}
            render={() => (
              <VmoTabPane>
                <TemplateFinance />
              </VmoTabPane>
            )}
          />
        </Switch>

        <div className="d-flex project-steps-title">
          <h5 className="d-flex">
            {startCase(get(['title'], projectsTemplateData, ''))}
            {manageProjectsPermissions && (
              <VmoDropdown className="project-name-dropdown" icon="chevron down" direction="right">
                <VmoDropdownMenu>
                  <VmoDropdownItem onClick={() => setIsEditModalOpen(true)}>
                    <FiPenTool /> Edit
                  </VmoDropdownItem>
                  <VmoDropdownItem
                    onClick={() => {
                      setIsDeleteModalOpen(true)
                    }}
                  >
                    <FiTrash2 /> Delete
                  </VmoDropdownItem>
                </VmoDropdownMenu>
              </VmoDropdown>
            )}
          </h5>
        </div>
      </div>

      {isEditModalOpen && (
        <EditProjectTemplatesModal open={isEditModalOpen} toggle={setIsEditModalOpen} id={templateId} />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectsTemplate(templateId))}
          type={DELETE_PROJECTS_TEMPLATE}
        />
      )}
    </>
  )
}

export default ProjectTemplateInsideSteps
