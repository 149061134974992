import {
  EMPLOYEES_STATS,
  EMPLOYEES_BY_STATUS,
  EMPLOYEES_BY_TYPE,
  EMPLOYEES_BY_DEPARTMENT,
  EMPLOYEES_TIME_TREND,
  NEW_EMPLOYEE_BY_DEPARTMENT,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case EMPLOYEES_STATS:
      return {
        ...state,
        employeesStatsData: action.payload,
      }

    case EMPLOYEES_BY_STATUS:
      return {
        ...state,
        employeesByStatusData: action.payload,
      }

    case EMPLOYEES_BY_TYPE:
      return {
        ...state,
        employeesByTypeData: action.payload,
      }

    case EMPLOYEES_BY_DEPARTMENT:
      return {
        ...state,
        employeesByTypeDepartment: action.payload,
      }

    case EMPLOYEES_TIME_TREND:
      return {
        ...state,
        employeeTimetrendData: action.payload,
      }

    case NEW_EMPLOYEE_BY_DEPARTMENT:
      return {
        ...state,
        newEmployeeByDepartmentData: action.payload,
      }

    default:
      return state
  }
}
