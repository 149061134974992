import React from 'react'
import {Controller} from 'react-hook-form'
import _ from 'lodash'
import {VmoButton, VmoFormInput, VmoFormSelect, VmoTooltip} from 'vmo-library'
import {addSocialOptionList} from 'constants/variables'
import SvgIcon from './SvgIcon'

const SocialCreateMultilple = props => {
  const {socialFields, socialFieldsName, errors, appendSocial, control, removeSocial} = props
  return (socialFields || []).map((socialItem, index) => (
    <div className="mutiple">
      <div key={index} className={index !== 0 && 'mt-3'}>
        <div className="hover-plusicon-Social mt-3">
          <Controller
            defaultValue={socialItem.url}
            name={`${socialFieldsName}[${index}].url`}
            render={({onChange, value}) => (
              <>
                <Controller
                  name={`${socialFieldsName}[${index}].category`}
                  defaultValue={socialItem.category}
                  render={({onChange, onBlur, value}) => (
                    <VmoFormSelect
                      className="m-0"
                      fluid
                      width={4}
                      options={addSocialOptionList}
                      selection
                      placeholder="Social category"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        _.get(errors, `${socialFieldsName}[${index}].category`) && {
                          content: _.get(errors, `${socialFieldsName}[${index}].category.message`),
                        }
                      }
                    />
                  )}
                  control={control}
                />
                <VmoFormInput
                  type="text"
                  fluid
                  className="m-0 ml-3"
                  width={4}
                  value={value}
                  placeholder="Type URL"
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    _.get(errors, `${socialFieldsName}[${index}].url`) && {
                      content: _.get(errors, `${socialFieldsName}[${index}].url.message`),
                    }
                  }
                />
              </>
            )}
            control={control}
          />

          <div className="d-flexb ml-3">
            <div className="ShowAddBtn">
              <VmoTooltip
                size="mini"
                position="top center"
                content="Remove"
                trigger={
                  <VmoButton
                    icon
                    style={{padding: '0.65571429em'}}
                    onClick={() => {
                      removeSocial(index)
                    }}
                  >
                    <SvgIcon path="common/delete" />
                  </VmoButton>
                }
              />
            </div>
            {index === socialFields.length - 1 && _.size(socialFields) < 3 && (
              <div className="ShowAddBtn">
                <VmoTooltip
                  size="mini"
                  position="top center"
                  content="Add"
                  trigger={
                    <VmoButton
                      icon
                      style={{padding: '0.65571429em'}}
                      onClick={() => {
                        appendSocial({category: '', url: ''})
                      }}
                    >
                      <SvgIcon path="common/plus" />
                    </VmoButton>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ))
}

export default SocialCreateMultilple
