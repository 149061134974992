import {NPS_PERFORMANCE_ANALYTICS, USER_RATINGS} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case NPS_PERFORMANCE_ANALYTICS:
      return {...state, npsPerformanceData: payload}

    case USER_RATINGS:
      return {...state, userRatingsData: payload}

    default:
      return state
  }
}
