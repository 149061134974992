import {apiAction} from './api'
import {KADENCE_ACCOUNT_V1, KADENCE_SETTING, USER_USERS} from './endpoint'
import {
  CREATE_TEAMS,
  DELETE_TEAMS,
  EDIT_TEAMS,
  EDIT_USER,
  GET_GENERAL_SETTING_ORGANIZATION,
  GET_TEAMS_LIST,
  GET_TEAM_DATA,
  GET_USER,
  UPDATE_GENERAL_SETTING_ORGANIZATION,
  CREATE_ADDRESS,
  GET_ALL_ADDRESSESS,
  DELETE_ADDRESS,
  UPDATE_ADDRESS,
  GET_ADDRESS_DATA,
} from './types'

export function getUser(id, data) {
  return apiAction({
    url: `${USER_USERS}/${id}`,
    method: 'GET',
    label: GET_USER,
    data,
    token: 'account',
  })
}

export function editUser(data, id) {
  return apiAction({
    url: `${USER_USERS}/${id}`,
    method: 'PUT',
    label: EDIT_USER,
    data,
    token: 'account',
    showToast: true,
    successMessage: 'User Updated',
  })
}

export function getAllTeams() {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/teams`,
    method: 'GET',
    label: GET_TEAMS_LIST,
  })
}

export function createTeams(data) {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/teams`,
    method: 'POST',
    label: CREATE_TEAMS,
    data,
    showToast: true,
    successMessage: 'Teams Created',
  })
}

export function deleteTeam(id) {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/teams/${id}`,
    method: 'DELETE',
    label: DELETE_TEAMS,
    showToast: true,
    successMessage: 'Team Deleted',
  })
}

export function getTeamsData(id) {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/teams/${id}`,
    method: 'GET',
    label: GET_TEAM_DATA,
  })
}

export function editTeams(id, data) {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/teams/${id}`,
    method: 'PUT',
    label: EDIT_TEAMS,
    data,
    showToast: true,
    successMessage: 'Teams Updated',
  })
}

export function getGeneralSettingsOrganization() {
  return apiAction({
    url: `${KADENCE_SETTING}/general`,
    method: 'GET',
    label: GET_GENERAL_SETTING_ORGANIZATION,
  })
}

export function updateGeneralSettingsOrganization(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/general`,
    method: 'POST',
    data,
    showToast: true,
    successMessage: 'Settings Updated',
    label: UPDATE_GENERAL_SETTING_ORGANIZATION,
  })
}

export function createAddress(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/general/address`,
    method: 'POST',
    label: CREATE_ADDRESS,
    data,
    showToast: true,
    successMessage: 'Address Added',
  })
}

export function getAddressData(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/general/address/${id}`,
    method: 'GET',
    label: GET_ADDRESS_DATA,
  })
}

export function updateAddress(data, id) {
  return apiAction({
    url: `${KADENCE_SETTING}/general/address/${id}`,
    method: 'PUT',
    label: UPDATE_ADDRESS,
    data,
    showToast: true,
    successMessage: 'Address Update',
  })
}

export function getAllAddressess() {
  return apiAction({
    url: `${KADENCE_SETTING}/general/addresses`,
    method: 'GET',
    label: GET_ALL_ADDRESSESS,
  })
}

export function deleteAddress(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/general/address/${id}`,
    method: 'DELETE',
    label: DELETE_ADDRESS,
    showToast: true,
    successMessage: 'Address Deleted',
  })
}
