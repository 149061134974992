/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoForm,
  VmoFormDropdown,
  VmoFormField,
  VmoFormInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'
import {getTagLabel} from 'actions/multiple_fields'
import {CREATE_TAG, EDIT_TAG_SETTING} from 'actions/types'
import {labelSchema} from 'validation/common.schema'
import {removeDoubleQuotes} from 'utils/helper'
import {colorOptions} from 'constants/variables'
import SvgIcon from './SvgIcon'

const CreateLabel = props => {
  const {handleSubmit, errors, control, reset, formState} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(labelSchema),
    defaultValues: {
      label: '',
      color: '#49A8FF',
    },
  })

  const {isDirty} = formState

  const {isModalOpen, setIsModalOpen, createMethod, action, contactIds, conversationIds, editId} = props

  const dispatch = useDispatch()

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {getTagData} = useSelector(state => state.multipleFields)

  useEffect(() => {
    if (successLabels.includes(CREATE_TAG)) {
      setIsModalOpen(false)
      dispatch(getTagLabel())
    }
    if (successLabels.includes(EDIT_TAG_SETTING)) {
      dispatch(getTagLabel())
      setIsModalOpen(false)
    }
  }, [successLabels])

  useEffect(() => {
    if (editId) {
      reset(_.omit(getTagData, ['description', 'slug', 'id']))
    }
  }, [editId, getTagData, reset])

  const handleSubmittedData = data => {
    if (action) return dispatch(action(data, editId))

    if (contactIds) {
      data.contactIds = contactIds
    }
    if (conversationIds) {
      data.conversationIds = conversationIds
    }
    if (createMethod) return createMethod(data)
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="mini"
      centered={false}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <VmoModalHeader>{action ? 'Edit' : 'Create'} Tag</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField>
            <label>Tag Name</label>

            <Controller
              control={control}
              name="label"
              render={({value, onChange}) => (
                <VmoFormInput
                  maxLength={20}
                  required
                  placeholder="Write tag name.."
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.label && {
                      content: removeDoubleQuotes(errors.label.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
          <VmoFormField>
            <label>Choose Color</label>
            <Controller
              control={control}
              name="color"
              render={({value, onChange}) => (
                <VmoFormDropdown
                  placeholder="select color"
                  selection
                  options={colorOptions}
                  onChange={(e, {value}) => onChange(value)}
                  value={value}
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton disabled={isLoadingData && type.includes(CREATE_TAG)} basic onClick={() => setIsModalOpen(false)}>
          Close
        </VmoButton>
        <VmoButton
          primary
          loading={isLoadingData && type.includes(CREATE_TAG)}
          disabled={!isDirty || (isLoadingData && type.includes(CREATE_TAG))}
          onClick={handleSubmit(data => handleSubmittedData(data))}
        >
          {action ? 'Update' : 'Create'} Tag
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default CreateLabel
