/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {useHistory} from 'react-router'
import axios from 'axios'
import _ from 'lodash'
import {
  VmoAttachment,
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoDivider,
  VmoEditorSmall,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoGrid,
  VmoGridColumn,
  VmoList,
  VmoListItem,
} from 'vmo-library'

import {CREATE_TEMPLATE, EDIT_SINGLE_TEMPLATE, GET_SINGLE_TEMPLATE} from 'actions/types'
import {uploadAttachments} from 'actions/attachments'
import {getAllMailInbox} from 'actions/inbox'
import {
  createQuickReply,
  deleteTemplateAttachment,
  editEmailTemplate,
  getEmailTemplate,
  singleEmailTemplate,
} from 'actions/settings'
import SettingLayout from 'layouts/settingLayout'
import {removeDoubleQuotes, startCase} from 'utils/helper'
import AttachmentPreview from 'components/common/AttachmentPreview'
import {EmailTemplateFormSchema} from 'validation/Settings/productivity/emailTemplate.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'

const EmailTemplateForm = props => {
  const {editId, setCreateCanResponse} = props
  const {CancelToken} = axios
  const requestToken = CancelToken.source()
  const dispatch = useDispatch()
  const history = useHistory()

  const [listMailBox, setListMailBox] = useState([])
  const [files, setFiles] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])

  // attachmentIds
  const [placeholder, setPlaceHolder] = useState(false)
  const [placeholderList, setPlaceHolderList] = useState([])
  const [attachmentsToDelete, setAttachmentsToDelete] = useState([])

  const {singleResponse} = useSelector(state => state.settings)
  const {inbox: mailBoxList} = useSelector(state => state.mailInbox)
  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const cannedResponsesAddLearnMore = settingsLearnMoreUrl?.productivity?.cannedResponses?.addLearnMore
  const cannedResponsesUpdateLearnMore = settingsLearnMoreUrl?.productivity?.cannedResponses?.updateLearnMore

  const {productivityPermissions} = useUserPermissions()
  const manageCannedReponsesPermissions = productivityPermissions?.manage_canned_reponses

  const defaultValue = {
    title: '',
    subject: '',
    body: '',
    applyTo: 'me',
    inboxIds: [],
    placeholders: [],
    attachmentIds: [],
  }

  const {handleSubmit, errors, control, watch, reset, formState, getValues, setValue} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(EmailTemplateFormSchema),
    defaultValues: defaultValue,
  })
  const {isDirty} = formState
  const watchApplyTo = watch('applyTo')

  const handleReset = () => {
    if (editId) {
      setUploadedFiles(
        singleResponse?.attachments?.map(({id, name, size, type}) => {
          return {id, name, size, type}
        })
      )
      reset(
        _.omit(singleResponse, [
          'createdAt',
          'createdBy',
          'updatedAt',
          'updatedBy',
          'openCount',
          'replyCount',
          'sendCount',
          'id',
          'accountId',
          'userId',
        ])
      )
    } else {
      reset(defaultValue)
    }
  }

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)
    window.onpopstate = () => {
      setCreateCanResponse ? setCreateCanResponse(false) : history.push('/settings/email-templates')
    }
    return () => (window.onpopstate = () => {})
  }, [dispatch])

  useEffect(() => {
    if (editId) dispatch(singleEmailTemplate(editId))
    if (!mailBoxList) dispatch(getAllMailInbox())
  }, [])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_TEMPLATE)) {
      handleReset()
    }
    if (successLabels.includes(CREATE_TEMPLATE)) {
      dispatch(getEmailTemplate())
      setCreateCanResponse(false)
    }
    if (successLabels.includes(EDIT_SINGLE_TEMPLATE)) {
      dispatch(singleEmailTemplate(editId))
      setAttachmentsToDelete([])
      history.push('/settings/email-templates')
    }
  }, [successLabels])

  useEffect(() => {
    if (mailBoxList) {
      const list = mailBoxList.map(inbox => ({
        key: inbox.id,
        value: inbox.id,
        text: inbox.name,
        content: (
          <div>
            {inbox.name}
            {!inbox.emailVerified && <small className="ml-2 kadence-lead">- not verified</small>}
          </div>
        ),
        disabled: !inbox.emailVerified,
      }))
      setListMailBox(list)
    }
  }, [mailBoxList])

  const handlePlaceholder = placeholder => {
    if (!placeholderList.includes(placeholder)) {
      setPlaceHolderList([...placeholderList, placeholder])
    }
    const editorValue = getValues('body')
    setValue('body', editorValue.concat(`<span> {{${placeholder}}} </span>`))
  }

  const RenderPlaceHolder = () => {
    const allPlaceholder = ['firstName', 'lastName', 'fullName', 'ticketNo', 'companyName', 'agentName', 'subject']

    return (
      <div className="insertPlaceHolder">
        <VmoCard>
          <VmoCardHeader className="p-4">
            Insert Placeholder
            <VmoButton floated="right" className="p-0" onClick={() => setPlaceHolder(false)}>
              <SvgIcon path="common/close" />
            </VmoButton>
          </VmoCardHeader>
          <VmoCardContent>
            <VmoList relaxed link>
              {allPlaceholder.map(placeholder => (
                <VmoListItem key={placeholder} as="a" onClick={() => handlePlaceholder(placeholder)}>
                  {startCase(placeholder)}
                </VmoListItem>
              ))}
            </VmoList>
          </VmoCardContent>
        </VmoCard>
      </div>
    )
  }

  const handleCannedResponse = data => {
    const newData = _.omit(data, 'attachments')
    if (placeholderList.length) newData.placeholders = placeholderList
    if (uploadedFiles?.length > 0) {
      newData.attachmentIds = uploadedFiles.map(file => file.id)
    }
    const newList = _.pickBy(newData)
    if (editId) {
      dispatch(editEmailTemplate(editId, newList))

      if (attachmentsToDelete.length > 0) {
        dispatch(deleteTemplateAttachment(editId, attachmentsToDelete))
      }
    } else {
      dispatch(createQuickReply(newList))
    }
  }

  // Note :- temporary we make "email templates" to "canned response"
  return (
    <>
      <SettingLayout
        // header={editId ? 'Edit Email Templates' : 'Create Email Templates'}
        header={editId ? 'Edit Canned Response' : 'Create Canned Response'}
        headerbackbtn={{
          onClick: () => {
            if (editId) {
              history.push('/settings/email-templates')
              handleReset()
            } else {
              setCreateCanResponse(false)
            }
          },
        }}
        // subHeader="Add details to create your customised email template"
        subHeader="Add details to create your customised canned response"
        learnMoreUrl={editId ? cannedResponsesUpdateLearnMore : cannedResponsesAddLearnMore}
        table={false}
        headerDivider
        actionButton={{
          cancel: {
            content: 'Cancel',
            disabled: isLoadingData && type.includes(EDIT_SINGLE_TEMPLATE || CREATE_TEMPLATE),
            onClick: () => {
              if (editId) {
                history.push('/settings/email-templates')
                handleReset()
              } else {
                setCreateCanResponse(false)
              }
            },
          },
          success: {
            content: editId ? 'Update' : 'Create',
            loading: isLoadingData && type.includes(EDIT_SINGLE_TEMPLATE || CREATE_TEMPLATE),
            ...(manageCannedReponsesPermissions && {
              onClick: handleSubmit(data => handleCannedResponse(data)),
            }),
            disabled: !isDirty || (isLoadingData && type.includes(EDIT_SINGLE_TEMPLATE || CREATE_TEMPLATE)),
          },
        }}
        lockRole={!manageCannedReponsesPermissions}
      >
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Response Title</label>
            <Controller
              name="title"
              control={control}
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  maxLength={30}
                  placeholder="Response Title"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  onBlur={onBlur}
                  error={
                    errors.title && {
                      content: removeDoubleQuotes(errors.title.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required>
            <label>Subject</label>
            <Controller
              name="subject"
              control={control}
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  maxLength={30}
                  placeholder="Subject"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  onBlur={onBlur}
                  error={
                    errors.subject && {
                      content: removeDoubleQuotes(errors.subject.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>

          <VmoFormField className="editoremail custom-upload-btn">
            <label>
              Response Message
              <VmoButton className="p-0" floated="right" size="tiny" onClick={() => setPlaceHolder(true)}>
                Insert Placeholder
              </VmoButton>
            </label>

            {placeholder && <RenderPlaceHolder />}

            <Controller
              name="body"
              control={control}
              render={({value, onChange, onBlur}) => (
                <VmoEditorSmall
                  style={{border: '1px solid #000'}}
                  setContents={value || ''}
                  onChange={content => onChange(content)}
                  onBlur={onBlur}
                />
              )}
            />

            <div className="attachmentText">
              {manageCannedReponsesPermissions ? (
                <VmoAttachment
                  content={
                    <>
                      <SvgIcon path="common/attachment" /> Attachment
                    </>
                  }
                  onClick={e => {
                    e.target.value = null
                  }}
                  fileList={files => {
                    setFiles(files)
                  }}
                  multiple
                  onChange={value => {
                    const data = {
                      file: value,
                    }
                    if (value.length > 0) {
                      dispatch(uploadAttachments(data, 'composeAttachment', requestToken))
                    }
                  }}
                />
              ) : (
                <LockPermissionTooltip isRoleAccessDenied={!manageCannedReponsesPermissions}>
                  <button type="button" className="ui icon button attach">
                    <SvgIcon path="common/attachment" /> Attachment
                  </button>
                </LockPermissionTooltip>
              )}

              <AttachmentPreview
                files={files}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                success="composeAttachment"
              />
            </div>
          </VmoFormField>

          <VmoFormField>
            {/* <label>Show this Email Template</label> */}
            <label>Show this Canned Response</label>
            <VmoGrid>
              <VmoGridColumn width={5}>
                <Controller
                  control={control}
                  name="applyTo"
                  render={({value, onChange}) => (
                    <VmoFormSelect
                      fluid
                      options={[
                        {key: 'me', value: 'me', text: 'Only to me'},
                        {
                          key: 'mailbox',
                          value: 'mailbox',
                          text: "Inbox's User",
                        },
                        {key: 'all', value: 'all', text: 'All'},
                      ]}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={errors.applyTo}
                    />
                  )}
                />
              </VmoGridColumn>
              <VmoGridColumn width={11}>
                {watchApplyTo === 'mailbox' && (
                  <VmoFormField required>
                    <Controller
                      control={control}
                      name="inboxIds"
                      render={({value, onChange}) => (
                        <VmoFormSelect
                          fluid
                          multiple
                          value={value}
                          options={listMailBox}
                          placeholder="Select Users"
                          onChange={(e, {value}) => onChange(value)}
                          error={errors.inboxIds}
                        />
                      )}
                    />
                  </VmoFormField>
                )}
              </VmoGridColumn>
            </VmoGrid>
          </VmoFormField>
        </VmoForm>
        <VmoDivider hidden />
      </SettingLayout>
    </>
  )
}

export default EmailTemplateForm
