import {apiAction} from './api'
import {KADENCE_ANALYTICS, KADENCE_PROJECTS} from './endpoint'
import {
  GET_PROJECTS_HEALTH_DATA,
  GET_PROJECTS_HEALTH_LIST,
  GET_PROJECTS_PLANNING_REPORT_UTILIZATION,
  GET_PROJECTS_TIMESHEET_TIMELOGS_BY_PROJECT_LIST,
  GET_PROJECTS_TIMESHEET_TIMELOGS_BY_USER_LIST,
  GET_PROJECTS_TIMESHEET_TIMELOGS_CHART,
  GET_PROJECTS_USERS_TASKS_BY_USER_LIST,
} from './types'

export function getProjectsHealthList(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/projects/projects-health/health-list`,
    method: 'POST',
    label: GET_PROJECTS_HEALTH_LIST,
    data,
  })
}

export function getProjectsHealthData(projectId) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/projects/projects-health/${projectId}`,
    method: 'GET',
    label: GET_PROJECTS_HEALTH_DATA,
  })
}

export function updateSelectedProject(id) {
  return {
    type: 'UPDATE_SELECTED_PROJECT',
    payload: id,
  }
}

export function getProjectsTimesheetTimelogsChart(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/projects/timesheet/timelogs-chart`,
    method: 'POST',
    data,
    label: GET_PROJECTS_TIMESHEET_TIMELOGS_CHART,
  })
}

export function getProjectsTimesheetTimelogsByProjectList(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/projects/timesheet/timelogs-by-project`,
    method: 'POST',
    data,
    label: GET_PROJECTS_TIMESHEET_TIMELOGS_BY_PROJECT_LIST,
  })
}

export function getProjectsTimesheetTimelogsByUserList(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/projects/timesheet/timelogs-by-user`,
    method: 'POST',
    data,
    label: GET_PROJECTS_TIMESHEET_TIMELOGS_BY_USER_LIST,
  })
}

export function getProjectsUsersTasksByUser(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/projects/users/tasks-by-user`,
    method: 'POST',
    data,
    label: GET_PROJECTS_USERS_TASKS_BY_USER_LIST,
  })
}

export function getProjectsPlanningReportUtilization(data) {
  return apiAction({
    url: `${KADENCE_PROJECTS}/planning/report/utilization`,
    method: 'GET',
    data,
    label: GET_PROJECTS_PLANNING_REPORT_UTILIZATION,
  })
}
