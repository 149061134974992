import {apiAction} from './api'
import {KADENCE_ACCOUNT_V1, KADENCE_SETTING} from './endpoint'
import {
  CAMPAIGN_ATTACHMENT_UPLOAD,
  CREATE_CAMPAIGN,
  CREATE_SMS_CAMPAIGN,
  DELETE_CAMPAIGN,
  DELETE_SMS_CAMPAIGN,
  GET_CAMPAIGN_DATA,
  GET_CAMPAIGN_FILTERS,
  GET_CAMPAIGN_LIST,
  GET_LABEL_TAGS,
  GET_SINGLE_SMS_CAMPAIGN,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_STATUS,
  UPDATE_SMS_CAMPAIGN,
  UPDATE_SMS_CAMPAIGN_STATUS,
} from './types'

export function getCampaignList() {
  return apiAction({
    url: `${KADENCE_SETTING}/campaign/list`,
    method: 'GET',
    label: GET_CAMPAIGN_LIST,
  })
}

export function getCampaignFilters() {
  return apiAction({
    url: `${KADENCE_SETTING}/campaign/getFilters`,
    method: 'GET',
    label: GET_CAMPAIGN_FILTERS,
  })
}

export function getCampaignData(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/campaign/${id}`,
    method: 'GET',
    label: GET_CAMPAIGN_DATA,
  })
}

export function createCampaign(data) {
  return apiAction({
    url: `${KADENCE_SETTING}/campaign/create`,
    method: 'POST',
    label: CREATE_CAMPAIGN,
    data,
    showToast: true,
    successMessage: 'Campaign Created',
  })
}

export function updateCampaign(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/campaign/${id}`,
    method: 'POST',
    label: UPDATE_CAMPAIGN,
    data,
    showToast: true,
    successMessage: 'Campaign Updated',
  })
}

export function updateCampaignStatus(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/campaign/${id}`,
    method: 'PUT',
    label: UPDATE_CAMPAIGN_STATUS,
    data,
    showToast: true,
    successMessage: 'Status Updated',
  })
}

export function deleteCampaign(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/campaign/${id}`,
    method: 'DELETE',
    label: DELETE_CAMPAIGN,
    data,
    showToast: true,
    successMessage: 'Campaign Deleted',
  })
}

export function uploadAttachment(data, successData) {
  return apiAction({
    url: `${KADENCE_ACCOUNT_V1}/conversations/commonFileUpload`,
    method: 'POST',
    label: CAMPAIGN_ATTACHMENT_UPLOAD,
    tokenIdentity: 'chat',
    contentType: 'multipart/form-data',
    data,
    successData,
  })
}

export function createEmailCampaign(data, contentType) {
  return apiAction({
    url: `${KADENCE_SETTING}/email-campaign/create`,
    method: 'POST',
    label: CREATE_CAMPAIGN,
    data,
    contentType,
    showToast: true,
    successMessage: 'Campaign Created',
  })
}

export function getEmailCampaignData(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/email-campaign/${id}`,
    method: 'GET',
    label: GET_CAMPAIGN_DATA,
  })
}

export function updateEmailCampaign(id, data, contentType) {
  return apiAction({
    url: `${KADENCE_SETTING}/email-campaign/${id}`,
    method: 'PUT',
    label: UPDATE_CAMPAIGN,
    data,
    contentType,
    showToast: true,
    successMessage: 'Campaign Updated',
  })
}

export function createSmsCampaign(data, contentType) {
  return apiAction({
    url: `${KADENCE_SETTING}/sms-campaign`,
    method: 'POST',
    label: CREATE_SMS_CAMPAIGN,
    data,
    contentType,
    showToast: true,
    successMessage: 'Sms Campaign Created',
  })
}

export function getsingleSmsCampaign(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/sms-campaign/${id}`,
    method: 'GET',
    label: GET_SINGLE_SMS_CAMPAIGN,
  })
}

export function updateSmsCampaign(data, id, contentType) {
  return apiAction({
    url: `${KADENCE_SETTING}/sms-campaign/${id}`,
    method: 'PUT',
    label: UPDATE_SMS_CAMPAIGN,
    data,
    contentType,
    showToast: true,
    successMessage: 'Sms Campaign Updated',
  })
}

export function deleteSmsCampaign(id) {
  return apiAction({
    url: `${KADENCE_SETTING}/sms-campaign/${id}`,
    method: 'DELETE',
    label: DELETE_SMS_CAMPAIGN,
    showToast: true,
    successMessage: 'Sms Campaign Deleted',
  })
}

export function updateSmsCampaignStatus(id, data) {
  return apiAction({
    url: `${KADENCE_SETTING}/sms-campaign/${id}/status`,
    method: 'PUT',
    label: UPDATE_SMS_CAMPAIGN_STATUS,
    data,
    showToast: true,
    successMessage: 'Status Updated',
  })
}

export function gatLabelTags() {
  return apiAction({
    url: `${KADENCE_SETTING}/labels/counts`,
    method: 'GET',
    label: GET_LABEL_TAGS,
  })
}
