/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {VmoButton, VmoModal, VmoModalActions, VmoModalContent, VmoModalHeader} from 'vmo-library'
import SvgIcon from './SvgIcon'

const DeleteModal = props => {
  const {isLoadingData, type: reducerType, successLabels = []} = useSelector(state => state.apiReducer)
  const {
    idTobeDeleted,
    deleteAction,
    isModalOpen,
    setIsModalOpen,
    modalText,
    onDelete,
    deleteModelHeader,
    type,
    deleteButtonText,
  } = props

  const dispatch = useDispatch()

  useEffect(() => {
    if (successLabels.includes(type)) {
      setIsModalOpen(false)
    }
  }, [successLabels])

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      centered={false}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <VmoModalHeader className="vmoModalHeader">{deleteModelHeader || 'Delete'}</VmoModalHeader>
      <VmoModalContent>{modalText || 'Are you sure you want to delete ?'}</VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </VmoButton>
        <VmoButton
          primary
          loading={isLoadingData && reducerType.includes(type)}
          onClick={() => {
            if (onDelete) {
              onDelete()
              setIsModalOpen(false)
            } else {
              dispatch(deleteAction(idTobeDeleted))
              setIsModalOpen(false)
            }
          }}
        >
          {deleteButtonText || 'Delete'}
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default DeleteModal
