/* eslint-disable no-use-before-define */
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiChevronRight, FiExternalLink} from 'react-icons/fi'
import _ from 'lodash'
import moment from 'moment'
import {
  VmoAccordion,
  VmoAccordionContent,
  VmoAccordionTitle,
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoGrid,
  VmoGridColumn,
  VmoIcon,
  VmoInput,
  VmoLabel,
  VmoList,
  VmoListContent,
  VmoListDescription,
  VmoListHeader,
  VmoListItem,
  VmoTooltip,
} from 'vmo-library'

import {editContact, uploadAttachmentsContact} from 'actions/contacts'
import {
  get,
  getFormattedDate,
  GetFormattedDate,
  getPermissionTooltipContent,
  removeDoubleQuotes,
  startCase,
  wrapText,
} from 'utils/helper'
import CreateTask from 'components/pages/Projects/Tasks/CreateTask'
import Tags from 'components/common/Tags'
import {editTaskActivities, getTaskActivities} from 'actions/activities'
import ViewTask from 'components/pages/Projects/Tasks/ViewTask'
import {addNoteInContacts} from 'actions/customers'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'

const ContactSummary = props => {
  const {contactId, getValues, errors, handleSubmit, updateContact, control, Controller, noteText, setNoteText} = props

  const dispatch = useDispatch()

  const refForm = useRef()
  const [activeIndex, setActiveIndex] = useState(0)
  const [userOption, setUserOption] = useState([])
  const [statusOptionList, setStatusOptionList] = useState([])
  const [typeList, setTypeList] = useState([])
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false)
  const [isViewTaskOpen, setIsViewTaskOpen] = useState(false)

  const {singleContact = {}, getTypeListData, contactStatusListData} = useSelector(state => state.contacts)
  const {interactions: getAllInterActionsData = [], interactionTime} = singleContact

  const {getUsersList = {}} = useSelector(state => state.settings)

  const {contactsAndCompaniesPermissions, tasksPermissions} = useUserPermissions()
  const manageContactsCompanyPermissions = contactsAndCompaniesPermissions?.manage_contacts_company
  const manageTasksPermissions = tasksPermissions?.manage_tasks

  useEffect(() => {
    if (getUsersList) {
      const list = getUsersList?.users?.map(user => ({
        value: user.id,
        text: `${get(['firstName'], user, '')} ${get(['lastName'], user, '')}`,
        key: user.id,
      }))
      setUserOption(list || [])
    }
  }, [getUsersList])

  useEffect(() => {
    if (contactStatusListData) {
      const list = contactStatusListData.map(item => ({
        value: item.id,
        text: item.status,
        key: item.id,
      }))
      setStatusOptionList(list)
    }
  }, [contactStatusListData])

  useEffect(() => {
    if (getTypeListData) {
      const companyList = getTypeListData.map(list => ({
        key: list.id,
        value: list.id,
        text: list.type,
      }))
      setTypeList(companyList)
    }
  }, [getTypeListData])

  const renderNotes = () => {
    const notesList = get(['notes'], singleContact, [])

    if (notesList.length > 0) {
      return notesList
        .filter((list, index) => index < 4)
        .map(list => (
          <VmoListItem>
            <VmoListContent floated="right">
              <span>{GetFormattedDate(get(['createdAt'], list, ''))}</span>
            </VmoListContent>

            <VmoListHeader>{get(['note'], list, '')}</VmoListHeader>
            <VmoListDescription>By : {get(['agent'], list, '')}</VmoListDescription>
          </VmoListItem>
        ))
    }
  }

  const renderCreateTaskModal = () => {
    if (isCreateTaskOpen) {
      return (
        <CreateTask
          setIsCreateTaskOpen={setIsCreateTaskOpen}
          isCreateTaskOpen={isCreateTaskOpen}
          conversation={get(['conversation'], props)}
          contactId={contactId}
          isDataAvailabel
        />
      )
    }
  }

  const renderSummary = () => {
    return (
      <VmoForm
        ref={refForm}
        className="editForm errorLabel"
        {...(manageContactsCompanyPermissions && {onSubmit: handleSubmit(updateContact)})}
      >
        <VmoFormField>
          <h5>Last Contacted</h5>
          <label className="kadence-lead">{interactionTime ? GetFormattedDate(interactionTime) : '-'}</label>
        </VmoFormField>
        <VmoFormField>
          <h5>Interactions</h5>
          <label className="kadence-lead">{getAllInterActionsData ? getAllInterActionsData.length : ''}</label>
        </VmoFormField>
        <VmoFormField>
          <h5>Inactive For</h5>
          <label className="kadence-lead">{interactionTime ? moment(interactionTime).fromNow() : '-'}</label>
        </VmoFormField>
        <VmoFormField>
          <div className="info-header">
            <label>Contact Type</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Select type of the contact"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="contactTypeId"
            render={({onChange, value, onBlur}) => (
              <VmoFormSelect
                fluid
                options={typeList}
                selection
                placeholder="Contact type"
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                  refForm.current.handleSubmit()
                }}
                onBlur={onBlur}
                disabled={!manageContactsCompanyPermissions}
                error={
                  errors?.contactTypeId && {
                    content: removeDoubleQuotes(errors?.contactTypeId?.message),
                  }
                }
              />
            )}
            control={control}
          />
        </VmoFormField>
        <VmoFormField>
          <div className="info-header">
            <label>Status</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Set current Status of contact"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="statusId"
            render={({onChange, value, onBlur}) => (
              <VmoFormSelect
                search
                fluid
                options={statusOptionList}
                selection
                placeholder="Status"
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                  refForm.current.handleSubmit()
                }}
                onBlur={onBlur}
                disabled={!manageContactsCompanyPermissions}
                error={
                  errors?.statusId && {
                    content: removeDoubleQuotes(errors?.statusId?.message),
                  }
                }
              />
            )}
            control={control}
          />
        </VmoFormField>
        <VmoFormField>
          <div className="info-header">
            <label>Assignee</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Assign an agent to this contact"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="assigneeId"
            render={({onChange, value, onBlur}) => (
              <VmoFormSelect
                search
                fluid
                options={userOption}
                selection
                placeholder="Owner"
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                  refForm.current.handleSubmit()
                }}
                onBlur={onBlur}
                disabled={!manageContactsCompanyPermissions}
                error={
                  errors?.assigneeId && {
                    content: removeDoubleQuotes(errors?.assigneeId?.message),
                  }
                }
              />
            )}
            control={control}
          />
        </VmoFormField>
      </VmoForm>
    )
  }

  const getProjectLabelColor = status => {
    if (status === 'active') {
      return 'yellow'
    }
    if (status === 'inactive') {
      return 'red'
    }
    if (status === 'archived') {
      return 'green'
    }
  }

  return (
    <div className="callwidget-accord">
      <VmoCard fluid>
        <VmoCardContent>
          <div className="staticData">
            <div className="dataOne">
              <p>Open Conversation</p>
              {/* <h4>{get(['openConversations'], contactDetails, '-')}</h4> */}
              <h4>-</h4>
            </div>
            <div className="dataOne">
              <p>Total Conversation</p>
              <h4>-</h4>
              {/* <h4>{get(['totalConversations'], contactDetails, '-')}</h4> */}
            </div>
          </div>

          <div className="staticData">
            <div className="dataOne">
              <p>Open Task</p>
              <h4>-</h4>
              {/* <h4>{get(['openTasks'], contactDetails, '-')}</h4> */}
            </div>
            <div className="dataOne">
              <p>Total Task</p>
              <h4>-</h4>
              {/* <h4>{get(['totalTasks'], contactDetails, '-')}</h4> */}
            </div>
          </div>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 0}
              index={0}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {!activeIndex ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Summary
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 0}>{renderSummary()}</VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 1}
              index={1}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 1 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Deals{' '}
              {get(['deals'], singleContact, []).length > 0 ? `(${get(['deals'], singleContact, []).length})` : ''}
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 1}>
              <VmoCardContent className="pt-0">
                {singleContact?.deals?.length > 0 ? (
                  singleContact?.deals?.map(deal => (
                    <VmoGrid key={deal.id}>
                      <VmoGridColumn width="12">
                        {/* <h2>{deal.name}</h2>
                        <p className="kadence-lead pb-2">Sales: {deal.status}</p> */}
                        <VmoList selection className="recentConv">
                          <VmoListItem>
                            <VmoListHeader>{deal.name}</VmoListHeader>
                            <VmoListDescription>Sales: {deal.status}</VmoListDescription>
                          </VmoListItem>
                        </VmoList>
                      </VmoGridColumn>
                      {/* <VmoGridColumn width="4" className="text-right">
                        <VmoTooltip
                          content="External"
                          size="mini"
                          position="top center"
                          trigger={
                            <VmoButton icon className="bg-btn">
                              <FiExternalLink />
                            </VmoButton>
                          }
                        />
                        <VmoTooltip
                          content="Delete"
                          size="mini"
                          position="top center"
                          trigger={
                            <VmoButton icon className="bg-btn">
                              <SvgIcon path="common/delete" />
                            </VmoButton>
                          }
                        />
                      </VmoGridColumn> */}
                    </VmoGrid>
                  ))
                ) : (
                  <p>No Deals Attached</p>
                )}
              </VmoCardContent>
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0 tags-contact">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 2}
              index={2}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 2 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Tags ({_.size(_.get(singleContact, 'labelIds'))})
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 2} className="tags-attached">
              {_.size(_.get(singleContact, 'labelIds')).length === 0 ? (
                <p className="kadence-lead mb-0">No Tags</p>
              ) : null}
              <Tags
                id={contactId}
                data={singleContact}
                tagsUpdate={editContact}
                Controller={Controller}
                control={control}
                errors={errors}
                getValues={getValues}
                rolePermissions={manageContactsCompanyPermissions}
              />
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 3}
              index={3}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 3 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Notes{' '}
              {get(['notes'], singleContact, []).length > 0 ? `(${get(['notes'], singleContact, []).length})` : ''}
            </VmoAccordionTitle>
            <VmoAccordionContent className="p-0 pb-2" active={activeIndex === 3}>
              <VmoForm className="mx-3 mb-2" size="tiny">
                <VmoFormField>
                  <VmoFormInput
                    maxLength={30}
                    placeholder="Write note here..."
                    disabled={!manageContactsCompanyPermissions}
                    onChange={e => manageContactsCompanyPermissions && setNoteText(e.target.value)}
                    value={noteText}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        if (!manageContactsCompanyPermissions) return
                        const data = {
                          note: e.target.value,
                        }

                        if (e.target.value.length !== 0 && e.target.value.trim() !== '') {
                          dispatch(addNoteInContacts(contactId, data))
                        }
                      }
                    }}
                  />
                </VmoFormField>
              </VmoForm>
              {/* {get(["notes"], singleContact, []).length === 0 ? (
                <p className="kadence-lead px-4 mb-0">No Notes </p>
              ) : null} */}
              <VmoList selection className="recentConv">
                {renderNotes()}
              </VmoList>
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 4}
              index={4}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 4 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Tasks ({get(['tasks'], singleContact, []).length})
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 4}>
              <VmoList className="recentConv">
                {get(['tasks'], singleContact, []).length > 0
                  ? get(['tasks'], singleContact, [])
                      .slice(0, 3)
                      .map(task => (
                        <VmoListItem>
                          <VmoIcon
                            style={{
                              paddingTop: '4px',
                            }}
                          >
                            <VmoCheckbox
                              onClick={() => {
                                if (!manageTasksPermissions) return
                                let data = {}

                                if (task.status === 'closed') {
                                  data = {
                                    status: 'open',
                                  }
                                } else {
                                  data = {
                                    status: 'closed',
                                  }
                                }

                                dispatch(editTaskActivities(task.id, data))
                              }}
                              checked={task.status === 'closed'}
                            />
                          </VmoIcon>
                          <VmoListContent>
                            <VmoListHeader
                              as="a"
                              style={
                                task.status === 'closed'
                                  ? {
                                      textDecoration: 'line-through',
                                    }
                                  : null
                              }
                              onClick={() => {
                                if (manageTasksPermissions) {
                                  dispatch(getTaskActivities(task.id))
                                  setIsViewTaskOpen(true)
                                }
                              }}
                            >
                              {task.subject ? wrapText(task.subject, 20) : ''}
                              <span
                                style={{
                                  float: 'right',
                                  fontSize: 'small',
                                }}
                              >
                                {get(['assignee', 'firstName'], task, '')} {get(['assignee', 'lastName'], task, '')}
                              </span>
                            </VmoListHeader>
                            {task.status === 'closed' ? null : (
                              <VmoListDescription>
                                Due:
                                {GetFormattedDate(get(['endAt'], task, ''))}
                              </VmoListDescription>
                            )}
                          </VmoListContent>
                        </VmoListItem>
                      ))
                  : ''}
              </VmoList>
              {get(['tasks'], singleContact, []).length === 0 ? <p className="kadence-lead mb-0">No Task</p> : null}
              <VmoTooltip
                content={
                  manageTasksPermissions
                    ? 'Add Task'
                    : getPermissionTooltipContent({roleAccess: manageTasksPermissions})
                }
                size="mini"
                position="top center"
                trigger={
                  <VmoButton
                    className="mt-3 ml-0 delete-forecast"
                    icon
                    onClick={() => manageTasksPermissions && setIsCreateTaskOpen(!isCreateTaskOpen)}
                  >
                    <SvgIcon path="common/plus" />
                  </VmoButton>
                }
              />
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 5}
              index={5}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 5 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Files ({_.size(_.get(singleContact, 'attachments'))})
            </VmoAccordionTitle>

            <VmoAccordionContent active={activeIndex === 5} className="pt-0">
              {get(['attachments'], singleContact, []).map(item => (
                <span>{item.name}</span>
              ))}
              {_.size(_.get(singleContact, 'attachments')) === 0 && <p className="kadence-lead">No Files</p>}
              <LockPermissionTooltip isRoleAccessDenied={!manageContactsCompanyPermissions}>
                <VmoInput
                  fluid
                  id="fileUpload"
                  type="file"
                  multiple
                  disabled={!manageContactsCompanyPermissions}
                  onChange={e => {
                    const data = {
                      file: e.target.files,
                    }
                    dispatch(uploadAttachmentsContact(contactId, data))
                  }}
                />
              </LockPermissionTooltip>
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 6}
              index={6}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 6 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Purchase Order({get(['purchaseorder'], singleContact, []).length})
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 6}>
              <VmoCardContent className="p-0">
                {get(['purchaseorder'], singleContact, []).length === 0 ? (
                  <p className="kadence-lead mb-4">No Purchase Order</p>
                ) : (
                  get(['purchaseorder'], singleContact, []).map(PO => {
                    return (
                      <>
                        <p>
                          <strong>PO Number: </strong>
                          {PO.poNumber}
                        </p>
                        <p>
                          <strong>Subject: </strong>
                          {PO.subject}
                        </p>
                        <p>
                          <strong>Status:</strong>
                          {PO.status}
                        </p>
                      </>
                    )
                  })
                )}
              </VmoCardContent>
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 7}
              index={7}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 7 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Sales Order({get(['salesorder'], singleContact, []).length})
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 7}>
              <VmoCardContent className="p-0">
                {get(['salesorder'], singleContact, []).length === 0 ? (
                  <p className="kadence-lead mb-4">No Sales Order</p>
                ) : (
                  get(['salesorder'], singleContact, []).map(SO => {
                    return (
                      <>
                        <p>
                          <strong>SO Number: </strong>
                          {SO.SoNumber}
                        </p>
                        <p>
                          <strong>Subject: </strong>
                          {SO.subject}
                        </p>
                        <p>
                          <strong>Status:</strong>
                          {SO.status}
                        </p>
                      </>
                    )
                  })
                )}
              </VmoCardContent>
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 8}
              index={8}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 8 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Invoice({get(['invoices'], singleContact, []).length})
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 8}>
              <VmoCardContent className="p-0">
                {get(['invoices'], singleContact, []).length === 0 ? (
                  <p className="kadence-lead mb-4">No Invoices</p>
                ) : (
                  get(['invoices'], singleContact, []).map(invoice => {
                    return (
                      <>
                        <p>
                          <strong>Invoice Number: </strong>
                          {invoice.invoiceNumber}
                        </p>
                        <p>
                          <strong>Subject: </strong>
                          {invoice.subject}
                        </p>
                        <p>
                          <strong>Status:</strong>
                          {invoice.status}
                        </p>
                      </>
                    )
                  })
                )}
              </VmoCardContent>
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>

      <VmoCard fluid>
        <VmoCardContent className="p-0">
          <VmoAccordion styled className="kadence-accordion">
            <VmoAccordionTitle
              active={activeIndex === 9}
              index={9}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 9 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Project ({get(['projects'], singleContact, []).length})
            </VmoAccordionTitle>
            <VmoAccordionContent active={activeIndex === 9}>
              {get(['projects'], singleContact, []).length === 0 ? (
                <p className="kadence-lead mb-4">No Projects</p>
              ) : (
                <VmoList>
                  {get(['projects'], singleContact, []).map(project => (
                    <VmoListItem key={project.id}>
                      <VmoListContent>
                        <VmoListContent floated="right">
                          <FiExternalLink
                            style={{cursor: 'pointer'}}
                            onClick={() => window.open(`/projects/${project.id}`)}
                          />
                        </VmoListContent>
                        <VmoListHeader>{startCase(project.title)}</VmoListHeader>
                        <VmoListDescription>
                          {getFormattedDate(project.startDate)} - {getFormattedDate(project.endDate)}
                        </VmoListDescription>
                        <VmoLabel color={getProjectLabelColor(project.status)} className="kadence-label">
                          {project.status}
                        </VmoLabel>
                      </VmoListContent>
                    </VmoListItem>
                  ))}
                </VmoList>
              )}
            </VmoAccordionContent>
          </VmoAccordion>
        </VmoCardContent>
      </VmoCard>
      {renderCreateTaskModal()}
      {isViewTaskOpen && <ViewTask setIsViewTaskOpen={setIsViewTaskOpen} isViewTaskOpen={isViewTaskOpen} />}
    </div>
  )
}

export default ContactSummary
