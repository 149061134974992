import {apiAction} from './api'
import {KADENCE_ANALYTICS} from './endpoint'
import {
  AVERAGE_REPLIES_RESOLUTION,
  AVERAGE_RESOLUTION_TIME_STATS,
  CUST_SAT_CONVO,
  FIRST_CONTACT_RESOLVED,
  FIRST_RESPONSE_TIME_STATS,
  NEW_CONTACTS_ANALYTICS,
  TAGGED_VS_NON_TAGGED_OVERTIME,
  TAGS_COUNT_TABLE,
  TOTAL_CONVERSATION,
  CONVERSATION_BY_INBOX,
} from './types'

export function totalConversationAnalytics(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/totalconversations`,
    method: 'POST',
    label: TOTAL_CONVERSATION,
    data,
  })
}

export function npsScoreStats(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/surveys/npsscorestats`,
    method: 'POST',
    label: CUST_SAT_CONVO,
    data,
  })
}

export function firstResponseTimeStats(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/firstresponsetimestats`,
    method: 'POST',
    label: FIRST_RESPONSE_TIME_STATS,
    data,
  })
}

export function firstContactResolved(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/firstcontactresolved`,
    method: 'POST',
    label: FIRST_CONTACT_RESOLVED,
    data,
  })
}

export function averageRepliesResolution(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/averagerepliesperresolution`,
    method: 'POST',
    label: AVERAGE_REPLIES_RESOLUTION,
    data,
  })
}

export function averageResolutionTimeStats(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/averageresolvedtimestats`,
    method: 'POST',
    label: AVERAGE_RESOLUTION_TIME_STATS,
    data,
  })
}

export function newContactsAnalytics(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/newcontacts`,
    method: 'POST',
    label: NEW_CONTACTS_ANALYTICS,
    data,
  })
}

export function taggedVsNonTaggedOvertime(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/taggedvsnontaggedovertime`,
    method: 'POST',
    label: TAGGED_VS_NON_TAGGED_OVERTIME,
    data,
  })
}

export function tagsCountTable(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/tagscounttable`,
    method: 'POST',
    label: TAGS_COUNT_TABLE,
    data,
  })
}

export function updateSelectedInbox(id) {
  return {
    type: 'UPDATE_SELECTED_INBOX',
    payload: id,
  }
}

export function updateSelectedAssignee(data) {
  return {
    type: 'UPDATE_SELECTED_ASSIGNEE',
    payload: data,
  }
}

export function conversationByInbox(data) {
  return apiAction({
    url: `${KADENCE_ANALYTICS}/conversations/conversationbyinbox`,
    method: 'POST',
    label: CONVERSATION_BY_INBOX,
    data,
  })
}
