/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoDivider,
  VmoForm,
  VmoFormInput,
  VmoGrid,
  VmoGridColumn,
  VmoImage,
  VmoInput,
  VmoModal,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {get} from 'utils/helper'
import {changeEmail, getVerification, resendVerification, setPassword, userVerification} from 'actions'
import {CHANGE_EMAIL, RESEND_VERIFICATION, SET_PASSWORD, VERIFICATION} from 'actions/types'
import {setAccountInfo, setAppToken, setMetadataInfo, setRoleInfo, setUserInfo} from 'utils/local-storage'
import logo from 'assets/omni24-full-logo.png'
import {currentSubscription} from 'actions/billing'
import {getUserRolePermissions} from 'actions/roles'
import SvgIcon from 'components/common/SvgIcon'

// keyCode constants
const BACKSPACE = 8
const LEFT_ARROW = 37
const RIGHT_ARROW = 39
const DELETE = 46
const SPACEBAR = 32

function Emailverify() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [resetPassword, setResetPassword] = useState(false)
  const [signUpWithAnother, setSignUpWithAnother] = useState(false)
  const [otpCounter, setOtpCounter] = useState(0)
  const [newPwdField, setNewPwdField] = useState('')
  const url = new URL(window.location.href)
  const queryObj = Object.fromEntries(new URLSearchParams(url.search))
  const otpFromUrl = queryObj.otp
  const c1Ref = useRef(null)
  const c2Ref = useRef(null)
  const c3Ref = useRef(null)
  const c4Ref = useRef(null)
  const c5Ref = useRef(null)
  const c6Ref = useRef(null)

  const [code, setCode] = useState({
    c1: get([[0]], otpFromUrl, ''),
    c2: get([[1]], otpFromUrl, ''),
    c3: get([[2]], otpFromUrl, ''),
    c4: get([[3]], otpFromUrl, ''),
    c5: get([[4]], otpFromUrl, ''),
    c6: get([[5]], otpFromUrl, ''),
  })

  const {verificationData, verificationOTPData, setPwdData, changeEmailData} = useSelector(state => state.verifyUser)
  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const getRequestId = () => {
    const url = new URL(window.location.href)
    return url.searchParams.get('requestId')
  }

  const resendOTPBtn = () => {
    setOtpCounter(30)
    dispatch(resendVerification(getRequestId()))
  }

  const {handleSubmit, errors, control} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    defaultValues: {newEmail: ''},
    resolver: joiResolver(
      Joi.object({
        newEmail: Joi.string()
          .email({tlds: {allow: false}})
          .required(),
      })
    ),
  })

  const UpdateEmailModal = () => {
    return (
      <VmoModal
        size="tiny"
        open={signUpWithAnother}
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        onClose={() => setSignUpWithAnother(false)}
        onOpen={() => setSignUpWithAnother(true)}
      >
        <VmoModalHeader>Change Your Email Address</VmoModalHeader>
        <VmoModalContent className="signInPage email-verify">
          <p>Try with another new email id!!</p>
          <VmoForm size="large">
            <Controller
              name="newEmail"
              render={({value, onChange}) => (
                <VmoFormInput
                  fluid
                  icon={<SvgIcon path="common/mail" className="mail-icon" />}
                  // icon="mail"
                  iconPosition="left"
                  placeholder="Email address"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.newEmail && {
                      content: errors.newEmail.message,
                    }
                  }
                />
              )}
              control={control}
            />
            <VmoButton
              primary
              type="submit"
              fluid
              size="large"
              disabled={isLoadingData && type.includes(CHANGE_EMAIL)}
              loading={isLoadingData && type.includes(CHANGE_EMAIL)}
              onClick={handleSubmit(data => {
                const payload = {
                  requestId: getRequestId(),
                  oldEmail: verificationData.email,
                  newEmail: data.newEmail,
                }
                dispatch(changeEmail(payload))
              })}
            >
              Update Email
            </VmoButton>
          </VmoForm>
        </VmoModalContent>
      </VmoModal>
    )
  }

  const renderPasswordField = () => {
    return (
      <VmoCardContent>
        <VmoInput
          style={{right: '50px'}}
          name="password"
          placeholder="Type New Password"
          type="password"
          onChange={e => setNewPwdField(e.target.value)}
        />

        <VmoButton
          color="primary"
          size="lg"
          block
          loading={isLoadingData && type.includes(SET_PASSWORD)}
          disabled={isLoadingData && type.includes(SET_PASSWORD)}
          onClick={() => {
            const data = {
              requestId: getRequestId(),
              password: newPwdField,
            }

            dispatch(setPassword(data))
          }}
        >
          Create New Password
        </VmoButton>
      </VmoCardContent>
    )
  }

  const onVerify = () => {
    const otp = code.c1 + code.c2 + code.c3 + code.c4 + code.c5 + code.c6
    const data = {
      otp,
      requestId: getRequestId(),
    }
    dispatch(userVerification(data))
  }
  const setInformation = data => {
    setAppToken(get(['login', 'token'], data, ''))
    setAccountInfo(get(['login', 'account'], data))
    setUserInfo(get(['login', 'user'], data))
    setMetadataInfo(get(['login', 'account', 'metadata'], data, {}))
    setRoleInfo(get(['login', 'role'], data, ''))
  }

  useEffect(() => {
    c1Ref.current.focus()
    if (Object.values(code).length === 6 && Object.values(code).some(value => value)) {
      onVerify()
    }
  }, [])

  useEffect(() => {
    let interval

    if (otpCounter > 0) {
      interval = setInterval(() => setOtpCounter(otpCounter => otpCounter - 1), 1000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [otpCounter])

  useEffect(() => {
    if (code.c1 && code.c1.length > 1) {
      setCode({
        ...code,
        c1: code.c1[0],
        c2: code.c1[1],
        c3: code.c1[2],
        c4: code.c1[3],
        c5: code.c1[4],
        c6: code.c1[5],
      })
      // changeFocus(c6Ref);
    }
  }, [JSON.stringify(code.c1)])

  useEffect(() => {
    if (code.c2 && code.c2.length > 1) {
      setCode({
        ...code,
        c2: code.c2[0],
        c3: code.c2[1],
        c4: code.c2[2],
        c5: code.c2[3],
        c6: code.c2[4],
      })
      // changeFocus(c6Ref);
    }
  }, [JSON.stringify(code.c2)])

  useEffect(() => {
    if (code.c3 && code.c3.length > 1) {
      setCode({
        ...code,
        c3: code.c3[0],
        c4: code.c3[1],
        c5: code.c3[2],
        c6: code.c3[3],
      })
      // changeFocus(c6Ref);
    }
  }, [JSON.stringify(code.c3)])

  useEffect(() => {
    if (code.c4 && code.c4.length > 1) {
      setCode({
        ...code,
        c4: code.c4[0],
        c5: code.c4[1],
        c6: code.c4[2],
      })
      // changeFocus(c6Ref);
    }
  }, [JSON.stringify(code.c4)])

  useEffect(() => {
    if (code.c5 && code.c5.length > 1) {
      setCode({...code, c5: code.c5[0], c6: code.c5[1]})
      // changeFocus(c6Ref);
    }
  }, [JSON.stringify(code.c5)])

  useEffect(() => {
    if (code.c6 && code.c6.length > 1) {
      setCode({...code, c6: code.c6[0]})
      // changeFocus(c6Ref);
    }
  }, [JSON.stringify(code.c6)])

  useEffect(() => {
    if (successLabels.includes(VERIFICATION)) {
      if (
        verificationOTPData.verification.action === 'reset_password' ||
        verificationOTPData.verification.action === 'set_password'
      ) {
        setResetPassword(true)
      }
      if (verificationOTPData.verification.action === 'account_setup') {
        if (
          verificationOTPData.verification.isVerified === true &&
          verificationOTPData.verification.action === 'account_setup'
        ) {
          setInformation(verificationOTPData)

          history.push(`/thankyou`)
          dispatch({
            type: 'LOGGED_IN',
          })
          dispatch(currentSubscription())
          dispatch(getUserRolePermissions())
        }
      }
    }
  }, [successLabels])

  useEffect(() => {
    if (successLabels.includes(SET_PASSWORD) && setPwdData.verification.action === 'set_password') {
      setInformation(setPwdData)
      dispatch(currentSubscription())
      dispatch(getUserRolePermissions())
      history.push(`/home`)
    }
    if (successLabels.includes(SET_PASSWORD) && setPwdData.verification.action === 'reset_password') {
      history.push(`/signin`)
    }
    if (successLabels.includes(CHANGE_EMAIL)) {
      setSignUpWithAnother(false)
      verificationData.email = changeEmailData?.newEmail
    }
    if (successLabels.includes(RESEND_VERIFICATION)) {
      dispatch(getVerification(getRequestId()))
    }
  }, [successLabels])

  useEffect(() => {
    dispatch(getVerification(getRequestId()))
  }, [dispatch])

  return (
    <VmoContainer>
      <div className="signInPage">
        <VmoImage src={logo} className="veemo-logo" />
        <div className="signInBody">
          <h3>{(verificationOTPData && verificationOTPData.verification.email) || ''}</h3>
          <VmoCard className="loginCard otpCard">
            <VmoCardContent>
              <h1>Check your email</h1>
              <p className="kadence-lead">
                We just sent a six-digit confirmation code to: {verificationData ? verificationData.email : null}
              </p>
              {!resetPassword ? (
                <>
                  <h3>Enter your confirmation code below:</h3>
                  <VmoDivider hidden />
                  <VmoForm>
                    <VmoGrid columns="equal">
                      <VmoGridColumn>
                        <VmoInput
                          onPaste={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            const pastedText = e.clipboardData.getData('text')
                            const numbersOnly = pastedText.replace(/\D/g, '')
                            setCode({...code, c1: numbersOnly})
                          }}
                          ref={c1Ref}
                          value={code.c1}
                          maxLength="1"
                          onChange={e => {
                            setCode({...code, c1: e.target.value})
                            if (e.target.value !== '') c2Ref.current.focus()
                          }}
                          onKeyDown={e => {
                            if ((e.keyCode === BACKSPACE || e.key === 'Backspace') && code.c1 === '') {
                              e.preventDefault()
                            } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
                              e.preventDefault()
                              c2Ref.current.focus()
                            } else if (
                              e.keyCode === SPACEBAR ||
                              e.key === ' ' ||
                              e.key === 'Spacebar' ||
                              e.key === 'Space'
                            ) {
                              e.preventDefault()
                            }
                          }}
                          fluid
                        />
                      </VmoGridColumn>
                      <VmoGridColumn>
                        <VmoInput
                          onPaste={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            const pastedText = e.clipboardData.getData('text')
                            const numbersOnly = pastedText.replace(/\D/g, '')
                            setCode({...code, c2: numbersOnly})
                          }}
                          ref={c2Ref}
                          value={code.c2}
                          maxLength="1"
                          onChange={e => {
                            setCode({...code, c2: e.target.value})
                            if (e.target.value !== '') c3Ref.current.focus()
                          }}
                          onKeyDown={e => {
                            if ((e.keyCode === BACKSPACE || e.key === 'Backspace') && code.c2 === '') {
                              e.preventDefault()
                              c1Ref.current.focus()
                            } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
                              e.preventDefault()
                              c1Ref.current.focus()
                            } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
                              e.preventDefault()
                              c3Ref.current.focus()
                            } else if (
                              e.keyCode === SPACEBAR ||
                              e.key === ' ' ||
                              e.key === 'Spacebar' ||
                              e.key === 'Space'
                            ) {
                              e.preventDefault()
                            }
                          }}
                          fluid
                        />
                      </VmoGridColumn>
                      <VmoGridColumn>
                        <VmoInput
                          ref={c3Ref}
                          value={code.c3}
                          maxLength="1"
                          onChange={e => {
                            setCode({...code, c3: e.target.value})
                            if (e.target.value !== '') c4Ref.current.focus()
                          }}
                          onPaste={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            const pastedText = e.clipboardData.getData('text')
                            const numbersOnly = pastedText.replace(/\D/g, '')
                            setCode({...code, c3: numbersOnly})
                          }}
                          onKeyDown={e => {
                            if ((e.keyCode === BACKSPACE || e.key === 'Backspace') && code.c3 === '') {
                              e.preventDefault()
                              c2Ref.current.focus()
                            } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
                              e.preventDefault()
                              c2Ref.current.focus()
                            } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
                              e.preventDefault()
                              c4Ref.current.focus()
                            } else if (
                              e.keyCode === SPACEBAR ||
                              e.key === ' ' ||
                              e.key === 'Spacebar' ||
                              e.key === 'Space'
                            ) {
                              e.preventDefault()
                            }
                          }}
                          fluid
                        />
                      </VmoGridColumn>
                      <VmoGridColumn>
                        <VmoInput
                          ref={c4Ref}
                          value={code.c4}
                          maxLength="1"
                          onChange={e => {
                            setCode({...code, c4: e.target.value})
                            if (e.target.value !== '') c5Ref.current.focus()
                          }}
                          fluid
                          onPaste={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            const pastedText = e.clipboardData.getData('text')
                            const numbersOnly = pastedText.replace(/\D/g, '')
                            setCode({...code, c4: numbersOnly})
                          }}
                          onKeyDown={e => {
                            if ((e.keyCode === BACKSPACE || e.key === 'Backspace') && code.c4 === '') {
                              e.preventDefault()
                              c3Ref.current.focus()
                            } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
                              e.preventDefault()
                              c3Ref.current.focus()
                            } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
                              e.preventDefault()
                              c5Ref.current.focus()
                            } else if (
                              e.keyCode === SPACEBAR ||
                              e.key === ' ' ||
                              e.key === 'Spacebar' ||
                              e.key === 'Space'
                            ) {
                              e.preventDefault()
                            }
                          }}
                        />
                      </VmoGridColumn>
                      <VmoGridColumn>
                        <VmoInput
                          ref={c5Ref}
                          value={code.c5}
                          maxLength="1"
                          onChange={e => {
                            setCode({...code, c5: e.target.value})
                            if (e.target.value !== '') c6Ref.current.focus()
                          }}
                          fluid
                          onPaste={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            const pastedText = e.clipboardData.getData('text')
                            const numbersOnly = pastedText.replace(/\D/g, '')
                            setCode({...code, c5: numbersOnly})
                          }}
                          onKeyDown={e => {
                            if (
                              (e.keyCode === BACKSPACE ||
                                e.key === 'Backspace' ||
                                e.keyCode === DELETE ||
                                e.key === 'Delete') &&
                              code.c5 === ''
                            ) {
                              e.preventDefault()
                              c4Ref.current.focus()
                            } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
                              e.preventDefault()
                              c4Ref.current.focus()
                            } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
                              e.preventDefault()
                              c6Ref.current.focus()
                            } else if (
                              e.keyCode === SPACEBAR ||
                              e.key === ' ' ||
                              e.key === 'Spacebar' ||
                              e.key === 'Space'
                            ) {
                              e.preventDefault()
                            }
                          }}
                        />
                      </VmoGridColumn>
                      <VmoGridColumn>
                        <VmoInput
                          ref={c6Ref}
                          value={code.c6}
                          maxLength="1"
                          onChange={e => {
                            setCode({...code, c6: e.target.value})
                          }}
                          fluid
                          onPaste={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            const pastedText = e.clipboardData.getData('text')
                            const numbersOnly = pastedText.replace(/\D/g, '')
                            setCode({...code, c6: numbersOnly})
                          }}
                          onKeyDown={e => {
                            if (
                              (e.keyCode === BACKSPACE ||
                                e.key === 'Backspace' ||
                                e.keyCode === DELETE ||
                                e.key === 'Delete') &&
                              code.c6 === ''
                            ) {
                              e.preventDefault()
                              c5Ref.current.focus()
                            } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
                              e.preventDefault()
                              c5Ref.current.focus()
                            } else if (
                              e.keyCode === SPACEBAR ||
                              e.key === ' ' ||
                              e.key === 'Spacebar' ||
                              e.key === 'Space'
                            ) {
                              e.preventDefault()
                            }
                          }}
                        />
                      </VmoGridColumn>
                    </VmoGrid>
                    <VmoButton primary type="submit" fluid size="large" onClick={onVerify}>
                      Verify
                    </VmoButton>
                  </VmoForm>
                </>
              ) : (
                renderPasswordField()
              )}
            </VmoCardContent>
          </VmoCard>
          {!resetPassword && (
            <>
              <p className="kadence-lead">
                Your code will expire in 5 minutes. Enter it soon! <br /> Keep this window open while checking for your
                code.
              </p>
              {otpCounter > 0 && <div className="kadence-lead mt-4">Resend OTP in {otpCounter} seconds</div>}
              {!otpCounter && (
                <div className="resend-otp">
                  <VmoButton primary onClick={resendOTPBtn}>
                    Resend OTP
                  </VmoButton>
                  <p>OR</p>
                  <VmoButton primary onClick={() => setSignUpWithAnother(true)}>
                    Change Email Address
                  </VmoButton>
                </div>
              )}
            </>
          )}
        </div>
        {signUpWithAnother && UpdateEmailModal()}
      </div>
    </VmoContainer>
  )
}

export default Emailverify
