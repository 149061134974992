import React from 'react'
import {Controller} from 'react-hook-form'
import {getDecimalNumberInput, removeDoubleQuotes} from 'utils/helper'
import {
  VmoCard,
  VmoCardContent,
  VmoCardDescription,
  VmoCardHeader,
  VmoDatePicker,
  VmoFormField,
  VmoFormInput,
  VmoGrid,
  VmoGridColumn,
} from 'vmo-library'

const FixedFeeBudgetForm = ({control, errors, setValue, getValues, budgetId}) => {
  const handleBlur = event => {
    const {id, value} = event.target
    let amount = parseFloat(value) >= 0 ? parseFloat(value) : Math.abs(value)

    const budgetAmount = parseFloat(getValues('budgetAmount')) || 0
    const budgetProfitMargin = parseFloat(getValues('budgetProfitMargin')) || 0
    const budgetTargetProfit = parseFloat(getValues('budgetTargetProfit')) || 0
    const budgetTargetCosts = parseFloat(getValues('budgetTargetCosts')) || 0

    if (id === 'budgetProfitMargin' && amount > 100) {
      amount = 100
    } else if (['budgetTargetProfit', 'budgetTargetCosts'].includes(id) && amount > budgetAmount) {
      amount = budgetAmount
    }

    switch (id) {
      case 'budgetAmount':
        setValue('budgetAmount', amount)
        if (budgetProfitMargin > 0) {
          const targetProfit = parseFloat((amount * (budgetProfitMargin / 100)).toFixed(2))
          const targetCosts = parseFloat((amount - targetProfit).toFixed(2))
          setValue('budgetTargetProfit', targetProfit)
          setValue('budgetTargetCosts', targetCosts)
        } else if (budgetTargetProfit > 0) {
          const profitMargin = parseFloat(((budgetTargetProfit / amount) * 100).toFixed(2))
          const targetCosts = parseFloat((amount - budgetTargetProfit).toFixed(2))
          setValue('budgetProfitMargin', profitMargin)
          setValue('budgetTargetCosts', targetCosts)
        } else if (budgetTargetCosts > 0) {
          const targetProfit = parseFloat((amount - budgetTargetCosts).toFixed(2))
          const profitMargin = parseFloat(((targetProfit / amount) * 100).toFixed(2))
          setValue('budgetTargetProfit', targetProfit)
          setValue('budgetProfitMargin', profitMargin)
        }
        break
      case 'budgetProfitMargin': {
        setValue('budgetProfitMargin', amount)
        const targetProfitFromMargin = parseFloat((budgetAmount * (amount / 100)).toFixed(2))
        const targetCostsFromMargin = parseFloat((budgetAmount - targetProfitFromMargin).toFixed(2))
        setValue('budgetTargetProfit', targetProfitFromMargin)
        setValue('budgetTargetCosts', targetCostsFromMargin)
        break
      }
      case 'budgetTargetProfit': {
        setValue('budgetTargetProfit', amount)
        const profitMarginFromProfit = parseFloat(((amount / budgetAmount) * 100).toFixed(2))
        const targetCostsFromProfit = parseFloat((budgetAmount - amount).toFixed(2))
        setValue('budgetProfitMargin', profitMarginFromProfit)
        setValue('budgetTargetCosts', targetCostsFromProfit)
        break
      }
      case 'budgetTargetCosts': {
        setValue('budgetTargetCosts', amount)
        const targetProfitFromCosts = parseFloat((budgetAmount - amount).toFixed(2))
        const profitMarginFromCosts = parseFloat(((targetProfitFromCosts / budgetAmount) * 100).toFixed(2))
        setValue('budgetTargetProfit', targetProfitFromCosts)
        setValue('budgetProfitMargin', profitMarginFromCosts)
        break
      }
      default:
        break
    }
  }

  return (
    <>
      <VmoFormField required width={8}>
        <label>Fixed fee budget</label>
        <Controller
          name="budgetAmount"
          control={control}
          render={({value, onChange}) => (
            <VmoFormInput
              type="number"
              fluid
              id="budgetAmount"
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={errors.budgetAmount && {content: removeDoubleQuotes(errors.budgetAmount.message)}}
              onBlur={handleBlur}
            />
          )}
        />
      </VmoFormField>

      <VmoGrid columns="equal">
        <VmoGridColumn>
          <VmoFormField required>
            <label>Budget start date</label>
            <Controller
              name="startDate"
              control={control}
              render={({value, onChange}) => (
                <VmoDatePicker
                  disabled={budgetId}
                  placeholder="MM/DD/YYYY"
                  time={false}
                  value={value ? new Date(value) : value}
                  onChange={value => onChange(value)}
                  inputProps={{readOnly: true}}
                />
              )}
            />
            {errors.startDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.startDate.message)}</p>}
          </VmoFormField>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoFormField required>
            <label>Budget end date</label>
            <Controller
              name="endDate"
              control={control}
              render={({value, onChange}) => (
                <VmoDatePicker
                  placeholder="MM/DD/YYYY"
                  time={false}
                  value={value ? new Date(value) : value}
                  onChange={value => onChange(value)}
                  inputProps={{readOnly: true}}
                />
              )}
            />
            {errors.endDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.endDate.message)}</p>}
          </VmoFormField>
        </VmoGridColumn>
      </VmoGrid>

      <VmoCard fluid>
        <VmoCardContent>
          <VmoCardHeader>Set financial targets</VmoCardHeader>
          <VmoCardDescription>Set targets and keep track of profitability throughout your project</VmoCardDescription>
          <VmoGrid columns="equal" className="mt-1">
            <VmoGridColumn>
              <VmoFormField>
                <label>Profit margin</label>
                <Controller
                  name="budgetProfitMargin"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      type="number"
                      fluid
                      id="budgetProfitMargin"
                      value={value}
                      onChange={(e, {value}) => onChange(getDecimalNumberInput(value))}
                      onBlur={handleBlur}
                    />
                  )}
                />
              </VmoFormField>
            </VmoGridColumn>
            <VmoGridColumn>
              <VmoFormField>
                <label>Target profit</label>
                <Controller
                  name="budgetTargetProfit"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      type="number"
                      fluid
                      id="budgetTargetProfit"
                      value={value}
                      onChange={(e, {value}) => onChange(getDecimalNumberInput(value))}
                      onBlur={handleBlur}
                    />
                  )}
                />
              </VmoFormField>
            </VmoGridColumn>
            <VmoGridColumn>
              <VmoFormField>
                <label>Target costs</label>
                <Controller
                  name="budgetTargetCosts"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      type="number"
                      fluid
                      id="budgetTargetCosts"
                      value={value}
                      onChange={(e, {value}) => onChange(getDecimalNumberInput(value))}
                      onBlur={handleBlur}
                    />
                  )}
                />
              </VmoFormField>
            </VmoGridColumn>
          </VmoGrid>
        </VmoCardContent>
      </VmoCard>
    </>
  )
}

export default FixedFeeBudgetForm
