import {
  GET_CAMPAIGN_TEMPLATE_LIST,
  GET_CANNED_RESPONSE_LIST,
  GET_SINGLE_TEMPLATE,
  GET_TAGS,
  GET_TAGS_SETTING,
  GET_TRAIL_LOGS,
} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_TAGS:
      return {
        ...state,
        tagList: action.payload,
      }

    case GET_TAGS_SETTING:
      return {
        ...state,
        getTagData: action.payload,
      }

    case GET_SINGLE_TEMPLATE: {
      return {
        ...state,
        singleResponse: action.payload,
      }
    }

    case GET_CANNED_RESPONSE_LIST: {
      return {
        ...state,
        cannedResponseList: action.payload,
      }
    }

    case GET_CAMPAIGN_TEMPLATE_LIST:
      return {
        ...state,
        getCampaignTemplateListData: action.payload || [],
      }

    case GET_TRAIL_LOGS:
      return {
        ...state,
        auditLogList: action.payload || {},
      }

    default:
      return state
  }
}
