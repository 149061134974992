import Joi from 'joi'
import {inputNumberValidation} from 'validation'

export const createInvoicesSchema = Joi.object({
  invoiceNumber: Joi.string().min(2).max(16).required().messages({
    'string.min': `Length must me at least 2 charactes`,
    'string.max': `Length must be less than or equal to 16 characters`,
  }),
  prefixId: inputNumberValidation.allow('', null).label('Prefix ID'),
  subject: Joi.string().trim().min(2).max(20).required().messages({
    'string.min': `Length must me at least 2 charactes`,
    'string.max': `Length must be less than or equal to 20 characters`,
  }),
  assigneeId: inputNumberValidation.allow(null),
  purchaseOrderNumber: Joi.string().allow(''),
  salesOrderNumber: Joi.string().allow(''),
  contactId: Joi.number().allow(null),
  // dealId: Joi.number().allow(null),
  exciseDuty: Joi.number().allow('').allow(null),
  salesCommission: Joi.number().allow('').allow(null),
  status: Joi.string().allow('', null),
  invoiceDate: Joi.date().allow(null),
  billingAddress: Joi.object({
    streetAddress: Joi.any().allow('', null).label('Street'),
    locality: Joi.any().allow('', null).label('Locality'),
    state: Joi.string().allow('', null).label('State'),
    country: Joi.string().allow('', null).label('Country'),
    postalCode: Joi.string().allow(null, '').label('Postal code'),
  }),
  shippingAddress: Joi.object({
    streetAddress: Joi.any().allow('', null).label('Street'),
    locality: Joi.any().allow('', null).label('Locality'),
    state: Joi.string().allow('', null).label('State'),
    country: Joi.string().allow('', null).label('Country'),
    postalCode: Joi.string().allow(null, '').label('Postal code'),
  }),
  description: Joi.string().trim().max(160).allow('', null).messages({
    'string.max': `Length must be less than or equal to 160 characters`,
  }),
})

export const stepsProductsSchema = Joi.object({
  products: Joi.array().items({
    productId: Joi.number().integer().required(),
    quantity: Joi.number(),
    unitPrice: Joi.number(),
    unitAmount: Joi.number(),
    discountValue: Joi.number(),
    taxValue: Joi.number(),
    unitTotal: Joi.number(),
  }),
  subTotal: Joi.number(),
  discount: Joi.number(),
  tax: Joi.number(),
  adjustment: Joi.number(),
  total: Joi.number(),
  terms: Joi.string().allow(null, '').max(250).messages({
    'string.max': `Length must be less than or equal to 250 characters`,
  }),
})

export const templateSchema = Joi.object({
  template: Joi.object({
    templateNo: Joi.number().required(),
    color: Joi.string().allow('').allow(null),
    header: Joi.string().allow('').allow(null),
    footer: Joi.string().allow('').allow(null),
    addressId: inputNumberValidation.required().messages({
      'number.base': 'Address must be selected',
    }),
  }),
})

export const settingSchema = Joi.object({
  prefix: Joi.string().trim().max(20).required().messages({
    'string.empty': `PrefixId cannot be an empty field`,
    'string.max': `PrefixId cannot be max char than 20`,
  }),
})

export const invoicesEditSchema = Joi.object({
  invoiceNumber: Joi.string().min(2).max(16).required().messages({
    'string.min': `Length must me at least 2 charactes`,
    'string.max': `Length must be less than or equal to 16 characters`,
  }),
  prefixId: inputNumberValidation.allow('', null).label('Prefix ID'),
  subject: Joi.string().trim().min(2).max(20).required().messages({
    'string.min': `Length must me at least 2 charactes`,
    'string.max': `Length must be less than or equal to 20 characters`,
  }),
  assigneeId: inputNumberValidation.allow(null),
  purchaseOrderNumber: Joi.string().allow(''),
  salesOrderNumber: Joi.string().allow(''),
  contactId: Joi.number().allow(null),
  // dealId: Joi.number().allow(null),
  exciseDuty: Joi.number().allow('').allow(null),
  salesCommission: Joi.number().allow('').allow(null),
  status: Joi.string().allow('', null),
  invoiceDate: Joi.date().allow(null),
  billingAddress: Joi.object({
    streetAddress: Joi.any().allow('', null).label('Street'),
    locality: Joi.any().allow('', null).label('Locality'),
    state: Joi.string().allow('', null).label('State'),
    country: Joi.string().allow('', null).label('Country'),
    postalCode: Joi.string().allow(null, '').label('Postal code'),
  }),
  shippingAddress: Joi.object({
    streetAddress: Joi.any().allow('', null).label('Street'),
    locality: Joi.any().allow('', null).label('Locality'),
    state: Joi.string().allow('', null).label('State'),
    country: Joi.string().allow('', null).label('Country'),
    postalCode: Joi.string().allow(null, '').label('Postal code'),
  }),
  description: Joi.string().trim().max(160).allow('', null).messages({
    'string.max': `Length must be less than or equal to 160 characters`,
  }),
  products: Joi.array().items({
    productId: Joi.number().integer().required(),
    quantity: Joi.number(),
    unitPrice: Joi.number(),
    unitAmount: Joi.number(),
    discountValue: Joi.number(),
    taxValue: Joi.number(),
    unitTotal: Joi.number(),
  }),
  subTotal: Joi.number(),
  discount: Joi.number(),
  tax: Joi.number(),
  adjustment: Joi.number(),
  total: Joi.number(),
  terms: Joi.string().allow(null, '').max(250).messages({
    'string.max': `Length must be less than or equal to 250 characters`,
  }),
  template: Joi.object({
    templateNo: Joi.number().required(),
    color: Joi.string().allow('').allow(null),
    header: Joi.string().allow('').allow(null),
    footer: Joi.string().allow('').allow(null),
    addressId: inputNumberValidation.allow('', null),
  }),
})
