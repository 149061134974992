import React, {useEffect, useMemo, useState} from 'react'
import {useHistory} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoHeader,
  VmoLabel,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {ALLOW_USER_ACCESS, DELETE_USER, GET_USERS} from 'actions/types'
import {allowUserAccess, deleteUser, getUsers} from 'actions/settings'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create-user.svg'
import {get, getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import {FiRefreshCcw} from 'react-icons/fi'
import {resendInvitation} from 'actions'
import moment from 'moment'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import UserForm from './UserForm'

const Users = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [createUser, setCreateUser] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState(0)

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {getUsersList: {users = [], pendingUsers = []} = {}} = useSelector(state => state.settings)
  const quantity = useSelector(state =>
    get(['billing', 'currentSubscriptionData', 'subscription', 'quantity'], state, 1)
  )
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const usersLearnMore = settingsLearnMoreUrl?.myOrganization?.users?.learnMore

  const {isLoading} = useApiLoader({label: GET_USERS})
  const {myOrganizationDefaultPermissions} = useUserPermissions()

  const activatedUser = useMemo(() => {
    return users.reduce((acc, user) => {
      if (!user.isDisabled) {
        return (acc += 1)
      }
      return acc
    }, 0)
  }, [users])

  useEffect(() => {
    dispatch(getUsers({setting: true}))
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(DELETE_USER) || successLabels.includes(ALLOW_USER_ACCESS)) {
      dispatch(getUsers({setting: true}))
    }
  }, [successLabels, dispatch])

  if (isLoading) {
    return <PageLoader />
  }

  if (createUser) {
    return <UserForm toggleForm={setCreateUser} {...props} />
  }

  if ([...users, ...pendingUsers].length < 1) {
    return (
      <CreateFirst
        header="Create User"
        subHeader="Create and Manage your company assets easily and efficiently
      at one place."
        addFirst={() => myOrganizationDefaultPermissions && setCreateUser(true)}
        buttonText="Add User"
        tooltip={!myOrganizationDefaultPermissions}
        tooltipContent={getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})}
        imageSrc={create}
      />
    )
  }

  const renderAccessButton = user => {
    if (user.isDisabled) {
      if (quantity > activatedUser) {
        return (
          <LockPermissionTooltip isRoleAccessDenied={!myOrganizationDefaultPermissions}>
            <VmoButton
              primary
              className={!myOrganizationDefaultPermissions ? 'disabled-button' : ''}
              onClick={e => {
                e.stopPropagation()
                if (myOrganizationDefaultPermissions) {
                  dispatch(allowUserAccess(user.id))
                }
              }}
            >
              Active
            </VmoButton>
          </LockPermissionTooltip>
        )
      }
      return <VmoLabel color="orange">De-Activated</VmoLabel>
    }
    return <VmoLabel color="green">Activated</VmoLabel>
  }

  const renderUserList = () => {
    return [...users, ...pendingUsers].map((user, index) => {
      if (user.isVerified) {
        return (
          <VmoTableRow className="tableLink" onClick={() => history.push(`users/${user.id}`)} key={user.id}>
            <VmoTableCell>{index + 1}</VmoTableCell>
            <VmoTableCell>
              <VmoHeader as="h5">
                {`${startCase(get(['firstName'], user, ''))} ${startCase(get(['lastName'], user, ''))}`}
                {get(['online'], user, '') && <VmoLabel circular color="green" empty size="mini" />}
              </VmoHeader>
            </VmoTableCell>
            <VmoTableCell>{get(['email'], user, '')}</VmoTableCell>
            <VmoTableCell>{startCase(get(['role'], user, ''))}</VmoTableCell>
            <VmoTableCell>{getFormattedDate(user.createdAt)}</VmoTableCell>
            <VmoTableCell>{renderAccessButton(user)}</VmoTableCell>
            <VmoTableCell>
              <VmoTooltip
                size="mini"
                position="top center"
                content={
                  myOrganizationDefaultPermissions
                    ? 'Delete'
                    : getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})
                }
                trigger={
                  <VmoButton
                    basic
                    icon
                    className="tableLink-delete-icon"
                    onClick={e => {
                      e.stopPropagation()
                      if (myOrganizationDefaultPermissions) {
                        setIsDelete(true)
                        setUserIdToDelete(user.id)
                      }
                    }}
                  >
                    <SvgIcon path="common/delete" />
                  </VmoButton>
                }
              />
            </VmoTableCell>
          </VmoTableRow>
        )
      }
      return (
        <VmoTableRow onClick={() => history.push(`/settings/users/${user.id}`)} className="tableLink" key={user.id}>
          <VmoTableCell>{index + 1}</VmoTableCell>
          <VmoTableCell className="prevCell">
            <VmoHeader as="h5" className="m-0">
              {`${startCase(get(['firstName'], user, ''))} ${startCase(get(['lastName'], user, ''))}`}
              <VmoLabel size="tiny" className="ml-2">
                Pending
              </VmoLabel>
              <div className="PrevActPart">
                <VmoTooltip
                  position="top center"
                  content={
                    myOrganizationDefaultPermissions
                      ? 'Resend Invitation'
                      : getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})
                  }
                  size="mini"
                  trigger={
                    <VmoButton
                      icon
                      onClick={e => {
                        e.stopPropagation()
                        if (myOrganizationDefaultPermissions) {
                          dispatch(resendInvitation(user.id))
                        }
                      }}
                    >
                      <FiRefreshCcw />
                    </VmoButton>
                  }
                />
              </div>
            </VmoHeader>
          </VmoTableCell>
          <VmoTableCell>{get(['email'], user, '')}</VmoTableCell>
          <VmoTableCell>{startCase(get(['role'], user, ''))}</VmoTableCell>
          <VmoTableCell>{getFormattedDate(user.createdAt)}</VmoTableCell>
          <VmoTableCell>
            <VmoTooltip
              size="mini"
              position="top center"
              content={
                myOrganizationDefaultPermissions
                  ? 'Delete'
                  : getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})
              }
              trigger={
                <VmoButton
                  basic
                  icon
                  className="tableLink-delete-icon"
                  onClick={e => {
                    e.stopPropagation()
                    if (myOrganizationDefaultPermissions) {
                      setIsDelete(true)
                      setUserIdToDelete(user.id)
                    }
                  }}
                >
                  <SvgIcon path="common/delete" />
                </VmoButton>
              }
            />
          </VmoTableCell>
          <VmoTableCell>Pending</VmoTableCell>
        </VmoTableRow>
      )
    })
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/users" />}
      header="Users"
      subHeader="Add users in your organization by sending invites through emails"
      learnMoreUrl={usersLearnMore}
      headerButton={{
        onClick: () => myOrganizationDefaultPermissions && setCreateUser(true),
        content: 'Add',
      }}
      table
      headerDivider
      lockRole={!myOrganizationDefaultPermissions}
    >
      <VmoTable basic selectable>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Name</VmoTableHeaderCell>
            <VmoTableHeaderCell>Email</VmoTableHeaderCell>
            <VmoTableHeaderCell>Role</VmoTableHeaderCell>
            <VmoTableHeaderCell>Created Date</VmoTableHeaderCell>
            <VmoTableHeaderCell>Status</VmoTableHeaderCell>
            <VmoTableHeaderCell>Action</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>
          {isLoadingData && type.includes(GET_USERS) ? <VmoTablePlaceholder columns={7} /> : renderUserList()}
        </VmoTableBody>
      </VmoTable>

      {isDelete && (
        <DeleteModal
          onDelete={() => {
            dispatch(deleteUser({ids: [userIdToDelete]}))
          }}
          isModalOpen={isDelete}
          setIsModalOpen={setIsDelete}
          modalText="Are you sure you want to delete user ?"
          type={DELETE_USER}
        />
      )}
    </SettingLayout>
  )
}
export default Users
