import React, {useEffect} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {getPublicProjectForms, submitProjectFrom} from 'actions/projects'
import {GET_PUBLIC_PROJECT_FORMS, SUBMIT_PROJECT_FORM} from 'actions/types'
import {useHistory, useParams} from 'react-router'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {publicFormSchema} from 'validation/Projects/projects/forms.schema'
import {VmoButton, VmoForm} from 'vmo-library'
import FormQuestions from './FormQuestions'

function PublicForm() {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id} = useParams()

  const {
    getQuestionsData = [],
    getSettingsData = {},
    getDesignData = {},
    getAllData = [],
  } = useSelector(state => state.projects)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    dispatch(getPublicProjectForms(id))
  }, [dispatch, id])

  const {handleSubmit, errors, control, reset, watch} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange',
    resolver: joiResolver(publicFormSchema),
    defaultValues: {
      answers: [],
    },
  })
  const watchAll = watch()

  useEffect(() => {
    if (successLabels.includes(GET_PUBLIC_PROJECT_FORMS)) {
      reset({
        answers: getQuestionsData?.map(({id, isRequired, question, type, settings, description}) => {
          return {
            questionId: id,
            type,
            question,
            isRequired,
            answer: [],
            settings,
            description,
          }
        }),
      })
    }
  }, [successLabels])

  useEffect(() => {
    if (successLabels.includes(SUBMIT_PROJECT_FORM)) {
      history.push(`/projects`)
    }
  }, [successLabels, history])

  const submitForm = data => {
    const {answers} = data
    const newAnswer = answers?.map(({questionId, answer}) => ({
      questionId,
      answer: answer.filter(val => val),
    }))

    data.answers = newAnswer

    dispatch(submitProjectFrom({data, formId: id, successMessage: getSettingsData?.confirmMessage}))
  }

  return (
    <div className="web-form ">
      <div className="web-form-content">
        <div className="web-form-contentHead" style={{backgroundColor: getDesignData?.backgroundColor}}>
          <span>{getAllData?.title}</span>
        </div>
        <div className="web-form-contentBody">
          <VmoForm className="errorLabel">
            {watchAll?.answers?.map((q, index) => (
              <>
                <FormQuestions {...q} index={index} control={control} errors={errors} />
              </>
            ))}
          </VmoForm>
        </div>
        <div className="web-form-contentfooter">
          <VmoButton
            primary
            onClick={handleSubmit(submitForm)}
            style={{backgroundColor: getDesignData?.submitButtonColor}}
          >
            {getDesignData?.submitButtonText}
          </VmoButton>
        </div>
      </div>
      <div className="web-from-footer">{getSettingsData?.showBranding && <span>Powered By Kadence</span>}</div>
    </div>
  )
}

export default PublicForm
