import React from 'react'
import ProductsMenu from 'navigation/sub-routes/Sales/Products/ProductsMenu'
import ProductsRoutes from 'navigation/sub-routes/Sales/Products/ProductsRoutes'

function Products() {
  return (
    <>
      <ProductsMenu />
      <ProductsRoutes />
    </>
  )
}

export default Products
